import { Injectable } from "@angular/core";
import { Subscription, Subject, ReplaySubject } from "rxjs";
import { BreadcrumbService } from "../../../../presentationnal/organisms/breadcrumbs/breadcrumbs.service";
import { CatalogQueriesService } from "../async/catalog_queries.service";
import { ElementGroup } from "app/facade/interfaces/element-group";
import { Category } from "app/facade/interfaces/category";

@Injectable()
export class CatalogService {
    private _currentCategory = new ReplaySubject<any>(1);
    private _elementGroup = new ReplaySubject<any>(1);
    public queryVariablesChanged: Subject<boolean> = new Subject(); // Used by CatalogTableComponent
    public filterResetChanged: Subject<boolean> = new Subject(); // Used by CatalogComponent
    private _categories: any[] = []; // The initial categories items
    private _categoriesBeforeFilter = null; // The selected category items before applying the filters
    private _isCategory: boolean = true; // Is the selected item a category or an elementGroup
    private _queryVariables = {
        search: "",
        sort: { sortBy: "name", sortDirection: "ASC" },
    };

    // Subscription
    private _categoriesSub: Subscription;
    private _getCategorySub: Subscription;
    private _breadcrumbSub: Subscription;
    // Getters & Setters
    public set categories(categories: any) { this._categories = categories; }
    public get categories(): any { return this._categories; }
    public get isCategory(): any { return this._isCategory; }
    public get queryVariables(): any { return this._queryVariables; }


    constructor(private _breadcrumbSrv: BreadcrumbService,
                private _catalogQueriesSrv: CatalogQueriesService) { }

    /**
     * @description Get all the categories and format them for the left menu
     * And generate the breadcrumb
     * @author Kevin Palade
     * @memberof CatalogService
     */
    public initializeCatalog() {
      if (this._categoriesSub) { this._categoriesSub.unsubscribe(); }
      if (this._breadcrumbSub) { this._breadcrumbSub.unsubscribe(); }
      this._categoriesSub = this._catalogQueriesSrv.listCategoriesWithChildren().subscribe(result => {
          this._categories = JSON.parse(JSON.stringify(result.data["categories"]));
          this._categoriesBeforeFilter = JSON.parse(JSON.stringify(result.data["categories"]));
          this._categories.forEach(category => {
              category.items = category.childrenElementGroups;
          });
          this._currentCategory.next(this._categories);
          this.applyQueryVariables();
          this.generateBreadcrumb(null);
      });

      // Listen the breadcrumb click event to change the category
      this._breadcrumbSub = this._breadcrumbSrv.itemChanged.subscribe(res => {
          this.changeCategories(res.category);
      });
    }

    /**
     * @description change the categories when we click on it
     * Used by catalogComponent and catalogPagesComponent
     * @author Kevin Palade
     * @param {(Category | ElementGroup)} category
     * @memberof CatalogService
     */
    public changeCategories(category: Category | ElementGroup) {
        if (category) {
            if (category.__typename == "ElementGroup") {
                this._isCategory = false;
                this._elementGroup.next(<ElementGroup>category);
            } else {
                this._isCategory = true;
                if ( this._getCategorySub ) { this._getCategorySub.unsubscribe(); }
                this._getCategorySub = this._catalogQueriesSrv.getCategoryById(category.id).subscribe(res => {
                    const currentCategories = res.data["category"].childrenElementGroups;
                    this._currentCategory.next(currentCategories);
                    this._categoriesBeforeFilter = JSON.parse(JSON.stringify(currentCategories));
                }, err => {
                    console.error(err);
                });
            }
            this.generateBreadcrumb(category);
        } else {
            this._isCategory = true;
            this.initializeCatalog();
        }
        this.resetQueryVariables();
        this.applyQueryVariables(false);
    }

    /**
     * @description Generate the breadcrumb items
     * @author Kevin Palade
     * @private
     * @param {(Category | ElementGroup)} category
     * @memberof CatalogService
     */
    private generateBreadcrumb(category: Category | ElementGroup) {
        this._breadcrumbSrv.clear();
        this._breadcrumbSrv.addItem({name: "Catégories", category: null});
        if (category) {
            if ((<ElementGroup>category).category) {
                const data = this._categories.find(cat => cat.id == (<ElementGroup>category).category.id);
                this._breadcrumbSrv.addItem({name: data.name, category: data});
            }
            this._breadcrumbSrv.addItem({name: category.name, category: category});
        }
    }

    /**
     * @description Reset the service
     * @author Kevin Palade
     * @memberof CatalogService
     */
    public reset() {
        this._isCategory = true;
        this._breadcrumbSrv.clear();
        if (this._categoriesSub) { this._categoriesSub.unsubscribe(); }
        if (this. _getCategorySub) { this. _getCategorySub.unsubscribe(); }
    }

    /**
     * @description Set the search field
     * @author Kevin Palade
     * @param {string} search
     * @memberof CatalogService
     */
    public setSearch(search: string) {
        this._queryVariables.search = search;
        this.applyQueryVariables();
    }

    /**
     * @description Reset the queryVariable with default value
     * @author Kevin Palade
     * @private
     * @memberof CatalogService
     */
    private resetQueryVariables() {
        this.filterResetChanged.next(true);
        this._queryVariables = {
            search: "",
            sort: { sortBy: this.isCategory ? "name" : "Nom", sortDirection: "ASC" },
        };
    }

    /**
     * @description Apply the queryVariable (sort / search)
     * @author Kevin Palade
     * @private
     * @memberof CatalogService
     */
    private applyQueryVariables(reload: boolean = true) {
        if (this._isCategory ) {
            this._currentCategory.next(this.sortAndFilter(this._categoriesBeforeFilter));
        } else { this.queryVariablesChanged.next(reload); }
    }

    /**
     * @description Sort and filter the given datas
     * @author Kevin Palade
     * @param {*} dataBeforeFilter
     * @param {string} [filterKey="name"]
     * @returns {*}
     * @memberof CatalogService
     */
    public sortAndFilter(dataBeforeFilter: any, filterKey: string = "name"): any {
        let data = null;
        if ( this._queryVariables.search && this._queryVariables.search != "") {
            data = dataBeforeFilter.filter(cat => cat[filterKey].toLowerCase().indexOf(this._queryVariables.search.toLowerCase()) != -1 );
        } else {
            data = dataBeforeFilter;
        }
        data.sort((cat1, cat2) => {
            if (cat1[this._queryVariables.sort.sortBy] == cat2[this._queryVariables.sort.sortBy]) { return 0; }
            if (cat1[this._queryVariables.sort.sortBy] > cat2[this._queryVariables.sort.sortBy]) {
                return this._queryVariables.sort.sortDirection == "ASC" ? 1 : -1;
            } else { return this._queryVariables.sort.sortDirection == "ASC" ? -1 : 1; }
        });
        return data;
    }

    public getCurrentCategory() {
      return this._currentCategory;
    }

    public getCurrentElementGroup() {
      return this._elementGroup;
    }
}