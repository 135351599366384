import { Component, OnInit, Inject } from "@angular/core";
import { CONTAINER_DATA, StepFormService } from "app/presentationnal/organisms/wizard-modal/service/step-form.service";
import { CatalogQueriesService } from "app/facade/queries/catalog/async/catalog_queries.service";
import { CustomElementService } from "app/facade/quote-element-definition/elements/customs/custom.service";
import { QuotesQueriesService } from "app/facade/queries/quote/async/quotes_queries.service";
import { FormBuilder } from "@angular/forms";
import { StepZeroBase } from "../../base/step-zero-base";

@Component({
  selector: "app-nervure-step-zero",
  templateUrl: "./nervure-step-zero.component.html",
  styleUrls: ["./nervure-step-zero.component.css"]
})
export class NervureStepZeroComponent extends StepZeroBase implements OnInit {

  constructor(
    @Inject(CONTAINER_DATA) data: any,
              protected _stepFormSrv: StepFormService,
              protected _catalogQueriesSrv: CatalogQueriesService,
              protected _customElementSrv: CustomElementService,
              protected _quoteQueriesSrv: QuotesQueriesService,
              protected _fb: FormBuilder
              ) {
    super(data, _stepFormSrv, _catalogQueriesSrv, _customElementSrv, _quoteQueriesSrv, _fb);
  }

  public chooseElement(choice: any, init: boolean = false) {
    super.chooseElement(choice, init);
    if ( !init || this.actualData.isEdited === undefined ) {
      this.stepZeroForm.get("heightMetal").patchValue(choice.InnerHeight);
      this.stepZeroForm.get("widthMetal").patchValue(choice.InnerWidth);
      this.stepZeroForm.get("heightMetal").markAsTouched();
      this.stepZeroForm.get("widthMetal").markAsTouched();
    }
  }

  protected generateFormContent() {
    super.generateFormContent();
    const data: any = this.actualData.custom ? this.actualData.custom : null,
          properties: any = data ? data.properties : null;
    this.formContent.behindPlate = properties ? properties.behindPlate : false;
  }

  protected formateFormValues() {
    const buildCustomData: { element: any, laserCutting: any, welding: any } = super.formateFormValues();
    buildCustomData.element.behindPlate = this.stepZeroForm.value.behindPlate;
    return buildCustomData;
  }

  protected formatWithEditedCustomData() {
    super.formatWithEditedCustomData();
    const datasToCheck: string[] = ["behindPlate"],
    referenceData: any = {
      "behindPlate" : "behindPlate"
    };
    datasToCheck.forEach( data => {
      if (this.stepZeroForm.get(data).touched) {
        this.customData[referenceData[data]] = this.stepZeroForm.get(data).value;
      }
    });
  }

}
