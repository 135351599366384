import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OrganismsModule } from '../../../presentationnal/organisms/organisms.module';
import { ListingUsersComponent } from './listing-users/listing-users.component';
import { EditUserComponent } from './edit-user/edit-user.component';
import { AddUserComponent } from './add-user/add-user.component';


@NgModule({
  imports: [
    CommonModule,
    OrganismsModule
  ],
  declarations: [
    ListingUsersComponent,
    EditUserComponent,
    AddUserComponent
  ]
})
export class UsersModule { }
