import { Input, OnInit, ViewChild, ElementRef, Renderer2, SimpleChanges, OnChanges, AfterViewInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { ERROR_MSG_CONFIG } from "./error-message.config";
// import { ERROR_MSG_CONFIG } from "./error-message.config";

export abstract class AInputs implements OnInit, OnChanges, AfterViewInit {
  @Input() public name: string;
  @Input() public id: string;
  @Input() public group: FormGroup;
  @Input() public disable: boolean = false;
  @Input() public autofocus: boolean = false;
  @Input() public displayError: boolean = true;
  @Input() public smallInput: boolean = false;
  @Input() public tabIndex: number = 0;

  @ViewChild("input") protected inputReference: ElementRef;
  protected errorMessage: string = "";

  public constructor(protected renderer: Renderer2) { }

  ngOnInit() { }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes["disable"] && this.group) {
      if (this.disable) {
        this.group.get(this.name).disable();
      } else {
        this.group.get(this.name).enable();
      }
    }
    if (changes.group) {
      this.validationCheck();
    }
  }

  ngAfterViewInit() {
    if (this.autofocus) {
      setTimeout(() => { this.inputReference.nativeElement.focus(); }, 400);
    }
  }

  protected validationCheck() {
    this.group.get(this.name).valueChanges.subscribe(result => {
      let msg = "";
      if (!this.group.get(this.name).errors) {
        this.renderer.removeClass(this.inputReference.nativeElement, "input_error");
      } else {
        this.renderer.addClass(this.inputReference.nativeElement, "input_error");
        Object.keys(this.group.get(this.name).errors).forEach(key => {
          if (ERROR_MSG_CONFIG.has(key)) {
            msg = (msg === "") ? ERROR_MSG_CONFIG.get(key)(this.group.get(this.name).errors[key]) : msg;
          } else {
            console.log("NO ERROR MESSAGE FOR ", key);
          }
      });
      }
      this.errorMessage = msg.length > 0 ? msg : null;
    });
  }

}
