import { ITableFormActions } from "../interfaces/tables.interface";
import { PriceRequestElementOptionUnit, PriceRequestElementOptionType } from "../enums/price-request-element-option-datas.enum";
import { ISupplierOfferFieldsConfig } from "../interfaces/fields-config.interface";
import { EnumQuantityUnitSelectValue } from "../enums/element-unit-category.enum";

export const SUPPLIER_RESPONSE_FIELDS_CONFIG: ISupplierOfferFieldsConfig = {
  isAddedRow: { key: "isAddedRow", name: "isAddedRow", type: "icon", iconRef: "fa-random", priority: 1, title: "Variante d'élément",
    additionnalButton: {title: "Ajouter une option", icon: "fa fa-plus", actionToDo: null}
  },
  quantity: { key: "quantity", name: "quantity", type: "number", cannotBeTab: true, priority: 2 },
  reference: { key: "reference", name: "reference", type: "suggest", linkedField: "elementId", actionToDo: null, addedData: {}, options: [], cannotBeTab: true, priority: 3 },
  denomination: { key: "denomination", name: "denomination", type: "text", cannotBeTab: true, priority: 3 },
  matterRef: { key: "matterRef", name: "matterRef", type: "suggest", linkedField: "matterId", actionToDo: null, addedData: {}, options: [], cannotBeTab: true, priority: 4 },
  format: { key: "format", name: "format", type: "text", cannotBeTab: true, priority: 5 },
  isCut: { key: "isCut", name: "isCut", type: "checkbox", cannotBeTab: true , actionToDo: null, priority: 6},
  isEn1090: { key: "isEn1090", name: "isEn1090", type: "checkbox", cannotBeTab: true, priority: 7 },
  option: { key: "option", name: "option", type: "select", options: [], cannotBeTab: true, actionToDo: null, priority: 8 },
  quantityUnit: { key: "quantityUnit", name: "quantityUnit", type: "select",  cannotBeTab: true, priority: 3,
    options: Object.keys(EnumQuantityUnitSelectValue).map( unit => ({value: unit, label: EnumQuantityUnitSelectValue[unit]}))
  },
  basicQuantityUnit: { key: "quantityUnit", name: "quantityUnit", type: "text", cannotBeTab: true, priority: 2.5 },
  thickness: { key: "thickness", name: "thickness", type: "number", cannotBeTab: true, priority: 5 },
  length: { key: "length", name: "length", type: "number", cannotBeTab: true, priority: 6 },
  width: { key: "width", name: "width", type: "number", cannotBeTab: true, priority: 7 },
  remark: { key: "remark", name: "remark", type: "text", cannotBeTab: true, priority: 19 },
  weight: { key: "weight", name: "weight", type: "number", cannotBeTab: true, priority: 20 },
  unitSuffix : {key: "unitSuffix", name: "unitSuffix", type: "select", cannotBeTab: true,priority: 29,
    options: Object.keys(PriceRequestElementOptionUnit).map( typeKey =>({ value: PriceRequestElementOptionUnit[typeKey], label: PriceRequestElementOptionUnit[typeKey] })) },
  price: { key: "price", name: "price", type: "number", suffix: "unitSuffix", cannotBeTab: false, priority: 28 },
  deliveryDate: { key: "deliveryDate", name: "deliveryDate", type: "datepicker", dateFormated: "dateFormated", cannotBeTab: true, priority: 30 }
};

const AMALGAM_ELEMENT_ATTRIBUTS: any[] = [
  { name: "isAddedRow" },
  { name: "quantity" },
  { name: "reference" },
  { name: "matterRef" },
  { name: "format" },
  { name: "isCut" },
  { name: "isEn1090" },
  { name: "option" },
  { name: "remark" },
  { name: "weight" },
  { name: "unitSuffix" },
  { name: "price" },
  { name: "deliveryDate" },
];

const EDITABLE_INPUTS: string[] = ["weight", "price", "deliveryDate","unitSuffix"];

export const AMALGAM_TABLE_CONFIG: any = {
  headerConfig: {
    actionsList: [],
    headersName: ["", "Qté", "Référence", "Matière", "Format", "Coupe", "En1090", "Options", "Remarque", "Poids", "Prix unitaire","Unité", "Date", "actions"],
    headerClass: "table-head-form table-head-form-variant__large",
    rowClass: "table-row-form table-row-form-variant__large"
  },
  attributs: AMALGAM_ELEMENT_ATTRIBUTS,
  editableInputs: {
    isAllEditable: false,
    fieldsEditable: EDITABLE_INPUTS
  },
  tableIndex: 1
};

const PLATE_ELEMENT_ATTRIBUTS: any[] = [
  { name: "isAddedRow" },
  { name: "quantity" },
  { name: "quantityUnit" },
  { name: "matterRef" },
  { name: "thickness" },
  { name: "length" },
  { name: "width" },
  { name: "remark" },
  { name: "weight" },
  { name: "unitSuffix" },
  { name: "price" },
  { name: "deliveryDate" },
];

export const PLATE_TABLE_CONFIG: any = {
  headerConfig: {
    actionsList: [],
    headersName: ["", "Qté", "Unité", "Matière", "Épaisseur", "Longueur", "Largeur", "Remarque", "Poids", "Prix unitaire","Unité", "Date", "actions"],
    headerClass: "table-head-form table-head-form-variant__plate",
    rowClass: "table-row-form table-row-form-variant__plate"
  },
  attributs: PLATE_ELEMENT_ATTRIBUTS,
  editableInputs: {
    isAllEditable: false,
    fieldsEditable: EDITABLE_INPUTS
  },
  tableIndex: 1
};

const DIVERS_ELEMENT_ATTRIBUTS: any[] = [
  { name: "isAddedRow" },
  { name: "quantity" },
  { name: "basicQuantityUnit" },
  { name: "denomination" },
  { name: "remark" },
  { name: "weight" },
  { name: "unitSuffix" },
  { name: "price" },
  { name: "deliveryDate" },
];

export const DIVERS_TABLE_CONFIG: any = {
  headerConfig: {
    actionsList: [],
    headersName: ["", "Qté", "Unité", "Dénomination", "Remarque", "Poids", "Prix unitaire","Unité", "Date", "actions"],
    headerClass: "table-head-form table-head-form-variant__small",
    rowClass: "table-row-form table-row-form-variant__small"
  },
  attributs: DIVERS_ELEMENT_ATTRIBUTS,
  editableInputs: {
    isAllEditable: false,
    fieldsEditable: EDITABLE_INPUTS
  },
  tableIndex: 3

};

export const SUPPLIER_RESPONSE_TABLE_FORM_ACTION: ITableFormActions = {
  addAction: {type: "TEXT", content: "Variante", contentClass: "button_size_xs margin_no" },
  deleteAction: {type: "ICON", content: "fa-trash-alt", contentClass: "button_icon button_color_red" }
};

// OPTIONS DATAS TABLES

export const OPTIONS_TABLE_CONFIG: any = {
  headerConfig: {
    actionsList: [],
    rowClass: "table-row-form table-row-form-variant__options"
  },
  configs: [
    {key: "", name: "", type: "button"},
    {key: "quantity", name: "quantity", type: "number", cannotBeTab: true},
    {key: "type", name: "type", type: "select",
      options: Object.keys(PriceRequestElementOptionType).map( unitKey =>
        ({ value: unitKey, label: PriceRequestElementOptionType[unitKey] })),
      enumSource: PriceRequestElementOptionType, cannotBeTab: true},
    {key: "denomination", name: "denomination", type: "text", cannotBeTab: true},
    {key: "unit", name: "unit", type: "select",
      options: Object.keys(PriceRequestElementOptionUnit).map( typeKey =>
        ({ value: typeKey, label: PriceRequestElementOptionUnit[typeKey] })),
      enumSource: PriceRequestElementOptionUnit, cannotBeTab: true},
    {key: "price", name: "price", type: "number", suffix: "unitSuffix", cannotBeTab: false },
  ],
  editableInputs: {
    isAllEditable: false,
    fieldsEditable: ["price"]
  },
  tableIndex: 4,
  actionList: {
    deleteAction: {
      type: "ICON", content: "fa-trash-alt", contentClass: "button_icon button_color_red", actionToDo: null
    }
  }
};