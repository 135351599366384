import { EnumPurchaseOrderStatus, EnumCustomPurchaseOrderStatus, EnumPurchaseOrderAction, EnumCustomPurchaseOrderAction, EnumPurchaseOrderElementStatus } from "../interfaces/purchase-order.interface";
import { IStatusConfig } from "../interfaces/status-config.interface";
import { EnumPriceRequestStatus, EnumCustomPriceRequestStatus } from "../interfaces/price-request.interface";

export const purchaseOrderStatusConfig: IStatusConfig[] = [
  {
    value: EnumPurchaseOrderStatus.CREATED,
    color: "status_color_grey",
    label: EnumCustomPurchaseOrderStatus.CREATED
  },
  {
    value: EnumPurchaseOrderStatus.SENT,
    color: "status_color_green",
    label: EnumCustomPurchaseOrderStatus.SENT
  },
  {
    value: EnumPurchaseOrderStatus.CANCELLED,
    color: "status_color_red",
    label: EnumCustomPurchaseOrderStatus.CANCELLED
  },
];

export const purchaseOrderElementActionConfig: IStatusConfig[] = [
  {
    value: EnumPurchaseOrderElementStatus.ATTENTE,
    color: "status_color_purple",
    label: EnumPurchaseOrderElementStatus.ATTENTE
  },
  {
    value: EnumPurchaseOrderElementStatus.RECEPTIONNE,
    color: "status_color_green",
    label: EnumPurchaseOrderElementStatus.RECEPTIONNE
  },
  {
    value: EnumPurchaseOrderElementStatus.ENCOURS,
    color: "status_color_orange",
    label: EnumPurchaseOrderElementStatus.ENCOURS
  },
];

export const priceRequestStatusConfig: IStatusConfig[] = [
  {
    value: EnumPriceRequestStatus.CREATED,
    color: "status_color_grey",
    label: EnumCustomPriceRequestStatus.CREATED
  },
  {
    value: EnumPriceRequestStatus.SENT,
    color: "status_color_blue",
    label: EnumCustomPriceRequestStatus.SENT
  },
  {
    value: EnumPriceRequestStatus.ORDERED,
    color: "status_color_green",
    label: EnumCustomPriceRequestStatus.ORDERED
  },
  {
    value: EnumPriceRequestStatus.PARTIALLY,
    color: "status_color_orange",
    label: EnumCustomPriceRequestStatus.PARTIALLY
  }
];
