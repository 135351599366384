import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "app-icon-catalog",
  templateUrl: "./icon-catalog.component.html",
  styleUrls: ["./icon-catalog.component.css"]
})
export class IconCatalogComponent implements OnInit {

  // @Input() icon: string;
  @Input() imgPath: string;

  constructor() { }

  ngOnInit() {
  }

}
