/*
 * @Author: Kevin Palade
 * @Date: 2018-09-08 14:46:44
 * @Last Modified by: Kevin Palade
 * @Last Modified time: 2018-09-21 13:30:40
 */
import { Injectable, ComponentFactoryResolver, Injector, ApplicationRef, InjectionToken } from "@angular/core";
import { DomPortalHost, ComponentPortal, PortalInjector, ComponentType } from "@angular/cdk/portal";
import { Subject } from "rxjs";
import { IModalData } from "../interfaces/modal-data.interface";
import { ModalContent } from "../model/modal-content";


export const DATA = new InjectionToken<{}>("DATA");

@Injectable()
export class ModalService {
  private _portalHost: DomPortalHost;
  private _portal: ComponentPortal<any>;
  public buttonClicked: Subject<number> = new Subject<number>();
  private _modalData: IModalData;

  public get modalData(): IModalData { return this._modalData; }

  constructor(private _componentFactoryResolver: ComponentFactoryResolver,
              private _injector: Injector,
              private _appRef: ApplicationRef) {
  }

  /**
   * @description Open a new modal using portal
   * @author Kevin Palade
   * @param {*} component
   * @memberof ModalService
   */
  public openModal(component: ComponentType<ModalContent>, modalData: IModalData = null): ModalContent {
    if (!this._portalHost || !this._portalHost.hasAttached() ) {
      this._modalData = modalData;
      this._portal = new ComponentPortal(component, null, this.createInjector(modalData));
      document.querySelector("#appModal").removeAttribute("style");
      document.querySelector("#appModalTitle").innerHTML = modalData.title;
      this._portalHost = new DomPortalHost(document.querySelector("#portalModal"), this._componentFactoryResolver, this._appRef, this._injector);
      const componentRef = this._portalHost.attach<ModalContent>(this._portal);
      return componentRef.instance;
    } else {
      return null;
    }
  }

  public buttonClick(action_type: number, deleteCancelEvent: boolean = false): void {
    if (deleteCancelEvent) {
      delete this._modalData.cancelCallback;
    }
    this.buttonClicked.next(action_type); // Subscribe by the ModalContent
  }

  /**
   * @description Close the modal and use the action type [MODAL_ACTION_TYPE]
   * @author Kevin Palade
   * @memberof ModalService
   */
  public closeModal(): void {
    document.querySelector("#appModal").setAttribute("style", "display:none");
    this._portalHost.detach();
    this._modalData = null;
  }

  /**
   * @description Create custom injector
   * @author Kevin Palade
   * @private
   * @param {*} dataToPass
   * @returns {PortalInjector}
   * @memberof ModalService
   */
  private createInjector(dataToPass: any): PortalInjector {
    const injectorTokens = new WeakMap();
    injectorTokens.set(DATA, dataToPass);
    return new PortalInjector(this._injector, injectorTokens);
  }

}
