import { Option } from "./option.base";
import { ITicketElement } from "app/presentationnal/organisms";

export interface MarginProperty {
    percentage: number;
}

export class Margin extends Option<MarginProperty> {

    public constructor(
      values: MarginProperty,
      protected ticket: ITicketElement[]) {
        super(values, ticket);
    }

    public get Percentage(): number {
        return this.values.percentage;
    }

    public set Percentage(value: number) {
        this.values.percentage = value;
    }

    public get CalculatedPrice(): number {
      const totalPrice = this.TotalTicketPrice * (this.Percentage / 100);
      return +totalPrice.toFixed(4);
    }

    public get TotalTicketPrice(): number {
      let totalPrice = 0;
      const dataTicket = this.ticket;
      if (dataTicket) {
        dataTicket.forEach( ticketLine => {
          const element: any = ticketLine.element;
          if (!(element instanceof Margin)) {
            let elementPrice = element.Price;
            if (ticketLine.children) {
              ticketLine.children.forEach( children => {
                elementPrice += children.Price * children.Quantity;
              });
            }
            totalPrice += elementPrice * element.Quantity;
          }
        });
      }
      return +totalPrice.toFixed(4);
    }
}