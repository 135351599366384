import gql from "graphql-tag";

export const GET_PRICE_REQUESTS = gql`
  query getPriceRequests($filter: PriceRequestFilter){
    priceRequests(filter: $filter){
      id
      reference
      remark
      status
      isValidated
      isDone
      createdAt
      user{
        firstname
        lastname
      }
      supplyLists {
        parentSupplyCategories {
          name
        }
      }
      linkedProjects {
        reference
      }
    }
  }
`;

export const GET_NEW_PRICE_REQUESTS = gql`
  query getPriceRequests($filter: PriceRequestFilter, $pagination: Pagination){
    priceRequests(filter: $filter, pagination: $pagination){
      id
      reference
      remark
      status
      isValidated
      isDone
      createdAt
      internalRemark
      user{
        firstname
        lastname
      }
      supplyLists {
        parentSupplyCategories {
          name
        }
      }
      linkedProjects {
        reference
      }
    }
  }
`;

export const GET_PRICE_REQUEST = gql`
  query getPriceRequest($id: ID!){
    priceRequest(id: $id){
      id
      reference
      remark
      status
      isValidated
      isDone
      createdAt
      internalRemark
      barsetGeneration {
        id
        beamLength
        beamOtherLengths
        beamIsAutoCut
        beamCutThreshold
        beamMaxLoss
        tubeLength
        tubeOtherLengths
        tubeIsAutoCut
        tubeCutThreshold
        tubeMaxLoss
        generationDuration
        partsTotalLength
        amalgamsTotalLength
        totalLoss
        lossPercent
        partsQuantity
        amalgamsQuantity
      }
      user{
        username
        firstname
        lastname
      }
    }
  }
`;

export const GET_PRICE_REQUEST_REFERENCE = gql`
  query getPriceRequestReference{
    getPriceRequestReference
  }
`;

export const FREE_PRICE_REQUEST_REFERENCE = gql`
  mutation freePriceRequestReference{
    freePriceRequestReference
  }
`;

export const CREATE_PRICE_REQUEST = gql`
  mutation createPriceRequest($data: PriceRequestInput!) {
    createPriceRequest(data: $data) {
      id
      reference
      remark
      createdAt
      internalRemark
    }
  }
`;

export const UPDATE_PRICE_REQUEST = gql`
  mutation updatePriceRequest($id: ID!, $data: PriceRequestUpdate!){
    updatePriceRequest(id: $id, data: $data){
      remark
      internalRemark
      isValidated
      isDone
      status
    }
  }
`;

export const DELETE_PRICE_REQUEST = gql`
  mutation deletePriceRequest($id: ID!){
    deletePriceRequest(id: $id)
  }
`;

/* Supply List */
export const ASSIGN_SUPPLY_LISTS_TO_PRICE_REQUEST = gql`
  mutation assignSupplyListsToPriceRequest($priceRequestId: ID!, $supplyListIds: [ID!]!){
    assignSupplyListsToPriceRequest(priceRequestId: $priceRequestId, supplyListIds: $supplyListIds)
  }
`;

export const FREE_SUPPLY_LIST_FROM_PRICE_REQUEST = gql`
  mutation freeSupplyListFromPriceRequest($supplyListId: ID!){
    freeSupplyListFromPriceRequest(supplyListId: $supplyListId)
  }
`;

/* Price Request Elements */
export const GET_PRICE_REQUEST_ELEMENTS = gql`
  query getPriceRequestElements($filter: PriceRequestElementFilter!){
    priceRequestElements(filter: $filter){
      id
      remark
      quantity
      weight
      supplyListElement {
        denomination
        quantityUnit
        thickness
        length
        width
        matterRef
        supplyCategoryId
      }
      amalgamGroup {
        reference
        matterRef
        isBlack
        isBlasted
        isPrimaryBlasted
        format
        isCut
        isEn1090
        elementId
        matterId
        supplyCategoryId
      }
      supplierOfferElements {
        id
        price
        deliveryDate
        isSelected
        selectedQuantity
        supplierOfferId
      }
      parentSupplyCategory{
        id
        name
      }
      options{
        id
        type
        denomination
        quantity
        unit
      }
    }
  }
`;

export const GET_PRICE_REQUEST_ELEMENTS_AND_SUPPLIERS = gql`
  query getPriceRequestElementsAndSuppliers($id: ID!){
    priceRequest(id: $id){
      id
      elements {
        id
        remark
        quantity
        weight
        stockQuantity
        purchaseOrderQuantity
        hasPrice
        unit
        supplyListElement {
          denomination
          supplyCategoryId
          matterRef
          thickness
          length
          width
          quantityUnit
          basicQuantityUnit
        }
        amalgamGroup {
          reference
          matterRef
          isBlack
          isBlasted
          isPrimaryBlasted
          format
          isCut
          isEn1090
        }
        supplierOfferElements {
          id
          price
          deliveryDate
          isSelected
          selectedQuantity
          supplierOfferId
          variantId
          unit
          variant {
            id
            reference
            denomination
            matterRef
            quantity
            weight
            purchaseOrderQuantity
            format
            isBlack
            isBlasted
            isPrimaryBlasted
            isCut
            isEn1090
            quantityUnit
            thickness
            length
            width
            remark
            supplyCategoryId
            matterId
            elementId
            options {
              id
              type
              denomination
              quantity
              price
              unit
            }
          }
          computedPrice
        }
        bestPrice
        bestTime
        parentSupplyCategory{
          id
          name
        }
        options{
          id
          type
          denomination
          quantity
          unit
          bestPrice
          supplierOfferElementOptions{
            id
            price
            supplierOfferElementId
            supplierOfferElement {
              supplierOfferId
            }
          }
        }
      }
      additionnalCosts {
        id
        type
        denomination
        quantity
        unit
        bestPrice
        supplierOfferAdditionnalCosts {
          id
          price
          denomination
          quantity
          unit
          supplierOfferId
        }
      }
      supplierOffers {
        id
        supplierId
        supplier{
          code
          name
        }
        totalPrice
        additionnalCosts {
          id
          price
        }
        totalAdditionnalCosts
      }
    }
  }
`;

export const UPDATE_PRICE_REQUEST_ELEMENT = gql`
  mutation updatePriceRequestElement($id: ID!, $data: PriceRequestElementUpdate!) {
    updatePriceRequestElement(id: $id, data: $data){
      id
      remark
    }
  }
`;

export const CREATE_PRICE_REQUEST_ELEMENT_OPTIONS = gql`
  mutation createPriceRequestElementOptions($data: PriceRequestElementOptionInput!) {
    createPriceRequestElementOptions(data: $data){
      id
    }
  }
`;

export const UPDATE_PRICE_REQUEST_ELEMENT_OPTION = gql`
  mutation updatePriceRequestElementOption($id: ID!, $data: PriceRequestElementOptionUpdate!) {
    updatePriceRequestElementOption(id: $id, data: $data){
      id
    }
  }
`;

export const DELETE_PRICE_REQUEST_ELEMENT_OPTION = gql`
  mutation deletePriceRequestElementOption($id: ID!) {
    deletePriceRequestElementOption(id: $id)
  }
`;

export const UPDATE_PRICE_REQUEST_ADDITIONNAL_COST = gql`
  mutation updatePriceRequestAdditionnalCost($id: ID!, $data: PriceRequestAdditionnalCostUpdate!) {
    updatePriceRequestAdditionnalCost(id: $id, data: $data){
      id
      type
      denomination
      quantity
      unit
    }
  }
`;

export const DELETE_PRICE_REQUEST_ADDITIONNAL_COST = gql`
  mutation deletePriceRequestAdditionnalCost($id: ID!){
    deletePriceRequestAdditionnalCost(id: $id)
  }
`;