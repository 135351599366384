import { Inject, OnInit, OnDestroy } from "@angular/core";
import { CatalogQueriesService } from "app/facade/queries/catalog/async/catalog_queries.service";
import { CustomElementService } from "app/facade/quote-element-definition/elements/customs/custom.service";
import { QuotesQueriesService } from "app/facade/queries/quote/async/quotes_queries.service";
import { FormBuilder, Validators, FormGroup } from "@angular/forms";
import { StepFormService, CONTAINER_DATA, ActualData } from "app/presentationnal/organisms/wizard-modal/service/step-form.service";
import { Matter } from "app/facade/interfaces";
import { ISelectOption } from "app/presentationnal/atoms/inputs/select-input/selectOptions";
import { Subscription } from "rxjs";

export type CustomAvailable =
    "Embase" |
    "Gousset" |
    "Nervure" |
    "Plaque" |
    "Raidisseur";

export abstract class StepZeroBase implements OnInit, OnDestroy {
  public stepZeroForm: FormGroup;
  public availableMatter: any[] = [];
  private _indexElement: number;
  public dataTicket: {element: any, children: any[]};
  protected actualData: ActualData;
  protected customData: any;
  public useClass: CustomAvailable;
  private _clickElement: any;
  public imgPath: string;
  public matterId: number;
  public errorForm: boolean = false;
  private _thicknessSub: Subscription;
  public optionsThickness: ISelectOption[] = [];
  public autoSelectData: boolean;


  protected formContent: any;

  constructor(@Inject(CONTAINER_DATA) data: any,
              protected _stepFormSrv: StepFormService,
              protected _catalogQueriesSrv: CatalogQueriesService,
              protected _customElementSrv: CustomElementService,
              protected _quoteQueriesSrv: QuotesQueriesService,
              protected _fb: FormBuilder) {
                this._indexElement = this._stepFormSrv.getLastElementSelected();
                this.dataTicket = data.ticket[this._indexElement];
                this.useClass = data.useClass;
                this.actualData = this._stepFormSrv.get();
                this.imgPath = "../../../../../../../../assets/img/wizard/" + this.useClass.toLowerCase() + "/step_one.jpg";
                if (this.actualData.isEdited) {
                  this.customData = this.actualData.custom;
                  this.dataTicket = data.ticket[this.actualData.index];
                }
                this.autoSelectData = this.actualData.custom !== undefined;
  }

  ngOnInit(): void {
    this.initForm();
    this.getMatterByElementGroupId();
  }

  protected initForm() {
    this.generateFormContent();
    this.stepZeroForm = this._fb.group(this.formContent);
  }

  ngOnDestroy(): void {
    if (this._thicknessSub) {
      this._thicknessSub.unsubscribe();
    }
  }

  protected generateFormContent() {
    const data: any = this.actualData.custom ? this.actualData.custom : null,
          properties: any = data ? data.properties : null,
          cutting: any = data && data.AdditionalComputings ? data.AdditionalComputings.cut : null,
          welding: any = data && data.AdditionalComputings ? data.AdditionalComputings.welding : null;
    this.formContent = {
      denomination: [data ? data.CustomName : this.useClass, Validators.required],
      quantity: [data ? data.Quantity : 1, Validators.compose([
        Validators.required,
        Validators.min(1)
      ])],
      matter: [null, Validators.required],
      thicknessMetal: [properties ? properties.thickness : null, Validators.required],
      heightMetal: [properties ? properties.height : null, Validators.required],
      widthMetal: [properties ? properties.width : null, Validators.required],
      priceMatter: [properties ? properties.matter.pricePerKg : null, Validators.required],
      weightMatter: [properties ? properties.matter.kgByLiter : null, Validators.required],
      quantityDrilling: cutting && cutting.origin ? cutting.origin.holeQuantity : null,
      diameter: cutting && cutting.origin ? cutting.origin.holeDiameter : null,
      weldSection: welding && welding.origin ? welding.origin.WeldingSection : null,
      WeldingPercentage: welding && welding.origin ? welding.origin.WeldingPercentage : 100
    };
  }

  protected getMatterByElementGroupId() {
    this._catalogQueriesSrv.getMatter(true).subscribe(result => {
      this.availableMatter = result.data["availableMatters"];
      this.chooseElement(this.dataTicket.element, true);
      if (this.actualData.custom) {
        this.getThickness(this.actualData.custom.properties.thickness, true);
        setTimeout( () =>
          this.stepZeroForm.get("thicknessMetal").markAsUntouched()
        , 250);
      }
    });
  }

  public getThickness(typeFilter: string = "", init: boolean = false ) {
    this._thicknessSub = this._quoteQueriesSrv.getSearchTickness( typeFilter, +this.matterId ).subscribe( result => {
      if (this.optionsThickness.length > 0) {this.optionsThickness = []; }
      const projectOption: any = result.data;
      this.optionsThickness = projectOption.searchPlateThickness.map(element => {
        return {
          label : element,
          value : element,
        };
      });
      if (init) {
        this.optionsThickness = this.optionsThickness.filter( element => element.label === typeFilter );
      }
    });
  }

  public chooseElement(choice: any, init: boolean = false) {
    this._clickElement = choice;
    this.chooseMatter(choice.properties.matter, init);
  }

  public chooseMatter(matter: Matter, init: boolean = false) {
    let indexMatter: number,
        definedMatterData: boolean = true;
    if (init && this.actualData.custom && this.actualData.custom.properties) {
      indexMatter = this.availableMatter.findIndex( q => q.id == this.actualData.custom.properties.matter.id);
      definedMatterData = false;
    } else {
      indexMatter = this.availableMatter.findIndex( q => q.name == matter.name);
    }
    if (indexMatter !== -1) {
      const matterData: Matter = this.availableMatter[indexMatter];
      this.stepZeroForm.get("matter").patchValue(matterData.name);
      if (definedMatterData) {
        this.stepZeroForm.get("priceMatter").patchValue(matterData.pricePerKg);
        this.stepZeroForm.get("weightMatter").patchValue(matterData.kgByLiter);
        this.stepZeroForm.get("matter").markAsTouched();
        this.stepZeroForm.get("priceMatter").markAsTouched();
        this.stepZeroForm.get("weightMatter").markAsTouched();
      }
      this.errorForm = false;
      this.matterId = matterData.id;
    }
  }

  public searchThickness(event: string) {
    this.getThickness(event);
  }

  // Save Actions

  public fastSave() {
    this.createNewElement(this.useClass, false);
  }

  public stepChange() {
    this.createNewElement(this.useClass);
  }

  public cancel() {
    this._stepFormSrv.closeModal$.next(true);
  }

  protected formateFormValues(): { element: any, laserCutting: any, welding: any } {
    const formValue: any = this.stepZeroForm.value;
    const data: any = this.actualData.custom && this.actualData.custom.properties ? this.actualData.custom.properties : null;
    const matterData: any = this.availableMatter.find( matter => +matter.id === +this.matterId );
    const buildCustomData: { element: any, laserCutting: any, welding: any } = {
      element: {
        denomination: formValue.denomination,
        quantity: formValue.quantity,
        elementForWelding: this._clickElement,
        matter: {
          id: +this.matterId,
          pricePerKg: formValue.priceMatter,
          kgByLiter: formValue.weightMatter,
          en1090Name: matterData ? matterData.en1090Name : "",
          name: matterData ? matterData.name : ""
        },
        thickness: formValue.thicknessMetal,
        height: formValue.heightMetal,
        width: formValue.widthMetal,
        margin: data ? data.margin : 10
      },
      laserCutting: {
        holeQuantity: formValue.quantityDrilling || 0,
        holeDiameter: formValue.diameter || 0
      },
      welding: {
        weldingSection: formValue.weldSection,
        weldingPercentage: formValue.WeldingPercentage
      }
    };

    return buildCustomData;
  }

  protected formatWithDeletedCustomData(values: any) {
    this.customData.CustomName = values.denomination;
    this.customData.Quantity = values.quantity;
    if (this.actualData.custom) {
      this.customData.Remarks = this.actualData.custom.Remarks;
      this.customData.UnitaryAdditionalCost = this.actualData.custom.UnitaryAdditionalCost;
      this.customData.GlobalAdditionalCost = this.actualData.custom.GlobalAdditionalCost;
      this.resetCuttingData();
      this.resetWeldingData();
    }
  }

  private resetCuttingData(): void {
    const attrCutting: string[] = [
      "PriceForBlueprint",
      "UnitaryAdditionalCost",
      "GlobalAdditionalCost",
      "Remarks"
    ];
    attrCutting.forEach( key => {
      this.customData.AdditionalComputings.cut.origin[key] = this.actualData.custom.AdditionalComputings.cut.origin[key];
    });
  }

  private resetWeldingData(): void {
    const attrWelding: string[] = [
      "Passes",
      "PriceByM",
      "UnitaryAdditionalCost",
      "GlobalAdditionalCost",
      "Remarks"
    ];

    attrWelding.forEach( key => {
      this.customData.AdditionalComputings.welding.origin[key] = this.actualData.custom.AdditionalComputings.welding.origin[key];
    });
  }

  protected formatWithEditedCustomData() {
    const referenceData: any = {
      "denomination" : "CustomName",
      "quantity" : "Quantity",
      "matter" : "matter",
      "thicknessMetal" : "thickness",
      "heightMetal" : "height",
      "widthMetal" : "width",
      "quantityDrilling" : "holeQuantity",
      "diameter" : "holeDiameter",
      "weldSection" : "WeldingSection",
      "WeldingPercentage" : "WeldingPercentage",
    },
      isCuttingData: string[] = ["quantityDrilling", "diameter"],
      isWeldingData: string[] = ["weldSection", "WeldingPercentage"],
      matterData: any = this.availableMatter.find( matter => +matter.id === +this.matterId ),
      matterDataToUpdate: any = {
        id: +this.matterId,
        pricePerKg: this.stepZeroForm.get("priceMatter").value,
        kgByLiter: this.stepZeroForm.get("weightMatter").value,
        en1090Name: matterData ? matterData.en1090Name : "",
        name: matterData ? matterData.name : ""
      };
    Object.keys(this.stepZeroForm.controls).forEach( control => {
      if (this.stepZeroForm.get(control).touched && referenceData[control]) {
        if (control === "matter") {
          this.customData.properties.matter = matterDataToUpdate;
        } else {
          if (isCuttingData.indexOf(control) !== -1) {
            this.customData.AdditionalComputings.cut.origin[referenceData[control]] = this.stepZeroForm.get(control).value;
          } else if (isWeldingData.indexOf(control) !== -1) {
            this.customData.AdditionalComputings.welding.origin[referenceData[control]] = this.stepZeroForm.get(control).value;
          } else {
            this.customData[referenceData[control]] = this.stepZeroForm.get(control).value;
          }
        }
      }
    });
  }

  private createNewElement(element: CustomAvailable, continueSteps: boolean = true) {
    if (this.stepZeroForm && this.stepZeroForm.valid) {
      if (this.actualData.isEdited) {
        this.formatWithEditedCustomData();
        this._stepFormSrv.updateElement(this.customData, null, this.actualData.index, this.actualData.childrenIndex);
        this.actionStepFormSrv(continueSteps, this.actualData.childrenIndex, this.actualData.isEdited);
      } else {
        const { element: values, laserCutting, welding } = this.formateFormValues();
        this._customElementSrv.create(element, element, values, { laserCutting, welding }).subscribe(cstm => {
          this.customData = cstm;
          this.formatWithDeletedCustomData(values);
          const childrenIndex = this._stepFormSrv.addCustom( this._indexElement , this.customData);
          this.actionStepFormSrv(continueSteps, childrenIndex);
        });
      }
    } else {
      this.errorForm = !this.stepZeroForm.get("matter").valid;
      this._stepFormSrv.markFormGroupTouched(this.stepZeroForm);
    }
  }

  private actionStepFormSrv(continueSteps: boolean, childrenIndex: number, isEdit: boolean = false) {
    if (continueSteps) {
      this._stepFormSrv.change({
        custom : this.customData,
        index: this._indexElement,
        childrenIndex,
        summary: [],
        isEdited: isEdit
      });
      this._stepFormSrv.navigate$.next(true);
    } else {
      this._stepFormSrv.closeModal$.next(true);
    }
  }

}