/*
 * @Author: Kevin Palade
 * @Date: 2018-09-08 14:38:12
 * @Last Modified by: Kevin Palade
 * @Last Modified time: 2018-11-16 15:24:14
 */
import { Directive, Input, ElementRef, Renderer, OnInit, OnChanges } from "@angular/core";

@Directive({
  selector: "[appTooltip]"
})
export class TooltipDirective implements OnInit, OnChanges {

  @Input("tooltipPosition") public tooltipPosition: string = "top";
  @Input("tooltipColor") public tooltipColor: string = "primary";
  @Input("appTooltip") public appTooltip: string;

  constructor(private _el: ElementRef,
              private _renderer: Renderer) { }

  /**
   * @description Apply the tooltip attributes and class on the element
   * @author Kevin Palade
   * @memberof TooltipDirective
   */
  ngOnInit() {
    this._el.nativeElement.classList.add("tooltip_position_" + this.tooltipPosition);
    this._el.nativeElement.classList.add("tooltip_color_" + this.tooltipColor);
    this._renderer.setElementAttribute(this._el.nativeElement, "data-tooltip", this.appTooltip);
  }

  ngOnChanges() {
    this._renderer.setElementAttribute(this._el.nativeElement, "data-tooltip", this.appTooltip);

  }

}
