import { Component, OnInit, Inject, Renderer2, OnDestroy } from "@angular/core";
import { StepWeldingBase } from "../../base/step-welding-base";
import { CONTAINER_DATA, StepFormService } from "app/presentationnal/organisms/wizard-modal/service/step-form.service";
import { FormBuilder } from "@angular/forms";
import { Subscription } from "rxjs";

@Component({
  selector: "app-nervure-step-three",
  templateUrl: "./nervure-step-three.component.html",
  styleUrls: ["./nervure-step-three.component.css"]
})
export class NervureStepThreeComponent extends StepWeldingBase implements OnInit, OnDestroy {

  private _behindPlateSubscription: Subscription;

  constructor(@Inject(CONTAINER_DATA) data: any,
              protected _stepFormSrv: StepFormService,
              protected renderer: Renderer2,
              protected _fb: FormBuilder) {
                super(data, _stepFormSrv, renderer, _fb);
              }

  ngOnInit() {
    super.ngOnInit();
    this._behindPlateSubscription = this.stepWeldingForm.get("behindPlate").valueChanges.subscribe(behindPlate => {
      this.actualWeldingData._element1.properties.behindPlate = behindPlate;
      const meterForWeldingWithoutPercentage = this.actualWeldingData.MeterForWelding / (this.actualWeldingData.WeldingPercentage / 100);
      this.stepWeldingForm.get("MeterForWelding").patchValue(meterForWeldingWithoutPercentage);
    });
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this._behindPlateSubscription.unsubscribe();
  }

  protected generateFormContent(): any {
    const formContent = super.generateFormContent();
    formContent.behindPlate = this.actualWeldingData._element1.properties.behindPlate ? this.actualWeldingData._element1.properties.behindPlate : null;
    return formContent;
  }

  public stepChange() {
    const isCustomProperties: string[] = [ "behindPlate" ];
    Object.keys(this.stepWeldingForm.controls).forEach(key => {
      if (this.stepWeldingForm.get(key).touched && this.stepWeldingForm.get(key).valid) {
        if (isCustomProperties.indexOf(key) !== -1) {
          this.actualData.custom[key] = this.stepWeldingForm.get(key).value;
        } else {
          this.actualData.custom.AdditionalComputings.welding.origin[key] = this.stepWeldingForm.get(key).value;
        }
      }
    });

    this._stepFormSrv.change({ custom: this.actualData.custom });
  }

}
