import { Component, OnInit, Input, EventEmitter, Output } from "@angular/core";
import { IBarsetItem } from "app/facade/interfaces/barset.interface";

@Component({
  selector: "app-barset-infos-item",
  templateUrl: "./barset-infos-item.component.html",
  styleUrls: ["./barset-infos-item.component.css"]
})
export class BarsetInfosItemComponent implements OnInit {

  @Input() public editBarsets: boolean = false;
  @Input() public barsetItem: IBarsetItem;
  @Output() public onEditBarset: EventEmitter<number> = new EventEmitter<number>();
  @Output() public onlockBarset: EventEmitter<number> = new EventEmitter<number>();
  @Output() public onUpdateStockValue: EventEmitter<number> = new EventEmitter<number>();
  constructor() { }

  ngOnInit() {
  }

  public openEditMode() {
    // avoid to save the barset already edited but not save
    if (!this.barsetItem.isEdited && !this.barsetItem.isLocked) {
      this.onEditBarset.emit(this.barsetItem.id);
    }
  }

  public updateLockedValue() {
    this.onlockBarset.emit(this.barsetItem.id);
  }

  public updateStockValue() {
    this.onUpdateStockValue.emit(this.barsetItem.id);
  }

}
