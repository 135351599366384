import gql from "graphql-tag";

export const BENDING = gql`
query getBending($params: FilterAction!) {
    getActionByParameters(
        actionGroupId: 2,
        params: $params
    ){
        id
        name
        natureValues
    }
}`;

export const CUTTING = gql`
query getCutting {
    searchAction(
        actionGroupId: 3,
        search:"C"
    ){
        id
        name
        natureValues
    }
}`;

export const DRILLING = gql`
query getDrilling($params: FilterAction!) {
    getActionByThickness(
        actionGroupId: 4,
        params: $params
    ){
        id
        name
        natureValues
    }
}`;

export const FOLDING = gql`
query getFolding($params: FilterAction!) {
    getActionByParameters(
        actionGroupId: 1,
        params: $params
    ){
        id
        name
        natureValues
    }
}`;

export const LASER_CUTTING = gql`
query getLaserCutting($params: FilterAction!) {
    getActionByThickness(
        actionGroupId: 4,
        params: $params
    ){
        id
        name
        natureValues
    }
}`;