import { Component, OnInit, OnDestroy } from "@angular/core";
import { TABLE_ITEM_ACTION_TYPE } from "app/presentationnal/organisms/table/table-item/enum/table-item-action-type.enum";
import { IPriceRequest, IPriceRequestFilter } from "app/facade/interfaces/price-request.interface";
import { TableDatasDefinition } from "app/facade/interfaces/table-datas-definition";
import { PriceRequestsQueriesService } from "app/facade/queries/price-request/price-requests-queries.service";
import { ModalService } from "app/presentationnal/organisms/modal/services/modal.service";
import { SnackService } from "app/presentationnal/organisms/snack-bar/services/snack.service";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
import * as moment from "moment";
import { ModalAddPriceRequestComponent, ModalConfirmComponent } from "app/presentationnal/organisms/modal";
import { MODAL_TYPE } from "app/presentationnal/organisms/modal/enums/modal-type.enum";
import { priceRequestStatusConfig } from "app/facade/configs/status.config";
import { MODAL_CLOSE_ACTION } from "app/presentationnal/organisms/modal/enums/modal-close-action.enum";
import { ListTableService } from "app/facade/services/list-table.service";
import { IPagination } from "app/facade/interfaces/crud.interface";
import { AuthService } from "@lib/auth/auth.service";
import { PermissionService } from "app/facade/services/permission.service";

const DEFAULT_LIMIT: number = 25;

@Component({
  selector: "app-listing-price-request",
  templateUrl: "./listing-price-request.component.html",
  styleUrls: ["./listing-price-request.component.css"]
})
export class ListingPriceRequestComponent implements OnInit, OnDestroy {
  private _listPriceRequestsSub: Subscription;
  public price_request: string = "price-requests";
  private _addPriceRequestSub: Subscription;
  private _deletePriceRequestSub: Subscription;
  private _actionsType = TABLE_ITEM_ACTION_TYPE;
  private _filterPriceRequest: IPriceRequestFilter = { search : "" };
  public priceRequests: IPriceRequest[] = [];
  private goToPurchaseOrderSearch(el) {
    const url = `/purchase-order?reference=${el.reference}`;
    window.open(url, '_blank');
  }
  public tableHeaders: string[] = [
    "Référence",
    "Date de création",
    "Créé par",
    "Projets",
    "Catégories",
    "Remarque générale",
    "Remarque interne",
    "Status",
    "B.E.",
    "Terminée",
    "actions"
  ];
  public dataDefinition: TableDatasDefinition[] = [
    { key: "reference", isTitle: true },
    { key: "createdAt", isTitle: false },
    { key: "createdBy", isTitle: false },
    { key: "projects", isTitle: false },
    { key: "categories", isTitle: false },
    { key: "remark", isTitle: false },
    { key: "internalRemark", isTitle: false },
    { key: "status", isTitle: false, isStatus: true, statusConfig: priceRequestStatusConfig, statusAction: this.goToPurchaseOrderSearch },
    { key: "isValidated", isTitle: false, isCheckBox: true },
    { key: "isDone", isTitle: false, isCheckBox: true },
  ];
  private _pagination: IPagination = { page: 1, limit: DEFAULT_LIMIT };
  public userGroup: any;
  public isEdit = false;
  public isDelete = false;

  constructor(
    private _priceRequestsQueriesSrv: PriceRequestsQueriesService,
    private _modalService: ModalService,
    private _snackBar: SnackService,
    private _listTableSrv: ListTableService,
    private _router: Router,
    private _authSrv: AuthService,
    private _permissionService: PermissionService
  ) { }

  ngOnInit() {
    this.userGroup =  this._authSrv.isUserGroup();
    this._permissionService.getAndUpdatePermission(this.userGroup);

    let savedSearch = JSON.parse(localStorage.getItem("dataPriceRecherche"));
    savedSearch = savedSearch && savedSearch.recherche.length > 0 ? savedSearch.recherche : "";
    if (savedSearch.length > 0) {
      this._snackBar.open("Liste filtrée", "Cette liste est filtrée suivant votre recherche", "info", 5000);
      this._filterPriceRequest.search = savedSearch;
    }
    let savedPagination = JSON.parse(localStorage.getItem("dataPricePagination"));
    savedPagination = savedPagination && savedPagination.pagination > 1 ? savedPagination.pagination : 1;
    if (savedPagination > 1) {
      this._pagination.limit = DEFAULT_LIMIT * savedPagination;
      this.getPriceRequestList(true);
      this._pagination = { page: savedPagination, limit: DEFAULT_LIMIT };
    } else {
      this.getPriceRequestList(true);
    }

    this.isEdit = this._permissionService.hasPermission(this.userGroup, "price-requests", "write");
    this.isDelete = this._permissionService.hasPermission(this.userGroup, "price-requests", "delete");
  }

  ngOnDestroy(): void {
    this._listPriceRequestsSub.unsubscribe();
    if (this._addPriceRequestSub) { this._addPriceRequestSub.unsubscribe(); }
  }

  private getPriceRequestList(forceFirstPage: boolean = false) {
    if ( this._listPriceRequestsSub ) { this._listPriceRequestsSub.unsubscribe(); }
    this._listPriceRequestsSub = this._priceRequestsQueriesSrv.getPriceRequestListing(this._filterPriceRequest, this._pagination).subscribe( result => {
      const data: any = result.data;
      if ( data && data.priceRequests ) {
        this.priceRequests = !forceFirstPage && this._pagination.page !== 1 ?
          [ ...this.priceRequests, ...this.mapPriceRequests(data.priceRequests) ] :
          this.mapPriceRequests(data.priceRequests);
      }
      if (data && data.pagination) {
        this._listTableSrv.setLoadMoreStatus(data.pagination.hasNext);
      }
    }, error => {
      console.log("LOADING PRICE REQUESTS ERROR", {error});
    });
  }

  /**
   * @description Map PriceRequest for listing display
   * @author Quentin Wolfs
   * @private
   * @param {any[]} priceRequests
   * @returns {IPriceRequest[]}
   * @memberof ListingPriceRequestComponent
   */
  private mapPriceRequests(priceRequests: any[]): IPriceRequest[] {
    let categories: Set<string>;
    return priceRequests.map( priceRequest => {
      categories = new Set<string>([]);
      priceRequest.supplyLists.map( suppList => suppList.parentSupplyCategories.map( cat => cat.name)).flat()
      .forEach( catName => {
        categories.add(catName);
      });
      return {
        ...priceRequest,
        projects: priceRequest.linkedProjects ?
          priceRequest.linkedProjects.map(project => project.reference).join(", ") : "",
        createdAt: moment.unix(priceRequest.createdAt).format("DD/MM/YYYY"),
        createdBy: `${priceRequest.user.lastname} ${priceRequest.user.firstname}`,
        categories: Array.from(categories).join( " - " )
      };
    });
  }

  public filterPriceRequest($event: any) {
    localStorage.setItem("dataPriceRecherche", JSON.stringify({"recherche": $event}));
    localStorage.setItem("dataPricePagination", JSON.stringify({"pagination": 1}));
    this._filterPriceRequest.search = $event;
    this._pagination.page = 1;
    this.getPriceRequestList();
  }

  public addPriceRequest() {
    const modalTitle: string = "Création d'une demande de prix";
    const modalRef = this._modalService.openModal(ModalAddPriceRequestComponent, {
      title: modalTitle,
      type: MODAL_TYPE.NORMAL,
      data: null,
      closeAction: MODAL_CLOSE_ACTION.SAVE
    });
    if (modalRef) {
      modalRef.afterClosed.subscribe(res => {
        if (res.confirm && res.data) {
          this._router.navigate([`${this._router.url}/edit/${res.data.id}`]);
        }
      });
    }
  }

  public tableButtonClicked($event: { actionType: number, itemId: number}) {
    switch (this._actionsType[$event.actionType]) {
      case "DELETE":
        this.beforeDeletePriceRequest($event.itemId);
        break;
      case "EDIT":
        this._router.navigate([`${this._router.url}/edit/${$event.itemId}`]);
        break;
      default:
        break;
    }
  }

  private beforeDeletePriceRequest(priceRequestId: number) {
    const item = this.priceRequests.find(data => +data.id === +priceRequestId);
    this._modalService.openModal(ModalConfirmComponent,
      {
        title: "Êtes-vous sûr?",
        type: MODAL_TYPE.CONFIRM,
        data: {
          message: `Voulez-vous supprimer la demande de prix <b>${item.reference}</b>?`,
          actions: [
            "Supprimer la demande de prix de manière définitive",
            "Libérer les listes d'approvisionnement liées à cette demande de prix"
          ],
        },
        params: { priceRequestId },
        confirmCallback: this.deletePriceRequest
      }
    );
  }

  public deletePriceRequest = (params: { priceRequestId: string|number }) => {
    const snackBarTitle: string = "Supprimer une demande de prix";
    if (this._deletePriceRequestSub) { this._deletePriceRequestSub.unsubscribe(); }
    this._deletePriceRequestSub = this._priceRequestsQueriesSrv.deletePriceRequest(+params.priceRequestId).subscribe(result => {
      if (result.data["deletePriceRequest"]) {
        const index = this.priceRequests.findIndex(project => project.id == params.priceRequestId);
        this.priceRequests = [...this.priceRequests.slice(0, index), ...this.priceRequests.slice(index + 1)];
        this._snackBar.open(snackBarTitle, "La demande de prix a été supprimée", "success", 5000);
      } else {
        this._snackBar.openSnackBarError(snackBarTitle, "La demande de prix n'a pas été supprimée");
      }
    }, error => {
      this._snackBar.openSnackBarError(snackBarTitle, "La demande de prix n'a pas été supprimée", error);
    });
  }

  public loadMore(event: boolean) {
    this._pagination.page++;
    localStorage.setItem("dataPricePagination", JSON.stringify({"pagination": this._pagination.page}));
    this.getPriceRequestList();
  }
}
