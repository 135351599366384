import { OnInit, Inject, OnDestroy } from "@angular/core";
import { CONTAINER_DATA, StepFormService, ActualData } from "app/presentationnal/organisms/wizard-modal/service/step-form.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Subscription } from "rxjs";
import { LaserCutFormulaDisplayer } from "app/facade/quote-element-definition/formula.display";


export abstract class StepLaserCuttingBase implements OnInit, OnDestroy {
  public stepLaserCuttingForm: FormGroup;
  public imgPath;
  public useClass;
  private _actualData: ActualData;
  private _actualCutData: any;
  private _formulaDisplayer: LaserCutFormulaDisplayer;
  private _formulaSub: Subscription;
  public calculs: string[] = [];

  constructor(@Inject(CONTAINER_DATA) data: any,
              protected _stepFormSrv: StepFormService,
              protected _fb: FormBuilder) {
                this.useClass = data.useClass;
                this.imgPath = "../../../../../../../../assets/img/wizard/" + this.useClass.toLowerCase() + "/step_two.jpg";
                this._actualData = this._stepFormSrv.get();
                this._actualCutData = this._actualData.custom.AdditionalComputings.cut.origin;
   }

   ngOnInit() {
    this.initForm();
    this._formulaDisplayer = new LaserCutFormulaDisplayer();
    this._formulaSub = this._formulaDisplayer.$laserCuttingPrice.subscribe(calculs => this.calculs = calculs);
    this.showCalcul();
  }

  ngOnDestroy() {
    this._formulaSub.unsubscribe();
  }

  private initForm() {
    this.stepLaserCuttingForm = this._fb.group({
      meterForCutting: [this._actualCutData.meterForCutting, Validators.required],
      holeQuantity: this._actualCutData.holeQuantity,
      holeDiameter: this._actualCutData.holeDiameter,
      Prc: [this._actualCutData.Prc , Validators.required],
      PriceByM: [this._actualCutData.PriceByM , Validators.required],
      margin: [this._actualData.custom.properties.margin , Validators.required],
      PriceForBlueprint: [this._actualCutData.PriceForBlueprint , Validators.required],
      UnitaryAdditionalCost: this._actualCutData.UnitaryAdditionalCost,
      GlobalAdditionalCost: this._actualCutData.GlobalAdditionalCost,
      Remarks: this._actualCutData.Remarks
    });
  }

  public next(fastSave: boolean = false) {
    if (this.stepLaserCuttingForm && this.stepLaserCuttingForm.valid) {
      this.stepChange();
      this._stepFormSrv.updateElement(this._actualData.custom, null, this._actualData.index, this._actualData.childrenIndex);
      if (fastSave) {
        this._stepFormSrv.closeModal$.next(true);
      } else {
        this._stepFormSrv.navigate$.next(true);
      }
    } else {
      this._stepFormSrv.markFormGroupTouched(this.stepLaserCuttingForm);
    }
  }

  public stepChange() {
    const isCustomProperties: string[] = [ "margin" ];
    Object.keys(this.stepLaserCuttingForm.controls).map( key => {
      if (this.stepLaserCuttingForm.get(key).touched && this.stepLaserCuttingForm.get(key).valid) {
        if (isCustomProperties.indexOf(key) !== -1) {
          this._actualData.custom[key] = this.stepLaserCuttingForm.get(key).value;
        } else {
          this._actualData.custom.AdditionalComputings.cut.origin[key] = this.stepLaserCuttingForm.get(key).value;
        }
      }
    });
    this._stepFormSrv.change({ custom: this._actualData.custom });
  }

  public previous() {
    this.stepChange();
    this._stepFormSrv.navigate$.next(false);
  }

  public cancel() {
    if (!this._actualData.isEdited) {
      this._stepFormSrv.deleteElement(this._actualData.index, this._actualData.childrenIndex);
    }
    this._stepFormSrv.closeModal$.next(true);
  }

  private showCalcul() {
    this.stepLaserCuttingForm.valueChanges.subscribe(values => {
      const base = {
        meterForCutting: values.meterForCutting,
        priceByM: values.PriceByM,
        holeQuantity: values.holeQuantity || 0,
        holeDiameter: values.holeDiameter || 0,
        amorcePrice: values.Prc,
        drawing: values.PriceForBlueprint,
        quantity: this._actualData.custom.Quantity
      };

      if (Object.keys(base).every(k => base[k] !== undefined && base[k] !== null)) {
        this._formulaDisplayer.base = base;
      }
    });

    const init = {
      meterForCutting: this.stepLaserCuttingForm.value.meterForCutting,
      priceByM: this.stepLaserCuttingForm.value.PriceByM,
      holeQuantity: this.stepLaserCuttingForm.value.holeQuantity || 0,
      holeDiameter: this.stepLaserCuttingForm.value.holeDiameter || 0,
      amorcePrice: this.stepLaserCuttingForm.value.Prc,
      drawing: this.stepLaserCuttingForm.value.PriceForBlueprint,
      quantity: this._actualData.custom.Quantity
    };

    if (Object.keys(init).every(k => init[k] !== undefined && init[k] !== null)) {
      this._formulaDisplayer.base = init;
    }
  }
}
