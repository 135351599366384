/*
 * @Author: Kevin Palade
 * @Date: 2018-09-08 14:47:19
 * @Last Modified by: Kevin Palade
 * @Last Modified time: 2018-09-21 13:31:09
 */
import { Component, Inject } from "@angular/core";
import { ModalContent } from "../../model/modal-content";
import { ModalService, DATA } from "../../services/modal.service";
import { IModalData } from "../../interfaces/modal-data.interface";

@Component({
  selector: "app-modal-confirm",
  templateUrl: "./modal-confirm.component.html",
  styleUrls: ["./modal-confirm.component.css"]
})
export class ModalConfirmComponent extends ModalContent {

  constructor(protected _modalService: ModalService,
              @Inject(DATA) private _data: IModalData) {
    super(_modalService);
  }

  public confirmModal() {
    if ( this._data && this._data.confirmCallback) {
      this._data.confirmCallback(this._data.params);
    }
    this._modalService.closeModal();
  }

  public cancelModal() {
    if ( this._data && this._data.cancelCallback) {
      this._data.cancelCallback(this._data.params);
    }
    this._modalService.closeModal();
  }


}
