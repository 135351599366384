import { Component, OnInit, Inject, OnDestroy } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { StepFormService, CONTAINER_DATA, ActualData } from "app/presentationnal/organisms/wizard-modal/service/step-form.service";
import { OptionService } from "app/facade/quote-element-definition/options/options.service";
import { Montage, MontageProperty } from "app/facade/quote-element-definition/options/montage";
import { OptionFormulaDisplayer, MountingFormulaDisplayer } from "app/facade/quote-element-definition/formula.display";
import { Subscription } from "rxjs";
import { debounceTime } from "rxjs/operators";

@Component({
  selector: "app-mounting-step-one",
  templateUrl: "./mounting-step-one.component.html",
  styleUrls: ["./mounting-step-one.component.css"]
})
export class MountingStepOneComponent implements OnInit, OnDestroy {
  public isKg = false;
  private mountingStepOneForm: FormGroup;
  public newMounting: Montage;
  private _actualData: ActualData;
  private _optionData: Montage;
  private _formulaDisplayer: OptionFormulaDisplayer | MountingFormulaDisplayer;
  private _formulaSub: Subscription;
  public calculs: string[];

  constructor(@Inject(CONTAINER_DATA) data: any,
    private _fb: FormBuilder,
    private _stepFormSrv: StepFormService,
    private _optionSrv: OptionService) {
    this._actualData = this._stepFormSrv.get();
    this._optionData = this._actualData.element;
  }

  ngOnInit() {
    this.createInitObject();
    this.initform();
    this.calculs = [];
    this._formulaDisplayer = this.isKg ? new MountingFormulaDisplayer : new OptionFormulaDisplayer();
    this._formulaSub = this._formulaDisplayer.$optionPrice.subscribe(calculs => this.calculs = calculs);
    this.showCalcul();
  }

  public ngOnDestroy() {
    this._formulaSub.unsubscribe();
  }

  public initform() {
    this.mountingStepOneForm = this._fb.group({
      personQuantity: [this.newMounting ? this.newMounting.PersonQuantity : 1, Validators.compose([Validators.required, Validators.min(1)])],
      dayQuantity: [this.newMounting ? this.newMounting.DayQuantity : 1, Validators.compose([Validators.required, Validators.min(0)])],
      hourlyRate: [this.newMounting ? this.newMounting.HourlyRate : 1, Validators.compose([Validators.required, Validators.min(0)])],
      weightCost: [this.newMounting ? this.newMounting.WeightCost : 1, Validators.compose([Validators.required, Validators.min(0)])],
      totalWeight: [this.newMounting ? this.newMounting.totalWeight : null, Validators.compose([Validators.required])],
      isComputedByWeight: [this.newMounting ? this.newMounting.IsComputedByWeight : false, Validators.compose([Validators.required])]
    });
    this.isKg = this.newMounting ? this.newMounting.IsComputedByWeight : false;

    this.mountingStepOneForm.get("isComputedByWeight").valueChanges.subscribe(result => {
      this.isKg = result;
      this.changeFormulaDisplayer();
    });
  }

  private changeFormulaDisplayer() {
    this._formulaSub.unsubscribe();
    this._formulaDisplayer = this.isKg ? new MountingFormulaDisplayer() : new OptionFormulaDisplayer();
    this._formulaSub = this._formulaDisplayer.$optionPrice.subscribe(calculs => this.calculs = calculs);
  }

  public save() {
    if (this.mountingStepOneForm && this.mountingStepOneForm.valid) {
      this.newMounting.DayQuantity = this.mountingStepOneForm.get("dayQuantity").value;
      this.newMounting.PersonQuantity = this.mountingStepOneForm.get("personQuantity").value;
      this.newMounting.HourlyRate = this.mountingStepOneForm.get("hourlyRate").value;
      this.newMounting.WeightCost = this.mountingStepOneForm.get("weightCost").value;
      this.newMounting.IsComputedByWeight = this.mountingStepOneForm.get("isComputedByWeight").value;
      this.newMounting.updateWeight(this._stepFormSrv.TicketData);

      if (this._actualData.isEdited) {
        this._stepFormSrv.updateElement(this.newMounting, null, this._actualData.index);
      } else {
        this._stepFormSrv.addElement(this.newMounting);
      }
    }
    this._stepFormSrv.closeModal$.next(true);
  }

  public cancel() {
    this._stepFormSrv.closeModal$.next(true);
  }

  public createInitObject() {
    if (this._actualData.isEdited) {
      this.newMounting = this._optionData;
      this.isKg = this.newMounting.IsComputedByWeight;
    } else {
      const mountingData: MontageProperty = {
        personQuantity: 1,
        dayQuantity: 1,
        hourlyRate: 1,
        weightCost: 1,
        isComputedByWeight: false,
      };
      this.newMounting = this._optionSrv.create("Montage", mountingData);
      this.newMounting.CustomName = "Montage";
    }
    this.newMounting.updateWeight(this._stepFormSrv.TicketData);
  }

  public showCalcul() {
    this.mountingStepOneForm.valueChanges.pipe(debounceTime(100)).subscribe(values => {
      const base = this.defineBaseFormulaDisplayer(values, this._formulaDisplayer instanceof MountingFormulaDisplayer);
      if (Object.keys(base).every(k => base[k])) {
        this._formulaDisplayer.base = base;
      }
    });

    const isKg: boolean = this._formulaDisplayer instanceof MountingFormulaDisplayer;
    const init = this.defineBaseFormulaDisplayer(this.mountingStepOneForm.value, isKg);
    if (Object.keys(init).every(k => init[k] !== undefined && init[k] !== null)) {
      this._formulaDisplayer.base = init;
    }
  }

  private defineBaseFormulaDisplayer(values: any, isMountingFormulaDisplayer: boolean): any {
    const base = isMountingFormulaDisplayer ?
      {
        pricePerKg: values.weightCost || null,
        totalWeight: values.totalWeight || null
      } :
      {
        personQuantity: values.personQuantity || null,
        dayQuantity: values.dayQuantity || null,
        hourlyRate: values.hourlyRate || null
      };

    return base;
  }
}
