import gql from "graphql-tag";

export const ADD_SCAN_PDF = gql`mutation($data:ScanPdfInput!){
  addScanpdf(data:$data){
    id
    name
    url
    purchaseOrderId
  }
}`;

export const EDIT_SCAN_PDF = gql`mutation($id:Int!, $data:EditScanPdfInput!){
  editScanpdf(id:$id, data:$data){
    id
    name
    url
    comment
    purchaseOrderId
  }
}`;

export const GET_SCAN_PDF = gql`
query($purchaseOrderId:Int!){
  getScanPdf(purchaseOrderId:$purchaseOrderId){
    id
    name
    url
    comment
    createdAt
  }
}`;


export const DELETE_SCAN_PDF = gql`
  mutation deleteScanPdf($id: ID!){
    deleteScanPdf(id: $id)
  }
`;


export const GET_ONE_SCAN_PDF = gql`
query($id:Int!){
  getOneScanPdf(id:$id){
    id
    name
    url
    comment
    createdAt
  }
}`;
