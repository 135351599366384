import { Injectable } from "@angular/core";
import { Apollo } from "apollo-angular";
import { WELDING } from "./assemblage.queries";
import { IWeldiable, IWeldiableMeterable, WeldingProperty } from "./welding";
import { Assemblage } from "./assemblage.base";
import { ASSEMBLAGES_MAP } from "../use-class-map";
import { map } from "rxjs/operators";
import { Observable } from "rxjs";

type AssemblageAvailable =
    "Welding";

type AssemblageBaseBuild<T extends AssemblageAvailable> =
    T extends "Welding" ? IWeldiableMeterable : never;

type AssemblageJoinBuild<T extends AssemblageAvailable> =
    T extends "Welding" ? IWeldiable : never;

type AssemblageData<T extends AssemblageAvailable> =
    T extends "Welding" ? WeldingProperty : never;

const QUERY_MAP: Map<AssemblageAvailable, any> = new Map<AssemblageAvailable, any>([
    [ "Welding", WELDING ]
]);

export type AssemblageCreate<V> = () => Observable<V>;

@Injectable()
export class AssemblageService {

    public constructor(
        private _apollo: Apollo
    ) {}

    public create<T extends AssemblageAvailable>(assemblage: T, base: AssemblageBaseBuild<T>, joined: AssemblageJoinBuild<T>, assemblageData?: AssemblageData<T>) {
        const query: any = QUERY_MAP.get(assemblage);
        const variables = { params: {} };
        const ctor: new (element1: any, element2: any, assemblageCreate: AssemblageCreate<any>, properties: any) => Assemblage<any, any> =
            ASSEMBLAGES_MAP[assemblage];

        const assemblageCreate: AssemblageCreate<any> = () => {
            return this._apollo.query<any>({ query, variables }).pipe(
                map(data => data.data.getActionByParameters.natureValues)
            );
        };
        const additionnalData: string[] = ["GlobalAdditionalCost", "UnitaryAdditionalCost", "Remarks"],
              tempAdditionnal: any = {};
        if (assemblageData) {
          additionnalData.forEach( dataName => {
            if (assemblageData.hasOwnProperty(dataName)) {
              tempAdditionnal[dataName] = assemblageData[dataName];
              delete assemblageData[dataName];
            }
          });
        }

        const newAssemblage = new ctor(base, joined, assemblageCreate, assemblageData || {});

        return newAssemblage.$ready.pipe(map(done => {
          Object.keys(tempAdditionnal).forEach( key => {
            newAssemblage[key] = tempAdditionnal[key];
          });
          return newAssemblage;
        }));
    }
}