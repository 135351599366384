import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-empty-quote-svg",
  templateUrl: "./empty-quote-svg.component.html",
  styleUrls: ["./empty-quote-svg.component.css"]
})
export class EmptyQuoteSvgComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
