import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OrganismsModule } from '../../../presentationnal/organisms/organisms.module';
import { TabpermissionManagementComponent } from './tabpermission-management/tabpermission-management.component';


@NgModule({
  imports: [
    CommonModule,
    OrganismsModule
  ],
  declarations: [
    TabpermissionManagementComponent
  ]
})
export class PermissionModule { }
