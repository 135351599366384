import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { OrganismsModule } from "app/presentationnal/organisms/organisms.module";
import { ElementExcelUploadComponent } from "./element-excel-upload/element-excel-upload.component";

@NgModule({
  imports: [
    CommonModule,
    OrganismsModule
  ],
  declarations: [
    ElementExcelUploadComponent,
  ]
})
export class BiomesModule { }
