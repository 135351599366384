import { Component, OnInit, Inject } from "@angular/core";
import { ModalService, DATA } from "../../services/modal.service";
import { ModalContentForm } from "../../model/modal-content-form";
import { SnackService } from "app/presentationnal/organisms/snack-bar/services/snack.service";
import { FormBuilder, FormArray } from "@angular/forms";
import { IPriceRequestElement, IAmalgamGroup } from "app/facade/interfaces/price-request.interface";
import { IAvailableMatters, ISupplyCategory } from "app/facade/interfaces/supplier";
import { AmalgamService } from "app/facade/services/amalgam.service";
import { ITableSelection } from "app/facade/interfaces/table-datas-definition";
import { ISupplierOfferElementAssociation, IPossiblePriceRequestElement } from "app/facade/interfaces/supplier-offer";
import { ElementUnitConfig } from "app/facade/configs/price-request-unit.config";
import { ElementUnitCategory, EnumQuantityUnitSelectValue } from "app/facade/enums/element-unit-category.enum";
import { ISupplyListElement } from "app/facade/interfaces/project";

interface IModalUpdateSupplierOfferElementsDatas {
  supplierOfferId: number;
  priceRequestElements: IPriceRequestElement[];
  possibleElements: IPossiblePriceRequestElement[];
  supplyCategories: ISupplyCategory[];
}

interface IElementListSelectionTemp extends ITableSelection {
  tableDatas: any[];
}

enum ElementType {
  AMALGAM,
  PLATE,
  DEFAULT
}

@Component({
  selector: "app-modal-update-supplier-offer-elements-list",
  templateUrl: "./modal-update-supplier-offer-elements-list.component.html",
  styleUrls: ["./modal-update-supplier-offer-elements-list.component.css"]
})
export class ModalUpdateSupplierOfferElementsListComponent extends ModalContentForm implements OnInit {
  private _supplierOfferId: number;
  private _priceRequestElements: IPriceRequestElement[];
  private _supplierOfferElements: IPossiblePriceRequestElement[];
  private _supplyCategoriesIds: number[];
  private _elementAlreadySelected: number[];
  private _elementAssociation: ISupplierOfferElementAssociation;
  public elementListDatas: IElementListSelectionTemp;

  constructor(
    protected _modalService: ModalService,
    protected _snackBar: SnackService,
    protected _fb: FormBuilder,
    @Inject(DATA) private _data: {data: IModalUpdateSupplierOfferElementsDatas},
    private _amalgamSrv: AmalgamService) {
      super(_modalService, _fb, _snackBar);
      this._supplierOfferId = this._data.data.supplierOfferId;
      this._priceRequestElements = this._data.data.priceRequestElements;
      this._supplierOfferElements = this._data.data.possibleElements;
      this._supplyCategoriesIds = this._data.data.supplyCategories.map( suppCat => suppCat.id);
      this._elementAlreadySelected = this._data.data.possibleElements.map( element => element.id);
  }

  ngOnInit() {
    this.formatPriceRequestELementDatas();
  }

  private formatPriceRequestELementDatas() {

    this.elementListDatas = {
      tableHeaders: ["", "Quantité", "Unité", "Dénomination", "Matière", "Option", "Format", "EN1090", "Remarque", "Poids"],
      checkboxDatas: [],
      tableDatas: [],
      tableDatasDefinition: [
        { key: "quantity", isTitle: false },
        { key: "quantityUnit", isTitle: false },
        { key: "denomination", isTitle: true },
        { key: "matterRef", isTitle: false },
        { key: "option", isTitle: false },
        { key: "format", isTitle: false },
        { key: "isEn1090", isTitle: false, isCheckBox: true },
        { key: "remark", isTitle: false },
        { key: "weight", isTitle: false },
      ]
    };
    let datas: any,
        amalgamGroup: IAmalgamGroup,
        supplyListElement: ISupplyListElement,
        isDefaultSupply: boolean;
    this._priceRequestElements.forEach( element => {
      const foundPossible = this._supplierOfferElements.find(possibleElement => element.id == possibleElement.id);
      datas = {
        id: element.id,
        quantity: element.quantity,
        remark: element.remark,
        weight: element.weight,
        optionsList: element.options.map( option => {
          return {
            quantity: option.quantity,
            type: option.type,
            denomination: option.denomination,
            unit: option.unit
          };
        })
      };
      const type: ElementType = this.getDisplayedElementType(element);
      switch (type) {
        case ElementType.AMALGAM:
          amalgamGroup = element.amalgamGroup;
          datas = {
            ...datas,
            denomination: amalgamGroup.reference,
            matterRef: amalgamGroup.matterRef,
            option: this._amalgamSrv.getOptionLabel(amalgamGroup),
            format: amalgamGroup.format,
            isEn1090: amalgamGroup.isEn1090
          };
          isDefaultSupply = this._supplyCategoriesIds.includes(amalgamGroup.supplyCategoryId);
          break;
        case ElementType.PLATE:
          supplyListElement = element.supplyListElement;
          datas = {
            ...datas,
            quantityUnit: supplyListElement.quantityUnit ? EnumQuantityUnitSelectValue[supplyListElement.quantityUnit] : null,
            denomination: `Plaque`,
            matterRef: supplyListElement.matterRef,
            format: `${supplyListElement.thickness}x${supplyListElement.length}x${supplyListElement.width}`
          };
          break;
        case ElementType.DEFAULT:
          datas = {
            ...datas,
            quantityUnit: element.supplyListElement.quantityUnit,
            denomination: element.supplyListElement.denomination
          };
          isDefaultSupply = this._supplyCategoriesIds.includes(element.supplyListElement.supplyCategoryId);
          break;
      }
      this.elementListDatas.tableDatas.push(datas);
      this.elementListDatas.checkboxDatas.push({
        key: "selected",
        formValue: this._fb.group({
          elementId: element.id,
          selected: this._elementAlreadySelected.includes(element.id),
          disable: !!foundPossible && foundPossible.isOrigin
        })
      });
    });
  }

  /**
   * @description Determine how data must be presented depending on the type of element (Amalgam / Plate / Others)
   * @author Quentin Wolfs
   * @private
   * @param {IPriceRequestElement} element
   * @returns {ElementType}
   * @memberof ModalUpdateSupplierOfferElementsListComponent
   */
  private getDisplayedElementType(element: IPriceRequestElement): ElementType {
    if (!!element.amalgamGroup) {
      return ElementType.AMALGAM;
    } else {
      if (ElementUnitConfig.categories[ElementUnitCategory.PLATES].includes(+element.supplyListElement.supplyCategoryId)) { // If element is a plate
        return ElementType.PLATE;
      } else {
        return ElementType.DEFAULT;
      }
    }
  }

  public confirmModal() {
    const getCheckboxValues: {elementId: number, selected: boolean, disable: boolean}[] = this.elementListDatas.checkboxDatas.map( checkboxForm => checkboxForm.formValue.value)
    .filter(checkboxValue => !checkboxValue.disable);

    const deletedPriceRequestElementIds: number[] = getCheckboxValues.filter( value => !value.selected && this._elementAlreadySelected.includes(value.elementId)).map( value => value.elementId);
    const supplierOfferIdsToDelete: number[][] = this._supplierOfferElements
      .filter( element => deletedPriceRequestElementIds.includes( element.id))
      .map( element => {
        return element.supplierOfferElements.map(suppOfferElement => suppOfferElement.id);
      });

    this._elementAssociation = {
      supplierOfferId: this._supplierOfferId,
      associatedPriceRequestElementIds: getCheckboxValues.filter( value => value.selected && !this._elementAlreadySelected.includes(value.elementId)).map( value => value.elementId),
      deletedSupplierOfferElementIds: supplierOfferIdsToDelete.flat()
    };

    this.save();
    this._modalService.closeModal();
  }

  protected save(): void {
    const objData = {
      confirm: true,
      data: {
        elementAssociation: this._elementAssociation
      }
    };
    this.afterClosed.next(objData);
  }

}
