import { Component, OnInit, OnDestroy, ViewChild } from "@angular/core";
import { CatalogService } from "../../../facade/queries/catalog/sync/catalog.service";
import { FilterInputComponent } from "app/presentationnal/atoms";
import { Subscription } from "rxjs";

@Component({
  selector: "app-catalog",
  templateUrl: "./catalog.component.html",
  styleUrls: ["./catalog.component.css"]
})
export class CatalogComponent implements OnInit {
  @ViewChild("searchField") searchField: FilterInputComponent;

  private _filterResetSub: Subscription;
  constructor(private _catalogSrv: CatalogService) { }
  ngOnInit() {
    this._filterResetSub = this._catalogSrv.filterResetChanged.subscribe(res => {
      if (this.searchField) {
        this.searchField.reset();
      }
    });
  }


  private filterSearch($event) {
    this._catalogSrv.setSearch($event);
  }

}
