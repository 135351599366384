import { Component, OnInit, Input, SimpleChanges, OnChanges, Renderer2, ElementRef, EventEmitter, Output } from "@angular/core";
import { IBarsetItem, IPresetSize, IBarsetItemContent, IMovePartFromItem } from "app/facade/interfaces/barset.interface";
import { AmalgamService } from "app/facade/services/amalgam.service";
import { ModalService } from "../../modal/services/modal.service";
import { ModalSetCustomAmalgamFormatComponent } from "../../modal";
import { MODAL_TYPE } from "../../modal/enums/modal-type.enum";
import { BARSET_ITEM_SIZE_LABELS } from "app/facade/configs/barset-item-sizes.config";

@Component({
  selector: "app-barset-bar",
  templateUrl: "./barset-bar.component.html",
  styleUrls: ["./barset-bar.component.css"]
})
export class BarsetBarComponent implements OnInit, OnChanges {

  @Input() public barsetItem: IBarsetItem;
  public presetSize: IPresetSize[];
  @Output() public updateBarsetSizeSelection: EventEmitter<any> = new EventEmitter<any>();
  @Output() public saveBarsetUpdated: EventEmitter<any> = new EventEmitter<any>();
  @Output() public onMoveAmalgamPart: EventEmitter<IMovePartFromItem> = new EventEmitter<IMovePartFromItem>();
  public sizeLabels: any = BARSET_ITEM_SIZE_LABELS; // Permit access to enum's key in html template

  constructor(
    private _amalgamService: AmalgamService,
    private _renderer: Renderer2,
    private _elementRef: ElementRef,
    private _modalService: ModalService
  ) { }

  ngOnInit() {
    this._renderer.addClass(this._elementRef.nativeElement, "barset-bar");
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.presetSize = [...this._amalgamService.getBarsetItemSize(this.barsetItem.supplyCategoryId)];
  }

  public updateBarsetSize(size: IPresetSize) {
    if (size.label === BARSET_ITEM_SIZE_LABELS.MANUAL) {
      this.setManualFormat(size);
    } else {
      this.updateBarsetSizeSelection.emit({
        barsetId: this.barsetItem.id,
        value: size
      });
    }
  }

  public leaveEditMode() {
    this._amalgamService.saveTempBarset(null);
  }

  public saveNewData() {
    this.saveBarsetUpdated.emit(this.barsetItem);
  }

  public moveAmalgamPartAction(content: IBarsetItemContent) {
    this.onMoveAmalgamPart.emit({
      barsetPart: content,
      barset: this.barsetItem
    });
  }

  /**
   * @description Opens a modal that asks for a manual format to be encoded
   * @author Quentin Wolfs
   * @param {IPresetSize} size
   * @memberof BarsetBarComponent
   */
  public setManualFormat(size: IPresetSize) {
    const modalRef = this._modalService.openModal(ModalSetCustomAmalgamFormatComponent, {
      title: "Encoder longueur manuelle d'un amalgame",
      type: MODAL_TYPE.NORMAL,
      data: {
        barsetItem: this.barsetItem,
      },
    });
    if (modalRef) {
      modalRef.afterClosed.subscribe(res => {
        if (res.confirm && res.data) {
          this.updateBarsetSizeSelection.emit({
            barsetId: this.barsetItem.id,
            value: size,
            manualSize: res.data
          });
        }
      });
    }
  }

}
