import { Component, OnInit, Input, EventEmitter, Output, ViewChildren, QueryList, ElementRef, OnChanges, SimpleChanges } from "@angular/core";
import { ILinkedMultiTableGroupData } from "app/facade/interfaces/multi-table-group.interface";

@Component({
  selector: "app-multi-table-group-linked",
  templateUrl: "./multi-table-group-linked.component.html",
  styleUrls: ["./multi-table-group-linked.component.css"]
})
export class MultiTableGroupLinkedComponent implements OnInit, OnChanges {
  @Input() public linkedData: ILinkedMultiTableGroupData[] = [];
  @Input() public displayHeader: boolean = true;
  @Input() public contentHeight: string = "45vh";
  @Input() public scrollTopPosition: number = 0;
  @Output() public onEncodeSupplierResponse: EventEmitter<number> = new EventEmitter<number>();
  @ViewChildren("linkedContent") public linkedContent: QueryList<ElementRef>;

  constructor() { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.scrollTopPosition && this.linkedContent) {
      this.linkedContent.toArray().forEach(link => {
        link.nativeElement.scrollTop = changes.scrollTopPosition.currentValue;
      });
    }
  }

  public linkedTableScroll(event: Event) {
    event.preventDefault();
    this.linkedContent.toArray().forEach(link => {
      link.nativeElement.scrollTop = this.scrollTopPosition;
    });
  }

  public encodeSupplierResponse(linkedData: ILinkedMultiTableGroupData) {
    this.onEncodeSupplierResponse.emit(linkedData.id);
  }

}
