import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges, OnChanges, OnDestroy, ElementRef, Renderer2, ViewChild } from "@angular/core";
import { IBarsetItem, IMovePartFromItem, IBarsetItemContent, IEditedBarset, IBarsetScale, IBarsetZoom, IBarsetGeneration, IPresetSize } from "app/facade/interfaces/barset.interface";
import { AmalgamService } from "app/facade/services/amalgam.service";
import { Subscription, Subject, Observable } from "rxjs";
import { ModalService } from "../../modal/services/modal.service";
import { MODAL_TYPE } from "../../modal/enums/modal-type.enum";
import { ModalMoveAmalgamPartComponent } from "../../modal/components/modal-move-amalgam-part/modal-move-amalgam-part.component";
import { IAmalgamGroupData } from "app/facade/interfaces/price-request.interface";
import { SnackService } from "../../snack-bar/services/snack.service";
import { ModalConfirmComponent } from "../../modal/components/modal-confirm/modal-confirm.component";
import { debounceTime } from "rxjs/operators";
import { BARSET_DEFAULT_ZOOM_SIZE, BARSET_ITEM_SIZES_CONFIG, BARSET_ITEM_SIZE_LABELS } from "app/facade/configs/barset-item-sizes.config";
import { ModalUpdateBarsetStockPositionComponent } from "../../modal/components/modal-update-barset-stock-position/modal-update-barset-stock-position.component";

@Component({
  selector: "app-barset",
  templateUrl: "./barset.component.html",
  styleUrls: ["./barset.component.css"]
})
export class BarsetComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild("popOverContent") public popOverContent: ElementRef;
  @ViewChild("popOver") public popOver: ElementRef;
  @ViewChild("barsetGenerationPopOverContent") public barsetGenerationPopOverContent: ElementRef;
  @ViewChild("barsetGenerationPopOver") public barsetGenerationPopOver: ElementRef;
  private _debouncer: Subject<any> = new Subject();
  private _resetBarsetSub: Subscription;
  private _updateToSaveSub: Subscription;
  private _maxScaleSub: Subscription;
  private _barsetLoadingSub: Subscription;
  private _barsetFilter: string = "";
  private editedBarset: IEditedBarset[] = [];
  @Input() public barsetGenerationData: IBarsetGeneration;
  @Input() public barsetItems: IBarsetItem[] = [];
  @Input() public barsetComponentSize: string = "55vh";
  public formatedBarsetGenerationData: IBarsetGeneration;
  public loading: boolean = true;
  public editBarsets: boolean = false;
  public editedBarsetId: number = null;
  public editedBarsetData: IBarsetItem = null;
  public barsetConsultationTitle: string = "Résultat de la mise en barre";
  public barsetEditTitle: string = "Modification de la mise en barre";
  public barsetScales: IBarsetScale[] = [];
  public barsetZoomSelected: IBarsetZoom;
  @Output() public resetBarsetItems: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() public saveBarsetItems: EventEmitter<IBarsetItem[]> = new EventEmitter<IBarsetItem[]>();
  @Output() public onGenerateNewAmalgam: EventEmitter<IBarsetItem[]> = new EventEmitter<IBarsetItem[]>();

  constructor(
    private _renderer: Renderer2,
    private _amalgamService: AmalgamService,
    private _modalService: ModalService,
    private _snackBar: SnackService ) {
      this.barsetZoomSelected = this._amalgamService.getZoomConfig();
      this._debouncer.pipe(debounceTime(500)).subscribe((value) => {
        this.updateBarsetSize();
      });
  }

  ngOnInit() {
    this._updateToSaveSub = this._amalgamService.getUpdateToSave().subscribe( update => {
      this.loading = false;
      this.editBarsets = update;
      this.editedBarset = [];
    });
    this._resetBarsetSub = this._amalgamService.getResetBarsetSubscriber().subscribe( barset => {
      this.editedBarsetId = null;
      this.resetOldBarsets(barset);
    });
    this._maxScaleSub = this._amalgamService.listenMaxBarsetScaleValue().subscribe( value => {
      if (value) {
        this.barsetScales = this._amalgamService.getScales(value);
      } else {
        this.barsetScales = this._amalgamService.getScales();
      }
    });
    this._barsetLoadingSub = this._amalgamService.setBarsetToLoading().subscribe(val => {
      this.loading = val;
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.barsetGenerationData && this.barsetGenerationData) {
      this.formatedBarsetGenerationData = {
        ...this.barsetGenerationData,
        generationDuration: +(+this.barsetGenerationData.generationDuration).toFixed(3),
        partsTotalLength: +(+this.barsetGenerationData.partsTotalLength / 1000).toFixed(3),
        amalgamsTotalLength: +(+this.barsetGenerationData.amalgamsTotalLength / 1000).toFixed(3),
        totalLoss: +(+this.barsetGenerationData.totalLoss / 1000).toFixed(3),
        lossPercent: +(+this.barsetGenerationData.lossPercent).toFixed(3),
        partsQuantity: this.barsetGenerationData.partsQuantity,
        amalgamsQuantity: this.barsetGenerationData.amalgamsQuantity,
      };
    }
    // this.loading = true;
    // setTimeout(() => {
      if (this.barsetZoomSelected.size !== BARSET_DEFAULT_ZOOM_SIZE) {
        this.updateBarsetSize();
      }
      this.editedBarsetId = null;
      this.checkDiplayBarsets();
      this._amalgamService.checkMaxScaleValue(this.barsetItems);
    //   this.loading = false;
    // }, 0);
  }

  ngOnDestroy(): void {
    this._updateToSaveSub.unsubscribe();
    this._resetBarsetSub.unsubscribe();
    this._maxScaleSub.unsubscribe();
    this._barsetLoadingSub.unsubscribe();
  }

  public printBarsetInfo(generation: boolean = false) {
    const element: string = generation ? "barsetGenerationPopOver" : "popOver";
    const elementContent: string = generation ? "barsetGenerationPopOverContent" : "popOverContent";
    const el = this[element].nativeElement;
    const content = this[elementContent].nativeElement;
    // Define the position
    const posXa = el.getBoundingClientRect().right;
    const posXb = el.getBoundingClientRect().left;
    const posX = generation ? posXa + 100 : ((posXa - posXb) / 2) + posXb;
    const posY = el.getBoundingClientRect().top;
    // Set the position
    content.style.left = `${posX}px`;
    content.style.top = `${posY}px`;

    this._renderer.addClass(el, "popover_active");
  }
  public hideBarsetInfo(element: string = "popOver") {
    this._renderer.removeClass(this[element].nativeElement, "popover_active");
  }

  public changeZoomConfig(minus: boolean = false) {
    this.barsetZoomSelected = this._amalgamService.setZoomConfig(minus);
    this._debouncer.next();
  }

  public resetZoomConfig() {
    this.barsetZoomSelected = this._amalgamService.resetZoomConfig();
    this._debouncer.next();
  }

  private updateBarsetSize() {
    this.barsetItems = this.barsetItems.map( barset => {
      return {
        ...barset,
        size: this._amalgamService.getDisplaySize(barset.realSize, this.barsetZoomSelected.size, barset.reference)
      };
    });
    this._amalgamService.checkMaxScaleValue(this.barsetItems);
  }

  public setEditMode() {
    this._amalgamService.setUpdateToSave(true);
  }

  private resetOldBarsets(barset: IBarsetItem) {
    const barsetIndex: number = this.barsetItems.findIndex(barsetItem => barsetItem.id === barset.id);
    this.barsetItems[barsetIndex] = barset;
    this.barsetItems[barsetIndex].isDisplay = this.setDisplayValue(barset);
    let calculateNewKey: boolean = false;
    let foundBarsetIndex: number;

    this.editedBarset.forEach( editedBarset => {
      foundBarsetIndex = this.barsetItems.findIndex( barsetItem => barsetItem.id === editedBarset.barset.id);
      if ( editedBarset.isCreated ) {
        calculateNewKey = true;
        this.barsetItems.splice(foundBarsetIndex, 1);
      } else {
        this.barsetItems[foundBarsetIndex] = {...editedBarset.barset, content: [...editedBarset.barset.content]};
      }
    });
    this.editedBarset = [];
    if (calculateNewKey) {
      this.calculateBarsetsNewKey();
    }
    this._amalgamService.checkMaxScaleValue(this.barsetItems);
  }

  private calculateBarsetsNewKey() {
    this.barsetItems = this.barsetItems.map( (barsetItem, index) => {
      return {
        ...barsetItem,
        key: "#" + (index + 1)
      };
    });
  }

  public deleteEmptyAmalgam(barset: IBarsetItem) {
    const foundBarsetIndex: number = this.barsetItems.findIndex(barsetItem => barsetItem.id === barset.id);
    if (foundBarsetIndex > -1) {
      this.barsetItems.splice(foundBarsetIndex, 1);
      this.calculateBarsetsNewKey();
    }
  }


  /**
   * @description Check AmalgamDatas to filter the barsets on which can respond to the move action
   * @author Lainez Eddy
   * @public
   * @param {IMovePartFromItem} datas
   * @memberof BarsetComponent
   */
  public moveAmalgamPartModal(moveDatas: IMovePartFromItem) {
    const barsetList: IBarsetItem[] = this.getBarsetListForMoveAction(moveDatas.barset, moveDatas.barsetPart);
    const modalRef = this._modalService.openModal(ModalMoveAmalgamPartComponent, {
      title: "Déplacement d'un élément",
      type: MODAL_TYPE.NORMAL,
      data: {
        ...moveDatas,
        barsetList
      },
    });
    if (modalRef) {
      modalRef.afterClosed.subscribe(res => {
        if (res.confirm && res.data) {
          this.updateAmalgamParts(moveDatas, res.data);
          this._snackBar.open("Déplacement d'un élément", "L'élément a bien été déplacé", "success", 5000);
          this._amalgamService.checkMaxScaleValue(this.barsetItems);
        }
      });
    }
  }

  private updateAmalgamParts(moveDatas: IMovePartFromItem, responseData: any) {
    let tempBarset: IBarsetItem;
    const tempBarsetDatas: IBarsetItem[] = [...this.barsetItems];
    let incrementalKey: number;
    let addingRowInfo: { index: number, barset: IBarsetItem};
    tempBarsetDatas.forEach((barset, index) => {
      tempBarset = this.barsetItems[index];
      if (tempBarset.id === moveDatas.barset.id) {
        tempBarset.content = tempBarset.content.filter( content => content.id !== moveDatas.barsetPart.id);
        this.calculateRealSize(tempBarset);
      }
      if (tempBarset.id === responseData.barsetId) {
        this.insertPartInExistingBarset(tempBarset, moveDatas.barsetPart);
      }

      if (incrementalKey) {
        tempBarset.key = "#" + (index + incrementalKey);
      }

      if (responseData.barsetId === null && tempBarset.id === moveDatas.barset.id) {
        incrementalKey = 2;
        tempBarset = {
          ...moveDatas.barset,
          isEdited: false,
          content: [moveDatas.barsetPart],
          id: + new Date(),
          key: "#" + (index + incrementalKey)
        };
        this.calculateRealSize(tempBarset);
        addingRowInfo = {
          index: index + 1,
          barset: tempBarset
        };
      }
    });
    this.insertPartInNewBarset(addingRowInfo);
  }

  private insertPartInNewBarset( addingRowInfo: { index: number, barset: IBarsetItem}) {
    if (addingRowInfo) {
      // No need to set the barsetPartsId because the row will be delete in cancel case
      this.editedBarset.push({
        barset: addingRowInfo.barset,
        isCreated: true
      });
      this.barsetItems.splice(addingRowInfo.index, 0, addingRowInfo.barset);
    }
  }

  private insertPartInExistingBarset( barset: IBarsetItem, part: IBarsetItemContent) {
    const getPreviousEditedBarset: IEditedBarset = this.editedBarset.find( edited => edited.barset.id === barset.id);
    if (!getPreviousEditedBarset) {
      this.editedBarset.push({
        barset: {...barset, content: [...barset.content]},
        isCreated: false
      });
    }
    barset.content.push( part );
    this.calculateRealSize(barset);
  }

  private calculateRealSize(barset: IBarsetItem) {
    let realSizes: {sizeUsed: number, selectedSizeValue: number};
    realSizes = this._amalgamService.getAmalgamRealSizes(barset);
    barset.sizeUsed = realSizes.sizeUsed;
    // update amalgam size only if it's bigger than the previous size
    if (!realSizes.selectedSizeValue || realSizes.selectedSizeValue > barset.realSize) {
      this._amalgamService.updateAmalgamSize(barset, this.findPresetSize(realSizes.selectedSizeValue, barset.selectedSizeLabel), barset.manualSizeValue);
    } else {
      this._amalgamService.updateAmalgamSize(barset, this.findPresetSize(barset.realSize, barset.selectedSizeLabel), barset.manualSizeValue);
    }
  }

  /**
   * @description Find PresetSize from config based on barset size or label
   * @author Quentin Wolfs
   * @private
   * @param {number} barsetSize
   * @param {BARSET_ITEM_SIZE_LABELS} baseLabel
   * @returns {IPresetSize}
   * @memberof BarsetComponent
   */
  private findPresetSize(barsetSize: number, baseLabel: BARSET_ITEM_SIZE_LABELS): IPresetSize {
    const standardSize = BARSET_ITEM_SIZES_CONFIG.find(size => size.value === barsetSize);
    if (standardSize) {
      return standardSize;
    } else {
      return BARSET_ITEM_SIZES_CONFIG.find(size => size.label === baseLabel);
    }
  }

  private getBarsetListForMoveAction(barsetItem: IBarsetItem, barsetPart: IBarsetItemContent) {
    const amalgamData: IAmalgamGroupData = barsetItem.amalgamData;
    let tempAmalgamData: IAmalgamGroupData;
    return this.barsetItems.filter( barset => {
      tempAmalgamData = barset.amalgamData;
      return barset.id !== barsetItem.id &&
        !barset.isLocked &&
        tempAmalgamData.reference === amalgamData.reference &&
        (tempAmalgamData.matterId === amalgamData.matterId || tempAmalgamData.matterRef === amalgamData.matterRef) &&
        tempAmalgamData.isEn1090 === amalgamData.isEn1090 &&
        tempAmalgamData.isBlack === amalgamData.isBlack &&
        tempAmalgamData.isBlasted === amalgamData.isBlasted &&
        tempAmalgamData.isPrimaryBlasted === amalgamData.isPrimaryBlasted
        && (!barset.isInStock || (barset.isInStock && (barset.sizeUsed + barsetPart.realSize) <= barset.realSize));
    });
  }

  public beforeCancelAmalgams() {
    this._modalService.openModal(ModalConfirmComponent,
      {
        title: "Êtes-vous sûr?",
        type: MODAL_TYPE.CONFIRM,
        data: {
          message: `Voulez-vous annuler vos modifications sur la mise en barre ?`,
          actions: [
            "Récupérer la dernière mise en barre générée ou modifiée",
            "Annuler toutes les modifications manuelles que vous avez fait sur les amalgames",
            "Annuler la nouvelle mise en barre que vous auriez pu générer"
          ],
        },
        confirmCallback: this.cancelAmalgams,
        customData: {
          deleteButtonName: "Oui",
          cancelButtonName: "Non"
        }
      }
    );
  }

  private cancelAmalgams = () => {
    this.loading = true;
    this.editedBarsetId = null;
    setTimeout(() => {
      this.resetBarsetItems.emit(true);
    }, 500);
  }

  public saveAmalgams() {
    this.loading = true;
    this.resetEditedBarset();
    setTimeout(() => {
      this.saveBarsetItems.emit(this.barsetItems);
    }, 200);
  }

  public generateNewAmalgam() {
    this.onGenerateNewAmalgam.emit(this.barsetItems.filter( barset => barset.isLocked));
  }

  public filterSearch(event: string) {
    this._barsetFilter = event;
    this.checkDiplayBarsets();
  }

  private checkDiplayBarsets() {
    this.barsetItems = this.barsetItems.map( barsetItem => {
      return {
        ...barsetItem,
        isDisplay: barsetItem.isEdited || this.setDisplayValue(barsetItem)
      };
    });
  }

  private setDisplayValue(barsetItem: IBarsetItem) {
    return (barsetItem.reference.toLowerCase().search(this._barsetFilter.toLowerCase()) !== -1 ) ||
    (barsetItem.key.toString().toLowerCase().search(this._barsetFilter.toLowerCase()) !== -1 ) ||
    (barsetItem.sizeLabel.toLowerCase().search(this._barsetFilter.toLowerCase()) !== -1 ) ||
      barsetItem.content.some( part => {
        return (!!part.reference && part.reference.toLowerCase().search(this._barsetFilter.toLowerCase()) !== -1) ||
          (!!part.description && part.description.toLowerCase().search(this._barsetFilter.toLowerCase()) !== -1) ||
          (!!part.denomination && part.denomination.toLowerCase().search(this._barsetFilter.toLowerCase()) !== -1) ||
          (!!part.format && part.format.toLowerCase().search(this._barsetFilter.toLowerCase()) !== -1);
      }
    );
  }
  /* barset infos item méthodes */
  public setEditedBarset(barsetId: number) {
    const barsetItem: IBarsetItem = this.barsetItems.find(barset => barset.id === barsetId);
    this._amalgamService.saveTempBarset(barsetItem);
    barsetItem.isEdited = true;
    this.editedBarsetId = barsetId;
    this.editedBarsetData = barsetItem;
  }

  public setlockBarset(barsetId: number) {
    const barsetItem: IBarsetItem = this.barsetItems.find(barset => barset.id === barsetId);
    barsetItem.isLocked = !barsetItem.isLocked;
    this.onSaveBarsetUpdated(barsetItem);
  }

  public updateStockValue(barsetId: number) {
    const barsetItem: IBarsetItem = this.barsetItems.find(barset => barset.id === barsetId);
    barsetItem.isInStock = !barsetItem.isInStock;
    if (barsetItem.isInStock) {
      const modalRef = this._modalService.openModal(ModalUpdateBarsetStockPositionComponent, {
        title: "Emplacement de stock",
        type: MODAL_TYPE.NORMAL,
        data: {
          stockPosition: barsetItem.stockPosition,
        },
      });
      if (modalRef) {
        modalRef.afterClosed.subscribe(res => {
          if (res.confirm && res.data) {
            barsetItem.stockPosition = res.data.stockPosition;
          }
        });
      }
    }
  }

  /* barset bar méthodes */
  public onUpdateBarsetSizeSelection(data: { barsetId: number, value: IPresetSize, manualSize?: number }) {
    const barsetItem: IBarsetItem = this.barsetItems.find(barset => barset.id === data.barsetId);
    this._amalgamService.updateAmalgamSize(barsetItem, data.value, data.manualSize);
    this._amalgamService.checkMaxScaleValue(this.barsetItems);
  }

  public onSaveBarsetUpdated(barsetToSave: IBarsetItem) {
    this._amalgamService.saveEditedBarset();
    this.resetEditedBarset();
    if (barsetToSave.content.length === 0) {
      this.deleteEmptyAmalgam(barsetToSave);
    }
  }

  private resetEditedBarset() {
    if (this.editedBarsetId) {
      const barsetItem: IBarsetItem = this.barsetItems.find( barset => barset.id === this.editedBarsetId);
      barsetItem.isEdited = false;
      barsetItem.isDisplay = this.setDisplayValue(barsetItem);
      if (barsetItem.content.length === 0) {
        this.deleteEmptyAmalgam(barsetItem);
      }
      this.editedBarsetId = null;
    }
  }

}
