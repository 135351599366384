import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { IPagination } from '../../../facade/interfaces/crud.interface';
import { IUserSort } from '../../../facade/interfaces/user.interface';
import { Users } from '../../../facade/interfaces/users';
import { ADD_USER, DELETE_USER, GET_USER, UPDATE_USER, USERS } from './users.queries';
@Injectable({
  providedIn: 'root'
})
export class UsersQueriesService {

  constructor(private _apollo: Apollo) { }

  public getUser(userId) {
    return this._apollo.query({
      query: GET_USER,
      variables: {id: userId},
      fetchPolicy: "network-only"
    });
  }

  public getUserListing(search?: string, sort?: IUserSort, pagination?: IPagination) {
    return this._apollo.query({
      query: USERS,
      variables: {
        search,
        sort,
        pagination
      },
      fetchPolicy: "no-cache"
    });
  }

  public updateUser(idUser: number, user: Users) {
    return this._apollo.mutate({
        mutation: UPDATE_USER,
        fetchPolicy: "no-cache",
        variables : {
          id: idUser,
          user: user
        }
    });
  }

  public deleteUser(id) {
    return this._apollo.mutate({
        mutation: DELETE_USER,
        variables : {
          id
        }
    });
  }

  public addUser(data) {
    return this._apollo.mutate({
        mutation: ADD_USER,
        variables : {
          data: data
        }
    });
  }

}
