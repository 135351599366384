import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ModalService } from "app/presentationnal/organisms/modal/services/modal.service";
import { SnackService } from "app/presentationnal/organisms/snack-bar/services/snack.service";
import { IContainerCardsConfig } from "app/facade/interfaces/container-cards-config.interface";
import { IInformationsCardConfig, IInformationsCardField } from "app/facade/interfaces/informations-card-data.interface";
import { ISupplyList } from "app/facade/interfaces/project";
import { Subscription } from "apollo-client/util/Observable";
import * as moment from "moment";
import { IMultiTableData } from "app/facade/interfaces/multi-table.interface";
import { PurchaseOrderQueriesService } from "app/facade/queries/purchase-order/purchase-order-queries.service";
import { IPurchaseOrder, ICustomPurchaseOrder, EnumPurchaseOrderStatus, IPurchaseOrderElement, IPurchaseOrderAdditionnalCost, IPurchaseOrderElementInput, IPurchaseOrderElementUpdate, IPurchaseOrderElementOptionInpdate, IPurchaseOrderUpdate } from "app/facade/interfaces/purchase-order.interface";
import { purchaseOrderStatusConfig } from "app/facade/configs/status.config";
import { ModalConfirmComponent, ModalAddPurchaseOrderComponent, ModalInpdatePurchaseOrderElementComponent, ModalUpdateSupplierOfferContactComponent, ModalSelectPurchaseOrderElementsToGenerateLabelsPdfComponent, ModalInpdatePurchaseOrderAdditionnalCostComponent, ModalUpdatePriceRequestElementRemarkComponent } from "app/presentationnal/organisms/modal";
import { MODAL_TYPE } from "app/presentationnal/organisms/modal/enums/modal-type.enum";
import { ApplicationConfig } from "app/app.config";
import { PurchaseOrderTableService } from "app/facade/services/purchase-order-table.service";
import { ILinkedMultiTableGroupData, IMultiTableGroup } from "app/facade/interfaces/multi-table-group.interface";
import { EnumSupplyTableLinkedSize } from "app/facade/enums/supply-table-linked-size.enum";
import { ISupplierOfferUpdate } from "app/facade/interfaces/supplier-offer";
import { MODAL_CLOSE_ACTION } from "app/presentationnal/organisms/modal/enums/modal-close-action.enum";
import { UniqueSupplierOfferAdditionnalCostType } from "app/facade/enums/supplier-offer-additionnal-cost-type.enum";
import { PermissionService } from "app/facade/services/permission.service";
import { AuthService } from "@lib/auth/auth.service";
import { TableDatasDefinition } from "app/facade/interfaces/table-datas-definition";
import { ScanPdf } from "app/facade/interfaces/scan-pdf.interface";
import { ScanPdfQueriesService } from "app/facade/queries/scan-pdf/scan-pdf.queries.service";
import { TABLE_ITEM_ACTION_TYPE } from "app/presentationnal/organisms/table/table-item/enum/table-item-action-type.enum";
import { ModalAddScanPdfComponent } from "app/presentationnal/organisms/modal/components/modal-add-scan-pdf/modal-add-scan-pdf.component";
import { ModalEditScanPdfComponent } from "app/presentationnal/organisms/modal/components/modal-edit-scan-pdf/modal-edit-scan-pdf.component";
import { UploadFileService } from "@lib/upload-file/upload-file.service";
import { ModalUpdatePriceRequestElementFormatComponent } from "app/presentationnal/organisms/modal/components/modal-update-price-request-element-format/modal-update-price-request-element-format.component";
import { ModalUpdatePriceRequestElementDenominationComponent } from "app/presentationnal/organisms/modal/components/modal-update-price-request-element-denomination/modal-update-price-request-element-denomination.component";
import { ModalUpdatePriceRequestElementQuantityComponent } from "app/presentationnal/organisms/modal/components/modal-update-price-request-element-quantity/modal-update-price-request-element-quantity.component";
import { ModalUpdatePriceRequestElementMatiereComponent } from "app/presentationnal/organisms/modal/components/modal-update-price-request-element-matiere/modal-update-price-request-element-matiere.component";
import { ModalUpdatePriceRequestElementWeightComponent } from "app/presentationnal/organisms/modal/components/modal-update-price-request-element-weight/modal-update-price-request-element-weight.component";
import { ModalUpdatePriceRequestElementWidthComponent } from "app/presentationnal/organisms/modal/components/modal-update-price-request-element-width/modal-update-price-request-element-width.component";
import { ModalUpdatePriceRequestElementLengthComponent } from "app/presentationnal/organisms/modal/components/modal-update-price-request-element-length/modal-update-price-request-element-length.component";
import { ModalUpdatePriceRequestElementThicknessComponent } from "app/presentationnal/organisms/modal/components/modal-update-price-request-element-thickness/modal-update-price-request-element-thickness.component";
import { ModalUpdatePriceRequestElementQuantityUnitComponent } from "app/presentationnal/organisms/modal/components/modal-update-price-request-element-quantity-unit/modal-update-price-request-element-quantity-unit.component";
import { ModalUpdatePriceRequestElementEn1090Component } from "app/presentationnal/organisms/modal/components/modal-update-price-request-element-en1090/modal-update-price-request-element-en1090.component";
import { ModalUpdatePriceRequestElementOptionsComponent } from "app/presentationnal/organisms/modal/components/modal-update-price-request-element-options/modal-update-price-request-element-options.component";
import { ModalUpdatePriceRequestElementPriceComponent } from "app/presentationnal/organisms/modal/components/modal-update-price-request-element-price/modal-update-price-request-element-price.component";
import { ModalUpdatePriceRequestElementDeliveryDateComponent } from "app/presentationnal/organisms/modal/components/modal-update-price-request-element-delivery-date/modal-update-price-request-element-delivery-date.component";

interface ISupplierContactInformationCard {
    id: number;
    contactId: number;
    code: string;
    name: string;
    categories: string;
    contact: string;
    mail: string;
}

@Component({
    selector: "app-purchase-order-details",
    templateUrl: "./purchase-order-details.component.html",
    styleUrls: ["./purchase-order-details.component.css"]
})
export class PurchaseOrderDetailsComponent implements OnInit, OnDestroy {
    private _purchaseOrderSub: Subscription;
    private _updatePurchaseOrderSub: Subscription;
    private _scanPdfSub: Subscription;
    private _deleteScanPdfSub: Subscription;
    private _purchaseOrderId: number;
    private _purchaseOrder: IPurchaseOrder;
    private _purchaseOrderElements: IPurchaseOrderElement[];
    private _purchaseOrderAdditionnalCosts: IPurchaseOrderAdditionnalCost[];
    private _actionsType = TABLE_ITEM_ACTION_TYPE;
  private _purchaseOrderElementUpdateSub: Subscription;
    public purchaseOrderIsCancellable: boolean = true;
    public purchaseOrderDataInformationCard: ICustomPurchaseOrder;
    public supplierDataInformationCard: ISupplierContactInformationCard;
    public purchaseOrderInformationsCardConfig: IInformationsCardConfig;
    public supplierInformationsCardConfig: IInformationsCardConfig;
    public purchaseOrderMultiTableDatas: IMultiTableGroup = {
        elements: [],
        linkedData: [],
        supplyTableLinkedSize: EnumSupplyTableLinkedSize.three
    };

    public purchaseOrderData: any;
    public containerCardsConfig: IContainerCardsConfig = null;
    public supplyListDatas: ISupplyList[] = [];
    public purchaseTableData: IMultiTableData[] = [];
    public hasPermission: any;
    public userGroup: any;
    public tableHeaders: string[] = [
        "Nom",
        "Date",
        "Commentaire",
        "Actions",
    ];
    public dataDefinition: TableDatasDefinition[] = [
        { key: "name", isTitle: true },
        { key: "createdAt", isTitle: false },
        { key: "comment", isTitle: false },
    ];
    public dataTable: ScanPdf[] = [];
    constructor(
        private _purchaseOrderQueriesSrv: PurchaseOrderQueriesService,
        private route: ActivatedRoute,
        private _scanPdfQueriesSrv: ScanPdfQueriesService,
        private router: Router,
        private _modalService: ModalService,
        private _snackBar: SnackService,
        private _purchaseOrderTableSrv: PurchaseOrderTableService,
        private _permissionService: PermissionService,
        private _authSrv: AuthService,
        private _uploadFileSrv: UploadFileService) {
        this._purchaseOrderId = +this.route.snapshot.paramMap.get("id");
    }

    ngOnInit(): void {
        // get this.userGroup
        this.userGroup = this._authSrv.isUserGroup();
        //get and update Permission
        this._permissionService.getAndUpdatePermission(this.userGroup);
        // check if hasPermissionn to see prices
        this.hasPermission = this._permissionService.hasPermission(this.userGroup, "purchase-orders", "seePrices");

        this.purchaseOrderInformationsCardConfig = {
            title: "Informations bon de commande",
            actions: [],
            fieldsToDisplay: [
                { key: "reference", label: "Référence" },
                { key: "supplier", label: "Fournisseur" },
                { key: "createdAt", label: "Date de création" },
                { key: "createdBy", label: "Créé par" },
                { key: "project", label: "Projet" },
                { key: "priceRequest", label: "Demande de prix" },
                { key: "status", label: "Status", isStatus: true, statusConfig: purchaseOrderStatusConfig },
                { key: "remark", label: "Remarque générale" },
                { key: "internalRemark", label: "Remarque interne" },
            ]
        };
        this.supplierInformationsCardConfig = {
            title: "Fournisseur et personne de contact",
            actions: [],
            fieldsToDisplay: [
                { key: "code", label: "Code Fournisseur" },
                { key: "name", label: "Nom du fournisseur" },
                { key: "categories", label: "Catégories" },
                { key: "contact", label: "Contact" },
                { key: "mail", label: "Email" }
            ]
        };
        this.getPurchaseOrderData();
        this.getScanPdf();
        // Subscribe to element remark update action
        this.updatePurchaseOrderElementRemark();
        // Subscribe to element format update action
        this.updatePurchaseOrderElementFormat();
        // Subscribe to element denomination update action
        this.updatePurchaseOrderElementDenomination();
        // Subscribe to element quantity update action
        this.updatePurchaseOrderElementQuantity();
        // Subscribe to element matiere update action
        this.updatePurchaseOrderElementMatiere();
        // Subscribe to element weight update action
        this.updatePurchaseOrderElementWeight();
        // Subscribe to element width update action
        this.updatePurchaseOrderElementWidth();
        // Subscribe to element length update action
        this.updatePurchaseOrderElementLength();
        // Subscribe to element thickness update action
        this.updatePurchaseOrderElementThickness();
        // Subscribe to element quantityUnit update action
        this.updatePurchaseOrderElementQuantityUnit();
        // Subscribe to element en1090 update action
        this.updatePurchaseOrderElementEn1090();
        // Subscribe to element options update action
        this.updatePurchaseOrderElementOption();
      // Subscribe to element price update action
      this.updatePurchaseOrderElementPrice();
      // Subscribe to element DeliveryDate update action
      this.updatePurchaseOrderElementDeliveryDate();
    }

    ngOnDestroy(): void {
        this._purchaseOrderSub.unsubscribe();
      this._purchaseOrderElementUpdateSub.unsubscribe();
        if (this._updatePurchaseOrderSub) { this._updatePurchaseOrderSub.unsubscribe(); }
    }

    // Purchase Order Detail Methods
    private async getPurchaseOrderData() {
        if (this._purchaseOrderSub) { this._purchaseOrderSub.unsubscribe(); }
        this._purchaseOrderSub = this._purchaseOrderQueriesSrv.watchPurchaseOrder(this._purchaseOrderId).valueChanges.subscribe(result => {
            const resultData: { purchaseOrder: IPurchaseOrder } = <any>result.data;
            if (resultData && resultData.purchaseOrder) {
                this.setPurchaseOrderDatas(resultData.purchaseOrder);
                this._purchaseOrderElements = resultData.purchaseOrder.elements;
                // check if hasn't permission and
                if (!this.hasPermission) {
                    // Change price element purchaseOrderElements 0
                    for (let elements = 0; elements < this._purchaseOrderElements.length; elements++) {
                        this._purchaseOrderElements[elements].price = 0;
                    }

                    // Change price element additionnalCosts 0
                    for (let additionnalCost = 0; additionnalCost < resultData.purchaseOrder.additionnalCosts.length; additionnalCost++) {
                        let additionnalCosts = resultData.purchaseOrder.additionnalCosts[additionnalCost];
                        additionnalCosts.price = 0;
                    }
                }
                this.purchaseOrderMultiTableDatas = this._purchaseOrderTableSrv.formatMultiTableData(
                    resultData.purchaseOrder.elements,
                    resultData.purchaseOrder.additionnalCosts,
                    [this.formatSupplierToLinked(resultData.purchaseOrder)],
                    this._purchaseOrder.status !== EnumPurchaseOrderStatus.CREATED
                );
            }
        }, error => {
            console.error("ERROR LOADING PURCHASE ORDER DATA", { error });
        });
    }

    //get scan pdf information
    public getScanPdf() {
        if (this._scanPdfSub) { this._scanPdfSub.unsubscribe(); }
        this._scanPdfSub = this._scanPdfQueriesSrv.getScanPdf(this._purchaseOrderId).subscribe(result => {
            const resultData = <any>result.data;
            if (resultData && resultData.getScanPdf) {
                this.dataTable = resultData.getScanPdf;
                this.dataTable = this.mapScanPdf(resultData.getScanPdf);
            }
        }, error => {
            console.log("ERROR LOADING LISTING SCANPDF", { error });
        });
    }

    //Map data for listing ScanPdf display
    private mapScanPdf(data: any[]): ScanPdf[] {
        return data.map(scan => {
            const createdAt = new Date(scan.createdAt * 1000);
            let createdAtStr: string = (createdAt.getDate() < 10 ? '0' : '') + createdAt.getDate();
            createdAtStr += '/' + (createdAt.getMonth() + 1 < 10 ? '0' : '') + (createdAt.getMonth() + 1);
            createdAtStr += '/' + createdAt.getFullYear();

            return {
                id: scan.id,
                name: scan.name,
                purchaseOrderId: scan.purchaseOrderId,
                url: scan.url,
                createdAt: createdAtStr,
                comment: scan.comment,

            };
        });
    }

    //ADD Actions in ScanPdfList
    private setScanPdfActionList(): any[] {
        const actionList: any[] = [];
        actionList.push(
            { label: "Lire", type: TABLE_ITEM_ACTION_TYPE.EDIT }
        );
        if (this.purchaseOrderIsCancellable) {
            actionList.push(
                { label: "Editer", type: TABLE_ITEM_ACTION_TYPE.SAVE }
            );
            actionList.push(
                { label: "Supprimer", type: TABLE_ITEM_ACTION_TYPE.DELETE, additionnalClass: "table-row__action_delete", style: "display:flex;" }
            );
        }

        return actionList;
    }

    public addScanPdf() {
        const modalTitle: string = "Ajout des Scans Pdf";
        const modalRef = this._modalService.openModal(ModalAddScanPdfComponent,
            {
                title: modalTitle,
                type: MODAL_TYPE.NORMAL,
                data: {
                    purchaseOrder: this._purchaseOrder,
                },
                closeAction: MODAL_CLOSE_ACTION.NONE
            }
        );
        if (modalRef) {
            modalRef.afterClosed.subscribe(res => {
                if (res.confirm && res.data) {
                    this.router.navigate([`${this.router.url}/edit/${res.data.id}`]);
                }
            });
        }
    }
    //open pdf file into the new window
    public printScanPdf(itemId: number) {
        const res = this._scanPdfQueriesSrv.getOneScanPdf(Number(itemId)).subscribe(result => {
            const resultData = <any>result.data;
            if (resultData && resultData.getOneScanPdf) {
                const url = resultData.getOneScanPdf.url;
                window.open(`${ApplicationConfig.Url}/api/scan/${url}`);
            } else {

            }
        }, error => {
            console.log(error);
        });
    }

    public tableButtonClicked($event: { actionType: number, itemId: number }) {
        switch (this._actionsType[$event.actionType]) {
            case "DELETE":
                this.beforeDeleteScanPdf($event.itemId);
                break;
            case "EDIT":
                this.printScanPdf($event.itemId);
                break;
            case "SAVE":
                this.editScanPdf($event.itemId);
                break;
            default:
                break;
        }
    }

    public editScanPdf(scanPdfId) {
        this._scanPdfQueriesSrv.getOneScanPdf(Number(scanPdfId)).subscribe(result => {
            const resultData = <any>result.data;
            if (resultData && resultData.getOneScanPdf) {
                const scanPdf: ScanPdf = resultData.getOneScanPdf;
                const modalTitle: string = "Edition du scan : " + scanPdf.name;
                const modalRef = this._modalService.openModal(ModalEditScanPdfComponent,
                    {
                        title: modalTitle,
                        type: MODAL_TYPE.NORMAL,
                        data: {
                            purchaseOrder: this._purchaseOrder,
                            scanPdf: scanPdf,
                        },
                        closeAction: MODAL_CLOSE_ACTION.SAVE
                    }
                );
                if (modalRef) {
                    modalRef.afterClosed.subscribe(res => {
                        if (res.confirm && res.data) {
                            this.router.navigate([`${this.router.url}/edit/${res.data.id}`]);
                        }
                    });
                }
            } else {
                console.log('error : can\'t find ScanPdf');
            }
        }, error => {
            console.log(error);
        });
    }
    //
    // CALL MODAL FOR DELETE SCAN PDF
    //
    private beforeDeleteScanPdf(scanPdfId: number) {
        const scanPdf: ScanPdf = this.dataTable.find(data => data.id === scanPdfId);
        if (scanPdf) {
            this._modalService.openModal(ModalConfirmComponent,
                {
                    title: "Êtes-vous sûr?",
                    type: MODAL_TYPE.CONFIRM,
                    data: {
                        message: `Voulez-vous supprimer le fichier <b>${scanPdf.name}</b>?`,
                        actions: [
                            "Supprimer le fichier de manière définitive",
                        ],
                    },
                    params: { scanPdfId },
                    confirmCallback: this.deleteScanPdf,
                    customData: {
                        deleteButtonName: "Valider",
                    }
                }
            );
        } else {
            console.error("CANNOT FIND PURCHASE ORDER ID ", scanPdfId);
        }
    }

    public deleteScanPdf = (params: { scanPdfId: number }) => {
        const snackBarTitle: string = "Supprimer un fichier PDF";
        if (this._deleteScanPdfSub) { this._deleteScanPdfSub.unsubscribe(); }
        this._deleteScanPdfSub = this._scanPdfQueriesSrv.deleteScanPdf(params.scanPdfId).subscribe((result: any) => {
            const resultData: { deleteScanPdf: boolean } = result.data;
            if (resultData && resultData.deleteScanPdf) {
                const index = this.dataTable.findIndex(scanpdf => scanpdf.id == params.scanPdfId);
                this.dataTable = [...this.dataTable.slice(0, index), ...this.dataTable.slice(index + 1)];
                this._snackBar.open(snackBarTitle, "Le fichier a été supprimé", "success", 5000);
            } else {
                this._snackBar.openSnackBarError(snackBarTitle, "Le fichier n'a pas été supprimé");
            }
        }, error => {
            this._snackBar.openSnackBarError(snackBarTitle, "Le fichier n'a pas été supprimé", error);
        });
    }

    private formatSupplierToLinked(purchaseOrder: IPurchaseOrder): ILinkedMultiTableGroupData {
        // check if hasn't permission change total 0
        if (!this.hasPermission) {
            // TODO: change total 0
            return {
                id: null, // null to don't display response button
                name: purchaseOrder.supplier.name,
                elements: [],
                totalPrice: 0,
                totalAdditionnalCosts: 0,
                totalSelectedElementPrice: null
            };
        }
        // TODO: Calculate the total of all element
        return {
            id: null, // null to don't display response button
            name: purchaseOrder.supplier.name,
            elements: [],
            totalPrice: +(purchaseOrder.totalPrice + purchaseOrder.totalAdditionnalCosts).toFixed(3),
            totalAdditionnalCosts: purchaseOrder.totalAdditionnalCosts, // Not use
            totalSelectedElementPrice: null // Not use
        };
    }

    private setPurchaseOrderDatas(purchaseOrder: IPurchaseOrder) {
        this._purchaseOrder = purchaseOrder;
        this.purchaseOrderIsCancellable = this._purchaseOrder.status === EnumPurchaseOrderStatus.CREATED;
        this.setPurchaseOrderAndSupplierInformationCardData();
        this.purchaseOrderInformationsCardConfig.actions = [];
        // if (this._purchaseOrder.status === EnumPurchaseOrderStatus.CREATED && !this._purchaseOrder.priceRequestId) {
        this.purchaseOrderInformationsCardConfig.actions.push({
            label: "Editer",
            fct: this.updatePurchaseOrderData
        });
        // }
        this.dataTable = this.dataTable.map((scanPdf) => ({ ...scanPdf, actionsList: this.setScanPdfActionList() }));
        if (this._purchaseOrder.priceRequestId) {
            const field: IInformationsCardField = this.purchaseOrderInformationsCardConfig.fieldsToDisplay.find(fieldDisplay => fieldDisplay.key === "priceRequest");
            if (field) {
                field.action = this.consultPriceRequest;
                field.icon = "fas fa-external-link-alt";
            }
        }

        if (this._purchaseOrder.supplier) {
            const field: IInformationsCardField = this.supplierInformationsCardConfig.fieldsToDisplay.find(fieldDisplay => fieldDisplay.key === "contact");
            if (field) {
                if (this._purchaseOrder.status === EnumPurchaseOrderStatus.CREATED) {
                    field.action = this.updateSupplierContact;
                } else {
                    delete field.action;
                }
            }
        }
    }

    private consultPriceRequest = () => {
        if (this._purchaseOrder.priceRequestId) {
            window.open(`${ApplicationConfig.getDomain()}/price-request/edit/${this._purchaseOrder.priceRequestId}`);
        } else {
            console.error("ERROR TO FIND PRICE REQUEST ID");
        }
    }

    private setPurchaseOrderAndSupplierInformationCardData() {
        this.purchaseOrderDataInformationCard = {
            id: this._purchaseOrder.id,
            reference: this._purchaseOrder.reference,
            supplier: this._purchaseOrder.supplier ? this._purchaseOrder.supplier.name : "",
            project: this._purchaseOrder.project ?
                this._purchaseOrder.project.reference :
                this._purchaseOrder.linkedProjects.map(project => project.reference).join(", "),
            createdAt: moment.unix(+this._purchaseOrder.createdAt).format("DD/MM/YYYY HH:mm"),
            createdBy: `${this._purchaseOrder.user.lastname} ${this._purchaseOrder.user.firstname}`,
            priceRequest: this._purchaseOrder.priceRequest ? this._purchaseOrder.priceRequest.reference : "",
            status: this._purchaseOrder.status,
            remark: this._purchaseOrder.remark,
            internalRemark: this._purchaseOrder.internalRemark
        };
        if (this._purchaseOrder.supplier) {
            this.supplierDataInformationCard = {
                id: this._purchaseOrder.supplier.id,
                contactId: this._purchaseOrder.supplierContactId,
                code: this._purchaseOrder.supplier.code,
                name: this._purchaseOrder.supplier.name,
                categories: this._purchaseOrder.supplier.parentSupplyCategories.map(cat => cat.name).join(", "),
                contact: this._purchaseOrder.supplierContact ? `${this._purchaseOrder.supplierContact.lastname} ${this._purchaseOrder.supplierContact.firstname}` : "",
                mail: this._purchaseOrder.supplierContact ? this._purchaseOrder.supplierContact.mail : this._purchaseOrder.supplier.mail,
            };
        }

    }

    private updatePurchaseOrderData = () => {
        const modalTitle: string = "Mise à jour du bon de commande";
        const modalRef = this._modalService.openModal(ModalAddPurchaseOrderComponent,
            {
                title: modalTitle,
                type: MODAL_TYPE.NORMAL,
                data: {
                    purchaseOrder: this._purchaseOrder,
                    isCreated: this._purchaseOrder.status === EnumPurchaseOrderStatus.CREATED,
                    onlyUpdateRemark: !!this._purchaseOrder.priceRequestId
                },
                closeAction: MODAL_CLOSE_ACTION.UPDATE
            }
        );
        if (modalRef) {
            modalRef.afterClosed.subscribe(res => {
                if (res.confirm && res.data) {
                    this.setPurchaseOrderDatas(res.data);
                }
            });
        }
    }

    private updateSupplierContact = () => {
        const modalTitle: string = "Modification du contact fournisseur";
        const modalRef = this._modalService.openModal(ModalUpdateSupplierOfferContactComponent, {
            title: modalTitle,
            type: MODAL_TYPE.NORMAL,
            data: {
                supplierId: this.supplierDataInformationCard.id,
                contactId: this.supplierDataInformationCard.contactId,
            }
        });
        if (modalRef) {
            modalRef.afterClosed.subscribe(res => {
                if (res.confirm && res.data) {
                    const resData: ISupplierOfferUpdate = res.data;
                    const purchaseOrderUpdate: IPurchaseOrderUpdate = {
                        supplierContactId: resData.supplierContactId
                    };
                    if (this._updatePurchaseOrderSub) { this._updatePurchaseOrderSub.unsubscribe(); }
                    this._updatePurchaseOrderSub = this._purchaseOrderQueriesSrv.updatePurchaseOrder(this._purchaseOrderId, purchaseOrderUpdate).subscribe((result: any) => {
                        const data: { updatePurchaseOrder: IPurchaseOrder } = result.data;
                        if (data && data.updatePurchaseOrder) {
                            this.setPurchaseOrderDatas(data.updatePurchaseOrder);
                            this._snackBar.open(modalTitle, "Le contact du bon de commande a été mis à jour", "success", 5000);
                        }
                    }, error => {
                        this._snackBar.openSnackBarError(modalTitle, "Le contact du bon de commande n'a pas été mis à jour", error);
                    });
                }
            });
        }
    }

    // General Methods
    public printPurchaseOrderPdf() {
        window.open(`${ApplicationConfig.Url}/api/purchaseOrders/${this._purchaseOrderId}.pdf`);
    }

    public beforeSendPurchaseOrder() {
        if (!this.purchaseOrderIsCancellable) {
            return;
        }
        this._modalService.openModal(ModalConfirmComponent,
            {
                title: "Êtes-vous sûr?",
                type: MODAL_TYPE.CONFIRM,
                data: {
                    message: `Voulez-vous envoyer le bon de commande <b>${this._purchaseOrder.reference}</b> à <b>${this._purchaseOrder.supplier.name}</b>?`,
                    actions: [],
                },
                params: { purchaseOrderId: this._purchaseOrderId },
                // confirmCallback: this.sendPurchaseOrder,
                confirmCallback: this.sendPurchaseOrder.bind(this),
                customData: {
                    deleteButtonName: "Envoyer",
                    deleteButtonColor: "none"
                }
            }
        );
    }

    public sendPurchaseOrder() {
        const snackBarTitle: string = "Envoi d'un bon de commande";
        this._purchaseOrderQueriesSrv.sendPurchaseOrder(this._purchaseOrderId).subscribe(result => {
            const resultData: { sendPurchaseOrderMail: boolean } = <any>result.data;
            if (resultData && resultData.sendPurchaseOrderMail) {
                this._snackBar.open(snackBarTitle, "Le bon de commande a été envoyé", "success", 5000);
            }
        }, error => {
            this._snackBar.openSnackBarError(snackBarTitle, "Erreur d'envoi du bon de commande", error);
        });
    }

    public selectElementToGenerateLabelsPdf() {
        const modalRef = this._modalService.openModal(ModalSelectPurchaseOrderElementsToGenerateLabelsPdfComponent, {
            title: "Sélection des éléments à imprimer",
            type: MODAL_TYPE.NORMAL,
            data: {
                purchaseOrderElements: this._purchaseOrderElements,
            },
            modalStyle: {
                modalWidth: "x-large"
            }
        });

        if (modalRef) {
            modalRef.afterClosed.subscribe(res => {
                if (res.confirm && res.data) {
                    this.generateLabelsPdf(res.data.stickerDatas);
                }
            });
        }
    }

    //   En résumé :
    // GET /api/purchaseOrderElements/stickers.pdf?elements=[{3,5},{4,1}]
    // Donnerait un PDF comprenant :
    // - 5 étiquettes du purchaseOrderElement 3
    // - 1 étiquette du purchaseOrderElement 4
    private generateLabelsPdf(stickerDatas: string) {
        if (stickerDatas) {
            window.open(`${ApplicationConfig.Url}/api/purchaseOrderElements/stickers.pdf?elements=${stickerDatas}`);
            setTimeout(() => {
                this.getPurchaseOrderData();
            }, 5000);
        } else {
            this._snackBar.open("Génération d'étiquettes", "Aucun élément n'a été sélectionné", "warning", 5000);
        }
    }




    public checkCustomElementActionType(data: { datas: any, type: string }) {
        if (data.datas.isAdditionnalCost) {
            if (data.type === "UPDATE") {
                this.modalInpdatePurchaseOrderAdditionnalCost(data.datas);
            } else if (data.type === "DELETE") {
                this.beforeDeletePurchaseOrderAdditionnalCost(data.datas);
            }
        } else {
            if (data.type === "UPDATE") {
                this.modalInpdatePurchaseOrderElement(data.datas.customElementDatas);
            } else if (data.type === "DELETE") {
                this.beforeDeletePurchaseOrderElement(data.datas.customElementDatas);
            }
        }
    }

    public modalInpdatePurchaseOrderElement(purchaseOrderElement?: IPurchaseOrderElement) {
        const modalRef = this._modalService.openModal(ModalInpdatePurchaseOrderElementComponent, {
            title: purchaseOrderElement ? "Mise à jour d'un élément du bon de commande" : "Ajout d'un élément au bon de commande",
            type: MODAL_TYPE.NORMAL,
            data: {
                purchaseOrderId: this._purchaseOrderId,
                purchaseOrderElement,
                purchaseOrder: this.purchaseOrderDataInformationCard
            },
            modalStyle: {
                modalWidth: "x-large"
            },
            closeAction: purchaseOrderElement ? MODAL_CLOSE_ACTION.UPDATE : MODAL_CLOSE_ACTION.SAVE
        });

        if (modalRef) {
            modalRef.afterClosed.subscribe(res => {
                // Nothing more to do ?
            });
        }
    }

    /**
     * @description Opens modal that permits user to either create a new Purchase Order Additionnal Cost or update an existing one
     * @author Quentin Wolfs
     * @param {IPurchaseOrderAdditionnalCost} [purchaseOrderAdditionnalCost]
     * @memberof PurchaseOrderDetailsComponent
     */
    public modalInpdatePurchaseOrderAdditionnalCost(purchaseOrderAdditionnalCost?: IPurchaseOrderAdditionnalCost) {
        const modalRef = this._modalService.openModal(ModalInpdatePurchaseOrderAdditionnalCostComponent, {
            title: !!purchaseOrderAdditionnalCost ? "Mise à jour d'un coût additionnel du bon de commande" : "Ajout d'un coût additionnel au bon de commande",
            type: MODAL_TYPE.NORMAL,
            data: {
                purchaseOrderId: this._purchaseOrderId,
                purchaseOrderAdditionnalCost
            },
            modalStyle: {
                modalWidth: "x-large",
            },
            closeAction: !!purchaseOrderAdditionnalCost ? MODAL_CLOSE_ACTION.UPDATE : MODAL_CLOSE_ACTION.SAVE
        });

        if (modalRef) {
            modalRef.afterClosed.subscribe(res => {
                // Nothing more to do atm
            });
        }
    }

    private beforeDeletePurchaseOrderElement(data: IPurchaseOrderElement) {
        this._modalService.openModal(ModalConfirmComponent,
            {
                title: "Êtes-vous sûr?",
                type: MODAL_TYPE.CONFIRM,
                data: {
                    message: `Voulez-vous supprimer l'élément <b>${data.denomination}</b>?`,
                    actions: [
                        "Supprimer l'élément de manière définitive",
                    ],
                },
                params: { elementId: data.id },
                confirmCallback: this.deletePurchaseOrderElement,
                customData: {
                    deleteButtonName: "Valider",
                }
            }
        );
    }

    private beforeDeletePurchaseOrderAdditionnalCost(data: IPurchaseOrderAdditionnalCost) {
        const isOtherType = Object.keys(UniqueSupplierOfferAdditionnalCostType).indexOf(data.type) === -1;
        const displayedDenomination = isOtherType ? data.denomination : UniqueSupplierOfferAdditionnalCostType[data.type];
        this._modalService.openModal(ModalConfirmComponent,
            {
                title: "Êtes-vous sûr?",
                type: MODAL_TYPE.CONFIRM,
                data: {
                    message: `Voulez-vous supprimer le coût additionnel <b>${displayedDenomination}</b>?`,
                    actions: [
                        "Supprimer le coût additionnel de manière définitive",
                    ],
                },
                params: { additionnalCostId: data.id },
                confirmCallback: this.deletePurchaseOrderAdditionnalCost,
                customData: {
                    deleteButtonName: "Valider",
                }
            }
        );
    }

    private deletePurchaseOrderElement = (params: { elementId: number }) => {
        const snackBarTitle: string = "Supprimer un élément du bon de commande";
        this._purchaseOrderQueriesSrv.deletePurchaseOrderElement(params.elementId, this._purchaseOrderId).subscribe((result: any) => {
            const resultData: { deletePurchaseOrderElement: boolean } = result.data;
            if (resultData && resultData.deletePurchaseOrderElement) {
                this._snackBar.open(snackBarTitle, "L'élément a été supprimé", "success", 5000);
            }
        }, error => {
            this._snackBar.openSnackBarError(snackBarTitle, "L'élément n'a pas été supprimé", error);
        });
    }

    private deletePurchaseOrderAdditionnalCost = (params: { additionnalCostId: number }) => {
        const snackBarTitle: string = "Supprimer un coût additionnel du bon de commande";
        this._purchaseOrderQueriesSrv.deletePurchaseOrderAdditionnalCost(params.additionnalCostId, this._purchaseOrderId).subscribe(result => {
            const resultData: any = result.data;
            if (resultData && resultData.deletePurchaseOrderAdditionnalCost) {
                this._snackBar.open(snackBarTitle, "Le coût additionnel a été supprimé", "success", 5000);
            }
        }, error => {
            this._snackBar.openSnackBarError(snackBarTitle, "Le coût additionnel n'a pas été supprimé", error);
        });
    }

    /**
     * @description Subscribe to action of updating element remark to Open modal of updating PO element remark
     * @author Lainez Eddy
     * @private
     * @memberof PriceRequestDetailsComponent
     */
    private updatePurchaseOrderElementRemark() {
        const modalTitle: string = "Mise à jour de la remarque";
      this._purchaseOrderElementUpdateSub = this._purchaseOrderTableSrv.subscribeElementUpdateRemark().subscribe(value => {
            const modalRef = this._modalService.openModal(ModalUpdatePriceRequestElementRemarkComponent, {
                title: modalTitle,
                type: MODAL_TYPE.NORMAL,
                data: value,
            });
            if (modalRef) {
                modalRef.afterClosed.subscribe(res => {
                    if (res.confirm && res.data) {
                        this._purchaseOrderQueriesSrv.updatePurchaseOrderElementRemark(value.id, res.data, this._purchaseOrderId).subscribe(result => {
                            this._snackBar.open(modalTitle, "La remarque a été mise à jour", "success", 5000);
                        }, error => {
                            this._snackBar.openSnackBarError(modalTitle, "La remarque n'a pas été mise à jour", error);
                        });
                    }
                });
            }
        });
    }

    /**
    * @description Subscribe to action of updating element format to Open modal of updating PO element format
    * @author Samir AFALLAH
    * @private
    * @memberof PriceRequestDetailsComponent
    */
    private updatePurchaseOrderElementFormat() {
        const modalTitle: string = "Mise à jour du format";
      this._purchaseOrderElementUpdateSub = this._purchaseOrderTableSrv.subscribeElementUpdateFormat().subscribe(value => {
            const modalRef = this._modalService.openModal(ModalUpdatePriceRequestElementFormatComponent, {
                title: modalTitle,
                type: MODAL_TYPE.NORMAL,
                data: value,
            });
            if (modalRef) {
                modalRef.afterClosed.subscribe(res => {
                    if (res.confirm && res.data) {
                        this._purchaseOrderQueriesSrv.updatePurchaseOrderElementFormat(value.id, res.data, this._purchaseOrderId).subscribe(result => {
                            this._snackBar.open(modalTitle, "Le format a été mis à jour", "success", 5000);
                        }, error => {
                            this._snackBar.openSnackBarError(modalTitle, "Le format n'a pas été mis à jour", error);
                        });
                    }
                });
            }
        });
    }


    /**
     * @description Subscribe to action of updating element denomination to Open modal of updating PO element denomination
     * @author Samir AFALLAH
     * @private
     * @memberof PriceRequestDetailsComponent
     */
    private updatePurchaseOrderElementDenomination() {
        const modalTitle: string = "Mise à jour de la dénomination";
      this._purchaseOrderElementUpdateSub = this._purchaseOrderTableSrv.subscribeElementUpdateDenomination().subscribe(value => {
            const modalRef = this._modalService.openModal(ModalUpdatePriceRequestElementDenominationComponent, {
                title: modalTitle,
                type: MODAL_TYPE.NORMAL,
                data: value,
            });
            if (modalRef) {
                modalRef.afterClosed.subscribe(res => {
                    if (res.confirm && res.data) {
                        this._purchaseOrderQueriesSrv.updatePurchaseOrderElementDenomination(value.id, res.data, this._purchaseOrderId).subscribe(result => {
                            this._snackBar.open(modalTitle, "La denomination a été mise à jour", "success", 5000);
                        }, error => {
                            this._snackBar.openSnackBarError(modalTitle, "La denomination n'a pas été mise à jour", error);
                        });
                    }
                });
            }
        });
    }

    /**
     * @description Subscribe to action of updating element Quantity to Open modal of updating PO element Quantity
     * @author Samir AFALLAH
     * @private
     * @memberof PriceRequestDetailsComponent
     */
    private updatePurchaseOrderElementQuantity() {
        const modalTitle: string = "Mise à jour de la quantité";
      this._purchaseOrderElementUpdateSub = this._purchaseOrderTableSrv.subscribeElementUpdateQuantity().subscribe(value => {
            const modalRef = this._modalService.openModal(ModalUpdatePriceRequestElementQuantityComponent, {
                title: modalTitle,
                type: MODAL_TYPE.NORMAL,
                data: value,
            });
            if (modalRef) {
                modalRef.afterClosed.subscribe(res => {
                    if (res.confirm && res.data) {
                        this._purchaseOrderQueriesSrv.updatePurchaseOrderElementQuantity(value.id, res.data, this._purchaseOrderId).subscribe(result => {
                            this._snackBar.open(modalTitle, "La quantité a été mise à jour", "success", 5000);
                        }, error => {
                            this._snackBar.openSnackBarError(modalTitle, "La quantité n'a pas été mise à jour", error);
                        });
                    }
                });
            }
        });
    }

    /**
 * @description Subscribe to action of updating element QuantityUnit to Open modal of updating PO element QuantityUnit
 * @author Samir AFALLAH
 * @private
 * @memberof PriceRequestDetailsComponent
 */
    private updatePurchaseOrderElementQuantityUnit() {
        const modalTitle: string = "Mise à jour de l'unité";
      this._purchaseOrderElementUpdateSub = this._purchaseOrderTableSrv.subscribeElementUpdateQuantityUnit().subscribe(value => {
            const modalRef = this._modalService.openModal(ModalUpdatePriceRequestElementQuantityUnitComponent, {
                title: modalTitle,
                type: MODAL_TYPE.NORMAL,
                data: value,
            });
            if (modalRef) {
                modalRef.afterClosed.subscribe(res => {
                    if (res.confirm && res.data) {
                        this._purchaseOrderQueriesSrv.updatePurchaseOrderElementQuantityUnit(value.id, res.data, this._purchaseOrderId).subscribe(result => {
                            this._snackBar.open(modalTitle, "L'unité a été mise à jour", "success", 5000);
                        }, error => {
                            this._snackBar.openSnackBarError(modalTitle, "L'unité n'a pas été mise à jour", error);
                        });
                    }
                });
            }
        });
    }

    /**
    * @description Subscribe to action of updating element En1090 to Open modal of updating PO element En1090
    * @author Samir AFALLAH
    * @private
    * @memberof PriceRequestDetailsComponent
    */
    private updatePurchaseOrderElementEn1090() {
        const modalTitle: string = "Mise à jour de En1090";
      this._purchaseOrderElementUpdateSub = this._purchaseOrderTableSrv.subscribeElementUpdateEn1090().subscribe(value => {
            const modalRef = this._modalService.openModal(ModalUpdatePriceRequestElementEn1090Component, {
                title: modalTitle,
                type: MODAL_TYPE.NORMAL,
                data: value,
            });
            if (modalRef) {
                modalRef.afterClosed.subscribe(res => {
                    if (res.confirm && res.data) {
                        this._purchaseOrderQueriesSrv.updatePurchaseOrderElementEn1090(value.id, res.data, this._purchaseOrderId).subscribe(result => {
                            this._snackBar.open(modalTitle, "Le En1090 a été mis à jour", "success", 5000);
                        }, error => {
                            this._snackBar.openSnackBarError(modalTitle, "Le En1090 n'a pas été mise à jour", error);
                        });
                    }
                });
            }
        });
    }

    /**
    * @description Subscribe to action of updating element option to Open modal of updating PO element option
    * @author Samir AFALLAH
    * @private
    * @memberof PriceRequestDetailsComponent
    */
    private updatePurchaseOrderElementOption() {
        const modalTitle: string = "Mise à jour de l'option";
      this._purchaseOrderElementUpdateSub = this._purchaseOrderTableSrv.subscribeElementUpdateOption().subscribe(value => {
            const modalRef = this._modalService.openModal(ModalUpdatePriceRequestElementOptionsComponent, {
                title: modalTitle,
                type: MODAL_TYPE.NORMAL,
                data: value,
            });
            if (modalRef) {
                modalRef.afterClosed.subscribe(res => {
                    if (res.confirm && res.data) {
                        this._purchaseOrderQueriesSrv.updatePurchaseOrderElementOption(value.id, res.data, this._purchaseOrderId).subscribe(result => {
                            this._snackBar.open(modalTitle, "L'option a été mis à jour", "success", 5000);
                        }, error => {
                            this._snackBar.openSnackBarError(modalTitle, "L'option n'a pas été mise à jour", error);
                        });
                    }
                });
            }
        });
    }

    /**
    * @description Subscribe to action of updating element Matiere to Open modal of updating PO element Matiere
    * @author Samir AFALLAH
    * @private
    * @memberof PriceRequestDetailsComponent
    */
    private updatePurchaseOrderElementMatiere() {
        const modalTitle: string = "Mise à jour de la matière";
      this._purchaseOrderElementUpdateSub = this._purchaseOrderTableSrv.subscribeElementUpdateMatiere().subscribe(value => {
            const modalRef = this._modalService.openModal(ModalUpdatePriceRequestElementMatiereComponent, {
                title: modalTitle,
                type: MODAL_TYPE.NORMAL,
                data: value,
            });
            if (modalRef) {
                modalRef.afterClosed.subscribe(res => {
                    if (res.confirm && res.data) {
                        this._purchaseOrderQueriesSrv.updatePurchaseOrderElementMatiere(value.id, res.data, this._purchaseOrderId).subscribe(result => {
                            this._snackBar.open(modalTitle, "La matière a été mise à jour", "success", 5000);
                        }, error => {
                            this._snackBar.openSnackBarError(modalTitle, "La matière n'a pas été mise à jour", error);
                        });
                    }
                });
            }
        });
    }

    /**
    * @description Subscribe to action of updating element Weight to Open modal of updating PO element Weight
    * @author Samir AFALLAH
    * @private
    * @memberof PriceRequestDetailsComponent
    */
    private updatePurchaseOrderElementWeight() {
        const modalTitle: string = "Mise à jour du poids";
      this._purchaseOrderElementUpdateSub = this._purchaseOrderTableSrv.subscribeElementUpdateWeight().subscribe(value => {
            const modalRef = this._modalService.openModal(ModalUpdatePriceRequestElementWeightComponent, {
                title: modalTitle,
                type: MODAL_TYPE.NORMAL,
                data: value,
            });
            if (modalRef) {
                modalRef.afterClosed.subscribe(res => {
                    if (res.confirm && res.data) {
                        this._purchaseOrderQueriesSrv.updatePurchaseOrderElementWeight(value.id, res.data, this._purchaseOrderId).subscribe(result => {
                            this._snackBar.open(modalTitle, "Le poids a été mis à jour", "success", 5000);
                        }, error => {
                            this._snackBar.openSnackBarError(modalTitle, "Le poids n'a pas été misà jour", error);
                        });
                    }
                });
            }
        });
    }

    /**
    * @description Subscribe to action of updating element Width to Open modal of updating PO element Width
    * @author Samir AFALLAH
    * @private
    * @memberof PriceRequestDetailsComponent
    */
    private updatePurchaseOrderElementWidth() {
        const modalTitle: string = "Mise à jour de la largeur";
      this._purchaseOrderElementUpdateSub = this._purchaseOrderTableSrv.subscribeElementUpdateWidth().subscribe(value => {
            const modalRef = this._modalService.openModal(ModalUpdatePriceRequestElementWidthComponent, {
                title: modalTitle,
                type: MODAL_TYPE.NORMAL,
                data: value,
            });
            if (modalRef) {
                modalRef.afterClosed.subscribe(res => {
                    if (res.confirm && res.data) {
                        this._purchaseOrderQueriesSrv.updatePurchaseOrderElementWidth(value.id, res.data, this._purchaseOrderId).subscribe(result => {
                            this._snackBar.open(modalTitle, "La largeur a été mise à jour", "success", 5000);
                        }, error => {
                            this._snackBar.openSnackBarError(modalTitle, "La largeur n'a pas été mise à jour", error);
                        });
                    }
                });
            }
        });
    }

    /**
    * @description Subscribe to action of updating element Length to Open modal of updating PO element Length
    * @author Samir AFALLAH
    * @private
    * @memberof PriceRequestDetailsComponent
    */
    private updatePurchaseOrderElementLength() {
        const modalTitle: string = "Mise à jour de la longueur";
      this._purchaseOrderElementUpdateSub = this._purchaseOrderTableSrv.subscribeElementUpdateLength().subscribe(value => {
            const modalRef = this._modalService.openModal(ModalUpdatePriceRequestElementLengthComponent, {
                title: modalTitle,
                type: MODAL_TYPE.NORMAL,
                data: value,
            });
            if (modalRef) {
                modalRef.afterClosed.subscribe(res => {
                    if (res.confirm && res.data) {
                        this._purchaseOrderQueriesSrv.updatePurchaseOrderElementLength(value.id, res.data, this._purchaseOrderId).subscribe(result => {
                            this._snackBar.open(modalTitle, "La longueur a été mise à jour", "success", 5000);
                        }, error => {
                            this._snackBar.openSnackBarError(modalTitle, "La longueur n'a pas été mise à jour", error);
                        });
                    }
                });
            }
        });
    }

    /**
    * @description Subscribe to action of updating element Thickness to Open modal of updating PO element Thickness
    * @author Samir AFALLAH
    * @private
    * @memberof PriceRequestDetailsComponent
    */
    private updatePurchaseOrderElementThickness() {
        const modalTitle: string = "Mise à jour de l'epaisseur";
      this._purchaseOrderElementUpdateSub = this._purchaseOrderTableSrv.subscribeElementUpdateThickness().subscribe(value => {
            const modalRef = this._modalService.openModal(ModalUpdatePriceRequestElementThicknessComponent, {
                title: modalTitle,
                type: MODAL_TYPE.NORMAL,
                data: value,
            });
            if (modalRef) {
                modalRef.afterClosed.subscribe(res => {
                    if (res.confirm && res.data) {
                        this._purchaseOrderQueriesSrv.updatePurchaseOrderElementThickness(value.id, res.data, this._purchaseOrderId).subscribe(result => {
                            this._snackBar.open(modalTitle, "L'epaisseur a été mise à jour", "success", 5000);
                        }, error => {
                            this._snackBar.openSnackBarError(modalTitle, "L'epaisseur n'a pas été mise à jour", error);
                        });
                    }
                });
            }
        });
    }

  /**
  * @description Subscribe to action of updating element price to Open modal of updating element price
  * @author Hassan EL BAROUDI
  * @private
  * @memberof PurchaseOrderDetailsComponent
  */
  private updatePurchaseOrderElementPrice() {
    const modalTitle: string = "Mise à jour du prix";
    this._purchaseOrderElementUpdateSub = this._purchaseOrderTableSrv.subscribeElementUpdatePrice().subscribe(value => {
      const modalRef = this._modalService.openModal(ModalUpdatePriceRequestElementPriceComponent, {
        title: modalTitle,
        type: MODAL_TYPE.NORMAL,
        data: value,
      });
      if (modalRef) {
        modalRef.afterClosed.subscribe(res => {
          if (res.confirm && res.data) {
            this._purchaseOrderQueriesSrv.updatePurchaseOrderElementPrice(value.id, res.data, this._purchaseOrderId).subscribe(result => {
              this._snackBar.open(modalTitle, "Le prix a été mise à jour", "success", 5000);
            }, error => {
              this._snackBar.openSnackBarError(modalTitle, "Le prix n'a pas été mise à jour", error);
            });
          }
        });
      }
    });
  }

  /**
  * @description Subscribe to action of updating element deliveryDate to Open modal of updating element deliveryDate
  * @author Hassan EL BAROUDI
  * @private
  * @memberof PurchaseOrderDetailsComponent
  */
  private updatePurchaseOrderElementDeliveryDate() {
    const modalTitle: string = "Mise à jour de la date";
    this._purchaseOrderElementUpdateSub = this._purchaseOrderTableSrv.subscribeElementUpdateDeliveryDate().subscribe(value => {
      const modalRef = this._modalService.openModal(ModalUpdatePriceRequestElementDeliveryDateComponent, {
        title: modalTitle,
        type: MODAL_TYPE.NORMAL,
        data: value,
      });
      if (modalRef) {
        modalRef.afterClosed.subscribe(res => {
          if (res.confirm && res.data) {
            this._purchaseOrderQueriesSrv.updatePurchaseOrderElementDeliveryDate(value.id, res.data, this._purchaseOrderId).subscribe(result => {
              this._snackBar.open(modalTitle, "La date a été mise à jour", "success", 5000);
            }, error => {
              this._snackBar.openSnackBarError(modalTitle, "La date n'a pas été mise à jour", error);
            });
          }
        });
      }
    });
  }

    // HEADER METHODS
    public returnAction() {
        this.router.navigate(["/purchase-order/"]);
    }

    public beforeDeletePurchaseOrder() {
        this._modalService.openModal(ModalConfirmComponent,
            {
                title: "Êtes-vous sûr?",
                type: MODAL_TYPE.CONFIRM,
                data: {
                    message: `Voulez-vous annuler le bon de commande <b>${this._purchaseOrder.reference}</b>?`,
                    actions: [
                        "Annuler le bon de commande de manière définitive",
                    ],
                },
                params: { purchaseOrderId: this._purchaseOrderId },
                confirmCallback: this.deletePurchaseOrder,
                customData: {
                    deleteButtonName: "Valider",
                }
            }
        );
    }

    public deletePurchaseOrder = (params: { purchaseOrderId: number }) => {
        const snackBarTitle: string = "Annuler un bon de commande";
        this._purchaseOrderQueriesSrv.deletePurchaseOrder(params.purchaseOrderId).subscribe((result: any) => {
            const resultData: { deletePurchaseOrder: boolean } = result.data;
            if (resultData && resultData.deletePurchaseOrder) {
                this._snackBar.open(snackBarTitle, "Le bon de commande a été annulé", "success", 5000);
            } else {
                this._snackBar.openSnackBarError(snackBarTitle, "Le bon de commande n'a pas été annulé");
            }
        }, error => {
            this._snackBar.openSnackBarError(snackBarTitle, "Le bon de commande n'a pas été annulé", error);
        });
    }
}
