import { Component, OnInit, Inject, Renderer2, OnDestroy } from "@angular/core";
import { ModalContentForm } from "../../model/modal-content-form";
import { ModalService, DATA } from "../../services/modal.service";
import { SnackService } from "app/presentationnal/organisms/snack-bar/services/snack.service";
import { FormBuilder, Validators, FormGroup, FormArray, FormControl } from "@angular/forms";
import { IPurchaseOrderElementInput, ICustomPurchaseOrderElementForm, ICustomPurchaseOrderElementOptionForm, IPurchaseOrderElementUpdate, IPurchaseOrderElement, IPurchaseOrderElementOption, ICustomPurchaseOrder } from "app/facade/interfaces/purchase-order.interface";
import { ISelectOption } from "app/presentationnal/atoms/inputs/select-input/selectOptions";
import { PriceRequestElementOptionType, PriceRequestElementOptionUnit } from "app/facade/enums/price-request-element-option-datas.enum";
import * as moment from "moment";
import { ElementUnitCategory, EnumElementUnit, EnumElementUnitSelectValue, EnumQuantityUnit, EnumQuantityUnitSelectValue, EnumUnitValue } from "app/facade/enums/element-unit-category.enum";
import { PurchaseOrderQueriesService } from "app/facade/queries/purchase-order/purchase-order-queries.service";
import { IModalData } from "../../interfaces/modal-data.interface";
import { MODAL_CLOSE_ACTION } from "../../enums/modal-close-action.enum";
import { IAvailableMatters, IElements, ISupplyCategory, ISupplyFormData } from "app/facade/interfaces/supplier";
import { ElementUnitConfig } from "app/facade/configs/price-request-unit.config";
import { ISupplyListFieldsConfig } from "app/facade/interfaces/fields-config.interface";
import { FIELDS_DEFAULT_VALUE, FIELDS_SPECIAL_KEY, FIELDS_TYPE_CONFIG, SUPPLY_LIST_TABLE_CONFIG } from "app/facade/enums/fields-default-value";
import { ISupplyList, ISupplyListElement } from "app/facade/interfaces/project";
import { CategoriesQueriesService } from "app/facade/queries/category/categories-queries.service";
import { ProjectsQueriesService } from "app/facade/queries/project/projects-queries.service";
import { CatalogQueriesService } from "app/facade/queries/catalog/async/catalog_queries.service";
import { ITableFormExecuteAction } from "app/facade/interfaces/tables.interface";
import { ITabs } from "app/facade/interfaces/tabs";
import { IBoxInfoConfig } from "app/facade/interfaces/box-info.interface";
import { LoginComponent } from "app/presentationnal/organisms/login/login.component";
import { Reference } from "@angular/compiler/src/render3/r3_ast";

interface IPurchaseOrderElementModalData extends IModalData {
  supplyListData: ISupplyList;
  projectId: number;
  data: {
    purchaseOrderId: number;
    purchaseOrderElement: IPurchaseOrderElement;
    purchaseOrder: ICustomPurchaseOrder;
  };
}

@Component({
  selector: "app-modal-inpdate-purchase-order-element",
  templateUrl: "./modal-inpdate-purchase-order-element.component.html",
  styleUrls: ["./modal-inpdate-purchase-order-element.component.css"]
})
export class ModalInpdatePurchaseOrderElementComponent extends ModalContentForm implements OnInit, OnDestroy {
  private _purchaseOrderId: number;
  private _supplyListData: ISupplyList;
  private _supplyCategories: ISupplyCategory[] = [];
  private _textUndefined: string[] = [
    "Le système a rencontré des éléments ne faisant pas partis du catalogue de l'application.",
    "Ces éléments sont ",
    "Si vous souhaitez que l'application puisse reconnaître automatiquement ces éléments à l'avenir, il vous suffit de contacter la société ITDM."];
  private _textUnselectedWithElement: string = "Des catégories ou sous-catégories ont été désélectionnées alors qu'elles contenaient des éléments. En sauvegardant, les éléments des catégories suivantes seront effacés: ";
  private isBlack: boolean;
  private isBlasted: boolean;
  private isPrimaryBlasted: boolean;
  public undefinedElements: ISupplyListElement[] = [];
  public projectId: number;

  public undefinedElementAlert: IBoxInfoConfig = {
    type: "warning",
    title: "Attention",
    textContent: `${this._textUndefined[0]} ${this._textUndefined[2]}`
  };
  public categoriesUnselectedWithElement: Set<string> = new Set<string>();
  public categoriesUnselectedWithElementAlert: IBoxInfoConfig = {
    type: "danger",
    title: "Attention",
    textContent: this._textUnselectedWithElement
  };
  private _purchaseOrderElement: IPurchaseOrderElement;
  private _deletedOptionsIds: number[] = [];
  private handlerOption: any;
  private _purchaseOrderElementInput: IPurchaseOrderElementInput;
  private _purchaseOrderElementUpdate: IPurchaseOrderElementUpdate;
  private tableConfig: any = SUPPLY_LIST_TABLE_CONFIG;
  public categoriesData: ISupplyFormData[] = [];
  private _saveForm : ISupplyFormData[] = [];

  public optionElementUnits: ISelectOption[] = [];
  public optionTypes: ISelectOption[] = [];
  public optionUnits: ISelectOption[] = [];
  public confirmDeleteOptionClass: string = "confirmDeleteOptionAction";
  public formGroupOptions: FormArray;
  public fieldsConfig: ISupplyListFieldsConfig = FIELDS_TYPE_CONFIG;
  public subCategoryFormsArray: { parentId: number, form: FormArray}[] = [];
  public categoryFormArray: FormArray;
  public formInputFileGroup: FormGroup;
  public disableFormInput: boolean = false;
  public subCategoryIndexSelected: number = -1;
  public categoryFormSelected: ISupplyFormData;
  public tabsData: ITabs[] = [];
  public categoryHasElementGroup: number[] = [];
  public filterTable: string = "";
  public tableFormLoaded: boolean = false;
  public showContent: boolean = false;
  public lastUpdatedCategoryId: number = null;
  public projectOption: ISelectOption[] = [];;
  public data : any;

  constructor(
    protected _modalService: ModalService,
    protected _snackBar: SnackService,
    protected _fb: FormBuilder,
    private _purchaseOrderQueriesSrv: PurchaseOrderQueriesService,
    private _projectSrv : ProjectsQueriesService,
    @Inject(DATA) private _data: IPurchaseOrderElementModalData,
    private _categoriesQueriesSrv: CategoriesQueriesService,
    private _projectsQueriesSrv: ProjectsQueriesService,
    private _catalogQueriesSrv: CatalogQueriesService,
    private _renderer: Renderer2) {
      super(_modalService, _fb, _snackBar);
      this._purchaseOrderId = this._data.data.purchaseOrderId;
      this._purchaseOrderElement = this._data.data.purchaseOrderElement;
  }

  ngOnInit() {
    this.initFormData();
    this.setHeadersActionsList();
    this.getInitData();
    //get liked project in purchaseOrder
    let mapProject = this._data.data.purchaseOrder.project ;
    let refProject = mapProject.split(', ');

    //add project option
    for (let index = 0; index < refProject.length; index++) {
      const element = refProject[index];
      this.fieldsConfig.project.options.push({label:element,value:element});
    }
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  private initFormData() {
    this.optionElementUnits = Object.keys(EnumElementUnitSelectValue).map( unit => {
      return {
        value: unit,
        label: EnumElementUnitSelectValue[unit]
      };
    });

    this.optionTypes = Object.keys(PriceRequestElementOptionType).map( type => {
      return {
        value: type,
        label: PriceRequestElementOptionType[type]
      };
    });

    this.optionUnits = Object.keys(PriceRequestElementOptionUnit).map( unit => {
      return {
        value: unit,
        label: PriceRequestElementOptionUnit[unit]
      };
    });
    this._formGroup = this._fb.group(this.setPurchaseOrderElementInputForm(this._purchaseOrderElement));
    const tempFormGroups: FormGroup[] = this._purchaseOrderElement ? this._purchaseOrderElement.options.map( option => {
      return this._fb.group(this.setPurchaseOrderElementOptionInputForm(option));
    }) : [];
    tempFormGroups.push(this._fb.group(this.setPurchaseOrderElementOptionInputForm()));
    this.formGroupOptions = this._fb.array(tempFormGroups);
  }

  private setPurchaseOrderElementInputForm(element?: IPurchaseOrderElement): ICustomPurchaseOrderElementForm | any {
    return {
      id: element ? element.id : null,
      quantity: [element ? element.quantity : null],
      quantityUnit: element ? this.displayQuantityUnit(element.quantityUnit) : null,
      denomination: [element ? element.denomination : null],
      remark: element ? element.remark : null,
      deliveryDate: element ? element.deliveryDate : null,
      deliveryDateFormated: element && element.deliveryDate ? moment.unix(+element.deliveryDate).format("DD/MM/YYYY") : null,
      price: element ? element.price : null,
      unit: element ? element.unit : EnumElementUnit.EURO_BY_UNIT,
      purchaseOrderId: [this._purchaseOrderId, Validators.required],
    };
  }

  private displayQuantityUnit(quantityUnit: string) {
    switch (quantityUnit) {
      case EnumQuantityUnit.UNIT: return EnumQuantityUnitSelectValue.UNIT;
      case EnumQuantityUnit.TON: return EnumQuantityUnitSelectValue.TON;
      // case EnumQuantityUnit.KG: return EnumQuantityUnitSelectValue.KG;
      default: return quantityUnit;
    }
  }

  private setPurchaseOrderElementOptionInputForm(option?: IPurchaseOrderElementOption): ICustomPurchaseOrderElementOptionForm | any {
    return {
      id: option ? option.id : null,
      type: [option ? option.type : this.optionTypes[0].value, Validators.required],
      denomination: option ? option.denomination : null,
      quantity: [option ? option.quantity : null, Validators.required],
      price: option ? option.price : null,
      unit: [option ? option.unit : this.optionUnits[0].value, Validators.required],
      canBeDeleted: option ? true : false,
      printDeleteConfirmation: false
    };
  }



  public addOption(index: number) {
    const formOption: FormGroup = (<FormGroup>this.formGroupOptions.controls[index]);
    if (formOption && formOption.valid) {
      formOption.get("canBeDeleted").patchValue(true);
      this.formGroupOptions.push(
        this._fb.group(this.setPurchaseOrderElementOptionInputForm())
      );
    } else {
      this.markFormGroupTouched(formOption);
    }
  }

  public printDeleteConfirmation(index: number, value: boolean = true) {
    if ( this.handlerOption ) { setTimeout( () => this.handlerOption(), 0); }
    const formOption: FormGroup = (<FormGroup>this.formGroupOptions.controls[index]);
    formOption.get("printDeleteConfirmation").patchValue(value);
    if (value) {
      setTimeout( () => {
        this.handlerOption = this._renderer.listen(document, "click", event => this.checkCancelOptionAction(event, index));
      }, 100);
    }
  }

  private checkCancelOptionAction(event, index: number) {
    if (event.target.className.indexOf(this.confirmDeleteOptionClass) === -1) {
      this.printDeleteConfirmation(index, false);
    }
  }

  public deleteOption(index: number) {
    this.handlerOption();
    const formOption: FormGroup = (<FormGroup>this.formGroupOptions.controls[index]);
    if (formOption.get("id").value) {
      this._deletedOptionsIds.push(formOption.get("id").value);
    }
    this.formGroupOptions.removeAt(index);
  }

  public confirmModal() {
    const addedOption: FormArray = this._fb.array(this.formGroupOptions.controls
      .filter( control => control.get("canBeDeleted").value));
    if (this._formGroup.valid && addedOption.valid) {
      switch (this._data.closeAction) {
        case MODAL_CLOSE_ACTION.SAVE:
          this.saveNewPurchaseOrderElement();
          break;
        case MODAL_CLOSE_ACTION.UPDATE:
          this.updatePurchaseOrderElement();
          break;
      }
    } else {
      this.markFormGroupTouched(this._formGroup);
      this.formGroupOptions.controls
      .filter( control => control.get("canBeDeleted").value).forEach( formGroup => {
        this.markFormGroupTouched(<FormGroup>formGroup);
      });
    }
  }

  /**
   * @description Format form value for new PurchaseOrderElement creation
   * @author Quentin Wolfs
   * @private
   * @returns {IPurchaseOrderElementInput}
   * @memberof ModalInpdatePurchaseOrderElementComponent
   */
  private formatValueForCreate(): IPurchaseOrderElementInput {
    const elementFormValue: ICustomPurchaseOrderElementForm  = { ...this._formGroup.value };
    const elementOptionFormValue: ICustomPurchaseOrderElementOptionForm[] = [ ...this.formGroupOptions.value ];

    return {
      quantity: elementFormValue.quantity,
      quantityUnit: elementFormValue.quantityUnit,
      denomination: elementFormValue.denomination,
      remark: elementFormValue.remark,
      deliveryDate: elementFormValue.deliveryDate,
      price: elementFormValue.price,
      unit: elementFormValue.unit,
      purchaseOrderId: elementFormValue.purchaseOrderId,
      options: elementOptionFormValue.filter(option => option.canBeDeleted).map(option => {
        return {
          type: option.type,
          denomination: option.denomination,
          quantity: option.quantity,
          price: option.price,
          unit: option.unit
        };
      })
    };
  }

  /**
  * @description Format form value for new PurchaseOrderElement update
  * @author Quentin Wolfs
  * @private
  * @returns {IPurchaseOrderElementUpdate}
  * @memberof ModalInpdatePurchaseOrderElementComponent
  */
  private formatValueForUpdate(): IPurchaseOrderElementUpdate {
    const elementFormValue: ICustomPurchaseOrderElementForm  = { ...this._formGroup.value };
    const elementOptionFormValue: ICustomPurchaseOrderElementOptionForm[] = [ ...this.formGroupOptions.value ];

    return {
      id: elementFormValue.id,
      quantity: elementFormValue.quantity,
      quantityUnit: elementFormValue.quantityUnit,
      denomination: elementFormValue.denomination,
      remark: elementFormValue.remark,
      deliveryDate: elementFormValue.deliveryDate,
      price: elementFormValue.price,
      unit: elementFormValue.unit,
      options: elementOptionFormValue.filter(option => option.canBeDeleted).map(option => {
        return {
          id: option.id,
          type: option.type,
          denomination: option.denomination,
          quantity: option.quantity,
          price: option.price,
          unit: option.unit
        };
      }),
      deletedOptionIds: this._deletedOptionsIds
    };
  }

  protected save(data: IPurchaseOrderElement): void {
    const objData = {
      confirm: true,
      data
    };
    this.afterClosed.next(objData);
  }

  /**
   * @description Send request to save new Purchase Order Element. Closes the modal if successfull, displays error if not
   * @author Quentin Wolfs
   * @private
   * @memberof ModalInpdatePurchaseOrderElementComponent
   */
  private saveNewPurchaseOrderElement(): void {
    const elementFormValue: ICustomPurchaseOrderElementForm  = { ...this._formGroup.value };
    const elementOptionFormValue: ICustomPurchaseOrderElementOptionForm[] = [ ...this.formGroupOptions.value ];
    const snackBarTitle: string = "Ajout d'un élément";

    //forEach tab saveForm to create purchaseOrderElement
    for (let index = 0; index < this._saveForm.length; index++) {
      const element = this._saveForm[index];
      for (let index = 0; index < element.data.value.length; index++) {
        const value = element.data.value[index];

        if(value.elementOptions === "isPrimaryBlasted"){
          this.isPrimaryBlasted = true;
        }
        if(value.elementOptions === "isBlack"){
          this.isBlack = true;
        }
        if(value.elementOptions === "isBlasted"){
          this.isBlasted = true;
        }
          this.data = {
            quantity: value.quantity,
            quantityUnit: value.quantityUnit,
            denomination: value.denomination,
            remark: value.remark,
            deliveryDate: value.price,
            unit:elementFormValue.unit,
            purchaseOrderId: elementFormValue.purchaseOrderId,
            supplyCategoryId: element.id,
            options: elementOptionFormValue.filter(option => option.canBeDeleted).map(option => {
              return {
                type: option.type,
                denomination: option.denomination,
                quantity: option.quantity,
                price: option.price,
                unit: option.unit
              };
            }),
            projectRef:value.project,
            isEn1090: value.isEn1090,
            isBlack: this.isBlack,
            isBlasted: this.isBlasted,
            isPrimaryBlasted: this.isPrimaryBlasted,
            format: value.format,
            weight: value.weight,
            matterRef: value.matterRef,
            thickness: value.thickness,
            length: value.length,
            width: value.width,
            isPrinted: value.isPrinted,
            printedQuantity: value.printedQuantity,
            supplierOfferElementId: value.elementId
          }
        this._purchaseOrderQueriesSrv.createPurchaseOrderElement(this.data, this._data.data.purchaseOrderId).subscribe(result => {
          const resultData: any = result.data;
          if (resultData && resultData.createPurchaseOrderElement) {
            this._snackBar.open(snackBarTitle, "L'élément a été ajouté au bon de commande", "success", 5000);
            // Save was successfull, closing the modal with saved data
            this.save(resultData.createPurchaseOrderElement);
          } else {
            this._snackBar.openSnackBarError(snackBarTitle, "L'élément n'a pas été ajouté au bon de commande");
          }
        }, error => {
          this._snackBar.openSnackBarError(snackBarTitle, "L'élément n'a pas été ajouté au bon de commande", error);
        });
      }

    }

    this._modalService.closeModal();
  }

  /**
   * @description Delete a row in a parent or child category's FormArray (attr "data")
   * @author Lainez Eddy
   * @param {{ rowId: string, categoryId: number}} eventData
   * @memberof ModalInpdatePurchaseOrderElementComponent
   */
     public deleteCategoryData(eventData: { rowId: string, categoryId: number}) {
      const category: ISupplyCategory = this._supplyCategories.find( cat => +cat.id === +eventData.categoryId );
      let parentCategoryForm: ISupplyFormData,
          categoryForm: ISupplyFormData;
      if (category.parentSupplyCategoryId) {
        parentCategoryForm = this.categoriesData.find( catForm => catForm.id === +category.parentSupplyCategoryId);
        categoryForm = parentCategoryForm.subCategories.find( subCatForm => subCatForm.id === +eventData.categoryId);
      } else {
        categoryForm = this.categoriesData.find( catForm => catForm.id === +category.id);
      }
      categoryForm.data.removeAt(+eventData.rowId);
      this._snackBar.open("Suppression d'un élémént", "L'élément a bien été supprimé", "success", 3000);
    }

  /**
   * @description Send request to update existing Purchase Order Element. Closes the modal if successfull, displays error if not
   * @author Quentin Wolfs
   * @private
   * @memberof ModalInpdatePurchaseOrderElementComponent
   */
  private updatePurchaseOrderElement(): void {
    const snackBarTitle: string = "Mise à jour d'un élément";
    this._purchaseOrderQueriesSrv.updatePurchaseOrderElement(this._data.data.purchaseOrderElement.id, this.formatValueForUpdate(), this._data.data.purchaseOrderId).subscribe( result => {
      const resultData: any = result.data;
      if (resultData && resultData.updatePurchaseOrderElement) {
        this._snackBar.open(snackBarTitle, "L'élément a été mis à jour", "success", 5000);

        // Update was successfull, closing the modal with updated data
        this.save(resultData.updatePurchaseOrderElement);
        this._modalService.closeModal();
      } else {
        this._snackBar.openSnackBarError(snackBarTitle, "L'élément n'a pas été mis à jour");
      }
    }, error => {
      this._snackBar.openSnackBarError(snackBarTitle, "L'élément n'a pas été mis à jour", error);
    });
  }

  //*************ModalAddSupplyListComponent*************

  /**
   * @description Generate FormArray for each child category
   * @author Lainez Eddy
   * @private
   * @memberof ModalInpdatePurchaseOrderElementComponent
   */
  private generateSubCategoryForms() {
    this._supplyCategories.filter( cat => cat.parentSupplyCategoryId === null).forEach( parentCat => {
      this.subCategoryFormsArray.push({
        parentId: +parentCat.id,
        form: this.generateCategoryFormArray(this._supplyCategories.filter( cat => +cat.parentSupplyCategoryId === +parentCat.id))
      });
    });
  }

  /**
   * @description Generate the formArray containing the ISupplyFormData for each parent categories
   * @author Lainez Eddy
   * @private
   * @param {ISupplyCategory[]} categories > Parent categories only
   * @returns {FormArray}
   * @memberof ModalInpdatePurchaseOrderElementComponent
   */
  private generateCategoryFormArray(categories: ISupplyCategory[]): FormArray {
    const formArray = new FormArray([]);
    categories.forEach( category => {
      this.generateCategoryFormData(category);
      formArray.push( this.generateCategoryFormGroup(category));
    });
    return formArray;
  }

  /**
   * @description Generate formGroup for each parent category checkbox
   * @author Lainez Eddy
   * @private
   * @param {*} category
   * @returns {FormGroup}
   * @memberof ModalInpdatePurchaseOrderElementComponent
   */
  private generateCategoryFormGroup( category: any ): FormGroup {
    const newFormGroup: FormGroup = this._fb.group({
      id: +category.id,
      name: category.name,
      selected: false
    });
    return newFormGroup;
  }

  /**
   * @description Set the base category form data for all parent categories. Contains cat label, fields, header's label, class & action, datas in formArray, new formGroup data, actions for special's fields & options for some fields
   * @author Lainez Eddy
   * @private
   * @param {ISupplyCategory} category
   * @memberof ModalInpdatePurchaseOrderElementComponent
   */
  private generateCategoryFormData(category: ISupplyCategory) {
    const data: ISupplyFormData = {
      id: +category.id,
      label: category.name,
      dataAttributs: category.fields,
      headerConfig: {
        // Take only necessary fields, sort them by priority and get the header
        headersName: category.fields
          .filter( field => !field.notDisplay)
          .sort((a, b) => this.fieldsConfig[a.name].priority > this.fieldsConfig[b.name].priority ? 1 : -1)
          .map( field => this.fieldsConfig[field.name].header),
        actionsList: this.tableConfig.headerActionList,
        headerClass: category.fields.some( field => field.name === "reference") ? "table-head-form-supply-list" : "table-head-form",
        rowClass: category.fields.some( field => field.name === "reference") ? "table-row-form-supply-list" : "table-row-form"
      },
      data: new FormArray([]),
      newData: null,
      actionToDo: {
        reference: this.searchReference,
        matterRef: this.checkPlateCategory(+category.id) ? this.searchMatterForPlate : this.searchMatter
      },
      options: {
        reference: [],
        matterRef: []
      }
    };

    if (category.elementGroup && category.elementGroup.elements) {
      data.options.reference = category.elementGroup.elements.map((element, index) => {
        return { value: index, label: element.name };
      });
    }
    if (category.elementGroup && category.elementGroup.availableMatters) {
      data.options.matterRef = category.elementGroup.availableMatters.map( matter => {
        return {
          value: +matter.id,
          label: matter.en1090Name || matter.name
        };
      });
    }
    data.newData = this.createFormControl(data);

    data.headerConfig.headersName.push("actions");
    if (category.parentSupplyCategoryId === null) {
      this.categoriesData.push({
        ...data,
        subCategories: []
      });
    } else {
      const parentFormData: ISupplyFormData = this.categoriesData.find( catData => catData.id === +category.parentSupplyCategoryId);
      if (parentFormData) {
        parentFormData.subCategories.push({
          ...data,
          isDisplay: false
        });
      }
    }
  }

  /**
   * @description Create the form group with all fields linked to the category (with validators) and set the element's datas passes as parameter. If no value, set default value
   * @author Lainez Eddy
   * @private
   * @param {ISupplyFormData} categoryForm
   * @param {(ISupplyListElement | any)} [element={}]
   * @returns
   * @memberof ModalInpdatePurchaseOrderElementComponent
   */
  private createFormControl(categoryForm: ISupplyFormData, element: ISupplyListElement | any = {}) {
    const categoryAttributs: any = categoryForm.dataAttributs;
    const data: FormGroup = this._fb.group({});
    let formControlAttr: FormControl;
    let value: any;
    let specialKey: string[];
    let attrName: string;
    if (element.id) {
      data.addControl("id", new FormControl(element.id));
    }
    categoryAttributs.forEach( attr => {
      value = FIELDS_DEFAULT_VALUE[attr.type] !== undefined ? FIELDS_DEFAULT_VALUE[attr.type] : null;
      specialKey = Object.keys(FIELDS_SPECIAL_KEY).filter( key => FIELDS_SPECIAL_KEY[key].indexOf(attr.name) !== -1);
      if (specialKey.length > 0) {
        attrName = specialKey[0];
        value = this.getSpecialKeyValue(attr.name, element[attr.name], data.get(attrName) === null);
      } else {
        attrName = attr.name;
        value = (element[attr.name] !== undefined) ? element[attr.name] : value;
      }

      if (attr.nullable || attr.name === "elementId" || attr.name === "matterId") {
        formControlAttr = new FormControl(value);
      } else {
        formControlAttr = new FormControl(value, Validators.required);
      }
      // Use to define the option
      if (data.get(attrName) && value) {
        data.get(attrName).patchValue(value);
      }
      data.addControl(attrName, formControlAttr);

      if (this.checkPlateCategory(+categoryForm.id) && !value) {
        this.setDefaultPlateFormControlData(data, attrName, categoryForm);
      }
      // select project
      if (attrName === "project") {
        data.get(attrName).patchValue(this.fieldsConfig.project.options[0].label);
      }
    });
    return data;
  }


  private checkPlateCategory(catId: number) {
    return ElementUnitConfig.categories[ElementUnitCategory.PLATES].includes(catId);
  }

  /**
   * @description Set Default value for new plate data
   * @author Lainez Eddy
   * @private
   * @param {FormGroup} data
   * @param {string} attrName
   * @param {ISupplyFormData} categoryForm
   * @memberof ModalInpdatePurchaseOrderElementComponent
   */
  private setDefaultPlateFormControlData(data: FormGroup, attrName: string, categoryForm: ISupplyFormData) {
    if (attrName === "quantityUnit") {
      data.get(attrName).patchValue(EnumQuantityUnit.TON);
    }
    if (attrName === "matterRef" && categoryForm.options.matterRef[0]) {
      data.get(attrName).patchValue(categoryForm.options.matterRef[0].label);
    }
    if (attrName === "matterId" && categoryForm.options.matterRef[0]) {
      data.get(attrName).patchValue(categoryForm.options.matterRef[0].value);
    }
    if (attrName === "project") {
      data.get(attrName).patchValue(this.fieldsConfig.project.options[0].label);
    }
  }

  /**
   * @description Use to define the default value of option group
   * @author Lainez Eddy
   * @private
   * @param {string} key
   * @param {*} value
   * @param {boolean} [isFirst=false]
   * @returns
   * @memberof ModalInpdatePurchaseOrderElementComponent
   */
  private getSpecialKeyValue(key: string, value: any, isFirst: boolean = false) {
    let newValue;
    switch (key) {
      case "isBlack":
      case "isBlasted":
      case "isPrimaryBlasted":
        newValue = value || isFirst ? this.fieldsConfig[key].options[0].value : null;
        break;
      default:
        newValue = null;
        break;
    }
    return newValue;
  }

  /**
   * @description Set elementId & matterId at null until the user select an element of the suggest
   * @private
   * @memberof ModalInpdatePurchaseOrderElementComponent
   */
  private searchReference = (data: {isSearch: boolean, value: number, label: string, formGroup: FormGroup, matterId: number, addedData: any}) => {
    if (data.isSearch) {
      data.formGroup.get("elementId").patchValue(null);
    } else {
      const reference: string = data.formGroup.get("reference").value;
      if (reference) {
        const category: ISupplyCategory = this._supplyCategories.find( supplyCategory => +supplyCategory.id === data.addedData.index);
        if (category && category.elementGroup && category.elementGroup.elements) {
          const element: IElements = category.elementGroup.elements.find(elem => elem.name === reference);
          data.formGroup.get("elementId").patchValue(element ? +element.id : null);
        }
      }
    }
  }

  /**
   * @description Set matterId at null until the user select an element of the suggest
   * @private
   * @memberof ModalInpdatePurchaseOrderElementComponent
   */
  private searchMatterForPlate = (data: {isSearch: boolean, value: number, label: string, formGroup: FormGroup, addedData: any, options: ISelectOption[]}) => {
    if (data.isSearch) {
      const matching = this.findMatchingMatter(data.formGroup.get("matterRef").value, data.options);
      data.formGroup.get("matterId").patchValue(!!matching ? matching.value : null);
    } else {
      data.formGroup.get("matterId").patchValue(data.value);
    }
  }

  /**
   * @description Search for matching matter from available options
   * @author Quentin Wolfs
   * @private
   * @param {string} search
   * @param {ISelectOption[]} options
   * @returns {ISelectOption}
   * @memberof ModalInpdatePurchaseOrderElementComponent
   */
  private findMatchingMatter(search: string, options: ISelectOption[]): ISelectOption {
    return options ? options.find(option => new RegExp(`^${option.label}.*$`).test(search)) : undefined;
  }

  /**
   * @description Set elementId & matterId at null until the user select an element of the suggest
   * @private
   * @memberof ModalInpdatePurchaseOrderElementComponent
   */
  private searchMatter = (data: {isSearch: boolean, value: number, label: string, formGroup: FormGroup, addedData: any, options: ISelectOption[]}) => {
    if (data.isSearch) {
      const matching = this.findMatchingMatter(data.formGroup.get("matterRef").value, data.options);
      data.formGroup.get("matterId").patchValue(!!matching ? matching.value : null);
    } else {
      const matterRef: string = data.formGroup.get("matterRef").value;
      if (matterRef) {
        data.formGroup.get("matterId").patchValue(data.value);
      }
    }
  }

  /**
   * @description Define header action (click on "isBlack", "isBlasted", "IsPrimaryBlasted") for each category table
   * @author Lainez Eddy
   * @private
   * @memberof ModalInpdatePurchaseOrderElementComponent
   */
  private setHeadersActionsList() {
    this.tableConfig.headerActionList = this.tableConfig.headerActionList.map( list => {
      if (list.id === "isBlack" || list.id === "isBlasted" || list.id === "isPrimaryBlasted") {
        return { ...list, actionToExecute: this.setOptions};
      }
      return list;
    });
  }

  /**
   * @description set option for all element in the category table
   * @private
   * @memberof ModalInpdatePurchaseOrderElementComponent
   */
  private setOptions = (data: ITableFormExecuteAction) => {
    data.tableDatas.controls.forEach( dataControl => {
      dataControl.get("elementOptions").patchValue(data.event.id);
    });
    if (data.newData) {
      data.newData.get("elementOptions").patchValue(data.event.id);
    }
  }

  /**
   * @description Loads all required inital data, such as matters and all supplyCategories (parent and child) with their elements
   * @author Quentin Wolfs
   * @private
   * @memberof ModalInpdatePurchaseOrderElementComponent
   */
  private getInitData(): void {
    const requiredData = [
      this._catalogQueriesSrv.getMatter(false).toPromise(),
      this._categoriesQueriesSrv.getSupplyCategories().toPromise()
    ];
    Promise.all(requiredData).then(result => {
      const matters: IAvailableMatters[] = (<any>result[0].data).availableMatters || [];
      const supplyCategories: ISupplyCategory[] = (<any>result[1].data).supplyCategories || [];
      this.setInitData(matters, supplyCategories);
    }, reject => {
      console.error("ERROR LOADING INIT DATA FOR ADD SUPPLY LIST", { reject });
    });
  }

  /**
   * @description Set field id for specials fields (reference, matterRef, etc...) and generate forms
   * @author Quentin Wolfs
   * @private
   * @param {IAvailableMatters[]} matters
   * @param {ISupplyCategory[]} supplyCategories
   * @memberof ModalInpdatePurchaseOrderElementComponent
   */
  private setInitData(matters: IAvailableMatters[], supplyCategories: ISupplyCategory[]) {
    this._supplyCategories = supplyCategories.map(cat => {
      if (cat.fields.some(field => field.name === "reference")) {
        cat.fields.push({ name: "elementId", type: "Int", notDisplay: true});
        if (cat.parentSupplyCategoryId) {
          this.categoryHasElementGroup.push(+cat.id);
        }
      }
      if (cat.fields.some(field => field.name === "matterRef")) {
        cat.fields.push({ name: "matterId", type: "Int", notDisplay: true});
        // Only until Devis matches Administration matter variety for beams & tubes !
        if (cat.elementGroup && (ElementUnitConfig.categories[ElementUnitCategory.BEAMS].includes(+cat.id) || ElementUnitConfig.categories[ElementUnitCategory.TUBES].includes(+cat.id))) {
          cat.elementGroup.availableMatters = matters;
        }
      }
      return cat;
    });
    this.categoryFormArray = this.generateCategoryFormArray(this._supplyCategories.filter( cat => cat.parentSupplyCategoryId === null));
    this.generateSubCategoryForms();
    this.supplyCategoriesSubscribeChange();
    this.supplySubCategoriesSubscribeChange();
    if ( this._supplyListData) {
      this.loadSupplyListData();
    } else {
      this.tableFormLoaded = true;
    }
  }


  /**
   * @description Listen value Changes on each parent category's checkbox
   * @author Lainez Eddy
   * @private
   * @memberof ModalInpdatePurchaseOrderElementComponent
   */
  private supplyCategoriesSubscribeChange() {
    this.categoryFormArray.controls.forEach( catForm => {
      catForm.valueChanges.subscribe( result => {
        const categoryData: ISupplyFormData = this.categoriesData.find( catData => catData.id === +result.id);
        this.lastUpdatedCategoryId = result.selected ? result.id : null;
        if (result.selected) {
          this.tableFormLoaded = false;
          this.categoriesUnselectedWithElement.delete(categoryData.label);
          this.categoriesUnselectedWithElementAlert.textContent = `${this._textUnselectedWithElement} ${Array.from(this.categoriesUnselectedWithElement).join(", ")}`;
          this.tabsData = this.tabsData.map( tab => {
            return { ...tab, isActive: false };
          });
          this.tabsData.push({ value: result.id, label: result.name, isActive: true} );
          this.tabsData.sort((a, b) => a.value > b.value ? 1 : -1 );
        } else {
          const actualTab: ITabs = {...this.tabsData.find( tab => tab.value === result.id )};
          this.tabsData = this.tabsData.filter( tab => tab.value !== result.id);
          if (actualTab.isActive) {
            this.tableFormLoaded = false;
            this.tabsData = this.tabsData.map( (tab, index) => {
              return { ...tab, isActive: index === 0 };
            });
          }
          if (categoryData.data.value.length > 0 || categoryData.subCategories.some(subCat => subCat.data.value.length > 0)) {
            this.categoriesUnselectedWithElement.add(categoryData.label);
            this.categoriesUnselectedWithElementAlert.textContent = `${this._textUnselectedWithElement} ${Array.from(this.categoriesUnselectedWithElement).join(", ")}`;
          }
        }
        setTimeout(() => {
          this.getSubcategoryIndexToPrint();
        } , 200 );
      });
    });
  }

  /**
   * @description Define which category must to be printed
   * @author Lainez Eddy
   * @private
   * @memberof ModalInpdatePurchaseOrderElementComponent
   */
  private getSubcategoryIndexToPrint() {
    const selectedTab: ITabs = this.tabsData.find(tab => tab.isActive);
    if (selectedTab) {
      this.subCategoryIndexSelected = this.subCategoryFormsArray.findIndex( subCat => subCat.parentId === +selectedTab.value);
      this.categoryFormSelected = this.categoriesData.find( catData => catData.id === +selectedTab.value);
    }
    this.tableFormLoaded = true;
  }

  /**
   * @description Listen value Changes on each children category's checkbox
   * @author Lainez Eddy
   * @private
   * @memberof ModalInpdatePurchaseOrderElementComponent
   */
  private supplySubCategoriesSubscribeChange() {
    this.subCategoryFormsArray.forEach( subCatObject => {
      subCatObject.form.controls.forEach( catForm => {
        catForm.valueChanges.subscribe( result => {
          const selectedCat: ISupplyCategory = this._supplyCategories.find( cat => +cat.id === result.id),
                categoryData: ISupplyFormData = this.categoriesData.find( catData => catData.id === +selectedCat.parentSupplyCategoryId),
                subCategoryData: ISupplyFormData = categoryData.subCategories.find(subCat => subCat.id === result.id);
          subCategoryData.isDisplay = result.selected;
          this.lastUpdatedCategoryId = result.selected ? result.id : null;
          if ( !result.selected && subCategoryData.data.value.length > 0) {
            this.categoriesUnselectedWithElement.add(selectedCat.name);
            this.categoriesUnselectedWithElementAlert.textContent = `${this._textUnselectedWithElement} ${Array.from(this.categoriesUnselectedWithElement).join(", ")}`;
          } else {
            this.categoriesUnselectedWithElement.delete(selectedCat.name);
            this.categoriesUnselectedWithElementAlert.textContent = `${this._textUnselectedWithElement} ${Array.from(this.categoriesUnselectedWithElement).join(", ")}`;
          }
        });
      });
    });
  }

  private loadSupplyListData() {
    this._projectsQueriesSrv.getSupplyList(this._supplyListData.id).subscribe( result => {
      const data: any = result.data;
      if (data && data.supplyList) {
        const {elements} = data.supplyList;
        this.checkAndGenerateData(elements);
      }
      this.tableFormLoaded = true;
      this.lastUpdatedCategoryId = null;
    }, error => {
      console.log("ERROR LOADING SUPPLY LIST DATA", {error});
      this.tableFormLoaded = true;
    });
  }

  /**
   * @description Get element's category and generate formDatas
   * @author Lainez Eddy
   * @private
   * @param {ISupplyListElement[]} elements
   * @memberof ModalInpdatePurchaseOrderElementComponent
   */
  private checkAndGenerateData(elements: ISupplyListElement[]) {
    const categoriesSelected: Set<number> = new Set<number>();
    elements.forEach( element => {
      categoriesSelected.add(+element.supplyCategoryId);
    });
    const categoriesId: number[] = this.checkCategorieAfterImport(categoriesSelected);
    this.generateFormDataElement(elements, categoriesId);
  }

  /**
   * @description Check category's & subcategory's checkbox
   * @author Lainez Eddy
   * @private
   * @param {Set<number>} categoriesId
   * @returns
   * @memberof ModalInpdatePurchaseOrderElementComponent
   */
  private checkCategorieAfterImport(categoriesId: Set<number>) {
    const parentCategoryToCheck: Set<number> = new Set<number>(),
          subCategoryToCheck: Set<number> = new Set<number>();
    const categoriesToSelect: ISupplyCategory[] = this._supplyCategories.filter( suppCat => Array.from(categoriesId).indexOf(+suppCat.id) !== -1);
    categoriesToSelect.forEach( cat => {
      if (cat.parentSupplyCategoryId === null) {
        parentCategoryToCheck.add(+cat.id);
      } else {
        parentCategoryToCheck.add(+cat.parentSupplyCategoryId);
        subCategoryToCheck.add(+cat.id);
      }
    });
    this.categoryFormArray.controls.forEach( catForm => {
      if (Array.from(parentCategoryToCheck).indexOf(catForm.get("id").value) !== -1) {
        if (!catForm.get("selected").value) {
          catForm.get("selected").patchValue(true);
        }
      }
    });
    Array.from(parentCategoryToCheck).forEach( parentCatId => {
      const subCat = this.subCategoryFormsArray.find( subCatObject => subCatObject.parentId === parentCatId );
      if (subCat) {
        subCat.form.controls.forEach( subCatForm => {
          if (Array.from(subCategoryToCheck).indexOf(subCatForm.get("id").value) !== -1) {
            subCategoryToCheck.delete(subCatForm.get("id").value);
            if (!subCatForm.get("selected").value) {
              subCatForm.get("selected").patchValue(true);
            }
          }
        });
      }
    });

    return Array.from(parentCategoryToCheck);
  }

  /**
   * @description Dispatch elements from Tekla file and generate formGroup in each category's formArray (excepted for elements from undefined category)
   * @author Lainez Eddy
   * @private
   * @param {ISupplyListElement[]} elements
   * @param {number[]} selectedCat
   * @memberof ModalInpdatePurchaseOrderElementComponent
   */
  private generateFormDataElement(elements: ISupplyListElement[], selectedCat: number[]) {
    const undefinedElementList: Set<string> = new Set<string>();
    const neededFormArray: ISupplyFormData[] = this.categoriesData.filter( catForms => selectedCat.indexOf(catForms.id) !== -1);
    const dataToSet: {category: any, subCategory: any} = {
      category: {},
      subCategory: {}
    };
    elements.forEach(element => {
      if (element.supplyCategoryId !== null) {
        if (neededFormArray.some( form => form.id === +element.supplyCategoryId )) {
          if (!dataToSet.category[element.supplyCategoryId]) {
            dataToSet.category[element.supplyCategoryId] = [];
          }
          dataToSet.category[element.supplyCategoryId].push(element);
        } else {
          if (!dataToSet.subCategory[element.supplyCategoryId]) {
            dataToSet.subCategory[element.supplyCategoryId] = [];
          }
          dataToSet.subCategory[element.supplyCategoryId].push(element);
        }
      } else {
        // Stock undefined Elements to get them when we need to save the supply list
        const elem: any = {...element};
        elem.supplyCategoryId = null;
        delete elem.supplyListId;
        delete elem.__typename;
        this.undefinedElements.push(elem);
        undefinedElementList.add(elem.reference);
      }
    });
    Object.keys(dataToSet.category).forEach( categoryKey => {
      const categoryForm: ISupplyFormData = this.categoriesData.find( catForm => catForm.id === +categoryKey);
      this.generateFormDataByAttributs(categoryForm, dataToSet.category[categoryKey]);
    });

    Object.keys(dataToSet.subCategory).forEach( categoryKey => {
      const category: ISupplyCategory = this._supplyCategories.find( cat => +cat.id === +categoryKey ),
            categoryForm: ISupplyFormData = this.categoriesData.find( catForm => catForm.id === +category.parentSupplyCategoryId),
            subCategoryForm: ISupplyFormData = categoryForm.subCategories.find( subCatForm => subCatForm.id === +categoryKey);
      this.generateFormDataByAttributs(subCategoryForm, dataToSet.subCategory[categoryKey]);
    });

    if (undefinedElementList.size > 0) {
      this.undefinedElementAlert.textContent = `${this._textUndefined[0]} ${this._textUndefined[1]} ${Array.from(undefinedElementList).join(", ")}. ${this._textUndefined[2]}`;
    }
  }

  /**
   * @description Set saved datas in formGroup
   * @author Lainez Eddy
   * @private
   * @param {ISupplyFormData} categoryForm
   * @param {ISupplyListElement[]} elements
   * @memberof ModalInpdatePurchaseOrderElementComponent
   */
  private generateFormDataByAttributs(categoryForm: ISupplyFormData, elements: ISupplyListElement[]) {
    elements.forEach( element => {
      categoryForm.data.push(this.createFormControl(categoryForm, element));
    });
  }

  public changeStatus() {
    this.showContent = !this.showContent;
  }

  /**
   * @description Push the "newData" FormGroup in the data FormArray of the category and create a new FormGroup for the "newData" attribute
   * @author Lainez Eddy
   * @param {{ data: FormGroup, categoryId: number}} eventData
   * @memberof ModalInpdatePurchaseOrderElementComponent
   */
  public addCategoryData(eventData: { data: FormGroup, categoryId: number}) {
    this.lastUpdatedCategoryId = +eventData.categoryId;
    const category: ISupplyCategory = this._supplyCategories.find( cat => +cat.id === +eventData.categoryId );
    let parentCategoryForm: ISupplyFormData,
        categoryForm: ISupplyFormData;
    if (category.parentSupplyCategoryId) {
      parentCategoryForm = this.categoriesData.find( catForm => catForm.id === +category.parentSupplyCategoryId);
      categoryForm = parentCategoryForm.subCategories.find( subCatForm => subCatForm.id === +eventData.categoryId);
    } else {
      categoryForm = this.categoriesData.find( catForm => catForm.id === +category.id);
    }
    const newElementValue = this.setTrueElementData({...eventData.data.value});
    const oldElementOptions: any = newElementValue.isBlack === undefined ? {} : {
      isBlack: newElementValue.isBlack,
      isBlasted: newElementValue.isBlasted,
      isPrimaryBlasted: newElementValue.isPrimaryBlasted,
    };
    categoryForm.data.push(this.createFormControl(categoryForm, newElementValue));
    const elementUnitCategory: any = Object.keys(ElementUnitConfig.categories).find(elementCat => ElementUnitConfig.categories[elementCat].includes(+eventData.categoryId));
    const unitSuffix = !!elementUnitCategory ? ElementUnitConfig.units[elementUnitCategory] : ElementUnitConfig.units.default;
    this._formGroup.patchValue({ unit: EnumUnitValue[unitSuffix] });
    // push categoryForm value in saveForm to create purchaseOrderElement
    if(this._saveForm.length !== 0){
      let detect = 0;
      for (let index = 0; index < this._saveForm.length; index++) {
        const element = this._saveForm[index];
        if(this._saveForm[index].id === categoryForm.id){
          detect += 1;
          this._saveForm[index] = categoryForm;
        }
      }
      if(detect == 0){
        this._saveForm.push(categoryForm);
      }
    }
    else{
      this._saveForm.push(categoryForm);
    }


    categoryForm.newData = null;
    setTimeout(() => {
      categoryForm.newData = this.createFormControl(categoryForm, oldElementOptions);
    }, 200);
    this._snackBar.open("Ajout d'un élémént", "L'élément a bien été ajouté", "success", 3000);
  }

  /**
   * @description Use to define the option selected
   * @author Lainez Eddy
   * @private
   * @param {ISupplyListElement} element
   * @returns
   * @memberof ModalInpdatePurchaseOrderElementComponent
   */
  private setTrueElementData(element: ISupplyListElement) {
    if (element.elementOptions) {
      element.isBlack = element.elementOptions === "isBlack";
      element.isBlasted = element.elementOptions === "isBlasted";
      element.isPrimaryBlasted = element.elementOptions === "isPrimaryBlasted";
      delete element.elementOptions;
    }
    return element;
  }

  /**
   * @description Return an array of element to save
   * @author Lainez Eddy
   * @private
   * @returns {ISupplyListElement[]}
   * @memberof ModalAddSupplyListComponent
   */
  private formatDataToSave(): ISupplyListElement[] {
    const allCategoryDatas: ISupplyFormData[] = this.getAllCategoriesDataSelected();
    const elements: ISupplyListElement[][] = [];
    let elementData: ISupplyListElement;
    allCategoryDatas.forEach( cat => {
      elements.push(cat.data.value.map( element => {
        elementData = this.setTrueElementData({...element});
        elementData.supplyCategoryId = cat.id;
        return elementData;
      }));
    });
    elements.push(this.undefinedElements);
    return elements.flat();
  }

  /**
   * @description Get all category & subCategory selected AND Return an array of ISupplyFormData (category with datas)
   * @author Lainez Eddy
   * @private
   * @returns {ISupplyFormData[]}
   * @memberof ModalAddSupplyListComponent
   */
  private getAllCategoriesDataSelected(): ISupplyFormData[] {
    const categoriesId: number[] = this.categoryFormArray.value.filter( cat => cat.selected).map(cat => cat.id),
          subCategoriesId: number[] = this.subCategoryFormsArray
            .filter( subCat => categoriesId.indexOf(subCat.parentId) !== -1).map( subCat => subCat.form )
            .map( subCatFormArray => subCatFormArray.value.filter( value => value.selected).map(cat => cat.id)).flat();
    const categoriesData: ISupplyFormData[] = this.categoriesData.filter( catData => categoriesId.indexOf(+catData.id) !== -1),
          subCategoriesData: ISupplyFormData[] = categoriesData.map( catData => catData.subCategories
            .filter( subCatData => subCategoriesId.indexOf(subCatData.id) !== -1)).flat();
    return categoriesData.concat(subCategoriesData);
  }

  /**
   * @description Change selected tab
   * @author Lainez Eddy
   * @param {ITabs[]} value
   * @memberof ModalAddSupplyListComponent
   */
  public changeTab(value: ITabs[]) {
    this.tableFormLoaded = false;
    this.tabsData = value;
    this.filterTable = "";
    this.lastUpdatedCategoryId = null;
    setTimeout(() => {
      this.getSubcategoryIndexToPrint();
    } , 200 );
  }

  /**
   * @description Change selected tab
   * @author Marie Claudia
   * @param {reference} string
   * @memberof ModalInpdatePurchaseOrderElementComponent
   */
  public getProject(reference: string) {
    this._projectSrv.projectByreference(reference).subscribe(result => {
      const resultData: any = result.data;
      if (resultData && resultData.projectsByReference) {
      return resultData.projectsByReference.id;
      }
    }, error => {
    console.log(error);
    ;
    });
  }
}
