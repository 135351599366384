import { Component, OnInit, Inject, OnDestroy } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { StepFormService, CONTAINER_DATA } from "app/presentationnal/organisms/wizard-modal/service/step-form.service";
import { TransportProperty, Transport } from "app/facade/quote-element-definition/options/transport";
import { OptionService } from "app/facade/quote-element-definition/options/options.service";
import { ActualData } from "app/presentationnal/organisms/wizard-modal/service/step-form.service";
import { TransportFormulaDisplayer } from "app/facade/quote-element-definition/formula.display";
import { Subscription } from "rxjs";

@Component({
  selector: "app-transport-step-one",
  templateUrl: "./transport-step-one.component.html",
  styleUrls: ["./transport-step-one.component.css"]
})
export class TransportStepOneComponent implements OnInit, OnDestroy {
  private transportStepOneForm: FormGroup;
  private _actualData: ActualData;
  private _optionData: Transport;
  public newTransport: Transport;
  private _formulaDisplayer: TransportFormulaDisplayer;
  private _formulaSub: Subscription;
  public calculs: string[];

  constructor(@Inject(CONTAINER_DATA) data: any,
    private _fb: FormBuilder,
    private _stepFormSrv: StepFormService,
    private _optionSrv: OptionService) {
    this._actualData = this._stepFormSrv.get();
    this._optionData = this._actualData.element;
  }

  ngOnInit() {
    this.initform();
    this.calculs = [];
    this._formulaDisplayer = new TransportFormulaDisplayer();
    this._formulaSub = this._formulaDisplayer.$optionPrice.subscribe(calculs => this.calculs = calculs);
    this.showCalcul();
  }

  public ngOnDestroy() {
    this._formulaSub.unsubscribe();
  }

  public initform() {
    this.transportStepOneForm = this._fb.group({
      transportPrice: [this._optionData ? this._optionData.TransportPrice : null, Validators.compose([Validators.required, Validators.min(0)])],
    });
  }


  public save() {
    if (this.transportStepOneForm && this.transportStepOneForm.valid) {
      if (this._actualData.isEdited) {
        this.newTransport = this._optionData;
        this.newTransport.TransportPrice = this.transportStepOneForm.get("transportPrice").value;

        this._stepFormSrv.updateElement(this.newTransport, null, this._actualData.index);
      } else {
        const transData: TransportProperty = {
          transportPrice: this.transportStepOneForm.get("transportPrice").value,
        };
        this.newTransport = this._optionSrv.create("Transport", transData);
        this.newTransport.CustomName = "Transport";
        this._stepFormSrv.addElement(this.newTransport);
      }
    }
    this._stepFormSrv.closeModal$.next(true);
  }

  public cancel() {
    this._stepFormSrv.closeModal$.next(true);
  }

  public showCalcul() {
    this.transportStepOneForm.valueChanges.subscribe(values => {
      const base = {
        price: values.transportPrice || null
      };

      if (Object.keys(base).every(k => base[k])) {
        this._formulaDisplayer.base = base;
      }
    });

    const init = {
      price: this.transportStepOneForm.value.transportPrice || null
    };

    if (Object.keys(init).every(k => init[k] !== undefined && init[k] !== null)) {
      this._formulaDisplayer.base = init;
    }
  }
}
