import { Injectable } from "@angular/core";
import { Apollo } from "apollo-angular";
import { GET_PRICE_REQUESTS, GET_PRICE_REQUEST_REFERENCE, CREATE_PRICE_REQUEST, FREE_PRICE_REQUEST_REFERENCE, DELETE_PRICE_REQUEST, GET_PRICE_REQUEST, UPDATE_PRICE_REQUEST, ASSIGN_SUPPLY_LISTS_TO_PRICE_REQUEST, FREE_SUPPLY_LIST_FROM_PRICE_REQUEST, GET_PRICE_REQUEST_ELEMENTS, GET_PRICE_REQUEST_ELEMENTS_AND_SUPPLIERS, UPDATE_PRICE_REQUEST_ELEMENT, UPDATE_PRICE_REQUEST_ELEMENT_OPTION, DELETE_PRICE_REQUEST_ELEMENT_OPTION, CREATE_PRICE_REQUEST_ELEMENT_OPTIONS, UPDATE_PRICE_REQUEST_ADDITIONNAL_COST, DELETE_PRICE_REQUEST_ADDITIONNAL_COST, GET_NEW_PRICE_REQUESTS } from "./price-requests.queries";
import { IPriceRequestFilter, IPriceRequestInput, IPriceRequestUpdate, IAmalgamFilter, IAmalgamInput, IAmalgamParam, IPriceRequestElementFilter, IPriceRequestElementUpdate, IPriceRequestElementOptionInput, IPriceRequestAdditionnalCostUpdate } from "app/facade/interfaces/price-request.interface";
import { GET_SUPPLY_LISTS_IN_PRICE_REQUEST } from "../project/projects-supply-lists.queries";
import { ISupplyListFilter, ISupplyListSort } from "app/facade/interfaces/project";
import { GET_AMALGAMS, SAVE_AMALGAMS, GENERATE_AMALGAMS } from "./amalgams.queries";
import { GET_SUPPLIER_OFFERS, ASSOCIATE_SUPPLIERS_TO_PRICE_REQUEST, GET_SUPPLIER_OFFER_RESPONSE, UPDATE_SUPPLIER_OFFER, UPDATE_SUPPLIER_OFFER_ELEMENT, DELETE_SUPPLIER_OFFER, GET_SUPPLIER_OFFERS_WITH_CATEGORIES, ASSOCIATE_SUPPLIER_OFFER_ELEMENTS, SEND_PRICE_REQUEST_MAILS, GET_VARIANT_WEIGHT, DELETE_SUPPLIER_OFFERS } from "./supplier-offers.queries";
import { ISupplierOfferFilter, ISupplierOfferUpdate, ISupplierOfferElementUpdate, ISupplierOfferElementAssociation, IWeightData } from "app/facade/interfaces/supplier-offer";
import { SupplyListSortBy } from "app/facade/enums/supply-list-sort.enum";
import { OrderByDirection } from "app/facade/enums/order-by-direction.enum";
import { IBarsetGenerationUpdate } from "app/facade/interfaces/barset.interface";
import { IPagination } from "app/facade/interfaces/crud.interface";

@Injectable({
  providedIn: "root"
})
export class PriceRequestsQueriesService {
  private _sortSupplyList: ISupplyListSort = {
    sortBy: SupplyListSortBy.CREATED_AT,
    sortDirection: OrderByDirection.DESC
  };

  constructor(private _apollo: Apollo) { }

  public watchPriceRequest(filter?: IPriceRequestFilter) {
    return this._apollo.watchQuery({
      query: GET_PRICE_REQUESTS,
      variables: {filter},
      fetchPolicy: "network-only"
    });
  }

  public getPriceRequestListing(filter?: IPriceRequestFilter, pagination?: IPagination) {
    return this._apollo.query({
      query: GET_NEW_PRICE_REQUESTS,
      variables: { filter, pagination },
      fetchPolicy: "no-cache"
    });
  }

  public getPriceRequest(id: number) {
    return this._apollo.query({
      query: GET_PRICE_REQUEST,
      variables: {id},
      fetchPolicy: "network-only"
    });
  }

  public getPriceRequestReference() {
    return this._apollo.query({
      query: GET_PRICE_REQUEST_REFERENCE,
      fetchPolicy: "network-only"
    });
  }

  public freePriceRequestReference() {
    return this._apollo.mutate({
      mutation: FREE_PRICE_REQUEST_REFERENCE
    });
  }

  public createPriceRequest(data: IPriceRequestInput) {
    return this._apollo.mutate({
      mutation: CREATE_PRICE_REQUEST,
      variables: {data}
    });
  }

  public updatePriceRequest(id: number, data: IPriceRequestUpdate) {
    return this._apollo.mutate({
      mutation: UPDATE_PRICE_REQUEST,
      variables: {id, data}
    });
  }

  public deletePriceRequest(id: number) {
    return this._apollo.mutate({
      mutation: DELETE_PRICE_REQUEST,
      variables: {id}
    });
  }

  public getSupplyListsInPriceRequest(filter: ISupplyListFilter, sort: ISupplyListSort = this._sortSupplyList) {
    return this._apollo.query({
      query: GET_SUPPLY_LISTS_IN_PRICE_REQUEST,
      variables: { filter, sort },
      fetchPolicy: "network-only"
    });
  }

  public watchSupplyListsInPriceRequest(filter: ISupplyListFilter, sort: ISupplyListSort = this._sortSupplyList) {
    return this._apollo.watchQuery({
      query: GET_SUPPLY_LISTS_IN_PRICE_REQUEST,
      variables: { filter, sort },
      fetchPolicy: "network-only"
    });
  }

  public assignSupplyLists(priceRequestId: number, supplyListIds: number[], filter: ISupplyListFilter) {
    return this._apollo.mutate({
      mutation: ASSIGN_SUPPLY_LISTS_TO_PRICE_REQUEST,
      variables: { priceRequestId, supplyListIds },
      fetchPolicy: "no-cache",
      refetchQueries: this.getRefetchQueries(filter, priceRequestId)
    });
  }

  public freeSupplyLists(supplyListId: number, filter: ISupplyListFilter, priceRequestId: number) {
    return this._apollo.mutate({
      mutation: FREE_SUPPLY_LIST_FROM_PRICE_REQUEST,
      variables: { supplyListId },
      fetchPolicy: "no-cache",
      refetchQueries: this.getRefetchQueries(filter, priceRequestId)
    });
  }

  private getRefetchQueries( filter: ISupplyListFilter, priceRequestId: number) {
    const queries: any[] = [{
      query: GET_SUPPLY_LISTS_IN_PRICE_REQUEST,
      variables: { filter, sort: this._sortSupplyList }
    }, {
      query: GET_AMALGAMS,
      variables: { filter: {priceRequestId} }
    }, {
      query: GET_SUPPLIER_OFFERS,
      variables: { filter: {priceRequestId} }
    }, {
      query: GET_PRICE_REQUEST_ELEMENTS_AND_SUPPLIERS,
      variables: { id: priceRequestId }
    }];

    return queries;
  }

  /* AMALGAMS */
  public watchAmalgams( filter: IAmalgamFilter ) {
    return this._apollo.watchQuery({
      query: GET_AMALGAMS,
      variables: {filter},
      fetchPolicy: "network-only"
    });
  }

  public saveAmalgams(priceRequestId: number, data: IAmalgamInput[], generation: IBarsetGenerationUpdate) {
    return this._apollo.mutate({
      mutation: SAVE_AMALGAMS,
      variables: { priceRequestId, data, generation },
      fetchPolicy: "no-cache",
      refetchQueries: [{
        query: GET_PRICE_REQUEST_ELEMENTS_AND_SUPPLIERS,
        variables: { id: priceRequestId }
      }]
    });
  }

  public generateNewAmalgams( priceRequestId: number, locked: IAmalgamInput[], params?: IAmalgamParam ) {
    const variables: any =  params ? {priceRequestId, locked, params} : {priceRequestId, locked};
    return this._apollo.query({
      query: GENERATE_AMALGAMS,
      variables,
      fetchPolicy: "network-only"
    });
  }

  /* SUPPLIER */
  public watchSupplierOffers( filter: ISupplierOfferFilter) {
    return this._apollo.watchQuery({
      query: GET_SUPPLIER_OFFERS,
      variables: {filter},
      fetchPolicy: "network-only"
    });
  }

  public getSupplierOffers( filter: ISupplierOfferFilter) {
    return this._apollo.query({
      query: GET_SUPPLIER_OFFERS_WITH_CATEGORIES,
      variables: {filter},
      fetchPolicy: "network-only"
    });
  }

  public getSupplierOfferResponse( id: number ) {
    return this._apollo.query({
      query: GET_SUPPLIER_OFFER_RESPONSE,
      variables: {id},
      fetchPolicy: "network-only"
    });
  }

  public associateSupplierToPriceRequest( priceRequestId: number, supplierIds: number[]) {
    return this._apollo.mutate({
      mutation: ASSOCIATE_SUPPLIERS_TO_PRICE_REQUEST,
      variables: {priceRequestId, supplierIds},
      refetchQueries: [
        {
          query: GET_SUPPLIER_OFFERS,
          variables: { filter: {priceRequestId} }
        },
        {
          query: GET_PRICE_REQUEST_ELEMENTS_AND_SUPPLIERS,
          variables: {id: priceRequestId}
        }
      ]
    });
  }

  public deleteSupplierOffer(id: number, priceRequestId: number) {
    return this._apollo.mutate({
      mutation: DELETE_SUPPLIER_OFFER,
      variables: {id},
      refetchQueries: [
        {
          query: GET_SUPPLIER_OFFERS,
          variables: { filter: {priceRequestId} }
        },
        {
          query: GET_PRICE_REQUEST_ELEMENTS_AND_SUPPLIERS,
          variables: {id: priceRequestId}
        }
      ]
    });
  }
  public deleteSupplierOffers(ids: number[], priceRequestId: number) {
    return this._apollo.mutate({
      mutation: DELETE_SUPPLIER_OFFERS,
      variables: {ids},
      refetchQueries: [
        {
          query: GET_SUPPLIER_OFFERS,
          variables: { filter: {priceRequestId} }
        },
        {
          query: GET_PRICE_REQUEST_ELEMENTS_AND_SUPPLIERS,
          variables: {id: priceRequestId}
        }
      ]
    });
  }
  public updateSupplierOffer( id: number, data: ISupplierOfferUpdate, priceRequestId: number) {
    return this._apollo.mutate({
      mutation: UPDATE_SUPPLIER_OFFER,
      variables: {id, data},
      fetchPolicy: "no-cache",
      refetchQueries: [{
        query: GET_PRICE_REQUEST_ELEMENTS_AND_SUPPLIERS,
        variables: {id: priceRequestId}
      }]
    });
  }

  public updateSupplierOfferContact( id: number, data: ISupplierOfferUpdate, priceRequestId: number) {
    return this._apollo.mutate({
      mutation: UPDATE_SUPPLIER_OFFER,
      variables: {id, data},
      fetchPolicy: "no-cache",
      refetchQueries: [{
        query: GET_SUPPLIER_OFFERS,
        variables: { filter: {priceRequestId} }
      }]
    });
  }

  public updateSupplierOfferElement(id: number, data: ISupplierOfferElementUpdate, priceRequestId: number) {
    return this._apollo.mutate({
      mutation: UPDATE_SUPPLIER_OFFER_ELEMENT,
      variables: { id, data },
      fetchPolicy: "no-cache",
      refetchQueries: [{
        query: GET_PRICE_REQUEST_ELEMENTS_AND_SUPPLIERS,
        variables: {id: priceRequestId}
      }]
    });
  }

  public getPriceRequestElements(filter: IPriceRequestElementFilter) {
    return this._apollo.query({
      query: GET_PRICE_REQUEST_ELEMENTS,
      variables: { filter },
      fetchPolicy: "network-only"
    });
  }

  public getPriceRequestElementsAndSupplier(id: number) {
    return this._apollo.watchQuery({
      query: GET_PRICE_REQUEST_ELEMENTS_AND_SUPPLIERS,
      variables: { id },
      fetchPolicy: "no-cache"
    });
  }

  public updatePriceRequestElement(id: number, data: IPriceRequestElementUpdate, priceRequestId: number) {
    return this._apollo.mutate({
      mutation: UPDATE_PRICE_REQUEST_ELEMENT,
      variables: { id, data },
      fetchPolicy: "no-cache",
      refetchQueries: [{
        query: GET_PRICE_REQUEST_ELEMENTS_AND_SUPPLIERS,
        variables: {id: priceRequestId}
      }]
    });
  }

  public createPriceRequestElementOptions(data: IPriceRequestElementOptionInput, priceRequestId: number) {
    return this._apollo.mutate({
      mutation: CREATE_PRICE_REQUEST_ELEMENT_OPTIONS,
      variables: { data },
      fetchPolicy: "no-cache",
      refetchQueries: [{
        query: GET_PRICE_REQUEST_ELEMENTS_AND_SUPPLIERS,
        variables: {id: priceRequestId}
      }]
    });
  }

  public updatePriceRequestElementOption(id: number, data: IPriceRequestElementOptionInput, priceRequestId: number) {
    return this._apollo.mutate({
      mutation: UPDATE_PRICE_REQUEST_ELEMENT_OPTION,
      variables: { id, data },
      fetchPolicy: "no-cache",
      refetchQueries: [{
        query: GET_PRICE_REQUEST_ELEMENTS_AND_SUPPLIERS,
        variables: {id: priceRequestId}
      }]
    });
  }

  public deletePriceRequestElementOption(id: number, priceRequestId: number) {
    return this._apollo.mutate({
      mutation: DELETE_PRICE_REQUEST_ELEMENT_OPTION,
      variables: { id },
      fetchPolicy: "no-cache",
      refetchQueries: [{
        query: GET_PRICE_REQUEST_ELEMENTS_AND_SUPPLIERS,
        variables: {id: priceRequestId}
      }]
    });
  }

  // Additionnal Cost Method
  public updatePriceRequestAdditionnalCost(id: number, data: IPriceRequestAdditionnalCostUpdate, priceRequestId: number) {
    return this._apollo.mutate({
      mutation: UPDATE_PRICE_REQUEST_ADDITIONNAL_COST,
      variables: {id, data},
      fetchPolicy: "no-cache",
      refetchQueries: [{
        query: GET_PRICE_REQUEST_ELEMENTS_AND_SUPPLIERS,
        variables: {id: priceRequestId}
      }]
    });
  }

  public deletePriceRequestAdditionnalCost(id: number, priceRequestId: number) {
    return this._apollo.mutate({
      mutation: DELETE_PRICE_REQUEST_ADDITIONNAL_COST,
      variables: {id},
      fetchPolicy: "no-cache",
      refetchQueries: [{
        query: GET_PRICE_REQUEST_ELEMENTS_AND_SUPPLIERS,
        variables: {id: priceRequestId}
      }]
    });
  }

  // ASSOCIATE ELEMENT
  public associateSupplierOfferElements(data: ISupplierOfferElementAssociation) {
    return this._apollo.mutate({
      mutation: ASSOCIATE_SUPPLIER_OFFER_ELEMENTS,
      variables: {data},
      fetchPolicy: "no-cache",
    });
  }

  public sendPriceRequestMails(ids: number[]) {
    return this._apollo.mutate({
      mutation: SEND_PRICE_REQUEST_MAILS,
      variables: {ids},
      fetchPolicy: "no-cache"
    });
  }

  // Variant Data
  public getVariantWeight(data: IWeightData) {
    return this._apollo.query({
      query: GET_VARIANT_WEIGHT,
      variables: {data},
      fetchPolicy: "no-cache"
    });
  }
}
