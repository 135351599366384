import { Operation, OperationCreate } from "./operation.base";
import { IAction } from "../base";
import { Element } from "../elements/element.base";

export interface FoldingDefinedProperty {
    foldingPrice: number;
}

export interface FoldingProperty extends FoldingDefinedProperty {
    angle: number;
}

export interface IFoldable extends Element {
    thickness: number;
}

export interface FoldingBuild extends IAction<IFoldable, FoldingProperty> {
}

export class Folding extends Operation<IFoldable, FoldingProperty, FoldingDefinedProperty> {

    public constructor(
        element: IFoldable,
        properties: FoldingProperty,
        operationCreate: OperationCreate<IFoldable, FoldingDefinedProperty>) {

        super(element, properties, operationCreate);
    }

    public get CalculatedPrice(): number {
        return 0;
    }

    protected updateAfterElementChange(): void {
        // delete this.properties.foldingPrice;
    }
}