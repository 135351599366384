import { Component, OnInit, Inject, OnDestroy } from "@angular/core";
import { ModalContentForm } from "../../model/modal-content-form";
import { ModalService, DATA } from "../../services/modal.service";
import { SnackService } from "../../../snack-bar/services/snack.service";
import { FormBuilder } from "@angular/forms";
import { IModalData } from "../../interfaces/modal-data.interface";
import { ScanPdf } from "../../../../../facade/interfaces/scan-pdf.interface";
import { ScanPdfQueriesService } from "../../../../../facade/queries/scan-pdf/scan-pdf.queries.service";
import { ApplicationConfig } from "app/app.config";
import { TABLE_ITEM_ACTION_TYPE } from "app/presentationnal/organisms/table/table-item/enum/table-item-action-type.enum";
import { TableDatasDefinition } from "app/facade/interfaces/table-datas-definition";
import { Subscription } from "apollo-client/util/Observable";

interface IShowScanPdfModalData extends IModalData {
  data: {
    purchaseOrderId: number;
  };
}

@Component({
  selector: 'app-modal-show-scan-pdf',
  templateUrl: './modal-show-scan-pdf.component.html',
  styleUrls: ['./modal-show-scan-pdf.component.css']
})
export class ModalShowScanPdfComponent extends ModalContentForm implements OnInit, OnDestroy {
  private _purchaseOrderId: number;
  private _scanPdfSub : Subscription;
  private _actionsType = TABLE_ITEM_ACTION_TYPE;
  public tableHeaders: string[] = [
    "Nom",
    "Date",
    "Commentaire",
    "Actions",
  ];
  public dataDefinition: TableDatasDefinition[] = [
    { key: "name", isTitle: true},
    { key: "createdAt", isTitle: false},
    { key: "comment", isTitle: false},
  ];
  public dataTable: ScanPdf[] = [];

  constructor(
    protected _modalService: ModalService,
    protected _snackBar: SnackService,
    protected _fb: FormBuilder,
    @Inject(DATA) private _data: IShowScanPdfModalData,
    private _scanPdfQueriesSrv: ScanPdfQueriesService,
  ) {
    super(_modalService, _fb, _snackBar);
    this._purchaseOrderId = this._data && this._data.data && this._data.data.purchaseOrderId ? this._data.data.purchaseOrderId : null;
  }

  ngOnInit() {
    this.getScanPdf()
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  //get scan pdf information
  public getScanPdf(){
    // this.dataTable = this.mapScanPdf(this._purchaseOrderData.scanPdfs);
    if (this._scanPdfSub) { this._scanPdfSub.unsubscribe(); }
    this._scanPdfSub = this._scanPdfQueriesSrv.getScanPdf(+this._purchaseOrderId).subscribe(result => {
      const resultData = <any>result.data;
      if(resultData && resultData.getScanPdf){
        this.dataTable = resultData.getScanPdf;
        this.dataTable = this.mapScanPdf(resultData.getScanPdf);
      }
    }, error => {
      console.log("ERROR LOADING LISTING SCANPDF", {error});
    });
  }

  //Map data for listing ScanPdf display
  private mapScanPdf(data: any[]): ScanPdf[] {
    return data.map( scan => {
      const createdAt= new Date(scan.createdAt*1000);
      let createdAtStr:string = (createdAt.getDate() < 10 ? '0' : '') + createdAt.getDate();
      createdAtStr += '/' + (createdAt.getMonth()+1 < 10 ? '0' : '') + (createdAt.getMonth()+1);
      createdAtStr += '/' + createdAt.getFullYear();

      return {
        id: scan.id,
        name: scan.name,
        purchaseOrderId: this._purchaseOrderId,
        url:scan.url,
        createdAt:createdAtStr,
        comment:scan.comment,
        actionsList: this.setScanPdfActionList()
      };
    });
  }

  //ADD Actions in ScanPdfList
  private setScanPdfActionList(): any[] {
    const actionList: any[] = [];
      actionList.push(
        { label: "Lire", type: TABLE_ITEM_ACTION_TYPE.EDIT}
      );
    return actionList;
  }

  public tableButtonClicked($event: { actionType: number, itemId: number}) {
    switch (this._actionsType[$event.actionType]) {
      case "EDIT":
       this.printScanPdf($event.itemId);
        break;
      default:
        break;
    }
  }

  //open pdf file into the new window
  public printScanPdf(itemId: number) {
    this._scanPdfQueriesSrv.getOneScanPdf(Number(itemId)).subscribe(result => {
      const resultData = <any>result.data;
      if (resultData && resultData.getOneScanPdf) {
        const url = resultData.getOneScanPdf.url;
        window.open(`${ApplicationConfig.Url}/api/scan/${url}`);
      } else {

      }
    }, error => {
      console.log(error);
    });
  }
 
  protected save(data?: any): void {
    if (this._formGroup.valid) {
      const objData = {
        confirm: true,
        data
      };
      this.afterClosed.next(objData);
    }
  }

  public confirmModal(): void {
    this._modalService.closeModal();
  }

}



