import { Component, OnInit, Inject, OnDestroy } from "@angular/core";
import { ModalContentForm } from "../../model/modal-content-form";
import { ModalService, DATA } from "../../services/modal.service";
import { SnackService } from "app/presentationnal/organisms/snack-bar/services/snack.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ISelectOption } from "app/presentationnal/atoms/inputs/select-input/selectOptions";
import { IPurchaseOrder, IPurchaseOrderFromProjectInput, IPurchaseOrderUpdate } from "app/facade/interfaces/purchase-order.interface";
import { PurchaseOrderQueriesService } from "app/facade/queries/purchase-order/purchase-order-queries.service";
import { SuppliersQueriesService } from "app/facade/queries/supplier/suppliers-queries.service";
import { ProjectsQueriesService } from "app/facade/queries/project/projects-queries.service";
import { ISupplier } from "app/facade/interfaces/supplier";
import { IProject, ISupplyList } from "app/facade/interfaces/project";
import { IModalData } from "../../interfaces/modal-data.interface";
import { MODAL_CLOSE_ACTION } from "../../enums/modal-close-action.enum";
import { Subscription } from "rxjs";
import { UploadFileService } from "@lib/upload-file/upload-file.service";
import { UPLOAD_PDF, UPLOAD_URLS } from "@lib/upload-file/uploadUrls";
import { PurchaseOrderSource } from "app/facade/enums/purchaseOrder.enum";
import { AddScanPdfInput, ScanPdfInput } from "app/facade/interfaces/scan-pdf.interface";

interface IPurchaseOrderModalData extends IModalData {
  data: {
    purchaseOrder: IPurchaseOrder;
    isCreated: boolean;
    onlyUpdateRemark: boolean;
  };
}

@Component({
  selector: "app-modal-add-purchase-order",
  templateUrl: "./modal-add-purchase-order.component.html",
  styleUrls: ["./modal-add-purchase-order.component.css"]
})
export class ModalAddPurchaseOrderComponent extends ModalContentForm implements OnInit, OnDestroy {

  private _purchaseOrderData: IPurchaseOrder;
  private _purchaseOrderNewReference: string;
  private _searchSupplierSub: Subscription;
  private _searchProjectSub: Subscription;
  private _scanPdf : any;
  private _listScanPdf: ScanPdfInput[] = [];
  public requiredField: string[] = [];
  public supplierOptions: ISelectOption[] = [];
  public projectOptions: ISelectOption[] = [];
  public isCreated: boolean = true;
  public onlyUpdateRemark: boolean = false;
  public tableFormLoaded: boolean = false;
  public formInputFileGroup: FormGroup;

  constructor(
    protected _modalService: ModalService,
    protected _snackBar: SnackService,
    protected _fb: FormBuilder,
    @Inject(DATA) private _data: IPurchaseOrderModalData,
    private _purchaseOrderQueriesSrv: PurchaseOrderQueriesService,
    private _supplierQueriesSrv: SuppliersQueriesService,
    private _projectQueriesSrv: ProjectsQueriesService,
    private _uploadFileSrv: UploadFileService) {
      super(_modalService, _fb, _snackBar);
      this._purchaseOrderData = this._data && this._data.data && this._data.data.purchaseOrder ? this._data.data.purchaseOrder : null;
      this.isCreated = this._data && this._data.data ? this._data.data.isCreated : true;
      this.onlyUpdateRemark = this._data && this._data.data ? this._data.data.onlyUpdateRemark : false;
     }

  ngOnInit() {
    this.loadOptionsAndReference();
  }

  ngOnDestroy() {
    if (this._searchSupplierSub) { this._searchSupplierSub.unsubscribe(); }
    if (this._searchProjectSub) { this._searchProjectSub.unsubscribe(); }
    super.ngOnDestroy();
  }

  private async loadOptionsAndReference() {
    if (!this._purchaseOrderData) {
      const promiseReference = await this._purchaseOrderQueriesSrv.getPurchaseOrderReference().toPromise();
      this._purchaseOrderNewReference = (promiseReference.data && (<any>promiseReference.data).getPurchaseOrderReference) ? (<any>promiseReference.data).getPurchaseOrderReference : null;
    }
    this.initForm();
  }

  private initForm() {
    this.requiredField = ["reference"];
    if (!!this._purchaseOrderData) {
      this.supplierOptions = [{ label : `${this._purchaseOrderData.supplier.code} - ${this._purchaseOrderData.supplier.name}`, value : this._purchaseOrderData.supplier.id, }];
      if (!!this._purchaseOrderData.project) {
        this.projectOptions = [{ value: this._purchaseOrderData.projectId, label: this._purchaseOrderData.project.reference }];
      }
    }
    this._formGroup = this._fb.group({
      reference: [this._purchaseOrderData ? this._purchaseOrderData.reference : this._purchaseOrderNewReference, Validators.required],
      supplierId: [
        this._purchaseOrderData ? this._purchaseOrderData.supplierId : null,
        (this.onlyUpdateRemark ? null : Validators.required)
      ],
      projectId: [
        this._purchaseOrderData ? this._purchaseOrderData.projectId : null,
        (this.onlyUpdateRemark ? null : Validators.required)
      ],
      price: [null],
      remark: [this._purchaseOrderData ? this._purchaseOrderData.remark : null],
      internalRemark: [this._purchaseOrderData ? this._purchaseOrderData.internalRemark : null],
    });

    this.formInputFileGroup = this._fb.group({
      fileValue: [(this. _purchaseOrderData && this. _purchaseOrderData.source === PurchaseOrderSource.PDF) ?
        (this._purchaseOrderData.model) ? this._purchaseOrderData.model : "Pas de nom de model définit"  : null],
    });

    this.formInputFileGroup = this._fb.group({
      fileValue: [(this. _purchaseOrderData && this. _purchaseOrderData.source === PurchaseOrderSource.PDF) ?
        (this._purchaseOrderData.model) ? this._purchaseOrderData.model : "Pas de nom de model définit"  : null],
    });
  }

  public cancelModal(): void {
    this._purchaseOrderQueriesSrv.freePurchaseOrderReference().subscribe( result => {
      this._modalService.closeModal();
    });
  }

  public formatPurchaseOrderDatas(): IPurchaseOrderFromProjectInput | IPurchaseOrderUpdate {
    let data: IPurchaseOrderFromProjectInput | IPurchaseOrderUpdate;
    if (!this._purchaseOrderData) {
      data = this._formGroup.getRawValue();
      let newkey = "scanPdfs";
      let newVal = this._listScanPdf;

      data[newkey] = newVal;
    } else {
      if (this.onlyUpdateRemark || !this.isCreated) {
        data = {
          remark: this._formGroup.get("remark").value,
          internalRemark: this._formGroup.get("internalRemark").value
        };
      } else {
        data = this._formGroup.value;
      }
    }
    return data;
  }

  protected save(data: IPurchaseOrder): void {
    if (this._formGroup.valid) {
      const objData = {
        confirm: true,
        data
      };
      this.afterClosed.next(objData);
    }
  }

  public confirmModal(): void {
    if (this._formGroup.valid) {
      switch (this._data.closeAction) {
        case MODAL_CLOSE_ACTION.SAVE:
          this.saveNewPurchaseOrder();
          break;
        case MODAL_CLOSE_ACTION.UPDATE:
          this.updatePurchaseOrder(this._purchaseOrderData.id);
          break;
      }
    } else {
        this.markFormGroupTouched(this._formGroup);
        this.displayErrorMessage();
    }
  }

  /**
   * @description Send request to save new Purchase Order. Closes the modal if successfull, displays error if not
   * @author Quentin Wolfs
   * @private
   * @memberof ModalAddPurchaseOrderComponent
   */
  private saveNewPurchaseOrder(): void {
    this._purchaseOrderQueriesSrv.createPurchaseOrderFromProject(<IPurchaseOrderFromProjectInput>this.formatPurchaseOrderDatas(),this._scanPdf).subscribe(result => {
      const resultData: any = result.data;
      
      if (resultData && resultData.createPurchaseOrderFromProject) {
        this._snackBar.open(this._data.title, "Le bon de commande a été créé", "success", 5000);
        this.save(resultData.createPurchaseOrderFromProject);
        this._modalService.closeModal();
      } else {
        this._snackBar.openSnackBarError(this._data.title, "Le bon de commande n'a pas été créé");
      }
    } , error => {
      this._snackBar.openSnackBarError(this._data.title, "Le bon de commande n'a pas été créé", error);
    });
  }

  /**
   * @description Send request to update existing Purchase Order. Closes the modal if successfull, displays error if not
   * @author Quentin Wolfs
   * @private
   * @param {number} id
   * @memberof ModalAddPurchaseOrderComponent
   */
  private updatePurchaseOrder(id: number): void {
    this._purchaseOrderQueriesSrv.updatePurchaseOrder(id, this.formatPurchaseOrderDatas()).subscribe(result => {
      const resultData: any = result.data;
      if (resultData && resultData.updatePurchaseOrder) {
        this._snackBar.open(this._data.title, "Le bon de commande a été mis à jour", "success", 5000);

        // Update was successfull, closing the modal with updated data
        this.save(resultData.updatePurchaseOrder);
        this._modalService.closeModal();
      } else {
        this._snackBar.openSnackBarError(this._data.title, "Le bon de commande n'a pas été mis à jour");
      }
    } , error => {
      this._snackBar.openSnackBarError(this._data.title, "Le bon de commande n'a pas été mis à jour", error);
    });
  }

  /**
   * @description Search for all suppliers matching the search string
   * @author Quentin Wolfs
   * @param {string} search
   * @memberof ModalAddPurchaseOrderComponent
   */
  public searchSuppliers(search: string) {
    this._searchSupplierSub = this._supplierQueriesSrv.searchSupplier(search).subscribe(result => {
      const resultData: any = result.data;
      this.supplierOptions = resultData.suppliers.map((supplier: ISupplier) => {
        return {
          label : `${supplier.code} - ${supplier.name}`,
          value : supplier.id,
        };
      });
    });
  }

  /**
   * @description Search for all projects matching the search string
   * @author Quentin Wolfs
   * @param {string} search
   * @memberof ModalAddPurchaseOrderComponent
   */
  public searchProjects(search: string) {
    this._searchProjectSub = this._projectQueriesSrv.getProjectList(search).subscribe(result => {
      const resultData: any = result.data;
      this.projectOptions = resultData.projects.map((project: IProject) => {
        return {
          value: project.id,
          label: project.reference
        };
      });
    });
  }
}


