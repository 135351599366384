/*
 * @Author: Kevin Palade
 * @Date: 2018-09-08 14:46:34
 * @Last Modified by: Kevin Palade
 * @Last Modified time: 2018-09-11 15:24:39
 */
import { Component, HostBinding, AfterContentChecked } from "@angular/core";

import { ModalService } from "./services/modal.service";
import { MODAL_ACTION_TYPE } from "./enums/modal-action-type.enum";
import { MODAL_TYPE } from "./enums/modal-type.enum";
import { IModalStyle } from "./interfaces/modal-data.interface";


@Component({
  selector: "app-modal",
  templateUrl: "./modal.component.html",
  styleUrls: ["./modal.component.css"]
})
export class ModalComponent implements AfterContentChecked {
  @HostBinding("style.display") get getDisplay(): string { return "none" ; }

  private _MODAL_TYPE = MODAL_TYPE;
  private _MODAL_ACTION_TYPE = MODAL_ACTION_TYPE;

  public modalStyle: string = "";
  public cancelButtonName: string = "Annuler";
  public confirmButtonName: string = "Sauvegarder";
  public deleteButtonName: string = "Supprimer";
  public cancelButtonColor: string = "";
  public confirmButtonColor: string = "";
  public deleteButtonColor: string = "red";
  public cancelButtonIsLink: boolean = true;
  public displayCancelButton: boolean = true;

  constructor(protected _modalService: ModalService) {}

  ngAfterContentChecked(): void {
    if (this._modalService && this._modalService.modalData) {
      const customData: any = this._modalService.modalData.customData;
      this.cancelButtonName = customData && customData.cancelButtonName ? customData.cancelButtonName : "Annuler";
      this.confirmButtonName = customData && customData.confirmButtonName ? customData.confirmButtonName : "Sauvegarder";
      this.deleteButtonName = customData && customData.deleteButtonName ? customData.deleteButtonName : "Supprimer";
      this.cancelButtonColor = customData && customData.cancelButtonColor ? customData.cancelButtonColor : "";
      this.confirmButtonColor = customData && customData.confirmButtonColor ? customData.confirmButtonColor : "";
      this.deleteButtonColor = customData && customData.deleteButtonColor ? customData.deleteButtonColor : "red";
      this.cancelButtonIsLink = customData && customData.cancelButtonIsLink !== undefined ? customData.cancelButtonIsLink : true;
      this.displayCancelButton = customData && customData.displayCancelButton !== undefined ? customData.displayCancelButton : true;

      const modalStyle: IModalStyle = this._modalService.modalData.modalStyle;
      this.modalStyle = modalStyle && modalStyle.modalWidth ? `modal_width_${modalStyle.modalWidth}` : "";
    }
  }

  /**
   * @description close the modal with action type [MODAL_ACTION_TYPE]
   * @author Kevin Palade
   * @private
   * @param {number} action_type
   * @memberof ModalComponent
   */
  private buttonClick(action_type: number, deleteCancelEvent: boolean = false): void {
    this._modalService.buttonClick(action_type, deleteCancelEvent);
  }
}
