export enum PriceRequestElementOptionType {
  PROCESSING = "Traitement",
  CUT = "Coupe",
  OTHER = "Autre",
}

export enum PriceRequestElementOptionUnit {
  EURO_BY_TON = "€ / T",
  EURO = "€",
  EURO_BY_UNIT = "€ / Qté",
  EURO_BY_METER = "€ / M",
  EURO_BY_SQUARE_METER = "€ / M²",
}