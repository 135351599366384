import { Component, OnInit, OnDestroy } from "@angular/core";
import { ModalService } from "app/presentationnal/organisms/modal/services/modal.service";
import { SnackService } from "app/presentationnal/organisms/snack-bar/services/snack.service";
import { Router } from "@angular/router";
import { TableDatasDefinition } from "app/facade/interfaces/table-datas-definition";
import { ModalConfirmComponent, ModalAddPurchaseOrderComponent, ModalShowScanPdfComponent } from "app/presentationnal/organisms/modal";
import { MODAL_TYPE } from "app/presentationnal/organisms/modal/enums/modal-type.enum";
import { TABLE_ITEM_ACTION_TYPE } from "app/presentationnal/organisms/table/table-item/enum/table-item-action-type.enum";
import { Subscription } from "apollo-client/util/Observable";
import { PurchaseOrderQueriesService } from "app/facade/queries/purchase-order/purchase-order-queries.service";
import { IPurchaseOrder, ICustomPurchaseOrder, ICustomPurchaseOrderSortBy, EnumPurchaseOrderSortBy, IPurchaseOrderSort, EnumPurchaseOrderStatus } from "app/facade/interfaces/purchase-order.interface";
import * as moment from "moment";
import { purchaseOrderStatusConfig } from "app/facade/configs/status.config";
import { OrderByDirection } from "app/facade/enums/order-by-direction.enum";
import { MODAL_CLOSE_ACTION } from "app/presentationnal/organisms/modal/enums/modal-close-action.enum";
import { IPagination } from "app/facade/interfaces/crud.interface";
import { ListTableService } from "app/facade/services/list-table.service";
import { AuthService } from "@lib/auth/auth.service";
import { PermissionService } from "app/facade/services/permission.service";
import { PriceRequestsQueriesService } from "app/facade/queries/price-request/price-requests-queries.service";
import { take } from "rxjs/operators";
import { ActivatedRoute } from '@angular/router';

const DEFAULT_LIMIT: number = 25;

@Component({
    selector: "app-listing-purchase-order",
    templateUrl: "./listing-purchase-order.component.html",
    styleUrls: ["./listing-purchase-order.component.css"]
})
export class ListingPurchaseOrderComponent implements OnInit, OnDestroy {
    private _actionsType = TABLE_ITEM_ACTION_TYPE;
    public purchase_orders: string = "purchase-orders";
    private _listPurchaseOrderSub: Subscription;
    private _singlePurchaseOrderSub: Subscription;
    private _addPurchaseOrderSub: Subscription;
    private _deletePurchaseOrderSub: Subscription;
    private _filterPurchaseOrder: string = "";
    private _purchaseOrderSort: IPurchaseOrderSort = {
        sortBy: EnumPurchaseOrderSortBy.ID,
        sortDirection: OrderByDirection.DESC
    };
    public isScan: boolean;
    public tableHeaders: string[] = [
        "Référence",
        "Fournisseur",
        "Date de création",
        "Créé par",
        "Projet",
        "Demande de prix",
        "Remarque générale",
        "Remarque interne",
        "Status",
        "actions"];
    public pdfList: String[] = ["purcahseOrder.pdf"];

    /**
    * @description Get Price request and Open it in a new tab
    * @author Hassan
    * @private
    * @memberof ListingPurchaseOrderComponent
    */
    private openPriceRequestEdit = (el: any) => {
        if (el.priceRequest) {
            this._priceRequestsQueriesSrv.getPriceRequestListing({ search: el.priceRequest }).pipe(take(1)).subscribe(result => {
                const data: any = result.data;
                if (data && data.priceRequests) {
                    const url = `/price-request/edit/${data.priceRequests[0].id}`;
                    window.open(url, '_blank');
                }
            }, error => {
                console.log("LOADING PRICE REQUEST ERROR", { error });
            });
        }
    }

    public dataDefinition: TableDatasDefinition[] = [
        { key: "reference", isTitle: true },
        { key: "supplier", isTitle: false },
        { key: "createdAt", isTitle: false },
        { key: "createdBy", isTitle: false },
        { key: "project", isTitle: false },
        { key: "priceRequest", isTitle: false, buttonAction: this.openPriceRequestEdit },
        { key: "remark", isTitle: false },
        { key: "internalRemark", isTitle: false },
        { key: "status", isTitle: false, isStatus: true, statusConfig: purchaseOrderStatusConfig },
    ];
    public dataTable: ICustomPurchaseOrder[] = [];
    public noSortHeaders: string[] = [
        "Projet"
    ];
    private _pagination: IPagination = { page: 1, limit: DEFAULT_LIMIT };
    public userGroup: any;
    public countPg = 1;
    public isEdit = false;
    public isDelete = false;

    constructor(
        private _modalService: ModalService,
        private _purchaseOrderQueriesSrv: PurchaseOrderQueriesService,
        private _priceRequestsQueriesSrv: PriceRequestsQueriesService,
        private _snackBar: SnackService,
        private _listTableSrv: ListTableService,
        private router: Router,
        private _authSrv: AuthService,
        private _permissionService: PermissionService,
        private route: ActivatedRoute) { }

    ngOnInit() {
        const reference = this.route.snapshot.queryParamMap.get('reference');
      if (reference) {
          localStorage.setItem('dataPurchaseRecherche', JSON.stringify({ "recherche": reference }));
        }
        this.userGroup = this._authSrv.isUserGroup();
        //get and update Permission
        this._permissionService.getAndUpdatePermission(this.userGroup);
        this._pagination = { page: 1, limit: DEFAULT_LIMIT };
        this.loadPurchaseOrders();

        let savedSearch = JSON.parse(localStorage.getItem('dataPurchaseRecherche'));
        savedSearch = savedSearch && savedSearch.recherche && savedSearch.recherche.length > 0 ? savedSearch.recherche : '';
        if (savedSearch.length > 0) {
          this._snackBar.open("Liste filtrée", "Cette liste est filtrée suivant votre recherche", "info", 5000);
          this._filterPurchaseOrder = savedSearch;
        }
        let savedPagination = JSON.parse(localStorage.getItem('dataPurchasePagination'));
        savedPagination = savedPagination && savedPagination.pagination > 1 ? savedPagination.pagination : 1;
        if (savedPagination > 1) {
            this._pagination.limit = DEFAULT_LIMIT * savedPagination;
            this.loadPurchaseOrders(true);
            this._pagination = { page: savedPagination, limit: DEFAULT_LIMIT };
        } else {
            this.loadPurchaseOrders(true);
        }

        this.isEdit = this._permissionService.hasPermission(this.userGroup, "purchase-orders", "write");

        this.isDelete = this._permissionService.hasPermission(this.userGroup, "purchase-orders", "delete");
    }

    ngOnDestroy(): void {
        if (this._listPurchaseOrderSub) { this._listPurchaseOrderSub.unsubscribe(); }
        if (this._singlePurchaseOrderSub) { this._singlePurchaseOrderSub.unsubscribe(); }
        if (this._addPurchaseOrderSub) { this._addPurchaseOrderSub.unsubscribe(); }
        if (this._deletePurchaseOrderSub) { this._deletePurchaseOrderSub.unsubscribe(); }
    }

    private loadPurchaseOrders(forceFirstPage: boolean = false) {
        if (this._listPurchaseOrderSub) { this._listPurchaseOrderSub.unsubscribe(); }
        this._listPurchaseOrderSub = this._purchaseOrderQueriesSrv.getPurchaseOrders(this._filterPurchaseOrder, this._purchaseOrderSort, this._pagination).subscribe(result => {
            const resultData: { purchaseOrders: IPurchaseOrder[], pagination?: any } = <any>result.data;

            if (resultData && resultData.purchaseOrders) {
                this.dataTable = !forceFirstPage && this._pagination.page !== 1 ?
                    [...this.dataTable, ...this.mapPurchaseOrders(resultData.purchaseOrders)] :
                    this.mapPurchaseOrders(resultData.purchaseOrders);
            }
            if (resultData && resultData.pagination) {
                this._listTableSrv.setLoadMoreStatus(resultData.pagination.hasNext);
            }
        }, error => {
            console.log("ERROR LOADING PURCHASE ORDERS", { error });
        });
    }

    /**
     * @description Map data for listing display
     * @author Quentin Wolfs
     * @private
     * @param {any[]} data
     * @returns {ICustomPurchaseOrder[]}
     * @memberof ListingPurchaseOrderComponent
     */
    private mapPurchaseOrders(data: any[]): ICustomPurchaseOrder[] {

        return data.map(purchaseOrder => {
            return {
                id: purchaseOrder.id,
                reference: purchaseOrder.reference,
                supplier: purchaseOrder.supplier ? purchaseOrder.supplier.name : null,
                createdAt: moment.unix(+purchaseOrder.createdAt).format("DD/MM/YYYY HH:mm"),
                createdBy: `${purchaseOrder.user.lastname} ${purchaseOrder.user.firstname}`,
                project: purchaseOrder.project ?
                    purchaseOrder.project.reference :
                    purchaseOrder.linkedProjects.map(project => project.reference).join(", "),
                priceRequest: purchaseOrder.priceRequest ? purchaseOrder.priceRequest.reference : null,
                status: purchaseOrder.status,
                remark: purchaseOrder.remark,
                internalRemark: purchaseOrder.internalRemark,
                actionsList: purchaseOrder.scanPdfs ? this.setPurchaseOrderActionList(purchaseOrder.status, true, purchaseOrder.scanPdfs.length) : this.setPurchaseOrderActionList(purchaseOrder.status, false),
                likedProject: purchaseOrder.linkedProjects
            };
        });
    }

    private setPurchaseOrderActionList(status: EnumPurchaseOrderStatus, scan: boolean, length?: number): any[] {
        const actionList: any[] = [];
        if (status === EnumPurchaseOrderStatus.CREATED) {
            actionList.push(
                { label: "Annuler", type: TABLE_ITEM_ACTION_TYPE.DELETE, additionnalClass: "table-row__action_delete", style: "display:flex;" }
            );
        }

        actionList.push(
            { label: "Editer", type: TABLE_ITEM_ACTION_TYPE.EDIT, additionnalClass: "table-row__action_edit" }
        );

        if (scan == true) {
            actionList.push(
                { label: "Scan " + length, type: TABLE_ITEM_ACTION_TYPE.SHOW, style: "display:flex;" }
            );
        }

        return actionList;
    }

    public sortTable(key: string) {
        const trueKey: EnumPurchaseOrderSortBy = (<EnumPurchaseOrderSortBy>Object.keys(ICustomPurchaseOrderSortBy).find(sortKey => ICustomPurchaseOrderSortBy[sortKey] === key));
        if (trueKey) {
            if (this._purchaseOrderSort.sortBy !== trueKey) {
                this._purchaseOrderSort = {
                    sortBy: trueKey,
                    sortDirection: OrderByDirection.DESC
                };
            } else {
                this._purchaseOrderSort.sortDirection = this._purchaseOrderSort.sortDirection === OrderByDirection.ASC ? OrderByDirection.DESC : OrderByDirection.ASC;
            }
            this._pagination.page = 1;
            this.loadPurchaseOrders();
        } else {
            console.error("NO DEFINED KEY");
        }
    }

    public filterPurchaseOrder(filter: any) {
        localStorage.setItem('dataPurchaseRecherche', JSON.stringify({ "recherche": filter }))
        localStorage.setItem('dataPurchasePagination', JSON.stringify({ "pagination": 1 }));
        this._filterPurchaseOrder = filter;
        this._pagination.page = 1;
        this.loadPurchaseOrders();
    }

    public addPurchaseOrder() {
        const modalTitle: string = "Création d'un bon de commande";
        const modalRef = this._modalService.openModal(ModalAddPurchaseOrderComponent,
            {
                title: modalTitle,
                type: MODAL_TYPE.NORMAL,
                data: null,
                closeAction: MODAL_CLOSE_ACTION.SAVE
            }
        );
        if (modalRef) {
            modalRef.afterClosed.subscribe(res => {
                if (res.confirm && res.data) {
                    this.router.navigate([`${this.router.url}/edit/${res.data.id}`]);
                }
            });
        }
    }

    public listingScanPdf(purchaseOrderId: number) {
        const modalTitle: string = "Liste des scans PDF";
        const modalRef = this._modalService.openModal(ModalShowScanPdfComponent,
            {
                title: modalTitle,
                type: MODAL_TYPE.NORMAL,
                data: {
                    purchaseOrderId: purchaseOrderId,
                },
                closeAction: MODAL_CLOSE_ACTION.NONE
            }
        );
    }

    public tableButtonClicked($event: { actionType: number, itemId: number }) {
        switch (this._actionsType[$event.actionType]) {
            case "DELETE":
                this.beforeDeletePurchaseOrder($event.itemId);
                break;
            case "EDIT":
                this.router.navigate([`${this.router.url}/edit/${$event.itemId}`]);
                break;
            case "SHOW":
                this.listingScanPdf($event.itemId)
                break;
            default:
                break;
        }
    }

    //
    // CALL MODAL FOR DELETE PURCHASE
    //
    private beforeDeletePurchaseOrder(purchaseOrderId: number) {
        const purchaseOrder: ICustomPurchaseOrder = this.dataTable.find(data => data.id === purchaseOrderId);
        if (purchaseOrder) {
            this._modalService.openModal(ModalConfirmComponent,
                {
                    title: "Êtes-vous sûr?",
                    type: MODAL_TYPE.CONFIRM,
                    data: {
                        message: `Voulez-vous annuler le bon de commande <b>${purchaseOrder.reference}</b>?`,
                        actions: [
                            "Annuler le bon de commande de manière définitive",
                        ],
                    },
                    params: { purchaseOrderId },
                    confirmCallback: this.deletePurchaseOrder,
                    customData: {
                        deleteButtonName: "Valider",
                    }
                }
            );
        } else {
            console.error("CANNOT FIND PURCHASE ORDER ID ", purchaseOrderId);
        }
    }

    public deletePurchaseOrder = (params: { purchaseOrderId: number }) => {
        const snackBarTitle: string = "Annuler un bon de commande";
        if (this._deletePurchaseOrderSub) { this._deletePurchaseOrderSub.unsubscribe(); }

        this._deletePurchaseOrderSub = this._purchaseOrderQueriesSrv.deletePurchaseOrder(params.purchaseOrderId).subscribe((result: any) => {
            const resultData: { deletePurchaseOrder: boolean } = result.data;
            if (resultData && resultData.deletePurchaseOrder) {
                this._snackBar.open(snackBarTitle, "Le bon de commande a été annulé", "success", 5000);
            } else {
                this._snackBar.openSnackBarError(snackBarTitle, "Le bon de commande n'a pas été annulé");
            }
            this.loadSinglePurchaseOrder(params.purchaseOrderId);
        }, error => {
            this._snackBar.openSnackBarError(snackBarTitle, "Le bon de commande n'a pas été annulé", error);
        });
    }

    public loadSinglePurchaseOrder(id: number) {
        if (this._singlePurchaseOrderSub) { this._singlePurchaseOrderSub.unsubscribe(); }
        this._singlePurchaseOrderSub = this._purchaseOrderQueriesSrv.getSinglePurchaseOrder(id).subscribe(result => {
            const resultData: { purchaseOrder: IPurchaseOrder } = <any>result.data;
            if (resultData && resultData.purchaseOrder) {
                const elementIndex = this.dataTable.findIndex(purchaseOrder => purchaseOrder.id == resultData.purchaseOrder.id);
                if (elementIndex > -1) {
                    this.dataTable = [
                        ...this.dataTable.slice(0, elementIndex),
                        ...this.mapPurchaseOrders([resultData.purchaseOrder]),
                        ...this.dataTable.slice(elementIndex + 1)
                    ];
                }
            }
        }, error => {
            console.log("ERROR LOADING SINGLE PURCHASE ORDER", { error });
        });
    }

    public loadMore(event: boolean) {
        this._pagination.page++;
        localStorage.setItem('dataPurchasePagination', JSON.stringify({ "pagination": this._pagination.page }));
        this.loadPurchaseOrders();
    }
}
