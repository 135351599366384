import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from "@angular/core";
import { FormGroup, FormBuilder, FormArray } from "@angular/forms";
import { StepFormService } from "../wizard-modal/service/step-form.service";
import { QuoteElementBase, quoteCertificates } from "app/facade/quote-element-definition/base";
import { Subscription } from "rxjs";
import { ApplicationConfig } from "app/app.config";
import { PermissionService } from "app/facade/services/permission.service";
import { AuthService } from "@lib/auth/auth.service";

@Component({
  selector: "app-ticket-summary",
  templateUrl: "./ticket-summary.component.html",
  styleUrls: ["./ticket-summary.component.css"]
})
export class TicketSummaryComponent implements OnInit, OnChanges {
  @Input("quote") public quote;
  @Output() public openRemarks = new EventEmitter<boolean>();
  public url = ApplicationConfig.Url;
  public remarksGroup: FormGroup;
  public dataTicket: {element: QuoteElementBase, children: QuoteElementBase[]}[];
  public ticketSub: Subscription;
  public chargesList: any[] = [];
  public certificatePrice: number;
  public quoteCharges: number;
  public hasPermission: any;
  public userGroup: any;

  constructor(private _fb: FormBuilder,
              private _stepFormSrv: StepFormService,
              private _permissionService: PermissionService,
              private _authSrv: AuthService) {
   }

  ngOnInit() {

    // get this.userGroup
    this.userGroup =  this._authSrv.isUserGroup();
    //get and update Permission
    this._permissionService.getAndUpdatePermission(this.userGroup);
    // check if hasPermissionn to see prices
    this.hasPermission = this._permissionService.hasPermission(this.userGroup,"quotations","seePrices");
    
    this.certificatePrice = this.quote && this.quote.isEn1090 ? 15 : 0;
    this.quoteCharges = this.quote && this.quote.isEn1090 ? 750 : 0;
    this.ticketSub = this._stepFormSrv.getUpdateTicket().subscribe(result => {
      this.dataTicket = result;
      if (this.quote && this.quote.isEn1090) {
        const certificates: Set<string> = quoteCertificates(this.dataTicket),
              certificatesTotalPrice: number = certificates.size * this.certificatePrice;
        this.chargesList.push({
          quantity: certificates.size,
          description: "Certificats",
          price: certificatesTotalPrice
        });
        this.chargesList.push({
          quantity: 1,
          description: "Frais de devis",
          price: this.quoteCharges
        });
      }
    });
    this.remarkForm();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["quote"] && this.remarksGroup) {
      if (changes["quote"].currentValue != null) {
        this.remarksGroup.get("remarks").patchValue(changes["quote"].currentValue.remarks);
      }
    }
  }

  public remarkForm() {
    this.remarksGroup = this._fb.group({
      remarks : this.quote ? this.quote.remarks : null ,
    });
  }

  /**
   * editRemarks
   */
  public editRemarks() {
    this.openRemarks.emit(true);
  }

  public exportPdf(lang: string) {
    window.open(this.url + "/api/quotes/" + this._stepFormSrv.getQuoteId() + ".pdf?lang=" + lang);
  }

}
