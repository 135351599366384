/*
 * @Author: Kevin Palade
 * @Date: 2018-09-08 16:37:37
 * @Last Modified by: Kevin Palade
 * @Last Modified time: 2018-09-10 17:14:32
 */
export enum TABLE_ITEM_ACTION_TYPE {
    EDIT,
    SAVE,
    DELETE,
    CANCEL,
    SHOW
}
