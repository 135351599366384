import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { HomepageComponent } from "./homepage/homepage.component";
import { OrganismsModule } from "app/presentationnal/organisms/organisms.module";
import { WizardModalComponent } from "app/presentationnal/organisms/wizard-modal/wizard-modal.component";
import { PortalModule } from "@angular/cdk/portal";
import { StepFormService } from "app/presentationnal/organisms/wizard-modal/service/step-form.service";
import { CustomElementService } from "app/facade/quote-element-definition/elements/customs/custom.service";

@NgModule({
  imports: [
    CommonModule,
    OrganismsModule,
    PortalModule
  ],
  exports: [
  ],
  entryComponents: [
    WizardModalComponent
  ],
  declarations: [
    HomepageComponent],
  providers: [
    StepFormService,
    CustomElementService
  ]
})
export class WizardsModule { }
