import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { OuterSubscriber } from "rxjs/internal/OuterSubscriber";

@Component({
  selector: "app-nav-step",
  templateUrl: "./nav-step.component.html",
  styleUrls: ["./nav-step.component.css"]
})
export class NavStepComponent implements OnInit {

  @Input() navSteps;
  @Input() currentStep;
  @Output() indexStep = new EventEmitter ();
  constructor() { }

  ngOnInit() {}

  /**
   * getIndexStep
   */
  public getIndexStep(indexStep) {
    if (this.currentStep > indexStep) {
      this.indexStep.emit(indexStep);
    }
  }

}
