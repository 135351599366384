/*
 * @Author: Kevin Palade
 * @Date: 2018-09-08 16:54:22
 * @Last Modified by: Kevin Palade
 * @Last Modified time: 2018-09-11 15:30:19
 */
import { OnDestroy } from "@angular/core";
import { Subscription } from "rxjs/internal/Subscription";
import { ModalService } from "../services/modal.service";
import { MODAL_ACTION_TYPE } from "../enums/modal-action-type.enum";
import { Subject } from "rxjs";

export abstract class ModalContent implements OnDestroy {

    public afterClosed: Subject<any>;

    private _buttonClickedSub: Subscription;
    constructor(protected _modalService: ModalService) {
        this.afterClosed = new Subject<any>();
        this._buttonClickedSub = this._modalService.buttonClicked.subscribe(res => {
            if ( res == MODAL_ACTION_TYPE.CONFIRM ) {
                this.confirmModal();
            } else {
                this.cancelModal();
            }
        });
    }

    public abstract confirmModal(): void;
    public abstract cancelModal(): void;

    ngOnDestroy() {
        if ( this._buttonClickedSub ) {
            this.afterClosed.complete();
            this._buttonClickedSub.unsubscribe();
        }
    }
}