import { Component, Renderer2, Input } from "@angular/core";
import { AInputs } from "../inputs";

@Component({
  selector: "app-number-input",
  templateUrl: "./number-input.component.html",
  styleUrls: ["./number-input.component.css"]
})
export class NumberInputComponent extends AInputs {
  @Input() suffix: string;
  constructor(public renderer: Renderer2) {
    super(renderer);
  }

}
