import { Component, OnInit, Inject, OnDestroy } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { StepFormService, CONTAINER_DATA, ActualData } from "app/presentationnal/organisms/wizard-modal/service/step-form.service";
import { OptionService } from "app/facade/quote-element-definition/options/options.service";
import { CraneProperty, Crane } from "app/facade/quote-element-definition/options/crane";
import { Subscription } from "rxjs";
import { OptionFormulaDisplayer } from "app/facade/quote-element-definition/formula.display";

@Component({
  selector: "app-hoist-step-one",
  templateUrl: "./hoist-step-one.component.html",
  styleUrls: ["./hoist-step-one.component.css"]
})
export class HoistStepOneComponent implements OnInit, OnDestroy {
  private hoistStepOneForm: FormGroup;
  private _actualData: ActualData;
  private _optionData: Crane;
  public newCrane: Crane;
  private _formulaDisplayer: OptionFormulaDisplayer;
  private _formulaSub: Subscription;
  public calculs: string[];

  constructor(@Inject(CONTAINER_DATA) data: any,
    private _fb: FormBuilder,
    private _stepFormSrv: StepFormService,
    private _optionSrv: OptionService) {
    this._actualData = this._stepFormSrv.get();
    this._optionData = this._actualData.element;
  }

  ngOnInit() {
    this.initform();
    this.calculs = [];
    this._formulaDisplayer = new OptionFormulaDisplayer();
    this._formulaSub = this._formulaDisplayer.$optionPrice.subscribe(calculs => this.calculs = calculs);
    this.showCalcul();
  }

  public ngOnDestroy() {
    this._formulaSub.unsubscribe();
  }

  public initform() {
    this.hoistStepOneForm = this._fb.group({
      personQuantity: [this._optionData ? this._optionData.PersonQuantity : 1, Validators.compose([Validators.required, Validators.min(1)])],
      dayQuantity: [this._optionData ? this._optionData.DayQuantity : 1, Validators.compose([Validators.required, Validators.min(0)])],
      hourlyRate: [this._optionData ? this._optionData.HourlyRate : 1, Validators.compose([Validators.required, Validators.min(0)])],
    });
  }

  public save() {
    if (this.hoistStepOneForm && this.hoistStepOneForm.valid) {
      if (this._actualData.isEdited) {
        this.newCrane = this._optionData;
        this.newCrane.DayQuantity = this.hoistStepOneForm.get("dayQuantity").value;
        this.newCrane.PersonQuantity = this.hoistStepOneForm.get("personQuantity").value;
        this.newCrane.HourlyRate = this.hoistStepOneForm.get("hourlyRate").value;
        this._stepFormSrv.updateElement(this.newCrane, null, this._actualData.index);
      } else {
        const craneData: CraneProperty = {
          personQuantity: this.hoistStepOneForm.get("personQuantity").value,
          dayQuantity: this.hoistStepOneForm.get("dayQuantity").value,
          hourlyRate: this.hoistStepOneForm.get("hourlyRate").value,
        };
        this.newCrane = this._optionSrv.create("Crane", craneData);
        this.newCrane.CustomName = "Grue";
        this._stepFormSrv.addElement(this.newCrane);
      }
      this._stepFormSrv.closeModal$.next(true);
    }
  }

  public cancel() {
    this._stepFormSrv.closeModal$.next(true);
  }

  public showCalcul() {
    this.hoistStepOneForm.valueChanges.subscribe(values => {
      const base = {
        personQuantity: values.personQuantity || null,
        dayQuantity: values.dayQuantity || null,
        hourlyRate: values.hourlyRate || null
      };

      if (Object.keys(base).every(k => base[k])) {
        this._formulaDisplayer.base = base;
      }
    });

    const init = {
      personQuantity: this.hoistStepOneForm.value.personQuantity,
      dayQuantity: this.hoistStepOneForm.value.dayQuantity,
      hourlyRate: this.hoistStepOneForm.value.hourlyRate
    };

    if (Object.keys(init).every(k => init[k] !== undefined && init[k] !== null)) {
      this._formulaDisplayer.base = init;
    }
  }
}
