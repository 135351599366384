import { Component, OnInit, Inject } from "@angular/core";
import { ModalService, DATA } from "../../services/modal.service";
import { SnackService } from "app/presentationnal/organisms/snack-bar/services/snack.service";
import { FormBuilder, Validators } from "@angular/forms";
import { ModalContentForm } from "../../model/modal-content-form";

@Component({
  selector: "app-modal-update-price-request-element-weight",
  templateUrl: "./modal-update-price-request-element-weight.component.html",
  styleUrls: ["./modal-update-price-request-element-weight.component.css"]
})
export class ModalUpdatePriceRequestElementWeightComponent extends ModalContentForm implements OnInit {

  constructor(
    protected _modalService: ModalService,
    protected _snackBar: SnackService,
    protected _fb: FormBuilder,
    @Inject(DATA) private _data) {
      super(_modalService, _fb, _snackBar);
    }

  ngOnInit() {
    this.initForm();
  }

  private initForm() {
    this._formGroup = this._fb.group({
      weight: [this._data.data.weight, Validators.maxLength(200)]
    });
  }

  protected save(): void {
    const objData = {
      confirm: true,
      data: this._formGroup.value,
    };
    this.afterClosed.next(objData);
  }
}
