import { Finish, FinishProperty } from "./finish.base";
import { ITicketElement } from "app/presentationnal/organisms/wizard-modal/service/step-form.service";

export class Lacquering<T extends FinishProperty> extends Finish<T> {

    public constructor(name: string, ticket: ITicketElement[], properties?: T) {
        super(name, ticket, properties);
    }

    public get CalculatedPrice(): number {
      return !this.FixedPrice ? this.getPricePerComputing() : this.Cost;
    }

    public getPricePerComputing(): number {
      return (this.Area || 1) * this.AreaCost;
    }
}