import { ChoiceElementGroupModalComponent } from "./component/element/choice-element-group-modal/choice-element-group-modal.component";
import { ElementDimensionComponent } from "./component/element/element-dimension/element-dimension.component";
import { ElementSummaryModalComponent } from "./component/element/element-summary-modal/element-summary-modal.component";
import { SawingStepOneComponent } from "./component/operation/sawing/sawing-step-one/sawing-step-one.component";
import { DrillingStepOneComponent } from "./component/operation/drilling/drilling-step-one/drilling-step-one.component";
import { SandblastingStepOneComponent } from "./component/finish/sandblasting/sandblasting-step-one/sandblasting-step-one.component";
import { MetallizationStepOneComponent } from "./component/finish/metallization/metallization-step-one/metallization-step-one.component";
import { PaintingStepOneComponent } from "./component/finish/painting/painting-step-one/painting-step-one.component";
import { LacqueringStepOneComponent } from "./component/finish/lacquering/lacquering-step-one/lacquering-step-one.component";
import { GalvanizationStepOneComponent } from "./component/finish/galvanization/galvanization-step-one/galvanization-step-one.component";
import { TransportStepOneComponent } from "./component/option/transport/transport-step-one/transport-step-one.component";
import { MountingStepOneComponent } from "./component/option/mounting/mounting-step-one/mounting-step-one.component";
import { PodStepOneComponent } from "./component/option/pod/pod-step-one/pod-step-one.component";
import { HoistStepOneComponent } from "./component/option/hoist/hoist-step-one/hoist-step-one.component";
import { StudyStepOneComponent } from "./component/option/study/study-step-one/study-step-one.component";
import { MarginStepOneComponent,
  EmbaseStepZeroComponent, EmbaseStepOneComponent, EmbaseStepTwoComponent, EmbaseStepThreeComponent, EmbaseSummaryComponent,
  EquerreStepZeroComponent, EquerreStepOneComponent, EquerreStepTwoComponent, EquerreStepThreeComponent, EquerreStepFourComponent, EquerreSummaryComponent,
  GoussetStepZeroComponent, GoussetStepOneComponent, GoussetStepTwoComponent, GoussetStepThreeComponent, GoussetSummaryComponent,
  NervureStepZeroComponent, NervureStepOneComponent, NervureStepTwoComponent, NervureStepThreeComponent, NervureSummaryComponent,
  PlaqueStepZeroComponent, PlaqueStepOneComponent, PlaqueStepTwoComponent, PlaqueStepThreeComponent, PlaqueSummaryComponent,
  RaidisseurStepZeroComponent, RaidisseurStepOneComponent, RaidisseurStepTwoComponent, RaidisseurStepThreeComponent, RaidisseurSummaryComponent
 } from ".";

export const ELEMENT_WIZARD_CONFIG = [
    { title: "Profile", component: ChoiceElementGroupModalComponent },
    { title: "Dimension", component: ElementDimensionComponent },
    { title: "Résumé", component: ElementSummaryModalComponent },
];

export const OPERATION_WIZARD_CONFIG = [
    [
        { title: "Sciage", component: SawingStepOneComponent }
    ],
    [
        { title: "Perçage", component: DrillingStepOneComponent }
    ]
];

export const FINISH_WIZARD_CONFIG = [
    [
        { title: "Sablage", component: SandblastingStepOneComponent },
    ],
    [
        { title: "Métallisation", component: MetallizationStepOneComponent }
    ],
    [
        { title: "Peinture", component: PaintingStepOneComponent },
    ],
    [
        { title: "Laquage au four", component: LacqueringStepOneComponent }
    ],
    [
        { title: "Galvanisation", component: GalvanizationStepOneComponent },
    ]
];

export const OPTION_WIZARD_CONFIG = [
    [
        { title: "Montage", component: MountingStepOneComponent },
    ],
    [
        { title: "Nacelle", component: PodStepOneComponent }
    ],
    [
        { title: "Grue", component: HoistStepOneComponent },
    ],
    [
        { title: "Etude", component: StudyStepOneComponent },
    ],
    [
        { title: "Transport", component: TransportStepOneComponent }
    ],
    [
        { title: "Marge", component: MarginStepOneComponent }
    ],
];

// Define Custom Config
const EMBASE_WIZARD_CONFIG = [
  { title: "", component: EmbaseStepZeroComponent },
  { title: "Matière", component: EmbaseStepOneComponent },
  { title: "Laser", component: EmbaseStepTwoComponent },
  { title: "Soudure", component: EmbaseStepThreeComponent },
  { title: "Résumé", component: EmbaseSummaryComponent }
],
EQUERRE_WIZARD_CONFIG = [
  { title: "", component: EquerreStepZeroComponent },
  { title: "Matière", component: EquerreStepOneComponent },
  { title: "Laser", component: EquerreStepTwoComponent },
  { title: "Pliage", component: EquerreStepThreeComponent },
  { title: "Soudure", component: EquerreStepFourComponent },
  { title: "Résumé", component: EquerreSummaryComponent }
],
GOUSSET_WIZARD_CONFIG = [
  { title: "", component: GoussetStepZeroComponent },
  { title: "Matière", component: GoussetStepOneComponent },
  { title: "Laser", component: GoussetStepTwoComponent },
  { title: "Soudure", component: GoussetStepThreeComponent },
  { title: "Résumé", component: GoussetSummaryComponent }
],
NERVURE_WIZARD_CONFIG = [
  { title: "", component: NervureStepZeroComponent },
  { title: "Matière", component: NervureStepOneComponent },
  { title: "Laser", component: NervureStepTwoComponent },
  { title: "Soudure", component: NervureStepThreeComponent },
  { title: "Résumé", component: NervureSummaryComponent }
],
PLAQUE_WIZARD_CONFIG = [
  { title: "", component: PlaqueStepZeroComponent },
  { title: "Matière", component: PlaqueStepOneComponent },
  { title: "Laser", component: PlaqueStepTwoComponent },
  { title: "Soudure", component: PlaqueStepThreeComponent },
  { title: "Résumé", component: PlaqueSummaryComponent }
],
RAIDISSEUR_WIZARD_CONFIG = [
  { title: "", component: RaidisseurStepZeroComponent },
  { title: "Matière", component: RaidisseurStepOneComponent },
  { title: "Laser", component: RaidisseurStepTwoComponent },
  { title: "Soudure", component: RaidisseurStepThreeComponent },
  { title: "Résumé", component: RaidisseurSummaryComponent }
];

export const CUSTOM_CONFIGS = [
  { key: "Embase", config: EMBASE_WIZARD_CONFIG},
  { key: "Equerre", config: EQUERRE_WIZARD_CONFIG},
  { key: "Gousset", config: GOUSSET_WIZARD_CONFIG},
  { key: "Nervure", config: NERVURE_WIZARD_CONFIG},
  { key: "Plaque", config: PLAQUE_WIZARD_CONFIG},
  { key: "Raidisseur", config: RAIDISSEUR_WIZARD_CONFIG}
];