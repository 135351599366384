import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-empty-project",
  templateUrl: "./empty-project.component.html",
  styleUrls: ["./empty-project.component.css"]
})
export class EmptyProjectComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
