import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "app-pr-list",
  templateUrl: "./pr-list.component.html",
  styleUrls: ["./pr-list.component.css"]
})
export class PrListComponent implements OnInit {

  // tslint:disable-next-line:no-input-rename
  @Output("itemClicked") itemClicked: EventEmitter<any> = new EventEmitter();
  @Input("categories") categories = [];
  constructor() { }

  ngOnInit() {
  }

  private clickItem(event: Event, item: any, isCategory = false) {
    if (event) {
      event.preventDefault();
      if ( isCategory) {
        const el: Element = this.findParentElement(event.srcElement);
        el.classList.toggle("pr-list__item_active");
        // if ( event && event.srcElement && event.srcElement.tagName == "I" ) {
        //   el.classList.toggle("pr-list__item_active");
        // } else if ( !el.classList.contains("pr-list__item_active")) {
        //   el.classList.add("pr-list__item_active");
        // }
      }
      if (event.srcElement && event.srcElement.tagName != "I") {
        this.itemClicked.emit(item);
      }
    }

  }

  private findParentElement(el: Element) {
    while ( !el.classList.contains("pr-list__item")) {
      el = el.parentElement;
    }
    return el;
  }
}
