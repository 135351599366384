/** ---------------------------------------------- **
 *                    ELEMENTS
 ** ---------------------------------------------- */

import { Heb } from "./elements/standard/beams/heb";
import { Hea } from "./elements/standard/beams/hea";
import { Hem } from "./elements/standard/beams/hem";
import { Ipe } from "./elements/standard/beams/ipe";
import { Tole } from "./elements/standard/toles/tole";
import { RectangleTube } from "./elements/standard/tubes/rectangle-tube";
import { SquareTube } from "./elements/standard/tubes/square-tube";
import { RoundTube } from "./elements/standard/tubes/round-tube";
import { Upn } from "./elements/standard/beams/upn";
import { Ipn } from "./elements/standard/beams/ipn";
import { Upe } from "./elements/standard/beams/upe";
import { UnequalCorner } from "./elements/standard/beams/unequal-corner";
import { EqualCorner } from "./elements/standard/beams/equal-corner";
import { BeamTShape } from "./elements/standard/beams/t-beam";
import { BeamUShape } from "./elements/standard/beams/u-beam";

export const ELEMENTS_MAP = {
    "Hea": Hea,
    "Heb": Heb,
    "Hem": Hem,
    "Ipe": Ipe,
    "Upn": Upn,
    "Upe": Upe,
    "Ipn": Ipn,
    "EqualCorner": EqualCorner,
    "UnequalCorner": UnequalCorner,
    "BeamTShape": BeamTShape,
    "BeamUShape": BeamUShape,
    "Tole": Tole,
    "RectangleTube": RectangleTube,
    "SquareTube": SquareTube,
    "RoundTube" : RoundTube
};

/** ---------------------------------------------- **
 *                    ELEMENTS
 ** ---------------------------------------------- */


import { Embase } from "./elements/customs/embase";
import { Gousset } from "./elements/customs/gousset";
import { Nervure } from "./elements/customs/nervure";
import { Raidisseur } from "./elements/customs/raidisseur";
import { Equerre } from "./elements/customs/equerre";
import { Plaque } from "./elements/customs/plaque";

export const CUSTOM_MAP = {
    "Embase": Embase,
    "Gousset": Gousset,
    "Nervure": Nervure,
    "Raidisseur": Raidisseur,
    "Equerre" : Equerre,
    "Plaque" : Plaque
};

// const a = new ELEMENTS_MAP.Hea()

/** ---------------------------------------------- **
 *                    OPERATIONS
 ** ---------------------------------------------- */

import { Bending } from "./operations/bending";
import { Cutting } from "./operations/cutting";
import { Drilling } from "./operations/drilling";
import { LaserCutting } from "./operations/laser-cutting";
import { Folding } from "./operations/folding";

export const OPERATIONS_MAP = {
    "Bending": Bending,
    "Cutting": Cutting,
    "Drilling": Drilling,
    "Folding": Folding,
    "LaserCutting": LaserCutting
};

/** ---------------------------------------------- **
 *                    ASSEMBLAGES
 ** ---------------------------------------------- */

import { Welding } from "./assemblages/welding";

export const ASSEMBLAGES_MAP = {
    "Welding": Welding
};

/** ---------------------------------------------- **
 *                     FINISHES
 ** ---------------------------------------------- */

import { Sandblasting } from "./finish/sandblasting";
import { Metallization } from "./finish/metallization";
import { Lacquering } from "./finish/lacquering";
import { Painting } from "./finish/painting";
import { Galvanization } from "./finish/galvanization";

export const FINISHES_MAP = {
    "Sandblasting": Sandblasting,
    "Metallization": Metallization,
    "Lacquering": Lacquering,
    "Painting": Painting,
    "Galvanization": Galvanization
};

/** ---------------------------------------------- **
 *                     FINISHES
 ** ---------------------------------------------- */

import { Crane } from "./options/crane";
import { Nacelle } from "./options/nacelle";
import { Montage } from "./options/montage";
import { Transport } from "./options/transport";
import { Study } from "./options/study";
import { Margin } from "./options/margin";

export const OPTIONS_MAP = {
    "Crane": Crane,
    "Montage": Montage,
    "Nacelle": Nacelle,
    "Transport": Transport,
    "Study": Study,
    "Margin": Margin
};