import { Component, OnInit, Inject } from "@angular/core";
import { StepLaserCuttingBase } from "../../base/step-laser-cutting-base";
import { CONTAINER_DATA, StepFormService } from "app/presentationnal/organisms/wizard-modal/service/step-form.service";
import { FormBuilder } from "@angular/forms";

@Component({
  selector: "app-equerre-step-two",
  templateUrl: "./equerre-step-two.component.html",
  styleUrls: ["./equerre-step-two.component.css"]
})
export class EquerreStepTwoComponent extends StepLaserCuttingBase implements OnInit {

  constructor(@Inject(CONTAINER_DATA) data: any,
              protected _stepFormSrv: StepFormService,
              protected _fb: FormBuilder) {
    super(data, _stepFormSrv, _fb);
  }

}
