import { Component, OnInit, Input, Renderer2, ElementRef } from "@angular/core";
import { IInformationsCardConfig } from "app/facade/interfaces/informations-card-data.interface";
import { IStatusConfig } from "app/facade/interfaces/status-config.interface";

@Component({
  selector: "app-informations-card",
  templateUrl: "./informations-card.component.html",
  styleUrls: ["./informations-card.component.css"]
})
export class InformationsCardComponent implements OnInit {
  @Input() public smallLabelPart: boolean = false;
  @Input() public datas: any = null;
  @Input() public configs: IInformationsCardConfig = null;

  constructor(
    private renderer: Renderer2,
    private elementRef: ElementRef) {
      this.renderer.addClass(this.elementRef.nativeElement, "information-card");
  }

  ngOnInit() {
  }

  public getStatusClass(statusConfigs: IStatusConfig[], key: string): string {
    const statusValue: string | number = this.datas[key];
    const statusConfig: IStatusConfig = statusConfigs.find( config => config.value === statusValue);
    return statusConfig ? statusConfig.color : "";
  }

  public getStatusLabel(statusConfigs: IStatusConfig[], key: string): string {
    const statusValue: string | number = this.datas[key];
    const statusConfig: IStatusConfig = statusConfigs.find( config => config.value === statusValue);
    return statusConfig ? statusConfig.label : key;
  }
}
