import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { LoginPageComponent } from "./login-page/login-page.component";
import { OrganismsModule } from "app/presentationnal/organisms/organisms.module";

@NgModule({
  imports: [
    CommonModule,
    OrganismsModule
  ],
  exports: [ LoginPageComponent],
  declarations: [
    LoginPageComponent
  ]
})
export class LoginModule { }
