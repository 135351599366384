import { Component, OnInit, Inject, Renderer2, OnDestroy } from "@angular/core";
import { StepWeldingBase } from "../../base/step-welding-base";
import { CONTAINER_DATA, StepFormService } from "app/presentationnal/organisms/wizard-modal/service/step-form.service";
import { FormBuilder } from "@angular/forms";

@Component({
  selector: "app-gousset-step-three",
  templateUrl: "./gousset-step-three.component.html",
  styleUrls: ["./gousset-step-three.component.css"]
})
export class GoussetStepThreeComponent extends StepWeldingBase implements OnInit, OnDestroy {

  constructor(@Inject(CONTAINER_DATA) data: any,
              protected _stepFormSrv: StepFormService,
              protected renderer: Renderer2,
              protected _fb: FormBuilder) {
                super(data, _stepFormSrv, renderer, _fb);
              }

}
