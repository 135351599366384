import { Component, OnInit, OnDestroy } from "@angular/core";
import { SuppliersQueriesService } from "app/facade/queries/supplier/suppliers-queries.service";
import { TABLE_ITEM_ACTION_TYPE } from "app/presentationnal/organisms/table/table-item/enum/table-item-action-type.enum";
import { TableDatasDefinition } from "app/facade/interfaces/table-datas-definition";
import { ModalService } from "app/presentationnal/organisms/modal/services/modal.service";
import { Subscription } from "rxjs";
import { SnackService } from "app/presentationnal/organisms/snack-bar/services/snack.service";
import { MODAL_TYPE } from "app/presentationnal/organisms/modal/enums/modal-type.enum";
import { ModalAddSupplierComponent, ModalConfirmComponent } from "app/presentationnal/organisms/modal";
import { Router } from "@angular/router";
import { ISupplier } from "app/facade/interfaces/supplier";
import { MODAL_CLOSE_ACTION } from "app/presentationnal/organisms/modal/enums/modal-close-action.enum";
import { IPagination } from "app/facade/interfaces/crud.interface";
import { ListTableService } from "app/facade/services/list-table.service";

const DEFAULT_LIMIT = 25;

@Component({
  selector: "app-listing-supplier",
  templateUrl: "./listing-supplier.component.html",
  styleUrls: ["./listing-supplier.component.css"]
})
export class ListingSupplierComponent implements OnInit, OnDestroy {

  public supplierCategorie = "suppliers";
  private _actionsType = TABLE_ITEM_ACTION_TYPE;
  private _listSupplierSub: Subscription;
  private _deleteSupplierSub: Subscription;
  public suppliers: ISupplier[] = [];
  public tableHeaders: string[] = [
    "Code Fournisseur",
    "Nom Fournisseur",
    "Email Général",
    "Téléphone Général",
    "Remarque",
    "Catégories fournies",
    "actions"];
  public dataDefinition: TableDatasDefinition[] = [
    { key: "code", isTitle: true},
    { key: "name", isTitle: true},
    { key: "mail", isTitle: false},
    { key: "phone", isTitle: false},
    { key: "remark", isTitle: false},
    { key: "parentSupplyCategories", isTitle: false }
  ];
  private _pagination: IPagination = { page: 1, limit: DEFAULT_LIMIT };

  constructor(
    private _suppliersQueriesSrv: SuppliersQueriesService,
    private _modalService: ModalService,
    private snackBar: SnackService,
    private _listTableSrv: ListTableService,
    private router: Router) { }

  ngOnInit() {
    this.getSupplierList();
  }

  ngOnDestroy(): void {
    this._listSupplierSub.unsubscribe();
    if (this._deleteSupplierSub) { this._deleteSupplierSub.unsubscribe(); }
  }

  public getSupplierList() {
    if (this._listSupplierSub) { this._listSupplierSub.unsubscribe(); }
    this._listSupplierSub = this._suppliersQueriesSrv.getSupplierListing(this._pagination).subscribe( result => {
      const data: any = result.data;
      if (data && data.suppliers) {
        this.suppliers = [
          ...this.suppliers,
          ...data.suppliers.map((supplier: ISupplier) => {
            return {
              ...supplier,
              parentSupplyCategories: supplier.parentSupplyCategories.map(cat => cat.name).join(", ")
            };
          })
        ];
      }
      if (data && data.pagination) {
        this._listTableSrv.setLoadMoreStatus(data.pagination.hasNext);
      }
    }, error => {
      console.log("ERROR LOADING SUPPLIER > ", {error});
    });
  }

  public addSupplier($event: any) {
    const modalRef = this._modalService.openModal(ModalAddSupplierComponent, {
      title: "Informations fournisseur",
      type: MODAL_TYPE.NORMAL,
      data: null,
      closeAction: MODAL_CLOSE_ACTION.SAVE
    });
    if (modalRef) {
      modalRef.afterClosed.subscribe(res => {
        if (res.confirm && res.data) {
          this.router.navigate([`${this.router.url}/edit/${+res.data.id}`]);
        }
      });
    }
  }

  public tableButtonClicked($event: { actionType: number, itemId: number}) {
    switch (this._actionsType[$event.actionType]) {
      case "DELETE":
        this.beforeDeleteSupplier($event.itemId);
        break;
      case "EDIT":
        this.router.navigate([`${this.router.url}/edit/${$event.itemId}`]);
        break;
      default:
        break;
    }
  }

  private beforeDeleteSupplier(supplierId: number) {
    const item = this.suppliers.find(data => data.id == supplierId);
    this._modalService.openModal(ModalConfirmComponent,
      {
        title: "Êtes-vous sûr?",
        type: MODAL_TYPE.CONFIRM,
        data: {
          message: `Voulez-vous supprimer le fournisseur <b>${item.name}</b>?`,
          actions: [
            "Supprimer le fournisseur de manière définitive",
            "Supprimer les contacts de ce fournisseur"
          ],
        },
        params: { supplierId },
        confirmCallback: this.deleteSupplier
      }
    );
  }

  public deleteSupplier = (params: { supplierId: number|string }) => {
    const snackBarTitle: string = "Supprimer un fournisseur";
    if (this._deleteSupplierSub) { this._deleteSupplierSub.unsubscribe(); }
    this._deleteSupplierSub = this._suppliersQueriesSrv.deleteSupplier(+params.supplierId).subscribe(result => {
      if (result.data["deleteSupplier"]) {
        const index = this.suppliers.findIndex(supplier => supplier.id == params.supplierId);
        this.suppliers = [...this.suppliers.slice(0, index), ...this.suppliers.slice(index + 1)];
        this.snackBar.open(snackBarTitle, "Le fournisseur a été supprimé", "success", 5000);
      } else {
        this.snackBar.openSnackBarError(snackBarTitle, "Le fournisseur n'a pas été supprimé");
      }
    }, error => {
      this.snackBar.openSnackBarError(snackBarTitle, "Le fournisseur n'a pas été supprimé", error);
    });
  }

  public loadMore(event: boolean) {
    if (this.suppliers.length > 0) { this._pagination.page++; }
    this.getSupplierList();
  }
}
