import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-empty-quote",
  templateUrl: "./empty-quote.component.html",
  styleUrls: ["./empty-quote.component.css"]
})
export class EmptyQuoteComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
