import { Component, OnInit, Inject, OnDestroy } from "@angular/core";
import { ModalContentForm } from "../../model/modal-content-form";
import { ModalService, DATA } from "../../services/modal.service";
import { SnackService } from "app/presentationnal/organisms/snack-bar/services/snack.service";
import { FormBuilder, FormGroup } from "@angular/forms";
import { ISelectOption } from "app/presentationnal/atoms/inputs/select-input/selectOptions";
import { IPurchaseOrder } from "app/facade/interfaces/purchase-order.interface";
import { IModalData } from "../../interfaces/modal-data.interface";
import { UploadFileService } from "@lib/upload-file/upload-file.service";
import { PurchaseOrderSource } from "app/facade/enums/purchaseOrder.enum";
import { ScanPdfInput } from "app/facade/interfaces/scan-pdf.interface";

interface IPurchaseOrderModalData extends IModalData {
  data: {
    purchaseOrder: IPurchaseOrder;
    isCreated: boolean;
    onlyUpdateRemark: boolean;
  };
}

@Component({
  selector: 'app-modal-add-scan-pdf',
  templateUrl: './modal-add-scan-pdf.component.html',
  styleUrls: ['./modal-add-scan-pdf.component.css']
})
export class ModalAddScanPdfComponent extends ModalContentForm implements OnInit, OnDestroy {

  private _purchaseOrderData: IPurchaseOrder;
  private _scanPdf : any;
  private _listScanPdf: ScanPdfInput[] = [];
  public requiredField: string[] = [];
  public supplierOptions: ISelectOption[] = [];
  public projectOptions: ISelectOption[] = [];
  public isCreated: boolean = true;
  public onlyUpdateRemark: boolean = false;
  public tableFormLoaded: boolean = false;
  public formInputFileGroup: FormGroup;

  constructor(
    protected _modalService: ModalService,
    protected _snackBar: SnackService,
    protected _fb: FormBuilder,
    @Inject(DATA) private _data: IPurchaseOrderModalData,
    private _uploadFileSrv: UploadFileService,
  ) {
    super(_modalService, _fb, _snackBar);
    this._purchaseOrderData = this._data && this._data.data && this._data.data.purchaseOrder ? this._data.data.purchaseOrder : null;
  }

  ngOnInit() {
    this.initForm();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  private initForm() {
    this.formInputFileGroup = this._fb.group({
      fileValue: [(this. _purchaseOrderData && this. _purchaseOrderData.source === PurchaseOrderSource.PDF) ?
        (this._purchaseOrderData.model) ? this._purchaseOrderData.model : "Pas de nom de model définit"  : null],
    });
  }

  protected save(data: IPurchaseOrder): void {
    if (this._formGroup.valid) {
      const objData = {
        confirm: true,
        data
      };
      this.afterClosed.next(objData);
    }
  }

  public confirmModal(): void {
    this._modalService.closeModal();
  }

  /**
   * @description Load PDF File and generate formGroups
   * @author Marie Claudia
   * @param {File} pdf
   * @memberof ModalAddSupplyListComponent
   */
  public uploadPdfFile(pdf: File[]) {
    this.tableFormLoaded = false;
    const formData = new FormData();
    const snackBarTitle: string = "Upload fichier pdf";
    const pdfName : string[] = [];
    for(var i=0; i<pdf.length; i++){
      pdfName.push(pdf[i].name);
      formData.append("pdf[]", pdf[i], pdf[i].name);
    }

    this.formInputFileGroup.get("fileValue").patchValue(pdfName);

    this._uploadFileSrv.uploadFile(formData, "/api/scan/" + this._purchaseOrderData.id).subscribe( result => {
      this._scanPdf = result;
      this._snackBar.open(snackBarTitle, "Le fichier pdf a bien été importé", "success", 5000);
      this.tableFormLoaded = true;
      this._modalService.closeModal();
      setTimeout(() => document.location.reload(), 200);
    });
  }

  public formDataScanpdf(): ScanPdfInput[]{
    for (let index = 0; index < this._scanPdf.length; index++) {
      const element = this._scanPdf[index];
      const scanElement: ScanPdfInput = {
        name:element.originalname,
        url:element.path,
        purchaseOrderId:+this._purchaseOrderData.id
      }
      this._listScanPdf.push(scanElement);
    }
    return this._listScanPdf;
  }

}



