import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { MODAL_TYPE } from "app/presentationnal/organisms/modal/enums/modal-type.enum";
import { ModalService } from "app/presentationnal/organisms/modal/services/modal.service";
import { StepFormService } from "app/presentationnal/organisms/wizard-modal/service/step-form.service";
import { QuotesQueriesService } from "app/facade/queries/quote/async/quotes_queries.service";
import { Quote } from "app/facade/interfaces";
import { SnackService } from "app/presentationnal/organisms/snack-bar/services/snack.service";
import * as moment from "moment";
import { ModalEditRemarksComponent } from "app/presentationnal/organisms/modal/components/modal-edit-remarks/modal-edit-remarks.component";
import { ModalConfirmComponent } from "app/presentationnal/organisms/modal";
import { ApplicationConfig } from "app/app.config";
import { PermissionService } from "app/facade/services/permission.service";
import { AuthService } from "@lib/auth/auth.service";

@Component({
  selector: "app-quote-details",
  templateUrl: "./quote-details.component.html",
  styleUrls: ["./quote-details.component.css"]
})
export class QuoteDetailsComponent implements OnInit {
  public project;
  public id = 1;
  public quoteActual;
  isEdit = false;
  isDelete = false;
  public userGroup : any;
  public quoting: Quote;
  public isEmpty: boolean = false;
  public urlPdf = ApplicationConfig.Url;
  public items = [{ name: "Ticket" }, { name: "Devis", path: "/quote" }];
  constructor(private route: ActivatedRoute,
    private router: Router,
    private _modalService: ModalService,
    private _stepFormSrv: StepFormService,
    private _quoteSrv: QuotesQueriesService,
    private snackBar: SnackService,
    private _quoteService: QuotesQueriesService,
    private _permissionService: PermissionService,
    private _authSrv: AuthService) { }

  ngOnInit() {
    this.userGroup =  this._authSrv.isUserGroup();
    //get and update Permission
    this._permissionService.getAndUpdatePermission(this.userGroup);
    this.isEdit = this._permissionService.hasPermission(this.userGroup,"quotations","write") ? true : false;
    this.isDelete = this._permissionService.hasPermission(this.userGroup,"quotations","delete") ? true : false;
    this.loadQuote();
  }


  public updateQuote() {
    this.router.navigate(["/quote/edit/" + this.quoteActual.id]);
  }

  public loadQuote() {
    const idQuote = this.route.snapshot.paramMap.get("id");
    this._quoteSrv.getQuote(idQuote).subscribe(element => {
       this.quoting = element.data["quote"];
      this.quoteActual = {
        id: this.quoting._id,
        name: this.quoting.name,
        number: this.quoting.number,
        reference: this.quoting.reference,
        date: moment.unix(   this.quoting.createdAt).format("DD/MM/YYYY"),
        project: this.quoting.project.name,
        remarks: this.quoting.remarks,
        totalPrice: this.quoting.totalPrice,
        isEn1090: this.quoting.isEn1090
      };
      this.isEmpty = this.quoting.elements.length > 0;
      const omitTypename = (key, value) => (key === "__typename" ? undefined : value);
      const newQuote = JSON.parse(JSON.stringify(this.quoting), omitTypename);
      this._stepFormSrv.initializeTicket(newQuote);
    });
  }

  /**
   * @description Confirmation before delete
   * @author Nicolas Van Der Haeghen
   * @private
   * @memberof QuoteDetailsComponent
   */
  private beforeDeleteQuote() {
    this._modalService.openModal(ModalConfirmComponent,
      {
        title: "Etes-vous sur?",
        type: MODAL_TYPE.CONFIRM,
        data: {
          message: `Voulez vous supprimer le devis <b>${this.quoteActual.number}</b>?`,
          actions: ["Supprimer le devis de manière définitive"],
        },
        confirmCallback: this.deleteQuote
      }
    );
  }

  /**
   * @description Delete quote & handle success/error snackbars
   * @memberof QuoteDetailsComponent
   */
  public deleteQuote = () => {
    const snackBarTitle: string = "Supprimer un devis";
    this._quoteSrv.deleteQuote(this.quoteActual.id).subscribe(result => {
      if (result.data["deleteQuote"]) {
        this.snackBar.open(snackBarTitle, "Le devis a été supprimé", "success", 5000);
      } else {
        this.snackBar.openSnackBarError(snackBarTitle, "Une erreur est survenue");
      }
      this.router.navigate(["/quote"]);
    }, error => {
      this.snackBar.openSnackBarError(snackBarTitle, "Le devis n'a pas été supprimé", error);
    });
  }

  /**
   * @description Edit an existing project
   * @author Kevin Palade
   * @param {string} projectId
   * @memberof ListingQuoteComponent
   */
  public editProject() {
    const modalRef = this._modalService.openModal(ModalEditRemarksComponent, { title: "Edition des remarques du ticket", type: MODAL_TYPE.NORMAL, data: this.quoteActual.remarks });
    if (modalRef) {
      modalRef.afterClosed.subscribe(res => {
        const remarkQuote: Quote = {
          remarks : res.data["remarks"],
        };
        const snackBarTitle: string = "Créer un devis";
        this._quoteSrv.updateQuote(this._stepFormSrv.getQuoteId(), remarkQuote).subscribe(result => {
          this.loadQuote();
          this.snackBar.open(snackBarTitle, "Le devis a été modifié", "success", 5000);
        }, error => {
          this.snackBar.openSnackBarError(snackBarTitle, "Le devis n'a pas été modifié", error);
        });
      });
    }
  }

  public duplicateQuote() {
    this._modalService.openModal(ModalConfirmComponent,
      {
        title: "Etes-vous sur?",
        type: MODAL_TYPE.NORMAL,
        data: {
          message: `Voulez vous vraiment dupliquer le devis n°<b>${this.quoteActual.number} - ${this.quoteActual.name}</b> ?`
        },
        params: {id: this.quoteActual.id},
        confirmCallback: this.confirmDuplicate,
        customData: {
          confirmButtonName: "Dupliquer"
        }
      }
    );
  }

  public confirmDuplicate = (params: any) => {
    const snackBarTitle: string = "Dupliquer un devis";
    this._quoteService.duplicateQuote(params.id).subscribe( (result: any) => {
      this.snackBar.open(snackBarTitle, "Le devis a été dupliqué", "success", 5000);
      this.router.navigate(["quote/edit/" + result.data.duplicateQuote._id]);
    }, error => {
      this.snackBar.openSnackBarError(snackBarTitle, "Le devis n'a pas été dupliqué", error);
    });
  }

  public goToListQuote() {
    this.router.navigate(["/quote"]);
  }

  public workSummary(quoteId: string) {
    window.open(`${this.urlPdf}/api/quotes/${quoteId}/resume.pdf`);
  }


}
