import { IProject } from "./project";
import { ISupplier, ISupplierContact, ISupplyCategory } from "./supplier";
import { IPriceRequest, IpriceRequestAdditionnalCostType, IpriceRequestAdditionnalCostUnit, IPriceRequestElementOptionType, IPriceRequestElementOptionUnit } from "./price-request.interface";
import { IUser } from "./user.interface";
import { ISupplierOfferElement } from "./supplier-offer";
import { OrderByDirection } from "../enums/order-by-direction.enum";
import { FormArray } from "@angular/forms";
import { EnumElementUnit, EnumQuantityUnit } from "../enums/element-unit-category.enum";
import { ScanPdf } from "./scan-pdf.interface";

export enum EnumPurchaseOrderStatus {
  CREATED = "CREATED",
  SENT = "SENT",
  CANCELLED = "CANCELLED"
}

// PURCHASE ORDER DETAIL
export interface IPurchaseOrder {
  id: number;
  reference: string;
  status: EnumPurchaseOrderStatus;
  sendingDate: Date;
  remark: string;
  internalRemark: string;
  projectId: number;
  project: IProject;
  supplierId: number;
  supplier: ISupplier;
  supplierContactId: number;
  supplierContact: ISupplierContact;
  priceRequestId: number;
  priceRequest: IPriceRequest;
  userId: number;
  user: IUser;
  model?: string;
  source?: string;
  createdAt: Date;
  deletedAt: Date;
  additionnalCosts: IPurchaseOrderAdditionnalCost[];
  elements: IPurchaseOrderElement[];
  linkedProjects: IProject[];
  totalPrice: number;
  totalAdditionnalCosts: number;
  scanPdfs: ScanPdf[];
}

export interface IPurchaseOrderAdditionnalCost {
  id: number;
  type: IpriceRequestAdditionnalCostType;
  denomination: string;
  quantity: number;
  price: number;
  unit: IpriceRequestAdditionnalCostUnit;
  purchaseOrderId: number;
  purchaseOrder: IPurchaseOrder;
}

export enum EnumPurchaseOrderAction {
  ADD = "ADD",
  View = "VIEW"
}

export enum EnumPurchaseOrderElementStatus {
  ATTENTE = "ATTENTE",
  ENCOURS = "ENCOURS",
  RECEPTIONNE = "RECEPTIONNE"
}

const actionList: any[] = [];
export interface IPurchaseOrderElement {
  id: number;
  quantity: number;
  denomination: string;
  status: EnumPurchaseOrderElementStatus;
  isEn1090: boolean;
  isBlack: boolean;
  isBlasted: boolean;
  isPrimaryBlasted: boolean;
  format: string;
  weight: number;
  matterRef: string;
  quantityUnit: EnumQuantityUnit;
  thickness: number;
  length: number;
  width: number;
  remark: string;
  deliveryDate: Date;
  price: number;
  unit: EnumElementUnit;
  isPrinted: boolean;
  printedQuantity: number;
  purchaseOrderId: number;
  projectRef: string;
  purchaseOrder: IPurchaseOrder;
  supplierOfferElementId: number;
  supplierOfferElement: ISupplierOfferElement;
  supplyCategoryId: number;
  supplyCategory: ISupplyCategory;
  options: IPurchaseOrderElementOption[];
  internalRemark: string;
}

export interface IPurchaseOrderElementOption {
  id: number;
  type: IPriceRequestElementOptionType;
  denomination: string;
  quantity: number;
  price: number;
  unit: IPriceRequestElementOptionUnit;
  purchaseOrderElementId: number;
  purchaseOrderElement: IPurchaseOrderElement;
}

// PURCHASE ORDER FILTER & SORT

export enum EnumPurchaseOrderSortBy {
  ID = "ID",
  REFERENCE = "REFERENCE",
  SUPPLIER = "SUPPLIER",
  CREATED_AT = "CREATED_AT",
  PRICE_REQUEST = "PRICE_REQUEST",
  STATUS = "STATUS",
  USER = "USER"
}
export interface IPurchaseOrderFilter {
  reference: string;
  status: EnumPurchaseOrderStatus;
  createdAt: Date;
}


export interface IPurchaseOrderSort {
  sortBy: EnumPurchaseOrderSortBy;
  sortDirection: OrderByDirection;
}

// PURCHASE ORDER ELEMENT CREATE & UPDATE
export interface IPurchaseOrderElementInput {
  quantity: number;
  quantityUnit?: string;
  denomination?: string;
  remark?: string;
  deliveryDate?: Date;
  realDeliveryDate?: Date;
  price?: number;
  unit: EnumElementUnit;
  purchaseOrderId: number;
  supplyCategoryId?: number;
  options: IPurchaseOrderElementOptionInput[];
  internalRemark?: string;
  isEn1090?: boolean;
  isBlack?: boolean;
  isBlasted?: boolean;
  isPrimaryBlasted?: boolean;
  format?: string;
  weight?: number;
  matterRef?: string;
  thickness?: number;
  projectRef?: string;
  length?: number;
  width?: number;
  isPrinted?: boolean;
  printedQuantity?: number;
  supplierOfferElementId?: number;
}

export interface IPurchaseOrderElementUpdate {
  id?: number;
  quantity?: number;
  quantityUnit?: string;
  denomination?: string;
  remark?: string;
  deliveryDate?: Date;
  price?: number;
  unit?: EnumElementUnit;
  options: IPurchaseOrderElementOptionInpdate[];
  deletedOptionIds: number[];
  internalRemark?: string;
}

export interface IPurchaseOrderElementUpdateRemark {
  remark?: string;
}

export interface IPurchaseOrderElementUpdateFormat {
  format?: number;
}

export interface IPurchaseOrderElementUpdateDenomination {
  denomination?: string;
}

export interface IPurchaseOrderElementUpdateMatiere {
  matterRef?: string;
}

export interface IPurchaseOrderElementUpdateQuantity {
  quantity?: number;
}

export interface IPurchaseOrderElementUpdateWeight {
  weight?: number;
}

export interface IPurchaseOrderElementUpdateWidth {
  width?: number;
}

export interface IPurchaseOrderElementUpdateLength {
  length?: number;
}

export interface IPurchaseOrderElementUpdateThickness {
  thickness?: number;
}

export interface IPurchaseOrderElementUpdatePrice {
  price?: number;
}

export interface IPurchaseOrderElementUpdateDeliveryDate {
  deliveryDate?: Date;
}

export interface IPurchaseOrderElementUpdateQuantityUnit {
  quantityUnit?: string;
}

export interface IPurchaseOrderElementUpdateEn1090 {
  isEn1090?: string;
}

export interface IPurchaseOrderElementUpdateOption {
  option?: string;
}

// PURCHASE ORDER CREATE & UPDATE
export interface IPurchaseOrderFromSupplierOfferInput {
  supplierOfferId: number;
  elements: IPOSupplierOfferElementInput[];
  additionnalCosts: IPOSupplierOfferAdditionnalCostInput[];
}

export interface IPOSupplierOfferElementInput {
  supplierOfferElementId: number;
  priceRequestElementId: number;
  quantity: number;
}

export interface IPOSupplierOfferAdditionnalCostInput {
  priceRequestAdditionnalCostId: number;
  supplierOfferAdditionnalCostId: number;
  quantity?: number;
  price?: number;
}

export interface IPurchaseOrderFromProjectInput {
  reference: string;
  remark: string;
  internalRemark: string;
  projectId: number;
  supplierId: number;
  price: number;
  scanPdfs: ScanPdf[];
}

export interface IPurchaseOrderUpdate {
  status?: EnumPurchaseOrderStatus;
  remark?: string;
  internalRemark?: string;
  projectId?: number;
  supplierId?: number;
  supplierContactId?: number;
}

// PURCHASE ORDER ADDITIONNAL COST & OPTIONS CREATE & UPDATE
export interface IPurchaseOrderAdditionnalCostInput {
  type?: IpriceRequestAdditionnalCostType;
  denomination?: string;
  quantity?: number;
  price?: number;
  unit?: IpriceRequestAdditionnalCostUnit;
  purchaseOrderId?: number;
}

export interface IPurchaseOrderAdditionnalCostUpdate {
  id?: number;
  type?: IpriceRequestAdditionnalCostType;
  denomination?: string;
  quantity?: number;
  price?: number;
  unit?: IpriceRequestAdditionnalCostUnit;
  purchaseOrderId?: number;
}

export interface IPurchaseOrderElementOptionInput {
  type: IPriceRequestElementOptionType;
  denomination?: string;
  quantity: number;
  price?: number;
  unit: IPriceRequestElementOptionUnit;
}

export interface IPurchaseOrderElementOptionInpdate {
  id?: number;
  type?: IPriceRequestElementOptionType;
  denomination?: string;
  quantity?: number;
  price?: number;
  unit?: IPriceRequestElementOptionUnit;
}

export interface IPurchaseOrderElementOptionUpdate {
  id?: number;
  type?: IPriceRequestElementOptionType;
  denomination?: string;
  quantity?: number;
  price?: number;
  unit?: IPriceRequestElementOptionUnit;
  purchaseOrderElementId?: number;
}

// Custom Data

export enum EnumCustomPurchaseOrderStatus {
  CREATED = "Créé",
  SENT = "Envoyé",
  CANCELLED = "Annulé"
}

export enum EnumCustomPurchaseOrderAction {
  ADD = "Ajouter admission",
  View = "VIEW"
}

export enum ICustomPurchaseOrderSortBy {
  ID = "ID",
  REFERENCE = "reference",
  SUPPLIER = "supplier",
  CREATED_AT = "createdAt",
  PRICE_REQUEST = "priceRequest",
  STATUS = "status",
  REMARK = "remark",
  USER = "createdBy"
}

export interface ICustomPurchaseOrder {
  id: number;
  reference: string;
  supplier: string;
  project: string;
  createdAt: string;
  createdBy?: string;
  priceRequest: string;
  status: string;
  remark?: string;
  actionsList?: any[];
  internalRemark?: string;
  scanPdfs?:ScanPdf[];
}

export interface IElementPurchaseOrder {
  id: number;
  quantity: number;
  denomination: string;
  priceWithUnit?:string;
  // price: number;
  // unit?:string;
  measures: string;
  // format: string;
  // length: number;
  // width: number;
  // thickness: string;
  weight: number;
  matterRef: string;
  quantityUnit?: string;
  remarks: string;
  purchaseOrder: FormArray;
  projectRef: string;
  projectId: number;
  status: EnumPurchaseOrderElementStatus;
}

export interface FilterPurchaseOrderElement{
  category?:string[],
  reference?: string,
  project?: string,
  bdc?: string,
  dateFrom?: Date,
  dateTo?: Date ,
  denom?:string,
  format?:string,
  unit?:string,
  poids?:number,
  long?:number,
  larg?:number,
  remarque?:string,
  status?:string,
  thickness?:string,
}

export interface IPurchaseOrderElementFilter {
  deleted?: boolean;
  search?: string;
}

export interface ICustomPurchaseOrderElementForm {
  id: number;
  quantity: number;
  quantityUnit: string;
  denomination: string;
  remark?: string;
  deliveryDate?: Date;
  deliveryDateFormated?: string;
  price?: number;
  unit?: EnumElementUnit;
  purchaseOrderId: number;
  options: FormArray;
}

export interface ICustomPurchaseOrderElementOptionForm {
  id?: number;
  type?: IPriceRequestElementOptionType;
  denomination?: string;
  quantity?: number;
  price?: number;
  unit?: IPriceRequestElementOptionUnit;
  canBeDeleted: boolean;
  printDeleteConfirmation: boolean;
}

export interface ICustomPurchaseOrderAdditionnalCostForm {
  id: number;
  quantity: number;
  type: IpriceRequestAdditionnalCostType;
  denomination: string;
  price?: number;
  unit?: IpriceRequestAdditionnalCostUnit;
  purchaseOrderId: number;
}
