import { Component, OnInit, Output, EventEmitter, Input } from "@angular/core";
import { SnackService } from "../snack-bar/services/snack.service";

@Component({
  selector: "app-subheader",
  templateUrl: "./subheader.component.html",
  styleUrls: ["./subheader.component.css"]
})
export class SubheaderComponent implements OnInit {

  @Output() optionSelected = new EventEmitter<boolean>();
  @Input() statusTab: boolean;
  constructor(private snackBar: SnackService) { }

  ngOnInit() {
  }

  public selectOption(event: Event, options: boolean) {
    if (event) { event.preventDefault(); }
    this.optionSelected.emit(options);
  }

}
