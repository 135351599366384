import { Component, OnInit, Renderer2, ElementRef, Input, OnChanges, SimpleChanges, Output, EventEmitter } from "@angular/core";
import { IBarsetItemContent } from "app/facade/interfaces/barset.interface";

@Component({
  selector: "app-barset-item-content",
  templateUrl: "./barset-item-content.component.html",
  styleUrls: ["./barset-item-content.component.css"]
})
export class BarsetItemContentComponent implements OnInit, OnChanges {
  @Input() public contentData: IBarsetItemContent;
  @Input() public editMode: boolean;
  @Output() public onMoveAmalgamPart: EventEmitter<IBarsetItemContent> = new EventEmitter<IBarsetItemContent>();

  constructor(
    private _renderer: Renderer2,
    private _elementRef: ElementRef ) {
    }

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges): void {
  }

  public moveAmalgamPart() {
    this.onMoveAmalgamPart.emit(this.contentData);
  }

}
