/*
 * @Author: Kevin Palade
 * @Date: 2018-09-08 14:48:35
 * @Last Modified by: Kevin Palade
 * @Last Modified time: 2018-11-16 12:06:05
 */
import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges, OnChanges, Inject, LOCALE_ID } from "@angular/core";
import { TABLE_ITEM_ACTION_TYPE } from "./enum/table-item-action-type.enum";
import { FormGroup, FormBuilder, FormControl, Validators } from "@angular/forms";
import { SnackService } from "../../snack-bar/services/snack.service";
import { numberMatcher } from "app/presentationnal/atoms/inputs/custom-validator";
import { formatNumber } from "@angular/common";
import { PermissionService } from "app/facade/services/permission.service";
import { AuthService } from "@lib/auth/auth.service";
@Component({
  selector: "app-table-item",
  templateUrl: "./table-item.component.html",
  styleUrls: ["./table-item.component.css"]
})
export class TableItemComponent implements OnInit, OnChanges {
  @Input("categorie") public categorie : string;
  @Input() contentItem: any[] = [];
  @Input("elementNatureDefinitions") public elementNatureDefinitions: any[] = [];
  @Input("displayActions") public displayActions: boolean = true;
  @Input("displayAsTitle") public displayAsTitle: string[] = [];
  @Output("buttonClicked") public buttonClicked: EventEmitter<{ itemId: number | string, actionType: number, value?: any }> = new EventEmitter();
  @Input("rowIndex") public rowIndex: number;
  @Input("isEditable") public isEditable: boolean = false;
  @Input("isAddRow") public isAddRow: boolean = false;
  @Input("defaultValues") public defaultValues: any;


  private _TABLE_ITEM_ACTION_TYPE = TABLE_ITEM_ACTION_TYPE;
  private _formGroup: FormGroup;
  isEdit = false;
  isDelete = false;
  public userGroup : any;
  constructor( @Inject(LOCALE_ID) private _locale: string,
              private _fb: FormBuilder,
              private _snackbar: SnackService,
              private _permissionService: PermissionService,
              private _authSrv: AuthService,) { }

  ngOnInit() {

    this.userGroup =  this._authSrv.isUserGroup();
    //get and update Permission
    this._permissionService.getAndUpdatePermission(this.userGroup);
    this.isEdit = this._permissionService.hasPermission(this.userGroup,this.categorie,"write") ? true : false;
    this.isDelete = this._permissionService.hasPermission(this.userGroup,this.categorie,"delete") ? true : false;
   }

  ngOnChanges(changes: SimpleChanges) {
    if ((changes["isEditable"] && this.isEditable) || this.isAddRow) {
      this.initFormGroup();
    }
  }

  /**
   * @description Emit an event with action type to parent on click on a button
   * @author Kevin Palade
   * @private
   * @param {number} itemId
   * @param {number} actionType
   * @memberof TableItemComponent
   */
  private buttonClick(itemId: number, actionType: number): void {

    if (actionType == this._TABLE_ITEM_ACTION_TYPE.SAVE && !this._formGroup.valid) {
      Object.keys(this._formGroup.controls).forEach(control => {
        this._formGroup.get(control).updateValueAndValidity();
      });
    }
    if (actionType != this._TABLE_ITEM_ACTION_TYPE.SAVE || (actionType == this._TABLE_ITEM_ACTION_TYPE.SAVE && this._formGroup.valid )) {
      this.buttonClicked.emit({
        actionType: actionType,
        itemId: itemId,
        value: this._formGroup ? this._formGroup.value : null
      });
    }
  }

  /**
   * @description Get value of object to display in table
   * @author Kevin Palade
   * @private
   * @param {*} key
   * @returns
   * @memberof TableItemComponent
   */
  public getFormatedValue(key): string | number {
    const value: any = this.isAddRow ? this.defaultValues[key] : this.contentItem[key],
          referenceValue: string[] = ["Matiere"];
    if (referenceValue.indexOf(key) !== -1) {
      const def: any = this.elementNatureDefinitions.find( el => el.name == key),
            option: any = def.options.find( opt => +opt.value === +value);
      return option ? option.label : "?";
    } else {
      if (isNaN(value)) {
        return value;
      } {
        return formatNumber(value, this._locale);
      }
    }
  }

  /**
   * @description Initialize the formgroup with validators
   * @author Kevin Palade
   * @private
   * @memberof TableItemComponent
   */
  private initFormGroup() {
    this._formGroup = this._fb.group({});
    let value: number | string ;
    this.elementNatureDefinitions.forEach(elementDef => {
      value = this.isAddRow ? this.defaultValues[elementDef.name] : this.contentItem[elementDef.name];
      const validators = [];
      if (!elementDef.nullable) { validators.push(Validators.required); }
      if (elementDef.regex ) { validators.push(Validators.pattern(elementDef.regex)); }
      if (elementDef.type == "INT") { validators.push(numberMatcher); }

      if (validators.length > 0) {
        this._formGroup.addControl(elementDef.name, new FormControl(value, Validators.compose(validators)));
      } else {
        this._formGroup.addControl(elementDef.name, new FormControl(value));
      }
    });
  }
}
