import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { OrganismsModule } from "app/presentationnal/organisms/organisms.module";
import { CatalogTableComponent } from "./catalog-table/catalog-table.component";
import { CatalogPagesComponent } from "./catalog-pages/catalog-pages.component";
import { CatalogComponent } from "app/container/biomes";
import { BreadcrumbService } from "app/presentationnal/organisms/breadcrumbs/breadcrumbs.service";
import { CatalogService } from "app/facade/queries/catalog/sync/catalog.service";
import { CatalogQueriesService } from "app/facade/queries/catalog/async/catalog_queries.service";


@NgModule({
  imports: [
    CommonModule,
    OrganismsModule,
  ],
  declarations: [
    CatalogComponent,
    CatalogTableComponent,
    CatalogPagesComponent,
  ],
  providers: [
    BreadcrumbService,
    CatalogService,
    CatalogQueriesService
  ]
})
export class CatalogModule { }
