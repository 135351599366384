import gql from "graphql-tag";

export const GET_SUPPLY_LISTS = gql`
  query getSupplyLists($filter: SupplyListFilter, $sort: SupplyListSort) {
    supplyLists(filter: $filter, sort: $sort) {
      id
      description
      model
      source
      deliveryDate
      status
      isAlreadyInBarset
      createdAt
      priceRequestId
      priceRequest {
        reference
      }
    }
  }
`;

export const GET_SUPPLY_LISTS_IN_PRICE_REQUEST = gql`
  query getSupplyListsInPriceRequest(
    $filter: SupplyListFilter
    $sort: SupplyListSort
  ) {
    supplyLists(filter: $filter, sort: $sort) {
      id
      description
      model
      deliveryDate
      createdAt
      isAlreadyInBarset
      project {
        id
        reference
        isEn1090
      }
      parentSupplyCategories {
        name
      }
      infos {
        matterRefs
        options
      }
    }
  }
`;

export const GET_SUPPLY_LIST = gql`
  query getSupplyList($id: ID!) {
    supplyList(id: $id) {
      elements {
        id
        reference
        denomination
        matterRef
        quantity
        weight
        format
        isBlack
        isBlasted
        isPrimaryBlasted
        quantityUnit
        basicQuantityUnit
        thickness
        length
        width
        remark
        supplyListId
        supplyCategoryId
        elementId
        matterId
      }
    }
  }
`;

export const CREATE_SUPPLY_LIST = gql`
  mutation createSupplyList($data: SupplyListInput!, $projectId: ID!) {
    createSupplyList(data: $data, projectId: $projectId) {
      id
      description
      model
      source
      deliveryDate
      status
      isAlreadyInBarset
      createdAt
    }
  }
`;

export const UPDATE_SUPPLY_LIST = gql`
  mutation updateSupplyList($id: ID!, $data: SupplyListUpdate!) {
    updateSupplyList(id: $id, data: $data) {
      id
      description
      model
      source
      deliveryDate
      status
      isAlreadyInBarset
      createdAt
    }
  }
`;

export const DELETE_SUPPLY_LIST = gql`
  mutation deleteSupplyList($id: ID!) {
    deleteSupplyList(id: $id)
  }
`;

export const DELETE_SUPPLY_LIST_ARRAY = gql`
  mutation deleteSupplyLists($ids: [ID!]!) {
    deleteSupplyLists(ids: $ids)
  }
`;

export const CREATE_PRICE_REQUEST_BY_SUPPLY_LIST = gql`
  mutation createPriceRequestBySupplyList($supplyListId: ID!) {
    createPriceRequestBySupplyList(supplyListId: $supplyListId) {
      id
    }
  }
`;
