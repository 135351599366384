import { QuoteElementBase, QuoteElementType } from "../base";
import { Subject } from "rxjs";
import { Matter } from "app/facade/interfaces";

export const BASE_MARGIN: number = 10;

export abstract class Element extends QuoteElementBase {

    public $updated: Subject<boolean>;

    public abstract get TotalArea(): number;
    public abstract get TotalWeight(): number;
    public abstract get Matter(): Matter;

    public constructor(name: string, protected reference?: string) {
        super(name);
        this.$updated = new Subject<boolean>();
    }

    public set Reference(reference: string) {
      this.reference = reference;
      this.elementUpdated();
    }

    public get Reference(): string {
      return this.reference;
    }

    protected elementUpdated(): void {
        this.$updated.next(true);
    }

    public get Type(): QuoteElementType {
        return "STANDARD";
    }
}