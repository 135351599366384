import { Component, OnInit, OnDestroy } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { SuppliersQueriesService } from "app/facade/queries/supplier/suppliers-queries.service";
import { ModalConfirmComponent, ModalAddSupplierComponent, ModalAddSupplierContactComponent } from "app/presentationnal/organisms/modal";
import { MODAL_TYPE } from "app/presentationnal/organisms/modal/enums/modal-type.enum";
import { ModalService } from "app/presentationnal/organisms/modal/services/modal.service";
import { SnackService } from "app/presentationnal/organisms/snack-bar/services/snack.service";
import { Subscription } from "rxjs";
import { IInformationsCardConfig } from "app/facade/interfaces/informations-card-data.interface";
import { IContainerCardsConfig } from "app/facade/interfaces/container-cards-config.interface";
import { CARD_ACTION_TYPE } from "app/facade/enums/card-action-type.enum";
import { CategoriesQueriesService } from "app/facade/queries/category/categories-queries.service";
import { FormGroup, FormArray, FormBuilder } from "@angular/forms";
import { ISupplierContact, ISupplierCategories, ISupplierCategoriesInput, ISupplierMatterInput } from "app/facade/interfaces/supplier";
import { IModalData } from "app/presentationnal/organisms/modal/interfaces/modal-data.interface";
import { MODAL_CLOSE_ACTION } from "app/presentationnal/organisms/modal/enums/modal-close-action.enum";
import { ISupplierMatter } from "app/facade/interfaces/supplier";

@Component({
  selector: "app-supplier-details",
  templateUrl: "./supplier-details.component.html",
  styleUrls: ["./supplier-details.component.css"]
})
export class SupplierDetailsComponent implements OnInit, OnDestroy {
  private _updateSupplierCategoriesSub: Subscription;
  private _getSupplierContactSub: Subscription;
  private _deleteSupplierSub: Subscription;
  private _deleteContactSub: Subscription;
  private _supplierId: string | number;
  private _categoriesData: ISupplierCategories[] = [];
  private _mattersData: ISupplierMatter[] = [];
  public supplierData: any = {};
  public categoriesChildrenName: string = "subCategories";
  public categoryFormArray: FormArray;
  public matterFormArray: FormArray;
  public hasSelectedCategories: boolean = false;
  public hasSelectedMatters: boolean = false;
  public categorySelectionActif: boolean = false;
  public matterSelectionActive: boolean = false;
  public contactsData: ISupplierContact[] = [];
  public informationsCardConfig: IInformationsCardConfig;
  public containerCardsConfig: IContainerCardsConfig = null;
  public otherContactsToDelete: Array<ISupplierContact> = [];

  constructor(
    private route: ActivatedRoute,
    private _suppliersQueriesSrv: SuppliersQueriesService,
    private _categoriesQueriesSrv: CategoriesQueriesService,
    private router: Router,
    private _modalService: ModalService,
    private snackBar: SnackService,
    protected _fb: FormBuilder) {
      this._supplierId = +this.route.snapshot.paramMap.get("id");
      this.getSupplierData();
      this.getSupplierContactsData();
      this.getSupplierCategories();
      this.getMatters();
  }

  ngOnInit() {}

  ngOnDestroy(): void {
    this._getSupplierContactSub.unsubscribe();
    if (this._updateSupplierCategoriesSub) { this._updateSupplierCategoriesSub.unsubscribe(); }
  }

  /* BEGIN CATEGORY METHODS */

  private getSupplierCategories() {
    this._categoriesQueriesSrv.getSupplierCategories(this._supplierId).subscribe( result => {
      const data: any = result.data;
      if (data && data.supplyCategoriesBySupplier) {
        this._categoriesData = data.supplyCategoriesBySupplier;
        this.hasSelectedCategories = this._categoriesData.some( catData => catData.selected );
        this.categoryFormArray = this.generateCategoryFormArray(this._categoriesData);
      }
    }, error => {
      console.log("ERROR LOADING SUPPLIER CATEGORIES > ", {error});
    });
  }

  private generateCategoryFormArray(categories: any): FormArray {
    const formArray = new FormArray([]);
    categories.forEach( category => {
      formArray.push( this.generateCategoryFormGroup(category) );
    });
    return formArray;
  }

  private generateCategoryFormGroup( category: any ): FormGroup {
    const newFormGroup: FormGroup = this._fb.group({
      id: category.id,
      name: category.name,
      selected: category.selected
    });
    if (category[this.categoriesChildrenName]) {
      newFormGroup.setControl(this.categoriesChildrenName, this.generateCategoryFormArray(category[this.categoriesChildrenName]));
    }
    return newFormGroup;
  }

  public updateCategoryDisable(reset: boolean = true) {
    if (reset && this.categorySelectionActif && this.categoryFormArray.touched) {
      this.categoryFormArray = this.generateCategoryFormArray(this._categoriesData);
    }
    this.categorySelectionActif = !this.categorySelectionActif;
  }

  public updateCategoryData() {
    this.updateCategoryDisable(false);
    if (this.categoryFormArray.touched) {
      this.categoryFormArray.markAsUntouched();
      const supplierCategoriesInput: ISupplierCategoriesInput[] = this.categoryFormArray.value.map( data => {
        const newData: ISupplierCategoriesInput = {
          id: +data.id,
          selected: data.selected,
        };
        newData[this.categoriesChildrenName] = data[this.categoriesChildrenName].map( child => {
          const childNewData: ISupplierCategoriesInput = {
            id: +child.id,
            selected: child.selected,
          };
          return childNewData;
        });
        return newData;
      });
      const snackBarTitle: string = "Modification des catégories";
      this._categoriesQueriesSrv.updateCategories(this._supplierId, supplierCategoriesInput).subscribe( result => {
        const resultData: any = result.data;
        if (resultData && resultData.setSupplyCategoriesOfSupplier) {
          this._categoriesData = this.categoryFormArray.value;
          this.hasSelectedCategories = this._categoriesData.some( cat => cat.selected);
          this.snackBar.open(snackBarTitle, "Catégories mises à jour", "success", 5000);
        } else {
          this.categoryFormArray = this.generateCategoryFormArray(this._categoriesData);
          this.snackBar.openSnackBarError(snackBarTitle, "Catégories non mises à jour");
        }
      }, error => {
        this.categoryFormArray = this.generateCategoryFormArray(this._categoriesData);
        this.snackBar.openSnackBarError(snackBarTitle, "Catégories non mises à jour", error);
      });
    }
  }
  /* END CATEGORY METHODS */

  /* BEGIN MATTER METHODS */

  private getMatters() {
    this._suppliersQueriesSrv.getMattersBySupplier(+this._supplierId).subscribe(result => {
      const data: any = result.data;
      if (data && data.mattersBySupplier) {
        this._mattersData = data.mattersBySupplier;
        this.hasSelectedMatters = this._mattersData.some(matData => matData.selected );
        this.matterFormArray = this.generateMatterFormArray(this._mattersData);
      }
    }, error => {
      console.log("ERROR LOADING SUPPLIER MATTERS > ", {error});
    });
  }

  private generateMatterFormArray(matters: ISupplierMatter[]): FormArray {
    const formArray = new FormArray([]);
    matters.forEach(matter => {
      formArray.push(this.generateMatterFormGroup(matter) );
    });
    return formArray;
  }

  private generateMatterFormGroup(matter: ISupplierMatter): FormGroup {
    const newFormGroup: FormGroup = this._fb.group({
      id: matter.id,
      name: matter.name,
      selected: matter.selected
    });
    return newFormGroup;
  }

  public updateMatterDisable(reset: boolean = true) {
    if (reset && this.matterSelectionActive && this.matterFormArray.touched) {
      this.matterFormArray = this.generateMatterFormArray(this._mattersData);
    }
    this.matterSelectionActive = !this.matterSelectionActive;
  }

  public updateMatterData() {
    this.updateMatterDisable(false);
    if (this.matterFormArray.touched) {
      this.matterFormArray.markAsUntouched();
      const supplierMatterInputs: ISupplierMatterInput[] = this.matterFormArray.value.map(data => ({
        id: +data.id,
        selected: data.selected,
      }));
      const snackBarTitle: string = "Modification des matières";
      this._suppliersQueriesSrv.updateSupplierMatters(+this._supplierId, supplierMatterInputs).subscribe(result => {
        const resultData: any = result.data;
        if (resultData && resultData.setMattersOfSupplier) {
          this._mattersData = this.matterFormArray.value;
          this.hasSelectedMatters = this._mattersData.some(matter => matter.selected);
          this.snackBar.open(snackBarTitle, "Matières mises à jour", "success", 5000);
        } else {
          this.matterFormArray = this.generateMatterFormArray(this._mattersData);
          this.snackBar.openSnackBarError(snackBarTitle, "Matières non mises à jour");
        }
      }, error => {
        this.matterFormArray = this.generateMatterFormArray(this._mattersData);
        this.snackBar.openSnackBarError(snackBarTitle, "Matières non mises à jour", error);
      });
    }
  }

  /* END MATTER METHODS */


  /* BEGIN SUPPLIER METHODS */
  private getSupplierData() {
    this._suppliersQueriesSrv.getSupplierData(this._supplierId).subscribe( result => {
      const data: any = result.data;
      if (data && data.supplier) {
        this.supplierData = data.supplier;
      }
    }, error => {
      console.log("ERROR LOADING SUPPLIER DATAS > ", {error});
    });
    this.informationsCardConfig = {
      title: "Informations fournisseur",
      actions: [
        {label: "Editer", fct: this.editSupplierInformation}
      ],
      fieldsToDisplay: [
        {key: "code", label: "Code fournisseur"},
        {key: "name", label: "Nom du fournisseur"},
        {key: "phone", label: "Téléphone général"},
        {key: "mail", label: "Email général"},
        {key: "remark", label: "Remarque"},
      ]
    };
  }

  private editSupplierInformation = () => {
    const modalRef = this._modalService.openModal(
      ModalAddSupplierComponent,
      {
        title: "Informations fournisseur",
        type: MODAL_TYPE.NORMAL,
        data: this.supplierData,
        closeAction: MODAL_CLOSE_ACTION.UPDATE
      }
    );
    if (modalRef) {
      modalRef.afterClosed.subscribe(res => {
        if (res.confirm && res.data) {
          this.supplierData = res.data;
        }
      });
    }
  }
  /* END SUPPLIER METHODS */

  /* BEGIN CONTACTS METHODS */
  private getSupplierContactsData() {
    this._getSupplierContactSub = this._suppliersQueriesSrv.watchSupplierContact(this._supplierId).valueChanges.subscribe( result => {
      const data: any = result.data;
      if (data && data.supplierContacts) {
        this.contactsData = data.supplierContacts;
      }
    }, error => {
      console.log("ERROR LOADING SUPPLIER CONTACTS > ", {error});
    });
    this.containerCardsConfig = {
      title: "Personnes de contact",
      actions: [
        {label: "Ajouter contact", fct: this.newContact}
      ],
      itemConfig: {
        fieldsToDisplay: [
          {key: "lastname", label: "Nom"},
          {key: "firstname", label: "Prénom"},
          {key: "phone", label: "Téléphone"},
          {key: "mail", label: "Email"},
          {key: "language", label: "Langue"},
          {key: "function", label: "Fonction"},
        ],
        actions: [
          { label: "Supprimer", fct: this.beforeDeleteContact, type: CARD_ACTION_TYPE.DELETE },
          // { label: null, fct: this.temp, type: CARD_ACTION_TYPE.SETTINGS},
          { label: "Editer", fct: this.setContact, type: CARD_ACTION_TYPE.PRIMARY },
        ],
        favorite: {
          needFavorite: true,
          fieldToCheck: "isFavorite",
          favoriteCallback: this.updateFavorite,
        }
      },
      emptyContent: {
        text: "Pas de contact",
        button: {label: "Ajouter une personne de contact", fct: this.newContact}
      }
    };
  }

  private newContact = () => {
    this.setContact();
  }

  private setContact = ( data: ISupplierContact = null) => {
    const modalRef = this._modalService.openModal(
      ModalAddSupplierContactComponent,
      {
        title: "Personne de contact",
        type: MODAL_TYPE.NORMAL,
        data : {
          ...data,
          supplierId: this._supplierId
        },
        modalStyle: {
          modalWidth: "small"
        }
      }
    );
    if (modalRef) {
      modalRef.afterClosed.subscribe(res => {
        if (res.confirm && res.data) {
          // Nothing more to do ?
        }
      });
    }
  }

  private updateFavorite = (data: any, printSnackBar: boolean = true) => {
    const snackBarTitle: string = "Contact Favoris";
    if (data && !data.isFavorite) {
      this._suppliersQueriesSrv.updateFavoriteSupplierContact(+data.id, this._supplierId).subscribe( result => {
        const resultData: any = result.data;
        if (resultData && resultData.setSupplierContactFavorite) {
          this.contactsData = this.contactsData.map( contact => {
            return {...contact, isFavorite: +contact.id === +data.id };
          });
          if (printSnackBar) {
            this.snackBar.open(snackBarTitle, "Favoris mis à jour", "success", 5000);
          }
        } else {
          this.snackBar.openSnackBarError(snackBarTitle, "Favoris non mis à jour");
        }
      }, error => {
        this.snackBar.openSnackBarError(snackBarTitle, "Favoris non mis à jour", error);
      });
    }
  }

  private beforeDeleteContact = (data: Array<ISupplierContact>) => {
    if (data.length < 1) return;
    const current = data.shift();
    let firstname = current.firstname && current.firstname.length > 0 ? current.firstname : '';
    this.otherContactsToDelete = data;
    const modalData: IModalData = {
      title: "Êtes-vous sûr ?",
      type: MODAL_TYPE.CONFIRM,
      data: {
        message: `Voulez-vous supprimer le contact <b>${firstname} ${current.lastname}</b> ?`,
        actions: [ "Supprimer le contact de manière définitive" ]
      },
      params: { contactId: +current.id, wasFavorite: current.isFavorite },
      confirmCallback: this.deleteContact,
      cancelCallback: () => { window.location.reload() }
    };
    if (current.isFavorite) {
      if (this.contactsData.length > 1) {
        modalData.data.alert = {
          type: "warning",
          title: "Attention",
          textContent: "Ce contact est l'actuel contact favori. Un nouveau contact favori sera sélectionné automatiquement."
        };
      } else {
        modalData.data.alert = {
          type: "info",
          title: "Information",
          textContent: "Si aucun contact n'est défini, l'application utilisera les coordonnées générales du fournisseur."
        };

      }
    }

    this._modalService.openModal(ModalConfirmComponent, modalData);
  }

  private deleteContact = (params: any) => {
    const snackBarTitle: string = "Supprimer un contact";
    if (this._deleteContactSub) { this._deleteContactSub.unsubscribe(); }
    this._deleteContactSub = this._suppliersQueriesSrv.deleteSupplierContact(params.contactId, this._supplierId).subscribe(result => {
      const data: any = result.data;
      if (data && data.deleteSupplierContact) {
        this.contactsData = this.contactsData.filter( contact => {
          return +contact.id !== params.contactId;
        });
        if (params.wasFavorite && this.contactsData.length > 0) {
          this.updateFavorite(this.contactsData[0], false);
        }
        this.snackBar.open(snackBarTitle, "Le contact a été supprimé", "success", 5000);
      } else {
        this.snackBar.openSnackBarError(snackBarTitle, "Le contact n'a pas été supprimé");
      }
      if (this.otherContactsToDelete.length > 0) this.beforeDeleteContact(this.otherContactsToDelete)
    }, error => {
      this.snackBar.openSnackBarError(snackBarTitle, "Le contact n'a pas été supprimé", error);
      if (this.otherContactsToDelete.length > 0) this.beforeDeleteContact(this.otherContactsToDelete)
    });
  }
  /* END CONTACTS METHODS */

  /* BEGIN HEADER METHODS */
  public returnAction() {
    this.router.navigate(["/supplier/"]);
  }

  public beforeDeleteSupplier() {
    this._modalService.openModal(ModalConfirmComponent,
      {
        title: "Êtes-vous sûr?",
        type: MODAL_TYPE.CONFIRM,
        data: {
          message: `Voulez-vous supprimer le fournisseur <b>${this.supplierData.name}</b>?`,
          actions: [
            "Supprimer le fournisseur de manière définitive",
            "Supprimer les contacts de ce fournisseur"
          ],
        },
        params: { supplierId: this._supplierId },
        confirmCallback: this.deleteSupplier
      }
    );
  }

  public deleteSupplier = (params: any) => {
    const snackBarTitle: string = "Supprimer un fournisseur";
    if (this._deleteSupplierSub) { this._deleteSupplierSub.unsubscribe(); }
    this._deleteSupplierSub = this._suppliersQueriesSrv.deleteSupplier(params.supplierId).subscribe(result => {
      if (result.data["deleteSupplier"]) {
        this.snackBar.open(snackBarTitle, "Le fournisseur a été supprimé", "success", 5000);
      } else {
        this.snackBar.openSnackBarError(snackBarTitle, "Le fournisseur n'a pas été supprimé");
      }
      this.router.navigate(["/supplier/"]);
    }, error => {
      this.snackBar.openSnackBarError(snackBarTitle, "Le fournisseur n'a pas été supprimé", error);
    });
  }
   /* END HEADER METHODS */

}
