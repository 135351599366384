import { Component, OnInit, Input, Renderer2, ViewChild, ElementRef } from "@angular/core";

@Component({
  selector: "app-label",
  templateUrl: "./label.component.html",
  styleUrls: ["./label.component.css"]
})
export class LabelComponent implements OnInit {

  @Input() public truncate: boolean = false;
  @Input("for") public for: string = "";
  @Input("big") public big: boolean = false;
  @Input("marginRight") public marginRight: boolean = false;
  @Input() icon: string;
  @Input() tooltip: string;
  public tooltipItems: string[] = [];
  public dropDownOpen: boolean = false;
  private handler: any;
  @ViewChild("dropDownList") dropDownList: ElementRef;

  constructor(private renderer: Renderer2) { }

  ngOnInit() { }

  ngOnChanges() {
    if (this.tooltip) {
      this.tooltipItems = this.tooltip.split('|').map(item => item.trim());
    }
  }

  public openPopover() {
    if (this.dropDownOpen) {
      this.handler();
    } else {
      this.handler = this.renderer.listen(document, "click", event => this.closePopover(event));
    }
    this.dropDownOpen = !this.dropDownOpen;
  }

  private closePopover(event) {
    const clickedInside = this.dropDownList.nativeElement.contains(event.target);
    if ( !clickedInside ) {
      this.handler();
      this.dropDownOpen = false;
    }
  }

}
