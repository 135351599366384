import { Injectable } from "@angular/core";
import { Apollo } from "apollo-angular";
import { GET_PROJECTS, CREATE_PROJECT, DELETE_PROJECT, GET_PROJECT, UPDATE_PROJECT, GET_NEW_PROJECTS, PROJECTS_BY_REFERENCE } from "./projects.queries";
import { IProjectInput, ISupplyListInput, ISupplyListFilter, ISupplyListSort } from "app/facade/interfaces/project";
import { GET_SUPPLY_LISTS, DELETE_SUPPLY_LIST, CREATE_SUPPLY_LIST, GET_SUPPLY_LIST, UPDATE_SUPPLY_LIST, CREATE_PRICE_REQUEST_BY_SUPPLY_LIST, DELETE_SUPPLY_LIST_ARRAY } from "./projects-supply-lists.queries";
import { SupplyListSortBy } from "app/facade/enums/supply-list-sort.enum";
import { OrderByDirection } from "app/facade/enums/order-by-direction.enum";
import { IPagination } from "app/facade/interfaces/crud.interface";

@Injectable({
  providedIn: "root"
})
export class ProjectsQueriesService {
  private _sortSupplyList: ISupplyListSort = {
    sortBy: SupplyListSortBy.CREATED_AT,
    sortDirection: OrderByDirection.DESC
  };

  constructor(private _apollo: Apollo) { }

  public getProjectList(search: string = "") {
    return this._apollo.query({
      query: GET_PROJECTS,
      variables: {search},
      fetchPolicy: "network-only"
    });
  }

  public watchProjectList(search: string) {
    return this._apollo.watchQuery({
      query: GET_PROJECTS,
      variables: {search},
      fetchPolicy: "network-only"
    });
  }

  public getProjectListing(search: string, pagination?: IPagination) {
    return this._apollo.query({
      query: GET_NEW_PROJECTS,
      variables: {search, pagination},
      fetchPolicy: "no-cache"
    });
  }

  public projectByreference(reference: string) {
    return this._apollo.query({
      query: PROJECTS_BY_REFERENCE,
      variables: {reference},
      fetchPolicy: "no-cache"
    });
  }

  public getProject(id: number | string) {
    return this._apollo.query({
      query: GET_PROJECT,
      variables: {id},
      fetchPolicy: "network-only"
    });
  }

  public addProject(projectData: IProjectInput) {
    return this._apollo.mutate({
      mutation: CREATE_PROJECT,
      variables: {data: projectData}
    });
  }

  public updateProject(id: number | string, data: IProjectInput) {
    return this._apollo.mutate({
      mutation: UPDATE_PROJECT,
      variables: { id, data }
    });
  }

  public deleteProject(id: number | string) {
    return this._apollo.mutate({
      mutation: DELETE_PROJECT,
      variables: { id }
    });
  }

  /* SUPPLY LISTS */
  public getSupplyLists(filter: ISupplyListFilter, sort: ISupplyListSort = this._sortSupplyList) {
    return this._apollo.query({
      query: GET_SUPPLY_LISTS,
      variables: { filter, sort },
      fetchPolicy: "network-only"
    });
  }

  public getSupplyList(id: number | string) {
    return this._apollo.query({
      query: GET_SUPPLY_LIST,
      variables: { id },
      fetchPolicy: "network-only"
    });
  }

  public createSupplyList(data: ISupplyListInput, projectId: number) {
    return this._apollo.mutate({
      mutation: CREATE_SUPPLY_LIST,
      variables: {data, projectId}
    });
  }

  public updateSupplyList(id: number, data: ISupplyListInput) {
    return this._apollo.mutate({
      mutation: UPDATE_SUPPLY_LIST,
      variables: {id, data},
    });
  }

  public deleteSupplyList(supplyListId: number | string) {
    return this._apollo.mutate({
      mutation: DELETE_SUPPLY_LIST,
      variables: { id: supplyListId }
    });
  }

  public deleteSupplyLists(supplyListIds: number[] | string) {
    return this._apollo.mutate({
      mutation: DELETE_SUPPLY_LIST_ARRAY,
      variables: { ids: supplyListIds }
    });
  }

  public createPriceRequestBySupplyList( supplyListId: number) {
    return this._apollo.mutate({
      mutation: CREATE_PRICE_REQUEST_BY_SUPPLY_LIST,
      variables: {supplyListId}
    });
  }
}
