import { Component, OnInit, Input, EventEmitter, Output } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { Subject } from "rxjs";
import { debounceTime } from "rxjs/operators";

@Component({
  selector: "app-filter-input",
  templateUrl: "./filter-input.component.html",
  styleUrls: ["./filter-input.component.css"]
})
export class FilterInputComponent implements OnInit {
  private debouncer: Subject<any> = new Subject();

  @Output("filterSearch") public filterSearch: EventEmitter<string>;
  @Input() disable: boolean;
  @Input() label: string;
  @Input() holderType: string = "";
  @Input() public valueSelect: string = "";
  constructor() {
    this.filterSearch = new EventEmitter<string>();
    this.debouncer.pipe(debounceTime(500)).subscribe((value) => this.filterSearch.emit(this.valueSelect));
   }

  ngOnInit() {
  }

  /**
   * findInoutFilter
   */
  public findInputFilter(event) {
    this.debouncer.next(this.valueSelect);
  }

  public reset() {
    this.valueSelect = "";
  }

}
