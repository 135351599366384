import { NgModule } from "@angular/core";
import { ClickOutsideDirective } from "./click-outside.directive";
import { TooltipDirective } from "./tooltip.directive";
import { LoadingDirective } from "./loading.directive";

@NgModule({
    declarations: [
        ClickOutsideDirective,
        TooltipDirective,
        LoadingDirective
    ],

    exports: [
        ClickOutsideDirective,
        TooltipDirective,
        LoadingDirective
    ]
})
export class SharedDirectivesModule { }