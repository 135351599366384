export enum UniqueSupplierOfferAdditionnalCostType {
  TRANSPORT_FEE = "Frais de transport",
  PACKAGING_FEE = "Frais d'emballage",
  CERTIFICATE_FEE = "Frais de certificats",
}

// Type who can be use more than one time
export enum MultiSupplierOfferAdditionnalCostType {
  OTHER = "Divers"
}

// Type who can be use more than one time
export enum EnumAdditionnalCostUnit {
  EURO = "€",
  EURO_BY_UNIT = "€ / Qté"
}