import { Component, OnInit, Inject } from "@angular/core";
import { CONTAINER_DATA, StepFormService } from "app/presentationnal/organisms/wizard-modal/service/step-form.service";
import { CatalogQueriesService } from "app/facade/queries/catalog/async/catalog_queries.service";
import { CustomElementService } from "app/facade/quote-element-definition/elements/customs/custom.service";
import { QuotesQueriesService } from "app/facade/queries/quote/async/quotes_queries.service";
import { FormBuilder, Validators } from "@angular/forms";
import { StepZeroBase } from "../../base/step-zero-base";

@Component({
  selector: "app-equerre-step-zero",
  templateUrl: "./equerre-step-zero.component.html",
  styleUrls: ["./equerre-step-zero.component.css"]
})
export class EquerreStepZeroComponent extends StepZeroBase implements OnInit {

  constructor(
    @Inject(CONTAINER_DATA) data: any,
              protected _stepFormSrv: StepFormService,
              protected _catalogQueriesSrv: CatalogQueriesService,
              protected _customElementSrv: CustomElementService,
              protected _quoteQueriesSrv: QuotesQueriesService,
              protected _fb: FormBuilder
              ) {
    super(data, _stepFormSrv, _catalogQueriesSrv, _customElementSrv, _quoteQueriesSrv, _fb);
  }

  protected generateFormContent() {
    super.generateFormContent();
    const data: any = this.actualData.custom ? this.actualData.custom : null,
          properties: any = data ? data.properties : null;
    this.formContent.stickTop = properties ? properties.stickTop : false;
    this.formContent.foldingPrice = properties ? properties.foldingPrice : 1.5;
    this.formContent.base = [properties ? properties.base : null, Validators.required];
  }

  protected formateFormValues() {
    const buildCustomData: { element: any, laserCutting: any, welding: any } = super.formateFormValues();
    buildCustomData.element.foldingPrice = this.stepZeroForm.value.foldingPrice;
    buildCustomData.element.stickTop = this.stepZeroForm.value.stickTop;
    buildCustomData.element.base = this.stepZeroForm.value.base;
    return buildCustomData;
  }

  protected formatWithEditedCustomData() {
    super.formatWithEditedCustomData();
    const datasToCheck: string[] = ["foldingPrice", "stickTop", "base"],
    referenceData: any = {
      "foldingPrice" : "foldingPrice",
      "stickTop" : "stickTop",
      "base" : "base"
    };
    datasToCheck.forEach( data => {
      if (this.stepZeroForm.get(data).touched) {
        this.customData[referenceData[data]] = this.stepZeroForm.get(data).value;
      }
    });
  }

  protected formatWithDeletedCustomData(values: any) {
    super.formatWithDeletedCustomData(values);
    if (this.actualData.custom) {
      this.customData.properties = { ...this.actualData.custom.properties, ...this.customData.properties };
    }
  }

}
