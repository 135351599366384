import { Component, Input, Renderer2 } from "@angular/core";
import { AInputs } from "../inputs";

@Component({
  selector: "app-text-input",
  templateUrl: "./text-input.component.html",
  styleUrls: ["./text-input.component.css"],
})
export class TextInputComponent extends AInputs {
  @Input() isQuantityMin: boolean;
  @Input() placeholder: string;
  public style:{};

  constructor(public renderer: Renderer2) {
    super(renderer);
  }
  ngOnInit(): void {
    if (this.placeholder === undefined) {
      this.placeholder = '';
    }
    
    if (this.isQuantityMin) {
      this.style =  {
        "border-color": "red",
        "border-width":"2px"
      }
    } else {

    }
  }

}
