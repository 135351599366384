import { IWeldiableMeterable } from "../../assemblages/welding";
import { MM_TO_M, SQUARE_MM_TO_SQUARE_M } from "../../base";
import { ILaserCuttable } from "../../operations/laser-cutting";
import { AdditionalComputingCreate } from "./custom.service";
import { Platine, PlatineProperty } from "./platine";

// export interface PlaqueProperty extends PlatineProperty {
// }

export class Plaque extends Platine<PlatineProperty> implements ILaserCuttable, IWeldiableMeterable {

    public constructor(
        name: string,
        createAdditionalComputing: AdditionalComputingCreate,
        properties: PlatineProperty
    ) {
        super(name, createAdditionalComputing, properties);
    }

    public get meterForWelding(): number {
        return ((this.properties.width + this.properties.thickness) * 4) * MM_TO_M;
    }

    public get TotalArea(): number {
        return (this.Area * 2
            + this.properties.width * this.properties.thickness * 2
            + this.properties.height * this.properties.thickness * 2) * SQUARE_MM_TO_SQUARE_M;
    }
}