import { Injectable } from "@angular/core";

import { CUSTOM_MAP } from "../../use-class-map";
import { EmbaseProperty, Embase } from "./embase";
import { CustomElement, AdditionalComputing } from "./custom.base";
import { OperationService } from "../../operations/operation.service";
import { AssemblageService } from "../../assemblages/assemblage.service";
import { ILaserCuttable, LaserCuttingProperty } from "../../operations/laser-cutting";
import { flatMap, map } from "rxjs/operators";
import { IWeldiableMeterable, IWeldiable } from "../../assemblages/welding";
import { NervureProperty } from "./nervure";
import { EquerreProperty } from "./equerre";
import { PlatineProperty, Platine } from "./platine";
import { Observable } from "rxjs";

// List of all available custom in the application
type CustomAvailable =
    "Embase" |
    "Gousset" |
    "Nervure" |
    "Plaque" |
    "Equerre" |
    "Raidisseur";

export interface AdditionalInformation {
    laserCutting: LaserCuttingProperty;
    welding: any; // TODO
}

// Minimal property to build asked custom
type CustomBuild<T extends CustomAvailable> =
    T extends "Embase" ? EmbaseProperty :
    T extends "Gousset" ? PlatineProperty :
    T extends "Nervure" ? NervureProperty :
    T extends "Plaque" ? PlatineProperty :
    T extends "Equerre" ? EquerreProperty :
    T extends "Raidisseur" ? PlatineProperty : never;

export interface AdditionalComputingCreate { [name: string]: (el: Platine<any>) => Observable<AdditionalComputing>; }

@Injectable()
export class CustomElementService {

    public constructor(
        private readonly _operationSrv: OperationService,
        private readonly _assemblageSrv: AssemblageService
    ) {}

    public create<T extends CustomAvailable>(custom: CustomAvailable, name: string, build: CustomBuild<T>, additional: AdditionalInformation) {
        const ctor: new (name: string, createAdditionalComputing: AdditionalComputingCreate, property: any) => CustomElement = CUSTOM_MAP[custom];
        const createAdditionalComputing: AdditionalComputingCreate = {
            cut: (el: Platine<any>) => {
                return this._operationSrv.create("LaserCutting", { element: el, properties: additional.laserCutting }).pipe(
                    map(cutting => ({ name: "cut", origin: cutting }))
                );
            },
            welding: (el: Platine<any>) => {
                let toWeld: IWeldiableMeterable;
                let weldOn: IWeldiable;
                if (!el.hasWeldingLength) {
                    toWeld = <IWeldiableMeterable>el.elementForWelding;
                    weldOn = <IWeldiable>el;
                } else {
                    toWeld = <any>el;
                    weldOn = <IWeldiable>el.elementForWelding;
                }

                return this._assemblageSrv.create("Welding", toWeld, weldOn, additional.welding).pipe(
                    map(welding => ({ name: "welding", origin: welding }))
                );
            }
        };

        const customElement: CustomElement = new ctor(name, createAdditionalComputing, build);
        customElement.ParentQuantity = build.elementForWelding.Quantity;
        return customElement.$ready.pipe(
            map(done => customElement)
        );
    }
}