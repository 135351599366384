import { NgModule } from "@angular/core";
import { HttpClientModule, HTTP_INTERCEPTORS, HttpHeaders } from "@angular/common/http";
import { ApolloModule, Apollo } from "apollo-angular";
import { HttpLinkModule } from "apollo-angular-link-http";
import { InMemoryCache } from "apollo-cache-inmemory";
import { ApolloLink, concat, from } from "apollo-link";
import { createUploadLink } from "apollo-upload-client";
import { onError } from "apollo-link-error";

import { AuthService, CSRF_HEADER_KEY, TOKEN_STORAGE_KEY, CSRF_STORAGE_KEY, AUTH_HEADER_KEY } from "@lib/auth/auth.service";
import { AuthInterceptor } from "@lib/auth/auth.interceptor";
import { ApplicationConfig } from "app/app.config";

@NgModule({
    providers: [
        AuthService,
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
    ],
    exports: [
        HttpClientModule,
        ApolloModule,
        HttpLinkModule
    ]
})
export class GraphQLModule {

    public constructor(
        apollo: Apollo,
        private _auth: AuthService
    ) {
        // Retrieve graphQL endpoint
        const webServiceUrl: string = ApplicationConfig.EndPointURI;

        // Initialize apollo angular client
        const http = createUploadLink({
            uri: webServiceUrl,
            credentials: "include",
            cache: new InMemoryCache({
                addTypename: false,
                // dataIdFromObject: obj => obj.key || null
            })
        });

        const authMiddleware = new ApolloLink((operation, forward) => {
            // add the authorization to the headers
            const token = localStorage.getItem(TOKEN_STORAGE_KEY);
            const newHeaders = {
                [CSRF_HEADER_KEY]: localStorage.getItem(CSRF_STORAGE_KEY) || ""
            };
            if (!!token) { newHeaders[AUTH_HEADER_KEY] = `Bearer ${token}`; }

            operation.setContext((ctx) => {
                return { headers: newHeaders };
            });

            return forward(operation);
        });

        const addTokenLink = new ApolloLink((operation, forward) => {
            // Set token after server response
            return forward(operation).map((response) => {
                const { response: { headers } } = operation.getContext();
                if (headers) {
                    const token = headers.get(AUTH_HEADER_KEY);
                    if (token) { localStorage.setItem(TOKEN_STORAGE_KEY, token.split(" ")[1]); }
                }
                return response;
            });
        });

        const logoutLink = onError(({ graphQLErrors, networkError }) => {
          let logout: boolean = false;
          const netErr: any = networkError;
          const gqlErr: any = graphQLErrors;
          if (netErr && netErr.statusCode === 401) {
            logout = true;
            this._auth.logout();
          }

          if (gqlErr && !logout) {
            if (gqlErr.some( error => error.statusCode === 401)) { this._auth.logout(); }
          }
        });

        apollo.create({
            link: from([authMiddleware, addTokenLink, logoutLink, http]),
            cache: new InMemoryCache()
        });
    }
}
