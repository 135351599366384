import { Injectable } from "@angular/core";
import { TOKEN_STORAGE_KEY, CSRF_HEADER_KEY, CSRF_STORAGE_KEY, AUTH_HEADER_KEY } from "@lib/auth/auth.service";
import { ApplicationConfig } from "app/app.config";
import { HttpClient } from "@angular/common/http";

@Injectable({
  providedIn: "root"
})
export class UploadFileService {

  private _headers: any = {};
  private _applicationUrl = ApplicationConfig.Url;

  constructor(private _httpClient: HttpClient) {}

  private getHeaders() {
    const token = localStorage.getItem(TOKEN_STORAGE_KEY);
    this._headers[CSRF_HEADER_KEY] = localStorage.getItem(CSRF_STORAGE_KEY) || "";
    if (!!token) { this._headers[AUTH_HEADER_KEY] = `Bearer ${token}`; }
    return this._headers;
  }

  public uploadFile(formData: FormData, url: string) {
    return this._httpClient.post(
      `${this._applicationUrl}${url}`,
      formData,
      { headers: this.getHeaders() }
    );
  }

  public downloadFile(url: string){
    return this._httpClient.get(
      `${this._applicationUrl}${url}`,
      { headers: this.getHeaders() }
    );
  }
}
