import { Component, OnInit, Input, EventEmitter, Output } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: "app-quote-cards",
  templateUrl: "./quote-cards.component.html",
  styleUrls: ["./quote-cards.component.css"]
})
export class QuoteCardsComponent implements OnInit {
  @Input() modeTable: boolean = true;
  @Input() quotes;
  @Output() public giveDeleteItem: EventEmitter<string> = new EventEmitter();
  constructor(public router: Router) { }

  ngOnInit() { }

  /**
   * giveDeleteInfo
   */
  public giveDeleteInfo(event) {
    const idItem: string = event;
    if (idItem) {
      this.giveDeleteItem.emit(idItem);
    }
  }

}
