import { Component, OnInit, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "app-table-mode",
  templateUrl: "./table-mode.component.html",
  styleUrls: ["./table-mode.component.css"]
})
export class TableModeComponent implements OnInit {
  public valueButton: boolean = true;
  @Output() changeMode = new EventEmitter<any>();

  constructor() { }

  ngOnInit() { }

  /**
   * @description Change the table display mode
   * @author Kevin Palade
   * @param {boolean} value
   * @memberof TableModeComponent
   */
  public changeModeTable(value: boolean) {
    this.valueButton = value;
    this.changeMode.emit(value);
  }
}
