import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '@lib/auth/auth.service';
import { USER_GROUPS } from '../../../../facade/enums/usergroups.enum';
import { IPermission } from '../../../../facade/interfaces/permission.interface';
import { TableDatasDefinition } from '../../../../facade/interfaces/table-datas-definition';
import { PermissionQueriesService } from '../../../../facade/queries/permission/permission.queries.service';
import { ListTableService } from '../../../../facade/services/list-table.service';
import { ISelectOption } from '../../../../presentationnal/atoms/inputs/select-input/selectOptions';
import { SnackService } from '../../../../presentationnal/organisms/snack-bar/services/snack.service';
import { group } from 'console';
import { Subscription } from 'rxjs';
import { PermissionService } from 'app/facade/services/permission.service';

@Component({
  selector: 'app-tabpermission-management',
  templateUrl: './tabpermission-management.component.html',
  styleUrls: ['./tabpermission-management.component.css']
})
export class TabpermissionManagementComponent implements OnInit, OnDestroy {

  private _permissionSub: Subscription;
  public userGroup: any;
  public userGroupOptions: ISelectOption[] = [];
  public selectedGroup: any = "";
  selectedStatus: any = "";
  public check: [];
  public defaultSelect: any;

  public dataDefinition: TableDatasDefinition[] = [
    { key: "category", isTitle: true },
    { key: "read", isTitle: false },
    { key: "write", isTitle: false },
    { key: "delete", isTitle: false }
  ];

  public dataTable: IPermission[] = [];

  private categoriesLabels: any;

  constructor(
    private readonly _permissionQueriesSrv: PermissionQueriesService,
    private _permissionService: PermissionService,
    private _authService: AuthService,
    private router: Router,
    private _snackBar: SnackService,
  ) { }

  ngOnDestroy(): void {
    if (this._permissionSub) { this._permissionSub.unsubscribe(); }
  }

  ngOnInit() {
    this.categoriesLabels = {
      'quotations': "Devis",
      'projects': "Projets",
      'price-requests': "Demandes de prix",
      'purchase-orders': "Bons de commande",
      'elements': "Liste articles",
      'suppliers': "Fournisseurs",
      'catalog': "Catalogue",
      'users': "Utilisateurs",
    };
    this.userGroup = this._authService.isUserGroup();
    this.userGroupOptions = [
      { value: USER_GROUPS.WORKSHOP, label: "Atelier" },
      { value: USER_GROUPS.DESIGN_OFFICE, label: "Bureau d'étude" },
      { value: USER_GROUPS.QUOTER, label: "Deviseur" },
      { value: USER_GROUPS.ADMINISTRATOR, label: "Administrateur" },
    ];

    this.selectedGroup = this.userGroup;

    this.loadPermission();
  }

  /**
   * @description Get data for listing display
   * @author Marie Claudia
   * @private
   * @returns {IPermission[]}
   * @memberof
   */
  private loadPermission() {
    if (this._permissionSub) { this._permissionSub.unsubscribe(); }
    this._permissionSub = this._permissionQueriesSrv.getPermissionyUserGroup(this.userGroup).subscribe(result => {
      const resultData: { getPermission: IPermission[] } = <any>result.data;
      if (resultData && resultData.getPermission) {
        this.dataTable = resultData.getPermission;
        this.dataTable = this.dataTable.filter(r => r.category != "see-prices");

      }
    }, error => {
      console.log("ERROR LOADING GET PERMISSION", { error });
    });
  }

  /**
   * @description Get data on change select input
   * @author Marie Claudia
   * @private
   * @returns {IPermission[]}
   * @memberof
   */
  private onChange() {
    if (this._permissionSub) { this._permissionSub.unsubscribe(); }
    this._permissionSub = this._permissionQueriesSrv.getPermissionyUserGroup(this.selectedGroup).subscribe(result => {
      const resultData: { getPermission: IPermission[] } = <any>result.data;
      if (resultData && resultData.getPermission) {
        this.dataTable = resultData.getPermission;
        this.dataTable = this.dataTable.filter(r => r.category != "see-prices");
      }
    }, error => {
      console.log("ERROR LOADING GET PERMISSION", { error });
    });
  }

  public changeCheckBox(id: number) {
    let permission = this.dataTable.filter(data => data.id == id);
    if (permission) {
      const permis = {
        userGroup: permission[0].userGroup,
        category: permission[0].category,
        read: permission[0].read,
        write: permission[0].write,
        delete: permission[0].delete,
        seePrices: permission[0].seePrices
      };
      this._permissionQueriesSrv.updatePermission(permis, id).subscribe(result => {
        this._permissionService.getAndUpdatePermission(this.userGroup, true);
        this._snackBar.open("Modifier permission", "La permission a été modifiée", "success", 5000);
      });
    } else {
      this._snackBar.open("Modifier permission", "Formulaire incomplet", "warning", 5000);
    }
  }

  public returnAction() {
    this.router.navigate(["/users/"]);
  }

  private shouldDisplayCheckbox(action: 'seePrice' | 'delete' | 'read' | 'write', category: string): boolean {
    const categories: {
      seePrice: string[];
      delete: string[];
      read: string[];
      write: string[];
    } = {
      seePrice: [
        "quotations",
        "price-requests",
        "purchase-orders",
        "elements",
      ],
      delete: [
        "quotations",
        "projects",
        "price-requests",
        "purchase-orders",
        "suppliers",
        "catalog",
        "users"
      ],
      read: [
        "quotations",
        "projects",
        "price-requests",
        "purchase-orders",
        "suppliers",
        "catalog",
        "users",
        "elements"
      ],
      write: [
        "quotations",
        "projects",
        "price-requests",
        "purchase-orders",
        "suppliers",
        "catalog",
        "users",
        "elements"
      ],
    }
    return categories[action].includes(category);
  }
}
