/*
 * @Author: Kevin Palade
 * @Date: 2018-09-08 14:34:30
 * @Last Modified by: Kevin Palade
 * @Last Modified time: 2018-09-08 14:35:04
 */
import { Directive, ElementRef, Input, OnChanges, Renderer2 } from "@angular/core";

@Directive({
  selector: "[appLoading]"
})
export class LoadingDirective implements OnChanges {

  @Input("appLoading") public appLoading: boolean = false;
  @Input() public lightLoading: boolean = false;
  private classList: Set<string> = new Set<string>(["loading"]);

  constructor(private renderer: Renderer2, private _el: ElementRef) { }

  /**
   * @description Apply the class 'loading' on the first element
   * @author Kevin Palade
   * @memberof LoadingDirective
   */
  ngOnChanges() {
    if (this.lightLoading) {
      this.classList.add("loading_light");
    } else {
      this.classList.delete("loading_light");
    }
    if ( this.appLoading ) {
      Array.from(this.classList).forEach( className => this.renderer.addClass(this._el.nativeElement, className) );
    } else {
      Array.from(this.classList).forEach( className => this.renderer.removeClass(this._el.nativeElement, className) );
    }
  }

}
