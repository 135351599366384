export enum SupplyListSortBy {
  ID = "ID",
  DESCRIPTION = "DESCRIPTION",
  MODEL = "MODEL",
  SOURCE = "SOURCE",
  DELIVERY_DATE = "DELIVERY_DATE",
  STATUS = "STATUS",
  CREATED_AT = "CREATED_AT"
}

