import { Component, OnInit, Inject, OnDestroy } from "@angular/core";
import { ModalContentForm } from "../../model/modal-content-form";
import { ModalService, DATA } from "../../services/modal.service";
import { SnackService } from "app/presentationnal/organisms/snack-bar/services/snack.service";
import { FormBuilder } from "@angular/forms";
import { Subscription } from "rxjs";
import { PriceRequestsQueriesService } from "app/facade/queries/price-request/price-requests-queries.service";
import { ISupplierOffer } from "app/facade/interfaces/supplier-offer";
import { ITableSelection } from "app/facade/interfaces/table-datas-definition";
import * as moment from "moment";

interface ISupplierSelection extends ITableSelection {
  tableDatas: {
    id: number;
    code: string;
    name: string;
    categories: string;
    isSent: boolean;
    sendingDate?: string;
  }[];
}

@Component({
  selector: "app-modal-select-supplier-offer",
  templateUrl: "./modal-select-supplier-offer.component.html",
  styleUrls: ["./modal-select-supplier-offer.component.css"]
})
export class ModalSelectSupplierOfferComponent extends ModalContentForm implements OnInit, OnDestroy {
  private _supplierOfferSub: Subscription;
  private _priceRequestId: number;
  private _supplierOfferDatas: ISupplierOffer[];
  private _isPdfGenerate: boolean;

  public supplierListDatas: ISupplierSelection;
  public listTableAdditionnalClass: string;

  constructor( protected _modalService: ModalService,
    protected _snackBar: SnackService,
    protected _fb: FormBuilder,
    @Inject(DATA) private _data: {data: {priceRequestId: number, isPdfGenerate: boolean}},
    private _priceRequestsQueriesSrv: PriceRequestsQueriesService) {
      super(_modalService, _fb, _snackBar);
      this._priceRequestId = this._data.data.priceRequestId;
      this._isPdfGenerate = this._data.data.isPdfGenerate;
      this.listTableAdditionnalClass = this._isPdfGenerate ? null : "_custom_send_pdf";
  }

  ngOnInit() {
    this.getSupplierOffers();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this._supplierOfferSub.unsubscribe();
  }

  private getSupplierOffers() {
    this._supplierOfferSub = this._priceRequestsQueriesSrv.getSupplierOffers({priceRequestId: this._priceRequestId}).subscribe( result => {
      const resultData: any = result.data;
      if ( resultData && resultData.supplierOffers) {
        this._supplierOfferDatas = resultData.supplierOffers;
        this.initSupplierListForm();
      }
    }, error => {
      console.log("ERROR LOADING SUPPLIER OFFERS > ", {error});
    });
  }

  private initSupplierListForm() {
    this.supplierListDatas = {
      tableHeaders: ["", "Code", "Name", "Catégories", "Envoyé", "Date d'envoi"],
      checkboxDatas: [],
      tableDatas: [],
      tableDatasDefinition: [
        { key: "code", isTitle: true},
        { key: "name", isTitle: true},
        { key: "categories", isTitle: false},
        { key: "isSent", isTitle: false, isCheckBox: true},
        { key: "sendingDate", isTitle: false},
      ]
    };
    if (this._isPdfGenerate) {
      this.supplierListDatas.tableHeaders.splice(4, 2);
      this.supplierListDatas.tableDatasDefinition.splice(3, 2);
    }
    this._supplierOfferDatas.forEach( supplierOffer => {
      this.supplierListDatas.tableDatas.push({
        id: supplierOffer.id,
        code: supplierOffer.supplier.code,
        name: supplierOffer.supplier.name,
        categories: supplierOffer.supplier.parentSupplyCategories.map(cat => cat.name).join(" - "),
        isSent: supplierOffer.isSent,
        sendingDate: supplierOffer.sendingDate ? moment.unix(+supplierOffer.sendingDate).format("DD/MM/YYYY HH:mm") : null
      });
      this.supplierListDatas.checkboxDatas.push({
        key: "selected",
        formValue: this._fb.group({
          supplierOfferId: supplierOffer.id,
          selected: false,
          disable: !this._isPdfGenerate && supplierOffer.isSent
        })
      });
    });
  }

  public confirmModal() {
    this.save();
    this._modalService.closeModal();
  }

  protected save(): void {
    const supplierOfferDatas: {id: number, supplierCode: string}[] = this.supplierListDatas.checkboxDatas
    .filter( data => data.formValue.value.selected).map( data => {
      return {
        id: data.formValue.value.supplierOfferId,
        supplierCode: this.supplierListDatas.tableDatas.find( supp => supp.id === data.formValue.value.supplierOfferId).code
      };
    });
    const objData = {
      confirm: true,
      data: supplierOfferDatas
    };
    this.afterClosed.next(objData);
  }

}
