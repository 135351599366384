import { OnInit, Inject } from "@angular/core";
import { CONTAINER_DATA, StepFormService, ActualData } from "app/presentationnal/organisms/wizard-modal/service/step-form.service";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { SQUARE_MM_TO_SQUARE_M } from "app/facade/quote-element-definition/base";

export abstract class StepSummaryBase implements OnInit {

  public summaryTitle = [];
  public summaryForm: FormGroup;
  public imgPath;
  public summary: any[] = [];
  public summaryAmount: any[] = [];
  protected actualData: ActualData;
  protected dataTicket: {element: any, children: any[]};


  constructor(@Inject(CONTAINER_DATA) data: any,
              protected _stepFormSrv: StepFormService,
              protected _fb: FormBuilder) {
    this.imgPath = "../../../../../../../../assets/img/wizard/" + data.useClass.toLowerCase() + "/step_four.jpg";
    this.actualData = this._stepFormSrv.get();
    this.dataTicket = data.ticket[this.actualData.index];
  }

  ngOnInit() {
    this.initForm();
    this.formatSummary();
  }

  private initForm() {
    this.summaryForm = this._fb.group({
      CustomName: [this.actualData.custom.CustomName, Validators.required],
      Quantity: [this.actualData.custom.Quantity, Validators.required],
    });
    this.summaryForm.get("Quantity").valueChanges.subscribe( val => {
      if (val !== null) {
        this.actualData.custom.Quantity = val;
        this.formatSummary();
      }
    });
  }

  public stepChange() {
    Object.keys(this.summaryForm.controls).map( key => {
      if (this.summaryForm.get(key).touched && this.summaryForm.get(key).valid) {
        this.actualData.custom[key] = this.summaryForm.get(key).value;
      }
    });
  }

  public next() {
    this.stepChange();
    this._stepFormSrv.navigate$.next(true);
  }

  public previous() {
    this.stepChange();
    this._stepFormSrv.navigate$.next(false);
  }

  public cancel() {
    this._stepFormSrv.closeModal$.next(true);
  }

  public valid() {
    this.stepChange();
    this._stepFormSrv.updateElement(this.actualData.custom, null, this.actualData.index, this.actualData.childrenIndex);
    this._stepFormSrv.closeModal$.next(true);
  }

  public formatSummary() {
    this.summaryTitle = [];
    this.summary = [];
    this.summaryAmount = [];
    const customData: any = this.actualData.custom,
          cuttingData: any = customData.AdditionalComputings.cut.origin,
          weldingData: any = customData.AdditionalComputings.welding.origin;

    let summaryData: any[];

    summaryData = this.formatMatterSummary(customData);
    if (summaryData.length > 0 ) {
      this.summaryTitle.push("Matière");
      this.summary.push(summaryData);
    }

    summaryData = this.formatCuttingSummary(customData, cuttingData);
    if (summaryData.length > 0 ) {
      this.summaryTitle.push("Laser");
      this.summary.push(summaryData);
    }

    summaryData = this.formatFoldingSummary(customData);
    if (summaryData.length > 0 ) {
      this.summaryTitle.push("Pliage");
      this.summary.push(summaryData);
    }

    summaryData = this.formatWeldingSummary(weldingData, customData);
    if (summaryData.length > 0 ) {
      this.summaryTitle.push("Soudure");
      this.summary.push(summaryData);
    }

    this.summaryAmount.push({ name: "Prix unitaire", value: customData.Price, suffix: "€" });
    this.summaryAmount.push({ name: "Prix total", value: customData.Price * customData.Quantity, suffix: "€" });
  }

  protected formatMatterSummary(customData: any): any[] {
    return [
      { name: "Matière", value: customData.elementForWelding.properties.matter.name, suffix: "" },
      { name: "Epaisseur", value: customData.properties.thickness, suffix: "mm" },
      { name: "Hauteur", value: customData.properties.height, suffix: "mm" },
      { name: "Longueur", value: customData.properties.width, suffix: "mm" },
      { name: "Prix", value: customData.properties.matter.pricePerKg, suffix: "€/kg" },
      { name: "Prix matière", value: customData.matterPrice.toFixed(3), suffix: "€" },
      { name: "Poids total", value: customData.TotalWeight.toFixed(3), suffix: "kg" },
      { name: "Surface total", value: (customData.TotalArea * SQUARE_MM_TO_SQUARE_M).toFixed(5), suffix: "m²" },
      { name: "Coût additionnel unitaire", value: customData.UnitaryAdditionalCost, suffix: "€" },
      { name: "Coût additionnel global", value: customData.GlobalAdditionalCost, suffix: "€" },
      { name: "Prix total", value: customData.CalculatedPrice.toFixed(3), suffix: "€" },
      { name: "Remarques", value: customData.Remarks, suffix: "" },
    ];
  }

  protected formatCuttingSummary(customData: any, cuttingData: any): any[] {
    return [
      { name : "Longueur de la découpe" , value : cuttingData.meterForCutting, suffix : "mm"},
      { name : "Quantité de perçage", value : cuttingData.holeQuantity,  suffix : ""},
      { name : "Diamètre", value : cuttingData.holeDiameter, suffix : "mm"},
      { name : "Marge de la découpe", value : customData.properties.margin, suffix : "mm"},
      { name : "Prix de l'amorce laser", value : cuttingData.Prc, suffix : "€"},
      { name : "Prix de la découpe laser", value : cuttingData.PriceByM, suffix : "€/m"},
      { name : "Dessin", value : cuttingData.PriceForBluePrint, suffix : "€"},
      { name : "Coût additionnel unitaire", value: cuttingData.UnitaryAdditionalCost, suffix: "€" },
      { name : "Coût additionnel global", value: cuttingData.GlobalAdditionalCost, suffix: "€" },
      { name : "Prix", value : cuttingData.Price.toFixed(3), suffix : "€"},
      { name : "Remarques", value: cuttingData.Remarks, suffix: "" },
    ];
  }

  public formatFoldingSummary(customData: any): any[] {
    return [];
  }

  protected formatWeldingSummary(weldingData: any, customData: any): any[] {
    return [
      { name : "Longueur de la soudure", value: (weldingData.MeterForWelding / (weldingData.WeldingPercentage / 100)), suffix: "m" },
      { name : "Section de la soudure", value: weldingData.WeldingSection, suffix: "mm" },
      { name : "Pourcentage de soudure", value: weldingData.WeldingPercentage, suffix: "%" },
      { name : "Nombre de passes", value: weldingData.Passes, suffix: "" },
      { name : "Prix au mètre de soudure", value: weldingData.PriceByM, suffix: "€" },
      { name : "Coût additionnel unitaire", value: weldingData.UnitaryAdditionalCost, suffix: "€" },
      { name : "Coût additionnel global", value: weldingData.GlobalAdditionalCost, suffix: "€" },
      { name : "Prix", value : weldingData.Price.toFixed(3), suffix : "€"},
      { name : "Remarques", value: weldingData.Remarks, suffix: "" },
    ];
  }

}
