import { Component, OnInit, Inject, OnDestroy } from "@angular/core";
import { ModalContentForm } from "../../model/modal-content-form";
import { ModalService, DATA } from "../../services/modal.service";
import { SnackService } from "app/presentationnal/organisms/snack-bar/services/snack.service";
import { FormBuilder, FormArray, FormGroup, Validators } from "@angular/forms";
import { AMALGAM_TABLE_CONFIG, DIVERS_TABLE_CONFIG, PLATE_TABLE_CONFIG, SUPPLIER_RESPONSE_FIELDS_CONFIG, SUPPLIER_RESPONSE_TABLE_FORM_ACTION, OPTIONS_TABLE_CONFIG } from "app/facade/configs/supplier-response.config";
import { IPriceRequestElement, IPriceRequestElementOptionType, IPriceRequestElementOptionUnit, IPriceRequestElementOption, IPriceRequestAdditionnalCost, IPriceRequest, IpriceRequestAdditionnalCostType } from "app/facade/interfaces/price-request.interface";
import { AmalgamService } from "app/facade/services/amalgam.service";
import * as moment from "moment";
import { ISupplierOfferUpdate, ISupplierOffer, ISupplierOfferElementInpdate, ISupplierOfferElement, IVariant, ISupplierOfferAdditionnalCost, ISupplierOfferElementOption, IVariantOption, IVariantOptionInpdate, ISupplierOfferAdditionnalCostInpdate, IWeightData } from "app/facade/interfaces/supplier-offer";
import { Subscription, Subject } from "rxjs";
import { ITableFormActions } from "app/facade/interfaces/tables.interface";
import { CategoriesQueriesService } from "app/facade/queries/category/categories-queries.service";
import { ISupplyCategory, IAvailableMatters, IElements } from "app/facade/interfaces/supplier";
import { ISelectOption } from "app/presentationnal/atoms/inputs/select-input/selectOptions";
import { OPTION_LABELS, OPTION_DESCRIPTIONS } from "app/facade/configs/price-request.config";
import { UniqueSupplierOfferAdditionnalCostType, MultiSupplierOfferAdditionnalCostType, EnumAdditionnalCostUnit } from "app/facade/enums/supplier-offer-additionnal-cost-type.enum";
import { deepClone } from "@lib/misc/clone";
import { ElementUnitConfig } from "app/facade/configs/price-request-unit.config";
import { PriceRequestElementOptionUnit } from "app/facade/enums/price-request-element-option-datas.enum";
import { AMALGAMS_IDS } from "app/facade/configs/amalgams-ids.config";
import { debounceTime } from "rxjs/operators";
import { PriceRequestsQueriesService } from "app/facade/queries/price-request/price-requests-queries.service";
import { ElementUnitCategory, EnumElementUnit, EnumQuantityUnit, EnumQuantityUnitSelectValue, EnumUnitValue ,EnumElementUnitSelectValue} from "app/facade/enums/element-unit-category.enum";
import { ISupplierOfferFieldsConfig } from "app/facade/interfaces/fields-config.interface";
import { IModalData } from "../../interfaces/modal-data.interface";
import { CatalogQueriesService } from "app/facade/queries/catalog/async/catalog_queries.service";

enum ElementType {
  AMALGAM,
  PLATE,
  DEFAULT
}

interface IEncoreSupplierModalData extends IModalData {
  data: IDataModalEncodeSupplierOffer;
}

interface IFormDataElements {
  headerConfig: {
    actionsList: any[];
    headerClass: string;
    headersName: string[];
    rowClass: string;
  };
  tableDatas: FormArray;
  attributs: {
    name: string;
  }[];
  categoryId?: number;
  unit: string;
  isAmalgam?: boolean;
  type?: ElementType;
  suggestConfig: {actionsList: any, optionsList: any};
}

interface IDataModalEncodeSupplierOffer {
  supplierOffer: ISupplierOffer;
  priceRequestId: number;
}

@Component({
  selector: "app-modal-encode-supplier-offer",
  templateUrl: "./modal-encode-supplier-offer.component.html",
  styleUrls: ["./modal-encode-supplier-offer.component.css"]
})
export class ModalEncodeSupplierOfferComponent extends ModalContentForm implements OnInit, OnDestroy {
  private debouncerCalculWeight: Subject<any> = new Subject();

  private _globalDateSub: Subscription;
  private _elements: IPriceRequestElement[] = [];
  private _supplierOffer: ISupplierOffer;
  private _priceRequest: IPriceRequest;
  private _additionnalCosts: ISupplierOfferAdditionnalCost[];
  private _supplierOfferUpdateDatas: ISupplierOfferUpdate;
  private _deletedVariantIds: number[] = [];
  private _deletedVariantOptionIds: number[] = [];
  private _deletedAdditionnalCostIds: number[] = [];
  private _supplyCategoriesWithElement: ISupplyCategory[] = [];
  private _additionnalCostUniqueKey: number = 0;
  public tableFormDatas: IFormDataElements[] = [];
  public fieldsConfig: ISupplierOfferFieldsConfig = SUPPLIER_RESPONSE_FIELDS_CONFIG;
  public actionList: ITableFormActions = SUPPLIER_RESPONSE_TABLE_FORM_ACTION;
  public additionnalCostFormData: FormArray = new FormArray([]);
  public uniqueAdditionnalCostDefinition: any = UniqueSupplierOfferAdditionnalCostType;
  public multiAdditionnalCostDefinition: any = MultiSupplierOfferAdditionnalCostType;
  public additionnalCostUnit: any = EnumAdditionnalCostUnit;
  public additionnalCostUnitOptions: ISelectOption[] = [];
  public optionTableConfig: any = OPTIONS_TABLE_CONFIG;

  constructor(
    private _amalgamService: AmalgamService,
    private _categoriesQueriesSrv: CategoriesQueriesService,
    private _priceRequestsQueriesSrv: PriceRequestsQueriesService,
    private _catalogQueriesSrv: CatalogQueriesService,
    protected _modalService: ModalService,
    protected _snackBar: SnackService,
    protected _fb: FormBuilder,
    @Inject(DATA) private _data: IEncoreSupplierModalData) {
      super(_modalService, _fb, _snackBar);
      this._supplierOffer = this._data.data.supplierOffer;
      this._priceRequest = this._data.data.supplierOffer.priceRequest;
      this._elements = this._data.data.supplierOffer.possibleElements;
      this._additionnalCosts = this._data.data.supplierOffer.additionnalCosts;
      this.fieldsConfig.isAddedRow.additionnalButton.actionToDo = this.addVariantOption;
      this.fieldsConfig.isCut.actionToDo = this.updateCutOption;
      this.fieldsConfig.option.actionToDo = this.updateOption;
      this.optionTableConfig.actionList.deleteAction.actionToDo = this.deleteVariantOption;
  }

  ngOnInit() {
    this._formGroup = this._fb.group({
      supplierInfo: `${this._supplierOffer.supplier.code} - ${this._supplierOffer.supplier.name}`,
      supplierReference: this._supplierOffer.supplierReference,
      remark: this._supplierOffer.remark,
      globalDeliveryDate: null
    });
    this.checkGlobalDate();
    this.defineAdditionnalCostFormData();
    this.getInitData();

    // Use to calculate weight on Variant Element
    this.debouncerCalculWeight.pipe(debounceTime(500)).subscribe((data) => {
      this.getVariantWeightValue(data.formGroup, data.type);
    });
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this._globalDateSub.unsubscribe();
  }

  /* ADDITIONNAL COST METHODS*/
  private defineAdditionnalCostFormData() {
    this.additionnalCostUnitOptions = Object.keys(this.additionnalCostUnit).map( unit => {
      return {
        value: unit,
        label: this.additionnalCostUnit[unit]
      };
    });
    let foundAddCost: ISupplierOfferAdditionnalCost;
    this._priceRequest.additionnalCosts.forEach( addCost => {
      foundAddCost = this._additionnalCosts.find( suppAddCost => suppAddCost.priceRequestAdditionnalCostId === addCost.id);
      // if true can't select unity (euro and euro quantity) else we can selected
      this.additionnalCostFormData.push(this._fb.group(this.setAdditionnalCostData(addCost, foundAddCost, false)));
    });
    this.additionnalCostFormData.push(this._fb.group(this.setAdditionnalCostData()));
  }

  private setAdditionnalCostData(additionnalCost?: IPriceRequestAdditionnalCost, supplierOffer?: ISupplierOfferAdditionnalCost, alwaysPrinted: boolean = false): any {
    return {
      additionnalCostid: additionnalCost ? additionnalCost.id : null,
      supplierOfferAdditionnalCostId: supplierOffer ? supplierOffer.id : null,
      quantity: [supplierOffer && supplierOffer.quantity ? supplierOffer.quantity : (!additionnalCost ? null : additionnalCost.quantity), Validators.required],
      type: additionnalCost ? additionnalCost.type : Object.keys(this.multiAdditionnalCostDefinition)[0],
      unit: supplierOffer && supplierOffer.unit ? supplierOffer.unit : (additionnalCost ? additionnalCost.unit : Object.keys(this.additionnalCostUnit)[0]),
      denomination: supplierOffer && supplierOffer.denomination ? supplierOffer.denomination : (!additionnalCost ? null : additionnalCost.denomination),
      price: [!supplierOffer ? null : (additionnalCost && additionnalCost.type === IpriceRequestAdditionnalCostType.OTHER ? supplierOffer.price : supplierOffer.inputPrice), Validators.required],
      alwaysPrinted, // Use to define if it's an unique type
      hasSupplierResponse: supplierOffer !== undefined,
      canBeDeleted: false, // Use to confirm the delete action
      canBeEdited: !additionnalCost || additionnalCost.type !== IpriceRequestAdditionnalCostType.OTHER, // Use to confirm the delete action
      isNew: supplierOffer === undefined,
      // Can be deleted
      canBeSave: alwaysPrinted || supplierOffer !== undefined,
    };
  }

  public getAdditionCostTypeValue(type: string) {
    return this.uniqueAdditionnalCostDefinition[type] ? this.uniqueAdditionnalCostDefinition[type] : this.multiAdditionnalCostDefinition[type];
  }

  public addAdditionnalCostData(formData: FormGroup) {
    if (formData.valid) {
      formData.get("hasSupplierResponse").patchValue(true);
      this.additionnalCostFormData.push(this._fb.group(this.setAdditionnalCostData()));
    } else {
      this.markFormGroupTouched(formData);
    }
  }

  public beforeDeleteAdditionnalCost(formData: FormGroup, value: boolean) {
    formData.get("canBeDeleted").patchValue(value);
  }

  public deleteAdditionnalCostData(formData: FormGroup, additionnalCostIndex: number) {
    if (!formData.get("isNew").value) {
      this._deletedAdditionnalCostIds.push(formData.get("supplierOfferAdditionnalCostId").value);
    }
    if (formData.get("alwaysPrinted").value) {
      const newFormGroup = this._fb.group({
        ...formData.getRawValue(),
        hasSupplierResponse: false,
        price: null
      });
      this.additionnalCostFormData.insert(additionnalCostIndex, newFormGroup);
      additionnalCostIndex++;
    }
    this.additionnalCostFormData.removeAt(additionnalCostIndex);
  }

  /* ELEMENTS METHODS */
  private getInitData() {
    const requiredData = [
      this._catalogQueriesSrv.getMatter(false).toPromise(),
      this._categoriesQueriesSrv.getSupplyCategoriesAndElements().toPromise()
    ];
    Promise.all(requiredData).then(result => {
      const matters: IAvailableMatters[] = (<any>result[0].data).availableMatters || [];
      const supplyCategories: ISupplyCategory[] = (<any>result[1].data).supplyCategories || [];
      this.addConfigsAction(matters, supplyCategories);
    }, reject => {
      console.error("ERROR LOADING INIT DATA FOR ADD SUPPLY LIST", { reject });
    });
  }

  private addConfigsAction(matters: IAvailableMatters[], supplyCategories: ISupplyCategory[]) {
    this.fieldsConfig.option.options = [
      {value: OPTION_LABELS.BLACK, label: OPTION_LABELS.BLACK},
      {value: OPTION_LABELS.BLASTED, label: OPTION_LABELS.BLASTED},
      {value: OPTION_LABELS.PRIMARY_BLASTED, label: OPTION_LABELS.PRIMARY_BLASTED},
    ];
    this.defineFormsDatas();
    /*
    * Récupérer les éléments selon leurs catégories parent
    * Envoyer ces catégories dans les tables form adéquates
    * Gérer le supplyCategoryId pour les variantes > peut être différent de son parent (pas logique mais possible)
    *
    */
    this._supplyCategoriesWithElement = supplyCategories.filter( supplyCat => supplyCat.elementGroup);
    let elementOptions: ISelectOption[];
    let matterOptions: ISelectOption[] = [];
    const formattedMatterOptions: ISelectOption[] = matters.map(matter => ({
      label: matter.en1090Name ? matter.en1090Name : matter.name,
      value: matter.id
    }));
    this.tableFormDatas.forEach( tableFormData => {
      elementOptions = [];
      matterOptions = [];
      this._supplyCategoriesWithElement.filter( supplyCat => supplyCat.parentSupplyCategoryId === tableFormData.categoryId).forEach( supplyCat => {
        const tempReference: Set<string> = new Set([]);
        const baseIndex = elementOptions.length;
        supplyCat.elementGroup.elements.forEach( element => {
          tempReference.add(element.name);
        });
        elementOptions = elementOptions.concat(Array.from(tempReference).map((value, index) => {
          return {value: baseIndex + index, label: value};
        }));
        if (ElementUnitConfig.categories[ElementUnitCategory.BEAMS].includes(+supplyCat.id) || ElementUnitConfig.categories[ElementUnitCategory.TUBES].includes(+supplyCat.id)) {
          // Only until Devis matches Administration matter variety for beams & tubes !
          matterOptions = formattedMatterOptions;
        }
      });
      tableFormData.suggestConfig = {
        actionsList: {
          reference: this.searchReference,
          matterRef: this.searchMatter
        },
        optionsList: {
          reference: elementOptions,
          matterRef: matterOptions
        }
      };
    });

  }

  private searchReference = (data: {isSearch: boolean, value: number, label: string, formGroup: FormGroup, matterId: number, addedData: any}) => {
    if (data.isSearch) {
      data.formGroup.get("elementId").patchValue(null);
    } else {
      const reference: string = data.formGroup.get("reference").value;
      if (reference) {
        const category: ISupplyCategory = this._supplyCategoriesWithElement.find(supplyCategory => supplyCategory.id === data.formGroup.get("supplyCategoryId").value);
        if (category && category.elementGroup && category.elementGroup.elements) {
          const element: IElements = category.elementGroup.elements.find(elem => elem.name === reference);
          data.formGroup.get("elementId").patchValue(element ? +element.id : null);
        }
      }
    }
  }

  private searchMatter = (data: {isSearch: boolean, value: number, label: string, formGroup: FormGroup, addedData: any, options: ISelectOption[]}) => {
    if (data.isSearch) {
      const matching = this.findMatchingMatter(data.formGroup.get("matterRef").value, data.options);
      data.formGroup.get("matterId").patchValue(!!matching ? matching.value : null);
    } else {
      const matterRef: string = data.formGroup.get("matterRef").value;
      if (matterRef) {
        data.formGroup.get("matterId").patchValue(data.value);
      }
    }
  }

  private findMatchingMatter(search: string, options: ISelectOption[]): ISelectOption {
    return options ? options.find(option => new RegExp(`^${option.label}.*$`).test(search)) : undefined;
  }

  private checkGlobalDate() {
    this._globalDateSub = this._formGroup.get("globalDeliveryDate").valueChanges.subscribe( value => {
      this.tableFormDatas.forEach( table => {
        table.tableDatas.controls.forEach( formControl => {
          formControl.get("deliveryDate").patchValue(value);
          formControl.get("dateFormated").patchValue(moment.unix(value).format("DD/MM/YYYY"));
        });
      });
    });
  }

  private defineFormsDatas() {
    this.tableFormDatas = [];
    // let isAmalgam: boolean,
    let categoryFormData: IFormDataElements,
        categoryId: number,
        createNewCategoryFormGroup: boolean,
        elementFormGroup: FormGroup,
        variantFormGroup: FormGroup,
        type: ElementType;
    this._elements.forEach( element => {

      type = this.getElementType(element);
      createNewCategoryFormGroup = false;
      // isAmalgam = element.amalgamGroup !== null;
      if (!element.parentSupplyCategory) {
        console.log("NO parentSupplyCategory >>>", element);
      }
      categoryId = element.parentSupplyCategory ? element.parentSupplyCategory.id : null;
      categoryFormData = this.tableFormDatas.find( catFormData => catFormData.categoryId === categoryId && catFormData.type === type);
      if (!categoryFormData) {
        createNewCategoryFormGroup = true;
        categoryFormData = this.initCategoryFormData(type);
        categoryFormData.tableDatas = new FormArray([]);
        categoryFormData.categoryId = categoryId;
        categoryFormData.type = type;
        categoryFormData.suggestConfig = {
          actionsList: null,
          optionsList: null
        };
      }
      const elementUnitCategory: any = Object.keys(ElementUnitConfig.categories).find( elementCat => ElementUnitConfig.categories[elementCat].includes(+categoryId));
      categoryFormData.unit = !!elementUnitCategory ? ElementUnitConfig.units[elementUnitCategory] : ElementUnitConfig.units.default;
      elementFormGroup = this._fb.group({
        ...this.getFormControl(element, type, null, false, false, elementUnitCategory)
      });

      categoryFormData.tableDatas.push(elementFormGroup);

      element.supplierOfferElements.filter( suppOfferElement => suppOfferElement.variantId !== null)
        .forEach( suppOfferElement => {
          variantFormGroup = this._fb.group({
            ...this.formateSavedVarianteToPriceRequestElement(element, suppOfferElement, type),
            unitSuffix: EnumElementUnitSelectValue[suppOfferElement.unit] ? EnumElementUnitSelectValue[suppOfferElement.unit] : !!elementUnitCategory ? ElementUnitConfig.units[elementUnitCategory] : ElementUnitConfig.units.default
          });
          categoryFormData.tableDatas.push(variantFormGroup);
          this.listenVarianteValueChange(variantFormGroup, type);
      });
      if (createNewCategoryFormGroup) {
        if (type === ElementType.AMALGAM) {
          // Put TUBES in the second position of the multi table
          if (+categoryFormData.categoryId === +AMALGAMS_IDS.TUBES_ID) {
            const findBeamTableIndex: number = this.tableFormDatas.findIndex( table => +table.categoryId === +AMALGAMS_IDS.BEAMS_ID);
            if (findBeamTableIndex === -1) {
              this.tableFormDatas.unshift(categoryFormData);
            } else {
              this.tableFormDatas.splice((findBeamTableIndex + 1), 0, categoryFormData);
            }
          } else {
            this.tableFormDatas.unshift(categoryFormData);
          }
        } else {
          this.tableFormDatas.push(categoryFormData);
        }
      }
    });
  }

  /**
   * @description Get element display type based on its composition
   * @author Quentin Wolfs
   * @private
   * @param {IPriceRequestElement} element
   * @returns {ElementType}
   * @memberof ModalEncodeSupplierOfferComponent
   */
  private getElementType(element: IPriceRequestElement): ElementType {
    if (!!element.amalgamGroup) {
      return ElementType.AMALGAM;
    } else {
      if (ElementUnitConfig.categories[ElementUnitCategory.PLATES].includes(+element.supplyListElement.supplyCategoryId)) {
        return ElementType.PLATE;
      } else {
        return ElementType.DEFAULT;
      }
    }
  }

  /**
   * @description Init the FormDataElement depending on its type. Uses external configs.
   * @author Quentin Wolfs
   * @private
   * @param {ElementType} type
   * @returns {IFormDataElements}
   * @memberof ModalEncodeSupplierOfferComponent
   */
  private initCategoryFormData(type: ElementType): IFormDataElements {
    switch (type) {
      case ElementType.AMALGAM:
        return deepClone(AMALGAM_TABLE_CONFIG);
      case ElementType.PLATE:
        return deepClone(PLATE_TABLE_CONFIG);
      case ElementType.DEFAULT:
      default:
        return deepClone(DIVERS_TABLE_CONFIG);
    }
  }

  // Define Element FormControl
  private getFormControl(element: IPriceRequestElement, type: ElementType, variantId: number = null, newVariant: boolean = false, isParentInStock: boolean = false, elementUnitCategory?: string): any {
    let formControlData: any = {
      id: null,
      quantity: element.quantity,
      remark: element.remark,
      weight: element.weight,
      price: null,
      deliveryDate: null,
      dateFormated: null,
      supplierOfferId: this._supplierOffer.id,
      priceRequestElementId: element.id,
      isAddedRow: variantId !== null, // variante
      variantId: variantId,
      isNewVariant: newVariant,
      isFullyInStock: variantId != null ? isParentInStock : element.stockQuantity >= element.quantity,
      rowAdditionnalDatas: this.setOptionsFormArray(element.options)
    };

    formControlData.unitSuffix = !!elementUnitCategory ? ElementUnitConfig.units[elementUnitCategory] : ElementUnitConfig.units.default;

    switch (type) {
      case ElementType.AMALGAM:
        formControlData = {
          ...formControlData,
          reference: element.amalgamGroup.reference,
          matterRef: element.amalgamGroup.matterRef,
          format: element.amalgamGroup.format,
          isCut: element.amalgamGroup.isCut,
          isEn1090: element.amalgamGroup.isEn1090,
          option: this._amalgamService.getOptionLabel(element.amalgamGroup),
          elementId: element.amalgamGroup.elementId,
          matterId: element.amalgamGroup.matterId,
          supplyCategoryId: element.amalgamGroup.supplyCategoryId
        };
        break;
      case ElementType.PLATE:
        formControlData = {
          ...formControlData,
          matterRef: element.supplyListElement.matterRef,
          thickness: element.supplyListElement.thickness,
          length: element.supplyListElement.length,
          width: element.supplyListElement.width,
          quantityUnit: !!variantId ? element.supplyListElement.quantityUnit : EnumQuantityUnitSelectValue[element.supplyListElement.quantityUnit],
          realQuanityUnit: element.supplyListElement.quantityUnit,
          matterId: element.supplyListElement.matterId,
          supplyCategoryId: element.supplyListElement.supplyCategoryId
        };
        break;
      case ElementType.DEFAULT:
      default:
        formControlData = {
          ...formControlData,
          denomination: element.supplyListElement.denomination,
          quantityUnit: element.supplyListElement.quantityUnit,
          supplyCategoryId: element.supplyListElement.supplyCategoryId
        };
    }
    const supplierOfferElement: ISupplierOfferElement = element.supplierOfferElements ? element.supplierOfferElements.find( suppOffer => suppOffer.variantId === variantId) : null;
    if (supplierOfferElement) {
      formControlData = {
        ...formControlData,
        ...this.setSupplierResponse(supplierOfferElement)
      };
      if (supplierOfferElement.price) {
        formControlData.unitSuffix = EnumElementUnitSelectValue[supplierOfferElement.unit] ? EnumElementUnitSelectValue[supplierOfferElement.unit] : !!elementUnitCategory ? ElementUnitConfig.units[elementUnitCategory] : ElementUnitConfig.units.default;
      }
    }
    return formControlData;
  }

  private setSupplierResponse(supplierOfferElement: ISupplierOfferElement): any {
    return {
      id: supplierOfferElement.id,
      price: supplierOfferElement.price,
      // unitSuffix:EnumElementUnitSelectValue[supplierOfferElement.unit],
      deliveryDate: supplierOfferElement.deliveryDate,
      dateFormated: supplierOfferElement.deliveryDate ? moment.unix(supplierOfferElement.deliveryDate).format("DD/MM/YYYY") : null
    };
  }

  private setOptionsFormArray(options: IPriceRequestElementOption[]) {
    const optionFormArray: FormArray = this._fb.array([]);
    let optionFormControl: any;
    let supplierOfferElementOption: ISupplierOfferElementOption;

    options.forEach(option => {
      optionFormControl = {
        type: option.type,
        denomination: option.denomination,
        quantity: option.quantity,
        unit: option.unit,
        priceRequestElementOptionId: option.id,
        id: null,
        price: null,
        unitSuffix: PriceRequestElementOptionUnit[option.unit]
      };
      supplierOfferElementOption = option.supplierOfferElementOptions.find( supplierOffer => supplierOffer.supplierOfferElement.supplierOfferId === this._supplierOffer.id);
      if ( supplierOfferElementOption ) {
        optionFormControl.id = supplierOfferElementOption.id;
        optionFormControl.price = supplierOfferElementOption.price;
      }
      optionFormArray.push( this._fb.group(optionFormControl));
    });
    return optionFormArray;
  }

  private formateSavedVarianteToPriceRequestElement(element: IPriceRequestElement, supplierOfferElement: ISupplierOfferElement, type: ElementType): any {
    const variant: IVariant = supplierOfferElement.variant;
    const priceRequestElement: any = { // IPriceRequestElement
      id: element.id,
      quantity: variant.quantity,
      remark: variant.remark,
      weight: variant.weight,
      supplierOfferId: this._supplierOffer.id,
      supplierOfferElements: [supplierOfferElement],
      options: [] // Treatment done at the end
    };
    switch (type) {
      case ElementType.AMALGAM:
        priceRequestElement.amalgamGroup = {
          reference: variant.reference,
          matterRef: variant.matterRef,
          format: variant.format,
          isCut: variant.isCut,
          isEn1090: variant.isEn1090,
          isBlack: variant.isBlack,
          isBlasted: variant.isBlasted,
          isPrimaryBlasted: variant.isPrimaryBlasted,
          elementId: variant.elementId,
          matterId: variant.matterId,
          supplyCategoryId: variant.supplyCategoryId
        };
        break;
      case ElementType.PLATE:
        priceRequestElement.supplyListElement = {
          matterRef: variant.matterRef,
          quantityUnit: variant.quantityUnit,
          thickness: variant.thickness,
          length: variant.length,
          width: variant.width,
          matterId: variant.matterId,
          supplyCategoryId: variant.supplyCategoryId
        };
        break;
      case ElementType.DEFAULT:
      default:
        priceRequestElement.supplyListElement = {
          denomination: variant.denomination,
          quantityUnit: variant.quantityUnit,
          supplyCategoryId: variant.supplyCategoryId
        };
    }
    let optionFormGroup: FormGroup;
    return {
      ...this.getFormControl(priceRequestElement, type , supplierOfferElement.variantId, false, element.stockQuantity >= element.quantity),
      rowAdditionnalDatas: this._fb.array(variant.options.map( option => {
        optionFormGroup = this._fb.group({
          ...option,
          isNewVariantOption: false,
        });
        this.updateOptionUnitSuffix(optionFormGroup);
        return optionFormGroup;
      }))
    };
  }

  public addVariante( eventData: { categoryId: number, data: FormGroup, rowId: string }) {
    const selectedTableFormData: IFormDataElements = this.tableFormDatas.find( table => table.categoryId === eventData.categoryId);

    if (selectedTableFormData) {
      let newFormGroup: FormGroup;
      const priceRequestElement: any = { // IPriceRequestElement
        id: eventData.data.get("priceRequestElementId").value,
        quantity: eventData.data.get("quantity").value,
        remark: eventData.data.get("remark").value,
        weight: eventData.data.get("weight").value,
        supplierOfferId: this._supplierOffer.id,
        options: []
      };
      switch (selectedTableFormData.type) {
        case ElementType.AMALGAM:
          priceRequestElement.amalgamGroup = {
            reference: eventData.data.get("reference").value,
            matterRef: eventData.data.get("matterRef").value,
            format: eventData.data.get("format").value,
            isCut: eventData.data.get("isCut").value,
            isEn1090: eventData.data.get("isEn1090").value,
            isBlack: eventData.data.get("option").value === OPTION_LABELS.BLACK,
            isBlasted: eventData.data.get("option").value === OPTION_LABELS.BLASTED,
            isPrimaryBlasted: eventData.data.get("option").value === OPTION_LABELS.PRIMARY_BLASTED,
            elementId: eventData.data.get("elementId").value,
            matterId: eventData.data.get("matterId").value,
            supplyCategoryId: eventData.data.get("supplyCategoryId").value,
          };
          break;
        case ElementType.PLATE:
          priceRequestElement.supplyListElement = {
            matterRef: eventData.data.get("matterRef").value,
            quantityUnit: eventData.data.get("realQuanityUnit").value,
            thickness: eventData.data.get("thickness").value,
            length: eventData.data.get("length").value,
            width: eventData.data.get("width").value,
            matterId: eventData.data.get("matterId").value,
            supplyCategoryId: eventData.data.get("supplyCategoryId").value,
          };
          break;
        case ElementType.DEFAULT:
        default:
          priceRequestElement.supplyListElement = {
            quantityUnit: eventData.data.get("quantityUnit").value,
            denomination: eventData.data.get("denomination").value,
            supplyCategoryId: eventData.data.get("supplyCategoryId").value
          };
      }
      const rowAdditionnalDatas: any = eventData.data.get("rowAdditionnalDatas").value;
      let optionFormGroup: FormGroup;
      const newFormControl: any = {
        ...this.getFormControl(priceRequestElement, selectedTableFormData.type , +new Date(), true, eventData.data.get("isFullyInStock").value),
        rowAdditionnalDatas: this._fb.array(rowAdditionnalDatas.map( option => {
          optionFormGroup = this._fb.group({
            id: null,
            type: option.type,
            denomination: option.denomination,
            quantity: option.quantity,
            price: null,
            unit: option.unit,
            variantId: null,
            isNewVariantOption: true,
            unitSuffix: PriceRequestElementOptionUnit[option.unit]
          });
          this.updateOptionUnitSuffix(optionFormGroup);
          return optionFormGroup;
        }))
      };
      newFormGroup = this._fb.group({
        ...newFormControl,
        unitSuffix: selectedTableFormData.unit
      });
      selectedTableFormData.tableDatas.insert(+eventData.rowId + 1, newFormGroup);
      this.listenVarianteValueChange(newFormGroup, selectedTableFormData.type);
    } else {
      console.log("ERROR SEARCHING FORMGROUP WITH ID ", eventData);
    }
  }

  private listenVarianteValueChange(formGroup: FormGroup, type: ElementType) {
    let listenedAttr: string[];
    switch (type) {
      case ElementType.AMALGAM:
        listenedAttr = ["quantity", "elementId", "format", "matterId"];
        break;
      case ElementType.PLATE:
        listenedAttr = ["quantity", "quantityUnit", "width", "length", "thickness"];
        break;
      case ElementType.DEFAULT:
      default:
        listenedAttr = [];
    }
    listenedAttr.forEach( attr => {
      formGroup.get(attr).valueChanges.subscribe( value => {
        this.debouncerCalculWeight.next({ formGroup, type });
      });
    });
  }

  private getVariantWeightValue(formGroup: FormGroup, type: ElementType) {
    const formGroupValue: any = formGroup.value;
    switch (type) {
      case ElementType.AMALGAM:
        if (formGroupValue.quantity && formGroupValue.format && formGroupValue.elementId && formGroupValue.matterId && formGroupValue.supplyCategoryId) {
          const weightData: IWeightData = {
            quantity: formGroupValue.quantity,
            format: formGroupValue.format.toString(),
            elementId: formGroupValue.elementId,
            matterId: formGroupValue.matterId,
            supplyCategoryId: formGroupValue.supplyCategoryId
          };
          this._priceRequestsQueriesSrv.getVariantWeight(weightData).subscribe( result => {
            const resultData: {getVariantWeight: number} = <any>result.data;
            if (resultData.getVariantWeight) {
              formGroup.get("weight").patchValue(resultData.getVariantWeight);
            } else {
              formGroup.get("weight").patchValue(0);
            }
          }, error => {
            console.error("ERROR GET VARIANT WEIGHT", {error});
          });
        } else {
          formGroup.get("weight").patchValue(0);
        }
        break;
      case ElementType.PLATE:
        if (formGroupValue.quantity && formGroupValue.quantityUnit) {
          switch (formGroupValue.quantityUnit) {
            // case EnumQuantityUnit.KG:
            //   formGroup.get("weight").patchValue(formGroupValue.quantity);
            //   break;
            case EnumQuantityUnit.TON:
              formGroup.get("weight").patchValue(+formGroupValue.quantity * 1000);
              break;
            case EnumQuantityUnit.UNIT:
              if (formGroupValue.quantity && formGroupValue.width && formGroupValue.length && formGroupValue.thickness && formGroupValue.matterId) {
                const weightData: IWeightData = {
                  quantity: formGroupValue.quantity,
                  elementId: formGroupValue.elementId,
                  matterId: formGroupValue.matterId,
                  supplyCategoryId: formGroupValue.supplyCategoryId,
                  thickness: formGroupValue.thickness.toString(),
                  width: formGroupValue.width.toString(),
                  length: formGroupValue.length.toString()
                };
                this._priceRequestsQueriesSrv.getVariantWeight(weightData).subscribe( result => {
                  const resultData: {getVariantWeight: number} = <any>result.data;
                  if (resultData.getVariantWeight) {
                    formGroup.get("weight").patchValue(resultData.getVariantWeight);
                  } else {
                    formGroup.get("weight").patchValue(0);
                  }
                }, error => {
                  console.error("ERROR GET VARIANT WEIGHT", {error});
                });
              } else {
                formGroup.get("weight").patchValue(0);
              }
              break;
            default:
              formGroup.get("weight").patchValue(0);
          }
        }
    }
  }

  public deleteVariante( eventData: { categoryId: number, rowId: number }) {
    const selectedTableFormData: IFormDataElements = this.tableFormDatas.find( table => table.categoryId === eventData.categoryId);
    if ( !selectedTableFormData.tableDatas.value[eventData.rowId].isNewVariant ) {
      this._deletedVariantIds.push(selectedTableFormData.tableDatas.value[eventData.rowId].variantId);
    }
    selectedTableFormData.tableDatas.removeAt(eventData.rowId);
  }

  public addVariantOption = (index: string) => {

    // 0: categoryId - 1: Index
    const splittedIndex: string[] = index.split("_");
    const selectedTableFormData: IFormDataElements = this.tableFormDatas.find( table => +table.categoryId === +splittedIndex[0]);
    const selectedVariantFormGroup: FormGroup = (<FormGroup>selectedTableFormData.tableDatas.controls[+splittedIndex[1]]);
    const rowAdditionnalDatas: FormArray = (<FormArray>selectedVariantFormGroup.get("rowAdditionnalDatas"));
    const newOptionDatas: any = {
      id: +new Date(),
      type: IPriceRequestElementOptionType.CUT,
      denomination: "",
      quantity: 1,
      price: null,
      unit: IPriceRequestElementOptionUnit.EURO_BY_TON,
      variantId: selectedVariantFormGroup.get("variantId").value,
      isNewVariantOption: true,
      unitSuffix: PriceRequestElementOptionUnit.EURO_BY_TON
    };
    const optionFormGroup: FormGroup = this._fb.group(newOptionDatas);
    this.updateOptionUnitSuffix(optionFormGroup);
    // rowAdditionnalDatas.push(this._fb.group(newOptionDatas)); // If we want to push at the end
    rowAdditionnalDatas.insert(0, optionFormGroup);
  }

  public updateCutOption = (index: string) => {
    const splittedIndex: string[] = index.split("_");
    const selectedTableFormData: IFormDataElements = this.tableFormDatas.find( table => +table.categoryId === +splittedIndex[0]);
    const selectedVariantFormGroup: FormGroup = (<FormGroup>selectedTableFormData.tableDatas.controls[+splittedIndex[1]]);
    const isCut: boolean = selectedVariantFormGroup.get("isCut").value;
    const rowAdditionnalDatas: FormArray = (<FormArray>selectedVariantFormGroup.get("rowAdditionnalDatas"));

    if (isCut) {
      if (rowAdditionnalDatas.controls.every(control => control.get("type").value != IPriceRequestElementOptionType.CUT)) {
        const newOptionDatas: any = {
          id: +new Date(),
          type: IPriceRequestElementOptionType.CUT,
          denomination: "",
          quantity: 1,
          price: null,
          unit: IPriceRequestElementOptionUnit.EURO_BY_UNIT,
          variantId: selectedVariantFormGroup.get("variantId").value,
          isNewVariantOption: true,
          unitSuffix: PriceRequestElementOptionUnit.EURO_BY_UNIT
        };
        const optionFormGroup: FormGroup = this._fb.group(newOptionDatas);
        this.updateOptionUnitSuffix(optionFormGroup);
        rowAdditionnalDatas.insert(0, optionFormGroup);
      }
    } else {
      const splicedIndexes: number[] = [];
      rowAdditionnalDatas.controls.forEach((control, ind) => {
        if (control.get("type").value == IPriceRequestElementOptionType.CUT) {
          if (!control.get("isNewVariantOption").value) {
            this._deletedVariantOptionIds.push(control.get("id").value);
          }
          splicedIndexes.push(ind);
        }
      });
      if (splicedIndexes.length > 0) {
        rowAdditionnalDatas.controls = rowAdditionnalDatas.controls.filter((control, ind) => !splicedIndexes.includes(ind));
      }
    }
  }

  public updateOption = (index: string) => {
    const splittedIndex: string[] = index.split("_");
    const selectedTableFormData: IFormDataElements = this.tableFormDatas.find( table => +table.categoryId === +splittedIndex[0]);
    const selectedVariantFormGroup: FormGroup = (<FormGroup>selectedTableFormData.tableDatas.controls[+splittedIndex[1]]);
    const option = selectedVariantFormGroup.get("option").value;
    const rowAdditionnalDatas: FormArray = (<FormArray>selectedVariantFormGroup.get("rowAdditionnalDatas"));
    const splicedIndexes: number[] = [];
    const newOption: any = {
      id: +new Date(),
      type: IPriceRequestElementOptionType.PROCESSING,
      denomination: "",
      quantity: 1,
      price: null,
      unit: IPriceRequestElementOptionUnit.EURO_BY_TON,
      variantId: selectedVariantFormGroup.get("variantId").value,
      isNewVariantOption: true,
      unitSuffix: PriceRequestElementOptionUnit.EURO_BY_TON
    };
    let needAdd: boolean = false;

    if (option == OPTION_LABELS.BLACK) {
      rowAdditionnalDatas.controls.forEach((control, ind) => {
        if (control.get("type").value == IPriceRequestElementOptionType.PROCESSING
          && (control.get("denomination").value == OPTION_DESCRIPTIONS[OPTION_LABELS.BLASTED] || control.get("denomination").value == OPTION_DESCRIPTIONS[OPTION_LABELS.PRIMARY_BLASTED])) {
          if (!control.get("isNewVariantOption").value) {
            this._deletedVariantOptionIds.push(control.get("id").value);
          }
          splicedIndexes.push(ind);
        }
      });
    } else if (option == OPTION_LABELS.BLASTED) {
      rowAdditionnalDatas.controls.forEach((control, ind) => {
        if (control.get("type").value == IPriceRequestElementOptionType.PROCESSING
          && control.get("denomination").value == OPTION_DESCRIPTIONS[OPTION_LABELS.PRIMARY_BLASTED]) {
          if (!control.get("isNewVariantOption").value) {
            this._deletedVariantOptionIds.push(control.get("id").value);
          }
          splicedIndexes.push(ind);
        }
      });
      if (rowAdditionnalDatas.controls.every(control => !(control.get("type").value == IPriceRequestElementOptionType.PROCESSING
        && control.get("denomination").value == OPTION_DESCRIPTIONS[OPTION_LABELS.BLASTED]))) {
          newOption.denomination = OPTION_DESCRIPTIONS[OPTION_LABELS.BLASTED];
          needAdd = true;
      }
    } else if (option == OPTION_LABELS.PRIMARY_BLASTED) {
      rowAdditionnalDatas.controls.forEach((control, ind) => {
        if (control.get("type").value == IPriceRequestElementOptionType.PROCESSING
          && control.get("denomination").value == OPTION_DESCRIPTIONS[OPTION_LABELS.BLASTED]) {
          if (!control.get("isNewVariantOption").value) {
            this._deletedVariantOptionIds.push(control.get("id").value);
          }
          splicedIndexes.push(ind);
        }
      });
      if (rowAdditionnalDatas.controls.every(control => !(control.get("type").value == IPriceRequestElementOptionType.PROCESSING
        && control.get("denomination").value == OPTION_DESCRIPTIONS[OPTION_LABELS.PRIMARY_BLASTED]))) {
          newOption.denomination = OPTION_DESCRIPTIONS[OPTION_LABELS.PRIMARY_BLASTED];
          needAdd = true;
      }
    }

    if (splicedIndexes.length > 0) {
      rowAdditionnalDatas.controls = rowAdditionnalDatas.controls.filter((control, ind) => !splicedIndexes.includes(ind));
    }
    if (needAdd) {
      const optionFormGroup: FormGroup = this._fb.group(newOption);
      this.updateOptionUnitSuffix(optionFormGroup);
      rowAdditionnalDatas.insert(0, optionFormGroup);
    }
  }

  private updateOptionUnitSuffix(optionFormGroup: FormGroup) {
    optionFormGroup.get("unit").valueChanges.subscribe( result => {
      optionFormGroup.get("unitSuffix").patchValue(PriceRequestElementOptionUnit[result]);
    });
  }

  public deleteVariantOption = (index: string) => {
    // 0: categoryId - 1: rowIndex - 2: rowAdditionnalDatasIndex
    const splittedIndex: string[] = index.split("_");
    const selectedTableFormData: IFormDataElements = this.tableFormDatas.find( table => +table.categoryId === +splittedIndex[0]);
    const selectedVariantFormGroup: FormGroup = (<FormGroup>selectedTableFormData.tableDatas.controls[+splittedIndex[1]]);
    const rowAdditionnalDatas: FormArray = (<FormArray>selectedVariantFormGroup.get("rowAdditionnalDatas"));
    const optionDataValue: any = rowAdditionnalDatas.controls[+splittedIndex[2]].value;
    if (!optionDataValue.isNewVariantOption) {
      this._deletedVariantOptionIds.push(optionDataValue.id);
    }
    rowAdditionnalDatas.controls.splice(+splittedIndex[2], 1);
  }

  /* SAVE METHODS */
  public confirmModal() {
    const validAdditionnalCostForm: boolean = this.additionnalCostFormData.controls.every( addFormControlData => {
      return addFormControlData.valid
      || (!addFormControlData.valid
      && (
        (addFormControlData.get("alwaysPrinted").value && !addFormControlData.get("price").value)
        || !addFormControlData.get("alwaysPrinted").value && !addFormControlData.get("hasSupplierResponse").value
      ));
    });

    if (validAdditionnalCostForm) {
      this._supplierOfferUpdateDatas = {
        supplierReference: this._formGroup.get("supplierReference").value,
        remark: this._formGroup.get("remark").value,
        elements: [],
        additionnalCosts: this.additionnalCostFormData.value
          .filter( addCost => {
            return (
              (!addCost.alwaysPrinted && addCost.hasSupplierResponse)
              || (addCost.alwaysPrinted && addCost.price)
            );
          })
          .map( addCost => {
            return {
              id: addCost.isNew ? null : addCost.supplierOfferAdditionnalCostId,
              price: addCost.price,
              inputPrice: addCost.price,
              denomination: addCost.denomination,
              quantity: addCost.quantity,
              unit: addCost.unit,
              supplierOfferId: this._supplierOffer.id,
              priceRequestAdditionnalCostId: addCost.additionnalCostid,
              priceRequestAdditionnalCost: addCost.alwaysPrinted ? null : {
                type: addCost.type,
                denomination: addCost.denomination,
                quantity: addCost.quantity,
                unit: addCost.unit,
                priceRequestId: this._priceRequest.id
              }
            };
          }),
        deletedVariantIds: this._deletedVariantIds,
        deletedVariantOptionIds: this._deletedVariantOptionIds,
        deletedSupplierOfferElementOptionIds: [],
        deletedAdditionnalCostIds: this._deletedAdditionnalCostIds
      };

      this.checkElementToSave(this.tableFormDatas.map( tableData => tableData.tableDatas.value).flat());
      this.updateSupplierOffer();
    } else {
      // TODO check if need to touch all the form
      this.additionnalCostFormData.controls.filter( addFormControlData => {
        return (
          (addFormControlData.get("supplierOfferAdditionnalCostId").value
            && (addFormControlData.get("alwaysPrinted").value && addFormControlData.get("price").value))
          || (!addFormControlData.get("alwaysPrinted").value
            && (addFormControlData.get("price").value || addFormControlData.get("quantity").value))
        );
      }).forEach(formGroup => this.markFormGroupTouched(<FormGroup>formGroup));
      this.displayErrorMessage();
    }
  }

  private checkElementToSave(formValue: any[]) {

    let supplierOfferElement: ISupplierOfferElementInpdate;
    formValue.forEach(element => {
      if ( element.price || element.id || element.variantId) {
        supplierOfferElement = {
          id: element.id,
          price: element.price,
          deliveryDate: element.deliveryDate,
          supplierOfferId: element.supplierOfferId,
          priceRequestElementId: element.priceRequestElementId,
          variantId: element.isNewVariant ? null :  element.variantId,
          options: [],
          unit: EnumUnitValue[element.unitSuffix]
        };
        if ( element.variantId ) {
          // check wich option is selected
          supplierOfferElement.variant = {
            id: element.isNewVariant ? null : element.variantId,
            reference: element.reference,
            denomination: element.denomination,
            matterRef: element.matterRef,
            quantity: element.quantity,
            weight: element.weight,
            format: element.format ? element.format.toString() : null,
            isBlack: element.option === OPTION_LABELS.BLACK,
            isBlasted: element.option === OPTION_LABELS.BLASTED,
            isPrimaryBlasted: element.option === OPTION_LABELS.PRIMARY_BLASTED,
            isCut: element.isCut,
            isEn1090: element.isEn1090,
            quantityUnit: element.quantityUnit,
            thickness: element.thickness,
            length: element.length,
            width: element.width,
            remark: element.remark,
            supplyCategoryId: element.supplyCategoryId,
            matterId: element.matterId,
            elementId: element.elementId,
            options: element.rowAdditionnalDatas.map( option => {
              const optionData: IVariantOptionInpdate = {
                ...option,
                price: option.price ? option.price : 0,
                id: (<any>option).isNewVariantOption ? null : option.id,
              };
              delete (<any>optionData).unitSuffix;
              delete (<any>optionData).__typename;
              delete (<any>optionData).isNewVariantOption;
              return optionData;
            })
          };
        } else {

          this._supplierOfferUpdateDatas.deletedSupplierOfferElementOptionIds = this._supplierOfferUpdateDatas.deletedSupplierOfferElementOptionIds.concat(
            element.rowAdditionnalDatas.filter(option => !option.price && option.id).map( option => option.id)
          );
          supplierOfferElement.options = element.rowAdditionnalDatas.filter(option => option.price)
            .map( option => {
              return {
                id: option.id,
                price: option.price,
                supplierOfferElementId: element.id,
                priceRequestElementOptionId: option.priceRequestElementOptionId
              };
            });
          supplierOfferElement.priceRequestElement = {
            weight: element.weight,
            unit: EnumUnitValue[element.unitSuffix]
          };
        }
        this._supplierOfferUpdateDatas.elements.push(supplierOfferElement);
      }
    });
  }

  protected save(data: ISupplierOffer): void {
    const objData = {
      confirm: true,
      data
    };
    this.afterClosed.next(objData);
  }

  /**
   * @description Update the supplier offer on the backend, displays visual error if not
   * @author Quentin Wolfs
   * @private
   * @memberof ModalEncodeSupplierOfferComponent
   */
  private updateSupplierOffer(): void {
    this._priceRequestsQueriesSrv.updateSupplierOffer(this._data.data.supplierOffer.id, this._supplierOfferUpdateDatas, this._data.data.priceRequestId).subscribe(result => {
      const resultData: any = result.data;
      if (resultData && resultData.updateSupplierOffer) {
        this._snackBar.open(this._data.title, "La réponse fournisseur a été mise à jour", "success", 5000);

        // Update was successfull, closing the modal with updated data
        this.save(resultData.updateSupplierOffer);
        this._modalService.closeModal();
      } else {
        this._snackBar.open(this._data.title, "La réponse fournisseur a été mise à jour mais une erreur est survenue", "warning", 5000);
      }
    }, error => {
      this._snackBar.openSnackBarError(this._data.title, "La réponse fournisseur n'a pas été mise à jour", error);
    });
  }

}
