import { Injectable } from "@angular/core";
import { Apollo } from "apollo-angular";
import { PURCHASE_ORDER_ELEMENT_BDC } from "./purchase-order-element.queries";


@Injectable({
  providedIn: "root"
})
export class PurchaseOrderElementQueriesService {

  constructor(private _apollo: Apollo) { }

  public filterPurchaseOrderElement(search: string,filter,pagination) {
    return this._apollo.query({
      query: PURCHASE_ORDER_ELEMENT_BDC,
      variables: {
        search,
        filter,
        pagination
      },
      fetchPolicy: "no-cache"
    });
  }
}
