/*
 * @Author: Kevin Palade
 * @Date: 2018-09-08 14:45:55
 * @Last Modified by:   Kevin Palade
 * @Last Modified time: 2018-09-08 14:45:55
 */
import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { AuthService } from "@lib/auth/auth.service";
import { Router } from "@angular/router";
import { EditedFormService } from "@lib/edited-form/edited-form.service";
import { PermissionService } from "app/facade/services/permission.service";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.css"]
})
export class HeaderComponent implements OnInit {
  public userEmail: string = "Undefined";
  public hasPermissionQ: any;
  public userGroup: any;

  @Output() onLogoutCancelled: EventEmitter<any> = new EventEmitter<any>();

  public links: Array<{ url: string, label: string, category: string }> = [
    { url: "/users", label: "Utilisateurs", category: "users" },
    { url: "/quote", label: "Devis", category: "quotations" },
    { url: "/project", label: "Projets", category: "projects" },
    { url: "/price-request", label: "Demandes de prix", category: "price-requests" },
    { url: "/purchase-order", label: "Bons de commande", category: "purchase-orders" },
    { url: "/elements", label: "Liste articles", category: "elements" }
  ];

  public linksRight: Array<{ url: string, label: string, category: string }> = [
    { url: "/supplier", label: "Fournisseurs", category: "suppliers" },
    { url: "/catalog", label: "Catalogue", category: "catalog" },
  ];

  constructor(private _authSrv: AuthService,
              private _authService: AuthService,
              private _editedFormSrv: EditedFormService,
    private _permissionService: PermissionService) {

  }

  ngOnInit() {
    const userData = JSON.parse(localStorage.getItem('userData'));
    this.userEmail = userData ? userData.username : "undefined";
    this.links = this.links.filter((link) => this._permissionService.hasPermission(this._authSrv.isUserGroup(), link.category, "read"));
    this.linksRight = this.linksRight.filter((link) => this._permissionService.hasPermission(this._authSrv.isUserGroup(), link.category, "read"))
  }

  public logout(event: Event) {
    if (event) { event.preventDefault(); }
    if (this._editedFormSrv.EditedForm) {
      this.onLogoutCancelled.emit(true);
    } else {
      this._authService.logout();
    }
  }
}
