/*
 * @Author: Kevin Palade
 * @Date: 2018-09-08 14:51:52
 * @Last Modified by: Kevin Palade
 * @Last Modified time: 2018-09-21 13:34:50
 */
import { Component, OnInit, Input } from "@angular/core";
import { SnackService } from "../services/snack.service";

@Component({
  selector: "app-snack",
  templateUrl: "./snack.component.html",
  styleUrls: ["./snack.component.css"]
})
export class SnackComponent implements OnInit {
  @Input("color") public color: string;
  @Input("snackUuid") public snackUuid: number;
  @Input("delay") public delay: number;
  private _interval: any;
  private _generatedClass: string = ""; // Used in html for applying the class
  private _average: any; // Used in html for display the loading bar
  constructor(private _snackService: SnackService) { }

  /**
   * @description generate class based on input
   * @author Kevin Palade
   * @memberof SnackComponent
   */
  ngOnInit() {
    this._generatedClass += this.color ? " snack_color_" + this.color : "";
    this.setAverageInterval();
  }

  /**
   * @description Set an interval for calculate the progress average of the snack
   * @author Kevin Palade
   * @private
   * @memberof SnackComponent
   */
  private setAverageInterval() {
    let count = 0;
    this._interval = setInterval(() => {
      count += 100;
      this._average = (count / this.delay) * 100;
      if ( count >= this.delay ) {
        clearInterval(this._interval);
      }
    }, 100);
  }

}
