import { Injectable } from "@angular/core";
import { Apollo } from "apollo-angular";
import { ADD_SMTP_CONFIG, GET_SMTP, UPDATE_SMTP_CONFIG } from "./smtp-config_queries";


@Injectable()
export class SmtpConfigQueriesService {

constructor(private _apollo: Apollo) { }

public addSmtpConfig(smtp) {
  return this._apollo.mutate({
      mutation: ADD_SMTP_CONFIG,
      variables : {
          smtp: smtp,
      }
  });
}

public updateSmtpConfig(smtp,id) {
  return this._apollo.mutate({
      mutation: UPDATE_SMTP_CONFIG,
      variables : {
          smtp,
          id
      }
  });
}

public getSmtp(id : number) {
  return this._apollo.query({
      query: GET_SMTP,
      variables: {id: id},
      fetchPolicy: "network-only"
  });
}

}
