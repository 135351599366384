/*
 * @Author: Kevin Palade
 * @Date: 2018-09-08 14:31:14
 * @Last Modified by: Kevin Palade
 * @Last Modified time: 2018-09-08 14:31:48
 */

/* COMPONENTS */
export * from "./box-info/box-info.component";
export * from "./button/button.component";
export * from "./max-text/max-text.component";
export * from "./table-head/table-head.component";
export * from "./table-mode/table-mode.component";
export * from "./tabs/tabs.component";
export * from "./labeled-input/labeled-input.component";
export * from "./load-more/load-more.component";