import { Element } from "./elements/element.base";
import { ITicketElement } from "app/presentationnal/organisms";
import { CustomElement } from "./elements/customs/custom.base";
import { BehaviorSubject } from "rxjs";

export const SQUARE_MM_TO_SQUARE_M = 1 / 1000000;
export const MM_TO_M = 1 / 1000;
export const SQUARE_MM_TO_SQUARE_CM = 1 / 100;
export const SQUARE_CM_TO_SQUARE_M = 1 / 10000;
export const SQUARE_CM_TO_SQUARE_MM = 100;
export const CUBE_M_TO_L = 1000;
export const KG_TO_T = 1 / 1000;

export interface IAction<T extends Element, U> {
    element: T;
    properties: U;
}

export type QuoteElementType = "STANDARD" | "CUSTOM" | "OPERATION" | "ASSEMBLAGE" | "FINISH" | "OPTION";

export function quoteTotal(elements: ITicketElement[], value: "WEIGHT" | "AREA") {
    let result: number = 0;

    elements.forEach(element => {
        const curElement: QuoteElementBase = element.element;
        if (curElement.Type == "CUSTOM" || curElement.Type == "STANDARD") {
            const custom: Element = curElement as Element;
            const nextValue: number = value == "WEIGHT" ? custom.TotalWeight : custom.TotalArea;
            result += (nextValue * custom.TotalQuantity);
        }

        if (element.children && element.children.length) {
            result += quoteTotal(element.children.map(c => ({ element: c, children: null })), value);
        }
    });

    return result;
}

export function quoteCertificates(elements: ITicketElement[]) {
    const result: Set<string> = new Set<string>();

    elements.forEach(element => {
        const curElement: QuoteElementBase = element.element;
        if (curElement.Type == "CUSTOM") {
            const custom: CustomElement = curElement as CustomElement;
            const certificate: string = `${(custom.Matter.en1090Name || custom.Matter.name)} (${custom.Thickness}mm)`;
            result.add(certificate);
        } else if (curElement.Type == "STANDARD") {
            const standard: Element = curElement as Element;
            const certificate: string = `${standard.Reference} (${standard.Matter.en1090Name || standard.Matter.name})`;
            result.add(certificate);
        }

        if (element.children && element.children.length) {
            Array.from(quoteCertificates(element.children.map(c => ({ element: c, children: null })))).forEach(certificate => {
                result.add(certificate);
            });
        }
    });
    return result;
}

export function calculateNewValueWithCuttinAngle( value: number, angle: number): number {
  return (value / Math.sin(angle * Math.PI / 180));
}

export abstract class QuoteElementBase {

    private _quantity: number;
    private _parentQuantity: number;
    private _isVisible: boolean;
    private _customCost?: number;
    private _customName?: string;
    private _unitaryAdditionalCost: number;
    private _globalAdditionalCost: number;

    private _remarks?: string;

    protected $quantityUpdated: BehaviorSubject<number>;

    public constructor(public readonly name: string) {
        this.$quantityUpdated = new BehaviorSubject<number>(1);
        this._isVisible = true;
        this._quantity = 1;
        this._parentQuantity = 1;
        this._customCost = null;
        this._customName = null;
        this._unitaryAdditionalCost = 0;
        this._globalAdditionalCost = 0;
    }

    public abstract get CalculatedPrice(): number;

    public abstract get Type(): QuoteElementType;

    public get Price(): number {
        return this.CustomCost ? this.CustomCost : this.UnitaryAdditionalCost + (this.GlobalAdditionalCost / this.TotalQuantity) + this.CalculatedPrice;
    }

    public get Quantity(): number {
        return this._quantity || 1;
    }

    public set Quantity(value: number) {
        this._quantity = value;
        this.$quantityUpdated.next(this.TotalQuantity);
    }

    public get ParentQuantity(): number {
        return this._parentQuantity || 1;
    }

    public set ParentQuantity(value: number) {
        this._parentQuantity = value;
        this.$quantityUpdated.next(this.TotalQuantity);
    }

    public get TotalQuantity(): number {
        return this.ParentQuantity * this.Quantity;
    }

    public get Visible(): boolean { // Visible sur le ticket
        return this._isVisible;
    }

    public set Visible(value: boolean) {
        this._isVisible = value;
    }

    public get CustomCost(): number { // Modifié sur le ticket
        return this._customCost != null ? this._customCost : 0;
    }

    public set CustomCost(value: number) {
        this._customCost = value;
    }

    public get CustomName(): string { // Modifié sur le ticket
        return this._customName;
    }

    public set CustomName(value: string) {
        this._customName = value;
    }

    public get Remarks(): string {
        return this._remarks;
    }

    public set Remarks(value: string) {
        this._remarks = value;
    }

    public get UnitaryAdditionalCost(): number {
        return this._unitaryAdditionalCost != null ? this._unitaryAdditionalCost : 0;
    }

    public set UnitaryAdditionalCost(value: number) {
        this._unitaryAdditionalCost = value;
    }

    public get GlobalAdditionalCost(): number {
        return this._globalAdditionalCost != null ? this._globalAdditionalCost : 0;
    }

    public set GlobalAdditionalCost(value: number) {
        this._globalAdditionalCost = value;
    }

}