import { Assemblage } from "../../assemblages/assemblage.base";
import { Operation } from "../../operations/operation.base";
import { Element } from "../element.base";
import { QuoteElementType } from "../../base";
import { Observable, Subject } from "rxjs";

export interface AdditionalComputing {
    name: string;
    origin: Operation<any, any, any> | Assemblage<any, any> | Element;
}

export abstract class CustomElement extends Element {

    public $ready: Subject<boolean>;

    protected _additionalComputings: { [name: string]: AdditionalComputing };

    public abstract get Thickness(): number;

    public constructor(name: string) {
        super(name);
        this._additionalComputings = {};
        this.$ready = new Subject<boolean>();
        this.$quantityUpdated.subscribe(totalQuantity => {
            Object.keys(this._additionalComputings).forEach(key => {
                this._additionalComputings[key].origin.ParentQuantity = totalQuantity;
            });
        });
    }

    public get AdditionalComputings(): { [name: string]: AdditionalComputing } {
        return this._additionalComputings;
    }

    protected setAdditionalComputings(name: string, origin: Operation<any, any, any> | Assemblage<any, any> | Element) {
        this._additionalComputings[name] = {
            name,
            origin
        };
    }

    public get Type(): QuoteElementType {
        return "CUSTOM";
    }
}