import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { USER_GROUPS } from '../../../../facade/enums/usergroups.enum';
import { Users } from '../../../../facade/interfaces/users';
import { UsersQueriesService } from '../../../../facade/queries/users/users-queries.service';
import { ISelectOption } from '../../../../presentationnal/atoms/inputs/select-input/selectOptions';
import { SnackService } from '../../../../presentationnal/organisms/snack-bar/services/snack.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.css']
})
export class AddUserComponent implements OnInit {

  private _editUserSub: Subscription;
  public formGroup: FormGroup;
  public userGroupOptions: ISelectOption[] = [];
  public _objData: Users;
  public isAdmin : boolean = false;

  constructor(protected _fb: FormBuilder, private _userQueriesSrv: UsersQueriesService,private snackBar: SnackService,) { }

  ngOnInit() {
    this.userGroupOptions = [
      {value: USER_GROUPS.WORKSHOP, label: "Atelier"},
      {value: USER_GROUPS.DESIGN_OFFICE, label: "Bureau d'étude"},
      {value: USER_GROUPS.QUOTER, label: "Deviseur"},
      {value: USER_GROUPS.ADMINISTRATOR, label: "Administrateur"},
    ];
    this.initForm();
  }

  /**
   * @description Init the basics form group
   * @author Marie Claudia
   * @private
   * @memberof EditUserComponent
   */
     private initForm() {
      this.formGroup = this._fb.group({
        username : ["", Validators.required],
        firstname : ["", Validators.required],
        lastname: ["", Validators.required],
        userGroup: [this.userGroupOptions[0].value, Validators.required],
        password: ["", Validators.required],
        confirme_password:["", Validators.required],
      });
    }

  /**
   * @description Processus to create User
   * @author Marie Claudia
   * @memberof AddUserComponent
   */
   public createUser() {
    let group = this.formGroup.get("userGroup").value;
    if(group === USER_GROUPS.ADMINISTRATOR){
      this.isAdmin = true;
    }

    if (this.formGroup && this.formGroup.valid) {
      let pwd = this.formGroup.get("password").value;
      let confirm_pwd = this.formGroup.get("confirme_password").value;
      if(pwd === confirm_pwd){
        const user = {
          username : this.formGroup.get("username").value,
          password:this.formGroup.get("password").value,
          firstname : this.formGroup.get("firstname").value,
          lastname : this.formGroup.get("lastname").value,
          userGroup : this.formGroup.get("userGroup").value,
          isAdmin: this.isAdmin,
        };

        this._userQueriesSrv.addUser(user).subscribe(result => {
          const user = result.data["createQuote"];
          this.snackBar.open("Ajout d'utilisateur", "L'Utilisateur a été créé", "success", 5000);
        });
      }else{
        this.snackBar.open("Ajout d'utilisateur", "Verifier le mot de passe", "warning", 5000);
      }

    } else {
       this.snackBar.open("Ajout d'utilisateur", "Formulaire incomplet", "warning", 5000);
    }
  }


  /**
   * @description Save user
   * @author Marie Claudia
   * @memberof AddUserComponent
   */
   public save() {
      this.createUser();
  }
}
