import { Option } from "./option.base";
import { ITicketElement } from "app/presentationnal/organisms";

export interface StudyProperty {
    hourQuantity?: number;
    hourlyRate?: number;
    weightCost?: number;
    totalWeight?: number;
    isComputedByWeight: boolean;
}

export class Study extends Option<StudyProperty> {

    public constructor(values: StudyProperty,
      protected ticket: ITicketElement[]) {
        super(values, ticket);
    }

    public get HourQuantity(): number {
        return this.values.hourQuantity;
    }

    public set HourQuantity(value: number) {
        this.values.hourQuantity = value;
    }

    public get HourlyRate(): number {
        return this.values.hourlyRate;
    }

    public set HourlyRate(value: number) {
        this.values.hourlyRate = value;
    }

    public get WeightCost(): number {
        return this.values.weightCost;
    }

    public set WeightCost(value: number) {
        this.values.weightCost = value;
    }

    public get IsComputedByWeight(): boolean {
        return this.values.isComputedByWeight;
    }

    public set IsComputedByWeight(value: boolean) {
        this.values.isComputedByWeight = value;
    }

    public get CalculatedPrice(): number {
        return this.IsComputedByWeight ? this.getPricePerWeight() : this.getPriceByHour();
    }

    private getPriceByHour(): number {
        return this.HourQuantity * this.HourlyRate;
    }

    private getPricePerWeight(): number {
        return (this.quoteTotalWeight || 1) * this.WeightCost;
    }
}