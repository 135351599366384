import { Injectable } from "@angular/core";
import { AmalgamService } from "./amalgam.service";
import { FormBuilder } from "@angular/forms";
import { deepClone } from "@lib/misc/clone";
import { MultiSupplierOfferAdditionnalCostType, UniqueSupplierOfferAdditionnalCostType, EnumAdditionnalCostUnit } from "../enums/supplier-offer-additionnal-cost-type.enum";
import { ILinkedMultiTableGroupData, IMultiTableGroup, IMultiTableGroupData } from "../interfaces/multi-table-group.interface";
import { EnumSupplyTableLinkedSize } from "../enums/supply-table-linked-size.enum";
import { ElementUnitConfig } from "../configs/price-request-unit.config";
import { PriceRequestElementOptionUnit, PriceRequestElementOptionType } from "../enums/price-request-element-option-datas.enum";
import { IPurchaseOrderElement, IPurchaseOrderElementOption, IPurchaseOrderAdditionnalCost } from "../interfaces/purchase-order.interface";
import { PURCHASE_ORDER_TABLE_CONFIG, PURCHASE_ORDER_MULTI_TABLE_GROUP_CLASS, NEW_PURCHASE_ORDER_MULTI_TABLE_GROUP_CLASS } from "../configs/purchase-order.config";
import * as moment from "moment";
import { EnumElementUnitSelectValue, ElementUnitCategory, EnumQuantityUnitSelectValue } from "../enums/element-unit-category.enum";
import { Subject } from "rxjs";

@Injectable({
  providedIn: "root"
})
export class PurchaseOrderTableService {
  private _updatePriceRequestElementRemark: Subject<any> = new Subject<any>();
  private _updatePriceRequestElementFormat: Subject<any> = new Subject<any>();
  private _updatePriceRequestElementDenomination: Subject<any> = new Subject<any>();
  private _updatePriceRequestElementQuantity: Subject<any> = new Subject<any>();
  private _updatePriceRequestElementMatiere: Subject<any> = new Subject<any>();
  private _updatePriceRequestElementWeight: Subject<any> = new Subject<any>();
  private _updatePriceRequestElementWidth: Subject<any> = new Subject<any>();
  private _updatePriceRequestElementLength: Subject<any> = new Subject<any>();
  private _updatePriceRequestElementThickness: Subject<any> = new Subject<any>();
  private _updatePriceRequestElementQuantityUnit: Subject<any> = new Subject<any>();
  private _updatePriceRequestElementEn1090: Subject<any> = new Subject<any>();
  private _updatePriceRequestElementOption: Subject<any> = new Subject<any>();
  private _updatePriceRequestElementPrice: Subject<any> = new Subject<any>();
  private _updatePriceRequestElementDeliveryDate: Subject<any> = new Subject<any>();


  constructor(
    private _amalgamService: AmalgamService,
    protected _fb: FormBuilder) { }


  private checkConfigToUse(element: IPurchaseOrderElement): { classToCheck: string, tableDataToUse: string } {
    let classToCheck: string;

    // Determine the type of config based on PurchaseOrderElement composition
    if (!!element.format) {
      classToCheck = PURCHASE_ORDER_MULTI_TABLE_GROUP_CLASS.LARGE;
    } else {
      if (!!element.supplierOfferElementId || !!element.supplyCategoryId) {
        if (ElementUnitConfig.categories[ElementUnitCategory.PLATES].includes(+element.supplyCategoryId)) {
          classToCheck = PURCHASE_ORDER_MULTI_TABLE_GROUP_CLASS.PLATE;
        } else {
          classToCheck = PURCHASE_ORDER_MULTI_TABLE_GROUP_CLASS.SHORT;
        }
      } else {
        classToCheck = PURCHASE_ORDER_MULTI_TABLE_GROUP_CLASS.CUSTOM;
      }
    }

    return {
      classToCheck,
      tableDataToUse: classToCheck.toLowerCase(),
    };
  }


  public formatMultiTableData(data: IPurchaseOrderElement[], additionnalCostsData: IPurchaseOrderAdditionnalCost[], linkedDatas: ILinkedMultiTableGroupData[], purchaseOrderSend: boolean = false): IMultiTableGroup {
    const multiTable: IMultiTableGroup = {
      elements: [],
      linkedData: linkedDatas,
      supplyTableLinkedSize: EnumSupplyTableLinkedSize.two
    };
    let multiTableElementIndex: number,
      usedConfig: { classToCheck: string, tableDataToUse: string },
      isLargeTableElement: boolean,
      categoryId: number;

    data.forEach(element => {
      categoryId = element.supplyCategory ? element.supplyCategory.parentSupplyCategoryId : element.supplyCategoryId;
      usedConfig = this.checkConfigToUse(element);
      isLargeTableElement = element.format !== null;
      if (categoryId) {
        multiTableElementIndex = multiTable.elements.findIndex(tableData => tableData.categoryId === categoryId && tableData.tableClass === usedConfig.classToCheck);
      } else {
        multiTableElementIndex = multiTable.elements.findIndex(tableData => tableData.tableClass === usedConfig.classToCheck);
      }
      this.setMultiTableDatas(multiTable.elements, element, multiTableElementIndex, usedConfig.tableDataToUse, isLargeTableElement, purchaseOrderSend);
      linkedDatas.forEach(link => {
        this.setMultiTableDatas(link.elements, element, multiTableElementIndex, "linked", isLargeTableElement, purchaseOrderSend);
      });
    });

    // Set Additionnal cost table
    this.formatMultiTableAdditionnalCostData(additionnalCostsData, linkedDatas, multiTable);
    // Set elements keys
    let elementKey: number = 1,
      addionalOptionKey: number = 1;
    multiTable.elements.forEach(element => {
      element.datas.forEach(dataElement => {
        if (element.newTableClass !== NEW_PURCHASE_ORDER_MULTI_TABLE_GROUP_CLASS.ADDITIONNAL_COST) {
          dataElement.keyLigne = `#${elementKey++}`;
        } else {
          dataElement.keyLigne = `#${addionalOptionKey++}`;
        }
      });
    });

    return multiTable;
  }
  /**
   * @description set multi table data & check which class to use, which data config to use & set the order of tables
   * @author Lainez Eddy
   * @private
   * @param {IMultiTableGroupData[]} multiTable It's the existing data we need to fill
   * @param {IPriceRequestElement} element It's the principal element datas
   * @param {number} index Allow to know if we can get an existing multiTable or if we need to create a new
   * @param {string} tableDataToUse The type of setter we need to use (it's a config)
   * @param {boolean} isLargeTableElement Define if we need to push or unshift new table
   * @param {any} linkedDataSet Data for linked table
   * @memberof MultiTableGroupService
   */
  private setMultiTableDatas(multiTable: IMultiTableGroupData[], element: IPurchaseOrderElement, index: number, tableDataToUse: string, isLargeTableElement: boolean, purchaseOrderSend: boolean = false) {
    const createTableDatas = index === -1;
    let elementTemp: IMultiTableGroupData;
    if (createTableDatas) {
      elementTemp = deepClone(PURCHASE_ORDER_TABLE_CONFIG[tableDataToUse]);
      elementTemp.categoryId = element.supplyCategory ? element.supplyCategory.parentSupplyCategoryId : element.supplyCategoryId;
      const elementUnitCategory: any = Object.keys(ElementUnitConfig.categories).find(elementCat => ElementUnitConfig.categories[elementCat].includes(elementTemp.categoryId));
      elementTemp.unit = !!elementUnitCategory ? ElementUnitConfig.units[elementUnitCategory] : ElementUnitConfig.units.default;
    } else {
      elementTemp = multiTable[index];
    }
    switch (tableDataToUse) {
      case "large":
      case "plate":
      case "short":
        elementTemp.datas.push(this.setElementTableData(element));
        break;
      case "custom":
        elementTemp.datas.push({
          ...this.setElementTableData(element),
          isCustomElement: true,
          canBeUpdate: !purchaseOrderSend,
          customElementDatas: element
        });
        break;
      case "linked":
        // ADD Linked Element Data for supplier
        const tempDataToAdd: any[] = [{
          id: element.id,
          price: element.price,
          deliveryDate: element.deliveryDate ? moment.unix(+element.deliveryDate).format("DD/MM/YYYY") : null,
          quantity: element.quantity,
          unit: EnumElementUnitSelectValue[element.unit]
        }];
        // ADD Linked Element Options Datas for supplier
        if (element.options) {
          element.options.forEach(option => {
            tempDataToAdd.push(this.setLinkedTableDatasOptions(option));
          });
        }
        tempDataToAdd[0].isFirstGroupData = true;
        elementTemp.datas = elementTemp.datas.concat(tempDataToAdd);
        if (elementTemp.config.find(config => config.key === "price") && !purchaseOrderSend) {
          elementTemp.config.find(config => config.key === "price").clickFct = this.updateElementPrice;
        }
        if (elementTemp.config.find(config => config.key === "deliveryDate") && !purchaseOrderSend) {
          elementTemp.config.find(config => config.key === "deliveryDate").clickFct = this.updateElementDeliveryDate;
        }
        break;
      default:
        break;
    }

    if (tableDataToUse !== "linked") {
      if (createTableDatas) {
        if (elementTemp.config.find(config => config.key === "format") && !purchaseOrderSend) {
          elementTemp.config.find(config => config.key === "format").clickFct = this.updateElementFormat;
        }
        if (elementTemp.config.find(config => config.key === "remark")) {
          elementTemp.config.find(config => config.key === "remark").clickFct = this.updateElementRemark;
        }
        if (elementTemp.config.find(config => config.key === "denomination") && !purchaseOrderSend) {
          elementTemp.config.find(config => config.key === "denomination").clickFct = this.updateElementDenomination;
        }
        if (elementTemp.config.find(config => config.key === "quantity") && !purchaseOrderSend) {
          elementTemp.config.find(config => config.key === "quantity").clickFct = this.updateElementQuantity;
        }
        if (elementTemp.config.find(config => config.key === "matterRef") && !purchaseOrderSend) {
          elementTemp.config.find(config => config.key === "matterRef").clickFct = this.updateElementMatiere;
        }
        if (elementTemp.config.find(config => config.key === "weight") && !purchaseOrderSend) {
          elementTemp.config.find(config => config.key === "weight").clickFct = this.updateElementWeight;
        }
        if (elementTemp.config.find(config => config.key === "width") && !purchaseOrderSend) {
          elementTemp.config.find(config => config.key === "width").clickFct = this.updateElementWidth;
        }
        if (elementTemp.config.find(config => config.key === "length") && !purchaseOrderSend) {
          elementTemp.config.find(config => config.key === "length").clickFct = this.updateElementLength;
        }
        if (elementTemp.config.find(config => config.key === "thickness") && !purchaseOrderSend) {
          elementTemp.config.find(config => config.key === "thickness").clickFct = this.updateElementThickness;
        }
        if (elementTemp.config.find(config => config.key === "quantityUnit") && !purchaseOrderSend) {
          elementTemp.config.find(config => config.key === "quantityUnit").clickFct = this.updateElementQuantityUnit;
        }
        if (elementTemp.config.find(config => config.key === "isEn1090") && !purchaseOrderSend) {
          elementTemp.config.find(config => config.key === "isEn1090").clickFct = this.updateElementEn1090;
        }
        if (elementTemp.config.find(config => config.key === "option") && !purchaseOrderSend) {
          elementTemp.config.find(config => config.key === "option").clickFct = this.updateElementOptions;
        }
      }
    }

    if (createTableDatas) {
      if (isLargeTableElement) {
        multiTable.unshift({ ...elementTemp });
      } else {
        multiTable.push({ ...elementTemp });
      }
    }
  }

  private updateElementRemark = (data: any) => {
    this._updatePriceRequestElementRemark.next(data);
  }

  private updateElementFormat = (data: any) => {
    this._updatePriceRequestElementFormat.next(data);
  }

  private updateElementDenomination = (data: any) => {
    this._updatePriceRequestElementDenomination.next(data);
  }

  private updateElementQuantity = (data: any) => {
    this._updatePriceRequestElementQuantity.next(data);
  }

  private updateElementMatiere = (data: any) => {
    this._updatePriceRequestElementMatiere.next(data);
  }

  private updateElementWeight = (data: any) => {
    this._updatePriceRequestElementWeight.next(data);
  }

  private updateElementWidth = (data: any) => {
    this._updatePriceRequestElementWidth.next(data);
  }

  private updateElementLength = (data: any) => {
    this._updatePriceRequestElementLength.next(data);
  }

  private updateElementThickness = (data: any) => {
    this._updatePriceRequestElementThickness.next(data);
  }

  private updateElementQuantityUnit = (data: any) => {
    this._updatePriceRequestElementQuantityUnit.next(data);
  }

  private updateElementEn1090 = (data: any) => {
    this._updatePriceRequestElementEn1090.next(data);
  }

  private updateElementOptions = (data: any) => {
    this._updatePriceRequestElementOption.next(data);
  }

  private updateElementPrice = (data: any) => {
    this._updatePriceRequestElementPrice.next(data);
  }

  private updateElementDeliveryDate = (data: any) => {
    this._updatePriceRequestElementDeliveryDate.next(data);
  }

  // TODO: traitment for option
  private setLinkedTableDatasOptions(option: IPurchaseOrderElementOption) {
    const dataToReturn: any = {
      id: option.id,
      price: option.price,
      isFirstGroupData: false,
      unit: PriceRequestElementOptionUnit[option.unit]
    };
    return dataToReturn;
  }


  private setElementTableData(element: IPurchaseOrderElement): any {
    return {
      keyLigne: `#`,
      id: element.id,
      quantity: element.quantity,
      denomination: element.denomination,
      matterRef: element.matterRef,
      option: this._amalgamService.getOptionLabel(element),
      format: element.format,
      isEn1090: element.isEn1090,
      quantityUnit: EnumQuantityUnitSelectValue[element.quantityUnit] || element.quantityUnit,
      thickness: element.thickness,
      length: element.length,
      width: element.width,
      remark: element.remark,
      weight: element.weight,
      isReadonly: true,
      isPrinted: element.isPrinted,
      // TODO: GET OPTIONS FROM POSSIBLE ELEMENT BY SUPPLIER
      elementAdditionnalData: element.options ? element.options.map(option => {
        return {
          id: option.id,
          quantity: option.quantity,
          denomination: option.denomination,
          type: option.type,
          labelType: PriceRequestElementOptionType[option.type],
          unit: option.unit
        };
      }) : []
    };
  }

  public formatMultiTableAdditionnalCostData(additionnalCosts: IPurchaseOrderAdditionnalCost[], linkedDatas: ILinkedMultiTableGroupData[], multiTable: IMultiTableGroup) {
    additionnalCosts.forEach(addCost => {
      const isOtherType = Object.keys(UniqueSupplierOfferAdditionnalCostType).indexOf(addCost.type) === -1;
      addCost.denomination = isOtherType ? addCost.denomination : UniqueSupplierOfferAdditionnalCostType[addCost.type];
    });
    const sortedAdditionnalCost: IPurchaseOrderAdditionnalCost[] = additionnalCosts.sort((a, b) => {
      if (a.denomination > b.denomination) { return 1; }
      if (a.denomination < b.denomination) { return -1; }
      return a.id > b.id ? 1 : -1;
    });
    const configAdditionnalCost = deepClone(PURCHASE_ORDER_TABLE_CONFIG.additionnalCost);

    const additionnalCostDatas: IMultiTableGroup = {
      elements: [deepClone(PURCHASE_ORDER_TABLE_CONFIG.additionnalCostElement)],
      linkedData: linkedDatas.map(link => {
        return {
          ...link,
          elements: [deepClone(configAdditionnalCost)]
        };
      })
    };
    let addedLink: any;

    sortedAdditionnalCost.forEach(addCost => {
      additionnalCostDatas.elements[0].datas.push({
        keyLigne: `#`,
        id: addCost.id,
        quantity: addCost.quantity,
        denomination: addCost.denomination,
        type: addCost.type,
        unit: addCost.unit,
        price: addCost.price,
        isAdditionnalCost: true,
        canBeUpdate: false,
      });
      additionnalCostDatas.linkedData.forEach(link => {
        addedLink = {
          price: addCost.price,
          unit: EnumAdditionnalCostUnit[addCost.unit],
          quantity: addCost.quantity,
          isSelected: true,
          isFirstGroupData: true
        };
        link.elements[0].datas.push(addedLink);
      });
    });

    multiTable.elements = multiTable.elements.concat(additionnalCostDatas.elements);
    multiTable.linkedData.forEach(link => {
      link.elements = link.elements.concat(additionnalCostDatas.linkedData.find(addLink => addLink.id === link.id).elements);
    });
  }

  public subscribeElementUpdateRemark() {
    return this._updatePriceRequestElementRemark;
  }

  public subscribeElementUpdateFormat() {
    return this._updatePriceRequestElementFormat;
  }

  public subscribeElementUpdateDenomination() {
    return this._updatePriceRequestElementDenomination;
  }

  public subscribeElementUpdateQuantity() {
    return this._updatePriceRequestElementQuantity;
  }

  public subscribeElementUpdateMatiere() {
    return this._updatePriceRequestElementMatiere;
  }

  public subscribeElementUpdateWeight() {
    return this._updatePriceRequestElementWeight;
  }

  public subscribeElementUpdateWidth() {
    return this._updatePriceRequestElementWidth;
  }

  public subscribeElementUpdateLength() {
    return this._updatePriceRequestElementLength;
  }

  public subscribeElementUpdateThickness() {
    return this._updatePriceRequestElementThickness;
  }

  public subscribeElementUpdateQuantityUnit() {
    return this._updatePriceRequestElementQuantityUnit;
  }

  public subscribeElementUpdateEn1090() {
    return this._updatePriceRequestElementEn1090;
  }

  public subscribeElementUpdateOption() {
    return this._updatePriceRequestElementOption;
  }

  public subscribeElementUpdatePrice() {
    return this._updatePriceRequestElementPrice;
  }

  public subscribeElementUpdateDeliveryDate() {
    return this._updatePriceRequestElementDeliveryDate;
  }
}
