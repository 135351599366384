import { BehaviorSubject } from "rxjs";
import { MM_TO_M, SQUARE_MM_TO_SQUARE_M, KG_TO_T } from "./base";
import { formatNumber } from "@angular/common";
import { LocaleLanguage } from "app/presentationnal/organisms/wizard-modal/service/step-form.service";


export interface CustomBase {
    width: number;
    base?: number;
    height: number;
    thickness: number;
    matterPriceByKg: number;
    matterKgByLiter: number;
}

export class CustomFormulaDisplayer {

    public $matterPrice: BehaviorSubject<string[]>;

    private _width: number;
    private _base: number;
    private _height: number;
    private _thickness: number;
    private _matterPriceByKg: number;
    private _matterKgByLiter: number;
    private _laserCutMargin: number;

    public constructor() {
        this.$matterPrice = new BehaviorSubject([]);
    }

    public set base({ width, base, height, thickness, matterPriceByKg, matterKgByLiter }: CustomBase) {
        this._width = width;
        this._base = base;
        this._height = height;
        this._thickness = thickness;
        this._matterPriceByKg = matterPriceByKg;
        this._matterKgByLiter = matterKgByLiter;
        this._laserCutMargin = 10;
        this.compute();
    }

    public set laserCutMargin(value: number) {
        this._laserCutMargin = value;
    }

    private compute(): void {
        const calcul: string[] = [];
        const width: number = this._width * MM_TO_M;
        const base: number = this._base * MM_TO_M;
        const height: number = this._height * MM_TO_M;
        const thickness: number = this._thickness * MM_TO_M;
        const laserCutMargin: number = this._laserCutMargin * MM_TO_M;
        let volume: number;

        if (this._base) {
          volume = (height + base + (laserCutMargin * 2)) * (width + (laserCutMargin * 2)) * thickness;
          calcul.push(`(H + B + ( Marge x 2 )) m x (L + (Marge x 2)) m x Épaisseur = Volume` );
          calcul.push(`${FormulaHelper.format(height + base + (laserCutMargin * 2))} x ${FormulaHelper.format(width + (laserCutMargin * 2))} x ${FormulaHelper.format(thickness)} = ${FormulaHelper.format(volume, 6)}`);
        } else {
          volume = (height + laserCutMargin * 2) * (width + laserCutMargin * 2) * thickness;
          calcul.push(`(H + Marge x 2) m x (L + Marge x 2) m x Épaisseur = Volume` );
          calcul.push(`${FormulaHelper.format(height + (laserCutMargin * 2))} x ${FormulaHelper.format(width + (laserCutMargin * 2))} x ${FormulaHelper.format(thickness)} = ${FormulaHelper.format(volume, 6)}`);
        }

        calcul.push(`Volume x 1000 (conversion en litre) = Nombre de litre`);
        const liter: number = volume * 1000;
        calcul.push(`${FormulaHelper.format(volume, 6)} x 1000 (conversion en litre) = ${FormulaHelper.format(liter)}`);

        calcul.push(`Nombre de litre x Nombre de kilogramme par litre = Poids`);
        const weight: number = liter * this._matterKgByLiter;
        calcul.push(`${FormulaHelper.format(liter)} x ${FormulaHelper.format(this._matterKgByLiter)} = ${FormulaHelper.format(weight)}`);

        calcul.push(`Poids x Prix matière = Prix`);
        const price: number = weight * this._matterPriceByKg;
        calcul.push(`${FormulaHelper.format(weight)} x ${FormulaHelper.format(this._matterPriceByKg)} = ${FormulaHelper.format(price)}`);

        this.$matterPrice.next(calcul);
    }
}

export interface LaserCutBase {
    meterForCutting: number;
    priceByM: number;
    holeQuantity?: number;
    holeDiameter?: number;
    amorcePrice: number;
    drawing: number;
    quantity: number;
}

export class LaserCutFormulaDisplayer {

    public $laserCuttingPrice: BehaviorSubject<string[]>;

    private _meterForCutting: number;
    private _priceByM: number;
    private _holeQuantity: number;
    private _holeDiameter: number;
    private _amorcePrice: number;
    private _drawing: number;
    private _quantity: number;

    public constructor() {
        this.$laserCuttingPrice = new BehaviorSubject([]);
    }

    public set base({meterForCutting, priceByM, holeQuantity, holeDiameter, amorcePrice, drawing, quantity}: LaserCutBase) {
        this._meterForCutting = meterForCutting;
        this._priceByM = priceByM;
        this._holeQuantity = holeQuantity;
        this._holeDiameter = holeDiameter;
        this._amorcePrice = amorcePrice;
        this._drawing = drawing;
        this._quantity = quantity;
        this.compute();
    }

    public compute(): void {
        const calcul = [];

        calcul.push(`(Périmètre de découpe (m) x Prix de découpe au mètre) + Prix amorce = Prix découpe`);
        const basePrice: number = ((this._meterForCutting * MM_TO_M) * this._priceByM) + this._amorcePrice;
        calcul.push(`(${FormulaHelper.format(this._meterForCutting * MM_TO_M)} x ${FormulaHelper.format(this._priceByM)}) + ${FormulaHelper.format(this._amorcePrice)} = ${FormulaHelper.format(basePrice)}`);
        const drawingUnit: number = this._drawing / this._quantity;

        if (this._holeQuantity) {
            calcul.push(`(Périmètre d'un trou (m) * Nombre de trou) * Prix de découpe au mètre = Prix découpe (trou)`);
            const holePerimeter: number = Math.PI * (this._holeDiameter * MM_TO_M);
            const holePrice: number = (holePerimeter * this._holeQuantity) * this._priceByM;
            calcul.push(`(${FormulaHelper.format(holePerimeter)} * ${FormulaHelper.format(this._holeQuantity)}) * ${FormulaHelper.format(this._priceByM)} = ${FormulaHelper.format(holePrice)}`);

            calcul.push(`Nombre de trou x Prix amorce = Prix amorce des trous`);
            const amorceHole: number = this._holeQuantity * this._amorcePrice;
            calcul.push(`${FormulaHelper.format(this._holeQuantity)} x ${FormulaHelper.format(this._amorcePrice)} = ${FormulaHelper.format(amorceHole)}`);

            calcul.push(`Prix découpe + Prix découpe (trou) + Prix des amorces + Prix dessin (unitaire) = Prix total`);
            const price = basePrice + holePrice + amorceHole + drawingUnit;
            calcul.push(`${FormulaHelper.format(basePrice)} + ${FormulaHelper.format(holePrice)} + ${FormulaHelper.format(amorceHole)} + ${FormulaHelper.format(drawingUnit)} = ${FormulaHelper.format(price)}`);
        } else {
            calcul.push(`Prix découpe + Prix dessin (unitaire) = Prix total`);
            const price = basePrice + drawingUnit;
            calcul.push(`${FormulaHelper.format(basePrice)} + ${FormulaHelper.format(drawingUnit)} = ${FormulaHelper.format(price)}`);
        }

        this.$laserCuttingPrice.next(calcul);
    }
}

export interface WeldingBase {
    meterForWelding: number;
    passes: number;
    priceByM: number;
    percentage: number;
    element1Thickness: number;
    element2Thickness: number;
    section: number;
}

export class WeldingFormulaDisplayer {

    public $weldingPrice: BehaviorSubject<string[]>;

    private _meterForWelding: number;
    private _passes: number;
    private _priceByM: number;
    private _percentage: number;
    private _element1Thickness: number;
    private _element2Thickness: number;
    private _section: number;

    public constructor() {
        this.$weldingPrice = new BehaviorSubject([]);
    }

    public set base({ meterForWelding, passes, priceByM, percentage, element1Thickness, element2Thickness, section }) {
        this._meterForWelding = meterForWelding;
        this._passes = passes;
        this._priceByM = priceByM;
        this._percentage = percentage;
        this._element1Thickness = element1Thickness;
        this._element2Thickness = element2Thickness;
        this._section = section;
        this.compute();
    }

    public compute() {
        const calcul: string[] = [];

        calcul.push(`Métré de soudure complet x Pourcentage = Métré de soudure`);
        const initialWeldingMeter: number = this._meterForWelding * (this._percentage / 100);
        calcul.push(`${FormulaHelper.format(this._meterForWelding)} x ${FormulaHelper.format(this._percentage)}% = ${FormulaHelper.format(initialWeldingMeter)}`);

        const minThickness = Math.min(this._element1Thickness, this._element2Thickness);
        calcul.push(`Epaisseur la plus petite = ${FormulaHelper.format(minThickness)}`);

        calcul.push(`(Section de soudure ou Plus petite épaisseur matière) / 2 = Seuil`);
        const thicknessCalculate = Math.ceil(minThickness / 2);
        const capSelected = (this._section) ? Math.ceil(this._section / 2) : thicknessCalculate;
        calcul.push(`${FormulaHelper.format(this._section)} ou ${FormulaHelper.format(thicknessCalculate)} = ${FormulaHelper.format(capSelected)}`);

        const passCalculated = capSelected < 6 ? 1 : (capSelected >= 13 ? 6 : 3);
        calcul.push(`Nombre de passes calculés = ${FormulaHelper.format(passCalculated)}`);

        calcul.push(`Métré de soudure x Nombre de passes = Métré total de soudure`);
        const weldingMeter: number = initialWeldingMeter * this._passes;
        calcul.push(`${FormulaHelper.format(initialWeldingMeter)} x ${FormulaHelper.format(this._passes)} = ${FormulaHelper.format(weldingMeter)}`);

        calcul.push(`Métré total de soudure x Prix de soudure au mètre = Prix total`);
        const price: number = weldingMeter * this._priceByM;
        calcul.push(`${FormulaHelper.format(weldingMeter)} * ${FormulaHelper.format(this._priceByM)} = ${FormulaHelper.format(price)}`);

        this.$weldingPrice.next(calcul);
    }
}

export interface CuttingBase {
    section: number;
    angle1: number;
    angle2: number;
    priceBySquareCm: number;
}

export class CuttingFormulaDisplayer {

    public $cuttingPrice: BehaviorSubject<string[]>;

    private _section: number;
    private _angle1: number;
    private _angle2: number;
    private _priceBySquareCm: number;

    public constructor() {
        this.$cuttingPrice = new BehaviorSubject([]);
    }

    public set base({section, angle1, angle2, priceBySquareCm}: CuttingBase) {
        this._section = section;
        this._angle1 = angle1;
        this._angle2 = angle2;
        this._priceBySquareCm = priceBySquareCm;
        this.compute();
    }

    public compute() {
        const calcul: string[] = [];
        const radAngle1 = this._angle1 * Math.PI / 180;
        const radAngle2 = this._angle2 * Math.PI / 180;
        calcul.push(`Section / sin(Angle de coupe) * Prix au cm² de découpe = Prix première découpe`);
        const priceAngle1 = this._section / Math.sin(radAngle1) * this._priceBySquareCm;
        calcul.push(`${FormulaHelper.format(this._section)} / ${FormulaHelper.format(Math.sin(radAngle1))} x ${FormulaHelper.format(this._priceBySquareCm)} = ${FormulaHelper.format(priceAngle1)}`);
        if (this._angle2 != null) {
            calcul.push(`Section / sin(Angle de coupe) * Prix au cm² de découpe = Prix deuxième découpe`);
            const priceAngle2 = this._section / Math.sin(radAngle2) * this._priceBySquareCm;
            calcul.push(`${FormulaHelper.format(this._section)} / ${FormulaHelper.format(Math.sin(radAngle2))} x ${FormulaHelper.format(this._priceBySquareCm)} = ${FormulaHelper.format(priceAngle2)}`);
            calcul.push(`Prix découpe 1 + Prix découpe 2 = Prix`);
            const totalPrice = priceAngle1 + priceAngle2;
            calcul.push(`${FormulaHelper.format(priceAngle1)} + ${FormulaHelper.format(priceAngle2)} = ${FormulaHelper.format(totalPrice)}`);
        }
        this.$cuttingPrice.next(calcul);
    }
}

export interface StandardElementBase {
    length: number;
    priceByKg: number;
    kgByLiter: number;
    kgByMeter?: number;
    section?: number;
    useClass?: string;
}

export class StandardElementFormulaDisplayer {

    public $standardElementPrice: BehaviorSubject<string[]>;

    private _length: number;
    private _priceByKg: number;
    private _kgByLiter: number;
    private _kgByMeter: number;
    private _section: number;
    private _useClass: string;

    public constructor() {
        this.$standardElementPrice = new BehaviorSubject([]);
    }

    public set base({ length, priceByKg, kgByLiter, kgByMeter, section, useClass }: StandardElementBase) {
        this._length = length;
        this._priceByKg = priceByKg;
        this._kgByLiter = kgByLiter;
        this._kgByMeter = kgByMeter;
        this._section = section;
        this._useClass = useClass;
        this.compute();
    }

    public compute() {
        const noKgByMeter: string[] = ["BeamTShape", "BeamUShape"];
        const calcul: string[] = [];

        if (noKgByMeter.indexOf(this._useClass) >= 0) {
            // No KgByMeter data available, need to compute volume
            calcul.push(`Section (m²) x Longueur (m) = Volume (m³)`);
            const volume: number = (this._section * SQUARE_MM_TO_SQUARE_M ) * (this._length * MM_TO_M);
            calcul.push(`${FormulaHelper.format(this._section * SQUARE_MM_TO_SQUARE_M)} x ${FormulaHelper.format(this._length * MM_TO_M)} = ${FormulaHelper.format(volume)}`);

            calcul.push(`Volume x 1000 (conversion en litre) = Nombre de litre`);
            const liters: number = volume * 1000;
            calcul.push(`${FormulaHelper.format(volume)} x 1000 = ${FormulaHelper.format(liters)}`);

            calcul.push(`Nombre de litre x Nombre de kilogramme par litre = Poids`);
            const weight: number = liters * this._kgByLiter;
            calcul.push(`${FormulaHelper.format(liters)} x ${FormulaHelper.format(this._kgByLiter)} = ${FormulaHelper.format(weight)}`);

            calcul.push(`Poids x Prix matière = Prix`);
            const price: number = weight * this._priceByKg;
            calcul.push(`${FormulaHelper.format(weight)} x ${FormulaHelper.format(this._priceByKg)} = ${FormulaHelper.format(price)}`);
        } else {
            // Standard way
            calcul.push(`Longueur (m) x Kg par mètre = Poids`);
            const weight: number = (this._length * MM_TO_M) * this._kgByMeter;
            calcul.push(`${FormulaHelper.format(this._length * MM_TO_M)} x ${FormulaHelper.format(this._kgByMeter)} = ${FormulaHelper.format(weight)}`);

            calcul.push(`Poids x Prix au Kg = Prix`);
            const price: number = weight * this._priceByKg;
            calcul.push(`${FormulaHelper.format(weight)} x ${FormulaHelper.format(this._priceByKg)} = ${FormulaHelper.format(price)}`);
        }

        this.$standardElementPrice.next(calcul);
    }
}

export interface PaintingBase {
    totalArea: number;
    totalWeight: number;
    layerAmount: number;
    layerThickness: number;
    blastingPricePerTon: number;
    isBlasted: boolean;
    cost: number;
    areaCost?: number;
    fixedPrice: boolean;
}

export class PaintingFormulaDisplayer {

    public $PaintingPrice: BehaviorSubject<string[]>;

    private _totalArea: number;
    private _totalWeight: number;
    private _layerAmount: number;
    private _layerThickness: number;
    private _blastingPricePerTon: number;
    private _isBlasted: boolean;
    private _cost: number;
    private _areaCost: number;
    private _fixedPrice: boolean;

    public constructor() {
        this.$PaintingPrice = new BehaviorSubject([]);
    }

    public set base({ totalArea, totalWeight, layerAmount, layerThickness, blastingPricePerTon, isBlasted, cost, areaCost, fixedPrice }: PaintingBase) {
        this._totalArea = totalArea;
        this._totalWeight = totalWeight;
        this._layerAmount = layerAmount;
        this._layerThickness = layerThickness;
        this._blastingPricePerTon = blastingPricePerTon;
        this._isBlasted = isBlasted;
        this._cost = cost;
        this._areaCost = areaCost;
        this._fixedPrice = fixedPrice;
        this.compute();
    }

    public compute() {
        const calcul: string[] = [];

        if (!this._fixedPrice) {
          calcul.push(`Nombre de couches x Superficie totale (m²) = Superficie peinture (m²)`);
          const paintArea: number = this._layerAmount * this._totalArea;
          calcul.push(`${FormulaHelper.format(this._layerAmount)} x ${FormulaHelper.format(this._totalArea)} = ${FormulaHelper.format(paintArea)}`);

          calcul.push(`Prix au m² x Superficie peinture (m²) = Prix de la peinture`);
          const paintprice: number = this._areaCost * paintArea;
          calcul.push(`${FormulaHelper.format(this._areaCost)} x ${FormulaHelper.format(paintArea)} = ${FormulaHelper.format(paintprice)}`);

          if (this._isBlasted) {
              calcul.push(`Poids total (T) x Prix grenaillage (€/T) = Prix grenaillage (€)`);
              const blastingPrice: number = this._totalWeight * KG_TO_T * this._blastingPricePerTon;
              calcul.push(`${FormulaHelper.format(this._totalWeight * KG_TO_T)} x ${FormulaHelper.format(this._blastingPricePerTon)} = ${FormulaHelper.format(blastingPrice)}`);

              calcul.push(`Prix de la peinture + Prix grenaillage = Prix total`);
              const price: number = paintprice + blastingPrice;
              calcul.push(`${FormulaHelper.format(paintprice)} + ${FormulaHelper.format(blastingPrice)} = ${FormulaHelper.format(price)}`);
          }
        } else {
          calcul.push(`Prix Fixe = ${FormulaHelper.format(this._cost)}`);
        }

        this.$PaintingPrice.next(calcul);
    }
}

export interface FinishBase {
  totalArea: number;
  cost: number;
  areaCost?: number;
  fixedPrice: boolean;
}

export class FinishFormulaDisplayer {

  public $FinishPrice: BehaviorSubject<string[]>;

  private _totalArea: number;
  private _cost: number;
  private _areaCost: number;
  private _fixedPrice: boolean;

  public constructor() {
      this.$FinishPrice = new BehaviorSubject([]);
  }

  public set base({ totalArea, cost, areaCost, fixedPrice }: FinishBase) {
      this._totalArea = totalArea;
      this._cost = cost;
      this._areaCost = areaCost;
      this._fixedPrice = fixedPrice;
      this.compute();
  }

  public compute() {
      const calcul: string[] = [];

      if (!this._fixedPrice) {
        calcul.push(`Superficie Total (m²) x Prix au m² = Prix`);
        const finishPrice: number = this._totalArea * this._areaCost;
        calcul.push(`${FormulaHelper.format(this._totalArea)} x ${FormulaHelper.format(this._areaCost)} = ${FormulaHelper.format(finishPrice)}`);
      } else {
        calcul.push(`Prix Fixe = ${FormulaHelper.format(this._cost)}`);
      }

      this.$FinishPrice.next(calcul);
  }
}

export interface GalvaBase {
  totalWeight: number;
  cost: number;
  weightCost?: number;
  fixedPrice: boolean;
}

export class GalvaFormulaDisplayer {

  public $GalvaPrice: BehaviorSubject<string[]>;

  private _totalWeight: number;
  private _cost: number;
  private _weightCost: number;
  private _fixedPrice: boolean;

  public constructor() {
      this.$GalvaPrice = new BehaviorSubject([]);
  }

  public set base({ totalWeight, cost, weightCost, fixedPrice }: GalvaBase) {
      this._totalWeight = totalWeight;
      this._cost = cost;
      this._weightCost = weightCost;
      this._fixedPrice = fixedPrice;
      this.compute();
  }

  public compute() {
      const calcul: string[] = [];

      if (!this._fixedPrice) {
        calcul.push(`Poids Total (kg) x Prix au kg = Prix`);
        const galvaPrice: number = this._totalWeight * this._weightCost;
        calcul.push(`${FormulaHelper.format(this._totalWeight)} x ${FormulaHelper.format(this._weightCost)} = ${FormulaHelper.format(galvaPrice)}`);
      } else {
        calcul.push(`Prix Fixe = ${FormulaHelper.format(this._cost)}`);
      }

      this.$GalvaPrice.next(calcul);
  }
}

export interface DrillingBase {
    isCalculated: boolean;
    isRound?: boolean;
    holeQuantity: number;
    holePrice?: number;
    diameter?: number;
    height?: number;
    width?: number;
    prc?: number;
    priceByM?: number;
}

export class DrillingFormulaDisplayer {

    public $drillingPrice: BehaviorSubject<string[]>;

    private _isCalculated: boolean;
    private _isRound: boolean;
    private _holeQuantity: number;
    private _holePrice: number;
    private _diameter: number;
    private _height: number;
    private _width: number;
    private _prc: number;
    private _priceByM: number;

    public constructor() {
        this.$drillingPrice = new BehaviorSubject([]);
    }

    public set base({ isCalculated, isRound, holeQuantity, holePrice, diameter, height, width, prc, priceByM }: DrillingBase) {
        this._isCalculated = isCalculated;
        this._isRound = isRound;
        this._holeQuantity = holeQuantity;
        this._holePrice = holePrice;
        this._diameter = diameter;
        this._height = height;
        this._width = width;
        this._prc = prc;
        this._priceByM = priceByM;
        this.compute();
    }

    public compute() {
        const calcul: string[] = [];
        if (!this._isCalculated) {
            calcul.push(`Nombre de trous x Prix d'un trou = Prix`);
            const price: number = this._holeQuantity * this._holePrice;
            calcul.push(`${FormulaHelper.format(this._holeQuantity)} x ${FormulaHelper.format(this._holePrice)} = ${FormulaHelper.format(price)}`);
        } else {
            const cuttingLength = (this._isRound ? Math.PI * this._diameter : (this._height + this._width) * 2) * MM_TO_M;
            calcul.push(`3 x (Amorce + Longueur découpe x prix au mètre) = Prix d'un trou`);
            const holePrice = 3 * (this._prc + (cuttingLength * this._priceByM));
            calcul.push(`3 x (${FormulaHelper.format(this._prc)} + ${FormulaHelper.format(cuttingLength)} x ${FormulaHelper.format(this._priceByM)}) = ${FormulaHelper.format(holePrice)}`);
            calcul.push(`Nombre de trous x Prix d'un trou = Prix total`);
            const price = this._holeQuantity * holePrice;
            calcul.push(`${FormulaHelper.format(this._holeQuantity)} x ${FormulaHelper.format(holePrice)} = ${FormulaHelper.format(price)}`);
        }

        this.$drillingPrice.next(calcul);
    }
}

export interface OptionBase {
    personQuantity: number;
    dayQuantity: number;
    hourlyRate: number;
}

export class OptionFormulaDisplayer {

    public $optionPrice: BehaviorSubject<any[]>;

    private _personQuantity: number;
    private _dayQuantity: number;
    private _hourlyRate: number;

    public constructor() {
        this.$optionPrice = new BehaviorSubject([]);
    }

    public set base({ personQuantity, dayQuantity, hourlyRate }: OptionBase) {
        this._personQuantity = personQuantity;
        this._dayQuantity = dayQuantity;
        this._hourlyRate = hourlyRate;
        this.compute();
    }

    public compute() {
        const calcul: string[] = [];

        const hourCount: number = this._personQuantity * this._dayQuantity * 10;
        calcul.push(`Nombre de personnes x Nombre de jours x 10 (heures par jour) = Nombre d'heures`);
        calcul.push(`${this._personQuantity} x ${this._dayQuantity} x 10 = ${FormulaHelper.format(hourCount)}`);

        const price: number = hourCount * this._hourlyRate;
        calcul.push(`Nombre d'heures x Taux horaire = Prix`);
        calcul.push(`${hourCount} x ${this._hourlyRate} = ${FormulaHelper.format(price)}`);

        this.$optionPrice.next(calcul);
    }
}

export interface MountingBase {
    totalWeight: number;
    pricePerKg: number;
}

export class MountingFormulaDisplayer {

    public $optionPrice: BehaviorSubject<any[]>;

    private _totalWeight: number;
    private _pricePerKg: number;

    public constructor() {
        this.$optionPrice = new BehaviorSubject([]);
    }

    public set base({ pricePerKg, totalWeight }: MountingBase) {
        this._pricePerKg = pricePerKg;
        this._totalWeight = totalWeight;
        this.compute();
    }

    public compute() {
        const calcul: string[] = [];

        const price: number = this._pricePerKg * this._totalWeight;
        calcul.push(`Prix au Kg x Poids total (en Kg) = Prix`);
        calcul.push(`${this._pricePerKg} x ${this._totalWeight} = ${FormulaHelper.format(price)}`);

        this.$optionPrice.next(calcul);
    }
}

export interface TransportBase {
    price: number;
}

export class TransportFormulaDisplayer {

    public $optionPrice: BehaviorSubject<any[]>;

    private _price: number;

    public constructor() {
        this.$optionPrice = new BehaviorSubject([]);
    }

    public set base({ price }: TransportBase) {
        this._price = price;
        this.compute();
    }

    public compute() {
        const calcul: string[] = [];
        calcul.push(`Prix = ${FormulaHelper.format(this._price)}`);
        this.$optionPrice.next(calcul);
    }
}

export interface MarginBase {
  ticketPrice: number;
  percentage: number;
}

export class MarginFormulaDisplayer {

  public $optionPrice: BehaviorSubject<any[]>;

  private _ticketPrice: number;
  private _percentage: number;

  public constructor() {
      this.$optionPrice = new BehaviorSubject([]);
  }

  public set base({ ticketPrice, percentage }: MarginBase) {
      this._ticketPrice = ticketPrice;
      this._percentage = percentage;
      this.compute();
  }

  public compute() {
      const calcul: string[] = [];
      calcul.push(`Prix total * Pourcentage = Marge`);
      const marge = this._ticketPrice * (this._percentage / 100);
      calcul.push(`${FormulaHelper.format(this._ticketPrice)} * ${FormulaHelper.format(this._percentage)}% =  ${FormulaHelper.format(+marge.toFixed(4))} `);
      this.$optionPrice.next(calcul);
  }
}

export interface SquareFoldingBase {
    height: number;
    base: number;
    length: number;
    thickness: number;
    price: number;
}

export class SquareFoldingFormulaDisplayer {

    public $squareFoldingPrice: BehaviorSubject<string[]>;

    private _height: number;
    private _base: number;
    private _length: number;
    private _thickness: number;
    private _price: number;

    public constructor() {
        this.$squareFoldingPrice = new BehaviorSubject([]);
    }

    public set base({ height, base, length, thickness, price }: SquareFoldingBase) {
        this._height = height;
        this._base = base;
        this._length = length;
        this._thickness = thickness;
        this._price = price;
        this.compute();
    }

    public compute() {
        const calcul: string[] = [];
        const length = this._length * MM_TO_M;
        const height = this._height * MM_TO_M;
        const base = this._base * MM_TO_M;
        const thickness = this._thickness * MM_TO_M;

        // calcul.push(`Hauteur (m) x Longueur (m) + Base (m) x Longueur (m) = Surface (m²)`);
        // const area = (height * length) + (base * length);
        // calcul.push(`${FormulaHelper.format(height)} x ${FormulaHelper.format(length)} + ${FormulaHelper.format(base)} x ${FormulaHelper.format(length)} = ${FormulaHelper.format(area)}`);
        // calcul.push(`Epaisseur & Surface = Prix (€/pli)`);
        calcul.push(`Prix = ${FormulaHelper.format(this._price)} €/pli`);

        this.$squareFoldingPrice.next(calcul);
    }
}

export interface StudyBase {
    hourQuantity: number;
    hourlyRate: number;
    isComputedByWeight: boolean;
    priceByKg?: number;
    totalWeight?: number;
}

export class StudyFormulaDisplayer {

    public $studyPrice: BehaviorSubject<string[]>;

    private _hourQuantity: number;
    private _hourlyRate: number;
    private _isComputedByWeight: boolean;
    private _priceByKg?: number;
    private _totalWeight?: number;

    public constructor() {
        this.$studyPrice = new BehaviorSubject([]);
    }

    public set base({ hourQuantity, hourlyRate, isComputedByWeight, priceByKg, totalWeight }: StudyBase) {
        this._hourQuantity = hourQuantity;
        this._hourlyRate = hourlyRate;
        this._isComputedByWeight = isComputedByWeight;
        this._priceByKg = priceByKg;
        this._totalWeight = totalWeight;
        this.compute();
    }

    public compute() {
        const calcul: string[] = [];

        if (this._isComputedByWeight) {
            calcul.push(`Prix au Kg x Poids total (en Kg) = Prix`);
            const price: number = this._priceByKg * this._totalWeight;
            calcul.push(`${this._priceByKg} x ${this._totalWeight} = ${FormulaHelper.format(price)}`);
        } else {
            calcul.push(`Nombre d'heures x Taux horaire = Prix`);
            const price: number = this._hourQuantity * this._hourlyRate;
            calcul.push(`${this._hourQuantity} x ${this._hourlyRate} = ${FormulaHelper.format(price)}`);
        }

        this.$studyPrice.next(calcul);
    }
}

class FormulaHelper {
  public static format(value: number, fixed?: number): string {
      if (value === null || value === undefined) {
        return "?";
      } else {
        return formatNumber(value, LocaleLanguage, "1.0-".concat((fixed ? fixed : 4).toString()));
      }
  }
}