import { Injectable } from "@angular/core";
import { Apollo } from "apollo-angular";
import { LIST_QUOTES, DELETE_QUOTE, ADD_QUOTE, UPDATE_QUOTE, GET_QUOTE, GET_NUMBER, GET_SEARCH_PLATE_THICKNESS, DUPLICATE_QUOTE, LIST_DISPLAY_QUOTES } from "../quotes.queries";
import { Quote } from "app/facade/interfaces";

@Injectable()
export class QuotesQueriesService {

    constructor(private _apollo: Apollo) { }

    public watchListQuotes(variables) {
        return this._apollo.watchQuery({
            query: LIST_DISPLAY_QUOTES,
            variables: JSON.parse(JSON.stringify(variables)),
            fetchPolicy: "network-only"
        });
    }

    public deleteQuote(quoteId: string) {
        return this._apollo.mutate({
            mutation: DELETE_QUOTE,
            variables: { _id: quoteId },
        });
    }

    public afterDeleteQuote(variables) {
        return this._apollo.query({
            query: LIST_DISPLAY_QUOTES,
            variables: JSON.parse(JSON.stringify(variables)),
            fetchPolicy: "network-only"
        });
    }

    public addQuote(quote) {
        return this._apollo.mutate({
            mutation: ADD_QUOTE,
            variables : {
                quote: quote
            }
        });
    }

    public updateQuote(quoteId: string, quote: Quote) {
        return this._apollo.mutate({
            mutation: UPDATE_QUOTE,
            fetchPolicy: "no-cache",
            variables : {
                _id: quoteId,
                quote: quote
            }
        });
    }

    public getQuote(quoteId) {
        return this._apollo.query({
            query: GET_QUOTE,
            variables: { _id: quoteId},
            fetchPolicy: "network-only"
        });
    }

    public getQuoteNumber() {
        return this._apollo.query({
            query: GET_NUMBER,
            fetchPolicy: "network-only"
          });
    }

    public getSearchTickness( search: string, idMatter) {
        return this._apollo.query({
            query: GET_SEARCH_PLATE_THICKNESS,
            fetchPolicy: "network-only",
            variables : {
                search: search.toString(),
                matterId: idMatter
            }
          });
    }

    public duplicateQuote( id: string) {
      return this._apollo.mutate({
        mutation: DUPLICATE_QUOTE,
        variables: {
          _id: id
        }
      });
    }

}