// import { MOCK_PROJECT } from "./listing-project.mock";
// import { MOCK_LIST_QUOTE } from "./listingQuote-mock";
import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
import { SnackService } from "app/presentationnal/organisms/snack-bar/services/snack.service";
import { ModalService } from "app/presentationnal/organisms/modal/services/modal.service";
import { MODAL_TYPE } from "app/presentationnal/organisms/modal/enums/modal-type.enum";
import { ModalAddQuotesProjectComponent } from "app/presentationnal/organisms/modal/components/modal-add-quotes-project/modal-add-quotes-project.component";
import { QuotesQueriesService } from "app/facade/queries/quote/async/quotes_queries.service";
import { QuotesProjectQueriesService } from "app/facade/queries/quote-project/async/quotes-project-queries.service";
import { Quote, QuotesProject, DisplayQuote } from "app/facade/interfaces";
import { ModalConfirmComponent } from "app/presentationnal/organisms/modal";
import { ApplicationConfig } from "app/app.config";
import { PermissionService } from "app/facade/services/permission.service";
import { AuthService } from "@lib/auth/auth.service";

const PAGINATION_LIMIT: number = 10;

@Component({
  selector: "app-listing-quote",
  templateUrl: "./listing-quote.component.html",
  styleUrls: ["./listing-quote.component.css"],
})
export class ListingQuoteComponent implements OnInit {
  @ViewChild("loadMore") public loadMore: ElementRef;
  public isQuote: boolean = false;
  public dataListQuote: DisplayQuote[] = [];
  public dataList: QuotesProject[] = [];
  public url;
  public searchFilter: string = "";
  public loadMoreDataQueries: number = 10;
  public noLoadMore: boolean = false;
  private _queryVariables = {
    search: "",
    sort: { sortBy: "CREATED_AT", sortDirection: "DESC" },
    pagination: {page: 1, limit: PAGINATION_LIMIT}
  };
  public isEdit = false;
  public isDelete = false;
  public userGroup: any;
  private _dataListQuotesSub: Subscription;
  private _dataListProjectsSub: Subscription;
  private _deleteQuoteSub: Subscription;
  private _editProjectSub: Subscription;
  private _addProjectSub: Subscription;
  private _afterDeleteQuotesSub: Subscription;
  private _deleteProjectSub: Subscription;
  private _reloadProjectSub: Subscription;

  constructor(private _modalService: ModalService,
    private router: Router,
    private _quotesQueriesSrv: QuotesQueriesService,
    private _quotesProjectQueriesSrv: QuotesProjectQueriesService,
    private _permissionService: PermissionService,
    private _authSrv: AuthService,
    private _snackBar: SnackService) { }

  ngOnInit() {
    this.userGroup =  this._authSrv.isUserGroup();
    //get and update Permission
    this._permissionService.getAndUpdatePermission(this.userGroup);
    this.url = this.router.url;

    let savedSearch = JSON.parse(localStorage.getItem('dataQuotesRecherche'));
    savedSearch = savedSearch && savedSearch.recherche.length > 0 ? savedSearch.recherche : '';
    if (savedSearch.length > 0) {
      this._snackBar.open("Liste filtrée", "Cette liste est filtrée suivant votre recherche", "info", 5000);
      this.searchFilter = savedSearch;
      this._queryVariables.search = savedSearch;
    }
    let savedPagination = JSON.parse(localStorage.getItem('dataQuotesPagination'));
    savedPagination = savedPagination && savedPagination.pagination > 1 ? savedPagination.pagination : 1;
    if (savedPagination > 1) {
      this._queryVariables.pagination.limit = PAGINATION_LIMIT * savedPagination;
      this.listProjectQuotes(true);
      this._queryVariables.pagination = { page: savedPagination, limit: PAGINATION_LIMIT };
    } else {
      this.listProjectQuotes(true);
    }

    this.isEdit = this._permissionService.hasPermission(this.userGroup, "quotations", "write");
    this.isDelete = this._permissionService.hasPermission(this.userGroup, "quotations", "delete");
  }

  /**
   * @description List the quotes
   * @author Kevin Palade
   * @memberof ListingQuoteComponent
   */
  public listQuotes() {
    if (this._dataListQuotesSub) { this._dataListQuotesSub.unsubscribe(); }
    this._dataListQuotesSub = this._quotesQueriesSrv.watchListQuotes(this._queryVariables).valueChanges.subscribe(result => {
      const data = result.data["displayQuotes"];
      this.noLoadMore = data.length < PAGINATION_LIMIT ? true : false;
      this.dataListQuote = this._queryVariables.pagination.page > 1 ? [...this.dataListQuote, ...data] : data;
    });
  }

  /**
   * @description Open a confirm modal before the delete of a quote
   * @author Kevin Palade
   * @private
   * @param {string} quoteId
   * @param {boolean} list
   * @memberof ListingQuoteComponent
   */
  private beforeDeleteQuote(quoteId: string, list: boolean) {
    let quotes = [];
    if (list) {
      quotes = this.dataListQuote;
    } else {
      this.dataList.forEach(project => {
        quotes = [...quotes, ...project.displayQuotes];
      });
    }
    const item = quotes.find(data => data._id == quoteId);
    this._modalService.openModal(ModalConfirmComponent,
      {
        title: "Etes-vous sur?",
        type: MODAL_TYPE.CONFIRM,
        data: {
          message: `Voulez vous supprimer le devis <b>${item.number}</b>?`,
          actions: [ "Supprimer le devis de manière définitive" ],
        },
        params: { quoteId: quoteId, isListQuote: list },
        confirmCallback: this.deleteQuote
      }
    );
  }

  /**
   * @description Delete a quote
   * @memberof ListingQuoteComponent
   */
  public deleteQuote = (params) => {
    if (params) {
      if (this._deleteQuoteSub) { this._deleteQuoteSub.unsubscribe(); }
      this._deleteQuoteSub = this._quotesQueriesSrv.deleteQuote(params.quoteId).subscribe(result => {
        if (result.data["deleteQuote"]) {
          this.afterDeleteQuote(params.isListQuote);
          this._snackBar.open("Supprimer un devis", "Le devis a été supprimé", "success", 5000);
        }
      });
      if(!this.isDelete){
        this._snackBar.openSnackBarError("Supprimer un devis", "Le devis n'est pas  supprimé car vous n'avez pas l'autorisation!!");
      }
    }
  }

  /**
   * @description Get the quote list after the delete of one quote
   * @author Kevin Palade
   * @private
   * @param {*} query
   * @memberof ListingQuoteComponent
   */
  private afterDeleteQuote(isListQuote) {
    const variables = JSON.parse(JSON.stringify(this._queryVariables));
    variables.pagination.limit = variables.pagination.limit * variables.pagination.page;
    variables.pagination.page = 1;
    if (this._afterDeleteQuotesSub) { this._afterDeleteQuotesSub.unsubscribe(); }
    if (isListQuote) {
      this._afterDeleteQuotesSub = this._quotesQueriesSrv.afterDeleteQuote(variables).subscribe(result => {
        this.noLoadMore = result.data["displayQuotes"].length < variables.pagination.limit ? true : false;
        this.dataListQuote = result.data["displayQuotes"];
      });
    } else {
      this.reloadProject(variables);
    }
  }

  /**
   * @description Go to the add quote to project page
   * @author Kevin Palade
   * @param {string} projectId
   * @memberof ListingQuoteComponent
   */
  public goToAddQuoteForProject(projectId: string) {
    this.router.navigate([this.url + "/add/" + projectId]);
  }

  /**
   * @description List the projects with quotes
   * @author Kevin Palade
   * @memberof ListingQuoteComponent
   */
  public listProjectQuotes(forceFirstPage: boolean = false) {
    if (this._dataListProjectsSub) { this._dataListProjectsSub.unsubscribe(); }
    this._dataListProjectsSub = this._quotesProjectQueriesSrv.listQuoteProjects(this._queryVariables).valueChanges.subscribe(result => {
      const resultData: any = result.data;
      if ( resultData && resultData.quoteProjects) {
        this.noLoadMore = resultData.quoteProjects.length < PAGINATION_LIMIT ? true : false;
        this.dataList = !forceFirstPage && this._queryVariables.pagination.page > 1 ? [...this.dataList, ...resultData.quoteProjects] : resultData.quoteProjects;
      }
    });
  }

  /**
   * @description Add a new project
   * @author Kevin Palade
   * @memberof ListingQuoteComponent
   */
  public addProject() {
    const modalTitle: string = "Ajouter un projet";
    const modalRef = this._modalService.openModal(ModalAddQuotesProjectComponent, { title: modalTitle, type: MODAL_TYPE.NORMAL, data: null });
    if (modalRef) {
      modalRef.afterClosed.subscribe(res => {
        if (res.confirm && res.data) {
          if (this._addProjectSub) { this._addProjectSub.unsubscribe(); }
          this._addProjectSub = this._quotesProjectQueriesSrv.addQuoteProject(res.data, this._queryVariables).subscribe(result => {
            this._snackBar.open(modalTitle, "Le projet a été créé", "success", 5000);
          }, error => {
            this._snackBar.openSnackBarError(modalTitle, "Le projet n'a pas été créé", error);
          });
        }
      });
    }
  }

   /**
   * @description Edit an existing project
   * @author Kevin Palade
   * @param {string} projectId
   * @memberof ListingQuoteComponent
   */
  public editProject(projectId: string) {
    const modalTitle: string = "Edition du projet";
    const modalRef = this._modalService.openModal(ModalAddQuotesProjectComponent, { title: modalTitle, type: MODAL_TYPE.NORMAL, data: projectId });
    if (modalRef) {
      modalRef.afterClosed.subscribe(res => {
        if (res.confirm && res.data) {
          if (this._editProjectSub) { this._editProjectSub.unsubscribe(); }
          this._editProjectSub = this._quotesProjectQueriesSrv.editQuoteProject(projectId, res.data, this._queryVariables).subscribe(result => {
            this._snackBar.open(modalTitle, "Le projet a été mis à jour", "success", 5000);
          }, error => {
            this._snackBar.openSnackBarError(modalTitle, "Le projet n'a pas été mis à jour", error);
          });
        }
      });
    }
  }

  /**
   * @description Open a confirm modal before delete project
   * @author Kevin Palade
   * @private
   * @param {string} projectId
   * @memberof ListingQuoteComponent
   */
  private beforeDeleteProject(projectId: string) {
    const item = this.dataList.find(data => data._id == projectId);
    this._modalService.openModal(ModalConfirmComponent,
      {
        title: "Etes-vous sur?",
        type: MODAL_TYPE.CONFIRM,
        data: {
          message: `Voulez vous supprimer le projet <b>${item.name}</b>?`,
          actions: [ "Supprimer le projet de manière définitive" ],
        },
        params: { projectId: projectId },
        confirmCallback: this.deleteProject
      }
    );
  }

  /**
   * @description Delete project
   * @memberof ListingQuoteComponent
   */
  public deleteProject = (params: any) => {
      if (this._deleteProjectSub) { this._deleteProjectSub.unsubscribe(); }
      this._deleteProjectSub = this._quotesProjectQueriesSrv.deleteQuoteProject(params.projectId).subscribe(result => {
        if (result.data["deleteQuoteProject"]) {
          const variables = JSON.parse(JSON.stringify(this._queryVariables));
          variables.pagination.limit = variables.pagination.limit * variables.pagination.page;
          variables.pagination.page = 1;
          this.reloadProject(variables);
          this._snackBar.open("Supprimer un projet", "Le projet a été supprimé", "success", 5000);
        }
      });
      if(!this.isDelete){
        this._snackBar.openSnackBarError("Supprimer un projet dans devis", "Le projet n'est pas  supprimé car vous n'avez pas l'autorisation!");
      }
  }

  /**
   * @description
   * @author Kevin Palade
   * @private
   * @param {*} queryVariables
   * @memberof ListingQuoteComponent
   */
  private reloadProject(queryVariables: any) {
    if (this._reloadProjectSub) { this._reloadProjectSub.unsubscribe(); }
    this._reloadProjectSub = this._quotesProjectQueriesSrv.reloadQuoteProject(queryVariables).subscribe(result => {
      this.noLoadMore = result.data["quoteProjects"].length < queryVariables.pagination.limit ? true : false;
      this.dataList = result.data["quoteProjects"];
    });
  }

  /**
   * @description Set the status (list of projects or list of quotes)
   * @author Kevin Palade
   * @param {boolean} status
   * @memberof ListingQuoteComponent
   */
  public setStatus(status: boolean) {
    this.isQuote = status;
    // this.resetVariables();
    if (this.isQuote) {
      this.listQuotes();
    } else {
      this.listProjectQuotes();
    }
  }

  /**
   * @description Reset the request variables
   * @author Kevin Palade
   * @private
   * @memberof ListingQuoteComponent
   */
  private resetVariables() {
    this._queryVariables = {
      search: "",
      sort: { sortBy: "CREATED_AT", sortDirection: "DESC" },
      pagination: {page: 1, limit: PAGINATION_LIMIT}
    };
  }


  /**
   * @description Send the request with the filters according to the chosen mode
   * @author Rousseau Corentin
   * @param {*} event
   * @memberof ListingQuoteComponent
   */
  public choiceTableFilter(event) {
    localStorage.setItem('dataQuotesRecherche', JSON.stringify({"recherche":event}));
    localStorage.setItem('dataQuotesPagination', JSON.stringify({"pagination":1}));
    this.resetVariables();
    this._queryVariables.search = event;
    this.isQuote ? this.listQuotes() : this.listProjectQuotes();
  }


  /**
   * @description Sends the "Load more" request according to the chosen mode
   * @author Rousseau Corentin
   * @param {*} event
   * @memberof ListingQuoteComponent
   */
  public setPagination(event) {
    localStorage.setItem('dataQuotesPagination', JSON.stringify({"pagination":event}));
    this._queryVariables.pagination.page = event;
    if (this.isQuote) {
      this.listQuotes();
    } else {
      this.listProjectQuotes();
    }
  }
}
