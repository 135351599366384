import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "app-ticket-empty",
  templateUrl: "./ticket-empty.component.html",
  styleUrls: ["./ticket-empty.component.css"]
})
export class TicketEmptyComponent implements OnInit {
  @Input("quote") public quote;
  constructor() { }

  ngOnInit() {
  }

}
