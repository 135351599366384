import { CustomElement } from "./custom.base";
import { forkJoin } from "rxjs";
import { IWeldiable } from "../../assemblages/welding";
import { Matter } from "app/facade/interfaces";
import { SQUARE_MM_TO_SQUARE_M, MM_TO_M } from "../../base";
import { AdditionalComputingCreate } from "./custom.service";
import { ILaserCuttable } from "../../operations/laser-cutting";

export interface PlatineProperty {
    width: number;
    height: number;
    matter: Matter;
    thickness: number;
    elementForWelding: IWeldiable;
    margin?: number;
    holeQuantity?: number;
    holeDiameter?: number;
}

export abstract class Platine<T extends PlatineProperty> extends CustomElement implements IWeldiable, ILaserCuttable {

    public constructor(
        name: string,
        protected createAdditionalComputing: AdditionalComputingCreate,
        protected properties: T) {
        super(name);
        const computings = Object.keys(this.createAdditionalComputing).map(key => this.createAdditionalComputing[key](this));
        forkJoin(computings).subscribe(additionalComputings => {
            additionalComputings.forEach(({ name: computingName, origin }) => this.setAdditionalComputings(computingName, origin));
            this.$ready.next(true);
            this.$ready.complete();
        });
    }

    public get Matter(): Matter {
        return this.properties.matter;
    }

    public set margin(value: number) {
        this.properties.margin = value;
        this.elementUpdated();
    }

    public set width(value: number) {
        this.properties.width = value;
        this.elementUpdated();
    }

    public set height(value: number) {
        this.properties.height = value;
        this.elementUpdated();
    }

    public set thickness(value: number) {
        this.properties.thickness = value;
        this.elementUpdated();
    }

    public set elementForWelding(value: IWeldiable) {
        this.properties.elementForWelding = value;
        this.elementUpdated();
    }

    public get elementForWelding(): IWeldiable {
        return this.properties.elementForWelding;
    }

    public get Area(): number {
        return this.properties.width * this.properties.height;
    }

    public get AreaWithMargin(): number {
        return (this.properties.width + (this.properties.margin * 2)) * (this.properties.height + (this.properties.margin * 2));
    }

    public get perimeter(): number {
        return (this.properties.width + this.properties.height) * 2;
    }

    public get perimeterWithMargin(): number {
        return (this.properties.width + this.properties.height + (this.properties.margin * 4)) * 2;
    }

    public get CalculatedPrice(): number {
        let price = +this.matterPrice.toFixed(3);
        Object.keys(this.AdditionalComputings).forEach(key => {
            price += this.AdditionalComputings[key].origin.Price;
        });

        return price;
    }

    public get hasWeldingLength(): boolean {
        return true;
    }

    public get TotalArea(): number {
        return this.Area + (4 * this.properties.thickness);
    }

    public get TotalWeight(): number {
        const volume: number = (this.AreaWithMargin * SQUARE_MM_TO_SQUARE_M) * (this.properties.thickness * MM_TO_M);
        const liter: number = volume * 1000;
        const { kgByLiter } = this.properties.matter;
        return liter * kgByLiter;
    }

    public get matterPrice(): number {
        const { pricePerKg } = this.properties.matter;
        return this.TotalWeight * pricePerKg;
    }

    public get thickness(): number {
        return this.properties.thickness;
    }

    public get Thickness(): number {
        return this.properties.thickness;
    }

    public get meterForCutting(): number {
        return this.perimeterWithMargin;
    }
}