import { Component, OnInit, Input, SimpleChanges, OnChanges, OnDestroy } from "@angular/core";
import { Subscription } from "rxjs";
import { CatalogQueriesService } from "../../../../facade/queries/catalog/async/catalog_queries.service";
import { TABLE_ITEM_ACTION_TYPE } from "app/presentationnal/organisms/table/table-item/enum/table-item-action-type.enum";
import { CatalogService } from "app/facade/queries/catalog/sync/catalog.service";
import { SnackService } from "app/presentationnal/organisms/snack-bar/services/snack.service";
import { ElementGroup } from "app/facade/interfaces";
import { HttpClient } from "@angular/common/http";
import { cloneDeep } from "apollo-utilities";
import { ISelectOption } from "app/presentationnal/atoms/inputs/select-input/selectOptions";
import { ModalService } from "app/presentationnal/organisms/modal/services/modal.service";
import { ModalConfirmComponent } from "app/presentationnal/organisms/modal";
import { MODAL_TYPE } from "app/presentationnal/organisms/modal/enums/modal-type.enum";

@Component({
  selector: "app-catalog-table",
  templateUrl: "./catalog-table.component.html",
  styleUrls: ["./catalog-table.component.css"]
})
export class CatalogTableComponent implements OnInit, OnChanges, OnDestroy {
  private _elementGroupSub: Subscription;
  private _queryVarChangedSub: Subscription;
  private _elementGroup: ElementGroup;
  private _tableDatas: any;
  public editableRowId: string | number = null;
  public defaultValues: any;
  public matterOptions: ISelectOption[] = [];
  public elementNatureDefinitions: any[];
  public imgPath;
  public catalogCategorie = "catalog";

  @Input("elementGroupSelected") elementGroupSelected: any;

  constructor(private _catalogQueriesSrv: CatalogQueriesService,
              private _catalogSrv: CatalogService,
              private _snackBarSrv: SnackService,
              private _modalService: ModalService,
              private _http: HttpClient) { }

  ngOnInit() {
    this._queryVarChangedSub = this._catalogSrv.queryVariablesChanged.subscribe(res => {
      if (res) {
        this.getElementGroupById();
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    this.getElementGroupById();
  }

  ngOnDestroy() {
    if (this._elementGroupSub) { this._elementGroupSub.unsubscribe(); }
    if (this._queryVarChangedSub) { this._queryVarChangedSub.unsubscribe(); }
  }

  /**
   * @description Get the specific element group and generate the datas for the table
   * @author Kevin Palade
   * @private
   * @memberof CatalogTableComponent
   */
  private getElementGroupById() {
    if (this._elementGroupSub) { this._elementGroupSub.unsubscribe(); }
    this._elementGroupSub = this._catalogQueriesSrv.getElementGroupByIdForTable(this.elementGroupSelected.id).valueChanges.subscribe( result => {
      this._elementGroup = result.data["getElementGroupById"];
      this.matterOptions = this._elementGroup.availableMatters.map( matter => {
        return {
          value: matter.id,
          label: matter.en1090Name || matter.name
        };
      });
      this.imgPath = "../../../../../assets/img/catalog/" + this._elementGroup.icon + ".jpg";

      const newElementNatureDefinitions: any[] = cloneDeep(this._elementGroup.elementNatureDefinitions);
      newElementNatureDefinitions.unshift({ name: "Matiere", redefine: true, nullable: false, regex: null, type: "SELECT", options: this.matterOptions });
      newElementNatureDefinitions.unshift({ name: "Nom", redefine: false, nullable: false, regex: null, type: "STRING" });

      const tableDatasBeforeFilter = this._elementGroup.elements.map(el => {
        return { id: el.id, Nom: el.name, Matiere: el.matter.id , ...el.natureValues };
      });
      this._tableDatas = this._catalogSrv.sortAndFilter(tableDatasBeforeFilter, "Nom");
      this.defaultValues = { Matiere: "ST37" };
      this.elementNatureDefinitions = newElementNatureDefinitions;
    });
  }

  /**
   * @description on table click callback with the actionType
   * @author Kevin Palade
   * @private
   * @param {({ itemId: number | string, actionType: number, value?: any })} event
   * @memberof CatalogTableComponent
   */
  public tableButtonClicked(event: { itemId: number | string, actionType: number, value?: any }) {
    if (event && event.actionType !== null) {
      switch (event.actionType) {
        case TABLE_ITEM_ACTION_TYPE.EDIT:
          this.editableRowId = event.itemId;
          break;
        case TABLE_ITEM_ACTION_TYPE.CANCEL:
          this.editableRowId = null;
          break;
        case TABLE_ITEM_ACTION_TYPE.SAVE:
          this.saveElement(+event.itemId, event.value);
          break;
        case TABLE_ITEM_ACTION_TYPE.DELETE:
          this.beforeDeleteQuote(+event.itemId);
          break;
        default:
          throw new Error("Error: action not defined!");
      }
    }
  }

  /**
   * @description Save an element (update) // Todo add
   * @author Kevin Palade
   * @private
   * @param {number} elementId
   * @param {*} element
   * @memberof CatalogTableComponent
   */
  private saveElement(itemId: number, element) {
    if (element) {
      const elementToSave = this.formatElementBeforeSave(element);
      if (itemId) {
        const snackBarTitle: string = "Mise à jour d'un élément";
        this._catalogQueriesSrv.updateElement(itemId, elementToSave, this.elementGroupSelected.id).subscribe(res => {
          this._snackBarSrv.open(snackBarTitle, "L'élément a été correctement mis à jour", "success", 5000);
          this.editableRowId = null;
        }, err => {
          this._snackBarSrv.openSnackBarError(snackBarTitle, "L'élément n'a pas été mis à jour", err);
        });
      } else {
        const snackBarTitle: string = "Ajout d'un élément";
        elementToSave["elementGroupId"] = +this.elementGroupSelected.id;
        this._catalogQueriesSrv.addElement(elementToSave, this.elementGroupSelected.id).subscribe(res => {
          this._snackBarSrv.open(snackBarTitle, "L'élément a été correctement ajouté", "success", 5000);
        }, err => {
          this._snackBarSrv.openSnackBarError(snackBarTitle, "L'élément n'a pas été ajouté", err);
        });
      }
    }
  }

  private formatElementBeforeSave(data: any) {
    const elementToSave = { name: null, natureValues: {}, matterId: null };
    Object.keys(data).forEach( el => {
      if (el.toLowerCase() == "nom" || el.toLowerCase() == "name" ) {
        elementToSave.name = data[el];
      } else if (el.toLowerCase() == "matiere" || el.toLowerCase() == "matter" ) {
        elementToSave.matterId = +data[el];
      } else {
        elementToSave.natureValues[el] = data[el];
      }
    });
    return elementToSave;
  }

  private beforeDeleteQuote(elementId: number) {
    const element: any = this._tableDatas.find( data => +data.id === elementId),
          matterData: any = this.matterOptions.find( matter => +matter.value === +element.Matiere);
    this._modalService.openModal(ModalConfirmComponent,
      {
        title: "Etes-vous sûr ?",
        type: MODAL_TYPE.CONFIRM,
        data: {
          message: `Voulez-vous supprimer l'élément <b>${element.Nom}</b> dans la matière <b>${matterData.label}</b> ?`,
          actions: ["Supprimer l'élément de manière définitive"],
        },
        params: { elementId },
        confirmCallback: this.deleteElement
      }
    );
  }

  /**
   * @description Delete an element
   * @author Kevin Palade
   * @private
   * @param {number} elementId
   * @memberof CatalogTableComponent
   */
  // TODO: Test when back will be adapted > Delete 3 test in Galva
  public deleteElement = (params: any) => {
    const snackBarTitle: string = "Suppression d'un élément";
    this._catalogQueriesSrv.deleteElement(params.elementId, this.elementGroupSelected.id).subscribe(res => {
      this._snackBarSrv.open(snackBarTitle, "L'élément a été correctement supprimé", "success", 5000);
    }, err => {
      this._snackBarSrv.openSnackBarError(snackBarTitle, "L'élément n'a pas été supprimé", err);
    });
  }
}


