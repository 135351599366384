/*
 * @Author: Kevin Palade
 * @Date: 2018-10-29 11:51:39
 * @Last Modified by: Kevin Palade
 * @Last Modified time: 2018-11-16 16:29:46
 */
import { Component, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { AuthService } from "@lib/auth/auth.service";
import { Router } from "@angular/router";
import { ApplicationConfig } from "app/app.config";

@Component({
    selector: "app-login",
    templateUrl: "./login.component.html",
    styleUrls: ["./login.component.css"]
})
export class LoginComponent implements OnInit {
    public appName: string = ApplicationConfig.AppName;
    public formGroup: FormGroup;
    public errorMessage: string;

    constructor(private _fb: FormBuilder,
        private _authService: AuthService,
        private _router: Router) { }

    ngOnInit() {
        this.initForm();
    }

    private initForm() {
        this.formGroup = this._fb.group({
            username: [null, Validators.required],
            password: [null, Validators.required]
        });
    }

    private login() {
        this.errorMessage = null;
        this._authService.login(this.formGroup.get("username").value, this.formGroup.get("password").value).subscribe(
            result => {
                this._router.navigate(["/"]);
            },
            error => {
                if (error.status == "401") {
                    this.errorMessage = "Email or password is incorrect";
                } else {
                    this.errorMessage = "Server is unreachable, please contact ITDM";
                }
            });
    }

    private isLoginDisabled() {
        return !this.formGroup.get("username").value || !this.formGroup.get("password").value;
    }

    clearNavigationHistory() {
        localStorage.clear();
        window.location.reload(true);
    }

}
