import { Component, OnInit, Inject, OnDestroy } from "@angular/core";
import { FormGroup, FormBuilder, Validators, AbstractControl } from "@angular/forms";
import { CONTAINER_DATA, StepFormService, ITicketElement, ActualData } from "app/presentationnal/organisms/wizard-modal/service/step-form.service";
import { FinishService, FinishAvailable } from "app/facade/quote-element-definition/finish/finish.service";
import { Galvanization } from "app/facade/quote-element-definition/finish/galvanization";
import { FinishProperty } from "app/facade/quote-element-definition/finish/finish.base";
import { Subscription } from "rxjs";
import { GalvaFormulaDisplayer } from "app/facade/quote-element-definition/formula.display";

@Component({
  selector: "app-galvanization-step-one",
  templateUrl: "./galvanization-step-one.component.html",
  styleUrls: ["./galvanization-step-one.component.css"]
})
export class GalvanizationStepOneComponent implements OnInit, OnDestroy {

  public isFixe: boolean;
  public dataTicket: ITicketElement[];
  public useClass: FinishAvailable;
  public galvaStepOneForm: FormGroup;

  private _actualData: ActualData;
  private _finishData: Galvanization<FinishProperty>;
  private _isEdit: boolean = true;

  private _formulaDisplayer: GalvaFormulaDisplayer;
  private _formulaSub: Subscription;
  public calculs: string[];

  constructor(
    @Inject(CONTAINER_DATA) data: any,
    private _stepFormSrv: StepFormService,
    private _finishSrv: FinishService,
    private _fb: FormBuilder
  ) {
    this.dataTicket = data.ticket;
    this.useClass = "Galvanization";
    this._actualData = this._stepFormSrv.get();
    this._finishData = this._actualData.element;
  }

  ngOnInit() {
    this.initform();
    this.calculs = [];
    this._formulaDisplayer = new GalvaFormulaDisplayer();
    this._formulaSub = this._formulaDisplayer.$GalvaPrice.subscribe(calculs => this.calculs = calculs);
    this.showCalcul();
  }

  ngOnDestroy(): void {
    this._formulaSub.unsubscribe();
  }

  private checkCostRequired(control: AbstractControl) {
    if (control.parent && control.parent.get("fixedPrice").value && !control.value) {
      return { required: true };
    }
    return null;
  }

  private checkWeightCostRequired(control: AbstractControl) {
    if (control.parent && !control.parent.get("fixedPrice").value && !control.value) {
      return { required: true };
    }
    return null;
  }

  public initform () {
    if (!this._finishData) {
      this._finishData = this._finishSrv.create("Galvanization", "Galvanisation", this.dataTicket, { cost: null, fixedPrice: false });
      this._isEdit = false;
    }

    this.galvaStepOneForm = this._fb.group({
      totalWeight: this._finishData.Weight,
      totalSurface: this._finishData.Area,
      cost: [this._finishData.Cost, this.checkCostRequired],
      weightCost: [this._finishData.WeightCost, this.checkWeightCostRequired],
      fixedPrice: this._finishData.FixedPrice
    });
    this.isFixe = this._finishData ? this._finishData.FixedPrice : false;

    this.galvaStepOneForm.get("fixedPrice").valueChanges.subscribe(result => {
      this.isFixe = result;
      this.galvaStepOneForm.get("cost").updateValueAndValidity();
      this.galvaStepOneForm.get("weightCost").updateValueAndValidity();
    });
  }

  public next() {
    if (this.galvaStepOneForm && this.galvaStepOneForm.valid) {
      let elementIndex: number;
      const finish = this._finishData;
      finish.Cost = this.galvaStepOneForm.get("cost").value;
      finish.WeightCost = this.galvaStepOneForm.get("weightCost").value;
      finish.FixedPrice = this.galvaStepOneForm.get("fixedPrice").value;
      if (this._isEdit) {
        // Only save totalSurface & Weight if they were overriden by user
        if (this.galvaStepOneForm.get("totalSurface").touched) { finish.Area = this.galvaStepOneForm.get("totalSurface").value; }
        if (this.galvaStepOneForm.get("totalWeight").touched) { finish.Weight = this.galvaStepOneForm.get("totalWeight").value; }
        elementIndex = this._actualData.index;
        this._stepFormSrv.updateElement(finish, null, elementIndex);
      } else {
        this._stepFormSrv.addElement(finish);
        elementIndex = this._stepFormSrv.getLastElementSelected();
      }
      this._stepFormSrv.change({
        element: finish,
        index: elementIndex
      });
      this._stepFormSrv.closeModal$.next(true);
    } else {
      this._stepFormSrv.markFormGroupTouched(this.galvaStepOneForm);
    }
  }

  public cancel() {
    if (!this._actualData.isEdited && this._actualData.index !== undefined) {
      this._stepFormSrv.deleteElement(this._actualData.index);
    }
    this._stepFormSrv.closeModal$.next(true);
  }

  private showCalcul() {
    this.galvaStepOneForm.valueChanges.subscribe(values => {
      const base = {
        totalWeight: values.totalWeight,
        cost: values.cost || 0,
        weightCost: values.weightCost || 0,
        fixedPrice: values.fixedPrice || false
      };

      if (Object.keys(base).every(k => base[k] != null)) {
        this._formulaDisplayer.base = base;
      }
    });

    const init = {
      totalWeight: this.galvaStepOneForm.value.totalWeight,
      cost: this.galvaStepOneForm.value.cost || 0,
      weightCost: this.galvaStepOneForm.value.weightCost || 0,
      fixedPrice: this.galvaStepOneForm.value.fixedPrice || false
    };

    if (Object.keys(init).every(k => init[k] != null)) {
      this._formulaDisplayer.base = init;
    }
  }

}
