import { Component, OnInit, Input } from "@angular/core";
import { BreadcrumbService } from "./breadcrumbs.service";

@Component({
  selector: "app-breadcrumbs",
  templateUrl: "./breadcrumbs.component.html",
  styleUrls: ["./breadcrumbs.component.css"]
})
export class BreadcrumbsComponent implements OnInit {

  public items = [{name: "test001", path: "/quote"}, {name: "test00", path: "/quote/details"} ];
  @Input() breadName;

  constructor(private _breadcrumbSrv: BreadcrumbService) { }

  ngOnInit() {
  }

}
