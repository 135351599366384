import { Injectable } from "@angular/core";
import { CanDeactivate, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";

@Injectable({
  providedIn: "root"
})
export class EditedFormService implements CanDeactivate<any> {
  private hasEditedForm: boolean = false;

  constructor() { }

  public set EditedForm(value: boolean) {
    this.hasEditedForm = value;
  }

  public get EditedForm(): boolean {
    return this.hasEditedForm;
  }

  /**
   * @description Use to deactivate route when we edit a form (or other case in futur)
   * @author Lainez Eddy
   * @param {*} component
   * @param {ActivatedRouteSnapshot} currentRoute
   * @param {RouterStateSnapshot} currentState
   * @param {RouterStateSnapshot} [nextState]
   * @returns
   * @memberof EditedFormService
   */
  canDeactivate(component: any, currentRoute: ActivatedRouteSnapshot, currentState: RouterStateSnapshot, nextState?: RouterStateSnapshot) {
    return !this.hasEditedForm;
  }


}
