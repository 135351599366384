import { Component, OnInit, OnChanges, Renderer2, SimpleChanges, ViewChild, ElementRef, Input, HostListener, AfterViewInit } from "@angular/core";
import { AInputs } from "../inputs";
import { Subject } from "rxjs";
import { debounceTime } from "rxjs/operators";
import { ISelectOption } from "../select-input/selectOptions";

@Component({
  selector: "app-suggest-input",
  templateUrl: "./suggest-input.component.html",
  styleUrls: ["./suggest-input.component.css"]
})
export class SuggestInputComponent extends AInputs implements OnInit, OnChanges, AfterViewInit {

  public valueSelect;
  public isClosed: boolean = true;
  private _debouncer: Subject<any> = new Subject();
  private _isClear: boolean = false;
  private _isOptionSelectedAction: boolean = false;
  // private handler: any;
  @Input() public actionToDo: any = null;
  @Input() public addedData: any = {};
  @Input() public linkedField: string = "";
  @Input() public options: ISelectOption[] = [];
  @ViewChild("inputFocus") inputFocus: ElementRef;
  @ViewChild("list") list: ElementRef;
  public filteredOptions: ISelectOption[];
  public findLinkedField: boolean = false;
  public hoveredItem: any = null;
  public hoveredItemIndex: number = null;
  public titleIcon: string = "";

  @HostListener("keydown", ["$event"]) onKeydownHandler(event: KeyboardEvent) {

    let height = 0;
    let top = 0;
    let menuHeight = 0;
    if (this.hoveredItemIndex) {
      height = this.list.nativeElement.querySelector(".select__list-item_hovered").offsetHeight;
      top = this.list.nativeElement.scrollTop;
      menuHeight = this.list.nativeElement.scrollHeight;
    }
    switch (event.keyCode) {
      case 38: // ArrowUp
        if (this.hoveredItemIndex > 0) {
          this.hoveredItemIndex--;
          this.hoveredItem = this.filteredOptions[this.hoveredItemIndex];
          // Set the scroll
          this.list.nativeElement.scrollTop = top - height;
        }
        break;
      case 40: // ArrowDown
        if (this.hoveredItemIndex < this.filteredOptions.length - 1) {
          this.hoveredItemIndex++;
          this.hoveredItem = this.filteredOptions[this.hoveredItemIndex];
        }
        // Calcul for scroll
        const nextHeight = top + height; // Next scrollTop height
        const maxHeight = menuHeight - height; // Max scrollTop height
        if (nextHeight <= maxHeight) {
            this.list.nativeElement.scrollTop = top + height;
        } else {
            this.list.nativeElement.scrollTop = 0;
        }
        break;
      case 13: // Enter
        this.close(this.hoveredItem);
        event.stopPropagation();
        break;
    }
  }

  constructor(protected renderer: Renderer2, private _elRef: ElementRef) {
    super(renderer);
    this._debouncer.pipe(debounceTime(200)).subscribe( value => {
      if (this.actionToDo && !this._isOptionSelectedAction) {
        this.actionToDo({isSearch: true, formGroup: this.group, options: this.options});
      }
      this.filterOptions(value);
      this._isOptionSelectedAction = false;
    });
  }

  ngOnInit() {
    if (this.linkedField !== "") {
      this.findLinkedField = this.group.get(this.linkedField).value ? true : false;
      this.group.get(this.linkedField).valueChanges.subscribe(result => {
        this.findLinkedField = result ? true : false;
        this.setTitleIcon();
      });
      this.setTitleIcon();
    }
  }

  ngAfterViewInit() {
    if (this.autofocus) {
      setTimeout(() => {
        this.open();
        this.inputFocus.nativeElement.focus();
      }, 400);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);
    if (this._isClear) {
      this._isClear = false;
      this.inputFocus.nativeElement.focus();
    }
    this.filterOptions(this.group.get(this.name).value);
  }

  clearSelect(event: Event) {
    this._isClear = true;
    if (event) { event.stopPropagation(); }
    this.changeValue("", false);
    this.callActionToDo({});
    this.inputFocus.nativeElement.focus();
    // WARNING: When opening, we can open all suggest display on the page with the clearButton
    // this.open();
  }

  private setTitleIcon() {
    this.titleIcon = this.findLinkedField ? "Correspondance en base de données" : "Il n'y a pas de correspondance en base de données";
  }

  // private checkClose(event) {
  //   const clickedInside = this._elRef.nativeElement.contains(event.target);
  //   if (!clickedInside && !this.isClosed) { this.close(null); }
  // }

  public open() {
    this.inputReference.nativeElement.focus();
    this.isClosed = false;
    // Subscribe
    // if ( this.handler ) { this.handler(); }
    // this.handler = this.renderer.listen(document, "click", event => this.checkClose(event));
  }

  public close(option: any): void {
    this.callActionToDo(option);
    this.isClosed = true;
    // Unsubscribe
    // this.handler();
  }

  public focusOut() {
    setTimeout(() => {
      this.isClosed = true;
      // this.handler();
    }, 200);
  }

  private callActionToDo(option: any) {
    if (option && option.label !== undefined) {
      this._isOptionSelectedAction = true;
      this.changeValue(option.label);
    }
    if (this.actionToDo) {
      this.actionToDo({ ...option, formGroup: this.group, addedData: this.addedData});
    }
  }

  public changeValue(event: string, openOptions: boolean = true) {
    this.group.get(this.name).patchValue(event);
    this._debouncer.next(event);
    if (openOptions) {
      this.isClosed = false;
    }
  }

  public filterOptions(data) {
    if (data) {
      this.filteredOptions = this.options.filter( option => option.label.toLowerCase().indexOf(data.toLowerCase()) !== -1);
    } else {
      this.filteredOptions = this.options;
    }

    // set the hovered item on the first option if exist
    if (this.filteredOptions && this.filteredOptions.length > 0) {
      this.hoveredItem = this.filteredOptions[0];
      this.hoveredItemIndex = 0;
    } else {
      this.hoveredItem = null;
      this.hoveredItemIndex = null;
    }

  }

}
