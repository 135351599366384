import { Component, OnInit, OnDestroy } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ModalService } from "app/presentationnal/organisms/modal/services/modal.service";
import { SnackService } from "app/presentationnal/organisms/snack-bar/services/snack.service";
import { ProjectsQueriesService } from "app/facade/queries/project/projects-queries.service";
import { ModalConfirmComponent, ModalAddProjectComponent } from "app/presentationnal/organisms/modal";
import { MODAL_TYPE } from "app/presentationnal/organisms/modal/enums/modal-type.enum";
import { Subscription } from "rxjs";
import { IProject, ISupplyList } from "app/facade/interfaces/project";
import { IInformationsCardConfig } from "app/facade/interfaces/informations-card-data.interface";
import { IContainerCardsConfig } from "app/facade/interfaces/container-cards-config.interface";
import { CARD_ACTION_TYPE } from "app/facade/enums/card-action-type.enum";
import { IModalData } from "app/presentationnal/organisms/modal/interfaces/modal-data.interface";
import { ModalAddSupplyListComponent } from "app/presentationnal/organisms/modal/components/modal-add-supply-list/modal-add-supply-list.component";
import * as moment from "moment";
import { deepClone } from "@lib/misc/clone";
import { PurchaseOrderQueriesService } from "app/facade/queries/purchase-order/purchase-order-queries.service";
import { IPurchaseOrder, ICustomPurchaseOrder } from "app/facade/interfaces/purchase-order.interface";
import { TableDatasDefinition } from "app/facade/interfaces/table-datas-definition";
import { purchaseOrderStatusConfig } from "app/facade/configs/status.config";
import { TABLE_ITEM_ACTION_TYPE } from "app/presentationnal/organisms/table/table-item/enum/table-item-action-type.enum";
import { ApplicationConfig } from "app/app.config";
import { MODAL_CLOSE_ACTION } from "app/presentationnal/organisms/modal/enums/modal-close-action.enum";
import {IPagination} from "../../../../facade/interfaces/crud.interface";
import {ListTableService} from "../../../../facade/services/list-table.service";

const DEFAULT_LIMIT: number = 25;

@Component({
  selector: "app-project-details",
  templateUrl: "./project-details.component.html",
  styleUrls: ["./project-details.component.css"]
})
export class ProjectDetailsComponent implements OnInit, OnDestroy {
  private _deleteProjectSub: Subscription;
  private _getProjectSub: Subscription;
  private _getSupplyListSub: Subscription;
  private _deleteSupplyListSub: Subscription;
  private _setSupplyListSub: Subscription;
  private _createPriceRequestSub: Subscription;

  private _projectId: number;
  public projectData: IProject;
  public supplyListDatas: ISupplyList[] = [];
  public informationsCardConfig: IInformationsCardConfig;
  public containerCardsConfig: IContainerCardsConfig = null;

  public purchaseOrderTableHeaders: string[] = [
    "Référence",
    "Fournisseur",
    "Date de création",
    "Créé par",
    "Projet",
    "Demande de prix",
    "Status",
    "Remarque",
    "actions"];
  public purchaseOrderDataDefinition: TableDatasDefinition[] = [
    { key: "reference", isTitle: true},
    { key: "supplier", isTitle: false},
    { key: "createdAt", isTitle: false},
    { key: "createdBy", isTitle: false},
    { key: "project", isTitle: false},
    { key: "priceRequest", isTitle: false},
    { key: "status", isTitle: false, isStatus: true, statusConfig: purchaseOrderStatusConfig},
    { key: "remark", isTitle: false},
  ];
  public purchaseOrderDatas: ICustomPurchaseOrder[] = [];

  private _pagination: IPagination = { page: 1, limit: DEFAULT_LIMIT };

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private _projectsQueriesSrv: ProjectsQueriesService,
    private _purchaseOrderQueriesSrv: PurchaseOrderQueriesService,
    private _modalService: ModalService,
    private _listTableSrv: ListTableService,
    private _snackBar: SnackService
  ) {
    this._projectId = +this.route.snapshot.paramMap.get("id");
    this.getProjectData();
    this.getSupplyListData();
    this.getPurchaseOrderData();
  }

  ngOnInit() {
  }

  ngOnDestroy(): void {
    this._getProjectSub.unsubscribe();
    this._getSupplyListSub.unsubscribe();
    if (this._deleteProjectSub) { this._deleteProjectSub.unsubscribe(); }
    if (this._deleteSupplyListSub) { this._deleteSupplyListSub.unsubscribe(); }
    if (this._setSupplyListSub) { this._setSupplyListSub.unsubscribe(); }
    if (this._createPriceRequestSub) { this._createPriceRequestSub.unsubscribe(); }
  }

  /* BEGIN SUPPLY LIST METHODES */
  private getSupplyListData() {
    this._getSupplyListSub = this._projectsQueriesSrv.getSupplyLists({projectId: this._projectId}).subscribe( result => {
      const data: any = result.data;
      if (data && data.supplyLists) {
        this.supplyListDatas = data.supplyLists.map(supplyList => {
          return {
            ...supplyList,
            deliveryDate: this.formatDate(supplyList.deliveryDate),
            createdAt: this.formatDate(supplyList.createdAt),
            priceRequest: supplyList.priceRequest ? supplyList.priceRequest.reference : null,
          };
        });
      }
    }, error => {
      console.log("ERROR LOADING SUPPLY LISTS > ", {error});
    });

    this.containerCardsConfig = {
      title: "Listes d'approvisionnement",
      actions: [
        {label: "Ajouter Liste", fct: this.newSupplyList}
      ],
      itemConfig: {
        fieldsToDisplay: [
          {key: "description", label: "Description"},
          {key: "deliveryDate", label: "Date livraison au plus tard"},
          {key: "model", label: "Model"},
          {key: "source", label: "Source"},
          {key: "createdAt", label: "Date de création"},
          {key: "priceRequest", label: "Demande de prix"}
        ],
        actions: [
          { label: "Supprimer", fct: this.beforeDeleteSupplyList, type: CARD_ACTION_TYPE.DELETE },
          { label: null, fct: this.newSupplyList, type: CARD_ACTION_TYPE.SETTINGS, actions: [
            { label: "Créer une demande de prix", fct: this.beforeCreatePriceRequest }
          ]},
          { label: "Editer", fct: this.setSupplyList, type: CARD_ACTION_TYPE.PRIMARY },
        ]
      },
      emptyContent: {
        text: "Pas de liste d'approvisionnement !",
        button: {label: "Ajouter une liste d'approvisionnement", fct: this.newSupplyList}
      }
    };
  }

  private beforeCreatePriceRequest = (data: ISupplyList) => {
    if (!data.priceRequestId) {
      const modalData: IModalData = {
        title: "Êtes-vous sûr ?",
        type: MODAL_TYPE.CONFIRM,
        data: {
          message: `Voulez-vous créer une nouvelle demande de prix avec la liste d'approvisionnement <b>${data.description ? data.description + " " : ""}</b>?`
        },
        params: { supplyList: data },
        confirmCallback: this.createPriceRequest,
        customData: {
          deleteButtonName: "Créer",
          deleteButtonColor: "green"
        }
      };
      this._modalService.openModal(ModalConfirmComponent, modalData);
    } else {
      this._snackBar.open("Création d'une demande de prix", "Une demande de prix a déjà été attribuée à cette liste d'approvisionnement", "info", 5000);
    }
  }

  private createPriceRequest = ( params: {supplyList: ISupplyList} ) => {
    const snackBarTitle: string = "Création d'une demande de prix";
    this._createPriceRequestSub = this._projectsQueriesSrv.createPriceRequestBySupplyList(params.supplyList.id).subscribe( result => {
      const resultData: any = result.data;
      if ( resultData && resultData.createPriceRequestBySupplyList) {
        this.router.navigate([`/price-request/edit/${+resultData.createPriceRequestBySupplyList.id}`]);
        this._snackBar.open(snackBarTitle, "La demande de prix a été créée", "success", 5000);
      }
    }, error => {
      this._snackBar.openSnackBarError(snackBarTitle, "La demande de prix n'a pas été créée", error);
    });
  }

  private newSupplyList = () => {
    this.setSupplyList();
  }

  private setSupplyList = ( data: any = null ) => {
    const modalRef = this._modalService.openModal(
      ModalAddSupplyListComponent,
      {
        title: data ? "Modification d'une liste d'approvisionnement" : "Création d'une liste d'approvisionnement",
        type: MODAL_TYPE.NORMAL,
        data: {
          supplyListData: data,
          projectId: this._projectId
        },
        modalStyle: {
          modalWidth: "x-large"
        }
      }
    );
    if (modalRef) {
      modalRef.afterClosed.subscribe(res => {
        if (res.confirm && res.data) {
          const newSupplyListData: ISupplyList = {
            ...res.data,
            deliveryDate: this.formatDate(res.data.deliveryDate),
            createdAt: this.formatDate(res.data.createdAt, "DD/MM/YYYY HH:mm")
          };
          if (res.isEdited) {
            this.supplyListDatas = this.supplyListDatas.map( supplyList => {
              return (+supplyList.id === +res.data.id) ? newSupplyListData : supplyList;
            });
          } else {
            this.supplyListDatas.unshift(newSupplyListData);
          }
        }
      });
    }
  }

  private beforeDeleteSupplyList = (data: ISupplyList[]) => {
    const selectedSupplyLists: ISupplyList[] = data.filter(d => d.checked) || []
    const modalData: IModalData = {
      title: "Êtes-vous sûr ?",
      type: MODAL_TYPE.CONFIRM,
      data: {
        message: `Voulez-vous supprimer les liste d'approvisionnements <b>${selectedSupplyLists.map((d: ISupplyList)  => d.description).reduce((d1: any, d2: any) => `${d1 ? d1 + ',' : ''} ${d2 || ''}`, '')}</b>?`,
        actions: [ "Supprimer la liste d'approvisionnement de manière définitive" ]
      },
      params: { supplyListIds: selectedSupplyLists.map(s => s.id) },
      confirmCallback: this.deleteSupplyList
    };
    this._modalService.openModal(ModalConfirmComponent, modalData);
  }

  private deleteSupplyList = (params: any) => {
    const snackBarTitle: string = "Supprimer une liste d'approvisionnement";
    if (this._deleteSupplyListSub) { this._deleteSupplyListSub.unsubscribe(); }
    this._deleteSupplyListSub = this._projectsQueriesSrv.deleteSupplyLists(params.supplyListIds).subscribe(result => {
      const data: any = result.data;
      if (data && data.deleteSupplyLists) {
        this.supplyListDatas = this.supplyListDatas.filter( supplyList => {
          return !params.supplyListIds.includes(supplyList.id);
        });
        this._snackBar.open(snackBarTitle, "La liste d'approvisionnement a été supprimée", "success", 5000);
      } else {
        this._snackBar.openSnackBarError(snackBarTitle, "La liste d'approvisionnement n'a pas été supprimée");
      }
    }, error => {
      this._snackBar.openSnackBarError(snackBarTitle, "La liste d'approvisionnement n'a pas été supprimée", error);
    });
  }
  /* END SUPPLY LIST METHODES */

  /* BEGIN PROJECT METHODES */
  private getProjectData() {
    this._getProjectSub = this._projectsQueriesSrv.getProject(this._projectId).subscribe( result => {
      const data: any = result.data;
      if (data && data.project) {
        this.projectData = deepClone(data.project);
        this.projectData.createdAt = this.formatDate(data.project.createdAt);
      }
    }, error => {
      console.log("ERROR GET PROJECT > ", {error});
    });

    this.informationsCardConfig = {
      title: "Informations projet",
      actions: [
        {label: "Editer", fct: this.editProjectInformation}
      ],
      fieldsToDisplay: [
        {key: "reference", label: "Référence Projet"},
        {key: "createdAt", label: "Date de création"},
        {key: "isEn1090", label: "EN1090", isBoolean: true}
      ]
    };
  }

  private editProjectInformation = () => {
    const modalRef = this._modalService.openModal(
      ModalAddProjectComponent,
      {
        title: "Informations projet",
        type: MODAL_TYPE.NORMAL,
        data: this.projectData,
        modalStyle: {
          modalWidth: "tiny"
        },
        closeAction: MODAL_CLOSE_ACTION.UPDATE
      }
    );
    if (modalRef) {
      modalRef.afterClosed.subscribe(res => {
        if (res.confirm && res.data) {
          this.projectData = res.data;
          this.projectData.createdAt = this.formatDate(+this.projectData.createdAt);
        }
      });
    }
  }
  /* END PROJECT METHODES */


  /* BEGIN PURCHASE ORDER METHODES */
  public getPurchaseOrderData() {
    this._purchaseOrderQueriesSrv.getPurchaseOrderByProject(this._projectId, this._pagination).subscribe( result => {
      const resultData: {purchaseOrdersByProject: IPurchaseOrder[], pagination: any} = <any>result.data;
      if (resultData && resultData.purchaseOrdersByProject) {
        this.purchaseOrderDatas = this._pagination.page !== 1 ?
          [ ...this.purchaseOrderDatas, ...this.mapPurchaseOrders(resultData.purchaseOrdersByProject) ] :
          this.mapPurchaseOrders(resultData.purchaseOrdersByProject);
      }
      if (resultData && resultData.pagination) {
        this._listTableSrv.setLoadMoreStatus(resultData.pagination.hasNext);
      }
    }, error => {
      console.log("ERROR LOADING PURCHASE ORDER DATAS", {error});
    });
  }

  public mapPurchaseOrders (purchaseOrders: IPurchaseOrder[]) {
    return purchaseOrders.map( purchaseOrder => {
      return {
        id: purchaseOrder.id,
        reference: purchaseOrder.reference,
        supplier: purchaseOrder.supplier ? purchaseOrder.supplier.name : null,
        createdAt: this.formatDate(+purchaseOrder.createdAt, "DD/MM/YYYY HH:mm"),
        createdBy: `${purchaseOrder.user.lastname} ${purchaseOrder.user.firstname}`,
        project: purchaseOrder.project ?
          purchaseOrder.project.reference :
          purchaseOrder.linkedProjects.map( project => project.reference).join(", "),
        priceRequest: purchaseOrder.priceRequest ? purchaseOrder.priceRequest.reference : null,
        status: purchaseOrder.status,
        remark: purchaseOrder.remark,
        actionsList: [{
          label: "Consulter",
          type: TABLE_ITEM_ACTION_TYPE.EDIT,
          additionnalClass: "none"
        }]
      };
    });
  }

  public tableButtonClicked(event: {actionType: TABLE_ITEM_ACTION_TYPE, itemId: number}) {
    if (event.actionType === TABLE_ITEM_ACTION_TYPE.EDIT) {
      window.open(`${ApplicationConfig.getDomain()}/purchase-order/edit/${event.itemId}`);
    }
  }

  /* END PURCHASE ORDER METHODES */

  /* BEGIN HEADER METHODES */
  public returnAction() {
    this.router.navigate(["/project/"]);
  }

  public beforeDeleteProject() {
    this._modalService.openModal(ModalConfirmComponent,
      {
        title: "Êtes-vous sûr?",
        type: MODAL_TYPE.CONFIRM,
        data: {
          message: `Voulez-vous supprimer le projet <b>${this.projectData.reference}</b>?`,
          actions: [
            "Supprimer le projet de manière définitive",
            "Supprimer les liste d'approvisionnement de ce projet"
          ],
        },
        params: { projectid: this._projectId },
        confirmCallback: this.deleteProject
      }
    );
  }

  public deleteProject = (params: any) => {
    const snackBarTitle: string = "Supprimer un projet";
    if (this._deleteProjectSub) { this._deleteProjectSub.unsubscribe(); }
    this._deleteProjectSub = this._projectsQueriesSrv.deleteProject(params.projectid).subscribe(result => {
      if (result.data["deleteProject"]) {
        this._snackBar.open(snackBarTitle, "Le projet a été supprimé", "success", 5000);
      } else {
        this._snackBar.openSnackBarError(snackBarTitle, "Le projet n'a pas été supprimé");
      }
      this.returnAction();
    }, error => {
      this._snackBar.openSnackBarError(snackBarTitle, "Le projet n'a pas été supprimé", error);
    });
  }
  /* END HEADER METHODES */

  /**
   * @description Format date for display
   * @author Quentin Wolfs
   * @private
   * @param {number} dateSeconds
   * @param {string} [momentFormat="DD/MM/YYYY"] Default DD/MM/YYYY
   * @returns {string}
   * @memberof ProjectDetailsComponent
   */
  private formatDate(dateSeconds: number, momentFormat: string = "DD/MM/YYYY"): string {
    return dateSeconds ? moment.unix(dateSeconds).format(momentFormat) : null;
  }

  public loadMore(event: boolean) {
    this._pagination.page++;
    this.getPurchaseOrderData();
  }

  public showDeleteProjectButton(): boolean {
    return (!!this.purchaseOrderDatas && this.purchaseOrderDatas.length === 0) && (!!this.supplyListDatas && !this.supplyListDatas.find(sld => !!sld.priceRequestId)) 
  }

}
