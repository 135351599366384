import { Poutrelle, PoutrelleProperty } from "./poutrelle";
import { CornerValues } from "./equal-corner";
import { MM_TO_M, calculateNewValueWithCuttinAngle } from "app/facade/quote-element-definition/base";

export class UnequalCorner extends Poutrelle {

    public constructor(
        name: string,
        reference: string,
        values: CornerValues,
        properties: PoutrelleProperty) {
        super(name, reference, values, properties);
    }

    public get meterForWelding(): number {
        return (this.values.H
            + this.values.B
            + (this.values.H - this.values.T_f1)
            + (this.values.B - this.values.T_f1)
            + this.values.T_f1 * 2) * MM_TO_M;
    }

    public meterForWeldingWithCut(angle: number): number {
      const B = calculateNewValueWithCuttinAngle(this.values.B, angle),
            B_i = calculateNewValueWithCuttinAngle((B - this.values.T_f1), angle);
      return (this.values.H
        + B
        + (this.values.H - this.values.T_f1)
        + B_i
        + this.values.T_f1 * 2) * MM_TO_M;
    }

    public get thickness(): number {
        return this.values.T_f1;
    }

    public get InnerHeight(): number {
        return Math.ceil(this.values.H - this.values.T_f1);
    }

    public get InnerWidth(): number {
      return Math.ceil(this.values.B - this.values.T_f1);
    }
}