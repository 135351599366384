import { Poutrelle, PoutrelleProperty } from "./poutrelle";
import { MM_TO_M, calculateNewValueWithCuttinAngle } from "app/facade/quote-element-definition/base";

export interface UpnValues {
    H: number;
    B: number;
    T_w: number;
    T_f: number;
    R: number;
    A_L: number;
    A: number;
    I_y: number;
    I_z: number;
    i_y: number;
    i_z: number;
    W_ely: number;
    W_elz: number;
    Kg_m: number;
    R2: number;
    F_sr: number;
}

export class Upn extends Poutrelle {

    public constructor(
        name: string,
        reference: string,
        values: UpnValues,
        properties: PoutrelleProperty) {
        super(name, reference, values, properties);
    }

    public get meterForWelding(): number {
        const H_i = this.values.H - (this.values.T_f * 2);
        const D = H_i - this.values.R;

        return (this.values.H
            + this.values.B * 2
            + this.values.R * 2
            + this.values.R2 * 2
            + (this.values.B - (this.values.R / 2) - (this.values.R2 / 2) - this.values.T_w) * 2
            + D) * MM_TO_M;
    }

    public meterForWeldingWithCut(angle: number): number {
      const H_i = this.values.H - (this.values.T_f * 2),
            D = H_i - this.values.R,
            B = calculateNewValueWithCuttinAngle(this.values.B, angle),
            B_i = calculateNewValueWithCuttinAngle((this.values.B - (this.values.R / 2) - (this.values.R2 / 2) - this.values.T_w), angle);
      return (this.values.H
        + B * 2
        + this.values.R * 2
        + this.values.R2 * 2
        + B_i * 2
        + D) * MM_TO_M;
    }

    public get InnerWidth(): number {
      return Math.ceil(this.values.B - this.values.T_w);
    }
}