import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'apollo-client/util/Observable';
import { UsersQueriesService } from '../../../../facade/queries/users/users-queries.service';
import { IPagination } from '../../../../facade/interfaces/crud.interface';
import { EnumUserSortBy, IUser, IUserList, IUserSort } from '../../../../facade/interfaces/user.interface';
import { TableDatasDefinition } from '../../../../facade/interfaces/table-datas-definition';
import { TABLE_ITEM_ACTION_TYPE } from '../../../../presentationnal/organisms/table/table-item/enum/table-item-action-type.enum';
import { ListTableService } from '../../../../facade/services/list-table.service';
import { OrderByDirection } from '../../../../facade/enums/order-by-direction.enum';
import { Router } from '@angular/router';
import { MODAL_TYPE } from '../../../../presentationnal/organisms/modal/enums/modal-type.enum';
import { ModalConfirmComponent } from '../../../../presentationnal/organisms/modal';
import { ModalService } from '../../../../presentationnal/organisms/modal/services/modal.service';
import { SnackService } from '../../../../presentationnal/organisms/snack-bar/services/snack.service';
import {USER_GROUPS} from "../../../../facade/enums/usergroups.enum";
import {PermissionService} from "../../../../facade/services/permission.service";
import {AuthService} from "@lib/auth/auth.service";

const DEFAULT_LIMIT: number = 25;
@Component({
  selector: 'app-listing-users',
  templateUrl: './listing-users.component.html',
  styleUrls: ['./listing-users.component.css']
})
export class ListingUsersComponent implements OnInit, OnDestroy {

  public usersCategorie = "users";
  private _actionsType = TABLE_ITEM_ACTION_TYPE;
  private _listUserSub: Subscription;
  private _deleteUserSub: Subscription;
  private _filterUser: string = "";
  private _userSort: IUserSort = {
    sortBy: EnumUserSortBy.ID,
    sortDirection: OrderByDirection.DESC
  };

  public tableHeaders: string[] = [
    "Nom",
    "Prénom",
    "Email",
    "Groupe",
    "Actions",
    ];
    public dataDefinition: TableDatasDefinition[] = [
      { key: "firstname", isTitle: false},
      { key: "lastname", isTitle: false},
      { key: "username", isTitle: false},
      { key: "userGroup", isTitle: false}
    ];
  public dataTable: IUserList[] = [];
  // public noSortHeaders: string[] = [
  //   "Email"
  // ];
  private _pagination: IPagination = { page: 1, limit: DEFAULT_LIMIT };
  private _snackBar: SnackService;
  private userGroupsLabels: any;
  public countPg = 1;
  public isEdit = false;
  public isDelete = false;
  public userGroup: any;

  constructor(
    private readonly _usersQueriesSrv: UsersQueriesService,
    private _listTableSrv: ListTableService,
    private _permissionService: PermissionService,
    private _authSrv: AuthService,
    private router: Router,
    private _modalService: ModalService
  ) { }

  ngOnDestroy(): void {
    if (this._listUserSub) { this._listUserSub.unsubscribe(); }
    if (this._deleteUserSub) { this._deleteUserSub.unsubscribe(); }
  }

  ngOnInit() {
    this.userGroup =  this._authSrv.isUserGroup();
    this.userGroupsLabels = {
      [USER_GROUPS.WORKSHOP]: "Atelier",
      [USER_GROUPS.DESIGN_OFFICE]: "Bureau d'étude",
      [USER_GROUPS.QUOTER]: "Deviseur",
      [USER_GROUPS.ADMINISTRATOR]: "Administrateur",
    };

    let savedSearch = JSON.parse(localStorage.getItem("dataUserRecherche"));
    savedSearch = savedSearch && savedSearch.recherche.length > 0 ? savedSearch.recherche : "";
    if (savedSearch.length > 0) {
      this._filterUser = savedSearch;
    }
    let savedPagination = JSON.parse(localStorage.getItem("dataUserPagination"));
    savedPagination = savedPagination && savedPagination.pagination > 1 ? savedPagination.pagination : 1;
    if (savedPagination > 1) {
      this._pagination.limit = DEFAULT_LIMIT * savedPagination;
      this.loadListUsers(true);
      this._pagination = { page: savedPagination, limit: DEFAULT_LIMIT };
    } else {
      this.loadListUsers(true);
    }

    this.isEdit = this._permissionService.hasPermission(this.userGroup, "users", "write");
    this.isDelete = this._permissionService.hasPermission(this.userGroup, "users", "delete");
  }

  private loadListUsers(forceFirstPage: boolean = false) {
    if (this._listUserSub) { this._listUserSub.unsubscribe(); }
    this._listUserSub = this._usersQueriesSrv.getUserListing(this._filterUser, this._userSort, this._pagination).subscribe( result => {
      const resultData: {getUsers: IUserList[], pagination?: any} = <any>result.data;
      if (resultData && resultData.getUsers) {
        this.dataTable = !forceFirstPage && this._pagination.page !== 1 ?
          [...this.dataTable, ...this.mapUsersList(resultData.getUsers)] :
          this.mapUsersList(resultData.getUsers);
      }
      if (resultData && resultData.pagination) {
        this._listTableSrv.setLoadMoreStatus(resultData.pagination.hasNext);
      }

    }, error => {
      console.log("ERROR LOADING LISTING USERS", {error});
    });
  }



  /**
   * @description Map data for listing display
   * @author Marie Claudia
   * @private
   * @param {any[]} data
   * @returns {IUserList[]}
   * @memberof ListingUsersComponent
   */
   private mapUsersList(data: any[]): IUserList[] {
    return data.map( userlist => {
      return {
        id: userlist.id,
        username: userlist.username,
        firstname: userlist.firstname,
        lastname: userlist.lastname,
        userGroup: this.userGroupsLabels[userlist.userGroup],
        // actionsList: this.setuserlistActionList(userlist.status)
      };
    });
  }


  /**
  * @description Sort user in table
  * @author Marie Claudia
  * @param {string} key
  * @returns
  * @memberof ListingUsersComponent
  */
  public sortTable(key: string) {
    const trueKey: EnumUserSortBy = (<EnumUserSortBy>Object.keys(EnumUserSortBy).find( sortKey => EnumUserSortBy[sortKey] === key));
    if (trueKey) {
      if (this._userSort.sortBy !== trueKey) {
        this._userSort = {
          sortBy: trueKey,
          sortDirection: OrderByDirection.DESC
        };
      } else {
        this._userSort.sortDirection = this._userSort.sortDirection === OrderByDirection.ASC ? OrderByDirection.DESC : OrderByDirection.ASC;
      }
      this._pagination.page = 1;
      this.loadListUsers();
    } else {
      console.error("NO DEFINED KEY");
    }
  }

  /**
  * @description filter user in table
  * @author Marie Claudia
  * @param {any} filter
  * @returns
  * @memberof ListingUsersComponent
  */
  public filterUser(filter: any) {
    localStorage.setItem('dataUserRecherche',JSON.stringify({"recherche":filter}))
    localStorage.setItem('dataUserPagination', JSON.stringify({"pagination":1}));
    this._filterUser = filter;
    this._pagination.page = 1;
    this.loadListUsers();
  }

  public loadMore(event: boolean) {
    this._pagination.page++;
    localStorage.setItem('dataUserPagination', JSON.stringify({"pagination":this._pagination.page}));
    this.loadListUsers();
  }

  public tableButtonClicked($event: { actionType: number, itemId: number}) {
    switch (this._actionsType[$event.actionType]) {
      case "DELETE":
        this.beforeDeleteUser($event.itemId);
        break;
      case "EDIT":
        this.router.navigate([`${this.router.url}/edit/${$event.itemId}`]);
        break;
      default:
        break;
    }
  }

public addUser(){
  this.router.navigate([`${this.router.url}/add`]);
}


  //
  // CALL MODAL FOR DELETE PURCHASE
  //
private beforeDeleteUser(userId: number) {
  const user: IUser = this.dataTable.find( data => data.id === userId);
  if (user) {
    this._modalService.openModal(ModalConfirmComponent,
      {
        title: "Êtes-vous sûr?",
        type: MODAL_TYPE.CONFIRM,
        data: {
          message: `Voulez-vous vraiment Supprimer <b>${user.firstname + " " + user.lastname}</b>?`,
          actions: [
            "Annuler la suppression douce",
            "Valider la suppression douce"
          ],
        },
        params: { userId },
        confirmCallback: this.delete,
        customData: {
          deleteButtonName: "Valider",
        }
      }
    );
  } else {
    console.error("CANNOT FIND USER ", user.firstname + " " + user.lastname);
  }
}

public delete = (params: { userId: number }) => {
  const snackBarTitle: string = "Supprimer un utilisateur";
  if (this._deleteUserSub) { this._deleteUserSub.unsubscribe(); }
    this._deleteUserSub = this._usersQueriesSrv.deleteUser(+params.userId).subscribe(result => {
      if (result.data["deleteUser"]) {
        const index = this.dataTable.findIndex(project => project.id == params.userId);
        this.dataTable = [...this.dataTable.slice(0, index), ...this.dataTable.slice(index + 1)];
        this._snackBar.open(snackBarTitle, "L'utilisateur a été supprimée", "success", 5000);
      } else {
        this._snackBar.openSnackBarError(snackBarTitle, "L'utilicateur n'a pas été supprimée");
      }
    }, error => {
      this._snackBar.openSnackBarError(snackBarTitle, "L'utilisateur' n'a pas été supprimée", error);
    });
 }

  public goTopermission = ()=>{
    this.router.navigate(['/permission']);
  }

}
