/*
 * @Author: Kevin Palade
 * @Date: 2018-09-08 14:43:52
 * @Last Modified by:   Kevin Palade
 * @Last Modified time: 2018-09-08 14:43:52
 */
import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-bottom-bar",
  templateUrl: "./bottom-bar.component.html",
  styleUrls: ["./bottom-bar.component.css"]
})
export class BottomBarComponent implements OnInit {

  constructor() { }

  ngOnInit() { }

}
