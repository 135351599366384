import { QuoteElementBase, QuoteElementType } from "../base";
import { Element } from "../elements/element.base";
import { Observable, Subject } from "rxjs";
import { flatMap } from "rxjs/operators";

export type OperationCreate<T extends Element, V> = (el: T) => Observable<V>;

export abstract class Operation<T extends Element, U, V> extends QuoteElementBase {

    public $ready: Subject<boolean>;

    protected definedProperties: V;

    public constructor(protected element: T, protected properties: U, protected operationCreate: OperationCreate<T, V>) {
        super(name);
        this.$ready = new Subject<boolean>();
        this.operationCreate(this.element).subscribe(definedProperties => {
            this.definedProperties = definedProperties;
            this.$ready.next(true);
            this.$ready.complete();
        });

        this.element.$updated.pipe(flatMap(updated => this.operationCreate(this.element)))
            .subscribe(definedProperties => {
                this.definedProperties = definedProperties;
                this.updateAfterElementChange();
            });
    }

    protected abstract updateAfterElementChange(): void;

    public get Type(): QuoteElementType {
        return "OPERATION";
    }
}