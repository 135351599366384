import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { OrganismsModule } from "app/presentationnal/organisms/organisms.module";
import { ListingSupplierComponent } from "./listing-supplier/listing-supplier.component";
import { SuppliersQueriesService } from "app/facade/queries/supplier/suppliers-queries.service";
import { SupplierDetailsComponent } from "./supplier-details/supplier-details.component";

@NgModule({
  imports: [
    CommonModule,
    OrganismsModule
  ],
  declarations: [
    ListingSupplierComponent,
    SupplierDetailsComponent
  ],
  providers: [
    SuppliersQueriesService
  ]
})
export class SuppliersModule { }
