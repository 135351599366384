import gql from "graphql-tag";

export const GET_SUPPLY_CATEGORIES_BY_SUPPLIER = gql`
  query getSupplyCategoriesBySupplier($supplierId : ID!){
    supplyCategoriesBySupplier(supplierId: $supplierId){
      id
      name
      selected
      subCategories {
        id
        name
        selected
      }
    }
  }
`;

export const SET_SUPPLY_CATEGORIES_OF_SUPPLIER = gql`
  mutation setSupplyCategoriesOfSupplier($supplierId: ID!, $data: [SelectedSupplyCategoryInput!]!){
    setSupplyCategoriesOfSupplier(supplierId: $supplierId, data: $data)
  }
`;

export const GET_SUPPLY_CATEGORIES = gql`
  query getSupplyCategories($parentSupplyCategoryId: ID){
    supplyCategories(parentSupplyCategoryId: $parentSupplyCategoryId){
      id
      name
      parentSupplyCategoryId
      fields {
        name
        type
        nullable
      }
      elementGroup {
        elements(matterId: 1) {
          id
          name
          matterId
          matter {
            id
            name
            en1090Name
          }
        }
        availableMatters {
          id
          name
          en1090Name
        }
      }
    }
  }
`;

export const GET_SUPPLY_CATEGORIES_AND_ELEMENTS  = gql`
  query getSupplyCategories($parentSupplyCategoryId: ID){
    supplyCategories(parentSupplyCategoryId: $parentSupplyCategoryId){
      id
      name
      parentSupplyCategoryId
      elementGroup {
        elements(matterId: 1) {
          id
          name
          matterId
          matter {
            name
            en1090Name
          }
        }
        availableMatters {
          id
          name
          en1090Name
        }
      }
    }
  }
`;

export const GET_LIGHT_SUPPLY_CATEGORIES = gql`
  query getSupplyCategories($parentSupplyCategoryId: ID){
    supplyCategories(parentSupplyCategoryId: $parentSupplyCategoryId){
      id
      name
      parentSupplyCategoryId
    }
  }
`;

export const GET_SUPPLY_CATEGORY_ELEMENT_GROUP = gql`
  query getSupplyCategory($id: ID!){
    supplyCategory(id: $id){
      id
      name
      elementGroup {
        elements {
          id
          name
        }
        availableMatters {
          id
          name
        }
      }
    }
  }
`;

