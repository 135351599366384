import { IWeldiableMeterable } from "../../assemblages/welding";
import { MM_TO_M, SQUARE_MM_TO_SQUARE_M } from "../../base";
import { ILaserCuttable } from "../../operations/laser-cutting";
import { AdditionalComputingCreate } from "./custom.service";
import { Platine, PlatineProperty } from "./platine";

export interface EquerreProperty extends PlatineProperty {
    base: number;
    // If the equerre touch the top of a beam we need to weld the top part of the equerre
    // and check how many meter (if the equerre is widther than the beam itself).
    stickTop?: boolean;
    foldingPrice: number;
    unitaryAdditionalCost?: number;
    globalAdditionalCost?: number;
    remarks?: string;
}

export class Equerre extends Platine<EquerreProperty> implements ILaserCuttable, IWeldiableMeterable {

    public constructor(
        name: string,
        createAdditionalComputing: AdditionalComputingCreate,
        properties: EquerreProperty
    ) {
        super(name, createAdditionalComputing, properties);
    }

    public get UnitaryFoldingAdditionalCost(): number {
      return this.properties.unitaryAdditionalCost || 0;
    }

    public set UnitaryFoldingAdditionalCost(val: number) {
      this.properties.unitaryAdditionalCost = val;
    }

    public set stickTop(value: boolean) {
        this.properties.stickTop = value;
        this.elementUpdated();
    }

    public set base(value: number) {
        this.properties.base = value;
        this.elementUpdated();
    }

    public set foldingPrice(value: number) {
        this.properties.foldingPrice = value;
        this.elementUpdated();
    }

    public get FoldingPrice() {
        return this.properties.foldingPrice + this.TotalAdditionalCost;
    }

    public get CalculatedPrice(): number {
      let price = +this.matterPrice.toFixed(3);
      Object.keys(this.AdditionalComputings).forEach(key => {
          price += this.AdditionalComputings[key].origin.Price;
      });
      return this.FoldingPrice + price;
    }

    public get TotalAdditionalCost(): number {
      const quantity = this.TotalQuantity !== null ? this.TotalQuantity : 1;
        return this.UnitaryFoldingAdditionalCost + ( this.GlobalFoldingAdditionalCost / quantity );
    }

    public get GlobalFoldingAdditionalCost(): number {
        return this.properties.globalAdditionalCost || 0;
    }

    public set GlobalFoldingAdditionalCost(val: number) {
        this.properties.globalAdditionalCost = val;
    }

    public get FoldingRemarks(): string {
      return this.properties.remarks;
    }

    public set FoldingRemarks(value: string) {
      this.properties.remarks = value;
    }

    public get meterForWelding(): number {
        let mmToWeld = (this.properties.height + this.properties.base) * 2 + this.properties.thickness * 2;
        // let mmToWeld = this.properties.height + this.properties.base;
        if (this.properties.stickTop) {
            const min = this.properties.elementForWelding.topLength ?
                Math.min(this.properties.elementForWelding.topLength, this.properties.width) : this.properties.width;

            mmToWeld += min * 2;
        }

        return mmToWeld * MM_TO_M;
    }

    public get TotalHeight(): number {
        return this.properties.height + this.properties.base;
    }

    public get Area(): number {
        return this.properties.width * this.TotalHeight;
    }

    public get AreaWithMargin(): number {
        return (this.properties.width + (this.properties.margin * 2)) * (this.TotalHeight + (this.properties.margin * 2));
    }

    public get perimeter(): number {
        return (this.properties.width * 2) + (this.TotalHeight * 2);
    }

    public get perimeterWithMargin(): number {
        return (this.properties.width + this.TotalHeight + (this.properties.margin * 4)) * 2;
    }

    public get TotalArea(): number {
        const overTop = this.properties.elementForWelding.topLength ?
            this.properties.width - this.properties.elementForWelding.topLength : this.properties.width;

        return (this.Area * 2
            + (this.TotalHeight * this.properties.thickness)
            + (this.properties.width * this.properties.thickness)
            + (!this.stickTop ?
                this.properties.width * this.properties.thickness :
                (overTop > 0 ? overTop * this.properties.thickness : 0)
            )) * SQUARE_MM_TO_SQUARE_M;
    }
}