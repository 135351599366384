import { Component, OnInit, Inject, OnDestroy } from "@angular/core";
import { ModalContentForm } from "../../model/modal-content-form";
import { ModalService, DATA } from "../../services/modal.service";
import { SnackService } from "app/presentationnal/organisms/snack-bar/services/snack.service";
import { FormBuilder, Validators } from "@angular/forms";
import { checkMailValid, CUSTOM_VALIDATOR } from "app/presentationnal/atoms/inputs/custom-validator";
import { Subscription } from "rxjs";
import { SuppliersQueriesService } from "app/facade/queries/supplier/suppliers-queries.service";
import { ISupplier } from "app/facade/interfaces/supplier";
import { IModalData } from "../../interfaces/modal-data.interface";
import { MODAL_CLOSE_ACTION } from "../../enums/modal-close-action.enum";

@Component({
  selector: "app-modal-add-supplier",
  templateUrl: "./modal-add-supplier.component.html",
  styleUrls: ["./modal-add-supplier.component.css"]
})
export class ModalAddSupplierComponent extends ModalContentForm implements OnInit, OnDestroy {
  private _subCode: Subscription;
  private supplierData: any;
  public requiredField: string[] = [];
  constructor(
    protected _modalService: ModalService,
    protected _snackBar: SnackService,
    protected _fb: FormBuilder,
    private _suppliersQueriesSrv: SuppliersQueriesService,
    @Inject(DATA) private _data: IModalData) {
      super(_modalService, _fb, _snackBar);
      this.supplierData = this._data && this._data.data ? this._data.data : null;
    }

  ngOnInit() {
    this.initForm();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this._subCode.unsubscribe();
  }

  private initForm() {
    this.requiredField = ["code", "name"];
    this._formGroup = this._fb.group({
      code: [this.supplierData ? this.supplierData.code : null, Validators.compose([
        Validators.required, Validators.maxLength(10)
      ])],
      name: [this.supplierData ? this.supplierData.name : null, Validators.compose([
        Validators.required, Validators.maxLength(100)
      ])],
      phone: [this.supplierData ? this.supplierData.phone : null, Validators.maxLength(20)],
      mail: [this.supplierData ? this.supplierData.mail : null, Validators.maxLength(100)],
      remark: [this.supplierData ? this.supplierData.remark : null, Validators.maxLength(255)]
    });
    this._subCode = this._formGroup.get("code").valueChanges.subscribe( value => {
      this._formGroup.get("code").patchValue(value.toUpperCase(), {emitEvent: false});
    });
  }

  public confirmModal(): void {
    if ( this._formGroup.valid ) {
      switch (this._data.closeAction) {
        case MODAL_CLOSE_ACTION.SAVE:
          this.saveNewSupplier();
          break;
        case MODAL_CLOSE_ACTION.UPDATE:
          this.updateSupplier();
          break;
        default:
          console.error("Modal called outside of scope");
      }
    } else {
        this.markFormGroupTouched(this._formGroup);
        this.displayErrorMessage();
    }
  }

  protected save(data: ISupplier): void {
    const objData = {
      confirm: true,
      data
    };
    this.afterClosed.next(objData);
  }

  public mailVerification() {
    if (!CUSTOM_VALIDATOR.email.test(this._formGroup.get("mail").value)) {
      this._formGroup.get("mail").setValidators(Validators.compose([checkMailValid]));
      this._formGroup["controls"]["mail"].updateValueAndValidity();
      // this._formGroup.controls["mail"].clearValidators();
    }
  }

  /**
   * @description Send request to save new Supplier. Closes the modal if successfull, displays error if not
   * @author Quentin Wolfs
   * @private
   * @memberof ModalAddSupplierComponent
   */
  private saveNewSupplier(): void {
    const snackBarTitle: string = "Ajout d'un fournisseur";
    this._suppliersQueriesSrv.addSupplier(this._formGroup.value).subscribe(result => {
      const resultData: any = result.data;
      if (resultData && resultData.createSupplier) {
        this._snackBar.open(snackBarTitle, "Le fournisseur a été créé", "success", 5000);

        // Save was successfull, closing the modal with saved data
        this.save(resultData.createSupplier);
        this._modalService.closeModal();
      }
    }, error => {
      this._snackBar.openSnackBarError(snackBarTitle, "Le fournisseur n'a pas été créé", error);
    });
  }

  /**
   * @description Send request to update existing Supplier. Closes the modal if successfull, displays error if not
   * @author Quentin Wolfs
   * @private
   * @memberof ModalAddSupplierComponent
   */
  private updateSupplier(): void {
    const snackBarTitle: string = "Modification d'un fournisseur";
    this._suppliersQueriesSrv.updateSupplier(this._data.data.id, this._formGroup.value).subscribe(result => {
      const resultData: any = result.data;
      if (resultData && resultData.updateSupplier) {
        this._snackBar.open(snackBarTitle, "Le fournisseur a été mis à jour", "success", 5000);

        // Update was successfull, closing the modal with updated data
        this.save(resultData.updateSupplier);
        this._modalService.closeModal();
      }
    }, error => {
      this._snackBar.openSnackBarError(snackBarTitle, "Le fournisseur n'a pas été mis à jour", error);
    });
  }

}
