import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ListingPurchaseOrderComponent } from "./listing-purchase-order/listing-purchase-order.component";
import { OrganismsModule } from "app/presentationnal/organisms/organisms.module";
import { PurchaseOrderDetailsComponent } from "./purchase-order-details/purchase-order-details.component";

@NgModule({
  imports: [
    CommonModule,
    OrganismsModule
  ],
  declarations: [
    ListingPurchaseOrderComponent,
    PurchaseOrderDetailsComponent
  ]
})
export class PurchaseOrderModule { }
