import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { USER_GROUPS } from '../../../../facade/enums/usergroups.enum';
import { SmtpConfig } from '../../../../facade/interfaces/smtp-config';
import { Users } from '../../../../facade/interfaces/users';
import { SmtpConfigQueriesService } from '../../../../facade/queries/smtp/smtp-config.queries.service';
import { UsersQueriesService } from '../../../../facade/queries/users/users-queries.service';
import { ISelectOption } from '../../../../presentationnal/atoms/inputs/select-input/selectOptions';
import { SnackService } from '../../../../presentationnal/organisms/snack-bar/services/snack.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.css']
})
export class EditUserComponent implements OnInit, OnDestroy {

  private _editUserSub: Subscription;
  public formGroup: FormGroup;
  public smtpFormGroup: FormGroup;
  public userGroupOptions: ISelectOption[] = [];
  private _objData: Users;
  public lastname : any;
  public firstname :any;
  private _objDataSmtp: SmtpConfig;
  public isAdmin : boolean = false;

  constructor(protected _fb: FormBuilder,
    private snackBar: SnackService,
    public route: ActivatedRoute,
    public router: Router,
    private _userQueriesSrv: UsersQueriesService,
    private _smtpQueriesSrv: SmtpConfigQueriesService
  ) { }

  ngOnInit() {
    this.userGroupOptions = [
      {value: USER_GROUPS.WORKSHOP, label: "Atelier"},
      {value: USER_GROUPS.DESIGN_OFFICE, label: "Bureau d'étude"},
      {value: USER_GROUPS.QUOTER, label: "Deviseur"},
      {value: USER_GROUPS.ADMINISTRATOR, label: "Administrateur"},
    ];


    this.checkUrl();
  }

  ngOnDestroy(): void {
    // throw new Error('Method not implemented.');
    if (this._editUserSub) { this._editUserSub.unsubscribe(); }
  }




  /**
   * @description Init the basics form group
   * @author Marie Claudia
   * @private
   * @memberof EditUserComponent
   */
  private initForm() {
    this.formGroup = this._fb.group({
      username : [this._objData && this._objData.username ? this._objData.username : null, Validators.required],
      firstname : [this._objData && this._objData.firstname ? this._objData.firstname : null, Validators.required],
      lastname: [this._objData && this._objData.lastname ? this._objData.lastname : null, Validators.required],
      userGroup: [this._objData && this._objData.userGroup ? this._objData.userGroup : this.userGroupOptions[0].label, Validators.required],
    });
    this.firstname = this.formGroup.get("firstname").value;
    this.lastname = this.formGroup.get("lastname").value;

    this.smtpFormGroup = this._fb.group({
      username : [this._objDataSmtp && this._objDataSmtp.username ? this._objDataSmtp.username : null],
      email : [this._objDataSmtp && this._objDataSmtp.email ? this._objDataSmtp.email : null],
      password : [this._objDataSmtp && this._objDataSmtp.password ? this._objDataSmtp.password : null],
      host: [this._objDataSmtp && this._objDataSmtp.host ? this._objDataSmtp.host : null],
      port: [this._objDataSmtp && this._objDataSmtp.port ? this._objDataSmtp.port : null],
      active: [this._objDataSmtp && this._objDataSmtp.active ? true : false],
    });

  }


  /**
   * @description Processus to update SMTP
   * @author Marie Claudia
   * @memberof EditUserComponent
   */
  public updateSmtpConfig() {
    // this._objDataSmtp && this._objDataSmtp.password ? this._objDataSmtp.password
    if (this.smtpFormGroup && this.smtpFormGroup.valid) {
      let id = this._objDataSmtp.id;
      if      (this.smtpFormGroup.get("username").value && !this.smtpFormGroup.get("email").value) this.smtpFormGroup.get("email").setValue(this.smtpFormGroup.get("username").value)
      else if (this.smtpFormGroup.get("email").value && !this.smtpFormGroup.get("username").value) this.smtpFormGroup.get("username").setValue(this.smtpFormGroup.get("email").value)
      const smtp = {
        username : this.smtpFormGroup.get("username").value,
        email : this.smtpFormGroup.get("email").value,
        password : this.smtpFormGroup.get("password").value,
        host : this.smtpFormGroup.get("host").value,
        port : +this.smtpFormGroup.get("port").value,
        active : <boolean>this.smtpFormGroup.get("active").value,
      };

      this._smtpQueriesSrv.updateSmtpConfig(smtp,id).subscribe(result => {
        const actualQuote = result.data["createQuote"];
        this.snackBar.open("Modifier Smtp", "Le smtp a été modifié", "success", 5000);
      });
    } else {
      this.snackBar.open("Modifier Smtp", "Formulaire SMTP incomplet", "warning", 5000);
    }
  }

  /**
   * @description Processus to update SMTP
   * @author Marie Claudia
   * @memberof EditUserComponent
   */
  public updateUser() {
    let group = this.formGroup.get("userGroup").value;
    if(group === USER_GROUPS.ADMINISTRATOR){
      this.isAdmin = true;
    }
    if (this.formGroup && this.formGroup.valid) {
      const id = this._objData.id;
      const user = {
        username : this.formGroup.get("username").value,
        firstname : this.formGroup.get("firstname").value,
        lastname : this.formGroup.get("lastname").value,
        userGroup : this.formGroup.get("userGroup").value,
        isAdmin:this.isAdmin
      };
      this.firstname = user.firstname;
      this.lastname = user.lastname;
      this._userQueriesSrv.updateUser(id, user).subscribe(result => {
        this.snackBar.open("Modifier un Utilisateur", "L' utilisateur a été modifié", "success", 5000);
      });
    } else {
      this.snackBar.open("Modifier un utilisateur", "Formulaire information utilisateur incomplet", "warning", 5000);
    }
  }


  /**
   * @description
   * @author Marie Claudia
   * @memberof EditUserComponent
   */
  public save() {
    if (this._objData && this._objData.id) {
      this.updateUser();
    }
    if (this._objDataSmtp && this._objDataSmtp.id) {
      this.updateSmtpConfig();
    }
  }


    /**
   * @description
   * @author Marie Claudia
   * @memberof EditUserComponent
   */
    public checkUrl() {
    let hasNoParamMap: boolean = true;
    if (this.route.snapshot.paramMap.get("id")) {
      hasNoParamMap = false;

      //get id in route
      let id= this.route.snapshot.paramMap.get("id");
      var idUser: number = +id;

      //get user by id
      this._userQueriesSrv.getUser(idUser).subscribe(result => {
        this._objData = result.data["getUser"];
          this.initForm();
      });
    }

    if (this.route.snapshot.paramMap.get("id")) {
      hasNoParamMap = false;

      //get id in route
      let ids= this.route.snapshot.paramMap.get("id");
      var idSmtp: number = +ids;

      //get user by id
      this._smtpQueriesSrv.getSmtp(idSmtp).subscribe(result => {
        this._objDataSmtp = result.data["getSmtpConfig"];
        this.initForm();
      });


    }


  }

}
