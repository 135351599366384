import { IWeldiableMeterable } from "../../assemblages/welding";
import { MM_TO_M, SQUARE_MM_TO_SQUARE_M } from "../../base";
import { ILaserCuttable } from "../../operations/laser-cutting";
import { AdditionalComputingCreate } from "./custom.service";
import { Platine, PlatineProperty } from "./platine";

export interface NervureProperty extends PlatineProperty {
    behindPlate: boolean;
}

export class Nervure extends Platine<NervureProperty> implements ILaserCuttable, IWeldiableMeterable {

    public constructor(
        name: string,
        createAdditionalComputing: AdditionalComputingCreate,
        properties: NervureProperty
    ) {
        super(name, createAdditionalComputing, properties);
    }

    public get behindPlate(): boolean {
        return this.properties.behindPlate;
    }

    public set behindPlate(value: boolean) {
        this.properties.behindPlate = value;
    }

    public get meterForWelding(): number {
        return (this.behindPlate ? this.meterForWeldingWithPlate : this.meterForWeldingWithoutPlate) * MM_TO_M;
    }

    public get meterForWeldingWithoutPlate(): number {
        return this.properties.height * 2 + this.properties.width * 4 + this.properties.thickness * 2;
    }

    public get meterForWeldingWithPlate(): number {
        return this.properties.height * 4 + this.properties.width * 4;
    }

    public get TotalArea(): number {
        return (this.Area * 2
            + (this.behindPlate ? 0 : this.properties.height * this.properties.thickness)) * SQUARE_MM_TO_SQUARE_M;
    }
}