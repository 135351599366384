import gql from "graphql-tag";

export const GET_SUPPLIERS = gql`
  query getSuppliers{
    suppliers{
      id
      code
      name
      mail
      phone
      remark
      parentSupplyCategories {
        name
      }
    }
  }
`;

export const GET_NEW_SUPPLIERS = gql`
  query getSuppliers($pagination: Pagination){
    suppliers(pagination: $pagination) {
      id
      code
      name
      mail
      phone
      remark
      parentSupplyCategories {
        name
      }
    }
  }
`;

export const GET_SUPPLIERS_WITH_CATEGORIES = gql`
  query getSuppliersWithCategories{
    suppliers{
      id
      code
      name
      mail
      phone
      remark
      allSupplyCategories {
        id
        name
      }
      parentSupplyCategories {
        id
        name
      }
    }
  }
`;

export const GET_SUPPLIER = gql`
  query getSupplier($id: ID!) {
    supplier(id: $id) {
      id
      code
      name
      mail
      phone
      remark
    }
  }
`;

export const CREATE_SUPPLIER = gql`
  mutation createSupplier($supplierData: SupplierInput!){
    createSupplier(data: $supplierData){
      id
      code
      name
      mail
      phone
      remark
    }
  }
`;

export const UPDATE_SUPPLIER = gql`
  mutation updateSupplier($id: ID!, $data: SupplierUpdate!){
    updateSupplier(id: $id, data: $data){
      id
      code
      name
      mail
      phone
      remark
    }
  }
`;

export const DELETE_SUPPLIER = gql`
  mutation deleteSupplier($id: ID!){
    deleteSupplier(id: $id)
  }
`;

export const SEARCH_SUPPLIERS = gql`
  query searchSuppliers($search: String){
    suppliers (search: $search){
      id
      code
      name
    }
  }
`;