import { Component, OnInit, OnDestroy } from "@angular/core";
import { IPriceRequest, IAmalgamInput, IPriceRequestAdditionnalCost, IPriceRequestElement, IPriceRequestAdditionnalCostUpdate, IAmalgamGenerationResult, IAmalgamParam } from "app/facade/interfaces/price-request.interface";
import { ISupplyList, ISupplyListForPriceRequestCards } from "app/facade/interfaces/project";
import { IInformationsCardConfig } from "app/facade/interfaces/informations-card-data.interface";
import { IContainerCardsConfig } from "app/facade/interfaces/container-cards-config.interface";
import { ActivatedRoute, Router, NavigationCancel } from "@angular/router";
import { PriceRequestsQueriesService } from "app/facade/queries/price-request/price-requests-queries.service";
import { ModalService } from "app/presentationnal/organisms/modal/services/modal.service";
import { SnackService } from "app/presentationnal/organisms/snack-bar/services/snack.service";
import * as moment from "moment";
import { ModalConfirmComponent, ModalAddPriceRequestComponent, ModalLinkSupplyListComponent, ModalGenerateNewAmalgamsComponent, ModalAddSupplierOfferComponent, ModalUpdateSupplierOfferContactComponent, ModalSelectSupplierOfferComponent, ModalInpdatePriceRequestElementOptionComponent, ModalInpdatePriceRequestAdditionnalCostComponent, ModalUpdateSupplierOfferElementsListComponent, ModalConsultCreatedPurchaseOrderComponent, ModalAddSupplyListComponent } from "app/presentationnal/organisms/modal";
import { MODAL_TYPE } from "app/presentationnal/organisms/modal/enums/modal-type.enum";
import { Subscription, Subject } from "rxjs";
import { CARD_ACTION_TYPE } from "app/facade/enums/card-action-type.enum";
import { IBarsetItem, IBarsetGeneration, IBarsetGenerationUpdate } from "app/facade/interfaces/barset.interface";
import { AmalgamService } from "app/facade/services/amalgam.service";
import { ITabsWithLoading } from "app/facade/interfaces/tabs";
import { PRICE_REQUEST_TABS_CONFIG, ActionTypeForSupplierOffer } from "app/facade/configs/price-request.config";
import { PriceRequestTabsData } from "app/facade/enums/price-request.enum";
import { EditedFormService } from "@lib/edited-form/edited-form.service";
import { ModalEncodeSupplierOfferComponent } from "app/presentationnal/organisms/modal/components/modal-encode-supplier-offer/modal-encode-supplier-offer.component";
import { ISupplierOffer, ISupplierOfferCard, ISupplierOfferAdditionnalCost, ISupplierOfferElementAssociation } from "app/facade/interfaces/supplier-offer";
import { ApplicationConfig } from "app/app.config";
import { ModalUpdatePriceRequestElementRemarkComponent } from "app/presentationnal/organisms/modal/components/modal-update-price-request-element-remark/modal-update-price-request-element-remark.component";
import { IMultiTableGroup, ILinkedMultiTableGroupData } from "app/facade/interfaces/multi-table-group.interface";
import { EnumSupplyTableLinkedSize } from "app/facade/enums/supply-table-linked-size.enum";
import { SupplyTableService } from "app/facade/services/supply-table.service";
import { cloneDeep } from "apollo-utilities";
import { IPurchaseOrderFromSupplierOfferInput, IPOSupplierOfferElementInput, IPurchaseOrder, IPOSupplierOfferAdditionnalCostInput } from "app/facade/interfaces/purchase-order.interface";
import { PurchaseOrderQueriesService } from "app/facade/queries/purchase-order/purchase-order-queries.service";
import { priceRequestStatusConfig } from "app/facade/configs/status.config";
import { MODAL_CLOSE_ACTION } from "app/presentationnal/organisms/modal/enums/modal-close-action.enum";
import { PermissionService } from "app/facade/services/permission.service";
import { AuthService } from "@lib/auth/auth.service";

interface ISavedPriceRequestElementsAndSuppliers {
  elements: IPriceRequestElement[];
  additionnalCosts: IPriceRequestAdditionnalCost[];
  supplierResponses: ILinkedMultiTableGroupData[];
}

@Component({
  selector: "app-price-request-details",
  templateUrl: "./price-request-details.component.html",
  styleUrls: ["./price-request-details.component.css"]
})
export class PriceRequestDetailsComponent implements OnInit, OnDestroy {
  private _routerEventsSub: Subscription;
  private _priceRequestSub: Subscription;
  private _deletePriceRequestSub: Subscription;
  private _supplyListSub: Subscription;
  private _assignSupplyListSub: Subscription;
  private _dissociateSupplyListSub: Subscription;
  private _barsetToSaveSub: Subscription;
  private _priceRequestElementRemarkUpdateSub: Subscription;
  private _amalgamsSub: Subscription;
  private _saveAmalgamsSub: Subscription;
  private _generateNewAmalgamsSub: Subscription;
  private _supplierOfferSub: Subscription;
  private _supplierOfferElementSub: Subscription;
  private _supplierOfferElementEncodeSub: Subscription;
  private _associateSupplierSub: Subscription;
  private _deleteSupplierOfferSub: Subscription;
  private _priceRequestElementsSub: Subscription;
  private _inpdatePriceRequestElementOptionSub: Subscription;
  private _inpdatePriceRequestAdditionnalCostSub: Subscription;

  public tabsData: ITabsWithLoading[] = [...PRICE_REQUEST_TABS_CONFIG];
  public tabsConfig: any = PriceRequestTabsData;
  public selectedTab: string = this.tabsData.find( tab => tab.isActive).value;
  public loading: boolean = false;
  public reduceDetailPriceRequest: boolean = false;
  public actionTypeForSupplierOffer: any = ActionTypeForSupplierOffer;
  public supplyListData: ISupplyList[] = [];

  private _priceRequestId: number;
  private _needToSaveBarset: boolean = false;
  private _isSavingBarset: boolean = false; // Fix refetch amalgams didn't call when we save the first time the barset
  // Use to regenerate in multi table service without query
  private _savedPriceRequestElementsAndSuppliers: ISavedPriceRequestElementsAndSuppliers;
  public needToSavePurchaseOrderPreparation: boolean = false;

  private _supplierOfferResponse: ISupplierOffer;
  public priceRequestData: IPriceRequest;
  public barsetGeneration: {saved: IBarsetGeneration, temp: IBarsetGeneration, toSaved: IBarsetGenerationUpdate} = {
    saved: null,
    temp: null,
    toSaved: null
  };
  public supplyListDatas: ISupplyListForPriceRequestCards[] = [];
  public informationsCardConfig: IInformationsCardConfig;
  public containerCardsConfig: IContainerCardsConfig = null;
  public amalgamsForBarset: IBarsetItem[] = [];
  public supplierOffersCardsConfig: IContainerCardsConfig = null;
  public supplierOfferCardDatas: ISupplierOfferCard[] = [];
  public supplierOfferToDelete = [];
  public hasPermission: any;
  public userGroup: any;
  public supplierOfferMultiTables: IMultiTableGroup = { elements: [], linkedData: [], supplyTableLinkedSize: EnumSupplyTableLinkedSize.four };
  public priceRequestElementsMultiTables: IMultiTableGroup = { elements: [], linkedData: [], supplyTableLinkedSize: EnumSupplyTableLinkedSize.four};

  public barsetLoadingSubject: Subject<boolean> = new Subject<boolean>();

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _amalgamService: AmalgamService,
    private _priceRequestsQueriesSrv: PriceRequestsQueriesService,
    private _supplyTableSrv: SupplyTableService,
    private _modalService: ModalService,
    private _snackBar: SnackService,
    private _editedFormSrv: EditedFormService,
    private _permissionService: PermissionService,
    private _purchaseOrderQueriesSrv: PurchaseOrderQueriesService,
    private _authSrv: AuthService) {
      this._priceRequestId = +this._route.snapshot.paramMap.get("id");
      this.informationsCardConfig = {
        title: "Informations demande de prix",
        actions: [
          { label: "Editer", fct: this.editAction }
        ],
        fieldsToDisplay: [
          { key: "reference", label: "Référence" },
          { key: "createdAt", label: "Date de création" },
          { key: "createdBy", label: "Créé par" },
          // {key: "remark", label: "Remarque", action: this.editAction },
          { key: "remark", label: "Remarque générale" },
          { key: "internalRemark", label: "Remarque interne" },
          { key: "status", label: "Status", isStatus: true, statusConfig: priceRequestStatusConfig },
          { key: "isValidated", label: "Validée par B.E.", isBoolean: true },
          { key: "isDone", label: "Terminée", isBoolean: true }
        ]
      };
      this.getPriceRequestData();
      this.getSupplyListData();
      this.loadTabData();
      // Subscribe to the navigation event to print modal "Edit mode" if we try to navigate on other page and we are in edit mode
      this._routerEventsSub = this._router.events.subscribe( value => {
        if ( value instanceof NavigationCancel) {
          this.checkEditedForm();
        }
      });
  }

  ngOnInit() {
    // get this.userGroup
    this.userGroup =  this._authSrv.isUserGroup();
    //update permission
    this._permissionService.getAndUpdatePermission(this.userGroup);
    // check if hasPermissionn to see prices
    this.hasPermission = this._permissionService.hasPermission(this.userGroup,"price-requests","seePrices");

    // Subscribe to the edit mode of barset
    this._barsetToSaveSub = this._amalgamService.getUpdateToSave().subscribe( update => {
      this._needToSaveBarset = update;
      this._editedFormSrv.EditedForm = update;
    });
    // Subscribe to element remark update action
    this.updatePriceRequestElementRemark();

  }

  ngOnDestroy(): void {
    this._routerEventsSub.unsubscribe();
    this._editedFormSrv.EditedForm = false;
    this._priceRequestSub.unsubscribe();
    this._supplyListSub.unsubscribe();
    this._barsetToSaveSub.unsubscribe();
    this._priceRequestElementRemarkUpdateSub.unsubscribe();
    if (this._priceRequestElementsSub) { this._priceRequestElementsSub.unsubscribe(); }
    if (this._amalgamsSub) { this._amalgamsSub.unsubscribe(); }
    if (this._supplierOfferSub) { this._supplierOfferSub.unsubscribe(); }
    if (this._supplierOfferElementSub) { this._supplierOfferElementSub.unsubscribe(); }
    if (this._deletePriceRequestSub) { this._deletePriceRequestSub.unsubscribe(); }
    // if (this._assignSupplyListSub) { this._assignSupplyListSub.unsubscribe(); }
    if (this._dissociateSupplyListSub) { this._dissociateSupplyListSub.unsubscribe(); }
    if (this._saveAmalgamsSub) { this._saveAmalgamsSub.unsubscribe(); }
    if (this._generateNewAmalgamsSub) { this._generateNewAmalgamsSub.unsubscribe(); }
    if (this._associateSupplierSub) { this._associateSupplierSub.unsubscribe(); }
    if (this._deleteSupplierOfferSub) { this._deleteSupplierOfferSub.unsubscribe(); }
    if (this._inpdatePriceRequestElementOptionSub) { this._inpdatePriceRequestElementOptionSub.unsubscribe(); }
    if (this._inpdatePriceRequestAdditionnalCostSub) { this._inpdatePriceRequestAdditionnalCostSub.unsubscribe(); }
  }

  public reduceDetail() {
    this.reduceDetailPriceRequest = !this.reduceDetailPriceRequest;
  }

  /**
   * @description Get price request general datas
   * @author Lainez Eddy
   * @private
   * @param {boolean} [stopLoading]
   * @memberof PriceRequestDetailsComponent
   */
  private getPriceRequestData(stopLoading?: boolean) {
    if (this._priceRequestSub) { this._priceRequestSub.unsubscribe(); }
    this._priceRequestSub = this._priceRequestsQueriesSrv.getPriceRequest(this._priceRequestId).subscribe( result => {
      const data: any = result.data;
      if (data && data.priceRequest) {
        this.priceRequestData = {
          ...data.priceRequest,
          createdAt: moment.unix(data.priceRequest.createdAt).format("DD/MM/YYYY HH:mm"),
          createdBy: `${data.priceRequest.user.lastname} ${data.priceRequest.user.firstname}`
        };
        this.initGenerationBarset(this.priceRequestData.barsetGeneration);
      }
      if (stopLoading) { this.loading = false; }
    }, error => {
      console.log("ERROR LOADING PRICE REQUEST", {error});
      if (stopLoading) { this.loading = false; }
    });

  }

  /**
   * @description Open modal to Update the price request
   * @private
   * @memberof PriceRequestDetailsComponent
   */
  private editAction = () => {
    const modalTitle: string = "Mise à jour de la remarque";
    const modalRef = this._modalService.openModal(ModalAddPriceRequestComponent, {
      title: modalTitle,
      type: MODAL_TYPE.NORMAL,
      data: this.priceRequestData,
      closeAction: MODAL_CLOSE_ACTION.UPDATE
    });
    if (modalRef) {
      modalRef.afterClosed.subscribe(res => {
        if (res.confirm && res.data) {
          this.priceRequestData.remark = res.data.remark;
          this.priceRequestData.internalRemark = res.data.internalRemark;
          this.priceRequestData.isValidated = res.data.isValidated;
          this.priceRequestData.isDone = res.data.isDone;
          this.priceRequestData.status = res.data.status;
        }
      });
    }
  }

  /**
   * @description Open edited form information and return true if barset or PR to PO are in edit mode
   * @author Lainez Eddy
   * @private
   * @returns {boolean}
   * @memberof PriceRequestDetailsComponent
   */
  private checkEditedForm(): boolean {
    if (this._needToSaveBarset) {
      this._modalService.openModal(ModalConfirmComponent,
        {
          title: "Mise en barre en cours d'édition",
          type: MODAL_TYPE.CONFIRM,
          data: {
            message: `La mise en barre est en cours d'édition. Merci de finaliser l'édition de celle-ci.`,
          },
          customData: {
            deleteButtonName: "Ok",
            deleteButtonColor: "none",
            displayCancelButton: false,
          }
        }
      );
    }
    if (this.needToSavePurchaseOrderPreparation) {
      this._modalService.openModal(ModalConfirmComponent,
        {
          title: "Préparation de bon de commande",
          type: MODAL_TYPE.CONFIRM,
          data: {
            message: `Une préparation de bon de commande est en cours d'édition. Merci de finaliser celle-ci.`,
          },
          customData: {
            deleteButtonName: "Ok",
            deleteButtonColor: "none",
            displayCancelButton: false,
          }
        }
      );
    }
    return (this._needToSaveBarset || this.needToSavePurchaseOrderPreparation);
  }

  /* TABS */
  /**
   * @description Change tab and load Tab Data if needed
   * @author Lainez Eddy
   * @param {ITabsWithLoading[]} value
   * @memberof PriceRequestDetailsComponent
   */
  public changeTab(value: ITabsWithLoading[]) {
    const newSelectedTab: ITabsWithLoading = value.find( tab => tab.isActive );
    if ( newSelectedTab.value !== this.selectedTab ) {
      // if (this.selectedTab === this.tabsConfig.BARSET && this.checkEditedForm()) {
      if (this.checkEditedForm()) {
        this.tabsData = this.tabsData.map( tab => {
          return {
            ...tab,
            isActive : tab.value === this.selectedTab
          };
        });
      } else {
        this.selectedTab = newSelectedTab.value;
        this.tabsData = value;
        if (!newSelectedTab.isLoaded) {
          this.loadTabData();
        }
      }
    }
  }

  /**
   * @description Load Tab Datas
   * @author Lainez Eddy
   * @private
   * @memberof PriceRequestDetailsComponent
   */
  private loadTabData() {
    switch (this.selectedTab) {
      case this.tabsConfig.RESUME:
        this.getPriceRequestElements();
        break;
      case this.tabsConfig.SUPPLIER:
        this.getSupplierOffers();
        break;
      case this.tabsConfig.BARSET:
        this.getAmalgams();
        break;
      default:
        break;
    }
    this.tabsData.find( tab => tab.value === this.selectedTab).isLoaded = true;
  }

  /* METHODS FOR SUPPLIER OFFER & Barset*/
  /**
   * @description Open modal allow user to select supplier offer and generate or send the price request
   * @author Lainez Eddy
   * @param {string} actionType
   * @memberof PriceRequestDetailsComponent
   */
  public selectSupplierForAction(actionType: string) {
    const modalTitle: string = `${actionType === this.actionTypeForSupplierOffer.PRINT_PDF ? "Générer" : "Envoyer"} la demande de prix pour les fournisseurs suivant`;
    const modalConfirmButton: string = `${actionType === this.actionTypeForSupplierOffer.PRINT_PDF ? "Générer" : "Envoyer"}`;
    const modalRef = this._modalService.openModal(ModalSelectSupplierOfferComponent, {
      title: modalTitle,
      type: MODAL_TYPE.NORMAL,
      data: {
        priceRequestId: this._priceRequestId,
        isPdfGenerate: actionType === this.actionTypeForSupplierOffer.PRINT_PDF
      },
      customData: {
        confirmButtonName: modalConfirmButton
      },
      modalStyle: {
        modalWidth: "large"
      }
    });
    if (modalRef) {
      modalRef.afterClosed.subscribe(res => {
        if (res.confirm && res.data) {
          if (res.data.length === 0) {
            this._snackBar.open(modalTitle, "Aucun fournisseur sélectionné", "warning", 5000);
          } else {
            switch (actionType) {
              case this.actionTypeForSupplierOffer.PRINT_PDF:
                res.data.forEach( supplierOffer => {
                  this.printSupplierOfferPdf(supplierOffer.id, `${this.priceRequestData.reference}-${supplierOffer.supplierCode}`);
                });
                break;
              case this.actionTypeForSupplierOffer.SEND_MAIL:
                this.sendPriceRequest(res.data.map(supplierOffer => supplierOffer.id));
                break;
              default:
                break;
            }
          }
        }
      });
    }
  }

  /**
   * @description Send price request to the selected supplier
   * @author Lainez Eddy
   * @param {number[]} ids
   * @memberof PriceRequestDetailsComponent
   */
  public sendPriceRequest(ids: number[]) {
    const snackBarTitle: string = "Envoi de la demande de prix";
    this._priceRequestsQueriesSrv.sendPriceRequestMails(ids).subscribe( result => {
      this._snackBar.open(snackBarTitle, "La demande de prix a été envoyé", "success", 5000);
      this.getPriceRequestData();
    }, error => {
      this._snackBar.openSnackBarError(snackBarTitle, "La demande de prix n'a pas été envoyé", error);
    });
  }

  /**
   * @description print the supplier offer PDF
   * @author Lainez Eddy
   * @param {number} supplierOfferId
   * @param {string} windowName
   * @memberof PriceRequestDetailsComponent
   */
  public printSupplierOfferPdf(supplierOfferId: number, windowName: string) {
    window.open(`${ApplicationConfig.Url}/api/priceRequests/offer/${supplierOfferId}.pdf`, windowName);
  }

  /**
   * @description Print the barset datas PDF
   * @author Lainez Eddy
   * @memberof PriceRequestDetailsComponent
   */
  public printBarsetPdf() {
    window.open(`${ApplicationConfig.Url}/api/priceRequests/${this._priceRequestId}/barset.pdf`);
  }

  /**
   * @description Change mode of the supply table to 4 for select purchase order elements or 2 to consult the price request elements
   * @author Lainez Eddy
   * @param {boolean} [setActive=true]
   * @memberof PriceRequestDetailsComponent
   */
  public activePurchaseOrderSelection(setActive: boolean = true) {
    this.needToSavePurchaseOrderPreparation = setActive;
    this._editedFormSrv.EditedForm = setActive;
    if (setActive) {
      this.generateMultiTableData(4);
    } else {
      this.generateMultiTableData(2);
    }
  }

  /**
   * @description Generate purchase order with elements selected
   * @author Lainez Eddy
   * @memberof PriceRequestDetailsComponent
   */
  public generatePurchaseOrder() {
    let purchaseOrderDatas: IPurchaseOrderFromSupplierOfferInput[] = this.priceRequestElementsMultiTables.linkedData.map( supplierOffer => {
      const tempData: IPOSupplierOfferElementInput[][] = supplierOffer.elements.map( element => {
        return element.datas.filter( data => {
          return !data.isAdditionnalCost && data.id && data.isSelected && data.selectedQuantity.formGroup.get("selectedQuantity").value ;
        }).map( data => {
          return {
            supplierOfferElementId: !data.isElement ? data.id : null,
            priceRequestElementId: data.isElement ? data.id : null,
            quantity: data.selectedQuantity.formGroup.get("selectedQuantity").value
          };
        });
      });
      const tempDataAddCost: IPOSupplierOfferAdditionnalCostInput[][] = supplierOffer.elements.map( element => {
        return element.datas.filter( data => {
          return data.isAdditionnalCost && data.id && data.isSelected;
        }).map( data => {
          return {
            supplierOfferAdditionnalCostId: !data.isAdditionnalCostId ? data.id : null,
            priceRequestAdditionnalCostId: data.isAdditionnalCostId ? data.id : null
          };
        });
      });
      return {
        supplierOfferId: supplierOffer.id,
        elements: tempData.flat(),
        additionnalCosts: tempDataAddCost.flat()
      };
    });
    purchaseOrderDatas = purchaseOrderDatas.filter(data => data.elements.length > 0);
    const snackBarTitle: string = "Génération de bon de commande";
    if (purchaseOrderDatas.length > 0) {
      this._purchaseOrderQueriesSrv.createPurchaseOrderFromSupplierOffer(purchaseOrderDatas, this._priceRequestId).subscribe( (result: any) => {
        const resultData: {createPurchaseOrderFromSupplierOffer: IPurchaseOrder[]} = result.data;
        if (resultData && resultData.createPurchaseOrderFromSupplierOffer) {
          const message: string = (purchaseOrderDatas.length === 1 ? "Le bon de commande a été créé" : "Les bons de commande ont été créés");
          this._snackBar.open(snackBarTitle, message, "success", 5000);
          this.modalInfoPurchaseOrderCreated(resultData.createPurchaseOrderFromSupplierOffer);
          this.getPriceRequestData();
        }
      }, error => {
        const message: string = (purchaseOrderDatas.length === 1 ? "Le bon de commande n'a pas été créé" : "Les bons de commande n'ont pas été créés");
        this._snackBar.openSnackBarError(snackBarTitle, message, error);
      });
    } else {
      this._snackBar.open(snackBarTitle, "Aucun élément sélectionné", "warning", 5000);
    }
    this.activePurchaseOrderSelection(false);
  }

  /**
   * @description Open modal with the list of purchase order create with the selected response of suppliers
   * @author Lainez Eddy
   * @private
   * @param {IPurchaseOrder[]} purchaseOrders
   * @memberof PriceRequestDetailsComponent
   */
  private modalInfoPurchaseOrderCreated(purchaseOrders: IPurchaseOrder[]) {
    this._modalService.openModal(
      ModalConsultCreatedPurchaseOrderComponent,
      {
        title: "Liste des bons de commande créés",
        type: MODAL_TYPE.NORMAL,
        data: {
          purchaseOrders
        },
        modalStyle: {
          modalWidth: "x-large"
        },
        customData: {
          confirmButtonName: "Fermer",
          displayCancelButton: false,
        }
      }
    );
  }

  /* SUPPLY LIST */
  /**
   * @description Get supply list data, format data for container cards and init config of the container card
   * @author Lainez Eddy
   * @private
   * @memberof PriceRequestDetailsComponent
   */
  private getSupplyListData() {
    this._supplyListSub = this._priceRequestsQueriesSrv.watchSupplyListsInPriceRequest({ priceRequestId: this._priceRequestId }).valueChanges.subscribe( result => {
      const data: any = result.data;
      if (data && data.supplyLists) {
        this.supplyListDatas = data.supplyLists.map( supplyList => {
          return {
            id: supplyList.id,
            projectReference: supplyList.project.reference,
            projectId: supplyList.project.id,
            description: supplyList.description,
            model: supplyList.model,
            isAlreadyInBarset: supplyList.isAlreadyInBarset,
            deliveryDate: supplyList.deliveryDate ? moment.unix(supplyList.deliveryDate).format("DD/MM/YYYY") : supplyList.deliveryDate,
          };
        });
      }
    }, error => {
      console.log("ERROR LOADING SUPPLY LIST > ", {error});
    });
    this.containerCardsConfig = {
      title: "Listes d'approvisionnement",
      actions: [
        {label: "Ajouter liste", fct: this.linkSupplyList}
      ],
      itemConfig: {
        fieldsToDisplay: [
          {key: "projectReference", label: "Projet"},
          {key: "description", label: "Description"},
          {key: "model", label: "Model"},
          {key: "deliveryDate", label: "Date livraison au plus tard"},
        ],
        actions: [
          { label: "Retirer", fct: this.beforeRemoveSupplyList, type: CARD_ACTION_TYPE.DELETE },
          { label: "Editer", fct: this.setSupplyList, type: CARD_ACTION_TYPE.PRIMARY },
          { label: "Consulter", fct: this.consultProject, type: CARD_ACTION_TYPE.SECONDARY }
        ]
      },
      emptyContent: {
        text: "Pas de liste d'approvisionnement !",
        button: {label: "Ajouter une liste d'approvisionnement", fct: this.linkSupplyList}
      }
    };
  }

  /**
   * @description Open modal to add supply list to the price request
   * @private
   * @memberof PriceRequestDetailsComponent
   */
  private linkSupplyList = () => {
    if (!this.checkEditedForm()) {
      const modalRef = this._modalService.openModal(ModalLinkSupplyListComponent, {
        title: "Sélection des listes d'approvisionnement",
        type: MODAL_TYPE.NORMAL,
        data: this.priceRequestData,
        modalStyle: {
          modalWidth: "x-large"
        }
      });
      if (modalRef) {
        modalRef.afterClosed.subscribe(res => {
          if (res.confirm && res.data) {
            this.loading = true;
            this._isSavingBarset = false;
            this.reloadSupplierOfferElement();
            this.getPriceRequestData(true);
            // this.loading = false;
          }
        });
      }
    }
  }

  /**
   * @description Open modal confirm to dissociate supply list of the price request
   * @private
   * @memberof PriceRequestDetailsComponent
   */
  private beforeRemoveSupplyList = (data: ISupplyListForPriceRequestCards[]) => {
    if (!this.checkEditedForm()) {
      console.log(data);
      let msg;
      if (data.length === 1) {
        msg = `Voulez-vous dissocier la liste d'approvisionnement <b>[${data[0].projectReference}] ${data[0].description ? data[0].description + " " : ""} </b>?`;
      } else {
        const tmpMsg = data.map((item) => {
          return `<b>[${item.projectReference}] ${item.description ? item.description : ""}</b>`;
        });
        msg = `Voulez-vous dissocier les listes d'approvisionnement ` + tmpMsg.join(", ") + " ?";
      }
      this._modalService.openModal(ModalConfirmComponent,
        {
          title: "Êtes-vous sûr?",
          type: MODAL_TYPE.CONFIRM,
          data: {
            message: msg,
            actions: [
              "Libérer " + (data.length === 1 ? "la liste" : "les listes") + " d'approvisionnement de la demande de prix",
              "Recalculer la mise en barre",
              "Dissocier les fournisseurs ne correpondant plus aux éléments de la demande de prix"
            ],
          },
          params: { supplyListIds: data.map((item) => item.id) },
          confirmCallback: this.dissociateMultipleSupplyLists
        }
      );
    }
  }

  private setSupplyList = ( data: any = null ) => {
    const modalRef = this._modalService.openModal(
      ModalAddSupplyListComponent,
      {
        title: data ? "Modification d'une liste d'approvisionnement" : "Création d'une liste d'approvisionnement",
        type: MODAL_TYPE.NORMAL,
        data: {
          supplyListData: data,
        },
        modalStyle: {
          modalWidth: "x-large"
        }
      }
    );
    if (modalRef) {
      modalRef.afterClosed.subscribe(res => {
        if (res.confirm && res.data) {
          const newSupplyListData: ISupplyList = {
            ...res.data,
            deliveryDate: this.formatDate(res.data.deliveryDate),
            createdAt: this.formatDate(res.data.createdAt, "DD/MM/YYYY HH:mm")
          };
          if (res.isEdited) {
            this.supplyListData = this.supplyListData.map( supplyList => {
              return (+supplyList.id === +res.data.id) ? newSupplyListData : supplyList;
            });
          } else {
            this.supplyListData.unshift(newSupplyListData);
          }
        }
        this.loadTabData()
      });
    }
  }


  /**
   * @description Format date for display
   * @author Quentin Wolfs
   * @private
   * @param {number} dateSeconds
   * @param {string} [momentFormat="DD/MM/YYYY"] Default DD/MM/YYYY
   * @returns {string}
   * @memberof PriceRequestDetailsComponent
   */
  private formatDate(dateSeconds: number, momentFormat: string = "DD/MM/YYYY"): string {
    return dateSeconds ? moment.unix(dateSeconds).format(momentFormat) : null;
  }

  /**
   * @description Dissociate multiple supply list of the price request
   * @private
   * @memberof PriceRequestDetailsComponent
   */
  private dissociateMultipleSupplyLists = (params: any) => {
    params.supplyListIds.forEach((id) => {
      this.dissociateSupplyList({supplyListId: id});
    });
  }

  /**
   * @description Dissociate supply list of the price request
   * @private
   * @memberof PriceRequestDetailsComponent
   */
  private dissociateSupplyList = (params: any) => {
    this._isSavingBarset = false;
    this.loading = true;
    setTimeout(() => {
      const snackBarTitle: string = "Dissocier une liste d'approvisionnement";
      this._dissociateSupplyListSub = this._priceRequestsQueriesSrv.freeSupplyLists(
        params.supplyListId,
        { priceRequestId: this._priceRequestId },
        this._priceRequestId
      ).subscribe( result => {
        const data: any = result.data;
        if ( data && data.freeSupplyListFromPriceRequest ) {
          this._snackBar.open(snackBarTitle, "La liste d'approvisionnement a été dissociée", "success", 5000);
          this.reloadSupplierOfferElement();
          this.getPriceRequestData();
        } else {
          this._snackBar.openSnackBarError(snackBarTitle, "La liste d'approvisionnement n'a pas été dissociée");
        }
        this.loading = false;
      }, error => {
        this._snackBar.openSnackBarError(snackBarTitle, "La liste d'approvisionnement n'a pas été dissociée", error);
        this.loading = false;
      });
    }, 200);
  }

  /**
   * @description Go to project interface
   * @private
   * @memberof PriceRequestDetailsComponent
   */
  private consultProject = (data: any) => {
    if (data.projectId) {
      window.open(`${ApplicationConfig.getDomain()}/project/edit/${data.projectId}`);
    } else {
      console.error("ERROR TO FIND PROJECT ID");
    }
  }

  /* PRICE REQUEST ELEMENTS */
  /**
   * @description Load price request element and stock datas in _savedPriceRequestElementsAndSuppliers to avais useless loading
   * @author Lainez Eddy
   * @private
   * @memberof PriceRequestDetailsComponent
   */
  private getPriceRequestElements() {
    this._priceRequestElementsSub = this._priceRequestsQueriesSrv.getPriceRequestElementsAndSupplier( this._priceRequestId ).valueChanges.subscribe( result => {
        const data: {priceRequest: IPriceRequest} = <any>result.data;
        if (data && data.priceRequest) {
          const supplierResponses: ILinkedMultiTableGroupData[] = data.priceRequest.supplierOffers.map( supplierOffer => {
            return this.setSupplierResponse(supplierOffer);
          });

          // check if user has permission and change price to 0

          if(!this.hasPermission){

               // Change price element supplierOfferElements 0
              for (let elements = 0; elements < data.priceRequest.elements.length; elements++) {
                  const element = data.priceRequest.elements[elements].supplierOfferElements;
                  for (let supplierOfferElements = 0; supplierOfferElements < element.length; supplierOfferElements++) {
                    const priceSupplierOfferElements = element[supplierOfferElements];
                    priceSupplierOfferElements.price = 0;
                  }
              }

               // Change price additionCost supplierOfferAdditionnalCost 0
              for (let additionCost = 0; additionCost < data.priceRequest.additionnalCosts.length; additionCost++) {
                  const additionCosts = data.priceRequest.additionnalCosts[additionCost].supplierOfferAdditionnalCosts;
                  for (let supplierOfferAdditionnalCost = 0; supplierOfferAdditionnalCost < additionCosts.length; supplierOfferAdditionnalCost++) {
                      const supplierOfferAdditionnalCosts = additionCosts[supplierOfferAdditionnalCost];
                      supplierOfferAdditionnalCosts.price = 0;
                  }
              }
          }

          this._savedPriceRequestElementsAndSuppliers = {
            elements: data.priceRequest.elements,
            additionnalCosts: data.priceRequest.additionnalCosts,
            supplierResponses
          };
          this.generateMultiTableData(2);
        }
      }, error => {
        console.log("ERROR LOADING PRICE REQUEST ELEMENTS", {error});
      }
    );
  }

  /**
   * @description Format price request element datas for supply table by passing the column number to transform supplier response in a purchase order or cancel to display only price and date
   * @author Lainez Eddy
   * @private
   * @param {number} column
   * @memberof PriceRequestDetailsComponent
   */
  private generateMultiTableData(column: number) {
    this.priceRequestElementsMultiTables = this._supplyTableSrv.formatMultiTableData(
      cloneDeep(this._savedPriceRequestElementsAndSuppliers.elements),
      cloneDeep(this._savedPriceRequestElementsAndSuppliers.additionnalCosts),
      cloneDeep(this._savedPriceRequestElementsAndSuppliers.supplierResponses),
      true,
      column
    );
  }

  /**
   * @description Subscribe to action of updating element remark to Open modal of updating PR element remark
   * @author Lainez Eddy
   * @private
   * @memberof PriceRequestDetailsComponent
   */
  private updatePriceRequestElementRemark() {
    const modalTitle: string = "Mise à jour de la remarque";
    this._priceRequestElementRemarkUpdateSub = this._supplyTableSrv.subscribeElementUpdateRemark().subscribe( value => {
      const modalRef = this._modalService.openModal(ModalUpdatePriceRequestElementRemarkComponent, {
        title: modalTitle,
        type: MODAL_TYPE.NORMAL,
        data: value,
      });
      if (modalRef) {
        modalRef.afterClosed.subscribe(res => {
          if (res.confirm && res.data) {
            this._priceRequestsQueriesSrv.updatePriceRequestElement(value.id, res.data, this._priceRequestId).subscribe( result => {
              this.reloadSupplierOfferElement();
              this._snackBar.open(modalTitle, "La remarque a été mise à jour", "success", 5000);
            }, error => {
              this._snackBar.openSnackBarError(modalTitle, "La remarque n'a pas été mise à jour", error);
            });
          }
        });
      }
    });
  }

  /**
   * @description Open modal of add / update element option of the price request (multi for adding)
   * @author Lainez Eddy
   * @param {{elementIds: number[], elementId: number, additionnalData: any}} data
   * @memberof PriceRequestDetailsComponent
   */
  public openModalInpdatePriceRequestElementOption(data: {elementIds: number[], elementId: number, additionnalData: any}) {
    if (!this.checkEditedForm()) {
      const modalTitle: string = data.elementId ? "Modification de l'option d'un élément" : "Ajout d'une option aux éléments sélectionnés";
      const modalRef = this._modalService.openModal(ModalInpdatePriceRequestElementOptionComponent, {
        title: modalTitle,
        type: MODAL_TYPE.NORMAL,
        data: {
          elementIds: data.elementIds,
          elementId: data.elementId,
          optionData: data.additionnalData
        },
        modalStyle: {
          modalWidth: "large"
        }
      });
      if (modalRef) {
        modalRef.afterClosed.subscribe(res => {
          if (res.confirm && res.data) {
            if (this._inpdatePriceRequestElementOptionSub) { this._inpdatePriceRequestElementOptionSub.unsubscribe(); }
            if (res.isUpdate) {
              this._inpdatePriceRequestElementOptionSub = this._priceRequestsQueriesSrv.updatePriceRequestElementOption(res.data.id, res.data.data, this._priceRequestId).subscribe( result => {
                this._snackBar.open(modalTitle, "L'option a été mise à jour", "success", 5000);
                this.reloadSupplierOfferElement();
              }, error => {
                this._snackBar.openSnackBarError(modalTitle, "L'option n'a pas été mise à jour", error);
              });
            } else {
              this._inpdatePriceRequestElementOptionSub = this._priceRequestsQueriesSrv.createPriceRequestElementOptions(res.data.data, this._priceRequestId).subscribe( result => {
                this._snackBar.open(modalTitle, "L'option a été ajoutée", "success", 5000);
                this.reloadSupplierOfferElement();
              }, error => {
                this._snackBar.openSnackBarError(modalTitle, "L'option n'a pas été ajoutée", error);
              });
            }
          }
          this.loadTabData()
        });
      }
    }
  }

  /**
   * @description Modal confirme of the delete element option
   * @author Lainez Eddy
   * @param {number} optionId
   * @memberof PriceRequestDetailsComponent
   */
  public beforeDeleteElementOption(optionId: number) {
    if (!this.checkEditedForm()) {
      this._modalService.openModal(ModalConfirmComponent,
        {
          title: "Êtes-vous sûr?",
          type: MODAL_TYPE.CONFIRM,
          data: {
            message: `Voulez-vous supprimer l'option de cet élément?`,
            actions: [
              "Supprimer l'option",
              "Supprimer les réponses fournisseurs propre à cette option"
            ],
          },
          params: { optionId },
          confirmCallback: this.deleteElementOption
        }
      );
    }
  }

  /**
   * @description Confirm the delete of an element option
   * @memberof PriceRequestDetailsComponent
   */
  public deleteElementOption = (params: any) => {
    const snackBarTitle: string = "Supprimer une option";
    if (this._inpdatePriceRequestElementOptionSub) { this._inpdatePriceRequestElementOptionSub.unsubscribe(); }
    this._inpdatePriceRequestElementOptionSub = this._priceRequestsQueriesSrv.deletePriceRequestElementOption(params.optionId, this._priceRequestId).subscribe(result => {
      if (result.data["deletePriceRequestElementOption"]) {
        this._snackBar.open(snackBarTitle, "L'option a été supprimée", "success", 5000);
      } else {
        this._snackBar.openSnackBarError(snackBarTitle, "L'option n'a pas été supprimée");
      }
    }, error => {
      this._snackBar.openSnackBarError(snackBarTitle, "L'option n'a pas été supprimée", error);
    });
  }

  /**
   * @description Check additionnal cost's click action in the supply table
   * @author Lainez Eddy
   * @param {{datas: any, type: string}} datas
   * @memberof PriceRequestDetailsComponent
   */
  public checkAdditionnalCostActionType(datas: {datas: any, type: string}) {
    switch (datas.type) {
      case "UPDATE":
        this.openModalInpdatePriceRequestAdditionnalCost(datas.datas);
        break;
      case "DELETE":
        this.beforeDeleteAdditionnalCost(datas.datas);
        break;
      default:
        console.log("NO SUCH CASE FOR", datas.type);
    }
  }

  /**
   * @description Open modal to update additionnal cost (No action for adding additionnal cost)
   * @author Lainez Eddy
   * @param {*} [datas]
   * @memberof PriceRequestDetailsComponent
   */
  public openModalInpdatePriceRequestAdditionnalCost(datas?: any) {
    if (!this.checkEditedForm()) {
      const modalTitle: string = datas ? "Modification D'un coût additionnel" : "Ajout d'un coût additionnel";
      const modalRef = this._modalService.openModal(ModalInpdatePriceRequestAdditionnalCostComponent, {
        title: modalTitle,
        type: MODAL_TYPE.NORMAL,
        data: {
          additionnalCost: datas,
        },
        modalStyle: {
          modalWidth: "large"
        }
      });
      if (modalRef) {
        modalRef.afterClosed.subscribe(res => {
          if (res.confirm && res.data) {
            const data: {id: number, additionnalCost: IPriceRequestAdditionnalCostUpdate} = res.data;
            if (data.id) {
              this._priceRequestsQueriesSrv.updatePriceRequestAdditionnalCost(data.id, data.additionnalCost, this._priceRequestId).subscribe( (result: any) => {
                if (result.data && result.data.updatePriceRequestAdditionnalCost) {
                  this._snackBar.open(modalTitle, "Le coût additionnel a été mis à jour", "success", 5000);
                }
              }, error => {
                this._snackBar.openSnackBarError(modalTitle, "Le coût additionnel n'a pas été mis à jour", error);
              });
            } else {
              // ADD ADDITIONNAL COST > No needed yet
            }
          }
        });
      }
    }
  }

  /**
   * @description Open confirm modal to delete additionnal cost
   * @author Lainez Eddy
   * @param {*} data
   * @memberof PriceRequestDetailsComponent
   */
  public beforeDeleteAdditionnalCost(data: any) {
    if (!this.checkEditedForm()) {
      this._modalService.openModal(ModalConfirmComponent,
        {
          title: "Êtes-vous sûr?",
          type: MODAL_TYPE.CONFIRM,
          data: {
            message: `Voulez-vous supprimer le coût additionnel <b>${data.denomination}</b>?`,
            actions: [
              "Supprimer le coût additionnel",
              "Supprimer les réponses fournisseurs propre à ce coût additionnel"
            ],
          },
          params: { additionnalCostId: data.id },
          confirmCallback: this.deleteAdditionnalCost
        }
      );
    }
  }

  /**
   * @description Confirm delete of additionnal cost
   * @memberof PriceRequestDetailsComponent
   */
  public deleteAdditionnalCost = (params: any) => {
    const modalTitle: string = "Supprimer un coût additionnel";
    if (this._inpdatePriceRequestAdditionnalCostSub) { this._inpdatePriceRequestAdditionnalCostSub.unsubscribe(); }
    this._inpdatePriceRequestAdditionnalCostSub = this._priceRequestsQueriesSrv.deletePriceRequestAdditionnalCost(params.additionnalCostId, this._priceRequestId).subscribe(result => {
      if (result.data["deletePriceRequestAdditionnalCost"]) {
        this._snackBar.open(modalTitle, "Le coût additionnel a été supprimé", "success", 5000);
      } else {
        this._snackBar.openSnackBarError(modalTitle, "Le coût additionnel n'a pas été supprimé");
      }
    }, error => {
      this._snackBar.openSnackBarError(modalTitle, "Le coût additionnel n'a pas été supprimé", error);
    });
  }

  /* AMALGAMS */
  /**
   * @description Get amalgams of the priceRequest and format in barset
   * @author Lainez Eddy
   * @private
   * @memberof PriceRequestDetailsComponent
   */
  private getAmalgams() {
    this._amalgamsSub = this._priceRequestsQueriesSrv.watchAmalgams({priceRequestId: this._priceRequestId}).valueChanges.subscribe( result => {
      const data: any = result.data;
      if (data && data.amalgams && !this._isSavingBarset) {
        this._amalgamService.amalgamsData = data.amalgams;
        this.amalgamsForBarset = this._amalgamService.getAmalgamsForBarset();
      }
    }, error => {
      console.log("ERROR LOADING AMALGAMS", {error});
    });
  }

  /**
   * @description Reset barset edit mode
   * @author Lainez Eddy
   * @param {boolean} reset
   * @memberof PriceRequestDetailsComponent
   */
  public onResetBarsetItems(reset: boolean) {
    this.amalgamsForBarset = this._amalgamService.getAmalgamsForBarset();
    this.barsetGeneration.temp = null;
    this.barsetGeneration.toSaved = null;
  }

  /**
   * @description Save edited barset and reset the amalgamsData in the service, the barset generation Data and the barsets to print
   * @author Lainez Eddy
   * @param {IBarsetItem[]} barsetItems
   * @memberof PriceRequestDetailsComponent
   */
  public onSaveBarsetItems(barsetItems: IBarsetItem[]) {
    this._isSavingBarset = true;
    const amalgamsInput: IAmalgamInput[] = this._amalgamService.formatBarsetToSave(barsetItems);
    if (this._saveAmalgamsSub) { this._saveAmalgamsSub.unsubscribe(); }
    const barsetGeneration: IBarsetGenerationUpdate = this.barsetGeneration.toSaved ? this.barsetGeneration.toSaved : null;
    const snackBarTitle: string = "Mise à jour de la mise en barre";
    this._saveAmalgamsSub = this._priceRequestsQueriesSrv.saveAmalgams(this._priceRequestId, amalgamsInput, barsetGeneration).subscribe( result => {
      const data: any = result.data;
      if (data && data.saveAmalgams) {
        this._amalgamService.amalgamsData = data.saveAmalgams;
        this.amalgamsForBarset = this._amalgamService.getAmalgamsForBarset();
        this.reloadSupplierOfferElement();
      }
      this._amalgamService.setUpdateToSave(false);
      this._snackBar.open(snackBarTitle, "La mise en barre a été mise à jour", "success", 5000);
      this.initGenerationBarset(this.barsetGeneration.temp);
    }, error => {
      this._amalgamService.setUpdateToSave(false);
      this._snackBar.openSnackBarError(snackBarTitle, "La mise en barre n'a pas été mise à jour", error);
    });
  }

  /**
   * @description open generation amalgam modal and send barsetItemsLocked with the new generation data
   * @author Lainez Eddy
   * @param {IBarsetItem[]} barsetItemsLocked
   * @memberof PriceRequestDetailsComponent
   */
  public callGenerateAmalgamModal(barsetItemsLocked: IBarsetItem[]) {
    const modalTitle: string = "Paramètres de la mise en barre";
    const modalRef = this._modalService.openModal(ModalGenerateNewAmalgamsComponent, {
      title: modalTitle,
      type: MODAL_TYPE.NORMAL,
      data: {
        priceRequest: this.priceRequestData,
        barsetGeneration: this.barsetGeneration.temp ? this.barsetGeneration.temp : this.barsetGeneration.saved
      },
      customData: {
        confirmButtonName: "Générer"
      }
    });
    if (modalRef) {
      modalRef.afterClosed.subscribe(res => {
        const modalResult: {confirm: boolean, data: IAmalgamParam} = res;
        if (modalResult.confirm && modalResult.data) {
          this._amalgamService.setBarsetToLoad(true);
          const amalgamsInputLocked: IAmalgamInput[] = this._amalgamService.formatBarsetToSave(barsetItemsLocked);
          if ( this._generateNewAmalgamsSub ) { this._generateNewAmalgamsSub.unsubscribe(); }
          this._generateNewAmalgamsSub = this._priceRequestsQueriesSrv.generateNewAmalgams(
            this._priceRequestId,
            amalgamsInputLocked,
            modalResult.data).subscribe( result => {
              const resultData: {generateAmalgams: IAmalgamGenerationResult} = <any>result.data;
              if (resultData && resultData.generateAmalgams) {
                this.setGenerationBarset(resultData.generateAmalgams.barsetGeneration, modalResult.data);
                this.amalgamsForBarset = this._amalgamService.formatAmalgamToPrint(resultData.generateAmalgams.amalgams);
                this._amalgamService.setBarsetToLoad(false);
                this._snackBar.open(modalTitle, "Les paramètres de la mise en barre ont été mis à jour", "success", 5000);
              } else {
                this._amalgamService.setBarsetToLoad(false);
                this._snackBar.openSnackBarError(modalTitle, "Les paramètres de la mise en barre n'ont pas été mis à jour");
              }
            }, error => {
              this._amalgamService.setBarsetToLoad(false);
              this._snackBar.openSnackBarError(modalTitle, "Les paramètres de la mise en barre n'ont pas été mis à jour", error);
            }
          );
        }
      });
    }
  }

  /**
   * @description Save barset generation datas
   * @author Lainez Eddy
   * @private
   * @param {IBarsetGeneration} barsetGeneration
   * @memberof PriceRequestDetailsComponent
   */
  private initGenerationBarset(barsetGeneration: IBarsetGeneration) {
    if (barsetGeneration) {
      this.barsetGeneration = {
        saved: barsetGeneration,
        temp: null,
        toSaved: null
      };
    }
  }

  /**
   * @description Update barset generation datas after the validation of the modal ModalGenerateNewAmalgamsComponent
   * @author Lainez Eddy
   * @private
   * @param {IBarsetGeneration} barsetGeneration
   * @param {IAmalgamParam} params
   * @memberof PriceRequestDetailsComponent
   */
  private setGenerationBarset(barsetGeneration: IBarsetGeneration, params: IAmalgamParam) {
    this.barsetGeneration = {
      ...this.barsetGeneration,
      temp: barsetGeneration,
      toSaved: {
        id: barsetGeneration.id,
        params,
        generationDuration: barsetGeneration.generationDuration,
        partsTotalLength: barsetGeneration.partsTotalLength,
        amalgamsTotalLength: barsetGeneration.amalgamsTotalLength,
        partsQuantity: barsetGeneration.partsQuantity,
        amalgamsQuantity: barsetGeneration.amalgamsQuantity
      }
    };
  }

  /* SUPPLIER OFFERS */
  /**
   * @description watch all supplier offer for the price request and init config of the cards container
   * @author Lainez Eddy
   * @private
   * @memberof PriceRequestDetailsComponent
   */
  private getSupplierOffers() {
    this._supplierOfferSub = this._priceRequestsQueriesSrv.watchSupplierOffers({priceRequestId: this._priceRequestId}).valueChanges.subscribe( result => {
      const resultData: any = result.data;
      if ( resultData && resultData.supplierOffers) {
        if (resultData.supplierOffers.length > 0) {
          this.supplierOfferCardDatas = (<ISupplierOffer[]>resultData.supplierOffers).map( (supplierOffer, index) => {
            if (index === 0) {
              this.loadSupplierOfferElements(supplierOffer.id);
            }
            return this.setSupplierOfferData(supplierOffer, index === 0);
          });
        } else {
          this.supplierOfferCardDatas = [];
          this.supplierOfferMultiTables = {elements: [], linkedData: [], supplyTableLinkedSize: EnumSupplyTableLinkedSize.four};
        }
      }
    }, error => {
      console.log("ERROR LOADING SUPPLIER OFFERS > ", {error});
    });

    this.supplierOffersCardsConfig = {
      title: "Fournisseurs",
      actions: [
        {label: "Ajouter un fournisseur", fct: this.addSupplierOffer}
      ],
      itemConfig: {
        fieldsToDisplay: [
          {key: "code", label: "Code fournisseur"},
          {key: "name", label: "Nom du fournisseur"},
          {key: "contact", label: "Contact"},
        ],
        actions: [
          { label: "Supprimer", fct: this.beforeDeleteSupplierOffers, type: CARD_ACTION_TYPE.DELETE },
          { label: "Editer", fct: this.editSupplierOffer, type: CARD_ACTION_TYPE.PRIMARY },
        ]
      },
      emptyContent: {
        text: "Pas de fournisseur !",
        button: {label: "Ajouter un fournisseur", fct: this.addSupplierOffer}
      }
    };
  }

  /**
   * @description Format SupplierOffer to SupplierOfferCard
   * @author Lainez Eddy
   * @private
   * @param {ISupplierOffer} supplierOffer
   * @param {boolean} selected
   * @returns {ISupplierOfferCard}
   * @memberof PriceRequestDetailsComponent
   */
  private setSupplierOfferData(supplierOffer: ISupplierOffer, selected: boolean): ISupplierOfferCard {
    const supplierContact = supplierOffer.supplierContact;
    let contactName = "";
    if (supplierContact) {
      contactName = `${supplierOffer.supplierContact.lastname ? supplierOffer.supplierContact.lastname : ""} ${supplierOffer.supplierContact.firstname ? supplierOffer.supplierContact.firstname : ""}`;
    }

    return {
      id: supplierOffer.id,
      code: supplierOffer.supplier.code,
      name: supplierOffer.supplier.name,
      contact: contactName,
      contactId: supplierOffer.supplierContactId,
      selectedCard: selected,
      supplierId: supplierOffer.supplierId,
      alreadyEncode: (supplierOffer.elements.some(element => element.price !== null)),
      isSent: supplierOffer.isSent
    };
  }

  /**
   * @description Load element response of a supplier offer for the supply table
   * @author Lainez Eddy
   * @private
   * @param {number} supplierOfferId
   * @memberof PriceRequestDetailsComponent
   */
  private loadSupplierOfferElements(supplierOfferId: number) {
    if (this._supplierOfferElementSub) { this._supplierOfferElementSub.unsubscribe(); }
    this._supplierOfferElementSub = this._priceRequestsQueriesSrv.getSupplierOfferResponse(supplierOfferId).subscribe( result => {
      const resultData: any = result.data;
      if (resultData && resultData.supplierOffer) {
        this._supplierOfferResponse = resultData.supplierOffer;
        const supplierResponse: ILinkedMultiTableGroupData[] = [this.setSupplierResponse(resultData.supplierOffer)];

        //check has permission and change possibleElements supplierOfferElements price 0
        if(!this.hasPermission){
          for (let elements = 0; elements < this._supplierOfferResponse.possibleElements.length; elements++) {
            const element = this._supplierOfferResponse.possibleElements[elements].supplierOfferElements;
            for (let supplierOfferElements = 0; supplierOfferElements < this._supplierOfferResponse.possibleElements[elements].supplierOfferElements.length; supplierOfferElements++) {
              const pricePossibleElements = this._supplierOfferResponse.possibleElements[elements].supplierOfferElements[supplierOfferElements];
              pricePossibleElements.price = 0;
            }
          }
        }


        const allAdditionnalCost: IPriceRequestAdditionnalCost[] = this._supplierOfferResponse.additionnalCosts.map(addCost => {
          return {
            ...addCost.priceRequestAdditionnalCost,
            supplierOfferAdditionnalCosts: [addCost]
          };
        });

        this.supplierOfferMultiTables = this._supplyTableSrv.formatMultiTableData(this._supplierOfferResponse.possibleElements, allAdditionnalCost, supplierResponse, false);
      }
    }, error => {
      console.log("ERROR LOADING SUPPLIER OFFERS ELEMENTS > ", {error});
    });
  }

  /**
   * @description Set SupplierOffer's base datas for supplyTableService (linked part)
   * @author Lainez Eddy
   * @private
   * @param {ISupplierOffer} supplierOffer
   * @param {boolean} [isAdditionnalCost=false]
   * @returns {ILinkedMultiTableGroupData}
   * @memberof PriceRequestDetailsComponent
   */
  private setSupplierResponse(supplierOffer: ISupplierOffer, isAdditionnalCost: boolean = false): ILinkedMultiTableGroupData {

    //check Hasn't permission in see prices change total price 0
    if(!this.hasPermission){
     return {
        id: supplierOffer.id,
        name: supplierOffer.supplier.name,
        elements: [],
        totalPrice: 0,
        totalAdditionnalCosts: 0,
        totalSelectedElementPrice: 0
      };
    }
    return {
      id: supplierOffer.id,
      name: supplierOffer.supplier.name,
      elements: [],
      totalPrice: +(supplierOffer.totalPrice + supplierOffer.totalAdditionnalCosts).toFixed(3),
      totalAdditionnalCosts: supplierOffer.totalAdditionnalCosts,
      totalSelectedElementPrice: +supplierOffer.totalAdditionnalCosts.toFixed(3)
    };
  }

  /**
   * @description Update the supplierOfferCard selected and load elements
   * @author Lainez Eddy
   * @param {ISupplierOfferCard} supplierOfferCard
   * @memberof PriceRequestDetailsComponent
   */
  public selectSupplierOffer(supplierOfferCard: ISupplierOfferCard) {
    this.loadSupplierOfferElements(supplierOfferCard.id);
    this.supplierOfferCardDatas = this.supplierOfferCardDatas.map( supOfferCard => {
      return {
        ...supOfferCard,
        selectedCard: supOfferCard.id === supplierOfferCard.id
      };
    });
  }

  /**
   * @description reload element on update supplier response
   * @author Lainez Eddy
   * @private
   * @memberof PriceRequestDetailsComponent
   */
  private reloadSupplierOfferElement() {
    const supplierOfferCard: ISupplierOfferCard = this.supplierOfferCardDatas.find( supOfferCard => supOfferCard.selectedCard);
    if (supplierOfferCard) {
      this.loadSupplierOfferElements(supplierOfferCard.id);
    }
  }

  /**
   * @description Open modal to add new element in the supplier offer
   * @author Lainez Eddy
   * @param {number} supplierOfferId
   * @memberof PriceRequestDetailsComponent
   */
  public updateSupplierOfferElementsList(supplierOfferId: number) {
    if (!supplierOfferId) {
      const suppSelected: ISupplierOfferCard = this.supplierOfferCardDatas.find( supp => supp.selectedCard);
      supplierOfferId = suppSelected ? suppSelected.id : supplierOfferId;
    }

    if (supplierOfferId) {
      this._priceRequestsQueriesSrv.getPriceRequestElements({priceRequestId: this._priceRequestId}).subscribe( result => {
        const elements: IPriceRequestElement[] = result.data ? (<any>result.data).priceRequestElements : [];
        const modalTitle: string = `Mise à jour des éléments fournissable par ${this._supplierOfferResponse.supplier.name}`;
        const modalRef = this._modalService.openModal(ModalUpdateSupplierOfferElementsListComponent, {
          title: modalTitle,
          type: MODAL_TYPE.NORMAL,
          data: {
            supplierOfferId,
            priceRequestElements: elements,
            possibleElements: this._supplierOfferResponse.possibleElements,
            supplyCategories: this._supplierOfferResponse.supplier.allSupplyCategories
          },
          modalStyle: {
            modalWidth: "x-large"
          }
        });
        if (modalRef) {
          modalRef.afterClosed.subscribe(res => {
            if (res.confirm && res.data) {
              this.associateElementToSupplierOffer(res.data.elementAssociation, modalTitle);
            }
          });
        }
      }, error => {
        console.log("ERROR GETTING PRICE REQUEST ELEMENTS", {error});
      });
    }
  }

  /**
   * @description Associate/disociate element of the supplier offer and reload supplier elements datas for supply table
   * @author Lainez Eddy
   * @private
   * @param {ISupplierOfferElementAssociation} supplierOfferElementAssociation
   * @param {string} modalTitle
   * @memberof PriceRequestDetailsComponent
   */
  private associateElementToSupplierOffer(supplierOfferElementAssociation: ISupplierOfferElementAssociation, modalTitle: string) {
    if (supplierOfferElementAssociation.associatedPriceRequestElementIds.length > 0 || supplierOfferElementAssociation.deletedSupplierOfferElementIds.length > 0) {
      this._priceRequestsQueriesSrv.associateSupplierOfferElements(supplierOfferElementAssociation).subscribe( (resultUpdate: any) => {
        if ( resultUpdate.data && resultUpdate.data.associateSupplierOfferElements) {
          this._snackBar.open(modalTitle, "Les éléments ont été associés à la réponse fournisseur", "success", 5000);
          this.reloadSupplierOfferElement();
        }
      }, error => {
        this._snackBar.openSnackBarError(modalTitle, "Les éléments n'ont pas été associés à la réponse fournisseur", error);
      });

    } else {
      this._snackBar.open(modalTitle, "Aucun élément à associer", "warning", 5000);
    }
  }

  /**
   * @description Load supplier offer for the encode modal
   * @author Lainez Eddy
   * @param {number} supplierOfferId
   * @memberof PriceRequestDetailsComponent
   */
  public beforeEncodeSupplierResponse(supplierOfferId: number) {
    if (!this.checkEditedForm()) {
      if (this._supplierOfferElementEncodeSub) { this._supplierOfferElementEncodeSub.unsubscribe(); }
      this._supplierOfferElementEncodeSub = this._priceRequestsQueriesSrv.getSupplierOfferResponse(supplierOfferId).subscribe( (result: any) => {
        const resultData: any = result.data;
        if (resultData && resultData.supplierOffer) {

            // check hasn't permission
            if(!this.hasPermission){
              //change price supplierOffer possibleElements supplierOfferElements 0
              for (let elements = 0; elements < resultData.supplierOffer.possibleElements.length; elements++) {
                const element = resultData.supplierOffer.possibleElements[elements].supplierOfferElements;
                for (let supplierOfferElements = 0; supplierOfferElements < element.length; supplierOfferElements++) {
                  const pricePossibleElements = element[supplierOfferElements];
                  pricePossibleElements.price = 0;
                }
              }

              //change price supplierOffer additionnalCosts  0
              for (let elements = 0; elements < resultData.supplierOffer.additionnalCosts.length; elements++) {
                let element = resultData.supplierOffer.additionnalCosts[elements];
                 element.price = 0;
              }

            }
          this.encodeSupplierOffer(resultData.supplierOffer);

        }
      }, error => {
        console.log("ERROR LOADING SUPPLIER OFFERS ELEMENTS > ", {error});
      });
    }
  }

  /**
   * @description Open modal encode supplier response
   * @author Lainez Eddy
   * @param {ISupplierOffer} supplierOffer
   * @memberof PriceRequestDetailsComponent
   */
  public encodeSupplierOffer(supplierOffer: ISupplierOffer) {
    if (!this.checkEditedForm()) {
      const modalTitle: string = "Encoder une réponse fournisseur";
      const modalRef = this._modalService.openModal(ModalEncodeSupplierOfferComponent, {
        title: modalTitle,
        type: MODAL_TYPE.NORMAL,
        data: {
          supplierOffer,
          priceRequestId: this._priceRequestId
        },
        modalStyle: {
          modalWidth: "x-large"
        }
      });
      if (modalRef) {
        modalRef.afterClosed.subscribe(res => {
          if (res.confirm && res.data) {
            if (this.supplierOfferCardDatas.some( supOfferCard => supOfferCard.selectedCard && supOfferCard.id === res.data.id)) {
              this.reloadSupplierOfferElement();
            }
          }
        });
      }
    }
  }

  /**
   * @description Add a supplier to the supplier offer list of the price request
   * @private
   * @memberof PriceRequestDetailsComponent
   */
  private addSupplierOffer = () => {
    const modalTitle: string = "Ajouter des fournisseurs";
    const modalRef = this._modalService.openModal(ModalAddSupplierOfferComponent, {
      title: modalTitle,
      type: MODAL_TYPE.NORMAL,
      data: this.supplierOfferCardDatas.map( suppOffer => suppOffer.supplierId),
      customData: {
        confirmButtonName: "Ajouter"
      },
      modalStyle: {
        modalWidth: "large"
      }
    });
    if (modalRef) {
      modalRef.afterClosed.subscribe(res => {
        if (res.confirm && res.data) {
          if ( res.data.length > 0) {
            if (this._associateSupplierSub) { this._associateSupplierSub.unsubscribe(); }
            this._associateSupplierSub = this._priceRequestsQueriesSrv.associateSupplierToPriceRequest(this._priceRequestId, res.data).subscribe( result => {
              this._snackBar.open(modalTitle, "Les fournisseurs ont été ajoutés à la demande de prix", "success", 5000);
            }, error => {
              this._snackBar.openSnackBarError(modalTitle, "Les fournisseurs n'ont pas été ajoutés à la demande de prix", error);
            });
          } else {
            this._snackBar.open(modalTitle, "Aucun fournisseur n'a été ajouté", "info", 5000);
          }
        }
      });
    }
  }

  /**
   * @description Open modal update supplier offer's contact
   * @private
   * @memberof PriceRequestDetailsComponent
   */
  private editSupplierOffer = (data: ISupplierOfferCard) => {
    const modalTitle: string = "Modification du contact fournisseur";
    const modalRef = this._modalService.openModal(ModalUpdateSupplierOfferContactComponent, {
      title: modalTitle,
      type: MODAL_TYPE.NORMAL,
      data: {
        supplierId: data.supplierId,
        contactId: data.contactId,
      }
    });
    if (modalRef) {
      modalRef.afterClosed.subscribe(res => {
        if (res.confirm && res.data) {
          if ( res.data.supplierContactId !== data.contactId) {
            this._priceRequestsQueriesSrv.updateSupplierOfferContact(data.id, res.data, this._priceRequestId).subscribe( result => {
              const resultData: any = result.data;
              if (resultData && resultData.updateSupplierOffer) {
                this._snackBar.open(modalTitle, "Le contact fournisseur a été mise à jour", "success", 5000);
              } else {
                this._snackBar.open(modalTitle, "Le contact fournisseur a été mise à jour", "warning", 5000);
              }
            }, error => {
              this._snackBar.openSnackBarError(modalTitle, "Le contact fournisseur n'a pas été mise à jour", error);
            });
          } else {
            this._snackBar.open(modalTitle, "Le contact fournisseur sélectionné est le même que l'ancien", "warning", 5000);
          }
        }
      });
    }
  }

  /**
   * @description Open confirm modal to delete supplier offer
   * @private
   * @memberof PriceRequestDetailsComponent
   */
  private beforeDeleteSupplierOffer = (data: ISupplierOfferCard) => {
    if (data.isSent) {
      this._modalService.openModal(ModalConfirmComponent,
        {
          title: "Impossible de supprimer la réponse fournisseur",
          type: MODAL_TYPE.CONFIRM,
          data: {
            message: data.alreadyEncode ? `Une réponse a déjà été encodée pour ce fournisseur.` : `La demande de prix a déjà été envoyée à ce fournisseur`,
          },
          customData: {
            deleteButtonName: "Ok",
            deleteButtonColor: "none",
            displayCancelButton: false,
          }
        }
      );
    }
    //  else {
    //   this._modalService.openModal(ModalConfirmComponent,
    //     {
    //       title: "Êtes-vous sûr?",
    //       type: MODAL_TYPE.CONFIRM,
    //       data: {
    //         message: `Voulez-vous supprimer la réponse du fournisseur <b>${data.code} - ${data.name}</b>?`,
    //         actions: [
    //           "Dissocier le fournisseur de cette demande de prix"
    //         ],
    //       },
    //       params: { supplierOfferId: data.id },
    //       confirmCallback: this.deleteSupplierOffer
    //     }
    //   );
    // }
  }

    /**
   * @description Open confirm modal to delete supplier offer
   * @private
   * @memberof PriceRequestDetailsComponent
   */
    private beforeDeleteSupplierOffers = (data: ISupplierOfferCard[]) => {
      const li = [];
      const li2 = [];
      let dataAllowed = [];
      dataAllowed = data.filter(r => r.isSent == false);
      dataAllowed.forEach(r => {
          li.push(`<li><b>${r.code} - ${r.name}</b></li>`);
      });
      let dataNotAllowed = [];
      dataNotAllowed = data.filter(r => r.isSent == true);
      dataNotAllowed.forEach(r => {
        li2.push(`<li><b>${r.code} - ${r.name}</b></li>`);
      });
      const message = `${dataAllowed.length > 0 ? `Voulez-vous supprimer les réponses du fournisseurs suivantes : <ul>${li}</ul>` : ""}`;
      const message2 = `${dataNotAllowed.length > 0 ? `Impossible de supprimer la réponse fournisseur suivantes : <ul>${li2}</ul>` : ""}`;
      const ids = dataAllowed.map(r => r.id);
      if (dataAllowed.length > 0) {
        this._modalService.openModal(ModalConfirmComponent,
          {
            title: "Êtes-vous sûr?",
            type: MODAL_TYPE.CONFIRM,
            data: {
              message: `${message} ${message2}`,
              actions: [
                "Dissocier le fournisseur de cette demande de prix"
              ],
            },
            params: { ids: ids },
            confirmCallback: this.deleteSupplierOffers
          }
        );

      } else {
        this._modalService.openModal(ModalConfirmComponent,
          {
            title: "Impossible de supprimer la réponse fournisseur",
            type: MODAL_TYPE.CONFIRM,
            data: {
              message: message2
            },
            customData: {
              deleteButtonName: "Ok",
              deleteButtonColor: "none",
              displayCancelButton: false,
            }
          }
        );
      }

    }

  /**
   * @description Remove supplier offer
   * @private
   * @memberof PriceRequestDetailsComponent
   */
  private deleteSupplierOffer = (params: any) => {
    const snackBarTitle: string = "Dissocier un fournisseur";
    this._deleteSupplierOfferSub = this._priceRequestsQueriesSrv.deleteSupplierOffer(params.supplierOfferId, this._priceRequestId).subscribe( (result: any) => {
      if ( result.data && result.data.deleteSupplierOffer) {
        this._snackBar.open(snackBarTitle, "La réponse fournisseur a été supprimée", "success", 5000);
      }
    }, error => {
      this._snackBar.openSnackBarError(snackBarTitle, "La réponse fournisseur n'a pas été supprimée", error);
    });
  }
  private deleteSupplierOffers = (params: any) => {
    const snackBarTitle: string = "Dissocier les fournisseurs";
    this._deleteSupplierOfferSub = this._priceRequestsQueriesSrv.deleteSupplierOffers(params.ids, this._priceRequestId).subscribe( (result: any) => {
      if ( result.data && result.data.deleteSupplierOffers) {
        this._snackBar.open(snackBarTitle, "Les réponses fournisseurs ont été supprimées", "success", 5000);
        this.supplierOfferToDelete = [];
        this.supplierOfferCardDatas = this.supplierOfferCardDatas.filter( supOfferCard => {
          return params.ids.indexOf("" + supOfferCard.id) === -1;
        });
      }
    }, error => {
      this._snackBar.openSnackBarError(snackBarTitle, "Les réponses fournisseurs n'ont pas été supprimées", error);
    });
  }
  /* HEADERS METHODES */
  /**
   * @description Action to return on price request list
   * @author Lainez Eddy
   * @memberof PriceRequestDetailsComponent
   */
  public returnAction() {
    if (!this.checkEditedForm()) {
      this._router.navigate(["/price-request/"]);
    }
  }

  /**
   * @description Open modal confirm to delete price request
   * @author Lainez Eddy
   * @memberof PriceRequestDetailsComponent
   */
  public beforeDeletePriceRequest() {
    this._modalService.openModal(ModalConfirmComponent,
      {
        title: "Êtes-vous sûr?",
        type: MODAL_TYPE.CONFIRM,
        data: {
          message: `Voulez-vous supprimer la demande de prix <b>${this.priceRequestData.reference}</b>?`,
          actions: [
            "Supprimer la demande de prix de manière définitive",
            "Libérer les listes d'approvisionnement liées à cette demande de prix"
          ],
        },
        params: { priceRequestId: this._priceRequestId },
        confirmCallback: this.deletePriceRequest
      }
    );
  }

  /**
   * @description Delete price request and redirect to price request list
   * @memberof PriceRequestDetailsComponent
   */
  public deletePriceRequest = (params: any) => {
    const snackBarTitle: string = "Supprimer une demande de prix";
    if (this._deletePriceRequestSub) { this._deletePriceRequestSub.unsubscribe(); }
    this._deletePriceRequestSub = this._priceRequestsQueriesSrv.deletePriceRequest(params.priceRequestId).subscribe(result => {
      if (result.data["deletePriceRequest"]) {
        this._snackBar.open(snackBarTitle, "La demande de prix a été supprimée", "success", 5000);
      } else {
        this._snackBar.openSnackBarError(snackBarTitle, "La demande de prix n'a pas été supprimée");
      }
      this.returnAction();
    }, error => {
      this._snackBar.openSnackBarError(snackBarTitle, "La demande de prix n'a pas été supprimée", error);
    });
  }

  /**
   * @description Check if we are in edit mode in case of the user would like to disconnect
   * @author Lainez Eddy
   * @memberof PriceRequestDetailsComponent
   */
  public checkLogoutCancelled() {
    this.checkEditedForm();
  }

}
