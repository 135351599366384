import { Injectable } from "@angular/core";
import { CanActivate, ActivatedRouteSnapshot, Router } from "@angular/router";
import { Observable, of } from "rxjs";
import { PermissionService } from "app/facade/services/permission.service";
import { AuthService } from "./auth.service";

@Injectable()
export class PermissionGuard implements CanActivate {
  public constructor(private _permissionService: PermissionService, private _authSrv: AuthService, private _router: Router) { }

  /**
  * @description Determine if we can access the asked route
  * @author Hassan EL BAROUDI
  * @param {ActivatedRouteSnapshot} route
  * @returns {Observable<boolean>}
  * @memberof PermissionGuard
  */
  public canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    if (!this._permissionService.hasPermission(this._authSrv.isUserGroup(), route.data['category'], "read")) {
      this._router.navigate(["/"]);
      return of(false);
    }
    return of(true);
  }
}
