import { Injectable } from "@angular/core";
import { Apollo } from "apollo-angular";
import { GET_PERMISSION, GET_PERMISSION_USERGROUP, UPDATE_PERMISSION} from "./permission.queries";


@Injectable()
export class PermissionQueriesService {

constructor(private _apollo: Apollo) { }

// public addSmtpConfig(smtp) {
//   return this._apollo.mutate({
//       mutation: ADD_SMTP_CONFIG,
//       variables : {
//           smtp: smtp,
//       }
//   });
// }

public updatePermission(permission,id) {
  return this._apollo.mutate({
      mutation: UPDATE_PERMISSION,
      variables : {
        permission,
        id
      }
  });
}

 /**
 * @description Find a Permission by its properties
 * @author Marie claudia
 * @param userGroup string
 * @returns
 * @memberof PermissionService
 */

  public getPermissionyUserGroup(userGroup : string) {
    return this._apollo.query({
        query: GET_PERMISSION_USERGROUP,
        variables: {userGroup: userGroup},
        fetchPolicy: "network-only"
    });
  }

 /**
 * @description Find a Permission by its properties
 * @author Marie claudia
 * @param userGroup string
 * @returns
 * @memberof PermissionService
 */

  public getPermission(userGroup : string, category: string) {
    return this._apollo.query({
        query: GET_PERMISSION,
        variables: {
          userGroup: userGroup,
          category: category
        },
        fetchPolicy: "network-only"
    });
  }

}
