import { ITableFormActions } from "../interfaces/tables.interface";
import { EnumQuantityUnitSelectValue } from "./element-unit-category.enum";
import { ISupplyListFieldsConfig } from "../interfaces/fields-config.interface";

export const FIELDS_DEFAULT_VALUE = {
  STRING : null,
  INT : null,
  BOOLEAN : false,
};

/**
 * Fields names use for the header
 */
export const FIELDS_NAMES = {
  reference: "Référence",
  denomination: "Dénomination",
  matterRef: "Matière",
  quantity: "Qté",
  format: "format",
  isBlack: "Noir",
  isBlasted: "Gren.",
  isPrimaryBlasted: "GPP",
  remark: "Remarque",
  length: "Longueur",
  width: "Largeur",
  thickness: "Epaisseur",
  quantityUnit: "Unité",
  project:"Projet"
};

/**
 * This field config is used in table-form component to create a config who while be used in the component
 * to display the inputs
 */
export const FIELDS_TYPE_CONFIG: ISupplyListFieldsConfig = {
  reference: { header: "Référence", key: "reference", name: "reference", type: "suggest", linkedField: "elementId", actionToDo: null, addedData: {}, options: [], priority: 1 },
  denomination: { header: "Dénomination", key: "denomination", name: "denomination", type: "text", priority: 2 },
  matterRef: { header: "Matière", key: "matterRef", name: "matterRef", type: "suggest", linkedField: "matterId", actionToDo: null, addedData: {}, options: [], priority: 3 },
  quantity: { header: "Qté", key: "quantity", name: "quantity", type: "number", priority: 4 },
  format: { header: "Format", key: "format", name: "format", type: "text", priority: 5 },
  isBlack: { header: "Noir", key: "isBlack", name: "elementOptions", type: "radio", options: [{label: "", value: "isBlack"}], priority: 6 },
  isBlasted: { header: "Gren.", key: "isBlasted", name: "elementOptions", type: "radio", options: [{label: "", value: "isBlasted"}], priority: 7 },
  isPrimaryBlasted: { header: "GPP", key: "isPrimaryBlasted", name: "elementOptions", type: "radio", options: [{label: "", value: "isPrimaryBlasted"}], priority: 8 },
  remark: { header: "Remarque", key: "remark", name: "remark", type: "text", priority: 99 },
  thickness: { header: "Épaisseur", key: "thickness", name: "thickness", type: "number", priority: 6 },
  length: { header: "Longueur", key: "length", name: "length", type: "number", priority: 7 },
  width: { header: "Largeur", key: "width", name: "width", type: "number", priority: 8 },
  quantityUnit: { header: "Unité", key: "quantityUnit", name: "quantityUnit", type: "select", options: Object.keys(EnumQuantityUnitSelectValue).map( unit => ({value: unit, label: EnumQuantityUnitSelectValue[unit]})), priority: 5 },
  basicQuantityUnit: { header: "Unité", key: "basicQuantityUnit", name: "basicQuantityUnit", type: "text", priority: 5 },
  project: { header: "Projet", key: "project", name: "project", type: "select", options: [], priority: 98, onlyBdc: true},
};

/**
 * Use to create a input option group
 */
export const FIELDS_SPECIAL_KEY = {
  elementOptions: ["isBlack", "isBlasted", "isPrimaryBlasted"]
};

export const SUPPLY_LIST_TABLE_CONFIG = {
  headerActionList: [
    { id: "isBlack", name: FIELDS_NAMES.isBlack, actionToExecute: null },
    { id: "isBlasted", name: FIELDS_NAMES.isBlasted, actionToExecute: null },
    { id: "isPrimaryBlasted", name: FIELDS_NAMES.isPrimaryBlasted, actionToExecute: null }
  ]
};

export const DEFAULT_TABLE_FORM_ACTION: ITableFormActions = {
  addAction: {type: "ICON", content: "fa-plus", contentClass: "button_icon" },
  deleteAction: {type: "ICON", content: "fa-trash-alt", contentClass: "button_icon button_color_red" }
};
