import { Component, OnInit, OnDestroy } from "@angular/core";
import { AuthService } from "@lib/auth/auth.service";
import { ConnectionService } from "./facade/services/connection.service";

@Component({
    selector: "app-root",
    templateUrl: "./app.component.html",
    styleUrls: ["./app.component.scss"]
})
export class AppComponent implements OnInit, OnDestroy {
    public title: string;
    public constructor(private _auth: AuthService, private _connectionSrv: ConnectionService) {
        this.title = "Devis";
    }

    public ngOnInit(): void {
        // ... CODE
      this._connectionSrv.startRefreshToken();
    }

    public ngOnDestroy(): void {
      this._connectionSrv.endRefreshToken();
    }
}