import gql from "graphql-tag";

export const LIST_QUOTES = gql`
query quotes($search:String,$sort:SortQuote, $pagination:Pagination){
    quotes(search:$search,sort:$sort,pagination:$pagination){
            _id
            name
            reference
            isEn1090
            number
            remarks
            project{
                reference
                customer
              }
    }
}`;

export const LIST_DISPLAY_QUOTES = gql`
query displayQuotes($search: String, $sort: SortQuote, $pagination: Pagination) {
    displayQuotes(search: $search, sort: $sort, pagination: $pagination) {
        _id
        name
        reference
        number
        isEn1090
    }
}`;

export const DELETE_QUOTE = gql`mutation($_id:String!){
    deleteQuote(_id:$_id)
}`;

export const ADD_QUOTE = gql`mutation($quote:InputQuote!){
    createQuote(quote:$quote){
        _id
        name
        reference
        number
        projectId
    }
}`;

export const UPDATE_QUOTE = gql`mutation($_id:String!,$quote:UpdateQuote!){
    updateQuote(_id:$_id,quote:$quote){
        _id
        name
        reference
        number
        projectId
    }
}`;

export const GET_QUOTE = gql`
query Quote($_id: String!) {
    quote(_id: $_id){
        _id
        name
        number
        reference
        projectId
        needSandblasting
        needMetallization
        needLacquering
        needPainting
        needGalvanization
        remarks
        totalPrice
        project{
            name
        }
        isEn1090
        createdAt
        elements
        {
            isVisible
            quantity
            unitPrice
            customName
            customCost
            unitaryAdditionalCost
            globalAdditionalCost
            remarks
            useClass
            content
            children {
                isVisible
                quantity
                unitPrice
                customName
                customCost
                unitaryAdditionalCost
                globalAdditionalCost
                remarks
                useClass
                content
                additionalComputings {
                    name
                    unitPrice
                    unitaryAdditionalCost
                    globalAdditionalCost
                    remarks
                    type
                    customCost
                    customName
                    useClass
                    properties
                }
            }
        }
    }
}`;

export const GET_NUMBER = gql`
query { getQuoteNumber }
`;

export const GET_SEARCH_PLATE_THICKNESS = gql`
query searchPlateThickness($matterId: ID!, $search: String!) {
     searchPlateThickness(matterId:$matterId, search:$search)  }
`;

export const DUPLICATE_QUOTE = gql`
mutation duplicateQuote($_id: String!) {
  duplicateQuote(_id: $_id){
      _id
    }
}`;
