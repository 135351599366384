import { OnInit, Inject, OnDestroy } from "@angular/core";
import { CONTAINER_DATA, StepFormService, ActualData } from "app/presentationnal/organisms/wizard-modal/service/step-form.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { CatalogQueriesService } from "app/facade/queries/catalog/async/catalog_queries.service";
import { CustomElementService } from "app/facade/quote-element-definition/elements/customs/custom.service";
import { Matter } from "app/facade/interfaces/matter";
import { CustomFormulaDisplayer } from "app/facade/quote-element-definition/formula.display";
import { Subscription } from "rxjs";
import { CustomElement } from "app/facade/quote-element-definition/elements/customs/custom.base";
import { CustomAvailable } from "./step-zero-base";
import { ISelectOption } from "app/presentationnal/atoms/inputs/select-input/selectOptions";
import { QuotesQueriesService } from "app/facade/queries/quote/async/quotes_queries.service";

export abstract class StepMatterBase implements OnInit, OnDestroy {

  public errorForm: boolean = false;
  public stepMatterForm: FormGroup;
  public availableMatter: any[] = [];
  public indexElement: number;
  public matterId: number;
  public clickElement: any;
  public customData: CustomElement;
  public useClass: CustomAvailable;
  public dataTicket: { element: any, children: any[] };
  public imgPath: string;
  public calculs: string[] = [];

  protected actualData: ActualData;
  protected formulaDisplayer: CustomFormulaDisplayer;
  private _formulaSub: Subscription;

  private _thicknessSub: Subscription;
  public optionsThickness: ISelectOption[] = [];
  public autoSelectData: boolean;

  constructor(@Inject(CONTAINER_DATA) data: any,
  protected _stepFormSrv: StepFormService,
    protected _catalogQueriesSrv: CatalogQueriesService,
    protected _customElementSrv: CustomElementService,
    protected _quoteQueriesSrv: QuotesQueriesService,
    protected _fb: FormBuilder) {
      this.useClass = data.useClass;
      this.actualData = this._stepFormSrv.get();
      this.indexElement = this.actualData.isEdited ? this.actualData.index : this._stepFormSrv.getLastElementSelected();
      this.dataTicket = data.ticket[this.indexElement];
      this.autoSelectData = this.actualData.custom !== undefined;
      this.imgPath = "../../../../../../../../assets/img/wizard/" + this.useClass.toLowerCase() + "/step_one.jpg";
  }

  ngOnInit() {
    this.getMatterByElementGroupId();
    this.stepMatterForm = this._fb.group(this.generateFormContent());

    this.formulaDisplayer = new CustomFormulaDisplayer();
    this._formulaSub = this.formulaDisplayer.$matterPrice.subscribe(calculs => this.calculs = calculs);
    this.showCalcul();
  }

  ngOnDestroy() {
    this._formulaSub.unsubscribe();
    if (this._thicknessSub) {
      this._thicknessSub.unsubscribe();
    }
  }

  protected generateFormContent(): any {
    const tempData: any = this.actualData.custom.properties;
    const form: any = {
      matterName: [null, Validators.required],
      thickness: [tempData.thickness, Validators.required],
      height: [tempData.height, Validators.required],
      width: [tempData.width, Validators.required],
      UnitaryAdditionalCost: this.actualData.custom.UnitaryAdditionalCost,
      GlobalAdditionalCost: this.actualData.custom.GlobalAdditionalCost,
      Remarks: this.actualData.custom.Remarks,
      pricePerKg: [tempData.matter.pricePerKg, Validators.required],
      kgByLiter: [tempData.matter.kgByLiter, Validators.required],
    };
    return form;
  }

  protected getMatterByElementGroupId() {
    this._catalogQueriesSrv.getMatter(true).subscribe(result => {
      this.availableMatter = result.data["availableMatters"];
      this.chooseElement(this.dataTicket.element, true);
      if (this.actualData.custom) {
        this.getThickness(this.actualData.custom.properties.thickness, true);
        setTimeout( () =>
          this.stepMatterForm.get("thickness").markAsUntouched()
        , 250);
      }
    });
  }

  public getThickness(typeFilter: string = "", init: boolean = false ) {
    this._thicknessSub = this._quoteQueriesSrv.getSearchTickness( typeFilter, +this.matterId ).subscribe( result => {
      if (this.optionsThickness.length > 0) {this.optionsThickness = []; }
      const projectOption: any = result.data;
      this.optionsThickness = projectOption.searchPlateThickness.map(element => {
        return {
          label : element,
          value : element,
        };
      });
      if (init) {
        this.optionsThickness = this.optionsThickness.filter( element => element.label === typeFilter );
      }
    });
  }

  public chooseElement(choice: any, init: boolean = false) {
    this.clickElement = choice;
    this.chooseMatter(choice.properties.matter, init);
  }

  public chooseMatter(matter: Matter, init: boolean = false) {
    let indexMatter: number,
      definedMatterData: boolean = true;
    if (init) {
      indexMatter = this.availableMatter.findIndex(q => q.id == this.actualData.custom.properties.matter.id);
      definedMatterData = false;
    } else {
      indexMatter = this.availableMatter.findIndex(q => q.name == matter.name);
    }
    if (indexMatter !== -1) {
      const matterData: Matter = this.availableMatter[indexMatter];
      this.stepMatterForm.get("matterName").patchValue(matterData.name);
      if (definedMatterData) {
        this.stepMatterForm.get("pricePerKg").patchValue(matterData.pricePerKg);
        this.stepMatterForm.get("kgByLiter").patchValue(matterData.kgByLiter);
      }
      if (!init) {
        this.actualData.custom.properties.matter = {
          id: +matterData.id,
          en1090Name: matterData.en1090Name,
          name: matterData.name,
          pricePerKg: matterData.pricePerKg,
          kgByLiter: matterData.kgByLiter
        };
      }
      this.errorForm = false;
      this.matterId = matterData.id;
    }
  }

  public showCalcul() {
    this.stepMatterForm.valueChanges.subscribe(values => {
      const base = {
        width: values.width || null,
        height: values.height || null,
        thickness: values.thickness || null,
        matterPriceByKg: values.pricePerKg || null,
        matterKgByLiter: values.kgByLiter || 1
      };

      if (Object.keys(base).every(k => base[k])) {
        this.formulaDisplayer.base = base;
      }
    });
  }

  public stepChange() {
    const isMatter: string[] = ["pricePerKg", "kgByLiter"],
          doNothing: string[] = ["matterName"];

    Object.keys(this.stepMatterForm.controls).map(key => {
      if (this.stepMatterForm.get(key).touched && doNothing.indexOf(key) === -1) {
        if (isMatter.indexOf(key) !== -1) {
          this.actualData.custom.properties.matter[key] = this.stepMatterForm.get(key).value;
        } else {
          this.actualData.custom[key] = this.stepMatterForm.get(key).value;
        }
      }
    });
    this._stepFormSrv.change( { custom: this.actualData.custom } );
  }

  public next(fastSave: boolean = false) {
    if (this.stepMatterForm && this.stepMatterForm.valid) {
      this.stepChange();
      this._stepFormSrv.updateElement(this.actualData.custom, null, this.actualData.index, this.actualData.childrenIndex);
      if (fastSave) {
        this._stepFormSrv.closeModal$.next(true);
      } else {
        this._stepFormSrv.navigate$.next(true);
      }
    } else {
      this.errorForm = !this.stepMatterForm.get("matterName").valid;
      this._stepFormSrv.markFormGroupTouched(this.stepMatterForm);
    }
  }

  public previous() {
    this.stepChange();
    if (!this.actualData.isEdited) {
      this._stepFormSrv.deleteElement(this.actualData.index, this.actualData.childrenIndex);
    }
    this._stepFormSrv.navigate$.next(false);
  }

  public cancel() {
    if (!this.actualData.isEdited) {
      this._stepFormSrv.deleteElement(this.actualData.index, this.actualData.childrenIndex);
    }
    this._stepFormSrv.closeModal$.next(true);
  }

}
