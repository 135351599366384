import { TubeProperty } from "./tube";
import { MM_TO_M, SQUARE_MM_TO_SQUARE_M, SQUARE_MM_TO_SQUARE_CM, calculateNewValueWithCuttinAngle } from "app/facade/quote-element-definition/base";
import { Element } from "../../element.base";
import { IWeldiableMeterable } from "app/facade/quote-element-definition/assemblages/welding";
import { IDrillable } from "app/facade/quote-element-definition/operations/drilling";
import { ICuttable } from "app/facade/quote-element-definition/operations/cutting";
import { Matter } from "app/facade/interfaces";

export interface RoundTubeNatureValues {
  A: number;
  D: number;
  A_L: number;
  T_p: number;
  Kg_m: number;
}

export class RoundTube extends Element implements IWeldiableMeterable, IDrillable, ICuttable {

    public constructor(
        name: string,
        reference: string,
        private values: RoundTubeNatureValues,
        public properties: TubeProperty
    ) {
        super(name, reference);
    }

    public get Matter(): Matter {
        return this.properties.matter;
    }

    public get meterForWelding(): number {
        return (this.values.D * Math.PI) * MM_TO_M;
    }

    // TODO: Need formule with angle
    public meterForWeldingWithCut(angle: number): number {
      const newMeter = calculateNewValueWithCuttinAngle((this.values.D * Math.PI), angle);
      return newMeter * MM_TO_M;
    }

    public get TotalArea(): number {
        return (this.values.A_L * SQUARE_MM_TO_SQUARE_M) * (this.properties.length * MM_TO_M);
    }

    public get TotalWeight(): number {
        return (this.properties.length * MM_TO_M) * this.values.Kg_m;
    }

    public get CalculatedPrice(): number {
        return this.properties.matter.pricePerKg * this.TotalWeight;
    }

    public get section(): number {
        return this.values.A * SQUARE_MM_TO_SQUARE_CM;
    }

    public get thickness(): number {
        return this.values.T_p;
    }

    public get drillThickness(): number {
      return this.values.T_p;
    }

    public get Angle1(): number {
      return this.properties.angle1;
    }

    public set Angle1(value: number) {
      if (value === null) {
        delete this.properties.angle1;
      } else {
        this.properties.angle1 = value;
      }
    }

    public get Angle2(): number {
      return this.properties.angle2 || 90;
    }

    public set Angle2(value: number) {
      if (value === null) {
        delete this.properties.angle2;
      } else {
        this.properties.angle2 = value;
      }
    }

    public set Properties(properties: TubeProperty) {
      this.properties = { ...properties };
      this.elementUpdated();
    }

    public set Values(values: RoundTubeNatureValues) {
      this.values = { ...values };
      this.elementUpdated();
    }

}