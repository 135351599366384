import { Component, OnInit, Inject, OnDestroy } from "@angular/core";
import { ModalService, DATA } from "../../services/modal.service";
import { SnackService } from "app/presentationnal/organisms/snack-bar/services/snack.service";
import { FormBuilder } from "@angular/forms";
import { ModalContentForm } from "../../model/modal-content-form";
import { SuppliersQueriesService } from "app/facade/queries/supplier/suppliers-queries.service";
import { Subscription } from "rxjs";
import { ISupplierContact } from "app/facade/interfaces/supplier";
import { IContainerCardsItemConfig } from "app/facade/interfaces/container-cards-config.interface";
import { ISupplierOfferUpdate } from "app/facade/interfaces/supplier-offer";

interface ISupplierContactClickable extends ISupplierContact {
  selectedCard: boolean;
}

@Component({
  selector: "app-modal-update-supplier-offer-contact",
  templateUrl: "./modal-update-supplier-offer-contact.component.html",
  styleUrls: ["./modal-update-supplier-offer-contact.component.css"]
})
export class ModalUpdateSupplierOfferContactComponent extends ModalContentForm implements OnInit, OnDestroy {
  private _supplierContactSub: Subscription;
  private _supplierId: number;
  private _contactId: number;
  public supplierContact: ISupplierContactClickable[] = [];
  public supplierContactConfig: IContainerCardsItemConfig;

  constructor(
    protected _modalService: ModalService,
    protected _snackBar: SnackService,
    protected _fb: FormBuilder,
    @Inject(DATA) private _data: {data: {supplierId: number, contactId: number}},
    private _supplierQueriesSrv: SuppliersQueriesService) {
      super(_modalService, _fb, _snackBar);
      this._supplierId = this._data.data.supplierId;
      this._contactId = this._data.data.contactId;
    }

  ngOnInit() {
    this.initSupplierContactData();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this._supplierContactSub.unsubscribe();
  }

  private initSupplierContactData() {
    this._supplierContactSub = this._supplierQueriesSrv.getSupplierContact(this._supplierId).subscribe( result => {
      const data: any = result.data;
      if (data && data.supplierContacts) {
        this.supplierContact = data.supplierContacts.map( supplierContact => {
          return {
            ...supplierContact,
            selectedCard: this._contactId === supplierContact.id
          };
        });
      }

      this.supplierContactConfig = {
        fieldsToDisplay: [
          {key: "lastname", label: "Nom"},
          {key: "firstname", label: "Prénom"},
          {key: "phone", label: "Téléphone"},
          {key: "mail", label: "Email"},
          {key: "language", label: "Langue"},
          {key: "function", label: "Fonction"},
        ],
        actions: []
      };
    }, error => {
      console.log("ERROR LOADING SUPPLIER CONTACTS > ", {error});
    });
  }

  public selectContact(data: ISupplierContactClickable) {
    this.supplierContact = this.supplierContact.map( contact => {
      return {
        ...contact,
        selectedCard: contact.id === data.id
      };
    });
  }

  public confirmModal() {
    if (this.supplierContact.find( contact => contact.selectedCard)) {
      this.save();
      this._modalService.closeModal();
    }
  }

  protected save(): void {
    const dataToSave: ISupplierOfferUpdate = {
      elements: [],
      additionnalCosts: [],
      deletedVariantIds: [],
      deletedAdditionnalCostIds: [],
      deletedVariantOptionIds: [],
      deletedSupplierOfferElementOptionIds: [],
      supplierContactId: this.supplierContact.find( contact => contact.selectedCard).id
    };
    const objData = {
      confirm: true,
      data: dataToSave
    };
    this.afterClosed.next(objData);
  }

}
