import { BrowserModule, Title } from "@angular/platform-browser";
import { NgModule, LOCALE_ID, ErrorHandler } from "@angular/core";
// Not use > Test for currency pipe
import localeFr from "@angular/common/locales/fr";
import localeFrExtra from "@angular/common/locales/extra/fr";
registerLocaleData(localeFr, "fr-FR", localeFrExtra);

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { GraphQLModule } from "@lib/graphql/graphql.module";
import { ModalService } from "./presentationnal/organisms/modal/services/modal.service";
import { SnackService } from "./presentationnal/organisms/snack-bar/services/snack.service";
import { OrganismsModule } from "./presentationnal/organisms/organisms.module";
import { SnackBarComponent, SnackComponent, BottomBarComponent } from "./presentationnal/organisms";

import { LoginModule } from "./container/pages/login/login.module";
import { CatalogModule } from "./container/pages/catalog/catalog.module";
import { QuotesModule } from "./container/pages/quotes/quotes.module";
import { BiomesModule } from "./container/biomes/biomes.module";
import { WizardsModule } from "./container/pages/wizards/wizards.module";
import { registerLocaleData } from "@angular/common";
import { SuppliersModule } from "./container/pages/suppliers/suppliers.module";
import { ProjectsModule } from "./container/pages/projects/projects.module";
import { ConnectionService } from "./facade/services/connection.service";
import { PriceRequestsModule } from "./container/pages/priceRequests/price-requests.module";
import { AmalgamService } from "./facade/services/amalgam.service";
import { PurchaseOrderModule } from "./container/pages/purchaseOrder/purchase-order.module";
import { GlobalErrorHandlerService } from "./facade/services/global-error.service";
import { UsersModule } from "./container/pages/users/users.module";
import { SmtpConfigQueriesService } from "./facade/queries/smtp/smtp-config.queries.service";
import { TabpermissionManagementComponent } from './container/pages/permissionManagement/tabpermission-management/tabpermission-management.component';
import { PermissionModule } from "./container/pages/permissionManagement/permission.module";
import { PermissionQueriesService } from "./facade/queries/permission/permission.queries.service";
import { ElementBdcComponent } from './container/pages/elementBdc/element-bdc/element-bdc.component';
import { ListingElementComponent } from './container/pages/elementBdc/listing-element/listing-element.component';
import { PurchaseOrderElementQueriesService } from "./facade/queries/purcase-order-element/purchase-order-element-queries.service";
import { PermissionGuard } from "@lib/auth/permission.guard";


@NgModule({
    declarations: [
        AppComponent,
        SnackBarComponent,
        SnackComponent,
        BottomBarComponent,
        ElementBdcComponent,
        ListingElementComponent,
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        GraphQLModule,
        OrganismsModule,
        LoginModule,
        CatalogModule,
        QuotesModule,
        SuppliersModule,
        ProjectsModule,
        PriceRequestsModule,
        PurchaseOrderModule,
        BiomesModule,
        WizardsModule,
        UsersModule,
        PermissionModule
    ],
    providers: [
        { provide: LOCALE_ID, useValue: "fr-FR" },
        GlobalErrorHandlerService,
        { provide: ErrorHandler, useClass: GlobalErrorHandlerService },
      PermissionGuard,
        Title,
        ModalService,
        SnackService,
        ConnectionService,
        AmalgamService,
        SmtpConfigQueriesService,
        PermissionQueriesService,
        PurchaseOrderElementQueriesService
    ],
    bootstrap: [
        AppComponent
    ]
})
export class AppModule {
  constructor() { }
}
