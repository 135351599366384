import { Injectable } from "@angular/core";
import { Finish, FinishProperty } from "./finish.base";
import { FINISHES_MAP } from "../use-class-map";
import { PaintingProperty } from "./painting";
import { ITicketElement } from "app/presentationnal/organisms/wizard-modal/service/step-form.service";

export type FinishAvailable =
    "Sandblasting" |
    "Metallization" |
    "Lacquering" |
    "Painting" |
    "Galvanization";

export type FinishBuild<T extends FinishAvailable> =
    T extends "Sandblasting" ? FinishProperty :
    T extends "Metallization" ? FinishProperty :
    T extends "Lacquering" ? FinishProperty :
    T extends "Painting" ? PaintingProperty :
    T extends "Galvanization" ? FinishProperty : never;

// Might be used later as we may store data in database for those finishes
const QUERY_MAP: Map<FinishAvailable, any> = new Map<FinishAvailable, any>([
    [ "Sandblasting", null ],
    [ "Metallization", null ],
    [ "Lacquering", null ],
    [ "Painting", null ],
    [ "Galvanization", null ]
]);

@Injectable()
export class FinishService {

    public constructor() {}

    public create<T extends FinishAvailable>(finish: FinishAvailable, name: string, ticket: ITicketElement[], properties: FinishBuild<T>) {
        const ctor: new (name: string, ticket: any, properties: any) => Finish<any> = FINISHES_MAP[finish];

        return new ctor(name, ticket, properties);
    }
}