import gql from "graphql-tag";

export const GET_SUPPLIER_CONTACTS = gql`
  query getSupplierContacts($supplierId: ID!){
    supplierContacts(supplierId: $supplierId){
      id
      firstname
      lastname
      phone
      mail
      function
      isFavorite
      language
    }
  }
`;

export const CREATE_SUPPLIER_CONTACT = gql`
  mutation createSupplierContact($data: SupplierContactInput!){
    createSupplierContact(data: $data){
      id
      firstname
      lastname
      phone
      mail
      function
      isFavorite
      language
    }
  }
`;

export const UPDATE_SUPPLIER_CONTACT = gql`
  mutation updateSupplierContact($id: ID!, $data: SupplierContactUpdate!){
    updateSupplierContact(id: $id, data: $data){
      id
      firstname
      lastname
      phone
      mail
      function
      isFavorite
      language
    }
  }
`;

export const SET_SUPPLIER_CONTACT_FAVORITE = gql`
  mutation setSupplierContactFavorite($id: ID!, $supplierId: ID!){
    setSupplierContactFavorite(id: $id, supplierId: $supplierId)
  }
`;

export const DELETE_SUPPLIER_CONTACT = gql`
  mutation deleteSupplierContact($id: ID!){
    deleteSupplierContact(id: $id)
  }
`;