import { Injectable } from "@angular/core";
import { IAmalgam, IAmalgamGroup, IAmalgamInput } from "../interfaces/price-request.interface";
import { BARSET_ITEM_SIZES_CONFIG, SUPPLY_CATEGORIE_IDS_FOR_TUBE, BARSET_ZOOM_CONFIG, BARSET_DEFAULT_ZOOM_SIZE, BARSET_SCALE_MAX_VALUE, BARSET_ITEM_SIZE_LABELS } from "../configs/barset-item-sizes.config";
import { IBarsetItem, IPresetSize, IBarsetScale, IBarsetZoom } from "../interfaces/barset.interface";
import { ReplaySubject } from "rxjs";
import { OPTION_LABELS } from "../configs/price-request.config";
import { IVariant } from "../interfaces/supplier-offer";
import { IPurchaseOrderElement } from "../interfaces/purchase-order.interface";

@Injectable({
  providedIn: "root"
})
export class AmalgamService {
  private _amalgamsData: IAmalgam[] = [];
  private _barsetItemsData: IBarsetItem[] = [];
  private _oldSavedBarsetData: IBarsetItem = null;
  private _zoomConfig: IBarsetZoom[] = BARSET_ZOOM_CONFIG;
  /* REPLAY SUBJECTS */
  private _resetBarset = new ReplaySubject<IBarsetItem>(1);
  private _asUpdateToSave = new ReplaySubject<boolean>(1);
  private _listenMaxBarsetScaleValue = new ReplaySubject<number>(1);
  private _setBarsetToLoading = new ReplaySubject<boolean>(1);

  constructor() { }

  public set amalgamsData(amalgams: IAmalgam[]) {
    this._amalgamsData = amalgams;
    this._barsetItemsData = this.formatAmalgamToPrint(this._amalgamsData);
  }

  public get amalgamsData(): IAmalgam[] {
    return this._amalgamsData;
  }

  /* Scale Methodes */
  public getZoomConfig(): IBarsetZoom {
    return this._zoomConfig.find( zoom => zoom.selected);
  }

  /**
   * @description Set / Update the zoom value
   * @author Lainez Eddy
   * @param {boolean} minus
   * @returns {IBarsetZoom}
   * @memberof AmalgamService
   */
  public setZoomConfig(minus: boolean): IBarsetZoom {
    const actualZoomIndex: number = this._zoomConfig.findIndex( zoom => zoom.selected),
          newIndex: number = minus ? actualZoomIndex - 1 : actualZoomIndex + 1;
    if ( this._zoomConfig[newIndex] ) {
      this._zoomConfig = this._zoomConfig.map( (zoom, index) => {
        return {
          ...zoom,
          selected: newIndex === index
        };
      });
    }
    return this.getZoomConfig();
  }

  /**
   * @description Reset the zoom to the default value
   * @author Lainez Eddy
   * @returns
   * @memberof AmalgamService
   */
  public resetZoomConfig() {
    this._zoomConfig = this._zoomConfig.map( zoom => {
      return {
        ...zoom,
        selected: zoom.size === BARSET_DEFAULT_ZOOM_SIZE
      };
    });
    return this.getZoomConfig();
  }


  /**
   * @description Check the max scale to print by looking in all the barsets size
   * @author Lainez Eddy
   * @param {IBarsetItem[]} barsetItems
   * @memberof AmalgamService
   */
  public checkMaxScaleValue(barsetItems: IBarsetItem[]) {
    if ( barsetItems.length > 0) {
      // const sizeScaleValue: number = barsetItems.map(barset => Math.ceil(barset.realSize / 1000))
      //   .reduce(( prev,  curr) => prev < curr ? curr : prev);
      const sizeScaleValue: number = Math.max(...barsetItems.map(barset => Math.ceil(barset.realSize / 1000)));
      this._listenMaxBarsetScaleValue.next(sizeScaleValue);
    } else {
      this._listenMaxBarsetScaleValue.next(null);
    }
  }

  /**
   * @description Return the scales of the barset component
   * @author Lainez Eddy
   * @param {number} [maxValue=BARSET_SCALE_MAX_VALUE]
   * @returns {IBarsetScale[]}
   * @memberof AmalgamService
   */
  public getScales( maxValue: number = BARSET_SCALE_MAX_VALUE): IBarsetScale[] {
    const zoomConfig: number = this.getZoomConfig().size;
    const scalesToReturn: IBarsetScale[] = [];
    let i = 1;
    while (maxValue >= i) {
      scalesToReturn.push({
       label: i + "m",
       size: this.getDisplaySize(1000, zoomConfig, `Scale ${i}`),
      });
      i++;
    }
    return scalesToReturn;
  }

  /**
   * @description Get Size in pourcent
   * @author Lainez Eddy
   * @param {number} size
   * @param {number} referenceSize
   * @param {string} referenceAlert
   * @returns {string}
   * @memberof AmalgamService
   */
  public getDisplaySize(size: number, referenceSize: number, referenceAlert: string): string {
    const calculatedSize: number = (+size / referenceSize) * 100;
    if (isNaN(calculatedSize)) {
      console.log("ERROR SIZE FOR ", referenceAlert);
    }
    return calculatedSize + "%";
  }

  /**
   * @description Get barset possible size by category
   * @author Lainez Eddy
   * @param {number} supplyCategoryId
   * @returns {IPresetSize[]}
   * @memberof AmalgamService
   */
  public getBarsetItemSize(supplyCategoryId: number): IPresetSize[] {
    if (SUPPLY_CATEGORIE_IDS_FOR_TUBE.some(id => +id === +supplyCategoryId)) {
      return BARSET_ITEM_SIZES_CONFIG.filter( size => size.tubeSize);
    } else {
      return BARSET_ITEM_SIZES_CONFIG;
    }
  }

  /**
   * @description Return the label of a the element's option
   * @author Lainez Eddy
   * @param {(IAmalgamGroup | IVariant | IPurchaseOrderElement)} amalgamGroup
   * @returns {string}
   * @memberof AmalgamService
   */
  public getOptionLabel(amalgamGroup: IAmalgamGroup | IVariant | IPurchaseOrderElement): string {
    if (amalgamGroup.isBlack) {
      return OPTION_LABELS.BLACK;
    } else if (amalgamGroup.isBlasted) {
      return OPTION_LABELS.BLASTED;
    } else if (amalgamGroup.isPrimaryBlasted) {
      return OPTION_LABELS.PRIMARY_BLASTED;
    } else {
      return "";
    }
  }

  /**
   * @description Format amalgams datas to display by checking the data & zoom of the scale
   * @author Lainez Eddy
   * @param {IAmalgam[]} amalgams
   * @returns {IBarsetItem[]}
   * @memberof AmalgamService
   */
  public formatAmalgamToPrint(amalgams: IAmalgam[]): IBarsetItem[] {
    amalgams.sort((a, b) => {
      if (a.amalgamGroup.reference !== b.amalgamGroup.reference) {
        return a.amalgamGroup.reference > b.amalgamGroup.reference ? 1 : -1 ;
      } else {
        return a.amalgamGroup.format > b.amalgamGroup.format ? 1 : -1 ;
      }
    });
    const zoomConfig: number = this.getZoomConfig().size;
    let amalgamGroup: IAmalgamGroup;
    let definedFormat: boolean;
    let partId: number = 1;
    return amalgams.map((amalgam, index) => {
      amalgamGroup = amalgam.amalgamGroup;
      definedFormat = BARSET_ITEM_SIZES_CONFIG.some( size => size.value === amalgamGroup.format );
      const barsetItem: IBarsetItem = {
        id: amalgam.id ? amalgam.id : index + 1,
        key: "#" + (index + 1),
        icon: amalgamGroup.icon,
        iconPath: `../../../../assets/img/catalog/${amalgamGroup.icon}_mini.jpg`,
        reference: amalgamGroup.reference,
        optionLabel: this.getOptionLabel(amalgamGroup),
        sizeLabel: amalgamGroup.format / 1000 + " M",
        loss: amalgam.loss / 1000,
        lossLabel: amalgam.loss + "mm",
        size: this.getDisplaySize(amalgamGroup.format, zoomConfig, amalgamGroup.reference),
        realSize: amalgamGroup.format,
        selectedSizeValue: definedFormat ? amalgamGroup.format : null,
        ...this.getSelectedLabel(amalgamGroup),
        sizeUsed: amalgamGroup.format - amalgam.loss,
        sizeUsedLabel: ((amalgamGroup.format - amalgam.loss) / 1000) + "m",
        sizeUsedPercent: Math.round(((amalgamGroup.format - amalgam.loss) / amalgamGroup.format) * 10000) / 100 + "%",
        isEn1090: amalgamGroup.isEn1090,
        isLocked: amalgam.isLocked,
        isInStock: amalgam.isInStock,
        stockPosition: amalgam.stockPosition,
        isCut: amalgamGroup.isCut,
        amalgamData: {
          reference: amalgamGroup.reference,
          matterId: amalgamGroup.matterId,
          matterRef: amalgamGroup.matterRef,
          isEn1090: amalgamGroup.isEn1090,
          isBlack: amalgamGroup.isBlack,
          isBlasted: amalgamGroup.isBlasted,
          isPrimaryBlasted: amalgamGroup.isPrimaryBlasted,
        },
        content: amalgam.parts.map( part => {
          return {
            id: partId++,
            supplyListElementId: part.supplyListElementId,
            reference: part.supplyListElement.supplyList.project.reference,
            description: part.supplyListElement.supplyList.description ? part.supplyListElement.supplyList.description : " - ",
            denomination: part.supplyListElement.denomination,
            format: part.supplyListElement.format + "mm",
            realSize: +part.supplyListElement.format,
            size: this.getDisplaySize(+part.supplyListElement.format, amalgamGroup.format, part.supplyListElement.denomination),
          };
        }),
        elementId: amalgamGroup.elementId,
        supplyCategoryId: amalgamGroup.supplyCategoryId,
        isEdited: false,
        isDisplay: true
      };
      // 100% - total of all "used" blocks
      barsetItem.lossSize = (100 - barsetItem.content.reduce((acc, curr) => acc + +curr.size.split("%")[0], 0)) + "%";

      return barsetItem;
    });
  }

  /**
   * @description Gets the correct label for an amalgamGroup. If manual, define the selected size as well
   * @author Quentin Wolfs
   * @private
   * @param {IAmalgamGroup} amalgamGroup
   * @returns {{ selectedSizeLabel: BARSET_ITEM_SIZE_LABELS, manualSizeValue?: number, manualSizeLabel?: string }}
   * @memberof AmalgamService
   */
  private getSelectedLabel(amalgamGroup: IAmalgamGroup)
  : { selectedSizeLabel: BARSET_ITEM_SIZE_LABELS, manualSizeValue?: number, manualSizeLabel?: string } {
    const standardSize = BARSET_ITEM_SIZES_CONFIG.find(size => size.value === amalgamGroup.format);
    if (standardSize) {
      return { selectedSizeLabel: standardSize.label };
    } else {
      return amalgamGroup.isManual ?
        {
          selectedSizeLabel: BARSET_ITEM_SIZE_LABELS.MANUAL,
          manualSizeValue: amalgamGroup.format,
          manualSizeLabel: amalgamGroup.format / 1000 + " M"
        } :
        { selectedSizeLabel: BARSET_ITEM_SIZE_LABELS.CUSTOM };
    }
  }

  /**
   * @description Format barset data to amalgamInput
   * @author Lainez Eddy
   * @param {IBarsetItem[]} barsetItems
   * @returns {IAmalgamInput[]}
   * @memberof AmalgamService
   */
  public formatBarsetToSave(barsetItems: IBarsetItem[]): IAmalgamInput[] {
    const amalgamInput: IAmalgamInput[] = barsetItems.map( barset => {
      return {
        id: barset.id,
        reference: barset.reference,
        format: barset.realSize,
        loss: barset.realSize - barset.sizeUsed,
        isEn1090: barset.isEn1090,
        isBlack: barset.amalgamData.isBlack,
        isBlasted: barset.amalgamData.isBlasted,
        isPrimaryBlasted: barset.amalgamData.isPrimaryBlasted,
        isCut: barset.isCut,
        isLocked: barset.isLocked,
        isInStock: barset.isInStock,
        isManual: barset.selectedSizeLabel === BARSET_ITEM_SIZE_LABELS.MANUAL,
        stockPosition: barset.stockPosition,
        icon: barset.icon,
        matterRef: barset.amalgamData.matterRef,
        matterId: barset.amalgamData.matterId,
        elementId: barset.elementId,
        supplyCategoryId: barset.supplyCategoryId,
        parts: barset.content.map( part => {
          return {
            supplyListElementId: part.supplyListElementId,
            length: part.realSize
          };
        })
      };
    });
    return amalgamInput;
  }

  /**
   * @description Get sizeUser by a barset & the selectedSizeValue (if in config)
   * @author Lainez Eddy
   * @param {IBarsetItem} barsetItem
   * @returns {{sizeUsed: number, selectedSizeValue: number}}
   * @memberof AmalgamService
   */
  public getAmalgamRealSizes(barsetItem: IBarsetItem): {sizeUsed: number, selectedSizeValue: number} {
    let sizeUsed: number = 0;
    barsetItem.content.forEach( content => sizeUsed += content.realSize);
    const barsetSizes: IPresetSize[] = this.getBarsetItemSize(barsetItem.supplyCategoryId);
    let checkSizeConfig: any = null;
    if (barsetSizes.find( barset => barset.value === barsetItem.realSize)) {
      checkSizeConfig = barsetSizes.find( size => size.value >= sizeUsed );
    }
    // let checkSizeConfig: any = barsetSizes.find( size => size.value >= sizeUsed );
    // const isCut: boolean = checkSizeConfig && (checkSizeConfig.value - sizeUsed > AMALGAM_MAX_FALL);
    // if (isCut) {
    //   checkSizeConfig = null;
    // }
    return {
      sizeUsed,
      selectedSizeValue: checkSizeConfig ? checkSizeConfig.value : null
    };
  }

  /**
   * @description Update amalgam size
   * @author Lainez Eddy
   * @param {number} value
   * @param {IBarsetItem} barsetItem
   * @memberof AmalgamService
   */
  public updateAmalgamSize(barsetItem: IBarsetItem, selectedSize: IPresetSize, manualSize?: number) {
    const newSize: number = manualSize ? manualSize : (selectedSize.value || barsetItem.sizeUsed);
    const lossValue: number = newSize - barsetItem.sizeUsed;
    const sizeUsed: number = newSize - lossValue;
    const zoomConfig: number = this.getZoomConfig().size;
    barsetItem.selectedSizeValue = selectedSize.value;
    barsetItem.selectedSizeLabel = selectedSize.label;
    barsetItem.manualSizeValue = manualSize || barsetItem.manualSizeValue;
    barsetItem.manualSizeLabel = barsetItem.manualSizeValue ? barsetItem.manualSizeValue / 1000 + " M" : null;
    barsetItem.isCut = selectedSize.label === BARSET_ITEM_SIZE_LABELS.CUSTOM;
    barsetItem.realSize = newSize;
    barsetItem.sizeLabel = newSize / 1000 + " M";
    barsetItem.loss = lossValue / 1000;
    barsetItem.lossLabel = lossValue + "mm";
    barsetItem.lossSize = this.getDisplaySize(+lossValue, newSize, "LOSS"),
    barsetItem.sizeUsedLabel = (sizeUsed / 1000) + "m",
    barsetItem.sizeUsedPercent = Math.round((sizeUsed / newSize) * 10000) / 100 + "%",
    barsetItem.size = this.getDisplaySize(newSize, zoomConfig, barsetItem.reference);
    barsetItem.content = barsetItem.content.map( part => {
      return {
        ...part,
        size : this.getDisplaySize(part.realSize, barsetItem.realSize, part.denomination)
      };
    });
  }

  /**
   * @description Get Amalgams to print in Barsets
   * @author Lainez Eddy
   * @returns {IBarsetItem[]}
   * @memberof AmalgamService
   */
  public getAmalgamsForBarset(): IBarsetItem[] {
    const zoomSize: number = this.getZoomConfig().size;
    this.setUpdateToSave(false);
    this._oldSavedBarsetData = null;
    return this._barsetItemsData.map( barsetItem => {
      return {
        ...barsetItem,
        size: this.getDisplaySize(barsetItem.realSize, zoomSize, barsetItem.reference),
        content: [...barsetItem.content]
      };
    });
  }

  public setUpdateToSave(value: boolean) {
    this._asUpdateToSave.next(value);
  }

  /**
   * @description Save temp Edited barset in case of cancel edit mode
   * @author Lainez Eddy
   * @param {IBarsetItem} barset
   * @memberof AmalgamService
   */
  public saveTempBarset(barset: IBarsetItem) {
    if (this._oldSavedBarsetData && (!barset || this._oldSavedBarsetData.id !== barset.id )) {
      this._resetBarset.next(this._oldSavedBarsetData);
    }
    this._oldSavedBarsetData = barset ? {...barset} : barset;
  }

  public get tempEditedBarset(): IBarsetItem {
    return this._oldSavedBarsetData;
  }

  /**
   * @description reset barset saved to null
   * @author Lainez Eddy
   * @memberof AmalgamService
   */
  public saveEditedBarset() {
    this._oldSavedBarsetData = null;
  }

  /* Subscriber */

  /**
   * @description Subscribe to reset the old barset edited and cancelled (not saved)
   * @author Lainez Eddy
   * @returns {ReplaySubject<IBarsetItem>}
   * @memberof AmalgamService
   */
  public getResetBarsetSubscriber(): ReplaySubject<IBarsetItem> {
    return this._resetBarset;
  }

  /**
   * @description Allow to subscribe on barset to know when we need to save any data (edit mode)
   * @author Lainez Eddy
   * @returns {ReplaySubject<boolean>}
   * @memberof AmalgamService
   */
  public getUpdateToSave(): ReplaySubject<boolean> {
    return this._asUpdateToSave;
  }

  public listenMaxBarsetScaleValue(): ReplaySubject<number> {
    return this._listenMaxBarsetScaleValue;
  }

  public setBarsetToLoad(value: boolean): void {
    this._setBarsetToLoading.next(value);
  }

  public setBarsetToLoading(): ReplaySubject<boolean> {
    return this._setBarsetToLoading;
  }

}
