import { Component, OnInit, Inject } from "@angular/core";
import { ModalContentForm } from "../../model/modal-content-form";
import { ModalService, DATA } from "../../services/modal.service";
import { SnackService } from "app/presentationnal/organisms/snack-bar/services/snack.service";
import { FormBuilder, Validators } from "@angular/forms";
import { UniqueSupplierOfferAdditionnalCostType, MultiSupplierOfferAdditionnalCostType, EnumAdditionnalCostUnit } from "app/facade/enums/supplier-offer-additionnal-cost-type.enum";
import { ISelectOption } from "app/presentationnal/atoms/inputs/select-input/selectOptions";
import { IPriceRequestAdditionnalCost } from "app/facade/interfaces/price-request.interface";

@Component({
  selector: "app-modal-inpdate-price-request-additionnal-cost",
  templateUrl: "./modal-inpdate-price-request-additionnal-cost.component.html",
  styleUrls: ["./modal-inpdate-price-request-additionnal-cost.component.css"]
})
export class ModalInpdatePriceRequestAdditionnalCostComponent extends ModalContentForm implements OnInit {
  private _additionnalCost: IPriceRequestAdditionnalCost;
  public additionnalCostUnitOptions: ISelectOption[] = [];

  constructor(protected _modalService: ModalService,
    protected _snackBar: SnackService,
    protected _fb: FormBuilder,
    @Inject(DATA) private _data: {data: {additionnalCost: IPriceRequestAdditionnalCost}}) {
      super(_modalService, _fb, _snackBar);
      this._additionnalCost = this._data.data.additionnalCost;
  }

  ngOnInit() {
    this.additionnalCostUnitOptions = Object.keys(EnumAdditionnalCostUnit).map( unit => {
      return {
        value: unit,
        label: EnumAdditionnalCostUnit[unit]
      };
    });
    this._formGroup = this._fb.group({
      quantity: [this._additionnalCost ? this._additionnalCost.quantity : null, Validators.required],
      type: [this._additionnalCost ? this._additionnalCost.type : null, Validators.required],
      denomination: [this._additionnalCost ? this._additionnalCost.denomination : null, Validators.required],
      unit: [this._additionnalCost ? this._additionnalCost.unit : null, Validators.required]
    });
  }

  public getAdditionCostTypeValue(type: string) {
    return UniqueSupplierOfferAdditionnalCostType[type] ? UniqueSupplierOfferAdditionnalCostType[type] : MultiSupplierOfferAdditionnalCostType[type];
  }

  protected save(): void {
    const objData = {
      confirm: true,
      data: {
        id: this._additionnalCost.id,
        additionnalCost: this._formGroup.value
      },
    };
    this.afterClosed.next(objData);
  }

}
