import { Component, OnInit, Input, Renderer2 } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { AInputs } from "../inputs";

@Component({
  selector: "app-textarea-input",
  templateUrl: "./textarea-input.component.html",
  styleUrls: ["./textarea-input.component.css"]
})
export class TextareaInputComponent extends AInputs implements OnInit {
  @Input("cols") public cols: number;
  @Input("rows") public rows: number;
  @Input("big") public big: boolean = false;
  @Input("line") public line: boolean = false;
  constructor(public renderer: Renderer2) {
    super(renderer);
  }

  ngOnInit() {
  }

}
