import { Assemblage } from "./assemblage.base";
import { Element } from "../elements/element.base";
import { AssemblageCreate } from "./assemblage.service";
import { concat, Subscription } from "rxjs";
import { Embase } from "../elements/customs/embase";

export interface IWeldiable extends Element {
    thickness: number;
    topLength?: number;
}

export interface IWeldiableMeterable extends IWeldiable {
    meterForWelding: number;
}

export interface WeldingDefinedProperty {
    PriceByM?: number;
}

// !!! ONLY USE FOR REDEFINITION
export interface WeldingProperty extends WeldingDefinedProperty {
    weldingPercentage?: number;
    passes?: number;
    weldingSection?: number;
    meterForWelding?: number;
}

export class Welding extends Assemblage<WeldingProperty, WeldingDefinedProperty> {
  private elementSubscription: Subscription;
    public constructor(
        private _element1: IWeldiableMeterable,
        private _element2: IWeldiable,
        assemblageCreate: AssemblageCreate<WeldingDefinedProperty>,
        weldingProperty?: WeldingProperty
    ) {
        super("Welding", assemblageCreate, weldingProperty);
        this.subscribeElements();
    }

    private resetAfterElementChanged() {
        delete this.properties.meterForWelding;
    }

    private subscribeElements() {
      if (this.elementSubscription) { this.elementSubscription.unsubscribe(); }
      this.elementSubscription = concat( this._element1.$updated, this._element2.$updated ).subscribe(update => {
          this.resetAfterElementChanged();
          this.create();
      });
    }

    public get Element1(): IWeldiableMeterable {
      return this._element1;
    }

    public set Element1( value: IWeldiableMeterable) {
      this._element1 = value;
      this.subscribeElements();
    }

    public get Element2(): IWeldiable {
      return this._element2;
    }

    public set Element2( value: IWeldiable) {
      this._element2 = value;
      this.subscribeElements();
    }

    public get Passes(): number {
        return this.properties.passes || this.computePasses();
    }

    public set Passes(value: number) {
        this.properties.passes = value;
    }

    public get WeldingSection(): number {
        return this.properties.weldingSection;
    }

    public set WeldingSection(value: number) {
        this.properties.weldingSection = value;
    }

    public get MeterForWelding(): number {
        return this.properties.meterForWelding || this.computeMeter();
    }

    public set MeterForWelding(value: number) {
        this.properties.meterForWelding = value;
    }

    public get PriceByM(): number {
        return this.properties.PriceByM || this.definedProperties.PriceByM;
    }

    public set PriceByM(value: number) {
        this.properties.PriceByM = value;
    }

    public get WeldingPercentage(): number {
        return this.properties.weldingPercentage != null ? this.properties.weldingPercentage : 100;
    }

    public set WeldingPercentage(value: number) {
        this.properties.weldingPercentage = value;
    }

    private computePasses(): number {
        let cap: number;
        if (this.WeldingSection) {
            cap = this.WeldingSection;
        } else {
            const thickness: number = Math.min(this._element1.thickness, this._element2.thickness);
            cap = Math.ceil(thickness / 2);
        }
        // 0 -> 5 : 1 passe, 5 -> 12 : 3 passes, 12 -> 20 : 6 passes
        return cap < 6 ? 1 : (cap >= 13 ? 6 : 3);
    }

    private computeMeter(): number {
        // CARE USE weldingProperty.meterForWelding if defined
        let meter: number;
        const embaseParent: any = this.Element1,
              embase: Embase = this.Element2 as Embase;
        if ( !this.properties.meterForWelding ) {
          if (embase.constructor.name === "Embase" && embase.usedAngle !== undefined && embaseParent.Angle1 != undefined) {
            if (embase.Quantity === 1) {
              const angle: string = embase.usedAngle.charAt(0).toUpperCase() + embase.usedAngle.slice(1);
              meter = embaseParent.meterForWeldingWithCut(embaseParent[angle]);
            } else {
              meter = embaseParent.meterForWeldingWithCut(embaseParent.Angle1);
              meter += embaseParent.meterForWeldingWithCut(embaseParent.Angle2);
              meter /= 2;
            }
          } else {
            meter = this._element1.meterForWelding;
          }
        } else {
          meter = this.properties.meterForWelding;
        }

        return Number((meter * (this.WeldingPercentage / 100)).toFixed(6));
    }

    public get CalculatedPrice(): number {
      return (this.Passes * this.computeMeter()) * this.PriceByM;
    }
}