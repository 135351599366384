import { Injectable } from "@angular/core";
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from "@angular/router";
import { Observable, of } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { ApplicationConfig } from "../../app/app.config";
import { map, switchMap, tap } from "rxjs/operators";
import { PermissionQueriesService } from "app/facade/queries/permission/permission.queries.service";
import { IPermission } from "app/facade/interfaces/permission.interface";

export const TOKEN_STORAGE_KEY = "token";
export const REFRESH_TOKEN_STORAGE_KEY = "token-refresh";
export const CSRF_STORAGE_KEY = "csrf";
export const TOKEN_COOKIE_NAME: string = "jwt";
export const REFRESH_TOKEN_COOKIE_NAME: string = "refreshjwt";
export const CSRF_HEADER_KEY: string = "csrf-authenticity";
export const AUTH_HEADER_KEY: string = "Authorization";
export const COOKIE_PATH: string = "/";
export const COOKIE_SECURE: boolean = false;

@Injectable()
export class AuthService implements CanActivate {


    private _dataTable: IPermission[] = [];
    public constructor(private _http: HttpClient,
        private _router: Router,
        private _permissionqueriesSrv: PermissionQueriesService) { }

    /**
    * @description Determine if we can access the asked route
    * @author Houtekiet Yves
    * @param {ActivatedRouteSnapshot} route
    * @param {RouterStateSnapshot} state
    * @returns {Observable<boolean>}
    * @memberof AuthService
    */
    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        if (!this.isLoggedIn()) {
            this._router.navigate(["/login"]);
            return of(false);
        }
        return of(true);
    }

    /**
    * @description Call the login endpoint back with the given username and password
    * @author Houtekiet Yves
    * @param {string} username
    * @param {string} password
    * @returns
    * @memberof AuthService
    */
  public login(username: string, password: string) {
    return this._http.post(ApplicationConfig.Url + "/login", { email: username, password }, { observe: "response" }).pipe(
      switchMap(response => {
        const dataResult: any = response.body;
        const headers: any = response.headers;
        localStorage.setItem("userGroup", dataResult.data.userGroup);
        localStorage.setItem("userData", JSON.stringify(dataResult.data));
        localStorage.setItem(TOKEN_STORAGE_KEY, headers.get(AUTH_HEADER_KEY).split(" ")[1]);
        localStorage.setItem(CSRF_STORAGE_KEY, headers.get(CSRF_HEADER_KEY));
      //Get permissin By properties usergroup and category
        return this._permissionqueriesSrv.getPermissionyUserGroup(dataResult.data.userGroup);
      }),
      tap((result) => {
        const resultData: { getPermission: IPermission[] } = <any>result.data;
        if (resultData.getPermission) {
          localStorage.setItem('dataPermission', JSON.stringify(resultData.getPermission));
        }
      })
    );
  }

    /**
    * @description get localStorage UserGroup
    * @author Marie Claudia
    * @returns
    * @memberof AuthService
    */
    public isUserGroup() {
        return localStorage.getItem('userGroup');
    }

    /**
    * @description Log out of the application by clearing local storage
    * @author Quentin Wolfs
    * @memberof AuthService
    */
    public logout() {
        // Clearing front-related informations
        localStorage.clear();
        this._router.navigate(["/login"]);
    }

    /**
    * @description Check if an auth token is stored in local storage. If so, the user is already logged in
    * @author Quentin Wolfs
    * @returns
    * @memberof AuthService
    */
    public isLoggedIn() {
        return !!localStorage.getItem(TOKEN_STORAGE_KEY);
    }

    /**
    * @description Get the auth token from the local storage
    * @author Quentin Wolfs
    * @returns
    * @memberof AuthService
    */
    public getToken() {
        return localStorage.getItem(TOKEN_STORAGE_KEY);
    }

    public setCookie(name: string, value: string, expires?: number | Date, path?: string, domain?: string, secure?: boolean) {
        let cookieString = encodeURIComponent(name) + "=" + encodeURIComponent(value) + ";";
        if (expires) {
            if (typeof expires === "number") {
                const dateExpires = new Date(new Date().getTime() + expires * 1000 * 60 * 60 * 24);
                cookieString += "expires=" + dateExpires.toUTCString() + ";";
            } else {
                cookieString += "expires=" + expires.toUTCString() + ";";
            }
        }
        if (path) { cookieString += "path=" + path + ";"; }
        if (domain) { cookieString += "domain=" + domain + ";"; }
        if (secure) { cookieString += "secure;"; }

        document.cookie = cookieString;
    }

    /**
    * @description Check if a httpOnly cookie is set with that name. WARNING : Will overwrite any non-httpOnly cookie. Use at your own risk !
    * @author Quentin Wolfs
    * @param {string} name
    * @memberof AuthService
    */
    private checkHttpOnlyCookie(name: string): boolean {
        // If already present in cookies, cannot be HttpOnly anyway
        if (document.cookie.indexOf(`${name}=`) != -1) { return false; }

        const dateExpires: Date = new Date(new Date().getTime() + 1000);
        const cookieString: string = `${encodeURIComponent(name)}=${encodeURIComponent("CHECK")};expires=${dateExpires.toUTCString()}; path=/`;
        document.cookie = cookieString;

        // If not present in cookies, it means that a httpOnly cookie was already set with that name
        return document.cookie.indexOf(`${name}=`) == -1;
    }
}
