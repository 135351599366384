import { Component, OnInit, Inject } from "@angular/core";
import { StepMatterBase } from "../../base/step-matter-base";
import { CONTAINER_DATA, StepFormService } from "app/presentationnal/organisms/wizard-modal/service/step-form.service";
import { CatalogQueriesService } from "app/facade/queries/catalog/async/catalog_queries.service";
import { CustomElementService } from "app/facade/quote-element-definition/elements/customs/custom.service";
import { FormBuilder, Validators } from "@angular/forms";
import { QuotesQueriesService } from "app/facade/queries/quote/async/quotes_queries.service";

@Component({
  selector: "app-equerre-step-one",
  templateUrl: "./equerre-step-one.component.html",
  styleUrls: ["./equerre-step-one.component.css"]
})
export class EquerreStepOneComponent extends StepMatterBase implements OnInit {

  constructor(@Inject(CONTAINER_DATA) data: any,
    protected _stepFormSrv: StepFormService,
    protected _catalogQueriesSrv: CatalogQueriesService,
    protected _customElementSrv: CustomElementService,
    protected _quoteQueriesSrv: QuotesQueriesService,
    protected _fb: FormBuilder) {
      super(data, _stepFormSrv, _catalogQueriesSrv, _customElementSrv, _quoteQueriesSrv, _fb);
  }

  protected generateFormContent() {
    const tempData: any = this.actualData.custom.properties;
    const form: any = super.generateFormContent();
    form.base = [tempData.base || null, Validators.required];
    return form;
  }

  public showCalcul() {
    this.stepMatterForm.valueChanges.subscribe(values => {
      const base = {
        width: values.width || null,
        base: values.base || null,
        height: values.height || null,
        thickness: values.thickness || null,
        matterPriceByKg: values.pricePerKg || null,
        matterKgByLiter: values.kgByLiter || 1
      };

      if (Object.keys(base).every(k => base[k])) {
        this.formulaDisplayer.base = base;
      }
    });
  }

}
