import { Injectable } from "@angular/core";
import { Apollo } from "apollo-angular";
import { GET_SUPPLY_CATEGORIES_BY_SUPPLIER, SET_SUPPLY_CATEGORIES_OF_SUPPLIER, GET_SUPPLY_CATEGORIES, GET_SUPPLY_CATEGORY_ELEMENT_GROUP, GET_LIGHT_SUPPLY_CATEGORIES, GET_SUPPLY_CATEGORIES_AND_ELEMENTS } from "./categories.queries";
import { ISupplierCategoriesInput } from "app/facade/interfaces/supplier";

@Injectable({
  providedIn: "root"
})
export class CategoriesQueriesService {

  constructor(private _apollo: Apollo) { }

  public getSupplierCategories(supplierId: number | string) {
    return this._apollo.query({
      query: GET_SUPPLY_CATEGORIES_BY_SUPPLIER,
      variables: { supplierId: supplierId },
      fetchPolicy: "network-only"
    });
  }

  public updateCategories(supplierId: number | string, data: ISupplierCategoriesInput[]) {
    return this._apollo.mutate({
      mutation: SET_SUPPLY_CATEGORIES_OF_SUPPLIER,
      variables: { supplierId, data }
    });
  }

  public getSupplyCategories(parentSupplyCategoryId?: number) {
    return this._apollo.query({
      query: GET_SUPPLY_CATEGORIES,
      variables: {parentSupplyCategoryId}
    });
  }

  public getSupplyCategoriesAndElements(parentSupplyCategoryId?: number) {
    return this._apollo.query({
      query: GET_SUPPLY_CATEGORIES_AND_ELEMENTS,
      variables: {parentSupplyCategoryId}
    });
  }

  public getLightSupplyCategories(parentSupplyCategoryId?: number) {
    return this._apollo.query({
      query: GET_LIGHT_SUPPLY_CATEGORIES,
      variables: {parentSupplyCategoryId}
    });
  }

  public getSupplyCategoryElementGroup(id: number) {
    return this._apollo.query({
      query: GET_SUPPLY_CATEGORY_ELEMENT_GROUP,
      variables: {id},
      fetchPolicy: "network-only"
    });
  }
}
