import { Injectable } from "@angular/core";
import { Element } from "../element.base";
import { ELEMENTS_MAP } from "../../use-class-map";
import { PoutrelleNatureValues, PoutrelleProperty } from "./beams/poutrelle";
import { ToleNatureValues, ToleProperty } from "./toles/tole";
import { TubeNatureValues, TubeProperty } from "./tubes/tube";
import { CornerValues } from "./beams/equal-corner";
import { IpnValues } from "./beams/ipn";
import { TShapeValues } from "./beams/t-beam";
import { UShapeValues } from "./beams/u-beam";
import { UpeValues } from "./beams/upe";
import { UpnValues } from "./beams/upn";
import { RoundTubeNatureValues } from "./tubes/round-tube";

type StandardAvailable =
    // Beams
    "Hea" | "Heb" | "Hem" | "Ipe" | "EqualCorner" | "Ipn" | "BeamTShape" | "BeamUShape" | "UnequalCorner" | "Upe" | "Upn" |
    // Toles
    "Tole" |
    // Tubes
    "RectangleTube" | "SquareTube" | "RoundTube";

// Minimal property to build asked standard
interface StandardBuild<T extends StandardAvailable> {
    values: StandardNatureValues<T>;
    properties: StandardProperty<T>;
    reference?: string;
}

type StandardNatureValues<T extends StandardAvailable> =
    // Beams
    T extends "Hea" ? PoutrelleNatureValues :
    T extends "Heb" ? PoutrelleNatureValues :
    T extends "Hem" ? PoutrelleNatureValues :
    T extends "Ipe" ? PoutrelleNatureValues :
    T extends "EqualCorner" ? CornerValues :
    T extends "Ipn" ? IpnValues :
    T extends "BeamTShape" ? TShapeValues :
    T extends "BeamUShape" ? UShapeValues :
    T extends "UnequalCorner" ? PoutrelleNatureValues :
    T extends "Upe" ? UpeValues :
    T extends "Upn" ? UpnValues :
    // Toles
    T extends "Tole" ? ToleNatureValues :
    // Beams
    T extends "RectangleTube" ? TubeNatureValues :
    T extends "SquareTube" ? TubeNatureValues :
    T extends "RoundTube" ? RoundTubeNatureValues : never;

type StandardProperty<T extends StandardAvailable> =
    // Beams
    T extends "Hea" ? PoutrelleProperty :
    T extends "Heb" ? PoutrelleProperty :
    T extends "Hem" ? PoutrelleProperty :
    T extends "Ipe" ? PoutrelleProperty :
    T extends "EqualCorner" ? PoutrelleProperty :
    T extends "Ipn" ? PoutrelleProperty :
    T extends "BeamTShape" ? PoutrelleProperty :
    T extends "BeamUShape" ? PoutrelleProperty :
    T extends "UnequalCorner" ? PoutrelleProperty :
    T extends "Upe" ? PoutrelleProperty :
    T extends "Upn" ? PoutrelleProperty :
    // Toles
    T extends "Tole" ? ToleProperty :
    // Beams
    T extends "RectangleTube" ? TubeProperty :
    T extends "SquareTube" ? TubeProperty :
    T extends "RoundTube" ? TubeProperty : never;

@Injectable()
export class StandardElementService {

    public constructor(
    ) {}

    public create<T extends StandardAvailable>(standard: StandardAvailable, name: string, build: StandardBuild<T>) {
        const ctor: new (name: string, reference: string, values: any, properties: any) => Element = ELEMENTS_MAP[standard];

        return new ctor(name, build.reference, build.values, build.properties);
    }
}