import gql from "graphql-tag";

export const ADD_ELEMENT_EXCEL = gql`mutation($element:InputExcelElement!){
    addElementWithExcel(element:$element){
      name
      elementNatureDefinitions{
        name
        type
        regex
        nullable
        redefine
      }
      elements{
        name
        natureValues
      }
    }
}`;