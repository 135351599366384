import { Component, OnInit, Inject, Renderer2, OnDestroy } from "@angular/core";
import { StepWeldingBase } from "../../base/step-welding-base";
import { CONTAINER_DATA, StepFormService } from "app/presentationnal/organisms/wizard-modal/service/step-form.service";
import { FormBuilder } from "@angular/forms";
import { Subscription } from "rxjs";

@Component({
  selector: "app-equerre-step-four",
  templateUrl: "./equerre-step-four.component.html",
  styleUrls: ["./equerre-step-four.component.css"]
})
export class EquerreStepFourComponent extends StepWeldingBase implements OnInit, OnDestroy {

  private _stickTopSubscription: Subscription;

  constructor(@Inject(CONTAINER_DATA) data: any,
              protected _stepFormSrv: StepFormService,
              protected renderer: Renderer2,
              protected _fb: FormBuilder) {
                super(data, _stepFormSrv, renderer, _fb);
              }

  ngOnInit() {
    super.ngOnInit();
    this._stickTopSubscription = this.stepWeldingForm.get("stickTop").valueChanges.subscribe(stickTop => {
      this.actualWeldingData._element1.properties.stickTop = stickTop;
      const meterForWeldingWithoutPercentage = this.actualWeldingData.MeterForWelding / (this.actualWeldingData.WeldingPercentage / 100);
      this.stepWeldingForm.get("MeterForWelding").patchValue(meterForWeldingWithoutPercentage);
    });

  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this._stickTopSubscription.unsubscribe();
  }

  protected generateFormContent(): any {
    const formContent = super.generateFormContent();
    formContent.stickTop = this.actualWeldingData._element1.properties.stickTop ? this.actualWeldingData._element1.properties.stickTop : null;
    return formContent;
  }

  public stepChange() {
    const isCustomProperties: string[] = [ "stickTop" ];
    Object.keys(this.stepWeldingForm.controls).forEach(key => {
      if (this.stepWeldingForm.get(key).touched && this.stepWeldingForm.get(key).valid) {
        if (isCustomProperties.indexOf(key) !== -1) {
          this.actualData.custom[key] = this.stepWeldingForm.get(key).value;
        } else {
          this.actualData.custom.AdditionalComputings.welding.origin[key] = this.stepWeldingForm.get(key).value;
        }
      }
    });

    this._stepFormSrv.change({ custom: this.actualData.custom });
  }

}
