import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { LabeledInputComponent, LoadMoreComponent, MaxTextComponent, TableHeadComponent, TableModeComponent, ButtonComponent, TabsComponent, BoxInfoComponent } from "./molecules";
import { LabelComponent, CheckboxInputComponent, SelectInputComponent, SelectSearchInputComponent, NumberInputComponent, TextareaInputComponent, TextInputComponent, PasswordInputComponent, IconCatalogComponent, EmptyQuoteSvgComponent, EmptyProjectComponent, FilterInputComponent, RadioInputComponent, FileInputComponent, SuggestInputComponent,FilePdfInputComponent} from "./atoms";
import { SharedDirectivesModule } from "../facade/directives/directives.modules";



const COMPONENTS = [
  // ATOMS
  EmptyProjectComponent,
  EmptyQuoteSvgComponent,
  IconCatalogComponent,
  CheckboxInputComponent,
  FilterInputComponent,
  NumberInputComponent,
  PasswordInputComponent,
  RadioInputComponent,
  SelectInputComponent,
  SelectSearchInputComponent,
  TextInputComponent,
  TextareaInputComponent,
  LabelComponent,
  SuggestInputComponent,
  // MOLECULES
  BoxInfoComponent,
  ButtonComponent,
  LabeledInputComponent,
  LoadMoreComponent,
  MaxTextComponent,
  TableHeadComponent,
  TableModeComponent,
  TabsComponent,
  FileInputComponent,
  FilePdfInputComponent
];

@NgModule({
  declarations: COMPONENTS,
  exports: [
    ...COMPONENTS,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedDirectivesModule
  ],
  imports : [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedDirectivesModule,
  ],
})
export class BaseModule { }
