import { Component, OnInit, EventEmitter, Output } from "@angular/core";

@Component({
  selector: "app-empty",
  templateUrl: "./empty.component.html",
  styleUrls: ["./empty.component.css"]
})
export class EmptyComponent implements OnInit {
  @Output() public addQuoteProjectEmpty: EventEmitter<string> = new EventEmitter();


  constructor() { }

  ngOnInit() {
  }
  /**
   * name
   */
  public addProjectEmpty() {
    this.addQuoteProjectEmpty.emit("true");
  }

}
