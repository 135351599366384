import { Tube, TubeNatureValues, TubeProperty } from "./tube";
import { MM_TO_M, calculateNewValueWithCuttinAngle } from "app/facade/quote-element-definition/base";
import { Matter } from "app/facade/interfaces";

export class SquareTube extends Tube {

    public constructor(
        name: string,
        reference: string,
        values: TubeNatureValues,
        properties: TubeProperty
    ) {
        super(name, reference, values, properties);
    }

    public get meterForWelding(): number {
        return (this.values.H * 4) * MM_TO_M;
    }

    public meterForWeldingWithCut(angle: number): number {
      const B_i = calculateNewValueWithCuttinAngle(this.values.H, angle);
      return ((this.values.H  * 2) + (B_i * 2)) * MM_TO_M;
    }

    public get Matter(): Matter {
        return this.properties.matter;
    }
}