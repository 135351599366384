import { Component, OnInit, Input, Renderer2, ElementRef } from "@angular/core";
import { IBarsetScale } from "app/facade/interfaces/barset.interface";

@Component({
  selector: "app-barset-scale",
  templateUrl: "./barset-scale.component.html",
  styleUrls: ["./barset-scale.component.css"]
})
export class BarsetScaleComponent implements OnInit {
  @Input() scales: IBarsetScale[];
  constructor(
    private _renderer: Renderer2,
    private _elementRef: ElementRef
  ) { }

  ngOnInit() {
    this._renderer.addClass(this._elementRef.nativeElement, "barset__scale");
    this._renderer.addClass(this._elementRef.nativeElement, "barset-scale");
  }

}
