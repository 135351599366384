import { Injectable } from "@angular/core";
import { Apollo } from "apollo-angular";
import { PING_BACK } from "./connection.queries";

@Injectable({
  providedIn: "root"
})
export class ConnectionQueriesService {

  constructor(private _apollo: Apollo) { }

  public pingBack() {
    return this._apollo.query({
      query: PING_BACK,
      fetchPolicy: "network-only"
    });
  }
}
