/*
 * @Author: Kevin Palade
 * @Date: 2018-09-11 14:10:29
 * @Last Modified by: Kevin Palade
 * @Last Modified time: 2018-11-19 15:57:12
 */
import { Component, Inject, OnInit, OnDestroy } from "@angular/core";
import { ModalService, DATA } from "../../services/modal.service";
import { FormBuilder, Validators } from "@angular/forms";
import { ModalContentForm } from "../../model/modal-content-form";
import { Subscription } from "rxjs";
import { QuotesProjectQueriesService } from "app/facade/queries/quote-project/async/quotes-project-queries.service";
import { SnackService } from "app/presentationnal/organisms/snack-bar/services/snack.service";
import { QuotesProject } from "app/facade/interfaces/quotesProject";

@Component({
  selector: "app-modal-add-quotes-project",
  templateUrl: "./modal-add-quotes-project.component.html",
  styleUrls: ["./modal-add-quotes-project.component.css"]
})
export class ModalAddQuotesProjectComponent extends ModalContentForm implements OnInit, OnDestroy {

  private _getProjectSub: Subscription;

  constructor(protected _modalService: ModalService,
              protected _snackBar: SnackService,
              protected _fb: FormBuilder,
              private _quotesProjectQueriesSrv: QuotesProjectQueriesService,
              @Inject(DATA) private _data) {
    super(_modalService, _fb, _snackBar);
  }

  ngOnInit() {
    this.initForm();

    if (this._data && this._data.data) {
      if (typeof this._data.data == "string") {
        this.setProject(this._data.data);
      } else {
        this.setFormValue(this._data.data.project);
      }
    }
  }

  private setFormValue(project: QuotesProject) {
    this._formGroup.patchValue({
      name: project && project.name ? project.name : null,
      reference: project && project.reference ? project.reference : null,
      customer: project && project.customer ? project.customer : null
    });
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    if (this._getProjectSub) { this._getProjectSub.unsubscribe(); }
  }

  /**
   * @description Preset the form with the project data (edit)
   * @author Kevin Palade
   * @private
   * @param {string} projectId
   * @memberof ModalAddQuotesProjectComponent
   */
  private setProject(projectId: string) {
    this._getProjectSub = this._quotesProjectQueriesSrv.getQuoteProject(projectId).subscribe(result => {
      const project = result.data["quoteProject"];
      this.setFormValue(project);
    });
  }

  /**
   * @description Initialize the formGroup
   * @author Kevin Palade
   * @private
   * @memberof ModalAddQuotesProjectComponent
   */
  private initForm() {
    this._formGroup = this._fb.group({
      name: [null, Validators.required],
      reference: [null, Validators.required],
      customer: [null, Validators.required]
    });
  }

  /**
   * @description Emit the data on save click
   * @author Kevin Palade
   * @protected
   * @memberof ModalAddQuotesProjectComponent
   */
  protected save(): void {
    const objData = {
      confirm: true,
      data: this._formGroup.value
    };
    this.afterClosed.next(objData);
  }
}
