import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

export class BreadcrumbItem {
    name: string;
    category?: any;
}

@Injectable()
export class BreadcrumbService {

    private _items = []; // Contain the breadcrumb item
    public itemChanged: Subject<any> = new Subject(); // Used by catalogService

    // Getters
    public get items() { return this._items; }

    constructor() { }

    /**
     * @description Add an item to the breadcrumb
     * @author Kevin Palade
     * @param {*} item
     * @memberof BreadcrumbService
     */
    public addItem(item: any) {
        this._items.unshift(item);
    }

    /**
     * @description Emit an event with the clicked item
     * @author Kevin Palade
     * @param {Event} event
     * @param {BreadcrumbItem} item
     * @memberof BreadcrumbService
     */
    public changeItem(event: Event, item: BreadcrumbItem) {
        if (event) {
            event.preventDefault();
        }
        this._items = this._items.slice(this._items.indexOf(item), this._items.length);
        this.itemChanged.next(item);
    }

    /**
     * @description Clear the breadcrumb
     * @author Kevin Palade
     * @memberof BreadcrumbService
     */
    public clear() {
        this._items = [];
    }



}