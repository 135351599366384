import gql from "graphql-tag";

// REFERENCE
export const GET_PURCHASE_ORDER_REFERENCE = gql`
  query getPurchaseOrderReference {
    getPurchaseOrderReference
  }
`;

export const FREE_PURCHASE_ORDER_REFERENCE = gql`
  mutation freePurchaseOrderReference {
    freePurchaseOrderReference
  }
`;

// Purchase Order
export const PURCHASE_ORDER = gql`
  query purchaseOrder($id: ID!){
    purchaseOrder(id: $id){
      id
      reference
      status
      remark
      internalRemark
      projectId
      project {
        id
        reference
      }
      supplierId
      supplier {
        id
        code
        name
        mail
        parentSupplyCategories {
          id
          name
        }
      }
      supplierContactId
      supplierContact {
        id
        firstname
        lastname
        mail
      }
      priceRequestId
      priceRequest {
        reference
      }
      user {
        firstname
        lastname
      }
      createdAt
      additionnalCosts {
        id
        type
        denomination
        quantity
        price
        unit
      }
      elements {
        id
        quantity
        denomination
        isEn1090
        isBlack
        isBlasted
        isPrimaryBlasted
        format
        weight
        matterRef
        quantityUnit
        thickness
        length
        width
        remark
        deliveryDate
        price
        unit
        isPrinted
        printedQuantity
        supplierOfferElementId
        supplyCategoryId
        supplyCategory {
          id
          parentSupplyCategoryId
        }
        options{
          id
          type
          denomination
          quantity
          price
          unit
        }
      }
      linkedProjects {
        id
        reference
      }
      totalPrice
      totalAdditionnalCosts
      scanPdfs{
        id
        name
        url
        comment
        createdAt
      }
    }
  }
`;

export const SINGLE_PURCHASE_ORDER = gql`
  query purchaseOrder($id: ID!){
    purchaseOrder(id: $id){
      id
      reference
      status
      remark
      internalRemark
      project {
        id
        reference
      }
      supplier {
        id
        name
      }
      priceRequest {
        reference
      }
      user {
        firstname
        lastname
      }
      createdAt
      linkedProjects {
        id
        reference
      }
      scanPdfs{
        id
        name
        url
        comment
        createdAt
      }
    }
  }
`;

export const PURCHASE_ORDERS = gql`
  query purchaseOrders($search: String, $sort: PurchaseOrderSort, $pagination: Pagination){
    purchaseOrders(search: $search, sort: $sort, pagination: $pagination){
      id
      reference
      status
      remark
      internalRemark
      project {
        id
        reference
      }
      supplier {
        id
        name
      }
      priceRequest {
        reference
      }
      user {
        firstname
        lastname
      }
      createdAt
      linkedProjects {
        id
        reference
      }
      scanPdfs{
        id
        name
        url
        comment
        createdAt
      }
    }
  }
`;

export const PURCHASE_ORDERS_BY_PROJECT = gql`
  query purchaseOrdersByProject($projectId: ID!, $pagination: Pagination) {
    purchaseOrdersByProject(projectId: $projectId, pagination: $pagination){
      id
      reference
      status
      remark
      internalRemark
      project {
        id
        reference
      }
      supplier {
        id
        name
      }
      priceRequest {
        reference
      }
      user {
        firstname
        lastname
      }
      createdAt
      linkedProjects {
        id
        reference
      }
    }
  }
`;

// Purchase Order Element
export const PURCHASE_ORDER_ELEMENT = gql`
  query purchaseOrderElement($id: ID!){
    purchaseOrderElement(id: $id){
      id
      quantity
      denomination
    }
  }
`;

export const PURCHASE_ORDER_ELEMENTS = gql`
  query purchaseOrderElements($filter: PurchaseOrderElementFilter!){
    purchaseOrderElements(filter: $filter){
      id
      quantity
      denomination
    }
  }
`;

export const CREATE_PURCHASE_ORDER_ELEMENT = gql`
  mutation createPurchaseOrderElement($data: PurchaseOrderElementInput!){
    createPurchaseOrderElement(data: $data){
      id
      quantity
      denomination
      quantity
      quantityUnit
      denomination
      remark
      price
      unit
      purchaseOrderId
      projectId
      supplierOfferElementId
      supplyCategoryId
    }
  }
`;

export const UPDATE_PURCHASE_ORDER_ELEMENT = gql`
  mutation updatePurchaseOrderElement($id: ID!, $data: PurchaseOrderElementUpdate!){
    updatePurchaseOrderElement(id: $id, data: $data){
      id
      quantity
      denomination
    }
  }
`;

export const UPDATE_PURCHASE_ORDER_ELEMENT_REMARK = gql`
  mutation updatePurchaseOrderElementRemark($id: ID!, $data: PurchaseOrderElementUpdateRemark!){
    updatePurchaseOrderElementRemark(id: $id, data: $data){
      id
      remark
    }
  }
`;

export const UPDATE_PURCHASE_ORDER_ELEMENT_FORMAT = gql`
  mutation updatePurchaseOrderElementFormat($id: ID!, $data: PurchaseOrderElementUpdateFormat!){
    updatePurchaseOrderElementFormat(id: $id, data: $data){
      id
      format
    }
  }
`;

export const UPDATE_PURCHASE_ORDER_ELEMENT_QUANTITY = gql`
    mutation updatePurchaseOrderElementQuantity($id: ID!, $data: PurchaseOrderElementUpdateQuantity!){
      updatePurchaseOrderElementQuantity(id: $id, data: $data){
        id
        quantity
      }
    }
`;

export const UPDATE_PURCHASE_ORDER_ELEMENT_DENOMINATION = gql`
  mutation updatePurchaseOrderElementDenomination($id: ID!, $data: PurchaseOrderElementUpdateDenomination!){
    updatePurchaseOrderElementDenomination(id: $id, data: $data){
      id
      denomination
    }
  }
`;

export const UPDATE_PURCHASE_ORDER_ELEMENT_MATIERE = gql`
  mutation updatePurchaseOrderElementMatiere($id: ID!, $data: PurchaseOrderElementUpdateMatiere!){
    updatePurchaseOrderElementMatiere(id: $id, data: $data){
      id
      matterRef
    }
  }
`;

export const UPDATE_PURCHASE_ORDER_ELEMENT_WEIGHT = gql`
  mutation updatePurchaseOrderElementWeight($id: ID!, $data: PurchaseOrderElementUpdateWeight!){
    updatePurchaseOrderElementWeight(id: $id, data: $data){
      id
      weight
    }
  }
`;

export const UPDATE_PURCHASE_ORDER_ELEMENT_WIDTH = gql`
  mutation updatePurchaseOrderElementWidth($id: ID!, $data: PurchaseOrderElementUpdateWidth!){
    updatePurchaseOrderElementWidth(id: $id, data: $data){
      id
      width
    }
  }
`;

export const UPDATE_PURCHASE_ORDER_ELEMENT_LENGTH = gql`
  mutation updatePurchaseOrderElementLength($id: ID!, $data: PurchaseOrderElementUpdateLength!){
    updatePurchaseOrderElementLength(id: $id, data: $data){
      id
      length
    }
  }
`;

export const UPDATE_PURCHASE_ORDER_ELEMENT_THICKNESS = gql`
  mutation updatePurchaseOrderElementThickness($id: ID!, $data: PurchaseOrderElementUpdateThickness!){
    updatePurchaseOrderElementThickness(id: $id, data: $data){
      id
      thickness
    }
  }
`;

export const UPDATE_PURCHASE_ORDER_ELEMENT_PRICE = gql`
  mutation updatePurchaseOrderElementPrice($id: ID!, $data: PurchaseOrderElementUpdatePrice!){
    updatePurchaseOrderElementPrice(id: $id, data: $data){
      id
      price
    }
  }
`;

export const UPDATE_PURCHASE_ORDER_ELEMENT_DELIVERY_DATE = gql`
  mutation updatePurchaseOrderElementDeliveryDate($id: ID!, $data: PurchaseOrderElementUpdateDeliveryDate!){
    updatePurchaseOrderElementDeliveryDate(id: $id, data: $data){
      id
      deliveryDate
    }
  }
`;

export const UPDATE_PURCHASE_ORDER_ELEMENT_EN1090 = gql`
  mutation updatePurchaseOrderElementEn1090($id: ID!, $data: PurchaseOrderElementUpdateEn1090!){
    updatePurchaseOrderElementEn1090(id: $id, data: $data){
      id
      isEn1090
    }
  }
`;

export const UPDATE_PURCHASE_ORDER_ELEMENT_OPTION = gql`
  mutation updatePurchaseOrderElementOption($id: ID!, $data: PurchaseOrderElementUpdateOption!){
    updatePurchaseOrderElementOption(id: $id, data: $data){
      id
      option
    }
  }
`;

export const UPDATE_PURCHASE_ORDER_ELEMENT_QUANTITY_UNIT = gql`
  mutation updatePurchaseOrderElementQuantityUnit($id: ID!, $data: PurchaseOrderElementUpdateQuantityUnit!){
    updatePurchaseOrderElementQuantityUnit(id: $id, data: $data){
      id
      quantityUnit
    }
  }
`;

export const DELETE_PURCHASE_ORDER_ELEMENT = gql`
  mutation deletePurchaseOrderElement($id: ID!){
    deletePurchaseOrderElement(id: $id)
  }
`;

// Purchase Order Additionnal Costs
export const CREATE_PURCHASE_ORDER_ADDITIONNAL_COST = gql`
  mutation createPurchaseOrderAdditionnalCost($data: PurchaseOrderAdditionnalCostInput!){
    createPurchaseOrderAdditionnalCost(data: $data){
      id
    }
  }
`;

export const UPDATE_PURCHASE_ORDER_ADDITIONNAL_COST = gql`
  mutation updatePurchaseOrderAdditionnalCost($id: ID!, $data: PurchaseOrderAdditionnalCostUpdate!){
    updatePurchaseOrderAdditionnalCost(id: $id, data: $data){
      id
    }
  }
`;

export const DELETE_PURCHASE_ORDER_ADDITIONNAL_COST = gql`
  mutation deletePurchaseOrderAdditionnalCost($id: ID!){
    deletePurchaseOrderAdditionnalCost(id: $id)
  }
`;

// CREATE AND UPDATE Purchase Order
export const CREATE_PURCHASE_ORDER_FROM_PROJECT = gql`
  mutation createPurchaseOrderFromProject($data: PurchaseOrderFromProjectInput!,$scanData: [AddScanPdfInput!]){
    createPurchaseOrderFromProject(data: $data,scanData:$scanData){
      id
      reference
      status
    }
  }
`;

export const CREATE_PURCHASE_ORDER_FROM_SUPPLIER_OFFER = gql`
  mutation createPurchaseOrderFromSupplierOffer($data: [PurchaseOrderFromSupplierOfferInput!]!) {
    createPurchaseOrderFromSupplierOffer(data: $data){
      id
      reference
      status
      supplier {
        id
        name
      }
      priceRequest {
        reference
      }
      createdAt
    }
  }
`;

export const UPDATE_PURCHASE_ORDER = gql`
  mutation updatePurchaseOrder($id: ID!, $data: PurchaseOrderUpdate!){
    updatePurchaseOrder(id: $id, data: $data){
      id
      reference
      status
      remark
      internalRemark
      projectId
      project {
        id
        reference
      }
      supplierId
      supplier {
        id
        code
        name
        mail
        parentSupplyCategories {
          id
          name
        }
      }
      supplierContactId
      supplierContact {
        id
        firstname
        lastname
        mail
      }
      priceRequestId
      priceRequest {
        reference
      }
      user {
        firstname
        lastname
      }
      createdAt
      linkedProjects {
        id
        reference
      }
    }
  }
`;

export const DELETE_PURCHASE_ORDER = gql`
  mutation deletePurchaseOrder($id: ID!){
    deletePurchaseOrder(id: $id)
  }
`;

// Other
export const SEND_PURCHASE_ORDER_MAIL = gql`
  mutation sendPurchaseOrderMail($id: ID!){
    sendPurchaseOrderMail(id: $id)
  }
`;
