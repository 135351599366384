import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { ADD_ADMISSION, GET_ADMISSION } from './admission-queries.service';

@Injectable({
  providedIn: 'root'
})
export class AdmissionQueriesService {
  constructor(private _apollo: Apollo) { }
  //add admission purchase order element
  public addAdmission(data) {
    return this._apollo.mutate({
        mutation: ADD_ADMISSION ,
        variables : {
          data: data
        }
    });
  }

  //get admission where idElement = id
  public getAdmission(id) {
    return this._apollo.query({
        query: GET_ADMISSION ,
        variables : {
          id
        }
    });
  }

}
