import { Component, OnInit, Inject } from "@angular/core";
import { ModalContentForm } from "../../model/modal-content-form";
import { ModalService, DATA } from "../../services/modal.service";
import { SnackService } from "app/presentationnal/organisms/snack-bar/services/snack.service";
import { FormBuilder } from "@angular/forms";
import { IPurchaseOrder, ICustomPurchaseOrder } from "app/facade/interfaces/purchase-order.interface";
import { TableDatasDefinition } from "app/facade/interfaces/table-datas-definition";
import { purchaseOrderStatusConfig } from "app/facade/configs/status.config";
import * as moment from "moment";
import { ApplicationConfig } from "app/app.config";

@Component({
  selector: "app-modal-consult-created-purchase-order",
  templateUrl: "./modal-consult-created-purchase-order.component.html",
  styleUrls: ["./modal-consult-created-purchase-order.component.css"]
})
export class ModalConsultCreatedPurchaseOrderComponent extends ModalContentForm implements OnInit {
  private _purchaseOrders: IPurchaseOrder[];
  public purchaseOrderListDatas: {
    tableHeaders: string[];
    tableDatasDefinition: TableDatasDefinition[];
    tableDatas: ICustomPurchaseOrder[]
  };

  constructor(protected _modalService: ModalService,
    protected _snackBar: SnackService,
    protected _fb: FormBuilder,
    @Inject(DATA) private _data: {data: {purchaseOrders: IPurchaseOrder[]}}) {
      super(_modalService, _fb, _snackBar);
      this._purchaseOrders = this._data.data.purchaseOrders;
  }

  ngOnInit() {
    this.purchaseOrderListDatas = {
      tableHeaders: ["Référence", "Fournisseur", "Date de création", "Demande de prix", "Status"],
      tableDatas: [],
      tableDatasDefinition: [
        { key: "reference", isTitle: true, clickAction: this.goToPurchaseOrderDetail},
        { key: "supplier", isTitle: false},
        { key: "createdAt", isTitle: false},
        { key: "priceRequest", isTitle: false},
        { key: "status", isTitle: false, isStatus: true, statusConfig: purchaseOrderStatusConfig}
      ]
    };
    this.purchaseOrderListDatas.tableDatas = this._purchaseOrders.map( purchaseOrder => {
      return {
        id: purchaseOrder.id,
        reference: purchaseOrder.reference,
        supplier: purchaseOrder.supplier ? purchaseOrder.supplier.name : null,
        createdAt: moment.unix(+purchaseOrder.createdAt).format("DD/MM/YYYY HH:mm"),
        project: null, // TODO
        priceRequest: purchaseOrder.priceRequest ? purchaseOrder.priceRequest.reference : null,
        status: purchaseOrder.status
      };
    });
  }

  private goToPurchaseOrderDetail = (purchaseOrder: ICustomPurchaseOrder) => {
    window.open(`${ApplicationConfig.getDomain()}/purchase-order/edit/${purchaseOrder.id}`);
  }

  public confirmModal() {
    this.cancelModal();
  }

  // Not use
  protected save(): void {
    const objData = {
      confirm: true,
    };
    this.afterClosed.next(objData);
  }

}
