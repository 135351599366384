import { Matter } from "app/facade/interfaces";
import { Element } from "../../element.base";
import { MM_TO_M, SQUARE_MM_TO_SQUARE_M, SQUARE_MM_TO_SQUARE_CM } from "app/facade/quote-element-definition/base";
import { IWeldiableMeterable } from "app/facade/quote-element-definition/assemblages/welding";
import { IDrillThickness } from "app/facade/quote-element-definition/operations/drilling";

export interface TubeNatureValues {
    H: number;
    B: number;
    A_L: number;
    A: number;
    Kg_m: number;
    T_p: number;
    R_1: number;
}

export interface TubeProperty {
    length: number;
    matter: Matter;
    angle1?: number;
    angle2?: number;
}

export abstract class Tube extends Element implements IWeldiableMeterable, IDrillThickness {

    public constructor(name: string,
                       reference: string,
                       protected values: TubeNatureValues,
                       protected properties: TubeProperty) {
        super(name, reference);
    }

    public set Properties(properties: TubeProperty) {
        this.properties = { ...properties };
        this.elementUpdated();
    }

    public set Values(values: TubeNatureValues) {
        this.values = { ...values };
        this.elementUpdated();
    }

    public get CalculatedPrice(): number {
        // length is in mm
        return (this.properties.length * MM_TO_M) * this.values.Kg_m * this.properties.matter.pricePerKg;
    }

    public abstract get meterForWelding(): number;

    public get thickness(): number {
        return this.values.T_p;
    }

    public get drillThickness(): number {
        return this.values.T_p;
    }

    public get section(): number {
        return this.values.A * SQUARE_MM_TO_SQUARE_CM;
    }

    public get TotalArea(): number {
        return (this.values.A_L * SQUARE_MM_TO_SQUARE_M) * (this.properties.length * MM_TO_M);
    }

    public get TotalWeight(): number {
        return this.values.Kg_m * (this.properties.length * MM_TO_M);
    }

    public get Angle1(): number {
      return this.properties.angle1;
    }

    public set Angle1(value: number) {
      if (value === null) {
        delete this.properties.angle1;
      } else {
        this.properties.angle1 = value;
      }
    }

    public get Angle2(): number {
      return this.properties.angle2 || 90;
    }

    public set Angle2(value: number) {
      if (value === null) {
        delete this.properties.angle2;
      } else {
        this.properties.angle2 = value;
      }
    }
}