/*
 * @Author: Kevin Palade
 * @Date: 2018-09-08 14:32:38
 * @Last Modified by: Kevin Palade
 * @Last Modified time: 2018-09-10 17:46:37
 */
import { Component, Input, OnChanges, OnInit } from "@angular/core";
import { AuthService } from "@lib/auth/auth.service";
import { PermissionService } from "app/facade/services/permission.service";

@Component({
  selector: "app-button",
  templateUrl: "./button.component.html",
  styleUrls: ["./button.component.css"]
})
export class ButtonComponent implements OnChanges,OnInit{

  @Input("tabIndex") public tabIndex: number = 0;
  @Input("full") public full: boolean;
  @Input("disabled") public disabled: boolean;
  @Input("link") public link: boolean;
  @Input("hollow") public hollow: boolean;
  @Input("noMargin") public noMargin: boolean = false;
  @Input("color") public color: string;
  @Input("shadow") public shadow: string;
  @Input("size") public size: string;
  @Input("icon") public icon: string;
  @Input("isIcon") public isIcon: boolean;
  @Input ("hidden") public isScanpdf: string;
  @Input() public additionnalClass: string = "";
  public _generatedClass: string;
  constructor() { }

  ngOnInit(): void {
  }

  /**
   * @description Generate the class based on the input
   * @author Kevin Palade
   * @memberof ButtonComponent
   */
  ngOnChanges() {
    this._generatedClass = this.additionnalClass;
    this._generatedClass += this.color && this.color !== "" ? " button_color_" + this.color : "";
    this._generatedClass += this.shadow ? " button_" + this.shadow : "";
    this._generatedClass += this.size ? " button_size_" + this.size : "";
  }

}
