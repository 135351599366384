import { Component, OnInit, Renderer2, ElementRef, Input, Output, EventEmitter, OnDestroy, SimpleChanges, OnChanges } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { Subscription } from "rxjs";

@Component({
  selector: 'app-file-pdf-input',
  templateUrl: './file-pdf-input.component.html',
  styleUrls: ['./file-pdf-input.component.css']
})
export class FilePdfInputComponent implements OnInit , OnChanges, OnDestroy {
  private _formGroupSub: Subscription;
  @Input() public textContent: string = "Glissez ici un fichier PDF";
  @Input() public acceptData: string = ".pdf";
  @Input() public name: string;
  @Input() public id: string;
  @Input() public group: FormGroup;
  @Input() public disable: boolean = false;
  @Output() public onFileChange: EventEmitter<File []> = new EventEmitter<File []>();
  //@Output() public onFileChange: EventEmitter<File> = new EventEmitter<File>();
  private _fileValue: File [] = [];
  //private _fileValue: File;
  public fileName: string;

  constructor(
    private _renderer: Renderer2,
    private _elementRef: ElementRef) {
      this._renderer.addClass(this._elementRef.nativeElement, "upload-files");
  }

  ngOnInit() {
    this.fileName = this.group.get(this.name).value;
    this._formGroupSub = this.group.valueChanges.subscribe( result => {
      this.fileName = result.fileValue;
      this._renderer.addClass(this._elementRef.nativeElement, "upload-files_success");
    });
    if (this.disable) {
      this._renderer.addClass(this._elementRef.nativeElement, "upload-files_disabled");
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.disable) {
      if (changes.disable.currentValue) {
        this._renderer.addClass(this._elementRef.nativeElement, "upload-files_disabled");
      } else {
        this._renderer.removeClass(this._elementRef.nativeElement, "upload-files_disabled");
      }
    }
  }

  ngOnDestroy(): void {
    this._formGroupSub.unsubscribe();
  }

  public changeInputFileValue(fileChangeEvent: Event) {
    const input = <HTMLInputElement>fileChangeEvent.target;
    //this._fileValue = input.files[0];

    for (var i=0; i<input.files.length; i++){
      this._fileValue.push(input.files[i]);
      // console.log("this._fileValue",input.files[i]);
    }


    if (this._fileValue) {
      this.onFileChange.emit(this._fileValue);
    }
  }

}
