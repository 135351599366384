import { Component, OnInit, Input, Output, EventEmitter, Renderer2 } from "@angular/core";
import { IMultiTableGroup } from "app/facade/interfaces/multi-table-group.interface";
import { EnumSupplyTableLinkedSize } from "app/facade/enums/supply-table-linked-size.enum";
import { Router } from '@angular/router';

@Component({
  selector: "app-supply-table",
  templateUrl: "./supply-table.component.html",
  styleUrls: ["./supply-table.component.css"]
})
export class SupplyTableComponent implements OnInit {
  @Input() isLargeTable: boolean = false;
  @Input() multiTable: IMultiTableGroup;
  @Input() hasMultiLinked: boolean = true;
  @Input() mainContentHeight: string = "45vh";
  @Input() linkedTableSize: EnumSupplyTableLinkedSize = EnumSupplyTableLinkedSize.four;
  @Input() selectedElementIds: number[] = [];
  public scrollTopPosition: number = 0;
  @Output() onEncodeData: EventEmitter<number> = new EventEmitter<number>();
  @Output() onUpdateAdditionnalData: EventEmitter<{elementIds?: number[], elementId?: number, additionnalData?: any}> = new EventEmitter<{elementId: number, additionnalData: any}>();
  @Output() onDeleteAdditionnalData: EventEmitter<number> = new EventEmitter<number>();
  @Output() onActionAdditionnalCost: EventEmitter<{datas: any, type: string}> = new EventEmitter<{datas: any, type: string}>();
  @Output() onUpdateSupplierElementsList: EventEmitter<number> = new EventEmitter<number>();
  public internalRemark = "Pas de remarque interne";

  constructor(
    private renderer: Renderer2,
    private _router: Router
  ) { }

  ngOnInit() {
    let page = this._router.url.split('/')[1];
    
    if (page == "price-request") {
      if (sessionStorage.getItem('priceRequestInternalRemark')) {
        let data = JSON.parse(sessionStorage.getItem('priceRequestInternalRemark'));
        if (data["internalRemark"]) {
          this.internalRemark = data["internalRemark"]
        }
        
      }
    }

    if (page == "purchase-order") {
      if (sessionStorage.getItem('purchaseOrderInternalRemark')) {
        let data = JSON.parse(sessionStorage.getItem('purchaseOrderInternalRemark'));
        if (data["internalRemark"]) {
          this.internalRemark = data["internalRemark"]
        }
      }
    }
    
    
    
  }

  public encodeData(linkedId: number) {
    let linkedIdToEncode: number;
    if (this.hasMultiLinked) {
      linkedIdToEncode = linkedId;
    } else {
      linkedIdToEncode = this.multiTable.linkedData[0] ? this.multiTable.linkedData[0].id : null;
    }
    if (linkedIdToEncode) {
      this.onEncodeData.emit(linkedIdToEncode);
    }
  }

  public mainTableScroll(data: any) {
    this.scrollTopPosition = data.target.scrollTop;
  }

  public deleteAdditionnalData(additionnalData: any) {
    this.onDeleteAdditionnalData.emit(additionnalData.id);
  }

  public addAdditionnalData() {
    this.onUpdateAdditionnalData.emit({elementIds: this.selectedElementIds});
  }

  public selectElement(elementData: any) {
    if (!elementData.isAddedLine && this.hasMultiLinked && !elementData.isAdditionnalCost) {
      const idIndex: number = this.selectedElementIds.indexOf(elementData.id);
      if (idIndex === -1) {
        this.selectedElementIds.push(elementData.id);
      } else {
        this.selectedElementIds.splice(idIndex, 1);
      }
    }
  }

  public resetSelectedElements() {
    this.selectedElementIds = [];
  }

  public updateAdditionnalData(additionnalData: any, elementData: any) {
    this.onUpdateAdditionnalData.emit({elementId: elementData.id, additionnalData});
  }

  public updateAdditionnalCost(datas: any, type: string) {
    this.onActionAdditionnalCost.emit({datas, type});
  }

  public addElementToSupplierOffer() {
    if (this.multiTable.linkedData[0]) {
      this.onUpdateSupplierElementsList.emit(this.multiTable.linkedData[0].id);
    }
  }

  public popOverMouseOver(event) {
    const element = event.toElement;
    if (/^popOver#\d+$/.test(element.id)) {
      const content = element.children[0];
      const parent = element.parentElement;
      // Define the position
      const posXa = parent.getBoundingClientRect().right;
      const posXb = parent.getBoundingClientRect().left;
      const posX = ((posXa - posXb) / 2) + posXb;
      const posY = element.getBoundingClientRect().bottom;
      // Set the position
      content.style.left = `${posX}px`;
      content.style.top = `${posY}px`;
    }

    this.renderer.addClass(element, "popover_active");
  }

  public popOverMouseOut(event) {
    this.renderer.removeClass(event.fromElement, "popover_active");
  }

}
