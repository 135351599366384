import { Component, OnInit, Input, OnChanges, SimpleChanges, Output, EventEmitter } from "@angular/core";
import { FormArray, FormGroup } from "@angular/forms";
import { IFields } from "app/facade/interfaces/supplier";
import { DEFAULT_TABLE_FORM_ACTION } from "app/facade/enums/fields-default-value";
import { ITableHeader, IActionTableHeader, ITableFormConfigEditable, ITableFormActions } from "app/facade/interfaces/tables.interface";
import { ISupplyListFieldsConfig, ISupplierOfferFieldsConfig } from "app/facade/interfaces/fields-config.interface";

@Component({
  selector: "app-table-form",
  templateUrl: "./table-form.component.html",
  styleUrls: ["./table-form.component.css"]
})
export class TableFormComponent implements OnInit, OnChanges {
  @Input() public isSupplyTableFormItem: boolean = false;
  @Input() public fieldsTypeConfig: ISupplyListFieldsConfig | ISupplierOfferFieldsConfig;
  @Input() public editableInputs: ITableFormConfigEditable = {
    isAllEditable: true,
    fieldsEditable: []
  };
  @Input() public headerConfig: ITableHeader;
  @Input() public tableDatas: FormArray = new FormArray([]);
  @Input() public newData: FormGroup;
  @Input() public attributs: IFields[] = [];
  @Input() public tableIndex: number;
  @Input() public actionsList: any = {};
  @Input() public optionsList: any = {};
  @Input() public filter: string = "";
  @Input() public autofocusFirstField: boolean = false;
  @Input() public actionsListButton: ITableFormActions = DEFAULT_TABLE_FORM_ACTION;
  @Input() public rowAdditionnalDatasConfig: any;
  @Output() public onAddingRow: EventEmitter<any> = new EventEmitter<any>();
  @Output() public onDeletingRow: EventEmitter<any> = new EventEmitter<any>();
  public attributsConfig: any[] = [];
  private attributList: Set<string> = new Set<string>();
  public indexToNotDisplay: number[] = [];

  constructor() { }

  ngOnInit() {

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.attributs) {
      this.setConfig();
    }
    if (changes.filter) {
      this.checkDisplayLine();
    }
  }

  private checkDisplayLine() {
    this.indexToNotDisplay = [];
    if (this.filter !== "") {
      this.tableDatas.controls.forEach( (formGroup, index) => {
        if (this.checkAttributesValueToFilter(formGroup.value)) {
          this.indexToNotDisplay.push(index);
        }
      });
    }
  }

  private checkAttributesValueToFilter(groupValue: any): boolean {
    return Array.from(this.attributList).every( attr => {
      if (groupValue[attr]) {
        return groupValue[attr].toString().toLowerCase().search(this.filter.toLowerCase()) === -1;
      }
      return true;
    });
  }

  private setConfig() {
    this.attributList.clear();
    this.attributsConfig = [];
    let config;
    this.orderAttributesByPriority(this.fieldsTypeConfig);
    this.attributs.forEach( field => {
      if (this.fieldsTypeConfig[field.name]) {
        config = {...this.fieldsTypeConfig[field.name]};
        this.attributList.add(config.name);
        if (config.type === "suggest" && this.actionsList[config.name]) {
          config.actionToDo = this.actionsList[config.name];
          config.options = this.optionsList[config.name];
          config.addedData = { index: this.tableIndex };
        }
        this.attributsConfig.push(config);
      }

    });
  }

  /**
   * @description Order fetched attributes by their configured priority
   * @author Quentin Wolfs
   * @private
   * @param {(ISupplyListFieldsConfig | ISupplierOfferFieldsConfig)} config
   * @memberof TableFormComponent
   */
  private orderAttributesByPriority(config: ISupplyListFieldsConfig | ISupplierOfferFieldsConfig): void {
    this.attributs.sort((a, b) => {
      if (!config[a.name] || !config[b.name]) {
        return 0;
      } else {
        return config[a.name].priority > config[b.name].priority ? 1 : -1;
      }
    });
  }

  public generateUlClass() {
    return {
      "table_fix": this.headerConfig.headersName.length > 10
    };
  }

  public addRow(eventData: any) {
    this.onAddingRow.emit({ ...eventData, categoryId: this.tableIndex});
  }

  public deleteRow(eventData: any) {
    this.onDeletingRow.emit({ ...eventData, categoryId: this.tableIndex});
  }

  public executeHeaderAction(eventData: IActionTableHeader) {
    if (eventData.actionToExecute) {
      eventData.actionToExecute({event: eventData, tableDatas: this.tableDatas, newData: this.newData});
    }
  }

}
