import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

@Injectable({
  providedIn: "root"
})
export class ListTableService {
    private _disableLoadMore: Subject<boolean> = new Subject<boolean>();

    constructor() { }

    public get disableLoadMore() {
        return this._disableLoadMore;
    }

    public setLoadMoreStatus(value: boolean) {
        this._disableLoadMore.next(!value);
    }
}