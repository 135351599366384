// INFO >>>>> NOT USE
import { Component, OnInit, Input, Renderer2, ElementRef, ViewChild, OnChanges, SimpleChanges, OnDestroy, EventEmitter, Output } from "@angular/core";
import { IPresetSize, IBarsetItem, IBarsetItemContent, IMovePartFromItem } from "app/facade/interfaces/barset.interface";
import { AmalgamService } from "app/facade/services/amalgam.service";
import { Subscription } from "rxjs";

@Component({
  selector: "app-barset-item",
  templateUrl: "./barset-item.component.html",
  styleUrls: ["./barset-item.component.css"]
})
export class BarsetItemComponent implements OnInit, OnChanges, OnDestroy {
  private _resetBarsetSub: Subscription;
  @Input() public editBarsets: boolean = false;
  @Input() public barsetItem: IBarsetItem;
  @Input() public barsetFilter: string = "";
  public presetSize: IPresetSize[];
  public editMode: boolean = false;
  public isDisplay: boolean = true;
  @Output() public onMoveAmalgamPart: EventEmitter<IMovePartFromItem> = new EventEmitter<IMovePartFromItem>();
  @Output() public ondeleteEmptyAmalgam: EventEmitter<IBarsetItem> = new EventEmitter<IBarsetItem>();

  constructor(
    private _elementRef: ElementRef,
    private _renderer: Renderer2,
    private _amalgamService: AmalgamService) {

  }

  ngOnInit() {
    this._resetBarsetSub = this._amalgamService.getResetBarsetSubscriber().subscribe( barset => {
      if (barset.id === this.barsetItem.id) {
        this.editMode = false;
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.presetSize = [...this._amalgamService.getBarsetItemSize(this.barsetItem.supplyCategoryId)];
    this.checkDiplayBarset();
    if ( this.editMode || this.isDisplay) {
      this._renderer.addClass(this._elementRef.nativeElement, "barset-item");
    } else {
      this._renderer.removeClass(this._elementRef.nativeElement, "barset-item");
    }
  }

  ngOnDestroy(): void {
    this._resetBarsetSub.unsubscribe();
  }

  private checkDiplayBarset() {
    this.isDisplay = (this.barsetItem.reference.toLowerCase().search(this.barsetFilter.toLowerCase()) !== -1 ) ||
    (this.barsetItem.key.toString()
      .toLowerCase().search(this.barsetFilter.toLowerCase()) !== -1 ) ||
      this.barsetItem.content.some( part => {
        return part.reference.toLowerCase().search(this.barsetFilter.toLowerCase()) !== -1 ||
          part.description.toLowerCase().search(this.barsetFilter.toLowerCase()) !== -1 ||
          part.denomination.toLowerCase().search(this.barsetFilter.toLowerCase()) !== -1 ||
          part.format.toLowerCase().search(this.barsetFilter.toLowerCase()) !== -1;
      }
    );
  }

  public updateBarsetSize(value: number) {
    // this._amalgamService.updateAmalgamSize(value, this.barsetItem);
  }

  public updateLockedValue() {
    // TODO: Check if we can unlock the barset
    if ( true ) {
      this.barsetItem.isLocked = !this.barsetItem.isLocked;
      if ( this._amalgamService.tempEditedBarset && this._amalgamService.tempEditedBarset.id === this.barsetItem.id) {
        this.saveNewData();
      }
    }
  }

  public openEditMode() {
    if ( !this.barsetItem.isLocked ) {
      this._amalgamService.saveTempBarset(this.barsetItem);
      this.editMode = true;
    }
  }

  public leaveEditMode() {
    this._amalgamService.saveTempBarset(null);
  }


  public saveNewData() {
    this._amalgamService.saveEditedBarset();
    this.editMode = false;
    if (this.barsetItem.content.length === 0) {
      this.ondeleteEmptyAmalgam.emit(this.barsetItem);
    }
  }

  public moveAmalgamPartAction(content: IBarsetItemContent) {
    this.onMoveAmalgamPart.emit({
      barsetPart: content,
      barset: this.barsetItem
    });
  }

}
