/*
 * @Author: Kevin Palade
 * @Date: 2018-09-08 14:52:18
 * @Last Modified by: Kevin Palade
 * @Last Modified time: 2018-09-21 13:22:09
 */
import { Component } from "@angular/core";
import { SnackService } from "../services/snack.service";

@Component({
  selector: "app-snack-bar",
  templateUrl: "./snack-bar.component.html",
  styleUrls: ["./snack-bar.component.css"]
})
export class SnackBarComponent {

  constructor(private _snackService: SnackService) { }

}
