import { Component, OnInit, Input, Renderer2, ElementRef, Output, EventEmitter } from "@angular/core";
import { IContainerCardsConfig } from "app/facade/interfaces/container-cards-config.interface";

@Component({
  selector: "app-container-cards",
  templateUrl: "./container-cards.component.html",
  styleUrls: ["./container-cards.component.css"]
})
export class ContainerCardsComponent implements OnInit {

  @Input() configs: IContainerCardsConfig = null;
  @Input() datas: any = null;
  @Input() verticalPrint: boolean = false;
  @Input() dataToDelete = [];
  @Output() onSelectItemCard: EventEmitter<any> = new EventEmitter<any>();
  @Output() onCheckItemCard: EventEmitter<any> = new EventEmitter<any>();
  public showDelete: boolean = false;

  constructor(
    private renderer: Renderer2,
    private elementRef: ElementRef) {
      this.renderer.addClass(this.elementRef.nativeElement, "container-card");
  }

  ngOnInit() {
  }
  public checkItemCard( data: any) {
    const dataChecked = this.datas.filter(r => r.checked == true);
    if (dataChecked.length > 0) {
      this.showDelete = true;
    } else {
      this.showDelete = false;
    }
    this.dataToDelete = dataChecked;

    // this.onCheckItemCard.emit(data);
  }
  public selectItemCard(event: MouseEvent, data: any) {
    const target: any = event.target;
    if (target.tagName === "BUTTON") {
      event.preventDefault();
      event.stopPropagation();
      return;
    }
    for (const d of this.datas) {
      if (d.id == data.id) {
        d.checked = !d.checked;
      }
    }
    const dataChecked = this.datas.filter(r => r.checked == true);
    if (dataChecked.length > 0) {
      this.showDelete = true;
    } else {
      this.showDelete = false;
    }
    this.dataToDelete = dataChecked;
    if (data.selectedCard !== undefined && !data.selectedCard && target.tagName !== "BUTTON") {
      this.onSelectItemCard.emit(data);
    }
  }
  public deletes() {
    const action = this.configs.itemConfig.actions.filter(r => ["Supprimer", "Retirer"].indexOf(r.label) > -1);
    action[0].fct(this.dataToDelete);
  }

}
