import { Component, OnInit, Inject } from "@angular/core";
import { StepSummaryBase } from "../../base/step-summary-base";
import { CONTAINER_DATA, StepFormService } from "app/presentationnal/organisms/wizard-modal/service/step-form.service";
import { FormBuilder } from "@angular/forms";

@Component({
  selector: "app-embase-summary",
  templateUrl: "./embase-summary.component.html",
  styleUrls: ["./embase-summary.component.css"]
})
export class EmbaseSummaryComponent extends StepSummaryBase implements OnInit {

  constructor(@Inject(CONTAINER_DATA) data: any,
              protected _stepFormSrv: StepFormService,
              protected _fb: FormBuilder) {
    super(data, _stepFormSrv, _fb);
  }

  protected formatWeldingSummary(weldingData: any, customData: any) {
    const weldingSummary: any[] = super.formatWeldingSummary(weldingData, customData);

    if (this.summaryForm.get("Quantity").value === 1 && this.dataTicket.element.Angle1 !== undefined) {
      const angle: string = this.actualData.custom.usedAngle.charAt(0).toUpperCase() + this.actualData.custom.usedAngle.slice(1);
      weldingSummary.unshift({ name : "Angle utilisé", value: this.dataTicket.element[angle], suffix: "°" });
    }

    return weldingSummary;
  }

}
