import { Directive, ElementRef, HostListener, ViewContainerRef } from "@angular/core";

export interface IClickOutsideComponent {
  showInfo();
}

@Directive({
  selector: "[appClickOutside]"
})
export class ClickOutsideDirective {

  constructor(private _elementRef: ElementRef, private _vcRef: ViewContainerRef) { }

  @HostListener("document:click", ["$event.target"])
    public onClick(targetElement) {
      const clickedInside = this._elementRef.nativeElement.contains(targetElement);
      if (!clickedInside) { this._vcRef["_view"].component.showInfo(); }
    }
}
