import { Poutrelle, PoutrelleProperty } from "./poutrelle";
import { MM_TO_M, CUBE_M_TO_L, SQUARE_MM_TO_SQUARE_M, calculateNewValueWithCuttinAngle } from "app/facade/quote-element-definition/base";

export interface TShapeValues {
    H: number;
    B: number;
    T_w: number;
    T_f: number;
    R: number;
    A_L: number;
    A: number;
    I_y: number;
    I_z: number;
    i_y: number;
    i_z: number;
    W_ely: number;
    W_elz: number;
    R2: number;
}

export class BeamTShape extends Poutrelle {

    public constructor(
        name: string,
        reference: string,
        values: TShapeValues,
        properties: PoutrelleProperty) {
        super(name, reference, values, properties);
    }

    public get meterForWelding(): number {
        return (this.values.B
            + (this.values.B - this.values.T_w)
            + ((this.values.H - this.values.T_f) * 2)
            + (this.values.T_f * 2)
            + this.values.T_w) * MM_TO_M;
    }

    public meterForWeldingWithCut(angle: number): number {
      const B = calculateNewValueWithCuttinAngle(this.values.B, angle),
            B_i = calculateNewValueWithCuttinAngle((B - this.values.T_w), angle);
      return (B
        + B_i
        + ((this.values.H - this.values.T_f) * 2)
        + (this.values.T_f * 2)
        + this.values.T_w) * MM_TO_M;
    }

    public get TotalWeight(): number {
        const volume = this.values.A * SQUARE_MM_TO_SQUARE_M * this.properties.length * MM_TO_M;

        return volume * CUBE_M_TO_L * this.properties.matter.kgByLiter;
    }

    public get CalculatedPrice(): number {
        return this.TotalWeight * this.properties.matter.pricePerKg;
    }
}