import { Component, OnInit, Output, EventEmitter, Input, ViewChild, ElementRef, OnChanges, SimpleChanges } from "@angular/core";
import * as moment from "moment";

@Component({
  selector: "app-date-picker",
  templateUrl: "./date-picker.component.html",
  styleUrls: ["./date-picker.component.css"]
})
export class DatePickerComponent implements OnInit, OnChanges {
  private _localeString: string = "en-gb";
  private _dateFormat: string = "DD/MM/YYYY";
  private _todayDate: any;
  private _selectedDay: any;
  public disableValidation: boolean = true;
  public navDate: any;
  public weekDaysHeaderArr: Array<string> = [];
  public calendarDays: Array<any> = [];
  @Output() public closeDatePicker = new EventEmitter<boolean>();
  @Output() public validateDate = new EventEmitter<string>();
  @Input() public formSelectedDate: string;
  @ViewChild("dateInputPicker") public dateInputPicker: ElementRef;
  @Output() public initInputPicker: EventEmitter<ElementRef> = new EventEmitter<ElementRef>();

  constructor() { }

  ngOnInit() {
    this.initInputPicker.emit(this.dateInputPicker);
    this._todayDate = moment();
    moment.locale(this._localeString);
    this._initializeValues();
    this.calendarWeekName();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.formSelectedDate && !changes.formSelectedDate.firstChange) {
      this._initializeValues();
    }
  }

  private _initializeValues() {
    if (this.formSelectedDate) {
      this.navDate = moment(this.formSelectedDate, this._dateFormat);
      this._selectedDay = moment(this.formSelectedDate, this._dateFormat).format(this._dateFormat);
    } else {
      this.navDate = moment();
      this._selectedDay = this._todayDate.format(this._dateFormat);
    }
    this.calendarDayMonth();
  }

  public changeNavMonth(num: number) {
      this.navDate.add(num, "month");
      this.calendarDayMonth();

  }
  public calendarWeekName() {
    const weekDaysArr: Array<number> = [0, 1, 2, 3, 4, 5, 6];
    weekDaysArr.forEach(day => this.weekDaysHeaderArr.push(moment().weekday(day).format("ddd")));
  }

  /**
   * @description Create the table of days of the selected month
   * @author Rousseau Corentin
   * @memberof DatePickerComponent
   */
  public calendarDayMonth() {
    this.disableValidation = true;
    this.calendarDays = [];
    const startCalendarDate = moment({
            y : this.navDate.year(),
            M : this.navDate.month(),
            d : 1
          }).startOf("week"),
          endCalendarDate = moment({
            y : this.navDate.year(),
            M : this.navDate.month(),
            d : this.navDate.daysInMonth()
          }).endOf("week").add(1, "days");

    while (startCalendarDate.valueOf() < endCalendarDate.valueOf()) {
      const obj: any = {
        "value" : startCalendarDate.format("D"),
        "available" : startCalendarDate.format("M") === this.navDate.format("M"),
        "passed" : startCalendarDate.endOf("day").valueOf() < this._todayDate.valueOf(),
        "today" : startCalendarDate.format(this._dateFormat) === this._todayDate.format(this._dateFormat),
        "selected" : startCalendarDate.format(this._dateFormat) === this._selectedDay,
        "dateValue" : startCalendarDate.format(this._dateFormat)
      };
      this.calendarDays.push(obj);
      if (this.disableValidation) {
        this.disableValidation = !obj.selected;
      }
      startCalendarDate.add(1, "days");
    }
  }

  public close() {
    this._initializeValues();
    this.closeDatePicker.emit(true);
  }

  public validate() {
    const selectedDate = this.calendarDays.find(function(element) {
      return element.selected;
    });
    if (selectedDate) {
      this.navDate = moment(selectedDate.dateValue, this._dateFormat);
      this._selectedDay = selectedDate.dateValue;
      this.validateDate.emit(selectedDate.dateValue);
    }
  }

  public onActivateValidation(activate: boolean) {
    this.disableValidation = !activate;
  }

}
