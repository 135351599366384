import { OrderByDirection } from "../enums/order-by-direction.enum";
export interface IUser {
  id: number;
  username: string;
  firstname: string;
  lastname: string;
}

export interface IUserFilter {
  deleted?: boolean;
  filter?: string;
}

export interface IUserList {
  id: number;
  username: string;
  firstname: string;
  lastname: string;
  userGroup: string;
  actionsList?: any[];
}

export enum EnumUserSortBy {
  ID = "ID",
  USERNAME = "USERNAME",
  FIRSTNAME = "FIRSTNAME",
  LASTNAME = "LASTNAME",
  EMAIL = "EMAIL",
  USERGROUP = "USERGROUP",

}
export interface IUserSort {
  sortBy: EnumUserSortBy;
  sortDirection: OrderByDirection;
}
