import { Component, OnInit, Input } from "@angular/core";
import { FormGroup } from "@angular/forms";

@Component({
  selector: "app-checkbox-input",
  templateUrl: "./checkbox-input.component.html",
  styleUrls: ["./checkbox-input.component.css"]
})
export class CheckboxInputComponent implements OnInit {
  @Input() public tabIndex: number = 0;
  @Input() name: string;
  @Input() id: string;
  @Input() group: FormGroup;
  @Input() label: string;
  @Input() labelRight: boolean = true;
  @Input() disable: boolean = false;
  @Input() small: boolean = false;
  public valueCheckbox: boolean;
  constructor() {
   }

  ngOnInit() {
  }

  /**
   * checkValue
   */
  public checkValue() {
    if (!this.disable) {
      this.group.get(this.name).patchValue( this.group.get(this.name).value ? false : true);
      this.group.get(this.name).markAsTouched();
    }
  }

}
