import { getUrlScheme } from "@angular/compiler";
import { Component, OnInit, Output, EventEmitter, Input } from "@angular/core";
import { cp } from "@angular/core/src/render3";
import { AuthService } from "@lib/auth/auth.service";
import { USER_GROUPS } from "app/facade/enums/usergroups.enum";
import { Router } from '@angular/router';
import { SnackService } from "../snack-bar/services/snack.service";

@Component({
  selector: "app-secondary-bar",
  templateUrl: "./secondary-bar.component.html",
  styleUrls: ["./secondary-bar.component.css"]
})
export class SecondaryBarComponent implements OnInit {

  @Output() btnActionEvent: EventEmitter<boolean> = new EventEmitter();
  @Output() btnOtherActionEvent: EventEmitter<boolean> = new EventEmitter();
  @Output() filterSearchType: EventEmitter<boolean> = new EventEmitter();
  @Output() leftBtnActionEvent: EventEmitter<boolean> = new EventEmitter();
  @Output() leftOtherBtnActionEvent: EventEmitter<boolean> = new EventEmitter();
  @Input() printSearch: boolean = true;
  @Input() leftIsFilter: boolean = true;
  @Input() showRightButton: boolean = true;
  @Input() showOtherRightButton: boolean = false;
  @Input() addBtnLabel: string = "Add";
  @Input() otherBtnLabel: string = "Other";
  @Input() addBtnTextAlert: boolean = false;
  @Input() filterPlaceholder: string = "Filter";
  @Input() valueData: string = "";
  public userGroup: any;
  isAdd = false;
  //private _snackBar: SnackService;

  constructor(private _authSrv: AuthService, private router: Router,private _snackBar: SnackService) { }

  ngOnInit() {
    this.userGroup =  this._authSrv.isUserGroup();
    // if(this.userGroup !== USER_GROUPS.ADMINISTRATOR){
    //   this.showRightButton = false;
    //   this.showOtherRightButton = false;
    // }
  }

  public btnAction(data: any = null): void {
    this.btnActionEvent.emit(true);
  }
  public btnOtherAction(data: any = null): void {
    this.btnOtherActionEvent.emit(true);
  }
  public leftButtonActon(data: any = null): void {
    this.leftBtnActionEvent.emit(true);
  }

  /**
   * filterSearch
   */
  public filterSearch(event) {
    this.filterSearchType.emit(event);
  }
}
