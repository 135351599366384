import { Component, OnInit, OnDestroy } from "@angular/core";
import { Subscription } from "rxjs";
import { CatalogService } from "../../../../facade/queries/catalog/sync/catalog.service";

@Component({
  selector: "app-catalog-pages",
  templateUrl: "./catalog-pages.component.html",
  styleUrls: ["./catalog-pages.component.css"]
})
export class CatalogPagesComponent implements OnInit, OnDestroy {
  private _wizardPath: string[] = ["drilling", "sawing", "beam", "tube", "plate", "embase", "nervure", "plaque", "gousset", "raidisseur", "equerre"];
  private _catalogPath: string[] = ["HEA", "HEB", "HEM", "IPE", "IPN", "T", "U", "UPE", "UPN", "tubes_carres", "tubes_rectangles", "cornieres_egales", "cornieres_inegales", "tubes_ronds"];
  private _isPng: string[] = ["drilling", "equerre", "sawing"];
  private _svg: string[] = ["rod", "painting", "welding"];
  public category: any[];
  private _categorySubscription: Subscription;
  public elementGroup: any[];
  private _elementGroupSubscription: Subscription;
  public isCategory: boolean = true;

  constructor(private _catalogSrv: CatalogService) {}

  ngOnInit() {
    this._catalogSrv.initializeCatalog();
    this._categorySubscription = this._catalogSrv.getCurrentCategory().subscribe( newCategorie => {
      this.category = newCategorie.map( cat => {
        const extension: string = (this._isPng.indexOf(cat.icon) === -1) ? "jpg" : "png";
        let imgPath: string;
        if (this._wizardPath.indexOf(cat.icon) !== -1) {
          imgPath = `../../../../assets/img/wizard/menu/${cat.icon}.${extension}`;
        }
        if (this._catalogPath.indexOf(cat.icon) !== -1) {
          imgPath = `../../../../assets/img/catalog/${cat.icon}_mini.${extension}`;
        }
        return {
          ...cat,
          imgPath
        };
      });
      this.isCategory = this._catalogSrv.isCategory;
    });
    this._elementGroupSubscription = this._catalogSrv.getCurrentElementGroup().subscribe( newElementGroup => {
      this.elementGroup = newElementGroup;
      this.isCategory = this._catalogSrv.isCategory;
    });
  }

  ngOnDestroy() {
    this._categorySubscription.unsubscribe();
    this._elementGroupSubscription.unsubscribe();
    this._catalogSrv.reset();
  }

  public changeCatalogueCategories(newCategorie: any) {
    this._catalogSrv.changeCategories(newCategorie);
  }
}
