/*
 * @Author: Kevin Palade
 * @Date: 2018-09-08 14:48:47
 * @Last Modified by: Kevin Palade
 * @Last Modified time: 2018-11-16 11:45:55
 */
import { Component, OnInit, Output, EventEmitter, Input, OnChanges, SimpleChanges, } from "@angular/core";

@Component({
  selector: "app-table",
  templateUrl: "./table.component.html",
  styleUrls: ["./table.component.css"]
})
export class TableComponent implements OnInit, OnChanges {

  @Output("buttonClicked") public buttonClicked: EventEmitter<any> = new EventEmitter();
  @Input("categorie") public categorie: string;
  @Input("tableDatas") public tableDatas: any[] = [];
  @Input("displayAsTitle") public displayAsTitle: string[] = [];
  @Input("displayActions") public displayActions: boolean = true;
  @Input("canChangeMode") public canChangeMode: boolean = true;
  @Input("canPaginate") public canPaginate: boolean = true;
  @Input("editableRowId") public editableRowId: string | number = null;
  @Input("needAddRow") public needAddRow: boolean = false;
  @Input("defaultValues") public defaultValues: any;
  @Input("elementNatureDefinitions") public elementNatureDefinitions: any[];

  public splittedDatas: any[][];
  public tableHeaders: string[] = [];
  public valueModeTable: boolean = true;
  constructor() { }

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["elementNatureDefinitions"]) {
      this.tableHeaders = this.elementNatureDefinitions.map( def => def.name);
      this.tableHeaders.push("actions");

      this.generateSplittedDatas();
    }
  }

  public generateUlClass() {
    return {
      "table_mode_list": this.valueModeTable,
      "table_mode_card": !this.valueModeTable,
      "table_fix": this.tableHeaders.length > 10
    };
  }

  private generateSplittedDatas() {
    const maxListData: number = 500,
          datas: any[] = [...this.tableDatas];
    this.splittedDatas = [];
    while (datas.length !== 0) {
      this.splittedDatas.push(datas.splice(0, maxListData));
    }
  }

  /**
   * @description Change the table display mode
   * @author Kevin Palade
   * @param {*} newValue
   * @memberof TableComponent
   */
  public changeMode(newValue) {
    this.valueModeTable = newValue;
  }

  /**
   * @description Button has been clicked on table and emit to parent
   * @author Kevin Palade
   * @private
   * @param {*} event
   * @memberof TableComponent
   */
  private buttonHasBeenCLicked(event: any): void {
    this.buttonClicked.emit(event);
  }

}
