import { Finish, FinishProperty } from "./finish.base";
import { ITicketElement } from "app/presentationnal/organisms/wizard-modal/service/step-form.service";
import { KG_TO_T } from "../base";

export interface PaintingProperty extends FinishProperty {
    layerAmount?: number;
    layerThickness?: number;
    isBlasted?: boolean;
}

export const BLASTING_PRICE_PER_TON: number = 60;

export class Painting<T extends PaintingProperty> extends Finish<T> {

    public constructor(name: string, ticket: ITicketElement[], properties?: T) {
        super(name, ticket, properties);
    }

    public get LayerAmount(): number {
        return this.properties.layerAmount;
    }

    public set LayerAmount(value: number) {
        this.properties.layerAmount = value;
    }

    public get LayerThickness(): number {
        return this.properties.layerThickness;
    }

    public set LayerThickness(value: number) {
        this.properties.layerThickness = value;
    }

    public get IsBlasted(): boolean {
        return this.properties.isBlasted != null ? this.properties.isBlasted : false;
    }

    public set IsBlasted(value: boolean) {
        this.properties.isBlasted = value;
    }

    public get CalculatedPrice(): number {
      return (!this.FixedPrice ? this.getPricePerComputing() : this.Cost);
    }

    public getBlastingPrice(): number {
        return this.TotalWeight * KG_TO_T * BLASTING_PRICE_PER_TON;
    }

    public getPricePerComputing(): number {
      return (((this.Area || 1) * this.AreaCost) * this.LayerAmount) + (this.IsBlasted ? this.getBlastingPrice() : 0);
    }
}