import { ITabsWithLoading } from "../interfaces/tabs";
import { PriceRequestTabsData } from "../enums/price-request.enum";
import { IMultiTableData } from "../interfaces/multi-table.interface";
import { IMultiTableGroupData } from "../interfaces/multi-table-group.interface";

export const OPTION_LABELS = {
  BLACK: "Noir",
  BLASTED: "Gren.",
  PRIMARY_BLASTED: "GPP"
};

export const OPTION_DESCRIPTIONS = {
  [OPTION_LABELS.BLACK]: null,
  [OPTION_LABELS.BLASTED]: "Grenaillé",
  [OPTION_LABELS.PRIMARY_BLASTED]: "Grenaillé prépeint"
};

export const PRICE_REQUEST_TABS_CONFIG: ITabsWithLoading[] = [
  { value: PriceRequestTabsData.RESUME, label: "Résumé", isActive: true, isLoaded: false },
  { value: PriceRequestTabsData.SUPPLIER, label: "Détail par fournisseur", isActive: false, isLoaded: false },
  { value: PriceRequestTabsData.BARSET, label: "Mise en barre", isActive: false, isLoaded: false },
];

export enum ActionTypeForSupplierOffer {
  PRINT_PDF,
  SEND_MAIL
}

export const MULTI_TABLE_CLASS: any = {
  LARGE: "multi-table-main-content__table_large",
  SHORT: "multi-table-main-content__table_short",
  PLATE: "multi-table-main-content__table_plate",
};

interface IMultiTableConfigs {
  large: IMultiTableData;
  short: IMultiTableData;
  plate: IMultiTableData;
  linked: IMultiTableData;
  additionnalCostElement: IMultiTableData;
  additionnalCost: IMultiTableData;
}

export const MULTI_TABLE_CONFIG: IMultiTableConfigs = {
  large: {
    headers: ["Qty", "Référence", "Matière", "Options", "Format", "Coupe", "En1090", "Remarque"],
    datas: [],
    config: [
      {key: "quantity", isCheckBox: false },
      {key: "reference", isCheckBox: false },
      {key: "matterRef", isCheckBox: false },
      {key: "option", isCheckBox: false },
      {key: "format", isCheckBox: false },
      {key: "isCut", isCheckBox: true },
      {key: "isEn1090", isCheckBox: true },
      {key: "remark", isCheckBox: false, clickFct: null },
    ],
    tableClass: MULTI_TABLE_CLASS.LARGE,
    categoryId: null
  },
  plate: {
    headers: ["Qté", "Unité", "Matière", "Épaisseur", "Longueur", "Largeur", "Remarque"],
    datas: [],
    config: [
      {key: "quantity", isCheckBox: false },
      {key: "quantityUnit", isCheckBox: false },
      {key: "matterRef", isCheckBox: false },
      {key: "thickness", isCheckBox: false },
      {key: "length", isCheckBox: false },
      {key: "width", isCheckBox: false },
      {key: "remark", isCheckBox: false, clickFct: null },
    ],
    tableClass: MULTI_TABLE_CLASS.PLATE,
    categoryId: null
  },
  short: {
    headers: ["Qty", "Dénomination", "Remarque"],
    datas: [],
    config: [
      {key: "quantity", isCheckBox: false },
      {key: "denomination", isCheckBox: false },
      {key: "remark", isCheckBox: false, clickFct: null }
    ],
    tableClass: MULTI_TABLE_CLASS.SHORT,
    categoryId: null
  },
  linked: {
    headers: ["€ / pcs", "Date", "Qté", ""],
    datas: [],
    config: [
      {key: "price", isCheckBox: false, keyToCheck: "bestPriceClass" },
      {key: "deliveryDate", isCheckBox: false, keyToCheck: "bestDeliveryTimeClass" },
      {key: "selectedQuantity", isCheckBox: false , type: "number", focusOutFct: null},
      {key: "isSelected", isCheckBox: true, type: "checkbox", clickFct: null }
    ],
    tableClass: "",
    categoryId: null
  },
  additionnalCostElement: {
    headers: ["Coûts additionnels globaux"],
    datas: [],
    config: [
      {key: "denomination", isCheckBox: false},
    ],
    tableClass: "",
    categoryId: null
  },
  additionnalCost: {
    headers: ["€ / pcs", "", "Qté", ""],
    datas: [],
    config: [
      {key: "price", isCheckBox: false},
      {key: "emptyData", isCheckBox: false},
      {key: "quantity", isCheckBox: false},
      {key: "isSelected", isCheckBox: true, type: "checkbox"},
    ],
    tableClass: "",
    categoryId: null
  }
};

export const MULTI_TABLE_GROUP_CLASS: any = {
  LARGE: "multi-supply-table-main-content__table_large",
  SHORT: "multi-supply-table-main-content__table_short",
  PLATE: "multi-supply-table-main-content__table_plate"
};

export const NEW_MULTI_TABLE_GROUP_CLASS: any = {
  AMALGAM: "block-products__amalgam",
  BASIC: "block-products__basic",
  PLATE: "block-products__plate",
  ADDITIONNAL_COST: "block-products__additionnal-cost"
};

// TODO - Add plate attribut
interface IMultiTableGroupConfigs {
  large: IMultiTableGroupData;
  plate: IMultiTableGroupData;
  short: IMultiTableGroupData;
  linked: IMultiTableGroupData;
  additionnalCostElement: IMultiTableGroupData;
  additionnalCost: IMultiTableGroupData;
}

export const MULTI_TABLE_GROUP_CONFIG: IMultiTableGroupConfigs = {
  large: {
    headers: ["", "Qté", "Stock", "BC", "Référence", "Matière", "Options", "Format", "En1090", "Remarque", "Poids"],
    datas: [],
    config: [
      {key: "quantity", isCheckBox: false },
      {key: "stockQuantity", isCheckBox: false },
      {key: "purchaseQuantity", isCheckBox: false },
      {key: "reference", isCheckBox: false },
      {key: "matterRef", isCheckBox: false },
      {key: "option", isCheckBox: false },
      {key: "format", isCheckBox: false },
      {key: "isEn1090", isCheckBox: true },
      {key: "remark", isCheckBox: false, clickFct: null },
      {key: "weight", isCheckBox: false},
    ],
    tableClass: MULTI_TABLE_GROUP_CLASS.LARGE,
    newTableClass: NEW_MULTI_TABLE_GROUP_CLASS.AMALGAM,
    categoryId: null,
    unit: null
  },
  plate: {
    headers: ["", "Qté", "BC", "Unité", "Matière", "Épaisseur", "Longueur", "Largeur", "Remarque", "Poids"],
    datas: [],
    config: [
      { key: "quantity", isCheckBox: false },
      { key: "purchaseQuantity", isCheckBox: false },
      { key: "quantityUnit", isCheckBox: false },
      { key: "matterRef", isCheckBox: false },
      { key: "thickness", isCheckBox: false },
      { key: "length", isCheckBox: false },
      { key: "width", isCheckBox: false },
      { key: "remark", isCheckBox: false, clickFct: null },
      { key: "weight", isCheckBox: false }
    ],
    tableClass: MULTI_TABLE_GROUP_CLASS.PLATE,
    newTableClass: NEW_MULTI_TABLE_GROUP_CLASS.PLATE,
    categoryId: null,
    unit: null
  },
  short: {
    headers: ["", "Qté", "BC", "Unité", "Dénomination", "Remarque", "Poids"],
    datas: [],
    config: [
      { key: "quantity", isCheckBox: false },
      { key: "purchaseQuantity", isCheckBox: false },
      { key: "quantityUnit", isCheckBox: false },
      { key: "denomination", isCheckBox: false },
      { key: "remark", isCheckBox: false, clickFct: null },
      { key: "weight", isCheckBox: false },
    ],
    tableClass: MULTI_TABLE_GROUP_CLASS.SHORT,
    newTableClass: NEW_MULTI_TABLE_GROUP_CLASS.BASIC,
    categoryId: null,
    unit: null
  },
  linked: {
    headers: ["Prix", "Date", "Qté", ""],
    datas: [],
    config: [
      {key: "price", isCheckBox: false, keyToCheck: "bestPriceClass", asUnit: true },
      {key: "deliveryDate", isCheckBox: false, keyToCheck: "bestDeliveryTimeClass" },
      {key: "selectedQuantity", isCheckBox: false , type: "number", focusOutFct: null},
      {key: "isSelected", isCheckBox: true, type: "checkbox", clickFct: null }
    ],
    tableClass: "",
    categoryId: null,
    unit: null
  },
  additionnalCostElement: {
    headers: ["", "Qté", "Coûts additionnels globaux"],
    datas: [],
    config: [
      {key: "quantity", isCheckBox: false},
      {key: "denomination", isCheckBox: false},
    ],
    tableClass: "",
    newTableClass: NEW_MULTI_TABLE_GROUP_CLASS.ADDITIONNAL_COST,
    categoryId: null,
    unit: null
  },
  additionnalCost: {
    headers: ["Prix", "", "", ""],
    datas: [],
    config: [
      {key: "price", keyToCheck: "bestPriceClass", isCheckBox: false, asUnit: true},
      {key: "emptyData", isCheckBox: false},
      {key: "quantity", isCheckBox: false},
      {key: "isSelected", isCheckBox: true, type: "checkbox", clickFct: null},
    ],
    tableClass: "",
    categoryId: null,
    unit: null
  }
};
