import { Component, OnInit, Inject } from "@angular/core";
import { StepSummaryBase } from "../../base/step-summary-base";
import { CONTAINER_DATA, StepFormService } from "app/presentationnal/organisms/wizard-modal/service/step-form.service";
import { FormBuilder } from "@angular/forms";

@Component({
  selector: "app-gousset-summary",
  templateUrl: "./gousset-summary.component.html",
  styleUrls: ["./gousset-summary.component.css"]
})
export class GoussetSummaryComponent extends StepSummaryBase implements OnInit {

  constructor(@Inject(CONTAINER_DATA) data: any,
              protected _stepFormSrv: StepFormService,
              protected _fb: FormBuilder) {
    super(data, _stepFormSrv, _fb);
  }

}

