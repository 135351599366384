import { Component, OnInit, Input } from "@angular/core";
import { Apollo } from "apollo-angular";

import { ADD_ELEMENT_EXCEL } from "./element-excel-upload-queries";

@Component({
    selector: "app-element-excel-upload",
    templateUrl: "./element-excel-upload.component.html",
    styleUrls: ["./element-excel-upload.component.css"]
})
export class ElementExcelUploadComponent implements OnInit {

    @Input("elementGroupId") public elementGroupId: number;

    public name: string;
    public file: File;

    public constructor(private _apollo: Apollo) { }

    public ngOnInit() {
        this.name = "testExcel";
    }

    public setFile(fileChangeEvent: Event): void {
        const input = <HTMLInputElement>fileChangeEvent.target;
        this.file = input.files[0];
        this.uploadElement();
    }

    public uploadElement(): void {
        this._apollo.mutate({
            mutation: ADD_ELEMENT_EXCEL,
            variables: {
                element: {
                    name: this.name,
                    elementGroupId: 2,
                    file: this.file
                }
            }
        }).subscribe(result => {
            console.log("SUCCESS");
        },
        err => {
            console.log("ERROR");
        });
    }
}
