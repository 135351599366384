import { Component, OnInit, Inject, OnDestroy } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { ActualData, CONTAINER_DATA, StepFormService } from "app/presentationnal/organisms/wizard-modal/service/step-form.service";
import { TransportFormulaDisplayer, StudyFormulaDisplayer } from "app/facade/quote-element-definition/formula.display";
import { Subscription } from "rxjs";
import { OptionService } from "app/facade/quote-element-definition/options/options.service";
import { Study, StudyProperty } from "app/facade/quote-element-definition/options/study";

@Component({
  selector: "app-study-step-one",
  templateUrl: "./study-step-one.component.html",
  styleUrls: ["./study-step-one.component.css"]
})
export class StudyStepOneComponent implements OnInit, OnDestroy {
  private studyStepOneForm: FormGroup;
  private _actualData: ActualData;
  private _optionData: Study;
  public newStudy: Study;
  private _formulaDisplayer: StudyFormulaDisplayer;
  private _formulaSub: Subscription;
  public calculs: string[];
  public isKg = false;

  constructor(@Inject(CONTAINER_DATA) data: any,
    private _fb: FormBuilder,
    private _stepFormSrv: StepFormService,
    private _optionSrv: OptionService) {
    this._actualData = this._stepFormSrv.get();
    this._optionData = this._actualData.element;
  }

  ngOnInit() {
    this.createInitObject();
    this.initform();
    this.calculs = [];
    this._formulaDisplayer = new StudyFormulaDisplayer();
    this._formulaSub = this._formulaDisplayer.$studyPrice.subscribe(calculs => this.calculs = calculs);
    this.showCalcul();
  }

  public ngOnDestroy() {
    this._formulaSub.unsubscribe();
  }

  public initform() {
    this.studyStepOneForm = this._fb.group({
      hourQuantity: [this.newStudy ? this.newStudy.HourQuantity : null, Validators.compose([Validators.required])],
      hourlyRate: [this.newStudy ? this.newStudy.HourlyRate : null, Validators.compose([Validators.required])],
      weightCost: [this.newStudy ? this.newStudy.WeightCost : null, Validators.compose([Validators.required])],
      isComputedByWeight: [this.newStudy ? this.newStudy.IsComputedByWeight : false, Validators.compose([Validators.required])],
      totalWeight: [this.newStudy ? this.newStudy.totalWeight : null, Validators.compose([Validators.required])]
    });

    this.studyStepOneForm.get("isComputedByWeight").valueChanges.subscribe(result => {
      this.isKg = result;
      // this.changeFormulaDisplayer();
    });
  }


  public save() {
    if (this.studyStepOneForm && this.studyStepOneForm.valid) {
      if (this._actualData.isEdited) {
        this._optionData.HourQuantity = this.studyStepOneForm.get("hourQuantity").value;
        this._optionData.HourlyRate = this.studyStepOneForm.get("hourlyRate").value;
        this._optionData.WeightCost = this.studyStepOneForm.get("weightCost").value;
        this._optionData.IsComputedByWeight = this.studyStepOneForm.get("isComputedByWeight").value;
        this.newStudy = this._optionData;

        this._stepFormSrv.updateElement(this.newStudy, null, this._actualData.index);
      } else {
        const studyData: StudyProperty = {
          hourQuantity: this.studyStepOneForm.get("hourQuantity").value,
          hourlyRate: this.studyStepOneForm.get("hourlyRate").value,
          weightCost: this.studyStepOneForm.get("weightCost").value,
          isComputedByWeight: this.studyStepOneForm.get("isComputedByWeight").value
        };
        this.newStudy = this._optionSrv.create("Study", studyData);
        this.newStudy.updateWeight(this._stepFormSrv.TicketData);
        this.newStudy.CustomName = "Etude";
        this._stepFormSrv.addElement(this.newStudy);
      }
    }
    this._stepFormSrv.closeModal$.next(true);
  }

  /**
   * save
   */
  public cancel() {
    this._stepFormSrv.closeModal$.next(true);
  }

  public showCalcul() {
    this.studyStepOneForm.valueChanges.subscribe(values => {
      const base = {
        hourQuantity: values.hourQuantity || null,
        hourlyRate: values.hourlyRate || null,
        isComputedByWeight: values.isComputedByWeight,
        priceByKg: values.weightCost || null,
        totalWeight: values.totalWeight || null
      };

      this._formulaDisplayer.base = base;
    });

    const init = {
      hourQuantity: this.studyStepOneForm.value.hourQuantity || null,
      hourlyRate: this.studyStepOneForm.value.hourlyRate || null,
      isComputedByWeight: this.studyStepOneForm.value.isComputedByWeight,
      priceByKg: this.studyStepOneForm.value.weightCost || null,
      totalWeight: this.studyStepOneForm.value.totalWeight || null
    };

    this._formulaDisplayer.base = init;
  }

  public createInitObject() {
    if (this._actualData.isEdited) {
      this.newStudy = this._optionData;
      this.isKg = this.newStudy.IsComputedByWeight;
    } else {
      const mountingData: StudyProperty = {
        hourQuantity: 1,
        hourlyRate: 1,
        weightCost: 1,
        isComputedByWeight: false
      };
      this.newStudy = this._optionSrv.create("Study", mountingData);
      this.newStudy.CustomName = "Etude";
    }
    this.newStudy.updateWeight(this._stepFormSrv.TicketData);
  }
}