import { Component, Input, OnInit, Output, EventEmitter, ViewChild, ElementRef } from "@angular/core";
import { IMultiTable, ILinkedMultiTableData } from "app/facade/interfaces/multi-table.interface";

@Component({
    selector: "app-multi-table",
    templateUrl: "./multi-table.component.html",
    styleUrls: ["./multi-table.component.css"]
})
export class MultiTableComponent implements OnInit {
  @Input() multiTable: IMultiTable;
  @Input() hasMultiLinked: boolean = true;
  @Input() mainContentHeight: string = "45vh";
  @ViewChild("mainContent") public mainContent: ElementRef;
  public scrollTopPosition: number = 0;
  @Output() onEncodeData: EventEmitter<number> = new EventEmitter<number>();

  ngOnInit(): void {
  }

  public encodeData(linkedId: number) {
    let linkedIdToEncode: number;
    if (this.hasMultiLinked) {
      linkedIdToEncode = linkedId;
    } else {
      linkedIdToEncode = this.multiTable.linkedData[0] ? this.multiTable.linkedData[0].id : null;
    }
    if (linkedIdToEncode) {
      this.onEncodeData.emit(linkedIdToEncode);
    }
  }

  public mainTableScroll(data: any) {
    this.scrollTopPosition = data.target.scrollTop;
  }

  // Not use > problem multi scroll event between the linked table and the main table
  public linkedTableScroll(scrollTopPosition: number) {
    this.mainContent.nativeElement.scrollTop = scrollTopPosition;
  }

}
