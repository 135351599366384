import gql from "graphql-tag";

export const ADD_ADMISSION = gql`mutation($data: purchaseOrderAdmissionLogInput!){
  addAdmission(data: $data){
    id
    quantity
    date
    location
    admitedBy
    idElement
  }
}`;

export const GET_ADMISSION = gql`
query getAdmission($id: Int!) {
  getAdmission(id: $id){
    id
    quantity
  }
}
`;


