import { Component, OnInit, Input, Renderer2, ElementRef, ViewChild } from "@angular/core";
import { AInputs } from "app/presentationnal/atoms/inputs/inputs";
import * as moment from "moment";

@Component({
  selector: "app-input-date",
  templateUrl: "./input-date.component.html",
  styleUrls: ["./input-date.component.css"]
})
export class InputDateComponent extends AInputs implements OnInit {

  public showPicker = false;
  public pickerElement: ElementRef;
  private _handler: any;
  @Input() public dateFormat: string = "DD/MM/YYYY";
  @Input() public dateValue: string;
  @ViewChild("dateInputContext") public dateInputContext: ElementRef;

  constructor(protected renderer: Renderer2, private _elRef: ElementRef) { super(renderer); }

  ngOnInit() {
    const value = this.group.get(this.name).value;
    if (!this.dateValue && value) {
      this.dateValue = this.isDateInFormat(value, this.dateFormat) ? value : moment.unix(value).format(this.dateFormat);
    }
  }

  public closePicker(event) {
    const clickedInside = this._elRef.nativeElement.contains(event.target);
    if (!clickedInside) {
      this.showPicker = false;
      this._handler();
    }
  }

  public toggleDatePicker(): void {
    window.dispatchEvent(new Event("resize"));
    if (!this.showPicker) {
      // Subscribe
      this._handler = this.renderer.listen(document, "click", event => this.closePicker(event));
      this.datePickerScript();
    } else {
      this._handler();
    }
    this.showPicker = !this.showPicker;
  }

  public onCloseDatePicker(close: boolean) {
    this.showPicker = !close;
    this._handler();
  }

  public onValidateDate(date: string) {
    this.dateValue = date;
    this.showPicker = false;
    this._handler();
    this.group.get(this.name).patchValue(moment(date, this.dateFormat).unix());
    this.group.get(this.name).markAsTouched();
  }

  public getInputPickerElement(pickerElement: ElementRef) {
    this.pickerElement = pickerElement;
  }

  public datePickerScript() {
    // TODO : Récupérer script pattern lab
    const context = this.dateInputContext.nativeElement;
    let contextPos = context.getBoundingClientRect();
    let contextPosX = contextPos.right;
    let contextPosY = contextPos.bottom;
    // Helpers for content (picker)
    this.datePickerPositionContent(contextPosX, contextPosY);

    // Listen to resize event // TODO: Check if we don't have to many event after open many datepicker
    window.onresize = (e) => {
      // Redefine position when event is triggered
      contextPos = context.getBoundingClientRect();
      contextPosX = contextPos.right;
      contextPosY = contextPos.bottom;
      // Trigger the position with new values
      this.datePickerPositionContent(contextPosX, contextPosY);
    };

  }

  // Position the content with context values
  private datePickerPositionContent(posX, posY) {
    const content: any = this.pickerElement.nativeElement;
    this.renderer.setStyle(content, "left", `${posX}px`);
    this.renderer.setStyle(content, "top", `${posY}px`);
    this.renderer.setStyle(content, "transform", `translateX(-100%)`);
  }

  private isDateInFormat(dateString: string, format: string): boolean {
    const date = moment(dateString, format, true); // Parse with strict mode
    return date.isValid() && date.format(format) === dateString;
  }
}
