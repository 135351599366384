import { Component, OnInit, Inject, OnDestroy } from "@angular/core";
import { DATA, ModalService } from "../../services/modal.service";
import { FormBuilder, Validators, AbstractControl, FormGroup, FormArray, FormControl } from "@angular/forms";
import { SnackService } from "app/presentationnal/organisms/snack-bar/services/snack.service";
import { ModalContentForm } from "../../model/modal-content-form";
import { IPriceRequest, IAmalgamParam } from "app/facade/interfaces/price-request.interface";
import { AMALGAM_MIN_SIZE, BARSET_ITEM_SIZES_CONFIG } from "app/facade/configs/barset-item-sizes.config";
import { ISelectOption } from "app/presentationnal/atoms/inputs/select-input/selectOptions";
import { Subscription } from "rxjs";
import { numberMatcher } from "app/presentationnal/atoms/inputs/custom-validator";
import { IBarsetGeneration } from "app/facade/interfaces/barset.interface";

@Component({
  selector: "app-modal-generate-new-amalgams",
  templateUrl: "./modal-generate-new-amalgams.component.html",
  styleUrls: ["./modal-generate-new-amalgams.component.css"]
})

export class ModalGenerateNewAmalgamsComponent extends ModalContentForm implements OnInit, OnDestroy {
  private _beamsLengthSub: Subscription;
  private _tubesLengthSub: Subscription;
  private _oldBeamsValue: number;
  private _oldTubesValue: number;
  public formTypes: string[] = ["beams", "tubes"];

  private _barsetGeneration: IBarsetGeneration;
  public beamSizesOptions: ISelectOption[] = BARSET_ITEM_SIZES_CONFIG.filter(size => size.value).map( size => {
    return {
      label: size.label,
      value: size.value
    };
  });
  public tubeSizesOptions: ISelectOption[] = BARSET_ITEM_SIZES_CONFIG.filter(size => size.tubeSize && size.value).map( size => {
    return {
      label: size.label,
      value: size.value
    };
  });

  public baseAmalgamLengthOptions: ISelectOption[] = [];
  public maxAmalgamLengthOptions: ISelectOption[] = [];

  constructor(
    protected _modalService: ModalService,
    protected _snackBar: SnackService,
    protected _fb: FormBuilder,
    @Inject(DATA) private _data: {data: {priceRequest: IPriceRequest, barsetGeneration: IBarsetGeneration}}) {
      super(_modalService, _fb, _snackBar);
      this._barsetGeneration = this._data.data.barsetGeneration;
  }

  ngOnInit() {
    this.initForm();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this._beamsLengthSub.unsubscribe();
    this._tubesLengthSub.unsubscribe();
  }

  private initForm() {
    this._formGroup = this._fb.group({
      beams: this._fb.group(this.generateCategoryFormArray("beam", this.beamSizesOptions)),
      tubes: this._fb.group(this.generateCategoryFormArray("tube", this.tubeSizesOptions))
    });
    this._oldBeamsValue = this._barsetGeneration.beamLength;
    this._oldTubesValue = this._barsetGeneration.tubeLength;

    this._beamsLengthSub = this._formGroup.get("beams").get("length").valueChanges.subscribe( value => {
      const formArrayValue: FormArray = <FormArray>this._formGroup.get("beams").get("otherLengths");
      this.updateOtherLengths(formArrayValue, value, this._oldBeamsValue);
      this._oldBeamsValue = value;
    });
    this._tubesLengthSub = this._formGroup.get("tubes").get("length").valueChanges.subscribe( value => {
      const formArrayValue: FormArray = <FormArray>this._formGroup.get("tubes").get("otherLengths");
      this.updateOtherLengths(formArrayValue, value, this._oldTubesValue);
      this._oldTubesValue = value;
    });
  }

  private updateOtherLengths(formArray: FormArray, value: number, oldValue: number) {
    formArray.controls.forEach( control => {
      if (control.get("value").value === oldValue) {
        control.get("selected").setValue(false);
      }
      if (control.get("value").value === value) {
        control.get("selected").setValue(true);
      }
      control.get("disabled").setValue(control.get("value").value === value);
    });
  }

  private generateCategoryFormArray(type: string, options: ISelectOption[]): any {
    return {
      length: this._barsetGeneration[type + "Length"],
      otherLengths: new FormArray(options.map( size => {
        return this._fb.group({
          label: size.label,
          value: size.value,
          selected: this._barsetGeneration[type + "OtherLengths"].indexOf(size.value) !== -1 || this._barsetGeneration[type + "Length"] === size.value,
          disabled: this._barsetGeneration[type + "Length"] === size.value
        });
      })),
      isAutoCut: this._barsetGeneration[type + "IsAutoCut"],
      cutThreshold: this._barsetGeneration[type + "Threshold"],
      maxLoss: this._barsetGeneration[type + "MaxLoss"]
    };
  }

  protected save(): void {
    const formValue: IAmalgamParam = {
      beams: {
        ...this._formGroup.value.beams,
        otherLengths: [...this._formGroup.value.beams.otherLengths]
          .filter( length => length.selected && length.value !== this._formGroup.value.beams.length)
          .map( length => length.value)
      },
      tubes: {
        ...this._formGroup.value.tubes,
        otherLengths: [...this._formGroup.value.tubes.otherLengths]
          .filter( length => length.selected && length.value !== this._formGroup.value.tubes.length)
          .map( length => length.value)
      }
    };
    const objData = {
      confirm: true,
      data: formValue
    };
    this.afterClosed.next(objData);
  }

}
