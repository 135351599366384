import { Component, OnInit, Inject } from "@angular/core";
import { ModalContentForm } from "../../model/modal-content-form";
import { ModalService, DATA } from "../../services/modal.service";
import { SnackService } from "app/presentationnal/organisms/snack-bar/services/snack.service";
import { FormBuilder, Validators, FormGroup } from "@angular/forms";
import { IProject, ISupplyListInput } from "app/facade/interfaces/project";
import { UPLOAD_URLS } from "@lib/upload-file/uploadUrls";
import { UploadFileService } from "@lib/upload-file/upload-file.service";
import { SupplyListSource } from "app/facade/enums/supply-list-source.enum";
import { IModalData } from "../../interfaces/modal-data.interface";
import { MODAL_CLOSE_ACTION } from "../../enums/modal-close-action.enum";
import { ProjectsQueriesService } from "app/facade/queries/project/projects-queries.service";

@Component({
  selector: "app-modal-add-project",
  templateUrl: "./modal-add-project.component.html",
  styleUrls: ["./modal-add-project.component.css"]
})
export class ModalAddProjectComponent extends ModalContentForm implements OnInit {
  private projectData: IProject;
  public requiredField: string[] = [];
  public printUploadBox: boolean = true;
  public formInputFileGroup: FormGroup;
  private _supplyLists: ISupplyListInput[] = [];

  constructor(
    protected _modalService: ModalService,
    protected _snackBar: SnackService,
    protected _fb: FormBuilder,
    private _projectsQueriesSrv: ProjectsQueriesService,
    @Inject(DATA) private _data: IModalData,
    private _uploadFileSrv: UploadFileService) {
      super(_modalService, _fb, _snackBar);
      this.projectData = this._data && this._data.data ? this._data.data : null;
      this.printUploadBox = this.projectData === null;
    }

  ngOnInit() {
    this.initForm();
  }

  private initForm() {
    this.requiredField = ["reference"];
    this._formGroup = this._fb.group({
      reference: [this.projectData ? this.projectData.reference : null, Validators.compose([
        Validators.required, Validators.maxLength(30)
      ])],
      isEn1090: [this.projectData ? this.projectData.isEn1090 : false]
    });

    this.formInputFileGroup = this._fb.group({
      fileValue: null
    });
  }

  public uploadTeklaFile(teklaFile: File) {
    const formData: FormData = new FormData();
    formData.append("file", teklaFile);
    const snackBarTitle: string = "Upload fichier Tekla";
    this._uploadFileSrv.uploadFile(formData, UPLOAD_URLS.TEKLA).subscribe( result => {
      this._supplyLists = [];
      const resultData: { project: IProject, supplyList: any} = <{ project: IProject, supplyList: any}>result;
      if (resultData.project) {
        this._formGroup.get("reference").patchValue(resultData.project.reference);
      }
      if (resultData.supplyList) {
        this._supplyLists.push({ ...resultData.supplyList, source: SupplyListSource.TEKLA });
      }
      this.formInputFileGroup.get("fileValue").patchValue(teklaFile.name);
      this._snackBar.open(snackBarTitle, "Le fichier Tekla a bien été importé", "success", 5000);
    }, error => {
      this._snackBar.openSnackBarError(snackBarTitle, "Erreur lors de l'upload du fichier", error);
    });
  }

  protected save(data: IProject): void {
    const objData = {
      confirm: true,
      data
    };
    this.afterClosed.next(objData);
  }

  public confirmModal(): void {
    if ( this._formGroup.valid ) {
      switch (this._data.closeAction) {
        case MODAL_CLOSE_ACTION.SAVE:
          this.saveNewProject();
          break;
        case MODAL_CLOSE_ACTION.UPDATE:
          this.updateProject();
          break;
        default:
          console.error("Modal called outside of scope");
      }
    } else {
        this.markFormGroupTouched(this._formGroup);
        this.displayErrorMessage();
    }
  }

  /**
   * @description Send request to save new Project. Closes the modal if successfull, displays error if not
   * @author Quentin Wolfs
   * @private
   * @memberof ModalAddProjectComponent
   */
  private saveNewProject(): void {
    this._projectsQueriesSrv.addProject({ ...this._formGroup.value, supplyLists: this._supplyLists }).subscribe(result => {
      const resultData: any = result.data;
      if (resultData && resultData.createProject) {
        this._snackBar.open(this._data.title, "Le projet a été créé", "success", 5000);

        // Save was successfull, closing the modal with saved data
        this.save(resultData.createProject);
        this._modalService.closeModal();
      } else {
        this._snackBar.openSnackBarError(this._data.title, "Le projet n'a pas été créé");
      }
    }, error => {
      this._snackBar.openSnackBarError(this._data.title, "Le projet n'a pas été créé", error);
    });
  }

  /**
   * @description Send request to update existing Project. Closes the modal if successfull, displays error if not
   * @author Quentin Wolfs
   * @private
   * @memberof ModalAddProjectComponent
   */
  private updateProject(): void {
    const snackBarTitle: string = "Modification d'un projet";
    this._projectsQueriesSrv.updateProject(this._data.data.id, this._formGroup.value).subscribe(result => {
      const resultData: any = result.data;
      if (resultData && resultData.updateProject) {
        this._snackBar.open(snackBarTitle, "Le projet a été mis à jour", "success", 5000);

        // Save was successfull, closing the modal with saved data
        this.save(resultData.updateProject);
        this._modalService.closeModal();
      }
    }, error => {
      this._snackBar.openSnackBarError(snackBarTitle, "Le projet n'a pas été mis à jour", error);
    });
  }
}
