import { Component, OnInit, Input, OnDestroy, Output, EventEmitter } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { Subscription } from "rxjs";

@Component({
  selector: "app-tree-checkbox-item",
  templateUrl: "./tree-checkbox-item.component.html",
  styleUrls: ["./tree-checkbox-item.component.css"]
})
export class TreeCheckboxItemComponent implements OnInit, OnDestroy {
  private _valueChangesSubscription: Subscription;

  @Input() public formGroupData: FormGroup;
  @Input() public isActif: boolean = true;
  @Input() public labelName: string = "name";
  @Input() public childName: string = "children";
  @Output() public changeSelected: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit() {
    this._valueChangesSubscription = this.formGroupData.valueChanges.subscribe( formValues => {
      this.changeSelected.emit(formValues.selected);
    });
  }

  ngOnDestroy(): void {
    this._valueChangesSubscription.unsubscribe();
  }


}
