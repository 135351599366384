import { Component, OnInit, Input, Renderer2, ElementRef, ViewChild, OnChanges, SimpleChanges, Output, EventEmitter } from "@angular/core";
import { IContainerCardsItemConfig, ICardAction } from "app/facade/interfaces/container-cards-config.interface";
import { CARD_ACTION_TYPE } from "app/facade/enums/card-action-type.enum";

@Component({
  selector: "app-item-card",
  templateUrl: "./item-card.component.html",
  styleUrls: ["./item-card.component.css"],
})
export class ItemCardComponent implements OnInit, OnChanges {

  public _CARD_ACTION_TYPE = CARD_ACTION_TYPE;
  public dropDownOpen: boolean = false;
  private handler: any;

  @Input() public datas: any = null;
  @Input() public configs: IContainerCardsItemConfig = null;
  @ViewChild("dropDownList") dropDownList: ElementRef;
  @Output() onCheckItemCard: EventEmitter<any> = new EventEmitter<any>();
  constructor(
    private renderer: Renderer2,
    private elementRef: ElementRef) {
      this.renderer.addClass(this.elementRef.nativeElement, "item-card");
  }

  ngOnInit() {

  }
  public checkItemCard(event: MouseEvent, data: any) {
      this.onCheckItemCard.emit(data);
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (this.datas.selectedCard) {
      this.renderer.addClass(this.elementRef.nativeElement, "item-card_select");
    } else {
      this.renderer.removeClass(this.elementRef.nativeElement, "item-card_select");
    }
  }

  public clickFavorite(datas) {
    if ( this.configs && this.configs.favorite && this.configs.favorite.favoriteCallback ) {
      this.configs.favorite.favoriteCallback(datas);
    }
  }

  public clickSetting() {
    if (this.dropDownOpen) {
      this.handler();
    } else {
      this.handler = this.renderer.listen(document, "click", event => this.closeSetting(event));
    }
    this.dropDownOpen = !this.dropDownOpen;
  }

  private closeSetting(event) {
    const clickedInside = this.dropDownList.nativeElement.contains(event.target);
    if ( !clickedInside ) {
      this.handler();
      this.dropDownOpen = false;
    }
  }

  public dropDownActionClick(dropDownAction: ICardAction) {
    this.handler();
    this.dropDownOpen = false;
    dropDownAction.fct(this.datas);
  }

}
