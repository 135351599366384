import { Component, OnInit, Input, Output, EventEmitter, Renderer2 } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { ITableFormConfigEditable, ITableFormActions } from "app/facade/interfaces/tables.interface";

@Component({
  selector: "app-supply-table-form-item",
  templateUrl: "./supply-table-form-item.component.html",
  styleUrls: ["./supply-table-form-item.component.css"]
})
export class SupplyTableFormItemComponent implements OnInit {
  @Input() public editableInputs: ITableFormConfigEditable = {
    isAllEditable: true,
    fieldsEditable: []
  };
  @Input() public tableRowClass: string = "table-row";
  @Input() public formData: FormGroup;
  @Input() public configs: any;
  @Input() public rowIndex: string;
  @Input() public isAddingRow: boolean = false;
  @Input() public autofocus: boolean = false;
  @Input() public actionList: ITableFormActions;
  @Input() public rowAdditionnalDatasConfig: any;
  @Output() public onAddingRow: EventEmitter<any> = new EventEmitter<any>();
  @Output() public onDeletingRow: EventEmitter<any> = new EventEmitter<any>();
  public printConfirmDelete: boolean = false;
  public confirmDeleteClass: string = "isConfirmDelete";
  private handler: any;

  constructor(private _renderer: Renderer2) { }

  ngOnInit() {
    
    
  }

  public addRow() {
    
    if (this.formData.valid) {
      const rowId: string = this.rowIndex.toString().split("_")[1];
      this.onAddingRow.emit({data: this.formData, rowId: rowId ? rowId : this.rowIndex});
      
    } else {
      Object.keys(this.formData.controls).forEach( control => {
        this.formData.get(control).patchValue(this.formData.get(control).value);
      });
    }
  }

  private checkCancelAction(event) {
    if (event.target.className.indexOf(this.confirmDeleteClass) === -1 && this.printConfirmDelete) {
      this.changePrintConfirmDelete(false);
    }
  }

  public changePrintConfirmDelete(value: boolean = true) {
    if ( this.handler ) { this.handler(); }
    if (value) {
      this.handler = this._renderer.listen(document, "click", event => this.checkCancelAction(event));
      setTimeout( () => this.printConfirmDelete = value , 100);
    } else {
      this.printConfirmDelete = value;
    }
  }

  public deleteRow() {
    this.handler();
    this.onDeletingRow.emit({ rowId: this.rowIndex.split("_")[1]});
  }

  public submitOnPressEnter() {
    if (this.isAddingRow) {
      this.addRow();
    }
  }

}
