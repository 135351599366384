/*
 * @Author: Kevin Palade
 * @Date: 2018-09-08 16:54:22
 * @Last Modified by: Kevin Palade
 * @Last Modified time: 2018-09-18 14:40:45
 */
import { Inject } from "@angular/core";
import { ModalService, DATA } from "../services/modal.service";
import { ModalContent } from "./modal-content";
import { FormBuilder, FormGroup, FormControl } from "@angular/forms";
import { SnackService } from "../../snack-bar/services/snack.service";

export abstract class ModalContentForm extends ModalContent {
    protected _formGroup: FormGroup;

    constructor(protected _modalService: ModalService,
                protected _fb: FormBuilder,
                protected _snackBar: SnackService) {
        super(_modalService);
    }

    /**
     * @description Confirm the modal if formGroup is valid
     * @author Kevin Palade
     * @memberof ModalContentForm
     */
    public confirmModal(): void {
        if ( this._formGroup.valid ) {
            this.save();
            this._modalService.closeModal();
        } else {
            this.markFormGroupTouched(this._formGroup);
            this.displayErrorMessage();
        }
    }

    /**
     * @description Cancel the modal
     * @author Kevin Palade
     * @memberof ModalContentForm
     */
    public cancelModal(): void {
        this._modalService.closeModal();
    }

    /**
     * @description update validity and mark as touched each control
     * @author Kevin Palade
     * @protected
     * @param {FormGroup} formGroup
     * @memberof ModalContentForm
     */
    protected markFormGroupTouched(formGroup: FormGroup) {
        (<any>Object).values(formGroup.controls).forEach(control => {
            (<FormControl>control).updateValueAndValidity();
            control.markAsTouched();
            if (control.controls) { control.controls.forEach(c => this.markFormGroupTouched(c)); }
        });
    }

    protected abstract save(data?: any): void;

    protected displayErrorMessage() {
        this._snackBar.openSnackBarError("Erreur", "Le formulaire n'est pas rempli correctement");
    }



}