import { Component, OnInit, Input, Renderer2, EventEmitter, Output, SimpleChanges, OnChanges, AfterViewInit, ViewChild, ElementRef } from "@angular/core";
import { Subject } from "rxjs";
import { debounceTime } from "rxjs/operators";
import { ISelectOption } from "../select-input/selectOptions";
import { AInputs } from "../inputs";
import { IClickOutsideComponent } from "app/facade/directives/click-outside.directive";

export interface SearchCreateOption {
  label: string;
  callback: (...any) => any;
  params?: any;
}
@Component({
  selector: "app-select-search-input",
  templateUrl: "./select-search-input.component.html",
  styleUrls: ["./select-search-input.component.css"]
})
export class SelectSearchInputComponent extends AInputs implements OnInit, OnChanges, IClickOutsideComponent, AfterViewInit {
  public valueSelect;
  public isClosed: boolean = true;
  private debouncer: Subject<any> = new Subject();
  private _isClear: boolean = false;

  @Input() selectName: string;
  @Input() options: ISelectOption[];
  @Input() canCreate: boolean = true;
  @Input() searchCreateOptions: SearchCreateOption;
  @Input() autoSelect: boolean = false;

  @ViewChild("inputFocus") inputFocus: ElementRef;
  @Output("search") public search: EventEmitter<string> = new EventEmitter<string>();

  constructor(protected renderer: Renderer2) {
    super(renderer);
    // Send This.search for the parent
    this.debouncer.pipe(debounceTime(200)).subscribe( value => {
      this.search.emit(value);
    });
   }

  ngOnInit() {
    if (this.options && this.options.length == 1) {
      this.valueSelect = this.options[0].label;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);
    if (this.options.length == 1 && this.autoSelect ) {
      this.autoSelect = false;
      this.close(this.options[0]);
      this.inputFocus.nativeElement.blur();
    }
    if (this._isClear) {
      this._isClear = false;
      this.inputFocus.nativeElement.focus();
    }
    if (changes.group && !changes.group.firstChange) {
      this.valueSelect = "";
      this.search.emit(this.valueSelect);
    }
  }

  ngAfterViewInit() {
    if (this.autofocus) {
      setTimeout(() => {
        this.open();
        this.inputFocus.nativeElement.focus();
      }, 400);
    }
  }

  public showInfo() {
    if (!this.isClosed) { this.close(null); }
  }

  /**
   * @description
   * @author Rousseau Corentin
   * @memberof SelectSearchInputComponent
   */
  public open() {
    this.inputReference.nativeElement.focus();
    this.isClosed = false;
  }


  /**
   * @description
   * @author Rousseau Corentin
   * @param {*} option
   * @memberof SelectSearchInputComponent
   */
  public close(option: any): void {
    if (option) {
      this.group.get(this.name).patchValue(option.value);
      this.group.get(this.name).markAsTouched();
      this.valueSelect = option.label;
      this.debouncer.next(this.valueSelect);
    }
    this.isClosed = true;
  }

  /**
   * @description clear Input
   * @author Rousseau Corentin
   * @param {Event} event
   * @memberof SelectSearchInputComponent
   */
  clearSelect(event: Event) {
    this._isClear = true;
    if (event) { event.stopPropagation(); }
    this.group.get(this.name).patchValue(null);
    this.valueSelect = "";
    this.search.emit(this.valueSelect);
    this.open();
  }

  /**
   * @description Event when ngmodel is update
   * @author Rousseau Corentin
   * @param {*} event
   * @memberof SelectSearchInputComponent
   */
  public findInput(event) {
    this.debouncer.next(this.valueSelect); // Send value for send "this.search" before X seconds
  }

}
