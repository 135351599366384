import { OnInit, Inject, ElementRef, ViewChild, Renderer2, OnDestroy } from "@angular/core";
import { CONTAINER_DATA, StepFormService, ActualData } from "app/presentationnal/organisms/wizard-modal/service/step-form.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { WeldingFormulaDisplayer } from "app/facade/quote-element-definition/formula.display";
import { Subscription } from "rxjs";

export abstract class StepWeldingBase implements OnInit, OnDestroy {
  @ViewChild("popOverContent") public popOverContent: ElementRef;
  @ViewChild("popOver") public popOver: ElementRef;
  public stepWeldingForm: FormGroup;
  public dataEmbase;
  public imgPath;
  public useClass;
  public popover = false;
  protected actualData: ActualData;
  protected actualWeldingData: any;
  public _dataTicket: {element: any, children: any[]};

  private _formulaDisplayer: WeldingFormulaDisplayer;
  private _formulaSub: Subscription;
  private _weldingSectionSubscription: Subscription;
  public calculs: string[] = [];

  constructor(@Inject(CONTAINER_DATA) data: any,
              protected _stepFormSrv: StepFormService,
              protected renderer: Renderer2,
              protected _fb: FormBuilder) {
                this.useClass = data.useClass;
                this.imgPath = "../../../../../../../../assets/img/wizard/" + this.useClass.toLowerCase() + "/step_three.jpg";
                this.actualData = this._stepFormSrv.get();
                this.actualWeldingData = this.actualData.custom.AdditionalComputings.welding.origin;
                this._dataTicket = data.ticket[this.actualData.index];
              }

  ngOnInit() {
    this.stepWeldingForm = this._fb.group(this.generateFormContent());
    this._weldingSectionSubscription = this.stepWeldingForm.get("WeldingSection").valueChanges.subscribe(weldingSection => {
      delete this.actualWeldingData.properties.passes;
      this.actualWeldingData.WeldingSection = weldingSection;
      this.stepWeldingForm.get("Passes").patchValue(this.actualWeldingData.Passes);
    });

    this._formulaDisplayer = new WeldingFormulaDisplayer();
    this._formulaSub = this._formulaDisplayer.$weldingPrice.subscribe(calculs => this.calculs = calculs);
    this.showCalcul();
  }

  ngOnDestroy() {
    this._formulaSub.unsubscribe();
    this._weldingSectionSubscription.unsubscribe();
  }


  protected generateFormContent(): any {
    const meterForWeldingWithoutPercentage = this.actualWeldingData.MeterForWelding / (this.actualWeldingData.WeldingPercentage / 100);
    return {
      MeterForWelding: [meterForWeldingWithoutPercentage, Validators.required],
      WeldingSection: this.actualWeldingData.WeldingSection,
      WeldingPercentage: this.actualWeldingData.WeldingPercentage,
      Passes: [this.actualWeldingData.Passes, Validators.required],
      PriceByM: [this.actualWeldingData.PriceByM, Validators.required],
      UnitaryAdditionalCost: this.actualWeldingData.UnitaryAdditionalCost,
      GlobalAdditionalCost: this.actualWeldingData.GlobalAdditionalCost,
      Remarks: this.actualWeldingData.Remarks
    };
  }

  public stepChange() {
    Object.keys(this.stepWeldingForm.controls).forEach(key => {
      if (this.stepWeldingForm.get(key).touched && this.stepWeldingForm.get(key).valid) {
        this.actualData.custom.AdditionalComputings.welding.origin[key] = this.stepWeldingForm.get(key).value;
      }
    });

    this._stepFormSrv.change({ custom: this.actualData.custom });
  }

  public next(fastSave: boolean = false) {
    if (this.stepWeldingForm && this.stepWeldingForm.valid) {
      this.stepChange();
      this._stepFormSrv.updateElement(this.actualData.custom, null, this.actualData.index, this.actualData.childrenIndex);
      if (fastSave) {
        this._stepFormSrv.closeModal$.next(true);
      } else {
        this._stepFormSrv.navigate$.next(true);
      }
    } else {
      this._stepFormSrv.markFormGroupTouched(this.stepWeldingForm);
    }
  }

  public previous() {
    this.stepChange();
    this._stepFormSrv.navigate$.next(false);
  }

  public cancel() {
    if (!this.actualData.isEdited) {
      this._stepFormSrv.deleteElement(this.actualData.index, this.actualData.childrenIndex);
    }
    this._stepFormSrv.closeModal$.next(true);
  }

  public showCalcul() {
    this.stepWeldingForm.valueChanges.subscribe(values => {

      const base = {
        meterForWelding: values.MeterForWelding || null,
        passes: values.Passes || null,
        priceByM: values.PriceByM || null,
        percentage: values.WeldingPercentage || null,
        element1Thickness: this.actualWeldingData._element1.thickness,
        element2Thickness: this.actualWeldingData._element2.thickness,
        section: values.WeldingSection || 0
      };

      if (Object.keys(base).every(k => base[k] !== undefined && base[k] !== null)) {
        this._formulaDisplayer.base = base;
      }
    });

    const init = {
      meterForWelding: this.stepWeldingForm.value.MeterForWelding || null,
      passes: this.stepWeldingForm.value.Passes || null,
      priceByM: this.stepWeldingForm.value.PriceByM || null,
      percentage: this.stepWeldingForm.value.WeldingPercentage || null,
      element1Thickness: this.actualWeldingData._element1.thickness,
      element2Thickness: this.actualWeldingData._element2.thickness,
      section: this.stepWeldingForm.value.WeldingSection || 0
    };

    if (Object.keys(init).every(k => init[k] !== undefined && init[k] !== null)) {
      this._formulaDisplayer.base = init;
    }
  }

  public mouseOver(event) {
    const el = this.popOver.nativeElement;
    const content = this.popOverContent.nativeElement;
    // Define the position
    const posXa = el.getBoundingClientRect().right;
    const posXb = el.getBoundingClientRect().left;
    const posX = ((posXa - posXb) / 2) + posXb;
    const posY = el.getBoundingClientRect().bottom;
    // Set the position
    content.style.left = `${posX}px`;
    content.style.top = `${posY}px`;

    this.renderer.addClass(el, "popover_active");
  }

  public mouseOut(event) {
    this.renderer.removeClass(this.popOver.nativeElement, "popover_active");
  }
}
