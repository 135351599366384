import { Component, OnInit, OnDestroy, Inject } from "@angular/core";
import { ModalContentForm } from "../../model/modal-content-form";
import { ModalService, DATA } from "../../services/modal.service";
import { SnackService } from "app/presentationnal/organisms/snack-bar/services/snack.service";
import { FormBuilder } from "@angular/forms";
import { Subscription } from "rxjs";
import { SupplyListStatus } from "app/facade/enums/supply-list-status.enum";
import { ISupplyListForPriceRequestCards, ISupplyList } from "app/facade/interfaces/project";
import { ITableSelection, IFiltrerDatas } from "app/facade/interfaces/table-datas-definition";
import * as moment from "moment";
import { PriceRequestsQueriesService } from "app/facade/queries/price-request/price-requests-queries.service";
import { OPTION_LABELS } from "app/facade/configs/price-request.config";
import { ApplicationConfig } from "app/app.config";
import { IModalData } from "../../interfaces/modal-data.interface";
import { IPriceRequest } from "app/facade/interfaces/price-request.interface";

interface ISupplyListSelectionTemp extends ITableSelection {
  tableDatas: ISupplyListForPriceRequestCards[];
}

@Component({
  selector: "app-modal-link-supply-list",
  templateUrl: "./modal-link-supply-list.component.html",
  styleUrls: ["./modal-link-supply-list.component.css"]
})

export class ModalLinkSupplyListComponent extends ModalContentForm implements OnInit, OnDestroy {
  private _supplyListsSub: Subscription;
  private _supplyListInPriceRequestSub: Subscription;
  public supplyListDatas: ISupplyListSelectionTemp;
  public filtredDataForList: IFiltrerDatas;
  private priceRequestData: IPriceRequest;

  constructor(
    private _priceRequestsQueriesSrv: PriceRequestsQueriesService,
    protected _modalService: ModalService,
    protected _snackBar: SnackService,
    protected _fb: FormBuilder,
    @Inject(DATA) private _data: IModalData) {
      super(_modalService, _fb, _snackBar);
      this.priceRequestData = this._data && this._data.data ? this._data.data : null;
    }

  ngOnInit() {
    this.getSupplyListsUnused();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this._supplyListsSub.unsubscribe();
  }

  private getSupplyListsUnused() {
    this._supplyListsSub = this._priceRequestsQueriesSrv.getSupplyListsInPriceRequest({status: SupplyListStatus.OPEN }).subscribe( result => {
      this.supplyListDatas = {
        tableHeaders: ["", "Projet", "Description", "Categories", "Matières", "Options", "Model", "En1090", "Date de livraison", "Date de création"],
        checkboxDatas: [],
        tableDatas: [],
        tableDatasDefinition: [
          { key: "projectReference", isTitle: true, clickAction: this.goToProjectDetail},
          { key: "description", isTitle: true},
          { key: "categories", isTitle: false},
          { key: "matters", isTitle: false},
          { key: "options", isTitle: false},
          { key: "model", isTitle: false},
          { key: "isEn1090", isTitle: false, isCheckBox: true },
          { key: "deliveryDate", isTitle: false},
          { key: "createdAt", isTitle: false},
        ]
      };
      const data: {supplyLists: ISupplyList[]} = <any>result.data;
      if (data && data.supplyLists) {
        data.supplyLists.forEach( supplyList => {
          this.supplyListDatas.tableDatas.push({
            id: supplyList.id,
            projectReference: supplyList.project.reference,
            projectId: supplyList.project.id,
            description: supplyList.description,
            model: supplyList.model,
            isEn1090: supplyList.project.isEn1090,
            deliveryDate: supplyList.deliveryDate ? moment.unix(supplyList.deliveryDate).format("DD/MM/YYYY") : supplyList.deliveryDate,
            createdAt: supplyList.createdAt ? moment.unix(supplyList.createdAt).format("DD/MM/YYYY HH:mm") : supplyList.createdAt,
            categories: supplyList.parentSupplyCategories.map( cat => cat.name).join(" - "),
            matters: supplyList.infos.matterRefs.join(" - "),
            options: this.getOptionsLabel((<any>supplyList.infos.options))
          });
          this.supplyListDatas.checkboxDatas.push({
            key: "selected",
            formValue: this._fb.group({
              supplyListId: supplyList.id,
              selected: false
            })
          });
        });
        this.filtredDataForList = {
          tableDatas : this.supplyListDatas.tableDatas,
          checkboxDatas : this.supplyListDatas.checkboxDatas,
        };
      }
    }, error => {
      console.log("ERROR LOADING SUPPLY LISTS", {error});
    });
  }

  private goToProjectDetail = (datas: ISupplyListForPriceRequestCards) => {
    window.open(`${ApplicationConfig.getDomain()}/project/edit/${datas.projectId}`);
  }

  private getOptionsLabel(options: {isBlack: boolean, isBlasted: boolean, isPrimaryBlasted: boolean}): string {
    let returnedOptions: string = "";
    returnedOptions += options.isBlack ? `${OPTION_LABELS.BLACK}` : "";
    returnedOptions += options.isBlasted ? `${returnedOptions === "" ? "" : " - "}${OPTION_LABELS.BLASTED}` : "";
    returnedOptions += options.isPrimaryBlasted ? `${returnedOptions === "" ? "" : " - "}${OPTION_LABELS.PRIMARY_BLASTED}` : "";
    return returnedOptions;
  }

  public filterSearch(event: string) {
    if (event !== "") {
      this.filtredDataForList.tableDatas = this.supplyListDatas.tableDatas.filter( sLData => {
        return ["description", "model", "projectReference", "deliveryDate", "createdAt"]
          .some(key => sLData[key] ? sLData[key].toLowerCase().search(event.toLowerCase()) !== -1 : false);
      });
      const selectedSupplyListsIds: number[] = this.filtredDataForList.tableDatas.map( sLData => sLData.id);
      this.filtredDataForList.checkboxDatas = this.supplyListDatas.checkboxDatas.filter( cBData => {
        return selectedSupplyListsIds.indexOf(cBData.formValue.value.supplyListId) !== -1;
      });
    } else {
      this.filtredDataForList = {
        tableDatas : this.supplyListDatas.tableDatas,
        checkboxDatas : this.supplyListDatas.checkboxDatas,
      };
    }
  }

  public confirmModal(): void {
    const snackBarTitle: string = "Ajout de liste d'approvisionnement";
    if ( this.supplyListDatas.checkboxDatas.filter( data => data.formValue.value.selected ).length !== 0 ) {
      this.linkSupplyLists(this.priceRequestData.id);
    } else {
      this._snackBar.openSnackBarError(snackBarTitle, "Veuilliez sélectionner au moins une liste d'approvisionnement");
    }
  }

  protected save(data: boolean): void {
    const objData = {
      confirm: true,
      data
    };
    this.afterClosed.next(objData);
  }

  /**
   * @description Send request to link multiples Supply Lists to a Price Request. Closes the modal if successfull, displays error if not
   * @author Quentin Wolfs
   * @private
   * @param {number} priceRequestId
   * @memberof ModalLinkSupplyListComponent
   */
  private linkSupplyLists(priceRequestId: number): void {
    const snackBarTitle: string = "Ajout de liste d'approvisionnement";
    const selectedSupplyListIds: number[] = this.supplyListDatas.checkboxDatas
      .filter(data => data.formValue.value.selected)
      .map(data => data.formValue.value.supplyListId);

    this._priceRequestsQueriesSrv.assignSupplyLists(priceRequestId, selectedSupplyListIds, { priceRequestId: +priceRequestId }).subscribe(result => {
      const data: any = result.data;
      if (data && data.assignSupplyListsToPriceRequest) {
        this._snackBar.open(snackBarTitle, "Les listes d'approvisionnement ont été assignées", "success", 5000);

        // Link was successfull, closing the modal with saved data
        this.save(data.assignSupplyListsToPriceRequest);
        this._modalService.closeModal();
      } else {
        this._snackBar.openSnackBarError(snackBarTitle, "Les listes d'approvisionnement n'ont pas été assignées");
      }
    }, error => {
      this._snackBar.openSnackBarError(snackBarTitle, "Les listes d'approvisionnement n'ont pas été assignées", error);
    });
  }
}
