import { Component, OnInit, Inject } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { CONTAINER_DATA, StepFormService, ActualData } from "../../../service/step-form.service";
import { ELEMENTS_MAP } from "app/facade/quote-element-definition/use-class-map";
import { StandardElementService} from "app/facade/quote-element-definition/elements/standard/element.service";
@Component({
  selector: "app-element-summary-modal",
  templateUrl: "./element-summary-modal.component.html",
  styleUrls: ["./element-summary-modal.component.css"]
})
export class ElementSummaryModalComponent implements OnInit {
  public _actualData: ActualData;
  public ticket;
  public elementGr = ELEMENTS_MAP;
  public summaryTitle: string[] = ["Profil", "Dimension", "Suppléments"];
  public summary: any[] = [];
  public summaryAmount: any[] = [];

  constructor(@Inject(CONTAINER_DATA) data: any,
    private _stepFormSrv: StepFormService,
    protected _fb: FormBuilder) {
      this._actualData = this._stepFormSrv.get();
      this.ticket = data.ticket;
  }

  ngOnInit() {
    this.formatSummary();
  }

  public previous() {
    if (!this._actualData.isEdited && this._actualData.index !== undefined) {
      this._stepFormSrv.deleteElement(this._actualData.index);
    }
    this._stepFormSrv.navigate$.next(false);
  }

  public cancel() {
    if (!this._actualData.isEdited && this._actualData.index !== undefined) {
      this._stepFormSrv.deleteElement(this._actualData.index);
    }
    this._stepFormSrv.closeModal$.next(true);
  }

  public valid() {
    this._stepFormSrv.updateElement(this._actualData.custom, null, this._actualData.index);
    this._stepFormSrv.closeModal$.next(true);
  }

  public formatSummary() {
    this.summary.push([
      { name: "Élément", value: this._actualData.elementGroup.name, suffix: "" },
      { name: "Matière", value: this._actualData.matter.en1090Name, suffix: "" },
      { name: "Quantité", value: this._actualData.element.Quantity, suffix: "" },
      { name: "Type", value: this._actualData.element.reference, suffix: "" },
      { name: "Dénomination", value: this._actualData.element.CustomName, suffix: "" }
    ]);
    this.summary.push([
      { name: "Longueur", value: this._actualData.element.properties.length, suffix: "mm" },
      { name: "Prix de la matière", value: this._actualData.element.properties.matter.pricePerKg, suffix: "€/kg" },
      { name: "Coût additionnel unitaire", value: this._actualData.element.UnitaryAdditionalCost, suffix: "€" },
      { name: "Coût additionnel global", value: this._actualData.element.GlobalAdditionalCost, suffix: "€" },
      { name: "Informations complémentaires", value: this._actualData.element.Remarks, suffix: "" , split: true},
      { name: "Aire totale", value: this._actualData.element.TotalArea, suffix: "m³" },
      { name: "Poids total", value: this._actualData.element.TotalWeight, suffix: "kg" },
    ]);

    this.summaryAmount.push({ name: "Prix unitaire", value: this._actualData.element.Price, suffix: "€" });
    this.summaryAmount.push({ name: "Prix total", value: this._actualData.element.Price * this._actualData.element.Quantity, suffix: "€" });

  }

}
