/*
 * @Author: Kevin Palade
 * @Date: 2018-09-10 09:35:27
 * @Last Modified by:   Kevin Palade
 * @Last Modified time: 2018-09-10 09:35:27
 */

export enum MODAL_TYPE {
    CONFIRM,
    NORMAL
}