import { Component, OnInit, Injector, OnDestroy } from "@angular/core";
// import { GET_CATEGORIES } from "./homepage-queries";
import { Portal, ComponentPortal, PortalInjector } from "@angular/cdk/portal";
import { WizardModalComponent } from "app/presentationnal/organisms/wizard-modal/wizard-modal.component";
import { CONTAINER_DATA, StepFormService, ActualData, ITicketElement } from "app/presentationnal/organisms/wizard-modal/service/step-form.service";
import { Subscription } from "rxjs";
import { CatalogQueriesService } from "app/facade/queries/catalog/async/catalog_queries.service";
import { QuotesQueriesService } from "app/facade/queries/quote/async/quotes_queries.service";
import { Quote } from "app/facade/interfaces";
import { ActivatedRoute } from "@angular/router";
import { QuoteElementBase } from "app/facade/quote-element-definition/base";

@Component({
  selector: "app-homepage",
  templateUrl: "./homepage.component.html",
  styleUrls: ["./homepage.component.css"]
})
export class HomepageComponent implements OnInit, OnDestroy {
  public quoting: Quote;
  public categories = [];
  public type = ["Element", "Custom", "Operation", "Finition", "Option"];
  private customReference: any[];
  public customCards = [
    { id: "1", name: "Embase", icon: "embase", reference: "Embase", imgPath: "../../../../assets/img/wizard/menu/embase.jpg" },
    { id: "2", name: "Equerre", icon: "equerre", reference: "Equerre", imgPath: "../../../../assets/img/wizard/menu/equerre.png" },
    { id: "3", name: "Gousset", icon: "gousset", reference: "Gousset", imgPath: "../../../../assets/img/wizard/menu/gousset.jpg" },
    { id: "4", name: "Nervure", icon: "nervure", reference: "Nervure", imgPath: "../../../../assets/img/wizard/menu/nervure.jpg" },
    { id: "5", name: "Plaque", icon: "plaque", reference: "Plaque", imgPath: "../../../../assets/img/wizard/menu/plaque.jpg" },
    { id: "6", name: "Raidisseur", icon: "raidisseur", reference: "Raidisseur", imgPath: "../../../../assets/img/wizard/menu/raidisseur.jpg" },
  ];
  public operations = [
    { id: "1", name: "Sciage", icon: "sawing", reference: "Cutting", imgPath: "../../../../assets/img/wizard/menu/sawing.png" },
    { id: "2", name: "Perçage", icon: "drilling", reference: "Drilling", imgPath: "../../../../assets/img/wizard/menu/drilling.png"  },
  ];
  public assemblage = [
    { id: "1", name: "Soudure", icon: "welding", reference: "" },
  ];

  public finitionsCards = [
    { id: "1", name: "Sablage", icon: null, reference: "Sandblasting"},
    { id: "2", name: "Métallisation", icon: null, reference: "Metallization" },
    { id: "3", name: "Peinture", icon: null, reference: "Painting"},
    { id: "4", name: "Laquage au four", icon: null, reference: "Lacquering" },
    { id: "5", name: "Galvanisation", icon: null, reference: "Galvanization" },
  ];

  public optionsCards = [
    { id: "1", name: "Montage", icon: null, reference: "Montage"},
    { id: "2", name: "Nacelle", icon: null, reference: "Nacelle" },
    { id: "3", name: "Grue", icon: null, reference: "Crane"},
    { id: "4", name: "Etude", icon: null, reference: "Study"},
    { id: "5", name: "Transport", icon: null, reference: "Transport" },
    { id: "6", name: "Marge", icon: null, reference: "Margin" },
  ];

  public ticketSub: Subscription;
  public ticketData: ITicketElement[];
  public printChildrenElement: boolean;
  public hasCategory: boolean = false;
  public cdkWizardModal: Portal<any>;
  constructor(private _injector: Injector,
              private _catalogQueriesSrv: CatalogQueriesService,
              public route: ActivatedRoute,
              private _stepFormSrv: StepFormService,
              private _quoteSrv: QuotesQueriesService) {
                this.generateCustomReference();
  }

  private generateCustomReference() {
    this.customReference = [
      { reference: "Custom", categorie: "customCards", values: this.customCards.map( element => element.reference )},
      { reference: "Operation", categorie: "operations", values: this.operations.map( element => element.reference )},
      { reference: "Finition", categorie: "finitionsCards", values: this.finitionsCards.map( element => element.reference )},
      { reference: "Option", categorie: "optionsCards", values: this.optionsCards.map( element => element.reference )}
    ];
  }

  ngOnInit() {
    this.loadQuote();
    this.listCategories();
    this._stepFormSrv.closeModal$.subscribe(result => {
      if (result) { this.closeModalWizard(true); }
    });

    this.ticketSub = this._stepFormSrv.getUpdateTicket().subscribe(result => {
      this.ticketData = result;
      if (this.ticketData[this._stepFormSrv.getLastElementSelected()]) {
        const lastElement: QuoteElementBase = this.ticketData[this._stepFormSrv.getLastElementSelected()].element;
        this.printChildrenElement = lastElement.Type === "STANDARD";
      } else {
        this.printChildrenElement = false;
      }
    });
  }

  ngOnDestroy(): void {
    this.ticketSub.unsubscribe();
  }

  /**
   * @description
   * @author Rousseau Corentin
   * @date 2018-11-05
   * @returns
   * @memberof HomepageComponent
   */
  public listCategories() {
    this._catalogQueriesSrv.listCategoriesWithChildren().subscribe(result => {
      this.categories = result.data["categories"];
      this.categories = this.categories.map( cat => {
        const imgPath: string = `../../../../assets/img/wizard/menu/${cat.icon}.jpg`;
        return {
          ...cat,
          imgPath
        };
      });
      // TODO : Remove the line from below when "'Tôle'" needs to be reinserted
      this.categories = this.categories.filter(cat => cat.name != "Tôle");
    });
  }

  public openModalWizard(wizardName: string, id: number, type: string, actualData?: ActualData) {
    this.cdkWizardModal = new ComponentPortal(WizardModalComponent, null, this.createInjector({
      categoryId: id,
      name: wizardName,
      type: type,
      ticket: this.ticketData ? this.ticketData : null,
      actualData
    }));
  }

  private createInjector(dataToPass): PortalInjector {
    const injectorTokens = new WeakMap();
    injectorTokens.set(CONTAINER_DATA, dataToPass);
    return new PortalInjector(this._injector, injectorTokens);
  }

  public closeModalWizard(event) {
    if (event && this.cdkWizardModal && this.cdkWizardModal.isAttached) {
      this._stepFormSrv.renitializeData();
      this.cdkWizardModal.detach();
    }
  }

  public loadQuote() {
   const idQuote = this.route.snapshot.paramMap.get("id");
    this._quoteSrv.getQuote(idQuote).subscribe(quote => {
      this.quoting = quote.data["quote"];
      const omitTypename = (key, value) => (key === "__typename" ? undefined : value);
      const newPayload = JSON.parse(JSON.stringify(this.quoting), omitTypename);
      this._stepFormSrv.initializeTicket(newPayload);
    });
  }

  public onOpenEditWizard($event: {actualData: ActualData, elementClass: string}) {
    const custom = this.customReference.find( element => {
      return element.values.indexOf($event.elementClass) !== -1;
    });
    const wizardType = custom ? custom.reference : "Element";

    let categorie: any;
    if (custom) {
      categorie = this[custom.categorie].find( cat => {
        return cat.reference === $event.elementClass;
      });
    } else {
      categorie = this.categories.find( cat => {
        const childNames: string[] = cat.childrenElementGroups.map( child => {
          return child.useClass;
        });
        return childNames.indexOf($event.elementClass) !== -1;
      });
    }
    if (categorie) {
      this.openModalWizard(categorie.name, categorie.id, wizardType, $event.actualData);
    }
  }

}
