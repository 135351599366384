import { ISupplyList, ISupplyListElement } from "./project";
import { IAvailableMatters, ISupplyCategory, IElements, ISupplier, ISupplierContact } from "./supplier";
import { ISupplierOffer, ISupplierOfferElement, ISupplierOfferAdditionnalCost, ISupplierOfferElementOption } from "./supplier-offer";
import { IUser } from "./user.interface";
import { IBarsetGeneration } from "./barset.interface";
import { StaticInjector } from "@angular/core/src/di/injector";

export enum EnumPriceRequestStatus {
  CREATED = "CREATED",
  SENT = "SENT",
  ORDERED = "ORDERED",
  PARTIALLY = "PARTIALLY"
}

export enum EnumCustomPriceRequestStatus {
  CREATED = "Créée",
  SENT = "Envoyée",
  ORDERED = "Dans B.C.",
  PARTIALLY = "Part. B.C."
}

export interface IPriceRequest {
  id: number;
  reference: string;
  remark: string;
  status: EnumPriceRequestStatus;
  isValidated: boolean;
  isDone: boolean;
  createdAt: number;
  userId: number;
  barsetGeneration: IBarsetGeneration;
  supplyLists: ISupplyList[];
  elements: IPriceRequestElement[];
  supplierOffers: ISupplierOffer[];
  user: IUser;
  additionnalCosts: IPriceRequestAdditionnalCost[];
  internalRemark : string;
}

export interface IAmalgamGenerationResult {
  amalgams: IAmalgam[];
  barsetGeneration: IBarsetGeneration;
}

export interface IPriceRequestFilter {
  deleted?: boolean;
  search?: string;
}

export interface IPriceRequestInput {
  reference: string;
  remark: string;
  internalRemark: string;
}

export interface IPriceRequestUpdate {
  remark: string;
  internalRemark: string;
  isValidated: boolean;
  status: string;
}

export interface IPriceRequestElementUpdate {
  id?: number;
  remark?: string;
  weight: number;
  internalRemark?: string;
  unit?: string;
}

export interface IAmalgam {
  id: number;
  loss: number;
  isLocked: boolean;
  isInStock: boolean;
  stockPosition: string;
  amalgamGroupId?: number;
  amalgamGroup?: IAmalgamGroup;
  parts: IAmalgamPart[];
}

export interface IAmalgamGroupData {
  reference: string;
  isEn1090: boolean;
  isBlack: boolean;
  isBlasted: boolean;
  isPrimaryBlasted: boolean;
  matterRef: string;
  matterId: number;
}

export interface IAmalgamGroup extends IAmalgamGroupData {
  id: number;
  format: number;
  isCut: boolean;
  icon: string;
  remark: string;
  isManual?: boolean;
  matter?: IAvailableMatters;
  supplyCategoryId: number;
  supplyCategory?: ISupplyCategory;
  elementId: number;
  element?: IElements;
  amalgams?: IAmalgam[];
}

export interface IAmalgamPart {
  id: number;
  amalgamId?: number;
  amalgam?: IAmalgam;
  supplyListElementId: number;
  supplyListElement?: ISupplyListElement;
}

export interface IAmalgamFilter {
  priceRequestId: number;
}

export interface IAmalgamInputData {
  isLocked: boolean;
  reference: string;
  matterRef: string;
  matterId?: number;
  elementId?: number;
  isEn1090: boolean;
  isBlack: boolean;
  isBlasted: boolean;
  isPrimaryBlasted: boolean;
  supplyCategoryId: number;
  isInStock: boolean;
  stockPosition: string;
}

export interface IAmalgamInput extends IAmalgamInputData {
  id: number;
  format: number;
  loss: number;
  isCut: boolean;
  icon: string;
  priceRequestId?: number;
  matter?: IAvailableMatters;
  priceRequest?: IPriceRequest;
  parts?: IAmalgamPartInput[];
}

export interface IAmalgamPartInput {
  supplyListElementId: number;
  length?: number;
}

export interface IAmalgamParam {
  beams: IAmalgamCategoryParam;
  tubes: IAmalgamCategoryParam;
}

export interface IAmalgamCategoryParam {
  length: number;
  otherLengths: number[];
  isAutoCut: boolean;
  cutThreshold: number;
  maxLoss: number;
}

/* PRICE REQUEST ELEMENT */
export interface IPriceRequestElementFilter {
  priceRequestId: number;
}

export interface IPriceRequestElement {
  id: number;
  remark: string;
  quantity: number;
  weight: number;
  priceRequestId?: number;
  PriceRequest?: IPriceRequest;
  supplyListElementId?: number;
  supplyListElement?: ISupplyListElement;
  amalgamGroupId?: number;
  amalgamGroup?: IAmalgamGroup;
  bestPrice: number;
  bestTime: number;
  stockQuantity: number;
  purchaseOrderQuantity: number;
  hasPrice: boolean;
  supplierOfferElements: ISupplierOfferElement[];
  parentSupplyCategory: ISupplyCategory;
  options: IPriceRequestElementOption[];
  unit: string;
}

export interface IPriceRequestElementOption {
  id: number;
  type: IPriceRequestElementOptionType;
  denomination: string;
  quantity: number;
  unit: IPriceRequestElementOptionUnit;
  bestPrice: number;
  priceRequestElementId: number;
  priceRequestElement: IPriceRequestElement;
  supplierOfferElementOptions: ISupplierOfferElementOption[];
}

export interface IPriceRequestElementOptionInput {
  type: IPriceRequestElementOptionType;
  denomination: string;
  quantity: number;
  unit: IPriceRequestElementOptionUnit;
  priceRequestElementIds: number[];
}


export interface IPriceRequestElementOptionUpdate {
  type: IPriceRequestElementOptionType;
  denomination: string;
  quantity: number;
  unit: IPriceRequestElementOptionUnit;
}

export interface IPriceRequestAdditionnalCost {
  id: number;
  type: IpriceRequestAdditionnalCostType;
  denomination: string;
  quantity: number;
  unit: IpriceRequestAdditionnalCostUnit;
  bestPrice: number;
  priceRequestId: number;
  priceRequest: IPriceRequest;
  supplierOfferAdditionnalCosts: ISupplierOfferAdditionnalCost[];
}

export interface IPriceRequestAdditionnalCostUpdate {
  id?: number;
  type?: IpriceRequestAdditionnalCostType;
  denomination?: string;
  quantity?: number;
  unit?: IpriceRequestAdditionnalCostUnit;
}

export enum IPriceRequestElementOptionType {
  PROCESSING = "PROCESSING",
  CUT = "CUT",
  OTHER = "OTHER"
}

export enum IPriceRequestElementOptionUnit {
  EURO = "EURO",
  EURO_BY_UNIT = "EURO_BY_UNIT",
  EURO_BY_TON = "EURO_BY_TON",
  EURO_BY_METER = "EURO_BY_METER",
  EURO_BY_SQUARE_METER = "EURO_BY_SQUARE_METER"
}

export enum IpriceRequestAdditionnalCostType {
  TRANSPORT_FEE = "TRANSPORT_FEE",
  PACKAGING_FEE = "PACKAGING_FEE",
  CERTIFICATE_FEE = "CERTIFICATE_FEE",
  OTHER = "OTHER"
}

export enum IpriceRequestAdditionnalCostUnit {
  EURO = "EURO",
  EURO_BY_UNIT = "EURO_BY_UNIT"
}