import { Operation, OperationCreate } from "./operation.base";
import { IAction, MM_TO_M } from "../base";
import { Element } from "../elements/element.base";

export interface ILaserCuttable extends Element {
    thickness: number;
    meterForCutting: number;
}

export interface LaserCuttingDefinedProperty {
    PriceByM?: number;
    Prc?: number;
}

export interface LaserCuttingProperty extends LaserCuttingDefinedProperty {
    holeQuantity: number;
    holeDiameter: number;
    meterForCutting?: number;
    priceForBlueprint?: number;
}

export interface LaserCuttingBuild extends IAction<ILaserCuttable, LaserCuttingProperty> {
}

export class LaserCutting extends Operation<ILaserCuttable, LaserCuttingProperty, LaserCuttingDefinedProperty> {

    public constructor(
        element: ILaserCuttable,
        properties: LaserCuttingProperty,
        operationCreate: OperationCreate<ILaserCuttable, LaserCuttingDefinedProperty>) {

        super(element, properties, operationCreate);
    }

    public updateAfterElementChange(): void {
        delete this.properties.meterForCutting;
        // delete this.properties.Prc;
        // delete this.properties.PriceByM;
    }

    public get meterForCutting(): number {
        return this.properties.meterForCutting != null ? this.properties.meterForCutting : this.element.meterForCutting;
    }

    public set meterForCutting(value: number) {
        this.properties.meterForCutting = value;
    }

    public get PriceByM(): number {
        return this.properties.PriceByM != null ? this.properties.PriceByM : this.definedProperties.PriceByM;
    }

    public set PriceByM(value: number) {
        this.properties.PriceByM = value;
    }

    public get Prc(): number {
        return this.properties.Prc != null ? this.properties.Prc : this.definedProperties.Prc;
    }

    public set Prc(value: number) {
        this.properties.Prc = value;
    }

    public get holeDiameter(): number {
        return this.properties.holeDiameter;
    }

    public set holeDiameter(value: number) {
        this.properties.holeDiameter = value;
    }

    public get holeQuantity(): number {
        return this.properties.holeQuantity;
    }

    public set holeQuantity(value: number) {
        this.properties.holeQuantity = value;
    }

    public get PriceForBlueprint(): number {
        return this.properties.priceForBlueprint || 8;
    }

    public get UnitaryPriceForBlueprint(): number {
        return this.PriceForBlueprint / this.TotalQuantity;
    }

    public set PriceForBlueprint(value: number) {
        this.properties.priceForBlueprint = value;
    }

    public get CalculatedPrice(): number {
        return this.Prc + (this.PriceByM * this.meterForCutting * MM_TO_M) + this.UnitaryPriceForBlueprint + this.HoleCutPrice;
    }

    private get HoleCutPrice(): number {
        if (!this.holeQuantity) { return 0; }

        return this.holeQuantity * (this.Prc + ((this.getHolePerimeter() * MM_TO_M) * this.PriceByM));
    }

    private getHolePerimeter(): number {
        return Math.PI * this.holeDiameter;
    }
}