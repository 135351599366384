import gql from "graphql-tag";

export const GET_PERMISSION_USERGROUP = gql`
  query getPermission($userGroup: String!){
    getPermission(userGroup: $userGroup){
      id
      userGroup
      category
      read
      write
      delete
      seePrices
    }
  }
`;

export const GET_PERMISSION = gql`
  query getOnePermission($userGroup: String!, $category: String!){
    getOnePermission(userGroup: $userGroup, category: $category){
      id
      userGroup
      category
      read
      write
      delete
      seePrices
    }
  }
`;

export const UPDATE_PERMISSION = gql`mutation($permission:UpdatePermission!,$id: Int!){
  updatePermission(permission:$permission, id:$id){
    id
    userGroup
    category
    read
    write
    delete
    seePrices
  }
}`
;
