import { Injectable, ErrorHandler, Injector } from "@angular/core";
import { Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { ApplicationConfig } from "app/app.config";
import { CSRF_HEADER_KEY, CSRF_STORAGE_KEY } from "@lib/auth/auth.service";

interface FormattedError {
    url: string;
    message: string;
    stackTrace: string;
}

@Injectable()
export class GlobalErrorHandlerService implements ErrorHandler {

    public constructor(
        private _injector: Injector
    ) { }

    handleError(error: any) {
        // Keep logging in console to ensure any error can be logged somewhere
        console.error(error);

        // Format & send error if possible
        const formatted: FormattedError = this.formatError(error);
        this.sendError(formatted);
    }

    /**
     * @description Format error into sendable format for backend if possible
     * @author Quentin Wolfs
     * @private
     * @param {*} error
     * @returns {FormattedError}
     * @memberof GlobalErrorHandlerService
     */
    private formatError(error: any): FormattedError {
        const url: string = this._injector.get(Router).url;
        if (error && error instanceof Error) {
            return {
                url,
                message: error.message,
                stackTrace: error.stack
            };
        } else {
            // Format of error is unknown, so we don't send anything
            return null;
        }
    }

    /**
     * @description Send formatted error to backend so it's logged in backend's error log if the error could be formatted
     * @author Quentin Wolfs
     * @private
     * @param {FormattedError} error
     * @returns
     * @memberof GlobalErrorHandlerService
     */
    private sendError(error: FormattedError) {
        if (!!error) {
            return this._injector.get(HttpClient).post(
                `${ApplicationConfig.Url}${"/api/error/log"}`,
                error,
                { headers: { [CSRF_HEADER_KEY]: localStorage.getItem(CSRF_STORAGE_KEY) } }
            ).subscribe(res => {
                console.error("Error sent to backlog");
            });
        }
    }
}