import { Component, OnInit, Inject } from "@angular/core";
import { CONTAINER_DATA, StepFormService } from "app/presentationnal/organisms/wizard-modal/service/step-form.service";
import { CatalogQueriesService } from "app/facade/queries/catalog/async/catalog_queries.service";
import { CustomElementService } from "app/facade/quote-element-definition/elements/customs/custom.service";
import { QuotesQueriesService } from "app/facade/queries/quote/async/quotes_queries.service";
import { FormBuilder } from "@angular/forms";
import { StepZeroBase } from "../../base/step-zero-base";
import { ISelectOption } from "app/presentationnal/atoms/inputs/select-input/selectOptions";

@Component({
  selector: "app-embase-step-zero",
  templateUrl: "./embase-step-zero.component.html",
  styleUrls: ["./embase-step-zero.component.css"]
})
export class EmbaseStepZeroComponent extends StepZeroBase implements OnInit {

  public anglesSelected: ISelectOption[] = [];
  public printAnglesSelection: boolean;

  constructor(
    @Inject(CONTAINER_DATA) data: any,
              protected _stepFormSrv: StepFormService,
              protected _catalogQueriesSrv: CatalogQueriesService,
              protected _customElementSrv: CustomElementService,
              protected _quoteQueriesSrv: QuotesQueriesService,
              protected _fb: FormBuilder
              ) {
    super(data, _stepFormSrv, _catalogQueriesSrv, _customElementSrv, _quoteQueriesSrv, _fb);
  }

  ngOnInit() {
    super.ngOnInit();
    this.getCuttingAngle();
  }

  protected initForm() {
    super.initForm();
    this.printAnglesSelection = this.stepZeroForm.get("quantity").value === 1;
    this.stepZeroForm.get("quantity").valueChanges.subscribe( newQuantity => {
      this.printAnglesSelection = newQuantity === 1;
    });
  }

  protected generateFormContent() {
    super.generateFormContent();
    const data: any = this.actualData.custom ? this.actualData.custom : null;
    this.formContent.usedAngle = data && data.usedAngle ? data.usedAngle : this.checkAngleAlreadyUse();
  }

  private checkAngleAlreadyUse(): string {
    const children: any = this.dataTicket.children.find( child => child.constructor.name === "Embase" );
    return (children && children.usedAngle === "angle1") ? "angle2" : "angle1";
  }

  private getCuttingAngle() {
    if ( this.dataTicket.element.Angle1 ) {
      this.anglesSelected.push ({
        label: `${this.dataTicket.element.Angle1}°`,
        value: "angle1"
      });
      this.anglesSelected.push ({
        label: `${this.dataTicket.element.Angle2}°`,
        value: "angle2"
      });

    }
  }

  protected formateFormValues() {
    const buildCustomData: { element: any, laserCutting: any, welding: any } = super.formateFormValues();
    buildCustomData.element.usedAngle = this.stepZeroForm.value.usedAngle;
    return buildCustomData;
  }

  protected formatWithEditedCustomData() {
    super.formatWithEditedCustomData();
    const datasToCheck: string[] = ["usedAngle"],
    referenceData: any = {
      "usedAngle" : "usedAngle"
    };
    datasToCheck.forEach( data => {
      if (this.stepZeroForm.get(data).touched) {
        this.customData[referenceData[data]] = this.stepZeroForm.get(data).value;
      }
    });
  }

}
