import { Component, OnInit, Input, Output, EventEmitter, ViewChildren, QueryList, ElementRef, SimpleChanges, OnChanges } from "@angular/core";
import { ILinkedMultiTableGroupData } from "app/facade/interfaces/multi-table-group.interface";

@Component({
  selector: "app-supply-table-linked",
  templateUrl: "./supply-table-linked.component.html",
  styleUrls: ["./supply-table-linked.component.css"]
})
export class SupplyTableLinkedComponent implements OnInit, OnChanges {
  @Input() public linkedData: ILinkedMultiTableGroupData;
  @Input() public displayHeader: boolean = true;
  @Input() public contentHeight: string = "45vh";
  @Input() public scrollTopPosition: number = 0;
  @Output() public onEncodeSupplierResponse: EventEmitter<number> = new EventEmitter<number>();
  @ViewChildren("linkedContent") public linkedContent: QueryList<ElementRef>;

  constructor() { }

  ngOnInit() {

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.scrollTopPosition) {
      this.updateScrollPosition();
    }
  }

  // Recursive method because linkedContent not already define when datas are update
  private updateScrollPosition(tryCount: number = 0) {
    if (tryCount < 10) {
      if (this.linkedContent) {
        this.linkedContent.toArray().forEach(link => {
          link.nativeElement.scrollTop = this.scrollTopPosition;
        });
      } else {
        setTimeout( () => {
          this.updateScrollPosition(++tryCount);
        }, 0);
      }
    } else {
      console.log("ERROR ON SCROLL SUPPLY TABLE LINKED");
    }
  }

  public linkedTableScroll(event: Event) {
    event.preventDefault();
    this.linkedContent.toArray().forEach(link => {
      link.nativeElement.scrollTop = this.scrollTopPosition;
    });
  }

  public encodeSupplierResponse(linkedData: ILinkedMultiTableGroupData) {
    this.onEncodeSupplierResponse.emit(linkedData.id);
  }
}
