import { formatNumber, NumberSymbol } from "@angular/common";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { purchaseOrderElementActionConfig } from "../../../../facade/configs/status.config";
import {
  EnumElementUnitSelectValue,
  EnumQuantityUnitSelectValue,
} from "../../../../facade/enums/element-unit-category.enum";
import { IPagination } from "../../../../facade/interfaces/crud.interface";
import {
  EnumPurchaseOrderAction,
  EnumPurchaseOrderElementStatus,
  FilterPurchaseOrderElement,
  IElementPurchaseOrder,
  IPurchaseOrderElementFilter,
} from "../../../../facade/interfaces/purchase-order.interface";
import { IpurchaseOrderAdmission } from "../../../../facade/interfaces/purchaseOrderAdmission.interface";
import { TableDatasDefinition } from "../../../../facade/interfaces/table-datas-definition";
import { AdmissionQueriesService } from "../../../../facade/queries/admission/admission.queries";
import { PurchaseOrderElementQueriesService } from "../../../../facade/queries/purcase-order-element/purchase-order-element-queries.service";
import { ListTableService } from "../../../../facade/services/list-table.service";
import { ModalConfirmComponent } from "../../../../presentationnal/organisms/modal";
import { ModalAddAdmissionComponent } from "../../../../presentationnal/organisms/modal/components/modal-add-admission/modal-add-admission.component";
import { MODAL_CLOSE_ACTION } from "../../../../presentationnal/organisms/modal/enums/modal-close-action.enum";
import { MODAL_TYPE } from "../../../../presentationnal/organisms/modal/enums/modal-type.enum";
import { ModalService } from "../../../../presentationnal/organisms/modal/services/modal.service";
import { TABLE_ITEM_ACTION_TYPE } from "../../../../presentationnal/organisms/table/table-item/enum/table-item-action-type.enum";
import * as moment from "moment";
import { Subscription } from "rxjs";
import { AuthService } from "@lib/auth/auth.service";
import { PermissionService } from "app/facade/services/permission.service";
import { Router } from "@angular/router";
import { SnackService } from "app/presentationnal/organisms/snack-bar/services/snack.service";
import { isEqual } from "apollo-utilities";

const DEFAULT_LIMIT: number = 50;
const NBSP: string = String.fromCharCode(160);

@Component({
  selector: "app-listing-element",
  templateUrl: "./listing-element.component.html",
  styleUrls: ["./listing-element.component.css"],
})
export class ListingElementComponent implements OnInit, OnDestroy {
  private _listPurchaseOrderElementSub: Subscription;
  private _addAdmissionSub: Subscription;
  private _filterElement: string = "";
  public filterFormGroup: FormGroup;
  public category: string[] = [
    "Sous-traitant",
    "Peinture achat",
    "Petit outillage",
    "Matériel soudure",
    "Bardage",
    "Location matériel",
    "Boulonnerie",
    "Consommable atelier",
    "Livraison",
    "Bois",
    "Verre",
    "Accessoires de montage",
    "Divers",
    "Poutrelle",
    "Tôle",
    "Tube"
  ];
  private _category: FilterPurchaseOrderElement = {
    category: this.category,
    reference: null,
    project: null,
    bdc: null,
    dateFrom: null,
    dateTo: null,
    denom: null,
    format: null,
    poids: null,
    long: null,
    larg: null,
    remarque: null,
    status: null,
    thickness: null,
  };
  private _filter: FilterPurchaseOrderElement = this._category;
  public canEdit: boolean = false;
  public canDelete: boolean = false;
  public canSeePrices: boolean = false;

  public issubcontractor: boolean = true;
  public ispaint: boolean = true;
  public issmalltools: boolean = true;
  public isWelding: boolean = true;
  public isCladding: boolean = true;
  public isRented: boolean = true;
  public isBolts: boolean = true;
  public isworkshop: boolean = true;
  public isdelivery: boolean = true;
  public iswood: boolean = true;
  public isglass: boolean = true;
  public ismounting: boolean = true;
  public isdiverse: boolean = true;
  public isbeam: boolean = true; // Poutrelle
  public isplate: boolean = true; // Tôle
  public istube: boolean = true; // Tube
  public dateFrom: Date; // Date à partir de
  public dateTo: Date; // Date jusqu'à
  public reference: string; // Matière
  public project: string; // Référence du projet
  public bdc: string; // Référence bons de commande
  public denom: string; // Dénomination
  public format: string; // Format
  public poids: number; // Poids
  public long: number; // Longueur
  public larg: number; // Largeur
  public remarque: string; // Remarque
  public status: string; // Statut
  public thickness: string; // Épaisseur

  public dateFromStr: string;
  public dateToStr: string;

  public tableHeaders: string[] = [
    "Date",
    "Délivré",
    "Qtt",
    "Matière",
    "Denom.",
    "Prix/u",
    "Prix",
    "Mesures",
    "Poids",
    "Ref BDC",
    "Ref Projet",
    "Options",
    "Remarques",
    "Status",
    "Fournisseur",
    "Actions",
  ];

  public dataDefinition: TableDatasDefinition[] = [
    { key: "createdAt", isTitle: false, rawHtml: true },
    { key: "deliveryDate", isTitle: false, rawHtml: true },
    { key: "qttOnqttTotal", isTitle: false, rawHtml: true },
    { key: "matterRef", isTitle: true },
    { key: "denomination", isTitle: true },
    { key: "priceWithUnit", isTitle: true, rawHtml: true },
    { key: "price", isTitle: true, rawHtml: true },
    { key: "measures", isTitle: false, rawHtml: true },
    { key: "weight", isTitle: true },
    {
      key: "purchaseOrder",
      isTitle: false,
      buttonAction: this.goToPurchaseOrderEdit,
    },
    { key: "projectRef", isTitle: true },
    { key: "allOptions", isTitle: false, rawHtml: true },
    { key: "remarks", isTitle: false, rawHtml: true },
    {
      key: "status",
      isTitle: false,
      isStatus: true,
      statusConfig: purchaseOrderElementActionConfig,
    },
    {
      key: "supplierName",
      isTitle: false,
      rawHtml: true
    }
  ];

  public noSortHeaders: string[] = [
    "Mesures",
    "Ref Projet",
    "Options",
    "Remarque",
    "Actions",
  ];

  public dataTable: IElementPurchaseOrder[] = [];
  private _pagination: IPagination = { page: 1, limit: DEFAULT_LIMIT };
  private _actionsType = TABLE_ITEM_ACTION_TYPE;
  private _totalPrice = 0;
  public userGroup: any;

  constructor(
    private _listTableSrv: ListTableService,
    protected _fb: FormBuilder,
    private _purchaseOrderElementQuerieSrv: PurchaseOrderElementQueriesService,
    private _modalService: ModalService,
    private _snackBarSrv: SnackService,
    private _router: Router,
    private _authSrv: AuthService,
    private _permissionService: PermissionService
  ) {}

  ngOnInit() {
    this.userGroup = this._authSrv.isUserGroup();
    this._permissionService.getAndUpdatePermission(this.userGroup);
    if (
      !this._permissionService.hasPermission(this.userGroup, "elements", "read")
    )
      this.backToHome(true);
    this.canEdit = this._permissionService.hasPermission(
      this.userGroup,
      "elements",
      "write"
    );
    this.canDelete = this._permissionService.hasPermission(
      this.userGroup,
      "elements",
      "delete"
    );
    this.canSeePrices = this._permissionService.hasPermission(
      this.userGroup,
      "elements",
      "seePrices"
    );

    this.initFilterForm();
    // let savedSearch = JSON.parse(localStorage.getItem('dataElementRecherche'));
    // savedSearch = savedSearch && savedSearch.recherche.length > 0 ? savedSearch.recherche : '';
    // if (savedSearch.length > 0) {
    //   this._filterElement = savedSearch;
    // }
    let savedFilter = JSON.parse(localStorage.getItem("dataElementRecherche"));
    if (savedFilter) {
      if (savedFilter.filter) {
        if (!isEqual(this._filter, savedFilter.filter)) {
          this._snackBarSrv.open("Liste filtrée", "Cette liste est filtrée suivant votre recherche", "info", 5000);
        }
        this._filter = savedFilter.filter;
        this.onResetFilter(this._filter, false);
      }
      if (savedFilter.recherche.length > 0) {
        this._snackBarSrv.open("Liste filtrée", "Cette liste est filtrée suivant votre recherche", "info", 5000);
        this._filterElement = savedFilter.recherche;
      }
    }
    let savedPagination = JSON.parse(
      localStorage.getItem("dataElementPagination")
    );

    savedPagination =
      savedPagination && savedPagination.pagination > 1
        ? savedPagination.pagination
        : 1;
    if (savedPagination > 1) {
      this._pagination.limit = DEFAULT_LIMIT * savedPagination;
      this.loadPurchaseOrdersElement(this._filter, this._pagination);
      this._pagination = { page: savedPagination, limit: DEFAULT_LIMIT };
    } else {
      this.loadPurchaseOrdersElement(this._filter);
    }
  }

  ngOnDestroy(): void {
    if (this._listPurchaseOrderElementSub) {
      this._listPurchaseOrderElementSub.unsubscribe();
    }
    if (this._addAdmissionSub) {
      this._addAdmissionSub.unsubscribe();
    }
  }

  /**
   * @description Redirect to homepage
   * @author Raphaël M
   * @private
   * @memberof ListingElementComponent
   */
  private backToHome(msg?: boolean) {
    if (msg) {
      this._snackBarSrv.openSnackBarError(
        "Accès refusé",
        "Vous n'avez pas accès à cette partie du site."
      );
    }
    this._router.navigate(["/"]);
  }

  /**
   * @description Open the purchase order in a new tab
   * @author Raphaël M
   * @private
   * @memberof ListingElementComponent
   */
  private goToPurchaseOrderEdit(el) {
    const url = `/purchase-order/edit/${el.purchaseOrderId}`;
    window.open(url, "_blank");
  }

  /**
   * @description Init the basics form group
   * @author Marie Claudia
   * @private
   * @memberof ListingElementComponent
   */
  private initFilterForm() {
    this.filterFormGroup = this._fb.group({
      issubcontractor: [this.issubcontractor],
      ispaint: [this.ispaint],
      issmalltools: [this.issmalltools],
      isWelding: [this.isWelding],
      isCladding: [this.isCladding],
      isRented: [this.isRented],
      isBolts: [this.isBolts],
      isworkshop: [this.isworkshop],
      isdelivery: [this.isdelivery],
      iswood: [this.iswood],
      isglass: [this.isglass],
      ismounting: [this.ismounting],
      isdiverse: [this.isdiverse],
      isbeam: [this.isbeam],
      isplate: [this.isplate],
      istube: [this.istube],
      dateFrom: [this.dateFrom],
      dateTo: [this.dateTo],
      reference: [this.reference],
      project: [this.project],
      bdc: [this.bdc],
      denom: [this.denom],
      format: [this.format],
      poids: [this.poids],
      long: [this.long],
      larg: [this.larg],
      remarque: [this.remarque],
      status: [this.status],
      thickness: [this.thickness],
    });
  }

  /**
   * @description get list in database
   * @author Marie Claudia
   * @private
   * @param {string[]} category
   * @returns
   * @memberof ListingElementComponent
   */
  private loadPurchaseOrdersElement(
    filter?: FilterPurchaseOrderElement,
    pagination: IPagination = { page: 1, limit: DEFAULT_LIMIT }
  ) {
    if (this._listPurchaseOrderElementSub) {
      this._listPurchaseOrderElementSub.unsubscribe();
    }
    if (pagination.page === 1) this._totalPrice = 0;

    this._listPurchaseOrderElementSub = this._purchaseOrderElementQuerieSrv
      .filterPurchaseOrderElement(this._filterElement, filter, pagination)
      .subscribe(
        (result) => {
          const resultData: {
            filterPurchaseOrderElement: IElementPurchaseOrder[];
            pagination?: any;
          } = <any>result.data;

          if (resultData && resultData.filterPurchaseOrderElement) {
            this.dataTable =
              pagination.page !== 1
                ? [
                    ...this.dataTable,
                    ...this.mapPurchaseOrdersElement(
                      resultData.filterPurchaseOrderElement
                    ),
                  ]
                : this.mapPurchaseOrdersElement(
                    resultData.filterPurchaseOrderElement
                  );
          }
          if (resultData && resultData.pagination) {
            this._listTableSrv.setLoadMoreStatus(resultData.pagination.hasNext);
          }
        },
        (error) => {
          this.dataTable = [];
          this._snackBarSrv.openSnackBarError("Liste articles", "La liste n'a pas été chargée", error);
        }
      );
  }

  public loadMore(event: boolean) {
    if (this.dataTable.length > 0) {
      this._pagination.page++;
    }
    localStorage.setItem(
      "dataElementPagination",
      JSON.stringify({ pagination: this._pagination.page })
    );
    this.loadPurchaseOrdersElement(this._filter, this._pagination);
  }

  public filterGlobal(filter: any) {
    this._filterElement = filter;
    this._pagination.page = 1;
    localStorage.setItem(
      "dataElementRecherche",
      JSON.stringify({ filter: this._filter, recherche: this._filterElement })
    );
    localStorage.setItem(
      "dataElementPagination",
      JSON.stringify({ pagination: 1 })
    );
    this.loadPurchaseOrdersElement(this._filter);
  }

  /**
   * @description Map data for listing display
   * @author Marie Claudia
   * @private
   * @param {any[]} data
   * @returns {IElementPurchaseOrder[]}
   * @memberof ListingElementComponent
   */
  private mapPurchaseOrdersElement(data: any[]): IElementPurchaseOrder[] {
    let res = data.map((purchaseOrderElement) => {
      //check if project is not null
      let project = purchaseOrderElement.purchaseOrder && (purchaseOrderElement.purchaseOrder.project
        ? purchaseOrderElement.purchaseOrder.project.reference
        : purchaseOrderElement.purchaseOrder.linkedProjects
            .map((project) => project.reference)
            .join(", "));

      //build quantity unit
      let qttUnitKey = purchaseOrderElement.quantityUnit
        ? purchaseOrderElement.quantityUnit
        : purchaseOrderElement.unit;
      let qttUnit = EnumElementUnitSelectValue[qttUnitKey];
      if (!qttUnit) qttUnit = EnumElementUnitSelectValue.EURO;

      //build measures
      let measures = [];
      if (purchaseOrderElement.format)
        measures.push(
          "Format" + NBSP + ":" + NBSP + purchaseOrderElement.format
        );
      if (purchaseOrderElement.length)
        measures.push(
          "Long." + NBSP + ":" + NBSP + purchaseOrderElement.length
        );
      if (purchaseOrderElement.width)
        measures.push("Larg." + NBSP + ":" + NBSP + purchaseOrderElement.width);
      if (purchaseOrderElement.thickness)
        measures.push(
          "Épais." + NBSP + ":" + NBSP + purchaseOrderElement.thickness
        );

      //build all options
      let allOptions = [];
      if (purchaseOrderElement.options.type)
        allOptions.push(
          "Option" + NBSP + ":" + NBSP + purchaseOrderElement.options.type
        );
      if (purchaseOrderElement.isEn1090) allOptions.push("En1090");
      if (purchaseOrderElement.isBlack) allOptions.push("Noir");
      if (purchaseOrderElement.isBlaste) allOptions.push("Grenaillé");

      //build total price for the element
      let price = purchaseOrderElement.price;
      if (price === null) price = 0;

      let totalPrice;
      switch (qttUnitKey) {
        case "EURO_BY_TON":
          totalPrice = (price * purchaseOrderElement.weight) / 1000;
          break;
        case "EURO_BY_UNIT":
          totalPrice = price * purchaseOrderElement.quantity;
          break;
        case "EURO_BY_METER":
          totalPrice = (price * purchaseOrderElement.format) / 1000;
          break;
        case "EURO_BY_SQUARE_METER":
          totalPrice =
            price *
            purchaseOrderElement.quantity *
            (purchaseOrderElement.length / 1000) *
            (purchaseOrderElement.width / 1000);
          break;
        case "EURO":
        default:
          totalPrice = price;
          break;
      }
      totalPrice = parseFloat(totalPrice.toFixed(2));
      this._totalPrice += totalPrice;

      let createdAtStr = moment
          .unix(purchaseOrderElement.createdAt)
          .format("DD/MM/YYYY"),
        createdAtHidden = `${purchaseOrderElement.createdAt}`.padStart(12, "0");
      let deliveryDateStr = purchaseOrderElement.deliveryDate
          ? moment.unix(purchaseOrderElement.deliveryDate).format("DD/MM/YYYY")
          : "Non",
        deliveryDateHidden = `${
          purchaseOrderElement.deliveryDate
            ? purchaseOrderElement.deliveryDate
            : 0
        }`.padStart(12, "0");
      let realDeliveryDateStr = moment
          .unix(purchaseOrderElement.realDeliveryDate)
          .format("DD/MM/YYYY"),
        realDeliveryDateHidden =
          `${purchaseOrderElement.realDeliveryDate}`.padStart(12, "0");
      let priceStr =
          this.canSeePrices && price
            ? price.toLocaleString("fr", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }) +
              NBSP +
              qttUnit.replace(/ /g, NBSP)
            : "--",
        priceHidden = `${
          this.canSeePrices && price ? Math.round(price * 100) : 0
        }`.padStart(12, "0");
      let totalPriceStr =
          this.canSeePrices && totalPrice
            ? totalPrice.toLocaleString("fr", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }) +
              NBSP +
              EnumElementUnitSelectValue.EURO
            : "--",
        totalPriceHidden = `${
          this.canSeePrices && totalPrice ? Math.round(totalPrice * 100) : 0
        }`.padStart(12, "0");
      let qttOnqttTotalStr = purchaseOrderElement.qttOnqttTotal,
        qttOnqttTotalHidden = `${Math.round(
          (qttOnqttTotalStr.split("/")[0] * 1000) /
            qttOnqttTotalStr.split("/")[1]
        )}`.padStart(4, "0");

      let remarksData = {
        Élément: purchaseOrderElement.remark,
        "BDC gén.": purchaseOrderElement.purchaseOrder && purchaseOrderElement.purchaseOrder.remark,
        "BDC int.": purchaseOrderElement.purchaseOrder && purchaseOrderElement.purchaseOrder.internalRemark,
        "DP gén.":
          purchaseOrderElement.purchaseOrder &&
          purchaseOrderElement.purchaseOrder.priceRequest &&
          purchaseOrderElement.purchaseOrder.priceRequest.remark,
        "DP int.":
          purchaseOrderElement.purchaseOrder &&
          purchaseOrderElement.purchaseOrder.priceRequest &&
          purchaseOrderElement.purchaseOrder.priceRequest.internalRemark,
      };
      let remarks = [];
      for (let k in remarksData) {
        if (!remarksData[k]) continue;
        remarks.push(k + " : " + remarksData[k]);
      }

      return {
        id: purchaseOrderElement.id,
        purchaseOrderId: purchaseOrderElement.purchaseOrder && purchaseOrderElement.purchaseOrder.id,
        createdAt:
          '<span class="hidden">' + createdAtHidden + "</span>" + createdAtStr,
        deliveryDate:
          '<span class="hidden">' +
          deliveryDateHidden +
          "</span>" +
          deliveryDateStr,
        realDeliveryDate:
          '<span class="hidden">' +
          realDeliveryDateHidden +
          "</span>" +
          realDeliveryDateStr,
        quantity: purchaseOrderElement.quantity,
        matterRef: purchaseOrderElement.matterRef,
        denomination: purchaseOrderElement.denomination,
        priceWithUnit:
          '<span class="hidden">' + priceHidden + "</span>" + priceStr,
        price:
          '<span class="hidden">' +
          totalPriceHidden +
          "</span>" +
          totalPriceStr,
        measures: measures.join(",<br>"),
        weight: purchaseOrderElement.weight,
        purchaseOrder: purchaseOrderElement.purchaseOrder && purchaseOrderElement.purchaseOrder.reference,
        project: project,
        projectRef: purchaseOrderElement.projectRef,
        supplierName: purchaseOrderElement.supplierName,
        projectId: purchaseOrderElement.projectId,
        allOptions: allOptions.join(",<br>"),
        remarks: remarks.join("<br><br>"),
        qttOnqttTotal:
          '<span class="hidden">' +
          qttOnqttTotalHidden +
          "</span>" +
          qttOnqttTotalStr,
        status: purchaseOrderElement.status,
        actionsList: this.setPurchaseOrderElementActionList(),
      };
    });
    return res;
  }

  /**
   * @description Add action admissions bouton in table list
   * @author Marie Claudia
   * @private
   * @param {EnumPurchaseOrderAction} action
   * @returns
   * @memberof ListingElementComponent
   */
  private setPurchaseOrderElementActionList(): any[] {
    const actionList: any[] = [];
    if (this.canEdit)
      actionList.push({
        label: "Réception",
        type: TABLE_ITEM_ACTION_TYPE.DELETE,
        additionnalClass: "table-row__action_edit",
      });
    return actionList;
  }

  /**
   * @description send filter when ENTER is pressed
   * @author Raphaël Michaux
   * @public
   * @memberof ListingElementComponent
   * @param {any} event
   */
  public keyUpFilter(event: KeyboardEvent) {
    const keyCode = event.key || event.keyCode;
    if (keyCode == "Enter") {
      this.onClickFilter();
    }
  }

  /**
   * @description Reset filter button
   * @author Raphaël Michaux
   * @public
   * @memberof ListingElementComponent
   * @param {any} event
   */
  public onResetFilter(
    filter?: FilterPurchaseOrderElement,
    makeSearch?: boolean
  ) {
    if (makeSearch === undefined) makeSearch = true;
    this.filterFormGroup
    .get("issubcontractor")
    .setValue(
      filter && filter.category !== undefined
        ? filter.category.indexOf("Sous-traitant") > -1
        : true
    );
    this.filterFormGroup
    .get("ispaint")
    .setValue(
      filter && filter.category !== undefined
        ? filter.category.indexOf("Peinture achat") > -1
        : true
    );
    this.filterFormGroup
    .get("issmalltools")
    .setValue(
      filter && filter.category !== undefined
        ? filter.category.indexOf("Petit outillage") > -1
        : true
    );
    this.filterFormGroup
    .get("isWelding")
    .setValue(
      filter && filter.category !== undefined
        ? filter.category.indexOf("Matériel soudure") > -1
        : true
    );
    this.filterFormGroup
    .get("isCladding")
    .setValue(
      filter && filter.category !== undefined
        ? filter.category.indexOf("Bardage") > -1
        : true
    );
    this.filterFormGroup
    .get("isRented")
    .setValue(
      filter && filter.category !== undefined
        ? filter.category.indexOf("Location matériel") > -1
        : true
    );
    this.filterFormGroup
    .get("isBolts")
    .setValue(
      filter && filter.category !== undefined
        ? filter.category.indexOf("Boulonnerie") > -1
        : true
    );
    this.filterFormGroup
    .get("isworkshop")
    .setValue(
      filter && filter.category !== undefined
        ? filter.category.indexOf("Consommable atelier") > -1
        : true
    );
    this.filterFormGroup
    .get("isdelivery")
    .setValue(
      filter && filter.category !== undefined
        ? filter.category.indexOf("Livraison") > -1
        : true
    );
    this.filterFormGroup
    .get("iswood")
    .setValue(
      filter && filter.category !== undefined
        ? filter.category.indexOf("Bois") > -1
        : true
    );
    this.filterFormGroup
    .get("isglass")
    .setValue(
      filter && filter.category !== undefined
        ? filter.category.indexOf("Verre") > -1
        : true
    );
    this.filterFormGroup
    .get("ismounting")
    .setValue(
      filter && filter.category !== undefined
        ? filter.category.indexOf("Accessoires de montage") > -1
        : true
    );
    this.filterFormGroup
    .get("isdiverse")
    .setValue(
      filter && filter.category !== undefined
        ? filter.category.indexOf("Divers") > -1
        : true
    );
    this.filterFormGroup
      .get("isbeam")
      .setValue(
        filter && filter.category !== undefined
          ? filter.category.indexOf("Poutrelle") > -1
          : true
      );
    this.filterFormGroup
      .get("isplate")
      .setValue(
        filter && filter.category !== undefined
          ? filter.category.indexOf("Tôle") > -1
          : true
      );
    this.filterFormGroup
      .get("istube")
      .setValue(
        filter && filter.category !== undefined
          ? filter.category.indexOf("Tube") > -1
          : true
      );
    this.filterFormGroup
      .get("dateFrom")
      .setValue(
        filter && filter.dateFrom !== undefined ? filter.dateFrom : null
      );
    this.filterFormGroup
      .get("dateTo")
      .setValue(filter && filter.dateTo !== undefined ? filter.dateTo : null);
    this.filterFormGroup
      .get("reference")
      .setValue(
        filter && filter.reference !== undefined ? filter.reference : null
      );
    this.filterFormGroup
      .get("project")
      .setValue(filter && filter.project !== undefined ? filter.project : null);
    this.filterFormGroup
      .get("bdc")
      .setValue(filter && filter.bdc !== undefined ? filter.bdc : null);
    this.filterFormGroup
      .get("denom")
      .setValue(filter && filter.denom !== undefined ? filter.denom : null);
    this.filterFormGroup
      .get("format")
      .setValue(filter && filter.format !== undefined ? filter.format : null);
    this.filterFormGroup
      .get("poids")
      .setValue(filter && filter.poids !== undefined ? filter.poids : null);
    this.filterFormGroup
      .get("long")
      .setValue(filter && filter.long !== undefined ? filter.long : null);
    this.filterFormGroup
      .get("larg")
      .setValue(filter && filter.larg !== undefined ? filter.larg : null);
    this.filterFormGroup
      .get("remarque")
      .setValue(
        filter && filter.remarque !== undefined ? filter.remarque : null
      );
    this.filterFormGroup
      .get("status")
      .setValue(filter && filter.status !== undefined ? filter.status : null);
    this.filterFormGroup
      .get("thickness")
      .setValue(
        filter && filter.thickness !== undefined ? filter.thickness : null
      );
    this._filterElement = "";
    this.onClickFilter(makeSearch);
  }

  /**
   * @description Filter button
   * @author Marie Claudia
   * @public
   * @memberof ListingElementComponent
   */
  public onClickFilter(makeSearch?: boolean) {
    if (makeSearch === undefined) makeSearch = true;

    let categories = [];
    if (this.filterFormGroup.get("issubcontractor").value) categories.push("Sous-traitant");
    if (this.filterFormGroup.get("ispaint").value) categories.push("Peinture achat");
    if (this.filterFormGroup.get("issmalltools").value) categories.push("Petit outillage");
    if (this.filterFormGroup.get("isWelding").value) categories.push("Matériel soudure");
    if (this.filterFormGroup.get("isCladding").value) categories.push("Bardage");
    if (this.filterFormGroup.get("isRented").value) categories.push("Location matériel");
    if (this.filterFormGroup.get("isBolts").value) categories.push("Boulonnerie");
    if (this.filterFormGroup.get("isworkshop").value) categories.push("Consommable atelier");
    if (this.filterFormGroup.get("isdelivery").value) categories.push("Livraison");
    if (this.filterFormGroup.get("iswood").value) categories.push("Bois");
    if (this.filterFormGroup.get("isglass").value) categories.push("Verre");
    if (this.filterFormGroup.get("ismounting").value) categories.push("Accessoires de montage");
    if (this.filterFormGroup.get("isdiverse").value) categories.push("Divers");
    if (this.filterFormGroup.get("isbeam").value) categories.push("Poutrelle");
    if (this.filterFormGroup.get("isplate").value) categories.push("Tôle");
    if (this.filterFormGroup.get("istube").value) categories.push("Tube");

    this.dateFrom = this.filterFormGroup.get("dateFrom").value
      ? this.filterFormGroup.get("dateFrom").value
      : null;
    this.dateTo = this.filterFormGroup.get("dateTo").value
      ? this.filterFormGroup.get("dateTo").value
      : null;
    this.reference = this.filterFormGroup.get("reference").value
      ? this.filterFormGroup.get("reference").value
      : null;
    this.project = this.filterFormGroup.get("project").value
      ? this.filterFormGroup.get("project").value
      : null;
    this.bdc = this.filterFormGroup.get("bdc").value
      ? this.filterFormGroup.get("bdc").value
      : null;
    this.denom = this.filterFormGroup.get("denom").value
      ? this.filterFormGroup.get("denom").value
      : null;
    this.format = this.filterFormGroup.get("format").value
      ? this.filterFormGroup.get("format").value
      : null;
    this.poids = this.filterFormGroup.get("poids").value
      ? +this.filterFormGroup.get("poids").value
      : null;
    this.long = this.filterFormGroup.get("long").value
      ? +this.filterFormGroup.get("long").value
      : null;
    this.larg = this.filterFormGroup.get("larg").value
      ? +this.filterFormGroup.get("larg").value
      : null;
    this.remarque = this.filterFormGroup.get("remarque").value
      ? this.filterFormGroup.get("remarque").value
      : null;
    this.status = this.filterFormGroup.get("status").value
      ? this.filterFormGroup.get("status").value
      : null;
    this.thickness = this.filterFormGroup.get("thickness").value
      ? this.filterFormGroup.get("thickness").value
      : null;

    this.dateFromStr = this.dateFrom
      ? this.timestampToDateStr(
          this.dateFrom instanceof Date
            ? this.dateFrom.getTime() / 1000
            : this.dateFrom
        )
      : null;
    this.dateToStr = this.dateTo
      ? this.timestampToDateStr(
          this.dateTo instanceof Date
            ? this.dateTo.getTime() / 1000
            : this.dateTo
        )
      : null;

    this._filter = {
      category: categories,
      reference: this.reference,
      project: this.project,
      bdc: this.bdc,
      dateFrom: this.dateFrom,
      dateTo: this.dateTo,
      denom: this.denom,
      format: this.format,
      // unit :this.unit,
      poids: this.poids,
      long: this.long,
      larg: this.larg,
      remarque: this.remarque,
      status: this.status,
      thickness: this.thickness,
    };
    //     this.dateFrom  = this.dateFrom === null || this.dateFrom instanceof Date ? this.dateFrom : new Date(this.dateFrom*1000);
    //     this.dateTo    = this.dateTo   === null || this.dateTo   instanceof Date ? this.dateTo : new Date(this.dateTo*1000);

    if (makeSearch) {
      localStorage.setItem(
        "dataElementRecherche",
        JSON.stringify({ filter: this._filter, recherche: this._filterElement })
      );
      localStorage.setItem(
        "dataElementPagination",
        JSON.stringify({ pagination: 1 })
      );

      this.loadPurchaseOrdersElement(this._filter);
    }
  }

  /**
   * @description display modal add admission component
   * @author Marie Claudia
   * @param {number} id
   * @private
   * @memberof ListingElementComponent
   */
  public addPurchaseOrderAdmisson(id: number) {
    const element: IElementPurchaseOrder = this.dataTable.find(
      (data) => data.id === id
    );
    let quantity = element.quantity;
    if (element) {
      const modalTitle: string = "Admission d'un élément";
      const modalRef = this._modalService.openModal(
        ModalAddAdmissionComponent,
        {
          title: modalTitle,
          type: MODAL_TYPE.NORMAL,
          data: null,
          params: { id, quantity },
          closeAction: MODAL_CLOSE_ACTION.SAVE,
        }
      );
      if (modalRef) {
        modalRef.afterClosed.subscribe((res) => {
          if (res.confirm && res.data) {
            console.log("res.data.id", res.data.id);
          }
        });
      }
    }
  }

  /**
   * @description Convert timestamp to date str
   * @author Raphaël Michaux
   * @param $value
   * @memberof ListingElementComponent
   */
  public timestampToDateStr(timestamp: number) {
    const date = new Date(timestamp * 1000);
    let padNumber: (value: number, size: number) => string = function (
      value: number,
      size: number
    ): string {
      let zero: number = size - value.toString().length + 1;
      return Array(+(zero > 0 && zero)).join("0") + value;
    };

    let result: string = "";

    result += padNumber(date.getDate(), 2) + "/";
    result += padNumber(date.getMonth() + 1, 2) + "/";
    result += date.getFullYear().toString();

    return result;
  }

  /**
   * @description display modal add admission component
   * @author Marie Claudia
   * @param $event
   * @private
   * @memberof ListingElementComponent
   */
  public tableButtonClicked($event: { actionType: number; itemId: number }) {
    switch (this._actionsType[$event.actionType]) {
      case "DELETE":
        this.addPurchaseOrderAdmisson($event.itemId);
        break;
      default:
        break;
    }
  }
}
