import { Component, OnInit, Inject } from "@angular/core";
import { StepMatterBase } from "../../base/step-matter-base";
import { CONTAINER_DATA, StepFormService } from "app/presentationnal/organisms/wizard-modal/service/step-form.service";
import { CatalogQueriesService } from "app/facade/queries/catalog/async/catalog_queries.service";
import { CustomElementService } from "app/facade/quote-element-definition/elements/customs/custom.service";
import { FormBuilder } from "@angular/forms";
import { QuotesQueriesService } from "app/facade/queries/quote/async/quotes_queries.service";

@Component({
  selector: "app-embase-step-one",
  templateUrl: "./embase-step-one.component.html",
  styleUrls: ["./embase-step-one.component.css"]
})
export class EmbaseStepOneComponent extends StepMatterBase implements OnInit {

  constructor(@Inject(CONTAINER_DATA) data: any,
    protected _stepFormSrv: StepFormService,
    protected _catalogQueriesSrv: CatalogQueriesService,
    protected _customElementSrv: CustomElementService,
    protected _quoteQueriesSrv: QuotesQueriesService,
    protected _fb: FormBuilder) {
      super(data, _stepFormSrv, _catalogQueriesSrv, _customElementSrv, _quoteQueriesSrv, _fb);
  }

}
