export const ERROR_MSG_CONFIG: Map<string, (data: any) => string> = new Map<string, (data: any) => string>([
    [ "maxlength", (data: any) => "Le champ accepte un maximum de  " + data.requiredLength + " caractéres. " ],
    [ "minlength", (data: any) => "Le champ a besoin d'au moins " + data.requiredLength + " caractéres. " ],
    [ "required", (data: any) => "Le champ est requis" ],
    [ "validEmail", (data: any) => "Le champ doit contenir un email valide" ],
    [ "number", (data: any) => "Le champ nécessite uniquement un nombre" ],
    [ "matchPassword", (data: any) => "Le mot de passe & le mot de passe de confirmation est différent"],
    [ "max", (data: any) => "La valeur doit être plus petite ou égale à " + data.max ],
    [ "min", (data: any) => "La valeur doit être plus grande ou égale à " + data.min ],
    // CUSTOM FOR ADMINISTRATION
    [ "validBaseAmalgam", (data: any) => "La valeur doit être plus petite ou égale à celle l'amalgame maximum"],
    [ "validMaxAmalgam", (data: any) => "La valeur doit être plus grande ou égale à celle l'amalgame de base"]
]);