import { Operation, OperationCreate } from "./operation.base";
import { IAction } from "../base";
import { Element } from "../elements/element.base";

export interface BendingDefinedProperty {
    bendingPrice: number;
}

export interface BendingProperty extends BendingDefinedProperty {
    angle: number;
}

export interface IBendable extends Element {
    diameter: number;
    thickness: number;
}

export interface BendingBuild extends IAction<IBendable, BendingProperty> {
}

export class Bending extends Operation<IBendable, BendingProperty, BendingDefinedProperty> {

    public constructor(
        element: IBendable,
        properties: BendingProperty,
        operationCreate: OperationCreate<IBendable, BendingDefinedProperty>) {

        super(element, properties, operationCreate);
    }

    public get CalculatedPrice(): number {
        return 0;
    }

    protected updateAfterElementChange(): void {
        // delete this.properties.bendingPrice;
    }
}