import gql from "graphql-tag";

export const GET_SUPPLIER_OFFERS = gql`
  query supplierOffers($filter: SupplierOfferFilter!){
    supplierOffers(filter: $filter){
      id
      reference
      remark
      isSent
      supplierId
      supplierContactId
      supplier{
        code
        name
      }
      supplierContact{
        firstname
        lastname
      }
      elements {
        id
        price
      }
    }
  }
`;

export const GET_SUPPLIER_OFFERS_WITH_CATEGORIES = gql`
  query supplierOffers($filter: SupplierOfferFilter!){
    supplierOffers(filter: $filter){
      id
      reference
      remark
      isSent
      sendingDate
      supplierId
      supplierContactId
      supplier{
        code
        name
        parentSupplyCategories {
          id
          name
        }
      }
      supplierContact{
        firstname
        lastname
      }
    }
  }
`;

export const GET_SUPPLIER_OFFER_RESPONSE = gql`
  query getSupplierOfferResponse($id: ID!){
    supplierOffer(id: $id){
      id
      remark
      supplierReference
      supplier{
        code
        name
        allSupplyCategories{
          id
        }
      }
      priceRequest {
        id
        additionnalCosts {
          id
          type
          denomination
          quantity
          unit
        }
      }
      possibleElements {
        id
        isOrigin
        remark
        quantity
        weight
        stockQuantity
        purchaseOrderQuantity
        unit
        supplyListElement {
          matterRef
          denomination
          quantityUnit
          basicQuantityUnit
          thickness
          length
          width
          matterId
          supplyCategoryId
        }
        amalgamGroup {
          reference
          matterRef
          isBlack
          isBlasted
          isPrimaryBlasted
          format
          isCut
          isEn1090
          elementId
          matterId
          supplyCategoryId
        }
        supplierOfferElements {
          id
          price
          deliveryDate
          isSelected
          selectedQuantity
          supplierOfferId
          variantId
          variant {
            id
            reference
            denomination
            matterRef
            quantity
            weight
            purchaseOrderQuantity
            format
            isBlack
            isBlasted
            isPrimaryBlasted
            isCut
            isEn1090
            quantityUnit
            thickness
            length
            width
            remark
            supplyCategoryId
            matterId
            elementId
            options {
              id
              type
              denomination
              quantity
              price
              unit
              variantId
            }
          }
          computedPrice
          unit
        }
        parentSupplyCategory{
          id
          name
        }
        hasPrice
        options{
          id
          type
          denomination
          quantity
          unit
          supplierOfferElementOptions{
            id
            price
            supplierOfferElementId
            supplierOfferElement {
              supplierOfferId
            }
          }
        }
      }
      totalPrice
      additionnalCosts {
        id
        inputPrice
        price
        denomination
        quantity
        unit
        supplierOfferId
        priceRequestAdditionnalCostId
      }
      totalAdditionnalCosts
    }
  }
`;

export const ASSOCIATE_SUPPLIERS_TO_PRICE_REQUEST = gql`
  mutation associateSuppliersToPriceRequest($priceRequestId: ID!, $supplierIds: [ID!]!){
    associateSuppliersToPriceRequest(priceRequestId: $priceRequestId, supplierIds: $supplierIds){
      id
      supplier{
        code
        name
      }
    }
  }
`;

export const DELETE_SUPPLIER_OFFER = gql`
  mutation deleteSupplierOffer($id: ID!){
    deleteSupplierOffer(id: $id)
  }
`;

export const DELETE_SUPPLIER_OFFERS = gql`
  mutation deleteSupplierOffers($ids: [ID!]!){
    deleteSupplierOffers(ids: $ids)
  }
`;

export const UPDATE_SUPPLIER_OFFER = gql`
  mutation updateSupplierOffer($id: ID!, $data: SupplierOfferUpdate!) {
    updateSupplierOffer(id: $id, data: $data){
      id
    }
  }
`;

export const UPDATE_SUPPLIER_OFFER_ELEMENT = gql`
  mutation updateSupplierOfferElement($id: ID!, $data: SupplierOfferElementUpdate!){
    updateSupplierOfferElement(id: $id, data: $data){
      id
    }
  }
`;

export const ASSOCIATE_SUPPLIER_OFFER_ELEMENTS = gql`
  mutation associateSupplierOfferElements($data: SupplierOfferElementAssociation!){
    associateSupplierOfferElements(data: $data){
      id
    }
  }
`;

export const SEND_PRICE_REQUEST_MAILS = gql`
  mutation sendPriceRequestMails($ids: [ID!]!) {
    sendPriceRequestMails(ids: $ids)
  }
`;

export const GET_VARIANT_WEIGHT = gql`
  query getVariantWeight($data: WeightData!) {
    getVariantWeight(data: $data)
  }
`;