import { Operation, OperationCreate } from "./operation.base";
import { IAction } from "../base";
import { Element } from "../elements/element.base";

export interface CuttingDefinedProperty {
    priceBySquareCm?: number;
}

export interface CuttingProperty extends CuttingDefinedProperty {
    angle1: number;
    angle2: number;
}

export interface ICuttable extends Element {
    section: number;
    Angle1: number;
    Angle2: number;
}

export interface CuttingBuild extends IAction<ICuttable, CuttingProperty> {
}

export class Cutting extends Operation<ICuttable, CuttingProperty, CuttingDefinedProperty> {

    public constructor(
        element: ICuttable,
        properties: CuttingProperty,
        operationCreate: OperationCreate<ICuttable, CuttingDefinedProperty>) {

        super(element, properties, operationCreate);
        element.Angle1 = properties.angle1;
        element.Angle2 = properties.angle2;
    }

    public get PriceBySquareCm(): number {
        return this.properties.priceBySquareCm !== null && this.properties.priceBySquareCm !== undefined ?
            this.properties.priceBySquareCm : this.definedProperties.priceBySquareCm;
    }

    public get angle1(): number {
        return this.properties.angle1;
    }

    public set angle1(value: number) {
      this.properties.angle1 = value;
      this.element.Angle1 = value;
    }

    public get angle2(): number {
      return this.properties.angle2;
    }

    public set angle2(value: number) {
      this.properties.angle2 = value;
      this.element.Angle2 = value;
    }

    public set PriceBySquareCm(value: number) {
        this.properties.priceBySquareCm = value;
    }

    public get CalculatedPrice(): number {
        const price1 = (this.element.section / Math.sin(this.angle1 * Math.PI / 180)) * this.PriceBySquareCm;
        const price2 = this.angle2 != null ? (this.element.section / Math.sin(this.angle2 * Math.PI / 180)) * this.PriceBySquareCm : 0;

        return price1 + price2;
    }

    protected updateAfterElementChange(): void {
        // delete this.properties.priceBySquareCm;
    }
}