import { Injectable } from "@angular/core";
import { Apollo } from "apollo-angular";
import { GET_SUPPLIERS, CREATE_SUPPLIER, DELETE_SUPPLIER, GET_SUPPLIER, UPDATE_SUPPLIER, GET_SUPPLIERS_WITH_CATEGORIES, GET_NEW_SUPPLIERS, SEARCH_SUPPLIERS } from "./suppliers.queries";
import { ISupplierInput, ISupplierContactInput, ISupplierMatterInput } from "app/facade/interfaces/supplier";
import { GET_SUPPLIER_CONTACTS, CREATE_SUPPLIER_CONTACT, UPDATE_SUPPLIER_CONTACT, SET_SUPPLIER_CONTACT_FAVORITE, DELETE_SUPPLIER_CONTACT } from "./suppliers-contacts.queries";
import { GET_MATTERS_BY_SUPPLIER, SET_MATTERS_OF_SUPPLIER } from "./matters.queries";
import { IPagination } from "app/facade/interfaces/crud.interface";

@Injectable({
  providedIn: "root"
})
export class SuppliersQueriesService {

  constructor(private _apollo: Apollo) { }

  /* SUPPLIER */
  public watchSupplierList() {
    return this._apollo.watchQuery({
      query: GET_SUPPLIERS,
      fetchPolicy: "network-only"
    });
  }

  public getSupplierListing(pagination?: IPagination) {
    return this._apollo.query({
      query: GET_NEW_SUPPLIERS,
      fetchPolicy: "no-cache",
      variables: {
        pagination : pagination || null
      }
    });
  }

  public getSupplierList() {
    return this._apollo.query({
      query: GET_SUPPLIERS_WITH_CATEGORIES,
      fetchPolicy: "network-only"
    });
  }

  public searchSupplier(search: string) {
    return this._apollo.query({
      query: SEARCH_SUPPLIERS,
      variables: { search },
      fetchPolicy: "no-cache"
    });
  }

  public getSupplierData(supplierId: number | string) {
    return this._apollo.query({
      query: GET_SUPPLIER,
      variables: { id: supplierId },
      fetchPolicy: "network-only"
    });
  }

  public addSupplier(supplierData: ISupplierInput) {
    return this._apollo.mutate({
      mutation: CREATE_SUPPLIER,
      variables: { supplierData }
    });
  }

  public updateSupplier(supplierId: number | string, supplierData: ISupplierInput) {
    return this._apollo.mutate({
      mutation: UPDATE_SUPPLIER,
      variables: {id: supplierId, data: supplierData}
    });
  }

  public deleteSupplier(supplierId: number) {
    return this._apollo.mutate({
      mutation: DELETE_SUPPLIER,
      variables: { id: supplierId }
    });
  }

  /* SUPPLIER CONTACTS */
  public watchSupplierContact(supplierId: number | string) {
    return this._apollo.watchQuery({
      query: GET_SUPPLIER_CONTACTS,
      variables: { supplierId },
      fetchPolicy: "network-only"
    });
  }

  public getSupplierContact(supplierId: number | string) {
    return this._apollo.query({
      query: GET_SUPPLIER_CONTACTS,
      variables: { supplierId },
      fetchPolicy: "network-only"
    });
  }

  public addSupplierContact(contactData: ISupplierContactInput, supplierId: number | string) {
    return this._apollo.mutate({
      mutation: CREATE_SUPPLIER_CONTACT,
      variables: {data: {...contactData, supplierId}},
      refetchQueries: [{
        query: GET_SUPPLIER_CONTACTS,
        variables: { supplierId }
      }]
    });
  }

  public updateSupplierContact(contactId: number | string, contactData: ISupplierContactInput, supplierId: number | string) {
    return this._apollo.mutate({
      mutation: UPDATE_SUPPLIER_CONTACT,
      variables: {id: contactId, data: contactData},
      refetchQueries: [{
        query: GET_SUPPLIER_CONTACTS,
        variables: { supplierId }
      }]
    });
  }

  public updateFavoriteSupplierContact(id: number | string, supplierId: number | string) {
    return this._apollo.mutate({
      mutation: SET_SUPPLIER_CONTACT_FAVORITE,
      variables: {id, supplierId}
    });
  }

  public deleteSupplierContact(contactId: number | string, supplierId: number | string) {
    return this._apollo.mutate({
      mutation: DELETE_SUPPLIER_CONTACT,
      variables: { id: contactId}
    });
  }

  public getMattersBySupplier(supplierId: number) {
    return this._apollo.query({
      query: GET_MATTERS_BY_SUPPLIER,
      variables: { supplierId },
      fetchPolicy: "network-only"
    });
  }

  public updateSupplierMatters(supplierId: number, data: ISupplierMatterInput[]) {
    return this._apollo.mutate({
      mutation: SET_MATTERS_OF_SUPPLIER,
      variables: { supplierId, data }
    });
  }
}
