import { Matter } from "app/facade/interfaces";
import { Element } from "../../element.base";
import { IWeldiableMeterable } from "../../../assemblages/welding";
import { MM_TO_M, SQUARE_MM_TO_SQUARE_CM, SQUARE_MM_TO_SQUARE_M, calculateNewValueWithCuttinAngle } from "../../../base";
import { IDrillThickness } from "app/facade/quote-element-definition/operations/drilling";

const A_L_RATIO: number = 1000000;

export interface PoutrelleNatureValues {
    A: number;
    B: number;
    H: number;
    R: number;
    A_L: number;
    I_y: number;
    I_z: number;
    T_f: number;
    T_w: number;
    i_y: number;
    i_z: number;
    Kg_m: number;
    W_ely: number;
    W_elz: number;
    // Not in "base" beams
    R2?: number;
    T_f1?: number;
}

export interface PoutrelleProperty {
    length: number;
    matter: Matter;
    angle1?: number;
    angle2?: number;
}

export abstract class Poutrelle extends Element implements IWeldiableMeterable, IDrillThickness {

    public constructor(name: string,
                       reference: string,
                       protected values: Partial<PoutrelleNatureValues>,
                       protected properties: PoutrelleProperty) {
        super(name, reference);
    }

    public get Matter(): Matter {
        return this.properties.matter;
    }

    public set Properties(properties: PoutrelleProperty) {
        this.properties = { ...properties };
        this.elementUpdated();
    }

    public set Values(values: Partial<PoutrelleNatureValues>) {
        this.values = { ...values };
        this.elementUpdated();
    }

    public get CalculatedPrice(): number {
        // length is in mm
        return (this.properties.length * MM_TO_M) * this.values.Kg_m * this.properties.matter.pricePerKg;
    }

    public get meterForWelding(): number {
        const H_i = this.values.H - (this.values.T_f * 2);
        const D = H_i - this.values.R;

        return (this.values.B * 2
            + this.values.R * 4
            + this.values.T_f * 4
            + (this.values.B - (this.values.R + this.values.T_w)) * 2
            + D * 2) * MM_TO_M;
    }

    public meterForWeldingWithCut(angle: number): number {
      const H_i = this.values.H - (this.values.T_f * 2),
            D = H_i - this.values.R,
            B = calculateNewValueWithCuttinAngle(this.values.B, angle),
            B_i = calculateNewValueWithCuttinAngle((B - (this.values.R + this.values.T_w)), angle);
      return (B * 2
          + this.values.R * 4
          + this.values.T_f * 4
          + B_i * 2
          + D * 2) * MM_TO_M;
    }

    public get section(): number {
        return this.values.A * SQUARE_MM_TO_SQUARE_CM;
    }

    public get thickness(): number {
        return Math.min(this.values.T_w, this.values.T_f);
    }

    public get drillThickness(): number {
        return this.values.T_f || this.values.T_f1;
    }

    public get TotalArea(): number {
        return (this.values.A_L * SQUARE_MM_TO_SQUARE_M) * (this.properties.length * MM_TO_M);
    }

    public get TotalWeight(): number {
        return this.values.Kg_m * (this.properties.length * MM_TO_M);
    }

    public get InnerWidth(): number {
        return Math.ceil((this.values.B - this.values.T_w) / 2);
    }

    public get InnerHeight(): number {
        return Math.ceil(this.values.H - (this.values.T_f * 2));
    }

    public get topLength(): number {
        return this.InnerHeight;
    }

    public get Angle1(): number {
      return this.properties.angle1;
    }

    public set Angle1(value: number) {
      if (value === null) {
        delete this.properties.angle1;
      } else {
        this.properties.angle1 = value;
      }
    }

    public get Angle2(): number {
      return this.properties.angle2 || 90;
    }

    public set Angle2(value: number) {
      if (value === null) {
        delete this.properties.angle2;
      } else {
        this.properties.angle2 = value;
      }
    }
}