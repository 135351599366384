import { Component, OnInit, Inject } from "@angular/core";
import { StepSummaryBase } from "../../base/step-summary-base";
import { CONTAINER_DATA, StepFormService } from "app/presentationnal/organisms/wizard-modal/service/step-form.service";
import { FormBuilder } from "@angular/forms";

@Component({
  selector: "app-equerre-summary",
  templateUrl: "./equerre-summary.component.html",
  styleUrls: ["./equerre-summary.component.css"]
})
export class EquerreSummaryComponent extends StepSummaryBase implements OnInit {

  constructor(@Inject(CONTAINER_DATA) data: any,
              protected _stepFormSrv: StepFormService,
              protected _fb: FormBuilder) {
    super(data, _stepFormSrv, _fb);
  }

  public formatFoldingSummary(customData: any): any[] {
    return [
      { name : "Hauteur", value: customData.properties.height, suffix: "mm" },
      { name : "Base", value: customData.properties.base, suffix: "mm" },
      { name : "Largeur", value: customData.properties.width, suffix: "mm" },
      { name : "Prix du pliage", value: customData.properties.foldingPrice, suffix: "€" },
      { name : "Coût additionnel unitaire", value: customData.UnitaryFoldingAdditionalCost, suffix: "€" },
      { name : "Coût additionnel global", value: customData.GlobalFoldingAdditionalCost, suffix: "€" },
      { name : "Remarques", value: customData.FoldingRemarks, suffix: "" },
    ];
  }

  protected formatWeldingSummary(weldingData: any, customData: any): any[] {
    const tempWeldingSummary: any[] =  super.formatWeldingSummary(weldingData, customData);
    tempWeldingSummary.unshift( { name : "Coller au sommet", value: ((customData.properties.stickTop) ? "Oui" : "Non"), suffix: "" });
    return tempWeldingSummary;
  }

}
