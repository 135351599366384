import { Injectable } from "@angular/core";
import { ConnectionQueriesService } from "../queries/connection/connection-queries.service";
import { AuthService } from "@lib/auth/auth.service";

@Injectable({
  providedIn: "root"
})
export class ConnectionService {
  private _refresh: boolean = false;
  private _refreshTime: number = 60 * 15; // Secondes!!

  constructor(private _authSrv: AuthService, private _connectionQueriesSrv: ConnectionQueriesService) {}

  private refreshToken() {
    setTimeout( () => {
      if (this._refresh) {
        if (this._authSrv.isLoggedIn()) {
          this._connectionQueriesSrv.pingBack().subscribe().unsubscribe();
        }
        this.refreshToken();
      }
    }, this._refreshTime * 1000);
  }

  public setRefresh( value: boolean) {
    this._refresh = value;
  }

  public startRefreshToken() {
    this._refresh = true;
    this.refreshToken();
  }

  public endRefreshToken() {
    this._refresh = false;
  }
}
