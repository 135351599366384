import { Component, OnInit, Inject, OnDestroy } from "@angular/core";
import { ModalContentForm } from "../../model/modal-content-form";
import { ModalService, DATA } from "../../services/modal.service";
import { SnackService } from "app/presentationnal/organisms/snack-bar/services/snack.service";
import { FormBuilder } from "@angular/forms";
import { IPurchaseOrder } from "app/facade/interfaces/purchase-order.interface";
import { IModalData } from "../../interfaces/modal-data.interface";
import { MODAL_CLOSE_ACTION } from "../../enums/modal-close-action.enum";
import { EditScanPdfInput, ScanPdf } from "app/facade/interfaces/scan-pdf.interface";
import { ScanPdfQueriesService } from "app/facade/queries/scan-pdf/scan-pdf.queries.service";

interface IEditScanPdfModalData extends IModalData {
  data: {
    scanPdf: ScanPdf;
  };
}

@Component({
  selector: 'app-modal-edit-scan-pdf',
  templateUrl: './modal-edit-scan-pdf.component.html',
  styleUrls: ['./modal-edit-scan-pdf.component.css']
})
export class ModalEditScanPdfComponent extends ModalContentForm implements OnInit, OnDestroy {

  private _scanPdf: ScanPdf;

  constructor(
    protected _modalService: ModalService,
    protected _snackBar: SnackService,
    protected _fb: FormBuilder,
    @Inject(DATA) private _data: IEditScanPdfModalData,
    private _scanPdfQueriesSrv: ScanPdfQueriesService,
  ) {
    super(_modalService, _fb, _snackBar);
    this._scanPdf = this._data && this._data.data && this._data.data.scanPdf ? this._data.data.scanPdf : null;
  }

  ngOnInit() {
    this.initForm();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  private initForm() {
    this._formGroup = this._fb.group({
      name: this._scanPdf.name,
      comment: this._scanPdf.comment,
    });
  }

  protected save(data: IPurchaseOrder): void {
    if (this._formGroup.valid) {
      const objData = {
        confirm: true,
        data
      };
      this.afterClosed.next(objData);
    }
  }

  public confirmModal(): void {
    if (this._formGroup.valid) {
      switch (this._data.closeAction) {
        case MODAL_CLOSE_ACTION.SAVE:
          this.editScanPdf();
          break;
      }
    } else {
        this.markFormGroupTouched(this._formGroup);
        this.displayErrorMessage();
    }
  }

  /**
   * @description Send request to edit Purchase Order. Closes the modal if successfull, displays error if not
   * @author Quentin Wolfs
   * @private
   * @memberof ModalEditPurchaseOrderComponent
   */
  private editScanPdf(): void {
    const element: EditScanPdfInput = {
      name: this._formGroup.value.name,
      comment: this._formGroup.value.comment,
    };
    this._scanPdfQueriesSrv.editScanPdf(element, +this._scanPdf.id).subscribe(result => {
      // console.log(result);
      const resultData: any = result.data;
      if (resultData && resultData.editScanpdf) {
        this._snackBar.open(this._data.title, "Le scan PDF a été modifié", "success", 5000);
        this._modalService.closeModal();
        setTimeout(() => document.location.reload(), 200);
      } else {
        this._snackBar.openSnackBarError(this._data.title, "Le scan PDF n'a pas pu être modifié");
      }
    } , error => {
      this._snackBar.openSnackBarError(this._data.title, "Le scan PDF n'a pas pu être modifié", error);
    });
  }

}



