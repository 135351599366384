import { Component, OnInit, Inject } from "@angular/core";
import { ModalContentForm } from "../../model/modal-content-form";
import { ModalService, DATA } from "../../services/modal.service";
import { SnackService } from "app/presentationnal/organisms/snack-bar/services/snack.service";
import { FormBuilder } from "@angular/forms";
import { ISelectOption } from "app/presentationnal/atoms/inputs/select-input/selectOptions";
import { IMovePartDatas } from "app/facade/interfaces/barset.interface";

@Component({
  selector: "app-modal-move-amalgam-part",
  templateUrl: "./modal-move-amalgam-part.component.html",
  styleUrls: ["./modal-move-amalgam-part.component.css"]
})
export class ModalMoveAmalgamPartComponent extends ModalContentForm implements OnInit {
  private _modalMoveAmalgamPartData: IMovePartDatas;
  public barsetOptions: ISelectOption[];

  constructor(
    protected _modalService: ModalService,
    protected _snackBar: SnackService,
    protected _fb: FormBuilder,
    @Inject(DATA) private _data) {
      super(_modalService, _fb, _snackBar);
      this._modalMoveAmalgamPartData = this._data.data;
  }

  ngOnInit() {
    this.initForm();
  }

  private initForm() {
    this.barsetOptions = this._modalMoveAmalgamPartData.barsetList.map( barset => {
      return {
        value: barset.id,
        label: `[${barset.key}] ${barset.reference} - ${barset.sizeLabel}`,
      };
    });
    this.barsetOptions.push({
      value: null,
      label: "Nouvel amalgame"
    });

    this._formGroup = this._fb.group({
      barsetId: this.barsetOptions[0].value,
    });
  }

  protected save(): void {
    const objData = {
      confirm: true,
      data: this._formGroup.value
    };
    this.afterClosed.next(objData);
  }

}
