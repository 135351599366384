import { Option } from "./option.base";
import { ITicketElement } from "app/presentationnal/organisms";

export interface TransportProperty {
    transportPrice: number;
}

export class Transport extends Option<TransportProperty> {

    public constructor(values: TransportProperty,
      protected ticket: ITicketElement[]) {
        super(values, ticket);
    }

    public get TransportPrice(): number {
        return this.values.transportPrice;
    }

    public set TransportPrice(value: number) {
        this.values.transportPrice = value;
    }

    public get CalculatedPrice(): number {
        return this.TransportPrice;
    }
}