import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "app-labeled-input",
  templateUrl: "./labeled-input.component.html",
  styleUrls: ["./labeled-input.component.css"]
})
export class LabeledInputComponent implements OnInit {

  @Input() public truncate: boolean = false;
  @Input("big") public big: boolean = false;
  @Input("inputType") public inputType: string = "text";
  @Input("for") public for: string = "";
  @Input("label") public label: string = "";
  @Input() public required: boolean = false;
  @Input() icon: string;
  @Input() tooltip: string;

  constructor() { }

  ngOnInit() {
    if (this.required) { this.label += "*"; }
  }

}
