import { Injectable } from "@angular/core";
import { ILaserCuttable, LaserCuttingProperty } from "./laser-cutting";
import { IBendable, BendingProperty } from "./bending";
import { ICuttable, CuttingProperty } from "./cutting";
import { IDrillable, DrillingProperty } from "./drilling";
import { IFoldable, FoldingProperty } from "./folding";
import { LASER_CUTTING, BENDING, CUTTING, DRILLING, FOLDING } from "./operation.queries";
import { Apollo } from "apollo-angular";
import { OPERATIONS_MAP } from "../use-class-map";
import { Operation, OperationCreate } from "./operation.base";
import { Element } from "../elements/element.base";
import { IAction } from "../base";
import { map } from "rxjs/operators";

type OperationAvailable =
    "LaserCutting" |
    "Bending" |
    "Cutting" |
    "Drilling" |
    "Folding";

type OperationBuild<T extends OperationAvailable> =
    T extends "LaserCutting" ? IAction<ILaserCuttable, LaserCuttingProperty> :
    T extends "Bending" ? IAction<IBendable, BendingProperty> :
    T extends "Cutting" ? IAction<ICuttable, CuttingProperty> :
    T extends "Drilling" ? IAction<IDrillable, DrillingProperty> :
    T extends "Folding" ? IAction<IFoldable, FoldingProperty> : never;

const QUERY_MAP: Map<OperationAvailable, any> = new Map<OperationAvailable, any>([
    [ "LaserCutting", LASER_CUTTING ],
    [ "Bending", BENDING ],
    [ "Cutting", CUTTING ],
    [ "Drilling", DRILLING ],
    [ "Folding", FOLDING ]
]);

function extractSearchActionData({ searchAction }): any {
    return { ...searchAction[0].natureValues };
}

function extractActionByThicknessData({ getActionByThickness }): any {
    return Object.assign(getActionByThickness.natureValues, getActionByThickness.matter);
}

function extractActionByParametersData({ getActionByParameters }): any {
    return Object.assign(getActionByParameters.natureValues, getActionByParameters.matter);
}

const EXTRACT_DATA_MAP: Map<OperationAvailable, (data) => any> = new Map<OperationAvailable, (data) => any>([
    [ "LaserCutting", extractActionByThicknessData ],
    [ "Bending", extractActionByParametersData ],
    [ "Cutting", extractSearchActionData ],
    [ "Drilling", extractActionByThicknessData ],
    [ "Folding", extractActionByParametersData ]
]);

function extractDrillThickness(el: any) { return { thickness: el.drillThickness }; }
function extractThickness(el: any) { return { thickness: el.thickness }; }
function extractThicknessAndMatter(el: any) { return { thickness: el.thickness, matterId: el.Matter ? el.Matter.id : undefined }; }
function extractThicknessAndDiameter(el: any) { return { thickness: el.thickness, diameter: el.diameter }; }
function extractSection(el: any) { return { section: el.section }; }

const EXTRACT_ELEMENT_PARAMETERS_MAP: Map<OperationAvailable, (el: any) => any> = new Map<OperationAvailable, (el: Element) => any>([
    [ "LaserCutting", extractThicknessAndMatter ],
    [ "Bending", extractThicknessAndDiameter ],
    [ "Cutting", extractSection ],
    [ "Drilling", extractDrillThickness ],
    [ "Folding", extractThickness ]
]);

@Injectable()
export class OperationService {

    public constructor(private _apollo: Apollo) {}

    public create<T extends OperationAvailable>(operation: T, build: OperationBuild<T>) {
        const query: any = QUERY_MAP.get(operation);
        const ctor: new (element: Element, properties: any, operationCreate: OperationCreate<any, any>) => Operation<any, any, any> = OPERATIONS_MAP[operation];
        const paramsExtract: (data) => any = EXTRACT_ELEMENT_PARAMETERS_MAP.get(operation);
        const extract: (data) => any = EXTRACT_DATA_MAP.get(operation);

        const operationCreate: OperationCreate<any, any> = (el: any) => {
            const variables: any = { params: paramsExtract(el) };
            const queryWithArgs = !!variables && !!variables.params ? { query, variables } : { query };

            return this._apollo.query<any>(queryWithArgs).pipe(map(data => extract(data.data)));
        };
        const additionnalData: string[] = ["GlobalAdditionalCost", "UnitaryAdditionalCost", "Remarks"],
              tempAdditionnal: any = {};
        additionnalData.forEach( dataName => {
          if (build.properties.hasOwnProperty(dataName)) {
            tempAdditionnal[dataName] = build.properties[dataName];
            delete build.properties[dataName];
          }
        });
        const newOperation: Operation<any, any, any> = new ctor(build.element, build.properties, operationCreate);
        return newOperation.$ready.pipe(map(done => {
          newOperation.ParentQuantity = build.element.Quantity;
          Object.keys(tempAdditionnal).forEach( key => {
            newOperation[key] = tempAdditionnal[key];
          });
          return newOperation;
        }));
    }

    public getCuttingData() {
      return this._apollo.query({
        query: CUTTING,
        fetchPolicy: "network-only"
      });
    }

    public getDrillingData(thickness) {
      return this._apollo.query({
        query: DRILLING,
        variables: {
          params: {
            thickness
          }
        }
      });
    }
}