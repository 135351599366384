/*
 * @Author: Kevin Palade
 * @Date: 2018-09-11 14:20:32
 * @Last Modified by: Kevin Palade
 * @Last Modified time: 2018-09-11 14:26:17
 */
export * from "./components/modal-add-quotes-project/modal-add-quotes-project.component";
export * from "./components/modal-confirm/modal-confirm.component";
export * from "./components/modal-edit-remarks/modal-edit-remarks.component";
export * from "./components/modal-add-supplier/modal-add-supplier.component";
export * from "./components/modal-add-supplier-contact/modal-add-supplier-contact.component";
export * from "./components/modal-add-project/modal-add-project.component";
export * from "./components/modal-add-supply-list/modal-add-supply-list.component";
export * from "./components/modal-add-price-request/modal-add-price-request.component";
export * from "./components/modal-link-supply-list/modal-link-supply-list.component";
export * from "./components/modal-move-amalgam-part/modal-move-amalgam-part.component";
export * from "./components/modal-generate-new-amalgams/modal-generate-new-amalgams.component";
export * from "./components/modal-add-purchase-order/modal-add-purchase-order.component";
export * from "./components/modal-add-supplier-offer/modal-add-supplier-offer.component";
export * from "./components/modal-encode-supplier-offer/modal-encode-supplier-offer.component";
export * from "./components/modal-update-price-request-element-remark/modal-update-price-request-element-remark.component";
export * from "./components/modal-update-supplier-offer-contact/modal-update-supplier-offer-contact.component";
export * from "./components/modal-update-barset-stock-position/modal-update-barset-stock-position.component";
export * from "./components/modal-select-supplier-offer/modal-select-supplier-offer.component";
export * from "./components/modal-inpdate-price-request-element-option/modal-inpdate-price-request-element-option.component";
export * from "./components/modal-inpdate-price-request-additionnal-cost/modal-inpdate-price-request-additionnal-cost.component";
export * from "./components/modal-update-supplier-offer-elements-list/modal-update-supplier-offer-elements-list.component";
export * from "./components/modal-consult-created-purchase-order/modal-consult-created-purchase-order.component";
export * from "./components/modal-inpdate-purchase-order-element/modal-inpdate-purchase-order-element.component";
export * from "./components/modal-select-purchase-order-elements-to-generate-labels-pdf/modal-select-purchase-order-elements-to-generate-labels-pdf.component";
export * from "./components/modal-inpdate-purchase-order-additionnal-cost/modal-inpdate-purchase-order-additionnal-cost.component";
export * from "./components/modal-set-custom-amalgam-format/modal-set-custom-amalgam-format.component";
export * from "./components/modal-add-scan-pdf/modal-add-scan-pdf.component"
export * from "./components/modal-edit-scan-pdf/modal-edit-scan-pdf.component"
export * from "./components/modal-show-scan-pdf/modal-show-scan-pdf.component"
export * from "./components/modal-add-admission/modal-add-admission.component"
