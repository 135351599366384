import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { OrganismsModule } from "app/presentationnal/organisms/organisms.module";
import { ListingProjectComponent } from "./listing-project/listing-project.component";
import { ProjectsQueriesService } from "app/facade/queries/project/projects-queries.service";
import { ProjectDetailsComponent } from "./project-details/project-details.component";

@NgModule({
  imports: [
    CommonModule,
    OrganismsModule
  ],
  declarations: [
    ListingProjectComponent,
    ProjectDetailsComponent
  ],
  providers: [
    ProjectsQueriesService
  ]
})
export class ProjectsModule { }
