import { Poutrelle, PoutrelleProperty } from "./poutrelle";
import { MM_TO_M, calculateNewValueWithCuttinAngle } from "app/facade/quote-element-definition/base";

export interface IpnValues {
    H: number;
    B: number;
    T_w: number;
    T_f: number;
    R: number;
    A_L: number;
    A: number;
    I_y: number;
    I_z: number;
    i_y: number;
    i_z: number;
    W_ely: number;
    W_elz: number;
    Kg_m: number;
    R2: number;
    F_sr: number;
}

export class Ipn extends Poutrelle {

    public constructor(
        name: string,
        reference: string,
        values: IpnValues,
        properties: PoutrelleProperty) {
        super(name, reference, values, properties);
    }

    public get meterForWelding(): number {
        const H_i = this.values.H - (this.values.T_f * 2);
        const D = H_i - this.values.R;

        return (this.values.B * 2
            + this.values.R * 4
            + this.values.R2 * 4
            + (this.values.B - this.values.R - this.values.R2 - this.values.T_w) * 2
            + D * 2) * MM_TO_M;
    }

    public meterForWeldingWithCut(angle: number): number {
      const H_i = this.values.H - (this.values.T_f * 2),
            D = H_i - this.values.R,
            B = calculateNewValueWithCuttinAngle(this.values.B, angle),
            B_i = calculateNewValueWithCuttinAngle((this.values.B - this.values.R - this.values.R2 - this.values.T_w), angle);
      return (B * 2
          + this.values.R * 4
          + this.values.R2 * 4
          + this.values.T_f * 4
          + B_i * 2
          + D * 2) * MM_TO_M;
    }
}