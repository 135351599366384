import { Option, HOURS_PER_DAY } from "./option.base";
import { ITicketElement } from "app/presentationnal/organisms";

export interface NacelleProperty {
    personQuantity: number;
    dayQuantity: number;
    hourlyRate: number;
}

export class Nacelle extends Option<NacelleProperty> {

    public constructor(values: NacelleProperty,
      protected ticket: ITicketElement[]) {
        super(values, ticket);
    }

    public get PersonQuantity(): number {
        return this.values.personQuantity;
    }

    public set PersonQuantity(value: number) {
        this.values.personQuantity = value;
    }

    public get DayQuantity(): number {
        return this.values.dayQuantity;
    }

    public set DayQuantity(value: number) {
        this.values.dayQuantity = value;
    }

    public get HourlyRate(): number {
        return this.values.hourlyRate;
    }

    public set HourlyRate(value: number) {
        this.values.hourlyRate = value;
    }

    public get CalculatedPrice(): number {
        return this.PersonQuantity * (this.DayQuantity * HOURS_PER_DAY) * this.HourlyRate;
    }
}