import { IPresetSize, IBarsetZoom } from "../interfaces/barset.interface";

export const AMALGAM_MIN_SIZE: number = 6000; // unused
export const AMALGAM_MAX_FALL: number = 3000;

export const SUPPLY_CATEGORIE_IDS_FOR_TUBE: number[] = [8, 25, 26, 27];

export enum BARSET_ITEM_SIZE_LABELS {
  S6 = "6 M",
  S10 = "10 M",
  S11 = "11 M",
  S12 = "12 M",
  S13 = "13 M",
  S14 = "14 M",
  S15 = "15 M",
  S16 = "16 M",
  S17 = "17 M",
  S18 = "18 M",
  S19 = "19 M",
  S24 = "24 M",
  CUSTOM = "Sur mesure",
  MANUAL = "Manuel"
}

export const BARSET_ITEM_SIZES_CONFIG: IPresetSize[] = [
  { label: BARSET_ITEM_SIZE_LABELS.S6, value: 6000, tubeSize: true },
  { label: BARSET_ITEM_SIZE_LABELS.S10, value: 10000, tubeSize: false },
  { label: BARSET_ITEM_SIZE_LABELS.S11, value: 11000, tubeSize: false },
  { label: BARSET_ITEM_SIZE_LABELS.S12, value: 12000, tubeSize: true },
  { label: BARSET_ITEM_SIZE_LABELS.S13, value: 13000, tubeSize: false },
  { label: BARSET_ITEM_SIZE_LABELS.S14, value: 14000, tubeSize: false },
  { label: BARSET_ITEM_SIZE_LABELS.S15, value: 15000, tubeSize: false },
  { label: BARSET_ITEM_SIZE_LABELS.S16, value: 16000, tubeSize: false },
  { label: BARSET_ITEM_SIZE_LABELS.S17, value: 17000, tubeSize: false },
  { label: BARSET_ITEM_SIZE_LABELS.S18, value: 18000, tubeSize: false },
  { label: BARSET_ITEM_SIZE_LABELS.S19, value: 19000, tubeSize: false },
  { label: BARSET_ITEM_SIZE_LABELS.S24, value: 24000, tubeSize: false },
  { label: BARSET_ITEM_SIZE_LABELS.CUSTOM, value: null, tubeSize: true },
  { label: BARSET_ITEM_SIZE_LABELS.MANUAL, value: 0, tubeSize: true }
];

export const BARSET_DEFAULT_ZOOM_SIZE: number = 12000;
export const BARSET_ZOOM_CONFIG: IBarsetZoom[] = [
  { label: "50% - 24m", size: 24000, selected: false },
  { label: "66.6% - 18m", size: 18000, selected: false },
  { label: "80% - 15m", size: 15000, selected: false },
  { label: "100% - 12m", size: 12000, selected: true },
  { label: "133.3% - 9m", size: 9000, selected: false },
  { label: "200% - 6m", size: 6000, selected: false },
  { label: "400% - 3m", size: 3000, selected: false },
  { label: "1200% - 1m", size: 1000, selected: false },
];

export const BARSET_SCALE_MAX_VALUE: number = 24;