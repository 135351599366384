import { Component, OnInit, Renderer2, ElementRef, Input, Output, EventEmitter } from "@angular/core";
import { IBarsetItemContent } from "app/facade/interfaces/barset.interface";

@Component({
  selector: "app-barset-bar-content",
  templateUrl: "./barset-bar-content.component.html",
  styleUrls: ["./barset-bar-content.component.css"]
})
export class BarsetBarContentComponent implements OnInit {
  @Input() public barsetItemContent: IBarsetItemContent;
  @Input() public isEdited: boolean;
  @Output() public onMoveAmalgamPart: EventEmitter<IBarsetItemContent> = new EventEmitter<IBarsetItemContent>();

  constructor(
    private _renderer: Renderer2,
    private _elementRef: ElementRef) { }

  ngOnInit() {
    this._renderer.addClass(this._elementRef.nativeElement, "barset-bar-information");
    this._renderer.setStyle(this._elementRef.nativeElement, "width", this.barsetItemContent.size);
  }

  public moveAmalgamPart() {
    this.onMoveAmalgamPart.emit(this.barsetItemContent);
  }


}
