import { Component, OnInit, Inject } from "@angular/core";
import { CONTAINER_DATA, StepFormService } from "app/presentationnal/organisms/wizard-modal/service/step-form.service";
import { CatalogQueriesService } from "app/facade/queries/catalog/async/catalog_queries.service";
import { CustomElementService } from "app/facade/quote-element-definition/elements/customs/custom.service";
import { QuotesQueriesService } from "app/facade/queries/quote/async/quotes_queries.service";
import { FormBuilder } from "@angular/forms";
import { StepZeroBase } from "../../base/step-zero-base";

@Component({
  selector: "app-gousset-step-zero",
  templateUrl: "./gousset-step-zero.component.html",
  styleUrls: ["./gousset-step-zero.component.css"]
})
export class GoussetStepZeroComponent extends StepZeroBase implements OnInit {

  constructor(
    @Inject(CONTAINER_DATA) data: any,
              protected _stepFormSrv: StepFormService,
              protected _catalogQueriesSrv: CatalogQueriesService,
              protected _customElementSrv: CustomElementService,
              protected _quoteQueriesSrv: QuotesQueriesService,
              protected _fb: FormBuilder
              ) {
    super(data, _stepFormSrv, _catalogQueriesSrv, _customElementSrv, _quoteQueriesSrv, _fb);
  }

}
