import { Component, OnInit, Renderer2, Output, EventEmitter } from "@angular/core";
import { AInputs } from "../inputs";

@Component({
  selector: "app-password-input",
  templateUrl: "./password-input.component.html",
  styleUrls: ["./password-input.component.css"]
})
export class PasswordInputComponent extends AInputs implements OnInit {

  @Output() public enter: EventEmitter<void>;

  public constructor(protected renderer: Renderer2) {
    super(renderer);
    this.enter = new EventEmitter<void>();
  }

  public ngOnInit() {
  }

  public keyUp(event: KeyboardEvent) {
    const keyCode = event.key || event.keyCode
    if (keyCode == 'Enter') {
      this.enter.emit();
    }
  }
}
