import { QuoteElementBase, QuoteElementType, quoteTotal } from "../base";
import { ITicketElement } from "app/presentationnal/organisms/wizard-modal/service/step-form.service";

export const HOURS_PER_DAY = 10;

export abstract class Option<T> extends QuoteElementBase {

    protected quoteTotalWeight: number;

    public constructor(
      protected values: T,
      protected ticket: ITicketElement[]) {
        super(name);
    }

    public get Type(): QuoteElementType {
        return "OPTION";
    }

    public get Price(): number {
        return this.CalculatedPrice;
    }

    public updateWeight(elements: ITicketElement[]) {
        this.quoteTotalWeight = quoteTotal(elements, "WEIGHT");
    }

    public get totalWeight(): number {
        return this.quoteTotalWeight;
    }

    public abstract get CalculatedPrice(): number;

    public set Ticket(value: ITicketElement[]) {
      this.ticket = value;
    }
}