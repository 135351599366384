import { Component, OnInit, Input, OnDestroy } from "@angular/core";
import { FormGroup, FormArray, FormBuilder } from "@angular/forms";
import { Subscription } from "rxjs";

@Component({
  selector: "app-tree-checkbox",
  templateUrl: "./tree-checkbox.component.html",
  styleUrls: ["./tree-checkbox.component.css"]
})
export class TreeCheckboxComponent implements OnInit, OnDestroy {
  private _valueChangesSubscription: Subscription;
  @Input() public formGroupData: FormGroup;
  @Input() public isActif: boolean = true;
  @Input() public labelName: string = "name";
  @Input() public childName: string = "children";
  @Input() public hasChildren: boolean = true;
  public parent: FormGroup;
  public children: FormArray = new FormArray([]);

  constructor( protected _fb: FormBuilder) {
  }

  ngOnInit() {
    const formDataValues: any = {...this.formGroupData.value};
    delete formDataValues[this.childName];
    this.parent = this._fb.group(formDataValues);
    if (this.hasChildren) { this.children = <FormArray>this.formGroupData.get(this.childName); }
    this.checkFormDataChange();
  }

  private checkFormDataChange() {
    this._valueChangesSubscription = this.parent.valueChanges.subscribe( formValues => {
      this.formGroupData.get("selected").patchValue(formValues.selected);
      this.formGroupData.get("selected").markAsTouched();
      if (this.hasChildren) {
        const childData: FormArray = <FormArray>this.formGroupData.get(this.childName);
        childData.controls.forEach( child => {
          child.get("selected").patchValue(formValues.selected);
          child.get("selected").markAsTouched();
        });
      }
    });
  }

  ngOnDestroy(): void {
    this._valueChangesSubscription.unsubscribe();
  }

  public onChangeSelected(newValue: boolean) {
    this._valueChangesSubscription.unsubscribe();
    const children: FormArray = <FormArray>this.formGroupData.get(this.childName);
    let selectParent: boolean = false;
    children.controls.forEach(childFormGroup => {
      if (childFormGroup.get("selected").value) {
        selectParent = true;
      }
    });
    this.parent.get("selected").patchValue(selectParent);
    this.formGroupData.get("selected").patchValue(selectParent);
    this.formGroupData.get("selected").markAsTouched();
    this.checkFormDataChange();
  }

}
