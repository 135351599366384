/*
 * @Author: Kevin Palade
 * @Date: 2018-10-30 15:47:47
 * @Last Modified by: Kevin Palade
 * @Last Modified time: 2018-11-14 16:58:15
 */
import gql from "graphql-tag";

export const GET_ELEMENT_GROUP_BY_ID = gql`
query getElementGroupById($elementGroupId: ID!) {
    getElementGroupById(elementGroupId: $elementGroupId){
        useClass
        name
        icon
        id
    }
}`;

export const GET_ELEMENT_GROUP_BY_ID_FOR_TABLE = gql`query($elementGroupId: ID!){
    getElementGroupById(elementGroupId: $elementGroupId){
        id
        name
        icon
        availableMatters{
            id
            en1090Name
            name
        }
        elementNatureDefinitions{
            name
            regex
            nullable
            redefine
            id
            type
        }
        elements{
            id
            name
            natureValues
            matter
            {
                id
                name
                en1090Name
            }
        }
    }
}`;

export const LIST_CATEGORIES = gql`query categories {
    categories{
        id,
        name,
        icon,
    }
}`;

export const LIST_CATEGORIES_WITH_CHILDREN = gql`query categories {
    categories{
        id,
        name,
        icon,
        childrenCategories{
            name
        }
        childrenElementGroups{
            id,
            name,
            icon,
            useClass,
            category{
                name,
                id
            }
        }
    }
}`;

export const LIST_ELEM_GRP = gql`
query category($id: ID!) {
    category(id: $id){
        childrenElementGroups{
            id
            name
            icon
            useClass
        }
    }
}`;

export const GET_CATEGORY_BY_ID = gql`query($categoryId: ID!){
    category(id: $categoryId){
        id
        name
        icon
        childrenElementGroups{
            id
            name
            icon
            category{
                name
                id
            }
        }
    }
}`;

export const UPDATE_ELEMENT = gql`mutation($id:ID!,$element:UpdateElement!){
    updateElement(id:$id,element:$element){
        id,
        name
    }
}`;

export const ADD_ELEMENT = gql`mutation($element:InputElement!){
    addElement(element:$element){
        id,
        name
    }
}`;

export const DELETE_ELEMENT = gql`mutation($id:ID!){
    deleteElement(id:$id)
}`;

export const SEARCH_ELEMENT = gql`
query searchElement($search:String!, $elementGroupId: ID, $matterId: ID) {
    searchElement(search : $search, elementGroupId: $elementGroupId, matterId: $matterId){
        name
        natureValues
    }
}`;

export const GET_ELEMENT_GROUP_BY_ID_WITH_MATTERS = gql`query getElementGroupById($elementGroupId: ID!) {
    getElementGroupById(elementGroupId: $elementGroupId){
        useClass
    	availableMatters{
            name
            en1090Name
            pricePerKg
            kgByLiter
            id
        }
    	elements{
            name
            matter
            {
                name
            }
        }
        name
        icon
        id
    }
}`;

export const GET_MATTER = gql`
query availableMatters($forCustom: Boolean){
    availableMatters(forCustom: $forCustom){
      id
      name
      en1090Name
      pricePerKg
      kgByLiter
    }
}`;

