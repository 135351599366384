import gql from "graphql-tag";

export const GET_PROJECTS = gql`
  query getProjects($search: String){
    projects(search: $search){
      id
      reference
      isEn1090
      createdAt
      totalSupplyListQty
      unusedSupplyListQty
    }
  }
`;

export const PROJECTS_BY_REFERENCE = gql`
  query projectsByReference($reference: String){
    projectsByReference(reference: $reference){
      id
      reference
      isEn1090
      createdAt
      totalSupplyListQty
      unusedSupplyListQty
    }
  }
`;

export const GET_NEW_PROJECTS = gql`
  query getProjects($search: String, $pagination: Pagination){
    projects(search: $search, pagination: $pagination){
      id
      reference
      isEn1090
      createdAt
      totalSupplyListQty
      unusedSupplyListQty
    }
  }
`;

export const GET_PROJECT = gql`
  query getProject($id: ID!){
    project(id: $id){
      id
      reference
      isEn1090
      createdAt
    }
  }
`;

export const CREATE_PROJECT = gql`
  mutation createProject($data: ProjectInput!){
    createProject(data: $data){
      id
      reference
      isEn1090
      createdAt
    }
  }
`;

export const UPDATE_PROJECT = gql`
  mutation updateProject($id: ID!, $data: ProjectUpdate!){
    updateProject(id: $id, data: $data){
      id
      reference
      isEn1090
      createdAt
    }
  }
`;

export const DELETE_PROJECT = gql`
  mutation deleteProject($id: ID!){
    deleteProject(id: $id)
  }
`;
