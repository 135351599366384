import { Component, OnInit, Inject, Renderer2, OnDestroy } from "@angular/core";
import { StepWeldingBase } from "../../base/step-welding-base";
import { CONTAINER_DATA, StepFormService } from "app/presentationnal/organisms/wizard-modal/service/step-form.service";
import { FormBuilder } from "@angular/forms";
import { Subscription } from "rxjs";
import { ISelectOption } from "app/presentationnal/atoms/inputs/select-input/selectOptions";

@Component({
  selector: "app-embase-step-three",
  templateUrl: "./embase-step-three.component.html",
  styleUrls: ["./embase-step-three.component.css"]
})
export class EmbaseStepThreeComponent extends StepWeldingBase implements OnInit, OnDestroy {

  private _usedAngleSubscription: Subscription;
  public anglesSelected: ISelectOption[] = [];

  constructor(@Inject(CONTAINER_DATA) data: any,
              protected _stepFormSrv: StepFormService,
              protected renderer: Renderer2,
              protected _fb: FormBuilder) {
                super(data, _stepFormSrv, renderer, _fb);
              }

  ngOnInit() {
    super.ngOnInit();
    this.getCuttingAngle();
    this._usedAngleSubscription = this.stepWeldingForm.get("usedAngle").valueChanges.subscribe(usedAngle => {
      this.actualData.custom.usedAngle = usedAngle;
      const meterForWeldingWithoutPercentage = this.actualWeldingData.MeterForWelding / (this.actualWeldingData.WeldingPercentage / 100);
      this.stepWeldingForm.get("MeterForWelding").patchValue(meterForWeldingWithoutPercentage);
    });
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this._usedAngleSubscription.unsubscribe();
  }

  protected generateFormContent(): any {
    const formContent = super.generateFormContent();
    formContent.usedAngle = this.actualData.custom.usedAngle;
    return formContent;
  }

  private getCuttingAngle() {
    if ( this._dataTicket.element.Angle1 && this.actualData.custom.Quantity < 2) {
      this.anglesSelected.push ({
        label: `${this._dataTicket.element.Angle1}°`,
        value: "angle1"
      });
      this.anglesSelected.push ({
        label: `${this._dataTicket.element.Angle2}°`,
        value: "angle2"
      });
    }
  }

  public stepChange() {
    const isCustomProperties: string[] = [ "usedAngle" ];
    Object.keys(this.stepWeldingForm.controls).forEach(key => {
      if (this.stepWeldingForm.get(key).touched && this.stepWeldingForm.get(key).valid) {
        if (isCustomProperties.indexOf(key) !== -1) {
          this.actualData.custom[key] = this.stepWeldingForm.get(key).value;
        } else {
          this.actualData.custom.AdditionalComputings.welding.origin[key] = this.stepWeldingForm.get(key).value;
        }
      }
    });

    this._stepFormSrv.change({ custom: this.actualData.custom });
  }

}
