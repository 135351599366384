import { Injectable } from "@angular/core";
import { Apollo } from "apollo-angular";
import { LIST_QUOTES_PROJECTS_WITH_QUOTE, ADD_QUOTES_PROJECT, UPDATE_QUOTES_PROJECT, DELETE_QUOTES_PROJECT, GET_QUOTES_PROJECT, LIST_QUOTES_PROJECTS } from "../quotes-project.queries";


@Injectable()
export class QuotesProjectQueriesService {

    constructor(private _apollo: Apollo) { }

    /**
     * @description List the projects with quotes
     * @author Kevin Palade
     * @param {*} variables
     * @returns
     * @memberof QuotesProjectQueriesService
     */
    public listQuoteProjects(variables) {
        return this._apollo.watchQuery({
            query: LIST_QUOTES_PROJECTS_WITH_QUOTE,
            variables: JSON.parse(JSON.stringify(variables)),
            fetchPolicy: "network-only"
        });
    }

    public queryListQuotesProjects(queryVariables) {

        return this._apollo.query({
            query: LIST_QUOTES_PROJECTS,
            variables: {
                search: JSON.parse(JSON.stringify(queryVariables))
            },
            fetchPolicy: "network-only"
        });
    }

    public getQuoteProject(projectId) {
        return this._apollo.query({
            query: GET_QUOTES_PROJECT,
            variables: { _id : projectId },
            fetchPolicy: "network-only"
        });
    }

    /**
     * @description Add a new project
     * @author Kevin Palade
     * @param {*} project
     * @param {*} refetchVariables
     * @returns
     * @memberof QuotesProjectQueriesService
     */
    public addQuoteProject(project: any, refetchVariables: any) {
        return this._apollo.mutate({
            mutation: ADD_QUOTES_PROJECT,
            variables: { project: project },
            refetchQueries: [{
                query: LIST_QUOTES_PROJECTS_WITH_QUOTE,
                variables: JSON.parse(JSON.stringify(refetchVariables))
            }],
        });
    }

    /**
     * @description Edit a project
     * @author Kevin Palade
     * @param {string} projectId
     * @param {*} project
     * @param {*} refetchVariables
     * @returns
     * @memberof QuotesProjectQueriesService
     */
    public editQuoteProject(projectId: string, project: any, refetchVariables: any) {
        return this._apollo.mutate({
            mutation: UPDATE_QUOTES_PROJECT,
            variables: { _id: projectId, project: project },
            refetchQueries: [{
                query: LIST_QUOTES_PROJECTS_WITH_QUOTE,
                variables: JSON.parse(JSON.stringify(refetchVariables))
            }],
        });
    }

    /**
     * @description Delete a project
     * @author Kevin Palade
     * @param {string} projectId
     * @returns
     * @memberof QuotesProjectQueriesService
     */
    public deleteQuoteProject(projectId: string) {
        return this._apollo.mutate({
            mutation: DELETE_QUOTES_PROJECT,
            variables: { _id: projectId },
        });
    }

    /**
     * @description Reload the current project list (with pagination / filter)
     * @author Kevin Palade
     * @param {*} variables
     * @returns
     * @memberof QuotesProjectQueriesService
     */
    public reloadQuoteProject(variables) {
        return this._apollo.query({
            query: LIST_QUOTES_PROJECTS_WITH_QUOTE,
            variables: JSON.parse(JSON.stringify(variables)),
            fetchPolicy: "network-only"
        });
    }
}