import { Component, OnInit, Inject } from "@angular/core";
import { StepFoldingBase } from "../../base/step-folding-base";
import { CONTAINER_DATA, StepFormService } from "app/presentationnal/organisms/wizard-modal/service/step-form.service";
import { FormBuilder } from "@angular/forms";

@Component({
  selector: "app-equerre-step-three",
  templateUrl: "./equerre-step-three.component.html",
  styleUrls: ["./equerre-step-three.component.css"]
})
export class EquerreStepThreeComponent extends StepFoldingBase implements OnInit {

  constructor(@Inject(CONTAINER_DATA) data: any,
              protected _stepFormSrv: StepFormService,
              protected _fb: FormBuilder) {
                super(data, _stepFormSrv, _fb);
              }

}
