import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from "@angular/common/http";
import { Injectable, Injector } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { CSRF_HEADER_KEY, AUTH_HEADER_KEY, AuthService, CSRF_STORAGE_KEY } from "@lib/auth/auth.service";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    public constructor(
        private _injector: Injector
    ) { }

    /**
     * @description Intercept all request to add CSRF key for security purpose
     * @author Houtekiet Yves
     * @param {HttpRequest<any>} req
     * @param {HttpHandler} next
     * @returns {Observable<HttpEvent<any>>}
     * @memberof AuthInterceptor
     */
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const token = this._injector.get(AuthService).getToken();
        const newHeaders = {
            [CSRF_HEADER_KEY]: localStorage.getItem(CSRF_STORAGE_KEY) || ""
        };
        if (!!token) { newHeaders[AUTH_HEADER_KEY] = `Bearer ${token}`; }

        return next.handle(req.clone({ setHeaders: newHeaders }));
        // return next.handle(tokenizedReq).pipe(map(event => {
        //     if (event instanceof HttpResponse) {
        //         const resCsrf: string = event.headers.get(CSRF_HEADER_KEY);
        //         if (resCsrf && resCsrf.length) {
        //             localStorage.setItem(CSRF_HEADER_KEY, resCsrf);
        //         }
        //     }
        //     return event;
        // }));
    }
}