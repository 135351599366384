import { Component, OnInit, ViewChild, ElementRef, Input, EventEmitter, Output } from "@angular/core";

@Component({
  selector: "app-load-more",
  templateUrl: "./load-more.component.html",
  styleUrls: ["./load-more.component.css"]
})
export class LoadMoreComponent implements OnInit {

  public data: number = 1;
  @ViewChild("loadMore") public loadMore: ElementRef;
  @Input() loadingState: boolean = true;
  @Output() loadMoreType: EventEmitter<number> = new EventEmitter<number>();

  constructor() { }

  ngOnInit() {
   
  }

  /**
   * loadMoreData
   */
  public loadMoreData() {
    this.data ++;
    this.loadMoreType.emit(this.data);
  }
}
