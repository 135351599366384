import { Component, OnInit, Input, EventEmitter, Output } from "@angular/core";
@Component({
  selector: "app-calendar",
  templateUrl: "./calendar.component.html",
  styleUrls: ["./calendar.component.css"]
})
export class CalendarComponent implements OnInit {

  @Input() headerDays: any;
  @Input() calendarDays: any;
  @Input() isMultiSelection: boolean = false;
  @Output() activateValidation = new EventEmitter<boolean>();
  @Output() selectElement: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {}

  public selectDay(daySelected) {
    if (this.isMultiSelection) {
      this.selectElement.emit(daySelected);
    } else {
      this.calendarDays.forEach(day => {
        day.selected = day.dateValue === daySelected.dateValue;
      });
      this.activateValidation.emit(true);
    }
  }
}
