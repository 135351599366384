import { Injectable, InjectionToken, Inject, LOCALE_ID } from "@angular/core";
import { Subject, ReplaySubject, Observable, of, forkJoin } from "rxjs";
import { flatMap, map } from "rxjs/operators";
import { QuoteElement, Quote, Matter, AdditionalComputing } from "app/facade/interfaces";
import { QuoteElementBase, IAction } from "app/facade/quote-element-definition/base";
import { FormGroup, FormControl } from "@angular/forms";
import { cloneDeep } from "apollo-utilities";
import { StandardElementService } from "app/facade/quote-element-definition/elements/standard/element.service";
import { CustomElementService } from "app/facade/quote-element-definition/elements/customs/custom.service";
import { OperationService } from "app/facade/quote-element-definition/operations/operation.service";
import { FinishService } from "app/facade/quote-element-definition/finish/finish.service";
import { Finish } from "app/facade/quote-element-definition/finish/finish.base";
import { OptionService } from "app/facade/quote-element-definition/options/options.service";
import { Equerre } from "app/facade/quote-element-definition/elements/customs/equerre";
import { Montage } from "app/facade/quote-element-definition/options/montage";
import { Study } from "app/facade/quote-element-definition/options/study";
import { CatalogQueriesService } from "app/facade/queries/catalog/async/catalog_queries.service";
import { CustomElement } from "app/facade/quote-element-definition/elements/customs/custom.base";
import { Margin } from "app/facade/quote-element-definition/options/margin";

export const CONTAINER_DATA = new InjectionToken<{}>("CONTAINER_DATA");

export interface ITicketElement {
  element: QuoteElementBase;
  children: QuoteElementBase[];
}

// Todo: Update for element & other if need
export interface ActualData {
  // For Element
  elementGroup?: any;
  matter?: Matter;
  useClass?: any; // is StandardAvailable
  element?: any;
  // For Custom
  custom?: any;
  index?: number;
  childrenIndex?: number;
  summary?: any;
  isEdited?: boolean;
}

// For getting app language config in formula display
export let LocaleLanguage: string;
@Injectable()
export class StepFormService {

  private _data: any;
  private _updateTicket = new ReplaySubject<any>(1);
  public navigate$: Subject<boolean>;
  public closeModal$: Subject<boolean>;
  public afterWizardClosed = new Subject<any>();
  public ticketData: ITicketElement[] = [];
  public dataTransfert: QuoteElement;
  private _quoteId: string;
  private _lastElementSelectedIndex: number;

  public constructor(
    @Inject(LOCALE_ID) private _locale: string,
    private _elementSrv: StandardElementService,
    private _finishSrv: FinishService,
    private _operationSrv: OperationService,
    private _optionSrv: OptionService,
    private _customSrv: CustomElementService,
    private _catalogQueriesSrv: CatalogQueriesService) {
      LocaleLanguage = this._locale;
      this._data = {};
      this.ticketData = [];
      this.navigate$ = new Subject<boolean>();
      this.closeModal$ = new Subject<boolean>();
  }

  public get TicketData(): ITicketElement[] {
    return this.ticketData;
  }

  public change(data: ActualData) {
    this._data = { ...this._data, ...data };
  }

  public get(): any {
    return this._data;
  }

  public getInfos(data): any {
    this.afterWizardClosed.next(data);
  }

  public addElement(elementSend: any, childrenData: any[] = []) {
    const contentInfos = {
      element: elementSend,
      children: childrenData,
    };
    this.ticketData.push(contentInfos);
    this.setLastElementSelected(this.ticketData.length - 1);
    this._updateTicket.next(this.ticketData);
  }

  public deleteElement(index: number, childrenIndex?: number): any {
    if (childrenIndex === undefined) {
      this.ticketData.splice(index, 1);
      this.setLastElementSelected(this._lastElementSelectedIndex - 1);
    } else {
      const element: any = this.ticketData[index].element;
      let childCutting: any,
          newAngle1: number,
          newAngle2: number;
      this.ticketData[index].children = this.ticketData[index].children.filter((children, childInd) => {
        if (children.constructor.name === "Cutting") {
          childCutting = children;
          if (childInd === childrenIndex) {
            element.Angle1 = null;
            element.Angle2 = null;
          } else {
            newAngle1 = childCutting.angle1;
            newAngle2 = childCutting.angle2;
          }
        }
        return childInd !== childrenIndex;
      });
      if (newAngle1 !== undefined || newAngle2 !== undefined) {
        element.Angle1 = newAngle1;
        element.Angle2 = newAngle2;
      }
      this.setLastElementSelected(index);
    }
    this._updateTicket.next(this.ticketData);
  }

  public updateElement(newValue: any, type: string, indexElement: number, childrenIndex?: number) {
    const typeElement: string = (childrenIndex === undefined) ? "element" : "children";
    if (typeElement === "element" && type == "Quantity" && !isNaN(+newValue)) {
      // Update all children quantity for the modified parent element quantity.
      this.ticketData[indexElement].children.forEach(child => child.ParentQuantity = +newValue);
    }
    let updatedElement: QuoteElementBase | QuoteElementBase[] = this.ticketData[indexElement][typeElement];
    if (childrenIndex === undefined) {
      if (type) {
        updatedElement[type] = newValue;
      } else {
        updatedElement = newValue;
      }
    } else {
      if (type) {
        updatedElement[childrenIndex][type] = newValue;
      } else {
        updatedElement[childrenIndex] = newValue;
      }
    }
    this.setLastElementSelected(indexElement);
    this._updateTicket.next(this.ticketData);
  }

  /**
   * replaceElement
   */
  public replaceElement(newValue: any, indexElement: number) {
    this.ticketData[indexElement]["element"] = newValue;
    const children: any[] = this.ticketData[indexElement]["children"];
    children.forEach(child => {
      if (child.AdditionalComputings) {
        if (newValue.Type === child.AdditionalComputings.welding.origin.Element1.Type) {
          child.AdditionalComputings.welding.origin.Element1 = newValue;
        } else {
          child.AdditionalComputings.welding.origin.Element2 = newValue;
        }
      }
    });
    this.setLastElementSelected(indexElement);
    this._updateTicket.next(this.ticketData);
  }

  public duplicateElement(indexElement: number, childrenIndex?: number) {
    const parentElement: ITicketElement = this.ticketData[indexElement];
    let newData: QuoteElement;

    if (childrenIndex === undefined) {
      newData = this.generateElementData(cloneDeep(parentElement));
      this.formatQuoteElementData(newData).subscribe( result => {
        this.addElement(result.element, result.children);
      });
    } else {
      newData = this.generateElementData(cloneDeep(parentElement.children[childrenIndex]), true);
      this.createElementForTicket(newData, parentElement.element).subscribe( result => {
        if (result.constructor.name === "Embase") {
          const embaseResult: any = result;
          embaseResult.usedAngle = embaseResult.usedAngle === "angle1" ? "angle2" : "angle1";
        }
        this.addCustom(indexElement, result);
      });
    }
  }

  public addCustom(indexElement, custom): number {
    this.ticketData[indexElement].children.push(custom);
    this.setLastElementSelected(indexElement);
    this._updateTicket.next(this.ticketData);
    return this.ticketData[indexElement].children.length - 1;
  }

  public initializeTicket(quoteData: Quote) {
    const elements$: Observable<{ element: QuoteElementBase, children: QuoteElementBase[] }>[] = [];
    this._quoteId = quoteData._id;

    if (quoteData.elements && quoteData.elements.length > 0) {
      quoteData.elements.forEach(el => elements$.push(this.formatQuoteElementData(el)));
      forkJoin(elements$).subscribe(elements => {
        this.ticketData = elements;
        this.assignTicketToFinishes();
        this.setTotalWeight();
        this.assignTicketToMargin();
        // Use to add name and en1090Name for custom matter
        // this.checkMatterCustom();
        this.setLastElementSelected(0);
        this._updateTicket.next(this.ticketData);
      });
    } else {
      this.ticketData = [];
      this._updateTicket.next(this.ticketData);
    }
  }


  /**
   * @description Add matter name and en1090Name properties if they're not defined (for certificates)
   * @author Lainez Eddy
   * @private
   * @memberof StepFormService
   */
  private checkMatterCustom() {
    this._catalogQueriesSrv.getMatter(true).subscribe(result => {
      const availableMatter = result.data["availableMatters"];
      let selectedMatter: any;
      this.ticketData.forEach( ticketLine => {
        ticketLine.children.forEach( child => {
          if (child.Type == "CUSTOM") {
            const custom: CustomElement = child as CustomElement;
            selectedMatter = availableMatter.find( matter => +matter.id === +custom.Matter.id);
            if (custom.Matter.en1090Name === undefined) {
              custom.Matter.en1090Name = selectedMatter.en1090Name;
            }
            if (custom.Matter.name === undefined) {
              custom.Matter.name = selectedMatter.name;
            }
          }
        });
      });
      this._updateTicket.next(this.ticketData);
    });
  }

  private assignTicketToFinishes(): void {
    const finishes = this.ticketData.filter(element => element.element instanceof Finish);
    finishes.forEach((finish: any) => finish.element.Ticket = this.ticketData);
  }

  private assignTicketToMargin(): void {
    const margins = this.ticketData.filter(element => element.element instanceof Margin);
    margins.forEach((margin: any) => margin.element.Ticket = this.ticketData);
  }

  private setTotalWeight(): void {
    const validOptions = this.ticketData.filter(element => element.element instanceof Montage || element.element instanceof Study);
    validOptions.forEach(option => (<Montage|Study>option.element).updateWeight(this.ticketData));
  }

  private formatQuoteElementData(quoteElement: QuoteElement): Observable<ITicketElement> {
    let base: QuoteElementBase;
    return this.createElementForTicket(quoteElement).pipe(
      flatMap(baseElement => {
        base = baseElement;
        return this.formatChildren(baseElement, quoteElement);
      }),
      map(children => {
        return {
          element: base,
          children: children
        };
      })
    );
  }

  private formatChildren(parent: QuoteElementBase, quoteElement: QuoteElement): Observable<QuoteElementBase[]> {
    if (!quoteElement.children || quoteElement.children.length == 0) {
      // No children found, only return empty array
      return of([]);
    } else {
      const customs$: Observable<QuoteElementBase>[] = [];
      quoteElement.children.forEach(child => customs$.push(this.createElementForTicket(child, parent)));

      return forkJoin(customs$);
    }
  }

  private createElementForTicket(quoteElement: QuoteElement, parentObject?: QuoteElementBase): Observable<QuoteElementBase> {
    return this.createElementOrCustomForTheTicket(quoteElement, parentObject).pipe(
      map(created => {
        return this.addElementProperties(created, quoteElement);
      })
    );
  }

  /**
   * WARNING : ONLY WORKS IF A ELEMENT AS ONLY ONE ROW OF CHILDREN : THE CHILDREN CANNOT HAVE CHILDREN.
   */
  private createElementOrCustomForTheTicket(quoteElement: QuoteElement, parentObject?: any): Observable<QuoteElementBase> {
    if (parentObject) {
      const properties: any = quoteElement.content.properties;
      if (quoteElement.content.isCustom) {
        properties.elementForWelding = parentObject ? parentObject : null;
        const additional: any = {};
        quoteElement.additionalComputings.forEach(additionalComputing => {
          if (additionalComputing.useClass == "LaserCutting") {
            additional.laserCutting = {
              ...additionalComputing.properties,
              GlobalAdditionalCost: additionalComputing.globalAdditionalCost,
              UnitaryAdditionalCost: additionalComputing.unitaryAdditionalCost,
              Remarks: additionalComputing.remarks
             };
          } else if (additionalComputing.useClass == "Welding") {
            additional.welding = {
              ...additionalComputing.properties,
              GlobalAdditionalCost: additionalComputing.globalAdditionalCost,
              UnitaryAdditionalCost: additionalComputing.unitaryAdditionalCost,
              Remarks: additionalComputing.remarks
            };
          }
        });
        return this._customSrv.create(<any>quoteElement.useClass, quoteElement.useClass, properties, additional);
      } else {
        return this._operationSrv.create(<any>quoteElement.useClass, { element: parentObject, properties: quoteElement.content.properties });
      }
    } else {
      if (quoteElement.content.isFinish) {
        return of(this._finishSrv.create(<any>quoteElement.useClass, quoteElement.content.name, null, quoteElement.content.properties));
      } else if (quoteElement.content.isOption) {
        return of(this._optionSrv.create(<any>quoteElement.useClass, quoteElement.content.properties.natureValues));
      } else {
        return of(this._elementSrv.create(<any>quoteElement.useClass, quoteElement.content.name, {
          values: quoteElement.content.properties.natureValues,
          properties: {
            length: quoteElement.content.properties.length,
            matter: quoteElement.content.properties.matter, // matter
          },
          reference: quoteElement.content.name,
        }));
      }
    }
  }

  private addElementProperties(element: QuoteElementBase, baseElement: QuoteElement, id?: number): QuoteElementBase {
    element.Remarks = baseElement.remarks;
    element.UnitaryAdditionalCost = baseElement.unitaryAdditionalCost;
    element.GlobalAdditionalCost = baseElement.globalAdditionalCost;
    element.Visible = baseElement.isVisible;
    element.Quantity = baseElement.quantity;
    element.CustomName = baseElement.customName;
    element.CustomCost = baseElement.customCost;
    if (element instanceof Equerre) {
      element.FoldingRemarks = baseElement.content.properties.remarks;
      element.UnitaryFoldingAdditionalCost = baseElement.content.properties.unitaryAdditionalCost;
      element.GlobalFoldingAdditionalCost = baseElement.content.properties.globalAdditionalCost;
    }
    return element;
  }

  public getUpdateTicket() {
    return this._updateTicket;
  }

  public getQuoteId(): string {
    return this._quoteId;
  }

  public renitializeData() {
    this._data = {};
  }

  public getLastElementSelected() {
    return this._lastElementSelectedIndex ;
  }

  public markFormGroupTouched(formGroup: FormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      (<FormControl>control).updateValueAndValidity();
      control.markAsTouched();
      if (control.controls) { control.controls.forEach(c => this.markFormGroupTouched(c)); }
    });
  }

  public setLastElementSelected(selectedIndex: number) {
    if (this.ticketData[selectedIndex] && this.ticketData[selectedIndex].element.Type === "STANDARD") {
      this._lastElementSelectedIndex = selectedIndex;
    } else if ( !this.ticketData[this._lastElementSelectedIndex] || this.ticketData[this._lastElementSelectedIndex].element.Type !== "STANDARD") {
      const checkStandard: number = this.ticketData.findIndex( line => line.element.Type === "STANDARD");
      this._lastElementSelectedIndex = checkStandard === -1 ? 0 : checkStandard;
    }
  }

  // TODO: Gestion des données children
  public generateElementData(element: any, isChildren: boolean = false): QuoteElement {
    const elementData: any = isChildren ? element : element.element;

    const properties = elementData.properties ? elementData.properties : (elementData.redefinedProperties ? elementData.redefinedProperties : {} );
    if (!isChildren) {
      properties.natureValues = elementData.values ?  elementData.values : null;
    }
    const returnElement: QuoteElement = {
      isVisible: elementData.Visible,
      quantity: elementData.Quantity,
      customName: elementData.CustomName,
      useClass: elementData.constructor.name,
      unitPrice: elementData.Price ? elementData.Price : elementData.unitPrice,
      unitaryAdditionalCost: elementData.UnitaryAdditionalCost,
      globalAdditionalCost: elementData.GlobalAdditionalCost,
      remarks: elementData.Remarks,
      content: {
        name: elementData.name,
        isCustom: elementData.Type == "CUSTOM",
        isFinish: elementData.Type == "FINISH",
        isOption: elementData.Type == "OPTION",
        properties: this.clearProperties(properties)
      }
    };

    if (elementData.AdditionalComputings) {
      returnElement.additionalComputings = [];
        Object.keys(elementData.AdditionalComputings).forEach(addCompute => {
          const objectAddComp: AdditionalComputing = {
            name: elementData.AdditionalComputings[addCompute]["name"],
            unitPrice: elementData.AdditionalComputings[addCompute]["origin"]["Price"],
            customCost: elementData.AdditionalComputings[addCompute]["origin"]["CustomCost"],
            customName: elementData.AdditionalComputings[addCompute]["origin"]["CustomName"],
            unitaryAdditionalCost: elementData.AdditionalComputings[addCompute]["origin"].UnitaryAdditionalCost,
            globalAdditionalCost: elementData.AdditionalComputings[addCompute]["origin"].GlobalAdditionalCost,
            remarks: elementData.AdditionalComputings[addCompute]["origin"].Remarks,
            type: elementData.AdditionalComputings[addCompute]["origin"].Type,
            useClass: elementData.AdditionalComputings[addCompute].origin.constructor.name,
            properties: elementData.AdditionalComputings[addCompute]["origin"]["properties"]
          };
          returnElement.additionalComputings.push(objectAddComp);

        });
    }

    if (element.children) {
      returnElement.children = element.children.map( childrenElement => {
        return this.generateElementData(childrenElement, true);
      });
    }
    return returnElement;
  }

  private clearProperties(properties: any): any {
    if (properties.elementForWelding) {
      delete properties.elementForWelding.$updated;
      delete properties.elementForWelding.$quantityUpdated;
    }
    return properties;
  }

}
