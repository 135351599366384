import { Component, OnInit, Inject, OnDestroy } from "@angular/core";
import { ModalContentForm } from "../../model/modal-content-form";
import { ModalService, DATA } from "../../services/modal.service";
import { SnackService } from "app/presentationnal/organisms/snack-bar/services/snack.service";
import { FormBuilder, FormGroup } from "@angular/forms";
import { IPurchaseOrderElement } from "app/facade/interfaces/purchase-order.interface";
import { ITableSelection } from "app/facade/interfaces/table-datas-definition";
import { AmalgamService } from "app/facade/services/amalgam.service";
import { Subscription } from "rxjs";
import { ElementUnitConfig } from "app/facade/configs/price-request-unit.config";
import { ElementUnitCategory } from "app/facade/enums/element-unit-category.enum";

interface IModalPurchaseOrderElementTableDatas {
  id: number;
  quantity: number;
  denomination: string;
  matterRef: string;
  option: string;
  format: string;
  isEn1090: boolean;
  remark: string;
  weight: number;
  quantityToPrint: FormGroup;
  printedQuantity: number;
}

interface IModalPurchaseOrderElementSelection extends ITableSelection {
  tableDatas: IModalPurchaseOrderElementTableDatas[];
}

@Component({
  selector: "app-modal-select-purchase-order-elements-to-generate-labels-pdf",
  templateUrl: "./modal-select-purchase-order-elements-to-generate-labels-pdf.component.html",
  styleUrls: ["./modal-select-purchase-order-elements-to-generate-labels-pdf.component.css"]
})
export class ModalSelectPurchaseOrderElementsToGenerateLabelsPdfComponent extends ModalContentForm implements OnInit, OnDestroy {
  private _checkBoxSub: Subscription[] = [];
  private _purchaseOrderElements: IPurchaseOrderElement[];
  private _stickerDatas: string = "";
  public purchaseOrderElementSelectionDatas: IModalPurchaseOrderElementSelection;

  constructor(
    private _amalgamSrv: AmalgamService,
    protected _modalService: ModalService,
    protected _snackBar: SnackService,
    protected _fb: FormBuilder,
    @Inject(DATA) private _data: {data: {purchaseOrderElements: IPurchaseOrderElement[]}}) {
      super(_modalService, _fb, _snackBar);
      this._purchaseOrderElements = this._data.data.purchaseOrderElements;
    }

  ngOnInit() {
    this.purchaseOrderElementSelectionDatas = {
      tableHeaders: [
        "",
        "Quantité",
        "Dénomination",
        "Matière",
        "Option",
        "Format",
        "En1090",
        "Remarque",
        "Poids",
        "Quantité à imprimer",
        "Quantité imprimée"
      ],
      checkboxDatas: [],
      tableDatas: [],
      tableDatasDefinition: [
        { key: "quantity", isTitle: false},
        { key: "denomination", isTitle: false},
        { key: "matterRef", isTitle: false},
        { key: "option", isTitle: false},
        { key: "format", isTitle: false},
        { key: "isEn1090", isTitle: false, isCheckBox: true},
        { key: "remark", isTitle: false},
        { key: "weight", isTitle: false},
        { key: "quantityToPrint", isTitle: false, isInput: true, inputType: "number"}, // INPUT
        { key: "printedQuantity", isTitle: false}
      ]
    };
    this.formatPurchaseElementDatas();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this._checkBoxSub.forEach( sub => {
      sub.unsubscribe();
    });
  }

  private formatPurchaseElementDatas() {
    this._purchaseOrderElements.forEach( element => {
      this.purchaseOrderElementSelectionDatas.tableDatas.push({
        id: element.id,
        quantity: element.quantity,
        denomination: this.formatDisplayedDenomination(element),
        matterRef: element.matterRef,
        option: this._amalgamSrv.getOptionLabel(element),
        format: this.formatDisplayedFormat(element),
        isEn1090: element.isEn1090,
        remark: element.remark,
        weight: element.weight,
        quantityToPrint: this._fb.group({quantityToPrint: null}),
        printedQuantity: element.printedQuantity,
      });
      this.purchaseOrderElementSelectionDatas.checkboxDatas.push({
        key: "selected",
        formValue: this._fb.group({
          purchaseOrderElementId: element.id,
          selected: false
        })
      });
    });
    this.listenCheckboxUpdate();
  }

  /**
   * @description Format displayed format depending on PurchaseOrderElement type
   * @author Quentin Wolfs
   * @private
   * @param {IPurchaseOrderElement} element
   * @returns {string}
   * @memberof ModalSelectPurchaseOrderElementsToGenerateLabelsPdfComponent
   */
  private formatDisplayedFormat(element: IPurchaseOrderElement): string {
    if (this.isPlate(element)) {
      return `${element.thickness}x${element.length}x${element.width}`;
    } else {
      return element.format;
    }
  }

  /**
   * @description Format displayed denomination depending on PurchaseOrderElement type
   * @author Quentin Wolfs
   * @private
   * @param {IPurchaseOrderElement} element
   * @returns {string}
   * @memberof ModalSelectPurchaseOrderElementsToGenerateLabelsPdfComponent
   */
  private formatDisplayedDenomination(element: IPurchaseOrderElement): string {
    if (this.isPlate(element)) {
      return "Plaque";
    } else {
      return element.denomination;
    }
  }

  /**
   * @description Verifies whether an element is a plate or not
   * @author Quentin Wolfs
   * @private
   * @param {IPurchaseOrderElement} element
   * @returns {boolean}
   * @memberof ModalSelectPurchaseOrderElementsToGenerateLabelsPdfComponent
   */
  private isPlate(element: IPurchaseOrderElement): boolean {
    return ElementUnitConfig.categories[ElementUnitCategory.PLATES].includes(+element.supplyCategoryId);
  }

  private listenCheckboxUpdate() {
    let valueChangesSub: Subscription;
    this.purchaseOrderElementSelectionDatas.checkboxDatas.forEach( checkbox => {
      valueChangesSub = checkbox.formValue.get("selected").valueChanges.subscribe( result => {
        const tableData: IModalPurchaseOrderElementTableDatas = this.purchaseOrderElementSelectionDatas.tableDatas.find( data => data.id === checkbox.formValue.get("purchaseOrderElementId").value);
        if (tableData && result && !tableData.quantityToPrint.get("quantityToPrint").value) {
          const newQuantity: number = tableData.quantity - tableData.printedQuantity;
          tableData.quantityToPrint.get("quantityToPrint").patchValue(newQuantity > 0 ? newQuantity : 1);
        }
      });
      this._checkBoxSub.push(valueChangesSub);
    });
  }

  public confirmModal() {
    const selectedElementIds: number[] = this.purchaseOrderElementSelectionDatas.checkboxDatas
      .filter( checkbox => checkbox.formValue.get("selected").value)
      .map( checkbox => checkbox.formValue.get("purchaseOrderElementId").value);
    const selectedElement: IModalPurchaseOrderElementTableDatas[] = this.purchaseOrderElementSelectionDatas.tableDatas
      .filter( element => selectedElementIds.includes(element.id) && element.quantityToPrint.get("quantityToPrint").value);
    const stickerDatas: string[] = selectedElement.map( element => {
      return `{${element.id},${element.quantityToPrint.get("quantityToPrint").value}}`;
    });
    this._stickerDatas = stickerDatas.length > 0 ? `[${stickerDatas.join(",")}]` : null;
    this.save();
    this._modalService.closeModal();
  }

  protected save(): void {
    const objData = {
      confirm: true,
      data : {
        stickerDatas: this._stickerDatas
      }
    };
    this.afterClosed.next(objData);
  }

}
