import { Component, OnInit, Inject, OnDestroy } from "@angular/core";
import { FormGroup, FormBuilder, Validators, AbstractControl } from "@angular/forms";
import { FinishAvailable, FinishService } from "app/facade/quote-element-definition/finish/finish.service";
import { ITicketElement, ActualData, CONTAINER_DATA, StepFormService } from "app/presentationnal/organisms/wizard-modal/service/step-form.service";
import { PaintingProperty, BLASTING_PRICE_PER_TON } from "app/facade/quote-element-definition/finish/painting";
import { Finish } from "app/facade/quote-element-definition/finish/finish.base";
import { PaintingFormulaDisplayer } from "app/facade/quote-element-definition/formula.display";
import { Subscription } from "rxjs";

@Component({
  selector: "app-painting-step-one",
  templateUrl: "./painting-step-one.component.html",
  styleUrls: ["./painting-step-one.component.css"]
})
export class PaintingStepOneComponent implements OnInit, OnDestroy {

  public isFixe: boolean;
  public dataTicket: ITicketElement[];
  public useClass: FinishAvailable;
  public paintStepOneForm: FormGroup;
  public calculs: string[];

  private _actualData: ActualData;
  private _finishData: any;
  private _isEdit: boolean = true;
  private _formulaDisplayer: PaintingFormulaDisplayer;
  private _formulaSub: Subscription;

  constructor(
    @Inject(CONTAINER_DATA) data: any,
    private _stepFormSrv: StepFormService,
    private _finishSrv: FinishService,
    private _fb: FormBuilder
  ) {
    this.dataTicket = data.ticket;
    this.useClass = "Painting";
    this._actualData = this._stepFormSrv.get();
    this._finishData = this._actualData.element;
  }

  ngOnInit() {
    this.initform();
    this.calculs = [];
    this._formulaDisplayer = new PaintingFormulaDisplayer();
    this._formulaSub = this._formulaDisplayer.$PaintingPrice.subscribe(calculs => this.calculs = calculs);
    this.showCalcul();
  }

  ngOnDestroy(): void {
    this._formulaSub.unsubscribe();
  }

  private checkCostRequired(control: AbstractControl) {
    if (control.parent && control.parent.get("fixedPrice").value && !control.value) {
      return { required: true };
    }
    return null;
  }

  private checkAreaCostRequired(control: AbstractControl) {
    if (control.parent && !control.parent.get("fixedPrice").value && !control.value) {
      return { required: true };
    }
    return null;
  }

  public initform () {
    if (!this._finishData) {
      this._finishData = this._finishSrv.create("Painting", "Peinture", this.dataTicket, { cost: null, fixedPrice: false });
      this._isEdit = false;
    }
    this.paintStepOneForm = this._fb.group({
      blasted: this._finishData.IsBlasted,
      totalWeight: this._finishData.Weight,
      totalSurface: this._finishData.Area,
      numberLayers: [this._finishData.LayerAmount ? this._finishData.LayerAmount : null, Validators.required],
      layerThickness: this._finishData.LayerThickness ? this._finishData.LayerThickness : null,
      cost: [this._finishData.Cost, this.checkCostRequired],
      areaCost: [this._finishData.AreaCost, this.checkAreaCostRequired],
      fixedPrice: this._finishData.FixedPrice
    });
    this.isFixe = this._finishData ? this._finishData.FixedPrice : false;

    this.paintStepOneForm.get("fixedPrice").valueChanges.subscribe(result => {
      this.isFixe = result;
      this.paintStepOneForm.get("cost").updateValueAndValidity();
      this.paintStepOneForm.get("areaCost").updateValueAndValidity();
    });
  }

  public next() {
    if (this.paintStepOneForm && this.paintStepOneForm.valid) {
      let elementIndex: number;
      const finish = this._finishData;
      finish.Cost = this.paintStepOneForm.get("cost").value;
      finish.LayerAmount = this.paintStepOneForm.get("numberLayers").value;
      finish.LayerThickness = this.paintStepOneForm.get("layerThickness").value;
      finish.IsBlasted = this.paintStepOneForm.get("blasted").value;
      finish.AreaCost = this.paintStepOneForm.get("areaCost").value;
      finish.FixedPrice = this.paintStepOneForm.get("fixedPrice").value;
      if (this._isEdit) {
        // Need to save surface / weight only if overriden by user
        if (this.paintStepOneForm.get("totalSurface").touched) { finish.Area = this.paintStepOneForm.get("totalSurface").value; }
        if (this.paintStepOneForm.get("totalWeight").touched) { finish.Weight = this.paintStepOneForm.get("totalWeight").value; }
        elementIndex = this._actualData.index;
        this._stepFormSrv.updateElement(finish, null, elementIndex);
      } else {
        this._stepFormSrv.addElement(finish);
        elementIndex = this._stepFormSrv.getLastElementSelected();
      }
      this._stepFormSrv.change({
        element: finish,
        index: elementIndex
      });
      this._stepFormSrv.closeModal$.next(true);
    } else {
      this._stepFormSrv.markFormGroupTouched(this.paintStepOneForm);
    }
  }

  public cancel() {
    if (!this._actualData.isEdited && this._actualData.index !== undefined) {
      this._stepFormSrv.deleteElement(this._actualData.index);
    }
    this._stepFormSrv.closeModal$.next(true);
  }

  private showCalcul() {
    this.paintStepOneForm.valueChanges.subscribe(values => {
      const base = {
        totalArea: values.totalSurface,
        totalWeight: values.totalWeight,
        layerAmount: values.numberLayers || 0,
        layerThickness: values.layerThickness || 0,
        blastingPricePerTon: BLASTING_PRICE_PER_TON,
        isBlasted: values.blasted || false,
        cost: values.cost || 0,
        areaCost: values.areaCost || 0,
        fixedPrice: values.fixedPrice || false
      };

      if (Object.keys(base).every(k => base[k] != null)) {
        this._formulaDisplayer.base = base;
      }
    });

    const init = {
      totalArea: this.paintStepOneForm.value.totalSurface,
      totalWeight: this.paintStepOneForm.value.totalWeight,
      layerAmount: this.paintStepOneForm.value.numberLayers || 0,
      layerThickness: this.paintStepOneForm.value.layerThickness || 0,
      blastingPricePerTon: BLASTING_PRICE_PER_TON,
      isBlasted: this.paintStepOneForm.value.blasted || false,
      cost: this.paintStepOneForm.value.cost || 0,
      areaCost: this.paintStepOneForm.value.areaCost || 0,
      fixedPrice: this.paintStepOneForm.value.fixedPrice || false
    };

    if (Object.keys(init).every(k => init[k] != null)) {
      this._formulaDisplayer.base = init;
    }
  }
}
