import { Component, OnInit, Inject } from "@angular/core";
import { ModalContentForm } from "../../model/modal-content-form";
import { ModalService, DATA } from "../../services/modal.service";
import { SnackService } from "app/presentationnal/organisms/snack-bar/services/snack.service";
import { FormBuilder, Validators, FormGroup} from "@angular/forms";
import { IPurchaseOrderElement, IPurchaseOrderAdditionnalCost, ICustomPurchaseOrderAdditionnalCostForm, IPurchaseOrderAdditionnalCostInput, IPurchaseOrderAdditionnalCostUpdate } from "app/facade/interfaces/purchase-order.interface";
import { ISelectOption } from "app/presentationnal/atoms/inputs/select-input/selectOptions";
import { EnumAdditionnalCostUnit, UniqueSupplierOfferAdditionnalCostType, MultiSupplierOfferAdditionnalCostType } from "app/facade/enums/supplier-offer-additionnal-cost-type.enum";
import { PurchaseOrderQueriesService } from "app/facade/queries/purchase-order/purchase-order-queries.service";
import { IModalData } from "../../interfaces/modal-data.interface";
import { MODAL_CLOSE_ACTION } from "../../enums/modal-close-action.enum";

interface IPurchaseOrderAdditionnalCostModalData extends IModalData {
  data: {
    purchaseOrderId: number;
    purchaseOrderAdditionnalCost: IPurchaseOrderAdditionnalCost;
  };
}

@Component({
  selector: "app-modal-inpdate-purchase-order-additionnal-cost",
  templateUrl: "./modal-inpdate-purchase-order-additionnal-cost.component.html",
  styleUrls: ["./modal-inpdate-purchase-order-additionnal-cost.component.css"]
})
export class ModalInpdatePurchaseOrderAdditionnalCostComponent extends ModalContentForm implements OnInit {
  private _purchaseOrderId: number;
  private _purchaseOrderAdditionnalCost: IPurchaseOrderAdditionnalCost;

  public priceUnits: ISelectOption[] = [];
  public costTypes: ISelectOption[] = [];

  constructor(
    protected _modalService: ModalService,
    protected _snackBar: SnackService,
    protected _fb: FormBuilder,
    private _purchaseOrderQueriesSrv: PurchaseOrderQueriesService,
    @Inject(DATA) private _data: IPurchaseOrderAdditionnalCostModalData) {
      super(_modalService, _fb, _snackBar);
      this._purchaseOrderId = this._data.data.purchaseOrderId;
      this._purchaseOrderAdditionnalCost = this._data.data.purchaseOrderAdditionnalCost;
  }

  ngOnInit() {
    this.initFormData();
  }

  private initFormData() {
    this.priceUnits = this.extractSelect(EnumAdditionnalCostUnit);

    this.costTypes = [
      ...this.extractSelect(UniqueSupplierOfferAdditionnalCostType),
      ...this.extractSelect(MultiSupplierOfferAdditionnalCostType)
    ];

    this._formGroup = this._fb.group(this.setPurchaseOrderAdditionnalCostInputForm(this._purchaseOrderAdditionnalCost));
    this.controlDenomination(this._formGroup);
  }

  /**
   * @description Ensure that if selected Type is "OTHERS", a denomination can be submitted, otherwise the field is nulled and disabled
   * @author Quentin Wolfs
   * @private
   * @param {FormGroup} formGroup
   * @memberof ModalInpdatePurchaseOrderAdditionnalCostComponent
   */
  private controlDenomination(formGroup: FormGroup): void {
    const denomControl = formGroup.get("denomination");
    if (!!this._purchaseOrderAdditionnalCost && this.hasNoDenomination(this._purchaseOrderAdditionnalCost.type)) {
      denomControl.disable({ emitEvent: false });
      denomControl.patchValue(null, { emitEvent: false });
    }

    this._formGroup.get("type").valueChanges.subscribe(type => {
      if (this.hasNoDenomination(type)) {
        if (denomControl.enabled) {
          denomControl.disable({ emitEvent: false });
          denomControl.patchValue(null, { emitEvent: false });
        }
      } else {
        if (denomControl.disabled) {
          denomControl.enable({ emitEvent: false });
        }
      }
    });
  }

  /**
   * @description Verifies if an AdditionnalCost has or not a denomination. Only "OTHERS" type get to have one.
   * @author Quentin Wolfs
   * @private
   * @param {string} type
   * @returns {boolean}
   * @memberof ModalInpdatePurchaseOrderAdditionnalCostComponent
   */
  private hasNoDenomination(type: string): boolean {
    return Object.keys(MultiSupplierOfferAdditionnalCostType).indexOf(type) === -1;
  }

  /**
   * @description Extract values of an enum to set it in select format
   * @author Quentin Wolfs
   * @private
   * @param {*} enumeration
   * @returns {ISelectOption[]}
   * @memberof ModalInpdatePurchaseOrderAdditionnalCostComponent
   */
  private extractSelect(enumeration: any): ISelectOption[] {
    return Object.keys(enumeration).map(key => {
        return {
            value: key,
            label: enumeration[key]
        };
    });
}

  private setPurchaseOrderAdditionnalCostInputForm(additionnalCost?: IPurchaseOrderAdditionnalCost): ICustomPurchaseOrderAdditionnalCostForm | any {
    return {
      id: additionnalCost ? additionnalCost.id : null,
      quantity: [additionnalCost ? additionnalCost.quantity : null, Validators.required],
      type: [additionnalCost ? additionnalCost.type : null, Validators.required],
      denomination: [additionnalCost ? additionnalCost.denomination : null, Validators.maxLength(200)],
      unit: [additionnalCost ? additionnalCost.unit : null, Validators.required],
      price: additionnalCost ? additionnalCost.price : null,
      purchaseOrderId: [this._purchaseOrderId, Validators.required]
    };
  }

  public confirmModal() {
    if (this._formGroup.valid) {
      switch (this._data.closeAction) {
        case MODAL_CLOSE_ACTION.SAVE:
          this.saveNewPurchaseOrderAdditionnalCost();
          break;
        case MODAL_CLOSE_ACTION.UPDATE:
          this.updatePurchaseOrderElement();
          break;
      }
    } else {
      this.markFormGroupTouched(this._formGroup);
    }
  }

  /**
   * @description Format form value for new PurchaseOrderAdditionnalCost creation
   * @author Quentin Wolfs
   * @private
   * @returns {IPurchaseOrderAdditionnalCostInput}
   * @memberof ModalInpdatePurchaseOrderAdditionnalCostComponent
   */
  private formatValueForCreate(): IPurchaseOrderAdditionnalCostInput {
    const formValues: ICustomPurchaseOrderAdditionnalCostForm  = { ...this._formGroup.value };

    return { purchaseOrderId: this._purchaseOrderId, ...this.formatBaseValues(formValues) };
  }

  /**
   * @description Format form value for new PurchaseOrderAdditionnalCost update
   * @author Quentin Wolfs
   * @private
   * @returns {IPurchaseOrderAdditionnalCostUpdate}
   * @memberof ModalInpdatePurchaseOrderAdditionnalCostComponent
   */
  private formatValueForUpdate(): IPurchaseOrderAdditionnalCostUpdate {
    const formValues: ICustomPurchaseOrderAdditionnalCostForm  = { ...this._formGroup.value };

    return { id: formValues.id, ...this.formatBaseValues(formValues) };
  }

  /**
   * @description Format common part of Input/Update Additionnal Cost
   * @author Quentin Wolfs
   * @private
   * @param {ICustomPurchaseOrderAdditionnalCostForm} formValues
   * @returns {Partial<IPurchaseOrderAdditionnalCostInput>}
   * @memberof ModalInpdatePurchaseOrderAdditionnalCostComponent
   */
  private formatBaseValues(formValues: ICustomPurchaseOrderAdditionnalCostForm): Partial<IPurchaseOrderAdditionnalCostInput> {
    return {
      quantity: formValues.quantity,
      type: formValues.type,
      denomination: formValues.denomination,
      price: formValues.price,
      unit: formValues.unit
    };
  }

  protected save(data: IPurchaseOrderElement): void {
    const objData = {
      confirm: true,
      data
    };
    this.afterClosed.next(objData);
  }

  /**
   * @description Send request to save new Purchase Order Additionnal Cost. Closes the modal if successfull, displays error if not
   * @author Quentin Wolfs
   * @private
   * @memberof ModalInpdatePurchaseOrderAdditionnalCostComponent
   */
  private saveNewPurchaseOrderAdditionnalCost(): void {
    this._purchaseOrderQueriesSrv.createPurchaseOrderAdditionnalCost(this.formatValueForCreate(), this._data.data.purchaseOrderId).subscribe(result => {
      const resultData: any = result.data;
      if (resultData && resultData.createPurchaseOrderAdditionnalCost) {
        this._snackBar.open(this._data.title, "Le coût additionnel a été ajouté au bon de commande", "success", 5000);

        // Save was successfull, closing the modal with saved data
        this.save(resultData.createPurchaseOrderAdditionnalCost);
        this._modalService.closeModal();
      } else {
        this._snackBar.openSnackBarError(this._data.title, "Le coût additionnel n'a pas été ajouté au bon de commande");
      }
    }, error => {
      this._snackBar.openSnackBarError(this._data.title, "Le coût additionnel n'a pas été ajouté au bon de commande", error);
    });
  }

  /**
   * @description Send request to update existing Purchase Order Element. Closes the modal if successfull, displays error if not
   * @author Quentin Wolfs
   * @private
   * @memberof ModalInpdatePurchaseOrderElementComponent
   */
  private updatePurchaseOrderElement(): void {
    this._purchaseOrderQueriesSrv.updatePurchaseOrderAdditionnalCost(this._purchaseOrderAdditionnalCost.id, this.formatValueForUpdate(), this._data.data.purchaseOrderId).subscribe( result => {
      const resultData: any = result.data;
      if (resultData && resultData.updatePurchaseOrderAdditionnalCost) {
        this._snackBar.open(this._data.title, "Le coût additionnel a été mis à jour", "success", 5000);

        // Update was successfull, closing the modal with updated data
        this.save(resultData.updatePurchaseOrderAdditionnalCost);
        this._modalService.closeModal();
      } else {
        this._snackBar.openSnackBarError(this._data.title, "Le coût additionnel n'a pas été mis à jour");
      }
    }, error => {
      this._snackBar.openSnackBarError(this._data.title, "Le coût additionnel n'a pas été mis à jour", error);
    });
  }
}
