import { Component, OnInit, Renderer2, Input, OnChanges, SimpleChanges, QueryList, ElementRef, ViewChildren } from "@angular/core";
import { AInputs } from "../inputs";
import { ISelectOption } from "../select-input/selectOptions";

@Component({
  selector: "app-radio-input",
  templateUrl: "./radio-input.component.html",
  styleUrls: ["./radio-input.component.css"]
})
export class RadioInputComponent extends AInputs implements OnInit, OnChanges {
  @Input() public options: ISelectOption[];
  @ViewChildren("input") protected inputRadioReference: QueryList<ElementRef>;
  @Input() public uniqueId: string;
  public selectedValue: string | number;

  constructor(protected renderer: Renderer2) {
    super(renderer);
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ( changes.group && changes.options && this.options ) {
      if (this.group.get(this.name).value !== null && this.group.get(this.name).value !== undefined) {
        this.selectedValue = this.group.get(this.name).value;
      } else {
        this.selectedValue = this.options[0].value;
        this.group.get(this.name).patchValue(this.options[0].value);
        this.group.get(this.name).markAsTouched();
      }
    }
    this.validationCheck();
  }

  public selectCheckbox(checkboxValue) {
    this.group.get(this.name).patchValue(checkboxValue);
    this.group.get(this.name).markAsTouched();
  }

  protected validationCheck() {
    this.group.get(this.name).valueChanges.subscribe(result => {
      this.selectedValue = result;
      this.inputRadioReference.toArray().forEach(element => {
        if (!this.group.get(this.name).errors) {
        this.renderer.removeClass(element.nativeElement, "input_error");
        } else {
          this.renderer.addClass(element.nativeElement, "input_error");
        }
      });
    });
  }
}
