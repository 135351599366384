import { Component, Input, OnInit, Output, EventEmitter, ElementRef, SimpleChanges, OnChanges, ViewChildren, QueryList } from "@angular/core";
import { ILinkedMultiTableData } from "app/facade/interfaces/multi-table.interface";

@Component({
    selector: "app-multi-table-linked",
    templateUrl: "./multi-table-linked.component.html",
    styleUrls: ["./multi-table-linked.component.css"]
})
export class MultiTableLinkedComponent implements OnInit, OnChanges  {
  @Input() public linkedData: ILinkedMultiTableData[] = [];
  @Input() public displayHeader: boolean = true;
  @Input() public contentHeight: string = "45vh";
  @Input() public scrollTopPosition: number = 0;
  @Output() public onEncodeSupplierResponse: EventEmitter<number> = new EventEmitter<number>();
  @ViewChildren("linkedContent") public linkedContent: QueryList<ElementRef>;
  // Not use > problem multi scroll event between the linked table and the main table
  @Output() public onScrollLinkedTable: EventEmitter<number> = new EventEmitter<number>();

  ngOnInit() { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.scrollTopPosition && this.linkedContent) {
      this.linkedContent.toArray().forEach(link => {
        link.nativeElement.scrollTop = changes.scrollTopPosition.currentValue;
      });
    }
  }

  public linkedTableScroll(event: Event) {
    event.preventDefault();
    this.linkedContent.toArray().forEach(link => {
      link.nativeElement.scrollTop = this.scrollTopPosition;
    });
    // this.onScrollLinkedTable.emit(event.target.scrollTop);
  }

  public encodeSupplierResponse(linkedData: ILinkedMultiTableData) {
    this.onEncodeSupplierResponse.emit(linkedData.id);
  }

}
