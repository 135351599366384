import { Component, OnInit, Inject } from "@angular/core";
import { ModalContentForm } from "../../model/modal-content-form";
import { ModalService, DATA } from "../../services/modal.service";
import { SnackService } from "app/presentationnal/organisms/snack-bar/services/snack.service";
import { FormBuilder } from "@angular/forms";
import { IPriceRequestElementOption, IPriceRequestElementOptionInput } from "app/facade/interfaces/price-request.interface";
import { ISelectOption } from "app/presentationnal/atoms/inputs/select-input/selectOptions";
import { PriceRequestElementOptionType, PriceRequestElementOptionUnit } from "app/facade/enums/price-request-element-option-datas.enum";

@Component({
  selector: "app-modal-inpdate-price-request-element-option",
  templateUrl: "./modal-inpdate-price-request-element-option.component.html",
  styleUrls: ["./modal-inpdate-price-request-element-option.component.css"]
})
export class ModalInpdatePriceRequestElementOptionComponent extends ModalContentForm implements OnInit {
  private _elementId: number;
  private _elementIds: number[];
  private _optionData: IPriceRequestElementOption;
  public typeOptions: ISelectOption[] = [];
  public unitOptions: ISelectOption[] = [];

  constructor( protected _modalService: ModalService,
    protected _snackBar: SnackService,
    protected _fb: FormBuilder,
    @Inject(DATA) private _data: {data: {elementIds: number[], elementId: number, optionData: IPriceRequestElementOption}}) {
      super(_modalService, _fb, _snackBar);
      this._elementIds = this._data.data.elementIds;
      this._elementId = this._data.data.elementId;
      this._optionData = this._data.data.optionData;
  }

  ngOnInit() {
    this.typeOptions = Object.keys(PriceRequestElementOptionType).map( typeKey => {
      return {
        value: typeKey,
        label: PriceRequestElementOptionType[typeKey]
      };
    });
    this.unitOptions = Object.keys(PriceRequestElementOptionUnit).map( unitKey => {
      return {
        value: unitKey,
        label: PriceRequestElementOptionUnit[unitKey]
      };
    });
    this._formGroup = this._fb.group({
      type: this._optionData && this._optionData.type ? this._optionData.type : this.typeOptions[0].value,
      denomination: this._optionData ? this._optionData.denomination : null,
      quantity: this._optionData ? this._optionData.quantity : null,
      unit: this._optionData && this._optionData.unit ? this._optionData.unit : this.unitOptions[0].value
    });
  }

  public confirmModal() {
    this.save();
    this._modalService.closeModal();
  }

  protected save(): void {
    let dataToSave: {id?: number, data: IPriceRequestElementOptionInput};
    let isUpdate: boolean;
    if (this._optionData && this._optionData.id) {
      dataToSave = {
        id: this._optionData.id,
        data: this._formGroup.value
      };
      isUpdate = true;
    } else {
      dataToSave = {
        data: {
          ...this._formGroup.value,
          priceRequestElementIds: this._elementIds
        }
      };
      isUpdate = false;
    }
    const objData = {
      confirm: true,
      data: dataToSave,
      isUpdate
    };
    this.afterClosed.next(objData);
  }
}
