import gql from "graphql-tag";

export const ADD_SMTP_CONFIG = gql`mutation($smtp:SmtpConfigInput!){
  createSmtpConfig(smtp:$smtp){
    id
    loginId
    username
    email
    password
    host
    port
    active
  }
}`;

export const UPDATE_SMTP_CONFIG = gql`mutation($smtp:UpdateSmtpConfig!,$id: Int!){
  updateSmtpConfig(smtp:$smtp, id:$id){
    loginId
    username
    email
    password
    host
    port
    active
  }
}`;

export const GET_SMTP = gql`
  query getSmtpConfig($id: Int!){
    getSmtpConfig(id: $id){
      id
      loginId
      username
      email
      password
      host
      port
      active
    }
  }
`;
