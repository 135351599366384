import { ElementUnitCategory } from "../enums/element-unit-category.enum";
import { IElementUnitConfig } from "../interfaces/element-unit-config.interface";
import { PriceRequestElementOptionUnit } from "../enums/price-request-element-option-datas.enum";

export const ElementUnitConfig: IElementUnitConfig = {
  categories: {
      [ElementUnitCategory.BEAMS]: [7, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24],
      [ElementUnitCategory.TUBES]: [8, 25, 26, 27],
      [ElementUnitCategory.PLATES]: [9, 28, 29, 30, 31, 32, 44]
  },
  units: {
      [ElementUnitCategory.BEAMS]: PriceRequestElementOptionUnit.EURO_BY_TON,
      [ElementUnitCategory.TUBES]: PriceRequestElementOptionUnit.EURO_BY_METER,
      [ElementUnitCategory.PLATES]: PriceRequestElementOptionUnit.EURO_BY_TON,
      default: PriceRequestElementOptionUnit.EURO_BY_UNIT
  }
};