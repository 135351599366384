import { Component, OnInit, Input } from "@angular/core";
import { IBoxInfoConfig } from "app/facade/interfaces/box-info.interface";

@Component({
  selector: "app-box-info",
  templateUrl: "./box-info.component.html",
  styleUrls: ["./box-info.component.css"]
})
export class BoxInfoComponent implements OnInit {
  @Input() configs: IBoxInfoConfig;

  constructor() { }

  ngOnInit() {
  }

}
