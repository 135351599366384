import { Injectable } from "@angular/core";
import * as moment from "moment";
import { ISnack } from "../interfaces/snack.interface";
import { IGraphQLErrorData } from "app/facade/interfaces/graphQL.interface";

@Injectable()
export class SnackService {

  private _snackBars: ISnack[] = [];
  public get snackBars(): ISnack[] { return this._snackBars; }

  constructor() { }

  /**
   * @description Open a new snack in the snack bar
   * @author Kevin Palade
   * @param {string} title
   * @param {string} content
   * @param {string} [color="primary"]
   * @param {number} [delay=3000]
   * @memberof SnackService
   */
  public open(title: string, content: string, color: string = "primary", delay: number) {
    const snack: ISnack = { title: title, content: content, delay: delay, color: color };
    snack.uuid = moment().unix(); // Give unique id (number of second since january..)
    this._snackBars.push(snack);

    // Automaticly close the snack after the given delay if different of null or 0
    if ( delay != null && delay != 0 ) {
      setTimeout(() => { this.remove(snack.uuid); }, snack.delay);
    }
  }

  /**
   * @description Remove the snack from the snackBar (Close)
   * @author Kevin Palade
   * @param {*} snackUuid
   * @memberof SnackService
   */
  public remove(snackUuid) {
    this._snackBars = this._snackBars.filter(sn => sn.uuid != snackUuid);
  }

  public openSnackBarError(title: string, defaultContent: string, error?: IGraphQLErrorData) {
    console.error(title, {error});
      let errorMessage: string;
    if (error && error.graphQLErrors) {
      errorMessage = error.graphQLErrors.map( gql => gql.infos).join(" - ");
    }
    if (!errorMessage) {
      errorMessage = defaultContent;
    }
    this.open(title, errorMessage, "danger", 0);
  }



}
