import { Component, OnInit, Input, ViewChild, ElementRef, OnChanges, SimpleChanges, Renderer2, Output, EventEmitter } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { ISelectOption } from "./selectOptions";
import { IClickOutsideComponent } from "app/facade/directives/click-outside.directive";
import { AInputs } from "../inputs";
import { ERROR_MSG_CONFIG } from "../error-message.config";

@Component({
  selector: "app-select-input",
  templateUrl: "./select-input.component.html",
  styleUrls: ["./select-input.component.css"]
})
export class SelectInputComponent extends AInputs implements OnInit, OnChanges, IClickOutsideComponent {
  @Input() public options: ISelectOption[];
  @Input() public value: string;
  @Input() public autoSelectFirst: boolean = false;
  @Output() public onClose: EventEmitter<boolean> = new EventEmitter<boolean>();

  public valueSelect: any = null;
  private _isClosed: boolean = true;
  public defaultValue : any;

  constructor(public renderer: Renderer2) {
    super(renderer);
  }

  ngOnInit() {
    if(this.group.get(this.name).value == null){
      const tmp = this.options//.filter((d) => {console.log("d.value",d.value); return d.value == this.group.get(this.name).value});
      this.valueSelect = tmp[0];
      if(tmp[0].label){
        this.defaultValue= tmp[0].label;
      }
      if (tmp.length == 0 && this.autoSelectFirst && this.options[0]) {
        this.valueSelect = this.options[0];
        this.group.get(this.name).patchValue(this.options[0].value);
      }
    }else{
      const tmp = this.options.filter((d) => {/*console.log("d.value",d.value);*/ return d.value == this.group.get(this.name).value});
      this.valueSelect = tmp[0];

      if(tmp[0].label){
        this.defaultValue= tmp[0].label;
      }
      if (tmp.length == 0 && this.autoSelectFirst && this.options[0]) {
        this.valueSelect = this.options[0];
        this.group.get(this.name).patchValue(this.options[0].value);
      }
    }

  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.options) {
      const tmp = this.options.filter((d) => d.value == this.group.get(this.name).value);
      this.valueSelect = tmp[0];
    }
    if (changes.group) {
      this.validationCheck();
    }
  }

  public showInfo() { this.close(null); }

  public open() {
    if (!this.disable) {
      this.inputReference.nativeElement.classList.add("select_active");
      this._isClosed = false;
    }
  }

  close(option: any): void {
    if (option) {
      this.group.get(this.name).patchValue(option.value);
      this.group.get(this.name).markAsTouched();
      this.valueSelect = option;
      this.onClose.emit(true);
    }
    this.inputReference.nativeElement.classList.remove("select_active");
    this._isClosed = true;
  }

  protected validationCheck() {
    this.valueSelect = null;
    this.group.get(this.name).valueChanges.subscribe(result => {
      // Use to change the selected value label
      this.valueSelect = result ? this.valueSelect : null;
      let msg = "";
      if (!this.group.get(this.name).errors) {
        this.renderer.removeClass(this.inputReference.nativeElement, "input_error");
      } else {
        this.renderer.addClass(this.inputReference.nativeElement, "input_error");
        Object.keys(this.group.get(this.name).errors).forEach(key => {
          msg += ERROR_MSG_CONFIG.get(key)(this.group.get(this.name).errors[key]);
        });
      }
      this.errorMessage = msg.length > 0 ? msg : null;
    });
  }
}
