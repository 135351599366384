export enum ElementUnitCategory {
  BEAMS,
  TUBES,
  PLATES
}

export enum EnumElementUnit {
  EURO = "EURO",
  EURO_BY_UNIT = "EURO_BY_UNIT",
  EURO_BY_TON = "EURO_BY_TON",
  EURO_BY_METER = "EURO_BY_METER",
  EURO_BY_SQUARE_METER = "EURO_BY_SQUARE_METER"
}

export enum EnumElementUnitSelectValue {
  EURO_BY_TON = "€ / T",
  EURO = "€",
  EURO_BY_UNIT = "€ / Qté",
  EURO_BY_METER = "€ / M",
  EURO_BY_SQUARE_METER = "€ / M²",
}

export enum EnumUnitValue {
  "€ / T" = "EURO_BY_TON",
  "€" = "EURO",
  "€ / Qté" = "EURO_BY_UNIT",
  "€ / M" = "EURO_BY_METER",
  "€ / M²" = "EURO_BY_SQUARE_METER",
}

export enum EnumQuantityUnit {
  UNIT = "UNIT",
  // KG = "KG",
  TON = "TON",
  METER = "METER",
  SQUARE_METER = "SQUARE_METER"
}

export enum EnumQuantityUnitSelectValue {
  UNIT = "Unité",
  // KG = "Kg",
  TON = "T",
  METER = "M",
  SQUARE_METER = "M²"
}
