import gql from "graphql-tag";

export const WELDING = gql`
query getWelding($params: FilterAction!) {
    getActionByParameters(
        actionGroupId: 6,
        params: $params
    ){
        id
        name
        natureValues
    }
}`;