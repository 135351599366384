import { Component, OnInit, Input, Output, EventEmitter, HostListener, ElementRef, Renderer2 } from "@angular/core";
import { Router } from "@angular/router";
import { IClickOutsideComponent } from "app/facade/directives/click-outside.directive";
import { QuotesQueriesService } from "app/facade/queries/quote/async/quotes_queries.service";
import { MODAL_TYPE } from "../modal/enums/modal-type.enum";
import { SnackService } from "../snack-bar/services/snack.service";
import { ModalService } from "../modal/services/modal.service";
import { ModalConfirmComponent } from "../modal";
import { ApplicationConfig } from "app/app.config";
import { PermissionService } from "app/facade/services/permission.service";
import { AuthService } from "@lib/auth/auth.service";

@Component({
  selector: "app-quote-card",
  templateUrl: "./quote-card.component.html",
  styleUrls: ["./quote-card.component.css"]
})
export class QuoteCardComponent implements OnInit, IClickOutsideComponent {
  @Output() public deleteItem: EventEmitter<string> = new EventEmitter();
  @Input() quote;
  public urlPdf = ApplicationConfig.Url;
  public url;
  isEdit = false;
  isDelete = false;
  public userGroup : any;
  public showAction: boolean = false;
  constructor( private router: Router,
    private _quoteService: QuotesQueriesService,
    private snackBar: SnackService,
    private _modalService: ModalService,
    private _el: ElementRef,
    private _permissionService: PermissionService,
    private _authSrv: AuthService,
    private _renderer: Renderer2) { }

  @HostListener("click", ["$event"]) public onClick(targetElement) {
    if (targetElement.target.className !== "") {
      const classList: string[] = targetElement.target.className.split(" "),
            avoidedClass: string[] = ["button", "table-row__item_actions", "dropdown__context"];
      let goViewDetail: boolean = true;
      avoidedClass.forEach(className => {
        if (classList.indexOf(className) !== -1) {
          goViewDetail = false;
        }
      });
      if (goViewDetail) {
        this.goToViewDetail(this.quote._id);
      }
    }
  }

  ngOnInit() {
    this.userGroup =  this._authSrv.isUserGroup();
    //get and update Permission
    this._permissionService.getAndUpdatePermission(this.userGroup);
    this.isEdit = this._permissionService.hasPermission(this.userGroup,"quotations","write") ? true : false;
    this.isDelete = this._permissionService.hasPermission(this.userGroup,"quotations","delete") ? true : false;
    this.url = this.router.url;
    this._renderer.addClass(this._el.nativeElement, "cursor_pointer");
  }

  public showInfo() {
    this.showAction = false;
  }

  /**
   * goToEditQuote
   */
  public goToEditQuote(id: string) {
    this.router.navigate([this.url + "/edit/" + id]);
  }

  public goToDeleteQuote(id: string) {
    this.deleteItem.emit(id);
  }

  public goToPdf(id: string, lang: string ) {
    window.open(this.urlPdf + "/api/quotes/" + id + ".pdf?lang=" + lang);
  }

  public workSummary(quoteId: string) {
    window.open(`${this.urlPdf}/api/quotes/${quoteId}/resume.pdf`);
  }

 /**
   * goToViewDetail
   */
  public goToViewDetail(id) {
    this.router.navigate(["/quote/" + id + "/details"]);
  }


  public goToDuplicate() {
    this._modalService.openModal(ModalConfirmComponent,
      {
        title: "Etes-vous sur?",
        type: MODAL_TYPE.NORMAL,
        data: {
          message: `Voulez vous vraiment dupliquer le devis n°<b>${this.quote.number} - ${this.quote.name}</b> ?`
        },
        params: {id: this.quote._id},
        confirmCallback: this.confirmDuplicate,
        customData: {
          confirmButtonName: "Dupliquer"
        }
      }
    );
  }

  public confirmDuplicate = (params: any) => {
    const snackBarTitle: string = "Dupliquer un devis";
    this._quoteService.duplicateQuote(params.id).subscribe( (result: any) => {
      this.snackBar.open(snackBarTitle, "Le devis a été dupliqué", "success", 5000);
      this.goToEditQuote(result.data.duplicateQuote._id);
    }, error => {
      this.snackBar.openSnackBarError(snackBarTitle, "Le devis n'a pas été dupliqué", error);
    });
  }

}
