import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-element-bdc',
  templateUrl: './element-bdc.component.html',
  styleUrls: ['./element-bdc.component.css']
})
export class ElementBdcComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
