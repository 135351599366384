import { Component, OnInit, Input, ViewChild, ElementRef, Output, EventEmitter } from "@angular/core";
import { IMultiTableGroup } from "app/facade/interfaces/multi-table-group.interface";

@Component({
  selector: "app-multi-table-group",
  templateUrl: "./multi-table-group.component.html",
  styleUrls: ["./multi-table-group.component.css"]
})
export class MultiTableGroupComponent implements OnInit {
  @Input() multiTable: IMultiTableGroup;
  @Input() hasMultiLinked: boolean = true;
  @Input() mainContentHeight: string = "45vh";
  public scrollTopPosition: number = 0;
  @Output() onEncodeData: EventEmitter<number> = new EventEmitter<number>();
  @Output() onUpdateAdditionnalData: EventEmitter<{elementId: number, additionnalData?: any}> = new EventEmitter<{elementId: number, additionnalData: any}>();
  @Output() onDeleteAdditionnalData: EventEmitter<number> = new EventEmitter<number>();

  constructor() { }

  ngOnInit() {
  }

  public encodeData(linkedId: number) {
    let linkedIdToEncode: number;
    if (this.hasMultiLinked) {
      linkedIdToEncode = linkedId;
    } else {
      linkedIdToEncode = this.multiTable.linkedData[0] ? this.multiTable.linkedData[0].id : null;
    }
    if (linkedIdToEncode) {
      this.onEncodeData.emit(linkedIdToEncode);
    }
  }

  public mainTableScroll(data: any) {
    this.scrollTopPosition = data.target.scrollTop;
  }

  public deleteAdditionnalData(additionnalData: any) {
    this.onDeleteAdditionnalData.emit(additionnalData.id);
  }

  public addAdditionnalData(elementData: any) {
    this.onUpdateAdditionnalData.emit({elementId: elementData.id});
  }

  public updateAdditionnalData(additionnalData: any, elementData: any) {
    this.onUpdateAdditionnalData.emit({elementId: elementData.id, additionnalData});
  }

}
