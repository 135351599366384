import { QuoteElementBase, QuoteElementType } from "../base";
import { AssemblageCreate } from "./assemblage.service";
import { Subject } from "rxjs";

export abstract class Assemblage<T, U> extends QuoteElementBase {

    public $ready: Subject<boolean>;

    protected definedProperties: U;

    public constructor(name: string, protected assemblageCreate: AssemblageCreate<U>, protected properties: T) {
        super(name);
        this.$ready = new Subject<boolean>();
        this.create();
    }

    protected create(): void {
        this.assemblageCreate().subscribe(definedProperties => {
            this.definedProperties = definedProperties;
            this.$ready.next(true);
            this.$ready.complete();
        });
    }

    public get Type(): QuoteElementType {
        return "ASSEMBLAGE";
    }
}