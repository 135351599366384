import { Component, Output, Input, EventEmitter, OnChanges, SimpleChanges, OnInit, OnDestroy } from "@angular/core";
import { AuthService } from "@lib/auth/auth.service";
import { TableDatasDefinition, TableInputsDefinition } from "app/facade/interfaces/table-datas-definition";
import { ListTableService } from "app/facade/services/list-table.service";
import { PermissionService } from "app/facade/services/permission.service";
import { Subscription } from "rxjs";

@Component({
  selector: "app-list-table",
  templateUrl: "./list-table.component.html",
  styleUrls: ["./list-table.component.css"]
})
export class ListTableComponent implements OnChanges, OnInit, OnDestroy {

  @Input() public categorie : string;
  @Output() public buttonClicked: EventEmitter<any> = new EventEmitter();
  @Output() public onSortTable: EventEmitter<string> = new EventEmitter<string>();
  @Output() public onLoadMore: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() public canChangeMode: boolean = false;
  @Input() public canPaginate: boolean = true;
  @Input() public canSortable: boolean = false;
  @Input() public querySort: boolean = false;
  @Input() public displayActions: boolean = true;
  @Input() public tableDatas: any[] = [];
  @Input() public tableDatasDefinition: TableDatasDefinition[] = [];
  @Input() public tableInputsDefinition: TableInputsDefinition[];
  @Input() public tableHeaders: string[] = [];
  @Input() public haveCheckbox: boolean = false;
  @Input() public tableAdditionnalClass: string;
  @Input() public contentViewHeight: string = "50vh";
  @Input() public noSortHeaders: string[] = [];
  @Input() public hasLoadMore: boolean = false;
  private _setLoadMoreSub: Subscription;
  public noLoadMore: boolean = false;


  public splittedDatas: any[][];
  public valueModeTable: boolean = true;
  public classTable: { head: string, row: string} = {
    head : "table-head",
    row : "table-row"
  };

  constructor(
    private _listTableSrv: ListTableService,

  ) { }

  ngOnInit(): void {
    if (this.haveCheckbox) {
      // TODO: Create check on special class for table head and row > Use table-head-select_send-supplier-offer
      this.classTable = {
        head : "table-head-select",
        row : "table-row-select"
      };
      let headerSize: string = "";
      if (!this.tableAdditionnalClass) {
        switch (this.tableHeaders.length) {
          case 4:
            headerSize = "_four";
            break;
          case 6:
            headerSize = "_six";
            break;
          case 7:
            headerSize = "_seven";
            break;
          case 9:
            headerSize = "_nine";
            break;
          case 10:
            headerSize = "_ten";
            break;
          case 11:
            headerSize = "_eleven";
            break;
          default:
            break;
        }
      } else {
        headerSize = this.tableAdditionnalClass;
      }
      this.classTable = {
        head: `table-head-select table-head-select${headerSize}`,
        row: `table-row-select table-row-select${headerSize}`
      };
    }
    if (this.canSortable) {
      this.classTable.head = "table-head table_sortable";
    }
    if (this.hasLoadMore) {
      this._setLoadMoreSub = this._listTableSrv.disableLoadMore.subscribe(value => { this.noLoadMore = value; });
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["tableDatas"]) {
      this.generateSplittedDatas();
    }
  }

  ngOnDestroy(): void {
    if (this._setLoadMoreSub) { this._setLoadMoreSub.unsubscribe(); }
  }

  public generateUlClass() {
    return {
      "table_mode_list": this.valueModeTable,
      "table_mode_card": !this.valueModeTable,
      "table_fix": this.tableHeaders.length > 10
    };
  }

  private generateSplittedDatas() {
    const maxListData: number = 500,
          datas: any[] = [...this.tableDatas];
    this.splittedDatas = [];
    while (datas.length !== 0) {
      this.splittedDatas.push(datas.splice(0, maxListData));
    }
  }

  public changeMode(newValue) {
    this.valueModeTable = newValue;
  }

  /**
   * @description Button has been clicked on table and emit to parent
   * @author Kevin Palade
   * @private
   * @param {*} event
   * @memberof TableComponent
   */
  public buttonHasBeenCLicked(event: any): void {
    this.buttonClicked.emit(event);
  }

  public sortHasBeenSended(event: any): void {
    if (this.canSortable) {
      const index: number  = this.tableHeaders.indexOf(event.name);
      if (this.querySort) {
        this.onSortTable.emit(this.tableDatasDefinition[index].key);
      } else {
        this.tableDatas.sort(this.compareValues(this.tableDatasDefinition[index].key, event.state));
        this.generateSplittedDatas();
      }
    }
  }

  public compareValues(key, order= true) {
    return (a, b) => {
      if (!a.hasOwnProperty(key) ||
         !b.hasOwnProperty(key)) {
        return 0;
      }

      const elementA = (typeof a[key] === "string") ?
        a[key].toUpperCase() : a[key];
      const elementB = (typeof b[key] === "string") ?
        b[key].toUpperCase() : b[key];

      let comparison = 0;
      if (elementA > elementB) {
        comparison = 1;
      } else if (elementA < elementB) {
        comparison = -1;
      }
      return (
        (order == false) ?
        (comparison * -1) : comparison
      );
    };
  }

  public loadMore() {
    this.onLoadMore.emit(true);
  }
}
