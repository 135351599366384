import { Component, OnInit, Input, OnChanges, SimpleChanges } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: "app-card-element",
  templateUrl: "./card-element.component.html",
  styleUrls: ["./card-element.component.css"]
})
export class CardElementComponent implements OnInit, OnChanges {
@Input() data: string;
@Input() activate: boolean;
@Input() big: boolean = true;
@Input() cardError: boolean;
  constructor(public route: ActivatedRoute,
              public router: Router) { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
  }

  public checkName() {
    if (this.data && this.data["CustomName"] || this.data && this.data["en1090Name"]) {
       return this.data["CustomName"] ? this.data["CustomName"] : this.data["en1090Name"];
    } else {
      return this.data["name"] ? this.data["name"] : "";
    }
  }
}
