import gql from "graphql-tag";

export const USERS = gql`
  query getUsers($search: String, $sort: UserSort, $pagination: Pagination){
      getUsers(search:$search, sort:$sort, pagination:$pagination){
        id
        username
        firstname
        lastname
        userGroup
      }
  }
`;

export const GET_USER = gql`
query getUser($id: Int!) {
  getUser(id: $id){
    id
    username
    isAdmin
    firstname
    lastname
    resetToken
    userGroup
    deletedAt
  }
}
`;

export const UPDATE_USER = gql`mutation($id:Int!,$user:UpdateUser!){
  updateUser(id:$id,user:$user){
    id
    firstname
    lastname
    userGroup
  }
}`;

export const ADD_USER = gql`mutation($data:UserInput!){
  createUser(data: $data){
    id
    firstname
    lastname
    userGroup
    isAdmin
    resetToken
    deletedAt
  }
}`;

export const DELETE_USER = gql`mutation($id:ID!){
  deleteUser(id: $id)
}`;

