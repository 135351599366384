import { Component, OnInit, Inject, OnDestroy } from "@angular/core";
import { ModalContentForm } from "../../model/modal-content-form";
import { DATA, ModalService } from "../../services/modal.service";
import { SnackService } from "app/presentationnal/organisms/snack-bar/services/snack.service";
import { FormBuilder } from "@angular/forms";
import { IPriceRequest, IPriceRequestInput } from "app/facade/interfaces/price-request.interface";
import { PriceRequestsQueriesService } from "app/facade/queries/price-request/price-requests-queries.service";
import { Subscription } from "rxjs";
import { IModalData } from "../../interfaces/modal-data.interface";
import { MODAL_CLOSE_ACTION } from "../../enums/modal-close-action.enum";
import { priceRequestStatusConfig } from "app/facade/configs/status.config";

@Component({
  selector: "app-modal-add-price-request",
  templateUrl: "./modal-add-price-request.component.html",
  styleUrls: ["./modal-add-price-request.component.css"]
})
export class ModalAddPriceRequestComponent extends ModalContentForm implements OnInit, OnDestroy {
  private _subPriceRequestReference: Subscription;
  private priceRequestData: IPriceRequest;
  private status: {value: string, label: string}[];

  constructor(
    private _priceRequestsQueriesSrv: PriceRequestsQueriesService,
    protected _modalService: ModalService,
    protected _snackBar: SnackService,
    protected _fb: FormBuilder,
    @Inject(DATA) private _data: IModalData) {
      super(_modalService, _fb, _snackBar);
      this.priceRequestData = this._data && this._data.data ? this._data.data : null;
    }

  ngOnInit() {
    this.status = priceRequestStatusConfig;
    if (!this.priceRequestData) {
      this._subPriceRequestReference = this._priceRequestsQueriesSrv.getPriceRequestReference().subscribe( result => {
        const data: any = result.data;
        this.initForm(data.getPriceRequestReference);
      }, error => {
        this.initForm(null);
        console.log("LOADING PRICE REQUESTS REFERENCE ERROR", {error});
      });
    } else {
      this.initForm(this.priceRequestData.reference);
    }
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    if (this._subPriceRequestReference) { this._subPriceRequestReference.unsubscribe(); }
  }

  private initForm(reference: string ) {
    this._formGroup = this._fb.group({
      reference: [ reference ],
      createdAt: [ {value: this.priceRequestData ? this.priceRequestData.createdAt : null, disabled: true},  ],
      remark: [ this.priceRequestData ? this.priceRequestData.remark : null ],
      internalRemark: [ this.priceRequestData ? this.priceRequestData.internalRemark : null ],
      isValidated: [ this.priceRequestData ? this.priceRequestData.isValidated : false ],
      isDone: [ this.priceRequestData ? this.priceRequestData.isDone : false ],
      status: [ this.priceRequestData ? this.priceRequestData.status : null ]
    });
  }

  protected save(data: IPriceRequest): void {
    const objData = {
      confirm: true,
      data
    };
    this.afterClosed.next(objData);
  }

  public confirmModal(): void {
    if (this._formGroup.valid) {
      switch (this._data.closeAction) {
        case MODAL_CLOSE_ACTION.SAVE:
          this.saveNewPriceRequest();
          break;
        case MODAL_CLOSE_ACTION.UPDATE:
          this.updatePriceRequest(this.priceRequestData.id);
          break;
      }
    } else {
        this.markFormGroupTouched(this._formGroup);
        this.displayErrorMessage();
    }
}

  public cancelModal(): void {
    this._priceRequestsQueriesSrv.freePriceRequestReference().subscribe( result => {
      this._modalService.closeModal();
    });
  }

  /**
   * @description Send request to save new Supplier. Closes the modal if successfull, displays error if not
   * @author Quentin Wolfs
   * @private
   * @memberof ModalAddPriceRequestComponent
   */
  private saveNewPriceRequest(): void {
    const data: IPriceRequestInput = this._formGroup.value;
  
    data.reference = this._formGroup.get("reference").value;
    delete (<any>data).isValidated;
    delete (<any>data).isDone;

    this._priceRequestsQueriesSrv.createPriceRequest(data).subscribe(result => {
      const resultData: any = result.data;
      
      if (resultData && resultData.createPriceRequest) {
        this._snackBar.open(this._data.title, "La demande de prix a été créée", "success", 5000);

        // Save was successfull, closing the modal with saved data
        this.save(resultData.createPriceRequest);
        this._modalService.closeModal();
      } else {
        this._snackBar.openSnackBarError(this._data.title, "La demande de prix n'a pas été créée");
      }
    }, error => {
      this._snackBar.openSnackBarError(this._data.title, "La demande de prix n'a pas été créée", error);
    });
  }

  /**
   * @description Send request to update existing Price Request. Closes the modal if successfull, displays error if not
   * @author Quentin Wolfs
   * @private
   * @param {number} id
   * @memberof ModalAddPriceRequestComponent
   */
  private updatePriceRequest(id: number): void {
    this._priceRequestsQueriesSrv.updatePriceRequest(id, this._formGroup.value).subscribe(result => {
      const resultData: any = result.data;
      if (resultData && resultData.updatePriceRequest) {
        this._snackBar.open(this._data.title, "La demande de prix a été mise à jour", "success", 5000);

        // Update was successfull, closing the modal with updated data
        this.save(resultData.updatePriceRequest);
        this._modalService.closeModal();
      } else {
        this._snackBar.openSnackBarError(this._data.title, "La demande de prix n'a pas été mise à jour");
      }
    }, error => {
      this._snackBar.openSnackBarError(this._data.title, "La demande de prix n'a pas été mise à jour", error);
    });
  }
}
