import { IMultiTableGroupData } from "../interfaces/multi-table-group.interface";

interface IMultiTableGroupConfigs {
  large: IMultiTableGroupData;
  plate: IMultiTableGroupData;
  short: IMultiTableGroupData;
  custom: IMultiTableGroupData;
  linked: IMultiTableGroupData;
  additionnalCostElement: IMultiTableGroupData;
  additionnalCost: IMultiTableGroupData;
}

export const PURCHASE_ORDER_MULTI_TABLE_GROUP_CLASS = {
  LARGE: "LARGE",
  PLATE: "PLATE",
  SHORT: "SHORT",
  CUSTOM: "CUSTOM"
};

export const NEW_PURCHASE_ORDER_MULTI_TABLE_GROUP_CLASS: any = {
  AMALGAM: "block-products__amalgam-bc",
  // Same number of columns as amalgams, so the same class is used
  PLATE: "block-products__amalgam-bc",
  BASIC: "block-products__basic-bc",
  CUSTOM: "block-products__custom",
  ADDITIONNAL_COST: "block-products__additionnal-cost-bc",
};

export const PURCHASE_ORDER_TABLE_CONFIG: IMultiTableGroupConfigs = {
  large: {
    headers: ["", "Qté", "Dénomination", "Matière", "Options", "Format", "En1090", "Remarque", "Poids", ""],
    datas: [],
    config: [
      {key: "quantity", isCheckBox: false, clickFct: null },
      {key: "denomination", isCheckBox: false, clickFct: null },
      {key: "matterRef", isCheckBox: false, clickFct: null },
      {key: "option", isCheckBox: false, clickFct: null },
      {key: "format", isCheckBox: false, clickFct: null },
      {key: "isEn1090", isCheckBox: true, clickFct: null },
      {key: "remark", isCheckBox: false, clickFct: null },
      {key: "weight", isCheckBox: false, clickFct: null},
      {key: "isPrinted", isCheckBox: false, type: "icon", iconClass: "fas fa-print"},
    ],
    tableClass: PURCHASE_ORDER_MULTI_TABLE_GROUP_CLASS.LARGE,
    newTableClass: NEW_PURCHASE_ORDER_MULTI_TABLE_GROUP_CLASS.AMALGAM,
    categoryId: null,
    unit: null
  },
  plate: {
    headers: ["", "Qté", "Unité", "Matière", "Epaisseur", "Longueur", "Largeur", "Remarque", "Poids", ""],
    datas: [],
    config: [
      { key: "quantity", isCheckBox: false, clickFct: null },
      { key: "quantityUnit", isCheckBox: false, clickFct: null },
      { key: "matterRef", isCheckBox: false, clickFct: null },
      { key: "thickness", isCheckBox: false, clickFct: null },
      { key: "length", isCheckBox: false, clickFct: null },
      { key: "width", isCheckBox: false, clickFct: null },
      { key: "remark", isCheckBox: false, clickFct: null },
      { key: "weight", isCheckBox: false, clickFct: null },
      { key: "isPrinted", isCheckBox: false, type: "icon", iconClass: "fas fa-print" }
    ],
    tableClass: PURCHASE_ORDER_MULTI_TABLE_GROUP_CLASS.PLATE,
    newTableClass: NEW_PURCHASE_ORDER_MULTI_TABLE_GROUP_CLASS.PLATE,
    categoryId: null,
    unit: null
  },
  short: {
    headers: ["", "Qté", "Unité", "Dénomination", "Remarque", "Poids", ""],
    datas: [],
    config: [
      {key: "quantity", isCheckBox: false, clickFct: null },
      {key: "quantityUnit", isCheckBox: false, clickFct: null },
      {key: "denomination", isCheckBox: false, clickFct: null },
      {key: "remark", isCheckBox: false, clickFct: null },
      {key: "weight", isCheckBox: false, clickFct: null },
      {key: "isPrinted", isCheckBox: false, type: "icon", iconClass: "fas fa-print"},
    ],
    tableClass: PURCHASE_ORDER_MULTI_TABLE_GROUP_CLASS.SHORT,
    newTableClass: NEW_PURCHASE_ORDER_MULTI_TABLE_GROUP_CLASS.BASIC,
    categoryId: null,
    unit: null
  },
  custom: {
    headers: ["", "Qté", "Unité", "Dénomination", "Remarque", "", ""],
    datas: [],
    config: [
      {key: "quantity", isCheckBox: false, clickFct: null },
      {key: "quantityUnit", isCheckBox: false, clickFct: null },
      {key: "denomination", isCheckBox: false, clickFct: null },
      {key: "remark", isCheckBox: false, clickFct: null },
      {key: "customElementAction", isCheckBox: false, type: "action" },
      {key: "isPrinted", isCheckBox: false, type: "icon", iconClass: "fas fa-print"},
    ],
    tableClass: PURCHASE_ORDER_MULTI_TABLE_GROUP_CLASS.CUSTOM,
    // newTableClass: NEW_PURCHASE_ORDER_MULTI_TABLE_GROUP_CLASS.BASIC,
    newTableClass: NEW_PURCHASE_ORDER_MULTI_TABLE_GROUP_CLASS.CUSTOM,
    categoryId: null,
    unit: null
  },
  linked: {
    headers: ["Prix", "Date"],
    datas: [],
    config: [
      { key: "price", isCheckBox: false, asUnit: true, clickFct: null },
      { key: "deliveryDate", isCheckBox: false, clickFct: null }
    ],
    tableClass: "",
    categoryId: null,
    unit: null
  },
  additionnalCostElement: {
    headers: ["", "Qté", "Coûts additionnels globaux", ""],
    datas: [],
    config: [
      {key: "quantity", isCheckBox: false, clickFct: null },
      {key: "denomination", isCheckBox: false, clickFct: null },
      {key: "additionnalCostAction", isCheckBox: false, type: "action"}
    ],
    tableClass: "",
    newTableClass: NEW_PURCHASE_ORDER_MULTI_TABLE_GROUP_CLASS.ADDITIONNAL_COST,
    categoryId: null,
    unit: null
  },
  additionnalCost: {
    headers: ["Prix", ""],
    datas: [],
    config: [
      {key: "price", isCheckBox: false, asUnit: true},
      {key: "emptyData", isCheckBox: false},
    ],
    tableClass: "",
    categoryId: null,
    unit: null
  }
};
