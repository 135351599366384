import gql from "graphql-tag";

export const GET_OLD_AMALGAMS = gql`
  query getAmalgams($filter: AmalgamFilter!){
    amalgams(filter: $filter){
      id
      reference
      size
      loss
      isEn1090
      isBlack
      isBlasted
      isPrimaryBlasted
      isCut
      isLocked
      icon
      matterId
      matter {
        id
        name
        en1090Name
      }
      parts {
        id
        supplyListElement {
          reference
          denomination
          format
          supplyList {
            description
            project {
              reference
            }
          }
        }
      }
    }
  }
`;

export const GET_AMALGAMS = gql`
  query getAmalgams($filter: AmalgamFilter!){
    amalgams(filter: $filter){
      id
      loss
      isLocked
      isInStock
      stockPosition
      amalgamGroupId
      amalgamGroup {
        id
        reference
        format
        isEn1090
        isBlack
        isBlasted
        isPrimaryBlasted
        isCut
        isManual
        matterRef
        icon
        remark
        matterId
        supplyCategoryId
        elementId
      }
      parts {
        id
        supplyListElementId
        supplyListElement {
          reference
          denomination
          format
          supplyList {
            description
            project {
              reference
            }
          }
        }
      }
    }
  }
`;

export const SAVE_AMALGAMS = gql`
  mutation saveAmalgams($priceRequestId: ID!, $data: [AmalgamInput!]!, $generation: BarsetGenerationUpdate) {
    saveAmalgams(priceRequestId: $priceRequestId, data: $data, generation: $generation){
      id
      loss
      isLocked
      isInStock
      stockPosition
      amalgamGroupId
      amalgamGroup {
        id
        reference
        format
        isEn1090
        isBlack
        isBlasted
        isPrimaryBlasted
        isCut
        isManual
        matterRef
        icon
        remark
        matterId
        supplyCategoryId
        elementId
      }
      parts {
        id
        supplyListElementId
        supplyListElement {
          reference
          denomination
          format
          supplyList {
            description
            project {
              reference
            }
          }
        }
      }
    }
  }
`;

export const GENERATE_AMALGAMS = gql`
  query generateAmalgams($priceRequestId: ID!, $params: AmalgamParam, $locked: [AmalgamInput!]){
    generateAmalgams(priceRequestId: $priceRequestId, params: $params, locked: $locked) {
      amalgams {
        loss
        isLocked
        isInStock
        stockPosition
        amalgamGroupId
        amalgamGroup {
          reference
          format
          isEn1090
          isBlack
          isBlasted
          isPrimaryBlasted
          isCut
          isManual
          matterRef
          icon
          remark
          matterId
          supplyCategoryId
          elementId
        }
        parts {
          supplyListElementId
          supplyListElement {
            reference
            denomination
            format
            supplyList {
              description
              project {
                reference
              }
            }
          }
        }
      }
      barsetGeneration {
        id
        beamLength
        beamOtherLengths
        beamIsAutoCut
        beamCutThreshold
        beamMaxLoss
        tubeLength
        tubeOtherLengths
        tubeIsAutoCut
        tubeCutThreshold
        tubeMaxLoss
        generationDuration
        partsTotalLength
        amalgamsTotalLength
        totalLoss
        lossPercent
        partsQuantity
        amalgamsQuantity
      }
    }
  }
`;