import gql from "graphql-tag";

export const GET_MATTERS_BY_SUPPLIER = gql`
  query getMattersBySupplier($supplierId : ID!){
    mattersBySupplier(supplierId: $supplierId){
      id
      name
      selected
    }
  }
`;

export const SET_MATTERS_OF_SUPPLIER = gql`
  mutation setMattersOfSupplier($supplierId: ID!, $data: [SelectedMattersInput!]!){
    setMattersOfSupplier(supplierId: $supplierId, data: $data)
  }
`;