import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { BaseModule } from "../base.module";
import { PortalModule } from "@angular/cdk/portal";
import { A11yModule } from "@angular/cdk/a11y";
import {
  BarsetComponent,
  BarsetItemComponent,
  BarsetItemContentComponent,
  BarsetInfosItemComponent,
  BarsetBarComponent,
  BarsetBarContentComponent,
  BarsetScaleComponent,
  ChoiceElementGroupModalComponent,
  ElementDimensionComponent,
  ElementSummaryModalComponent,
  DrillingStepOneComponent,
  SawingStepOneComponent,
  GalvanizationStepOneComponent,
  LacqueringStepOneComponent,
  MetallizationStepOneComponent,
  PaintingStepOneComponent,
  SandblastingStepOneComponent,
  HoistStepOneComponent,
  MarginStepOneComponent,
  MountingStepOneComponent,
  TransportStepOneComponent,
  PodStepOneComponent,
  BreadcrumbsComponent,
  CardElementComponent,
  ContainerCardsComponent,
  CalendarComponent,
  DatePickerComponent,
  InputDateComponent,
  EmptyComponent,
  EmptyQuoteComponent,
  HeaderComponent,
  InformationsCardComponent,
  ItemCardComponent,
  LoginComponent,
  PrListComponent,
  QuoteCardComponent,
  QuoteCardsComponent,
  SecondaryBarComponent,
  SubheaderComponent,
  TableComponent,
  TableItemComponent,
  TableFormComponent,
  TableFormItemComponent,
  ListTableComponent,
  ListTableItemComponent,
  TicketComponent,
  TicketEmptyComponent,
  TicketSummaryComponent,
  TreeCheckboxComponent,
  TreeCheckboxItemComponent,
  NavStepComponent,
  WizardModalComponent,
  StepFormService,
  StudyStepOneComponent,
  EmbaseStepZeroComponent,
  EmbaseStepOneComponent,
  EmbaseStepTwoComponent,
  EmbaseStepThreeComponent,
  EmbaseSummaryComponent,
  EquerreStepZeroComponent,
  EquerreStepOneComponent,
  EquerreStepTwoComponent,
  EquerreStepThreeComponent,
  EquerreStepFourComponent,
  EquerreSummaryComponent,
  GoussetStepZeroComponent,
  GoussetStepOneComponent,
  GoussetStepTwoComponent,
  GoussetStepThreeComponent,
  GoussetSummaryComponent,
  NervureStepZeroComponent,
  NervureStepOneComponent,
  NervureStepTwoComponent,
  NervureStepThreeComponent,
  NervureSummaryComponent,
  PlaqueStepZeroComponent,
  PlaqueStepOneComponent,
  PlaqueStepTwoComponent,
  PlaqueStepThreeComponent,
  PlaqueSummaryComponent,
  RaidisseurStepZeroComponent,
  RaidisseurStepOneComponent,
  RaidisseurStepTwoComponent,
  RaidisseurStepThreeComponent,
  RaidisseurSummaryComponent,
  MultiTableComponent,
  MultiTableLinkedComponent,
  MultiTableGroupComponent,
  MultiTableGroupLinkedComponent,
  SupplyTableComponent,
  SupplyTableLinkedComponent,
  SupplyTableFormItemComponent} from ".";
import { CustomElementService } from "app/facade/quote-element-definition/elements/customs/custom.service";
import { OperationService } from "app/facade/quote-element-definition/operations/operation.service";
import { AssemblageService } from "app/facade/quote-element-definition/assemblages/assemblage.service";
import { StandardElementService } from "app/facade/quote-element-definition/elements/standard/element.service";
import { FinishService } from "app/facade/quote-element-definition/finish/finish.service";
import {
  ModalAddQuotesProjectComponent,
  ModalConfirmComponent,
  ModalEditRemarksComponent,
  ModalAddSupplierComponent,
  ModalAddSupplierContactComponent,
  ModalAddProjectComponent,
  ModalAddSupplyListComponent,
  ModalAddPriceRequestComponent,
  ModalLinkSupplyListComponent,
  ModalMoveAmalgamPartComponent,
  ModalGenerateNewAmalgamsComponent,
  ModalAddPurchaseOrderComponent,
  ModalAddSupplierOfferComponent,
  ModalEncodeSupplierOfferComponent,
  ModalUpdatePriceRequestElementRemarkComponent,
  ModalUpdateSupplierOfferContactComponent,
  ModalUpdateBarsetStockPositionComponent,
  ModalSelectSupplierOfferComponent,
  ModalInpdatePriceRequestElementOptionComponent,
  ModalInpdatePriceRequestAdditionnalCostComponent,
  ModalUpdateSupplierOfferElementsListComponent,
  ModalConsultCreatedPurchaseOrderComponent,
  ModalInpdatePurchaseOrderElementComponent,
  ModalInpdatePurchaseOrderAdditionnalCostComponent,
  ModalSelectPurchaseOrderElementsToGenerateLabelsPdfComponent,
  ModalSetCustomAmalgamFormatComponent,
  ModalAddScanPdfComponent,
  ModalAddAdmissionComponent,
  ModalEditScanPdfComponent,
  ModalShowScanPdfComponent} from "./modal";
import { ModalComponent } from "./modal/modal.component";
import { RouterModule } from "@angular/router";
import { OptionService } from "app/facade/quote-element-definition/options/options.service";
import { Null } from "app/facade/pipe/Null.pipe";
import { UsersCardComponent } from './users-card/users-card.component';
import { ModalUpdatePriceRequestElementFormatComponent } from "./modal/components/modal-update-price-request-element-format/modal-update-price-request-element-format.component";
import { ModalUpdatePriceRequestElementDenominationComponent } from "./modal/components/modal-update-price-request-element-denomination/modal-update-price-request-element-denomination.component";
import { ModalUpdatePriceRequestElementQuantityComponent } from "./modal/components/modal-update-price-request-element-quantity/modal-update-price-request-element-quantity.component";
import { ModalUpdatePriceRequestElementMatiereComponent } from "./modal/components/modal-update-price-request-element-matiere/modal-update-price-request-element-matiere.component";
import { ModalUpdatePriceRequestElementWeightComponent } from "./modal/components/modal-update-price-request-element-weight/modal-update-price-request-element-weight.component";
import { ModalUpdatePriceRequestElementWidthComponent } from "./modal/components/modal-update-price-request-element-width/modal-update-price-request-element-width.component";
import { ModalUpdatePriceRequestElementLengthComponent } from "./modal/components/modal-update-price-request-element-length/modal-update-price-request-element-length.component";
import { ModalUpdatePriceRequestElementThicknessComponent } from "./modal/components/modal-update-price-request-element-thickness/modal-update-price-request-element-thickness.component";
import { ModalUpdatePriceRequestElementQuantityUnitComponent } from "./modal/components/modal-update-price-request-element-quantity-unit/modal-update-price-request-element-quantity-unit.component";
import { ModalUpdatePriceRequestElementEn1090Component } from "./modal/components/modal-update-price-request-element-en1090/modal-update-price-request-element-en1090.component";
import { ModalUpdatePriceRequestElementOptionsComponent } from "./modal/components/modal-update-price-request-element-options/modal-update-price-request-element-options.component";
import { ModalUpdatePriceRequestElementPriceComponent } from "./modal/components/modal-update-price-request-element-price/modal-update-price-request-element-price.component";
import { ModalUpdatePriceRequestElementDeliveryDateComponent } from "./modal/components/modal-update-price-request-element-delivery-date/modal-update-price-request-element-delivery-date.component";


const COMPONENTS = [
  BarsetComponent,
  BarsetItemComponent,
  BarsetItemContentComponent,
  BarsetInfosItemComponent,
  BarsetBarComponent,
  BarsetBarContentComponent,
  BarsetScaleComponent,
  BreadcrumbsComponent,
  CardElementComponent,
  ContainerCardsComponent,
  CalendarComponent,
  DatePickerComponent,
  InputDateComponent,
  EmptyComponent,
  EmptyQuoteComponent,
  HeaderComponent,
  InformationsCardComponent,
  ItemCardComponent,
  LoginComponent,
  PrListComponent,
  QuoteCardComponent,
  QuoteCardsComponent,
  SecondaryBarComponent,
  SubheaderComponent,
  TableComponent,
  TableItemComponent,
  TableFormComponent,
  TableFormItemComponent,
  ListTableComponent,
  ListTableItemComponent,
  TicketComponent,
  TicketEmptyComponent,
  TicketSummaryComponent,
  TreeCheckboxComponent,
  TreeCheckboxItemComponent,
  NavStepComponent,
  WizardModalComponent,
  MultiTableComponent,
  MultiTableLinkedComponent,
  MultiTableGroupComponent,
  MultiTableGroupLinkedComponent,
  SupplyTableComponent,
  SupplyTableLinkedComponent,
  SupplyTableFormItemComponent,
];

const SERVICES = [
  StepFormService
];

const MODAL_COMPONENTS = [
  ModalComponent,
  ModalAddQuotesProjectComponent,
  ModalConfirmComponent,
  ModalAddPurchaseOrderComponent,
  ModalEditRemarksComponent,
  ModalAddSupplierComponent,
  ModalAddSupplierContactComponent,
  ModalAddProjectComponent,
  ModalAddSupplyListComponent,
  ModalAddPriceRequestComponent,
  ModalLinkSupplyListComponent,
  ModalMoveAmalgamPartComponent,
  ModalGenerateNewAmalgamsComponent,
  ModalAddSupplierOfferComponent,
  ModalEncodeSupplierOfferComponent,
  ModalUpdatePriceRequestElementRemarkComponent,
  ModalUpdatePriceRequestElementFormatComponent,
  ModalUpdatePriceRequestElementDenominationComponent,
  ModalUpdatePriceRequestElementQuantityComponent,
  ModalUpdatePriceRequestElementMatiereComponent,
  ModalUpdatePriceRequestElementWeightComponent,
  ModalUpdatePriceRequestElementWidthComponent,
  ModalUpdatePriceRequestElementLengthComponent,
  ModalUpdatePriceRequestElementThicknessComponent,
  ModalUpdatePriceRequestElementPriceComponent,
  ModalUpdatePriceRequestElementDeliveryDateComponent,
  ModalUpdatePriceRequestElementQuantityUnitComponent,
  ModalUpdatePriceRequestElementLengthComponent,
  ModalUpdatePriceRequestElementEn1090Component,
  ModalUpdatePriceRequestElementOptionsComponent,
  ModalUpdateSupplierOfferContactComponent,
  ModalUpdateBarsetStockPositionComponent,
  ModalSelectSupplierOfferComponent,
  ModalInpdatePriceRequestElementOptionComponent,
  ModalInpdatePriceRequestAdditionnalCostComponent,
  ModalUpdateSupplierOfferElementsListComponent,
  ModalConsultCreatedPurchaseOrderComponent,
  ModalInpdatePurchaseOrderElementComponent,
  ModalInpdatePurchaseOrderAdditionnalCostComponent,
  ModalSelectPurchaseOrderElementsToGenerateLabelsPdfComponent,
  ModalAddAdmissionComponent,
  ModalAddScanPdfComponent,
  ModalEditScanPdfComponent,
  ModalShowScanPdfComponent,
  ChoiceElementGroupModalComponent,
  ElementDimensionComponent,
  ElementSummaryModalComponent,
  GalvanizationStepOneComponent,
  LacqueringStepOneComponent,
  MetallizationStepOneComponent,
  PaintingStepOneComponent,
  SandblastingStepOneComponent,
  DrillingStepOneComponent,
  SawingStepOneComponent,
  HoistStepOneComponent,
  MarginStepOneComponent,
  MountingStepOneComponent,
  PodStepOneComponent,
  TransportStepOneComponent,
  StudyStepOneComponent,
  ModalSetCustomAmalgamFormatComponent,
  // CUSTOM STEPS
  EmbaseStepZeroComponent,
  EquerreStepZeroComponent,
  EquerreStepOneComponent,
  EquerreStepTwoComponent,
  EquerreStepThreeComponent,
  EquerreStepFourComponent,
  EquerreSummaryComponent,
  EmbaseStepOneComponent,
  EmbaseStepTwoComponent,
  EmbaseStepThreeComponent,
  EmbaseSummaryComponent,
  GoussetSummaryComponent,
  GoussetStepOneComponent,
  GoussetStepTwoComponent,
  GoussetStepThreeComponent,
  NervureSummaryComponent,
  NervureStepOneComponent,
  NervureStepTwoComponent,
  NervureStepThreeComponent,
  PlaqueSummaryComponent,
  PlaqueStepOneComponent,
  PlaqueStepTwoComponent,
  PlaqueStepThreeComponent,
  RaidisseurSummaryComponent,
  RaidisseurStepOneComponent,
  RaidisseurStepTwoComponent,
  RaidisseurStepThreeComponent,
  RaidisseurStepZeroComponent,
  PlaqueStepZeroComponent,
  NervureStepZeroComponent,
  GoussetStepZeroComponent,
];
@NgModule({
  declarations: [
    ...COMPONENTS,
    ...MODAL_COMPONENTS,
    Null,
    UsersCardComponent,
  ],
  entryComponents: [
    ...MODAL_COMPONENTS
  ],
  exports: [
    ...COMPONENTS,
    ...MODAL_COMPONENTS,
    BaseModule,
  ],
  imports: [
    CommonModule,
    BaseModule,
    RouterModule,
    PortalModule,
    A11yModule
  ],
  providers: [
    ...SERVICES,
    CustomElementService,
    OperationService,
    AssemblageService,
    StandardElementService,
    FinishService,
    OptionService
  ]
})
export class OrganismsModule { }
