import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { OrganismsModule } from "app/presentationnal/organisms/organisms.module";
import { PriceRequestsQueriesService } from "app/facade/queries/price-request/price-requests-queries.service";
import { ListingPriceRequestComponent } from "./listing-price-request/listing-price-request.component";
import { PriceRequestDetailsComponent } from "./price-request-details/price-request-details.component";

@NgModule({
  imports: [
    CommonModule,
    OrganismsModule
  ],
  declarations: [
    ListingPriceRequestComponent,
    PriceRequestDetailsComponent
  ],
  providers: [
    PriceRequestsQueriesService
  ]
})
export class PriceRequestsModule { }
