import { Component, OnInit, Input, OnDestroy, EventEmitter, Output, ElementRef, ViewChild, Renderer2 } from "@angular/core";
import { FormBuilder, FormGroup, FormArray, FormControl, Validators } from "@angular/forms";
import { Subscription } from "rxjs";
import { StepFormService, ActualData } from "../wizard-modal/service/step-form.service";
import { QuoteElement, Quote } from "app/facade/interfaces";
import { QuotesQueriesService } from "app/facade/queries/quote/async/quotes_queries.service";
import { Router } from "@angular/router";
import { SnackService } from "../snack-bar/services/snack.service";
import { QuoteElementBase, quoteCertificates } from "app/facade/quote-element-definition/base";
import { ModalService } from "../modal/services/modal.service";
import { MODAL_TYPE } from "../modal/enums/modal-type.enum";
import { ModalConfirmComponent } from "../modal";
import { ISelectOption } from "app/presentationnal/atoms/inputs/select-input/selectOptions";
import { deepClone } from "@lib/misc/clone";
import { ApplicationConfig } from "app/app.config";

@Component({
  selector: "app-ticket",
  templateUrl: "./ticket.component.html",
  styleUrls: ["./ticket.component.css"]
})
export class TicketComponent implements OnInit, OnDestroy {
  @ViewChild("popOverContent") public popOverContent: ElementRef;
  @ViewChild("popOver") public popOver: ElementRef;
  @Input("ticket") public ticket;
  @Input("quote") public quote;
  public dataTicket: {element: QuoteElementBase, children: QuoteElementBase[]}[];
  public numForm: FormGroup;
  public choiceElementForm: FormGroup;
  public ticketSub: Subscription;
  public totalPrice: number;
  public indexAccordion: number;
  public certificates: Set<string>;
  public certificatesTotalPrice: number = 0;
  public certificatePrice: number;
  public quoteCharges: number ;
  public elementChooseOptions: ISelectOption[][];
  @Output() public openEditWizard: EventEmitter<{actualData: ActualData, elementClass: string}> = new EventEmitter<{actualData: ActualData, elementClass: string}>();

  constructor( private _renderer: Renderer2,
              protected _fb: FormBuilder,
              public router: Router,
              private _stepFormSrv: StepFormService,
              private _quoteSrv: QuotesQueriesService,
              private _snackBarSrv: SnackService,
              private _modalService: ModalService) {
  }

  ngOnInit() {
    this.certificatePrice = this.quote && this.quote.isEn1090 ? 15 : 0;
    this.quoteCharges = this.quote && this.quote.isEn1090 ? 750 : 0;
    this.ticketSub = this._stepFormSrv.getUpdateTicket().subscribe(result => {
      this.dataTicket = result;
      this.certificates = quoteCertificates(this.dataTicket);
      this.certificatesTotalPrice = this.certificates.size * this.certificatePrice;
      this.indexAccordion = (this._stepFormSrv.getLastElementSelected() + 1);
      this.calculateTotalPriceAndReformatName();
      this.checkData();
      // TO CHECK PRICE
      // this.checkTotalCalcul();
    });
  }

  ngOnDestroy(): void {
    this.ticketSub.unsubscribe();
  }

  private checkTotalCalcul(): void {
    let findData: any,
        detailList: any,
        element: any;
    const listElement: any[] = [],
          listOperation: any[] = [],
          totalList: any[] = [];
    if (this.dataTicket) {
      this.dataTicket.forEach( ticketLine => {
        element = ticketLine.element;
        detailList = {label: element.constructor.name, name: element.CustomName , price: element.Price, quantity: element.Quantity, total: element.Price * element.Quantity, totalPrice: 0, customs: []};
        totalList.push(detailList);
        findData = listElement.find( ope => ope.label === element.constructor.name);
        if (findData) {
          findData.price += element.Price * element.Quantity;
        } else {
          listElement.push({label: element.constructor.name, price: element.Price * element.Quantity});
        }
        let elementPrice = element.Price;

        if (ticketLine.children) {
          ticketLine.children.forEach( children => {
            findData = listOperation.find( ope => ope.label === children.constructor.name);
            if (findData) {
              findData.price += children.Price * children.Quantity * element.Quantity;
            } else {
              listOperation.push({label: children.constructor.name, price: children.Price * children.Quantity * element.Quantity});
            }
            elementPrice += children.Price * children.Quantity;
            detailList.customs.push({label: children.constructor.name, price: children.Price, quantity: children.Quantity, total: children.Price * children.Quantity});
          });
        }
        detailList.totalPrice = elementPrice * element.Quantity;
      });
    }
    // let childPrice: number;
    // totalList.forEach( elementList => {
    //   childPrice = 0;
    //   console.log(elementList.name , " Prix sans child =", elementList.total, "€");
    //   console.log("<<< <<< <<< <<< CHILD >>> >>> >>> >>>");
    //   elementList.customs.forEach( custom => {
    //     console.log(custom.label , " = ", custom.total, "€");
    //     childPrice += custom.total;
    //   });
    //   console.log("Prix des child = ", childPrice , "*", elementList.quantity, "=", childPrice * elementList.quantity);
    //   console.log(elementList.name , " = ", elementList.totalPrice, "€");
    //   console.log("________________________________________________");
    // });
  }


  private initForm() {
    this.numForm = this._fb.group({
      element: this._fb.array([]),
    });
  }

  private choiceFrom() {
    this.choiceElementForm = this._fb.group({
      elementChoose: (this._stepFormSrv.getLastElementSelected() + 1),
    });

    this.elementChooseOptions = [];
    let i = 1;
    while (i <= this.dataTicket.length) {
      this.elementChooseOptions.push([{
        label: null,
        value: i++,
      }]);
    }

    this.choiceElementForm.get("elementChoose").valueChanges.subscribe(result => {
      this.indexAccordion = result;
      this._stepFormSrv.setLastElementSelected(this.indexAccordion - 1);
    });
  }

  public createItem(data?: {element: QuoteElementBase, children: QuoteElementBase[]}) {
    const itemForm: any = {
      quantity: [data && data.element ? data.element.Quantity : 1, Validators.compose([
        Validators.required,
        Validators.min(1)
      ])],
      nameLmt: [data && data.element ? data.element.CustomName  : null, Validators.required],
      price: [data && data.element ? data.element.Price : 250, Validators.required],
      children: this._fb.array([]),
      isChecked: false,
    };

    return this._fb.group(itemForm);
  }

  public createChildren(data?): FormGroup {
    const childrenForm = {
      quantityChild: [data ? data.Quantity : 1, Validators.compose([
        Validators.required,
        Validators.min(1)
      ])],
      nameChild: [data ? data.CustomName : null, Validators.required],
      priceChild: [data ? data.Price : 50, Validators.required],
    };
    return this._fb.group(childrenForm);
  }

  private addRow(elementTicket) {
    const newFormGroup: FormGroup = this.createItem(elementTicket);
    (<FormArray>this.numForm.get("element")).push(newFormGroup);
  }


  /**
   * @description Calculate price and reformate denomination if she's not contain the reference of the element
   * @author Lainez Eddy
   * @private
   * @memberof TicketComponent
   */
  private calculateTotalPriceAndReformatName(): void {
    let element: any;
    this.totalPrice = this.quoteCharges + this.certificatesTotalPrice;
    if (this.dataTicket) {
      this.dataTicket.forEach( ticketLine => {
        element = ticketLine.element;
        // console.log("EDDY - PARENT >>> ", element.properties);
        if (element.reference) {
          if (element.CustomName.search(element.reference) === -1) {
            element.CustomName = element.CustomName.concat(" - ", element.reference);
          }
        } else {
          if (element.CustomName.search(element.name) === -1) {
            element.CustomName = element.CustomName.concat(" - ", element.name);
          }
        }
        let elementPrice = element.Price;
        if (ticketLine.children) {
          ticketLine.children.forEach( children => {
            if (children.CustomName.search(children.name) === -1) {
              children.CustomName = children.CustomName.concat(" - ", children.name);
            }
            // console.log("EDDY - CHILDREN >>> ", children);
            elementPrice += children.Price * children.Quantity;
          });
        }
        this.totalPrice += elementPrice * element.Quantity;
      });
    }
    this.totalPrice = +this.totalPrice.toFixed(4);
  }

  public checkData() {
    this.initForm();
    this.choiceFrom();
    if (this.dataTicket) {
      this.dataTicket.forEach((elementTicket, index) => {
        this.addRow(elementTicket);
        const control = (<FormArray>this.numForm.get("element")["controls"][index].get("children"));
        elementTicket.children.forEach((element, indexElm) => {
          control.push(this.createChildren(element));
        });
      });
    }
  }

  public deleteElement(index: number, childrenIndex?: number) {
    const elementType: string = childrenIndex === undefined ? "cet élément" : "cette option",
          elementName: string = childrenIndex === undefined ? this.dataTicket[index].element.CustomName : this.dataTicket[index].children[childrenIndex].CustomName,
          actionMessage: string = childrenIndex === undefined ? "l'élément" : "l'option",
          actions: string[] = [`Supprimer ${actionMessage}`];

    if (childrenIndex === undefined && this.dataTicket[index].children.length !== 0) {
      actions.push("Supprimer les options liées à cet élément");
    }
    this._modalService.openModal(ModalConfirmComponent,
      {
        title: "Etes-vous sur?",
        type: MODAL_TYPE.CONFIRM,
        data: {
          message: `Voulez vous vraiment supprimer ${elementType} <b>${elementName}</b>?`,
          actions
        },
        params: {index, childrenIndex},
        confirmCallback: this.confirmDeleteElement
      }
    );
  }

  public confirmDeleteElement = (params: {index: number, childrenIndex?: number}) => {
    this._stepFormSrv.deleteElement(params.index, params.childrenIndex);
  }

  public editElement(index: number, childrenIndex?: number) {
    const elementInfo: any = this.dataTicket[index];
    let element: QuoteElementBase,
        actualData: ActualData;
    if (childrenIndex === undefined) {
      element = elementInfo.element;
      // Todo: Adapt For Element
      actualData = {
        element,
        index,
        isEdited: true
      };
    } else {
      element = elementInfo.children[childrenIndex];
      actualData = {
        custom: element,
        index,
        childrenIndex,
        summary: [],
        isEdited: true
      };
    }
    const elementClass: string = element.constructor.name;
    this.openEditWizard.emit({actualData, elementClass});
  }

  public updateValue(form: FormControl, type: string, indexElement: number, indexChildren?: number) {
    if (form.valid) {
      this._stepFormSrv.updateElement(form.value, type, indexElement, indexChildren);
    }
  }

  public checkElementChildren(indexElement: number) {
    if (this.dataTicket[indexElement].children.length === 0) {
      this.duplicateElement(indexElement);
    } else {
      this._modalService.openModal(ModalConfirmComponent,
        {
          title: "Etes-vous sur?",
          type: MODAL_TYPE.NORMAL,
          data: {
            message: `Voulez vous également dupliquer les différentes options liées à cet élément ?`
          },
          params: {indexElement},
          confirmCallback: this.duplicateOnlyElement,
          cancelCallback: this.duplicateAllElement,
          customData: {
            confirmButtonName : "Non",
            confirmButtonColor : "red",
            cancelButtonName : "Oui",
            cancelButtonIsLink : false
          }
        }
      );
    }
  }

  public duplicateOnlyElement = (params: any) => {
    const newElement: any = deepClone(this.dataTicket[params.indexElement].element);
    newElement.Angle1 = null;
    newElement.Angle2 = null;
    this._stepFormSrv.addElement(newElement);
  }

  public duplicateAllElement = (params: any) => {
    this.duplicateElement(params.indexElement);
  }

  public duplicateElement(indexElement: number, indexChildren?: number) {
    this._stepFormSrv.duplicateElement(indexElement, indexChildren);
  }

  public saveQuote(index?: number) {
    const elements: QuoteElement[] = this.dataTicket.map( element => {
      return this._stepFormSrv.generateElementData(element);
    });
    const quoteData: Quote = {
      totalPrice : this.totalPrice,
      elements: elements
    };

    const snackBarTitle: string = "Créer un devis";
    this._quoteSrv.updateQuote(this._stepFormSrv.getQuoteId(), quoteData).subscribe(result => {
      this._snackBarSrv.open(snackBarTitle, "Le devis a été créé", "success", 5000);
      this.router.navigate(["/quote/" + this._stepFormSrv.getQuoteId() + "/details"]);
    }, error => {
      this._snackBarSrv.openSnackBarError(snackBarTitle, "Le devis n'a pas été créé", error);
    });
  }

  public exportPdf(lang: string) {
        window.open(ApplicationConfig.Url + "/api/quotes/" + this._stepFormSrv.getQuoteId() + ".pdf?lang=" + lang);
  }

  public printCertificates(event) {
    const el = this.popOver.nativeElement;
    const content = this.popOverContent.nativeElement;
    // Define the position
    const posXa = el.getBoundingClientRect().right;
    const posXb = el.getBoundingClientRect().left;
    const posX = ((posXa - posXb) / 2) + posXb;
    const posY = el.getBoundingClientRect().bottom;
    // Set the position
    content.style.left = `${posX}px`;
    content.style.top = `${posY}px`;

    this._renderer.addClass(el, "popover_active");
  }
  public hideCertificates(event) {
    this._renderer.removeClass(this.popOver.nativeElement, "popover_active");
  }

}
