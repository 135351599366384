import { Component, OnInit, Inject } from "@angular/core";
import { ModalService, DATA } from "../../services/modal.service";
import { SnackService } from "app/presentationnal/organisms/snack-bar/services/snack.service";
import { FormBuilder, Validators } from "@angular/forms";
import { ModalContentForm } from "../../model/modal-content-form";
import { OPTION_LABELS } from "app/facade/configs/price-request.config";

@Component({
  selector: "app-modal-update-price-request-element-options",
  templateUrl: "./modal-update-price-request-element-options.component.html",
  styleUrls: ["./modal-update-price-request-element-options.component.css"]
})
export class ModalUpdatePriceRequestElementOptionsComponent extends ModalContentForm implements OnInit {

  private options: { value: string, label: string }[];

  constructor(
    protected _modalService: ModalService,
    protected _snackBar: SnackService,
    protected _fb: FormBuilder,
    @Inject(DATA) private _data) {
    super(_modalService, _fb, _snackBar);
  }

  ngOnInit() {
    this.options = Object.keys(OPTION_LABELS).map(unit => ({ value: unit, label: OPTION_LABELS[unit] }));
    this.initForm();
  }

  private initForm() {
    this._formGroup = this._fb.group({
      option: [
        this._data.data.option ? this.options.find(option => option.label === this._data.data.option).value : "BLACK", Validators.maxLength(200)]
    });
  }

  protected save(): void {
    const objData = {
      confirm: true,
      data: this._formGroup.value,
    };
    this.afterClosed.next(objData);
  }
}
