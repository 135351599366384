import { Poutrelle, PoutrelleNatureValues, PoutrelleProperty } from "./poutrelle";

export class Hem extends Poutrelle {

    public constructor(
        name: string,
        reference: string,
        values: PoutrelleNatureValues,
        properties: PoutrelleProperty) {
        super(name, reference, values, properties);
    }
}