import { Operation, OperationCreate } from "./operation.base";
import { IAction, MM_TO_M } from "../base";
import { Element } from "../elements/element.base";

export interface IDrillThickness {
    drillThickness: number;
}

export interface DrillingDefinedProperty {
    Prc: number;
    PriceByM: number;
}

export interface DrillingProperty extends DrillingDefinedProperty {
    isCalculated: boolean;
    holeQuantity: number;
    isRound?: boolean;
    holePrice?: number;
    width?: number;
    height?: number;
    diameter?: number;
}

export interface IDrillable extends Element {
    thickness: number;
}

export interface DrillingBuild extends IAction<IDrillable, DrillingProperty> {
}

export class Drilling extends Operation<IDrillable, DrillingProperty, DrillingDefinedProperty> {

    public constructor(
        element: IDrillable,
        properties: DrillingProperty,
        operationCreate: OperationCreate<IDrillable, DrillingDefinedProperty>) {

        super(element, properties, operationCreate);
    }

    public get Prc(): number {
        return this.properties.Prc != null ? this.properties.Prc : this.definedProperties.Prc;
    }

    public set Prc(value: number) {
        this.properties.Prc = value;
    }

    public get PriceByM(): number {
        return this.properties.PriceByM != null ? this.properties.PriceByM : this.definedProperties.PriceByM;
    }

    public set PriceByM(value: number) {
        this.properties.PriceByM = value;
    }

    public get Diameter(): number {
        return this.properties.diameter;
    }

    public set Diameter(value: number) {
        this.properties.diameter = value;
    }

    public get Height(): number {
        return this.properties.height;
    }

    public set Height(value: number) {
        this.properties.height = value;
    }

    public get Width(): number {
        return this.properties.width;
    }

    public set Width(value: number) {
        this.properties.width = value;
    }

    public get HoleQuantity(): number {
        return this.properties.holeQuantity;
    }

    public set HoleQuantity(value: number) {
        this.properties.holeQuantity = value;
    }

    public get HolePrice(): number {
        return this.properties.holePrice;
    }

    public set HolePrice(value: number) {
        this.properties.holePrice = value;
    }

    public get IsCalculated(): boolean {
        return this.properties.isCalculated;
    }

    public set IsCalculated(value: boolean) {
        this.properties.isCalculated = value;
    }

    public get IsRound(): boolean {
        return this.properties.isRound;
    }

    public set IsRound(value: boolean) {
        this.properties.isRound = value;
    }

    public get Perimeter(): number {
        const { isRound, width, height, diameter } = this.properties;
        return isRound ? diameter * Math.PI : ((width + height) * 2);
    }

    public get CalculatedPrice(): number {
        return this.HoleQuantity * (this.IsCalculated ? 3 * (this.Prc + ((this.Perimeter * MM_TO_M) * this.PriceByM)) : this.HolePrice);
    }

    protected updateAfterElementChange(): void {
        // delete this.properties.PriceByM;
    }
}