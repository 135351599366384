import { Component, OnInit, Inject } from "@angular/core";
import { StepSummaryBase } from "../../base/step-summary-base";
import { CONTAINER_DATA, StepFormService } from "app/presentationnal/organisms/wizard-modal/service/step-form.service";
import { FormBuilder } from "@angular/forms";

@Component({
  selector: "app-nervure-summary",
  templateUrl: "./nervure-summary.component.html",
  styleUrls: ["./nervure-summary.component.css"]
})
export class NervureSummaryComponent extends StepSummaryBase implements OnInit {

  constructor(@Inject(CONTAINER_DATA) data: any,
              protected _stepFormSrv: StepFormService,
              protected _fb: FormBuilder) {
    super(data, _stepFormSrv, _fb);
  }

  protected formatWeldingSummary(weldingData: any, customData: any): any[] {
    const tempWeldingSummary: any[] =  super.formatWeldingSummary(weldingData, customData);
    tempWeldingSummary.unshift( { name : "Derrière une plaque", value: ((customData.properties.behindPlate) ? "Oui" : "Non"), suffix: "" });
    return tempWeldingSummary;
  }

}

