import { Component, OnInit, Inject, OnDestroy } from "@angular/core";
import { ModalService, DATA } from "../../services/modal.service";
import { SnackService } from "app/presentationnal/organisms/snack-bar/services/snack.service";
import { FormBuilder, FormArray } from "@angular/forms";
import { ModalContentForm } from "../../model/modal-content-form";
import { SuppliersQueriesService } from "app/facade/queries/supplier/suppliers-queries.service";
import { Subscription } from "rxjs";
import { ISupplier, ISupplyCategory } from "app/facade/interfaces/supplier";
import { CategoriesQueriesService } from "app/facade/queries/category/categories-queries.service";
import { ITableSelection, IFiltrerDatas } from "app/facade/interfaces/table-datas-definition";


interface ISupplierSelectionTemp extends ITableSelection {
  tableDatas: {
    id: number;
    code: string;
    name: string;
    categories: string;
    allCategoriesIds: number[];
  }[];
}

@Component({
  selector: "app-modal-add-supplier-offer",
  templateUrl: "./modal-add-supplier-offer.component.html",
  styleUrls: ["./modal-add-supplier-offer.component.css"]
})
export class ModalAddSupplierOfferComponent extends ModalContentForm implements OnInit, OnDestroy {
  private _supplierSub: Subscription;
  private _supplyCategoriesSub: Subscription;
  private _subSupplyCategoriesFormSub: Subscription;

  private _supplierIdAlreadySet: number[] = [];
  private _suppliersData: ISupplier[] = [];
  private _supplyCategories: ISupplyCategory[] = [];

  public parentSupplyCategoriesFormArray: FormArray = new FormArray([]);
  public subSupplyCategoriesFormArray: FormArray = new FormArray([]);
  public printFilter: boolean = true;
  public search: string = "";

  public supplierListDatas: ISupplierSelectionTemp;
  public filtredDataForList: IFiltrerDatas;

  constructor( protected _modalService: ModalService,
    protected _snackBar: SnackService,
    protected _fb: FormBuilder,
    @Inject(DATA) private _data,
    private _suppliersQueriesSrv: SuppliersQueriesService,
    private _categoriesQueriesSrv: CategoriesQueriesService) {
      super(_modalService, _fb, _snackBar);
      this._supplierIdAlreadySet = this._data.data;
  }

  ngOnInit() {
    this.getSuppliers();
    this.getSupplyCategories();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this._supplierSub.unsubscribe();
    this._supplyCategoriesSub.unsubscribe();
  }

  private getSuppliers() {
    this._supplierSub = this._suppliersQueriesSrv.getSupplierList().subscribe( result => {
      const data: any = result.data;
      if ( data && data.suppliers) {
        this._suppliersData = data.suppliers;
        this.initSupplierListForm();
      }
    }, error => {
      this._snackBar.openSnackBarError("Erreur de récupération des fournisseurs", "La liste des fournisseurs n'a pas pu être récupéré", error);
    });
  }

  private initSupplierListForm() {
    this.supplierListDatas = {
      tableHeaders: ["", "Code", "Name", "Catégories"],
      checkboxDatas: [],
      tableDatas: [],
      tableDatasDefinition: [
        { key: "code", isTitle: true},
        { key: "name", isTitle: true},
        { key: "categories", isTitle: false}
      ]
    };
    this._suppliersData.forEach( supplier => {
      this.supplierListDatas.tableDatas.push({
        id: supplier.id,
        code: supplier.code,
        name: supplier.name,
        categories: supplier.parentSupplyCategories.map(cat => cat.name).join(" - "),
        allCategoriesIds: supplier.allSupplyCategories.map(cat => cat.id)
      });
      this.supplierListDatas.checkboxDatas.push({
        key: "selected",
        formValue: this._fb.group({
          supplierId: supplier.id,
          selected: this._supplierIdAlreadySet.indexOf(supplier.id) !== -1,
          disable: this._supplierIdAlreadySet.indexOf(supplier.id) !== -1,
        })
      });
    });
    this.filtredDataForList = {
      tableDatas : this.supplierListDatas.tableDatas,
      checkboxDatas : this.supplierListDatas.checkboxDatas,
    };
  }

  private getSupplyCategories() {
    this._supplyCategoriesSub = this._categoriesQueriesSrv.getLightSupplyCategories().subscribe( result => {
      const data: any = result.data;
      if ( data && data.supplyCategories) {
        this._supplyCategories = data.supplyCategories;
        this.parentSupplyCategoriesFormArray = this.initSupplyCategorieForm(
          this._supplyCategories.filter( cat => cat.parentSupplyCategoryId === null));
        this.subscribeActionForParentSupplyCategorieForm();
      }
    }, error => {
      this._snackBar.openSnackBarError("Erreur de récupération des catégories", "La liste des catégories n'a pas pu être récupéré", error);
    });
  }

  private initSupplyCategorieForm(categories: ISupplyCategory[]): FormArray {
    const formCategories: FormArray = this._fb.array(categories.map(cat => {
      return this._fb.group({
        label: cat.name,
        id: cat.id,
        selected: false
      });
    }));
    return formCategories;
  }

  private subscribeActionForParentSupplyCategorieForm() {
    this.parentSupplyCategoriesFormArray.controls.forEach( control => {
      control.valueChanges.subscribe( newValue => {
        if (newValue.selected) {
          this.parentSupplyCategoriesFormArray.controls.forEach( form => {
            if (form.get("id").value !== newValue.id && form.get("selected").value) {
              form.get("selected").setValue(false);
            }
          });
          this.initSubSupplyCategorieForm(newValue.id);
        } else if ( this.parentSupplyCategoriesFormArray.controls.every( form => !form.get("selected").value)) {
          this.initSubSupplyCategorieForm();
        }
      });
    });
  }

  private initSubSupplyCategorieForm(parentId?: number) {
    this.subSupplyCategoriesFormArray = this.initSupplyCategorieForm(
      this._supplyCategories.filter( cat => cat.parentSupplyCategoryId === parentId));
    if ( this._subSupplyCategoriesFormSub ) { this._subSupplyCategoriesFormSub.unsubscribe(); }
    this._subSupplyCategoriesFormSub = this.subSupplyCategoriesFormArray.valueChanges.subscribe( values => {
      this.supplierPrintedFilter();
    });
    setTimeout( () => {
      this.supplierPrintedFilter();
    }, 0);
  }

  public displayFilterCategory() {
    this.printFilter = !this.printFilter;
  }

  public filterSupplier(search: string) {
    this.search = search;
    this.supplierPrintedFilter();
  }

  private supplierPrintedFilter() {
    let catIdsSelected: number[] = this.parentSupplyCategoriesFormArray.value.filter( cat => cat.selected).map( cat => cat.id);
    catIdsSelected = catIdsSelected.concat(this.subSupplyCategoriesFormArray.value.filter( cat => cat.selected).map( cat => cat.id));

    const checkboxSelected: number[] = this.supplierListDatas.checkboxDatas
      .filter( cBData => cBData.formValue.value.selected).map( cBData => cBData.formValue.value.supplierId);
    this.filtredDataForList.tableDatas = this.supplierListDatas.tableDatas.filter( supplierData => {
      return ["code", "name"]
        .some(key => supplierData[key] ? supplierData[key].toLowerCase().search(this.search.toLowerCase()) !== -1 : false)
        && catIdsSelected.every( catSelected => supplierData.allCategoriesIds.some( catId => catId === catSelected));
    });
    const selectedSupplierIds: number[] = this.filtredDataForList.tableDatas.map( supplierData => supplierData.id);
    this.filtredDataForList.checkboxDatas = this.supplierListDatas.checkboxDatas.filter( cBData => {
      return selectedSupplierIds.indexOf(cBData.formValue.value.supplierId) !== -1;
    });
  }

  public confirmModal() {
    this.save();
    this._modalService.closeModal();
  }

  protected save(): void {
    const newSupplierIds: number[] = this.supplierListDatas.checkboxDatas
      .filter( data => data.formValue.value.selected && !data.formValue.value.disable)
      .map( data => data.formValue.value.supplierId);
    const objData = {
      confirm: true,
      data: newSupplierIds
    };
    this.afterClosed.next(objData);
  }
}
