import { Injectable } from "@angular/core";
import { Apollo } from "apollo-angular";
import { ADD_SCAN_PDF, DELETE_SCAN_PDF, EDIT_SCAN_PDF, GET_ONE_SCAN_PDF, GET_SCAN_PDF } from "./scan-pdf.queries";


@Injectable({
  providedIn: "root"
})
export class ScanPdfQueriesService {

constructor(private _apollo: Apollo) { }

  public addScanPdf(data) {
    return this._apollo.mutate({
        mutation: ADD_SCAN_PDF,
        variables : {
            data: data,
        }
    });
  }

  public editScanPdf(data, scanPdfId: number) {
    return this._apollo.mutate({
        mutation: EDIT_SCAN_PDF,
        variables : {
            id: scanPdfId,
            data: data,
        }
    });
  }

  public getScanPdf(purchaseOrderId: number) {
    return this._apollo.query({
        query: GET_SCAN_PDF,
        variables : {
          purchaseOrderId: purchaseOrderId
        },
        fetchPolicy: "no-cache"
    });
  }

  public deleteScanPdf(id: number) {
    return this._apollo.mutate({
      mutation: DELETE_SCAN_PDF,
      variables: {id},
      fetchPolicy: "no-cache",
    });
  }

  public getOneScanPdf(id: number) {
    return this._apollo.query({
        query: GET_ONE_SCAN_PDF,
        variables : {id},
        fetchPolicy: "no-cache"
    });
  }
}
