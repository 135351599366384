import { EnvironmentMode } from "@lib/environments.enum";
import { ENV } from "./environment.constant";

export class ApplicationConfig {

    private constructor() {}
    public static readonly AppName: string = "Devis";

    public static get EndPointURI(): string {
        switch (ENV) {
            case EnvironmentMode.Development:
                return "//51.254.182.37:4040/graphql";
            case EnvironmentMode.LocalDevelopment:
                return "//localhost:4000/graphql";
            case EnvironmentMode.Stage:
                return "//walcarius-staging-ws.dev-local.com/graphql";
            case EnvironmentMode.Production:
                return "//ws.administration.walcarius.be/graphql";
            default:
                throw new Error("This environment is not defined");
        }
    }

    public static get Url(): string {
        switch (ENV) {
            case EnvironmentMode.Development:
                return "//51.254.182.37:4040";
            case EnvironmentMode.LocalDevelopment:
                return "//localhost:4000";
            case EnvironmentMode.Stage:
                return "//walcarius-staging-ws.dev-local.com";
            case EnvironmentMode.Production:
                return "//ws.administration.walcarius.be";
            default:
                throw new Error("This environment is not defined");
        }
    }

    public static getDomain(): string {
        switch (ENV) {
            case EnvironmentMode.Development:
                return "//localhost:4200";
            case EnvironmentMode.LocalDevelopment:
                return "//localhost:4200";
            case EnvironmentMode.Stage:
                return "//walcarius-staging.dev-local.com";
            case EnvironmentMode.Production:
                return "//administration.walcarius.be";
            default:
                throw new Error("This environment is not defined");
        }
    }
}
