import { Injectable } from "@angular/core";
import { Apollo } from "apollo-angular";
import { LIST_CATEGORIES_WITH_CHILDREN, GET_ELEMENT_GROUP_BY_ID_FOR_TABLE, UPDATE_ELEMENT, DELETE_ELEMENT, ADD_ELEMENT, GET_CATEGORY_BY_ID, LIST_CATEGORIES, LIST_ELEM_GRP, GET_ELEMENT_GROUP_BY_ID, SEARCH_ELEMENT, GET_ELEMENT_GROUP_BY_ID_WITH_MATTERS, GET_MATTER,  } from "../catalog.queries";
import { ADD_ELEMENT_EXCEL } from "app/container/biomes/element-excel-upload/element-excel-upload-queries";

@Injectable()
export class CatalogQueriesService {

    constructor(private _apollo: Apollo) { }

    /**
     * @description List all the categories
     * @author Kevin Palade
     * @returns
     * @memberof CatalogService
     */
    public listCategoriesWithChildren() {
        return this._apollo.query({
            query: LIST_CATEGORIES_WITH_CHILDREN,
            fetchPolicy: "network-only"
        });
    }

    public listCategoriesWith() {
        return this._apollo.query({
            query: LIST_CATEGORIES,
            fetchPolicy: "network-only"
        });
    }

    /**
     * @description Get an elementGroup by id
     * @author Kevin Palade
     * @param {string} id
     * @returns
     * @memberof CatalogQueriesService
     */
    public getElementGroupByIdForTable(id: string) {
        return this._apollo.watchQuery({
            query: GET_ELEMENT_GROUP_BY_ID_FOR_TABLE,
            variables: {
                elementGroupId: id,
            },
            fetchPolicy: "network-only"
        });
    }

    public getElementGroupById(id: number) {
        return this._apollo.query({
            query: GET_ELEMENT_GROUP_BY_ID,
            variables: {
                elementGroupId: id
            }
        });
    }

    /**
     * @description Get a category by id
     * @author Kevin Palade
     * @param {number} id
     * @returns
     * @memberof CatalogQueriesService
     */
    public getCategoryById(id: number) {
        return this._apollo.query({
            query: GET_CATEGORY_BY_ID,
            variables: {
                categoryId: id,
            },
            fetchPolicy: "network-only"
        });
    }

    public updateElement(elementId: number, element: any, elementGroupId: number) {
        return this._apollo.mutate({
            mutation: UPDATE_ELEMENT,
            variables: { id: elementId, element: element },
            refetchQueries: [{
                query: GET_ELEMENT_GROUP_BY_ID_FOR_TABLE,
                variables: {
                    elementGroupId: elementGroupId,
                }
            }],
        });
    }

    public addElement(element: any, elementGroupId: number) {
        return this._apollo.mutate({
            mutation: ADD_ELEMENT,
            variables: { element: element },
            refetchQueries: [{
                query: GET_ELEMENT_GROUP_BY_ID_FOR_TABLE,
                variables: {
                    elementGroupId: elementGroupId,
                }
            }],
        });
    }

    public deleteElement(elementId: number, elementGroupId: number) {
        return this._apollo.mutate({
            mutation: DELETE_ELEMENT,
            variables: { id: elementId },
            refetchQueries: [{
                query: GET_ELEMENT_GROUP_BY_ID_FOR_TABLE,
                variables: {
                    elementGroupId,
                }
            }]
        });
    }

    public listElementGroup(categoryId) {
        return this._apollo.query({
            query: LIST_ELEM_GRP,
            variables: {
                id: categoryId
            }
        });
    }

    public searchElement(search: string, elementGroupId: number, matterId: number) {
        return this._apollo.query({
            query: SEARCH_ELEMENT,
            variables: {
              search,
              elementGroupId,
              matterId
            }
        });
    }

    public getElementGroupByIdWithMatters(elementGroupId) {
        return this._apollo.query({
            query: GET_ELEMENT_GROUP_BY_ID_WITH_MATTERS,
            variables: {
                elementGroupId: elementGroupId ? elementGroupId : "",
            }
        });
    }
    public getMatter(forCustom: boolean) {
        return this._apollo.query({
            query: GET_MATTER,
            variables: {
                forCustom: forCustom
            },
            fetchPolicy: "network-only"
        });
    }
}