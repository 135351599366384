import { Component, OnInit, Inject } from "@angular/core";
import { StepFormService, CONTAINER_DATA, ActualData } from "../../../service/step-form.service";
import { FormGroup, FormBuilder } from "@angular/forms";
import { CatalogQueriesService } from "app/facade/queries/catalog/async/catalog_queries.service";
import { SnackService } from "app/presentationnal/organisms/snack-bar/services/snack.service";
import { Matter } from "app/facade/interfaces/matter";

@Component({
  selector: "app-choice-element-group-modal",
  templateUrl: "./choice-element-group-modal.component.html",
  styleUrls: ["./choice-element-group-modal.component.css"]
})
export class ChoiceElementGroupModalComponent implements OnInit {

  public elementGroups;
  public idCategory;
  public availableMatters;
  public typeElement;
  public choiceElementForm: FormGroup;
  public indexChoose: number;
  public dataMatter: string;
  public isDisable = true;
  private _actualData: ActualData;

  constructor(@Inject(CONTAINER_DATA) data: any,
    private _snackbar: SnackService,
    private _stepFormSrv: StepFormService,
    private _catalogQueriesSrv: CatalogQueriesService,
    private _fb: FormBuilder) {
    this.typeElement = data.useClass;
    this.idCategory = data.id;
    this._actualData = this._stepFormSrv.get();
  }

  ngOnInit() {
    this.initForm();
    if (this.idCategory) {
      this.getElementGroup(this.idCategory);
    }
  }

  private initForm() {
    this.choiceElementForm = this._fb.group({
      elementGroupIdChoose: null,
      matterChoose: null,
    });

    this.choiceElementForm.get("elementGroupIdChoose").valueChanges.subscribe(result => {
      this.dataMatter = null;
      this.choiceElementForm.get("matterChoose").patchValue(null);
      this.isDisable = true;
    });
  }

  public next() {
    if (this.choiceElementForm.valid) {
      this.stepChange();
      this._stepFormSrv.navigate$.next(true);
    }
  }
  public stepChange() {
    const elementGrp = this.choiceElementForm.get("elementGroupIdChoose").value;
    this._stepFormSrv.change({
      elementGroup: elementGrp,
      matter: this.choiceElementForm.get("matterChoose").value,
      useClass: elementGrp.useClass
    });
  }


  public cancel() {
    if (!this._actualData.isEdited && this._actualData.index !== undefined) {
      this._stepFormSrv.deleteElement(this._actualData.index);
    }
    this._stepFormSrv.closeModal$.next(true);
  }

  public getElementGroup(id: number) {
    if (id) {
      this._catalogQueriesSrv.listElementGroup(id).subscribe(result => {
        if (result && result.data && result.data["category"] && result.data["category"]["childrenElementGroups"]) {
          this.elementGroups = result.data["category"]["childrenElementGroups"];
          this.elementGroups = this.elementGroups.map( cat => {
            const imgPath: string = `../../../../assets/img/catalog/${cat.icon}_mini.jpg`;
            return {
              ...cat,
              imgPath
            };
          });
          let previousSelectedElementName: string,
            previousMatterName: string;

          if (this._actualData.elementGroup) {
            previousSelectedElementName = this._actualData.elementGroup.useClass;
            previousMatterName = this._actualData.matter.name;
          } else if (this._actualData.element) {
            previousSelectedElementName = this._actualData.element.constructor.name;
            previousMatterName = this._actualData.element.properties.matter.name;
          }

          if (previousSelectedElementName) {
            const getElement: any = this.elementGroups.find(element => {
              return element.useClass === previousSelectedElementName;
            });
            this.elementGroupChoose(getElement, previousMatterName);
          }
        }
      });
    }
  }

  public elementGroupChoose(indexCard: any, previousMatter?: string) {
    if (indexCard.id) {
      this.indexChoose = indexCard.id;
      this.choiceElementForm.get("elementGroupIdChoose").patchValue(indexCard);
      this.getMatterByElementGroupId(this.indexChoose, previousMatter);
    }
  }

  public matterChoose(matter: Matter) {
    this.dataMatter = matter.name;
    this.choiceElementForm.get("matterChoose").patchValue(matter);
    this.isDisable = false;
  }

  public getMatterByElementGroupId(id, previousMatter?: string) {
    if (id) {
      this._catalogQueriesSrv.getElementGroupByIdWithMatters(id).subscribe(result => {
        this.availableMatters = result.data["getElementGroupById"].availableMatters;
        if (previousMatter) {
          const getMatter: any = this.availableMatters.find(matter => {
            return matter.name === previousMatter;
          });
          this.matterChoose(getMatter);
        }
        if (!previousMatter && (this.typeElement == "Poutrelle" || this.typeElement == "Tube")) {
          const presetMatter: any = this.availableMatters.find(matter => {
            return matter.name === "ST37";
          });
          this.matterChoose(presetMatter);
        }
      });
    }
  }
}
