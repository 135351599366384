import { Option, HOURS_PER_DAY } from "./option.base";
import { ITicketElement } from "app/presentationnal/organisms";

export interface MontageProperty {
    personQuantity?: number;
    dayQuantity?: number;
    hourlyRate?: number;
    weightCost?: number;
    totalWeight?: number;
    isComputedByWeight: boolean;
}

export class Montage extends Option<MontageProperty> {

    public constructor(values: MontageProperty,
      protected ticket: ITicketElement[]) {
        super(values, ticket);
    }

    public get PersonQuantity(): number {
        return this.values.personQuantity;
    }

    public set PersonQuantity(value: number) {
        this.values.personQuantity = value;
    }

    public get DayQuantity(): number {
        return this.values.dayQuantity;
    }

    public set DayQuantity(value: number) {
        this.values.dayQuantity = value;
    }

    public get HourlyRate(): number {
        return this.values.hourlyRate;
    }

    public set HourlyRate(value: number) {
        this.values.hourlyRate = value;
    }

    public get WeightCost(): number {
        return this.values.weightCost;
    }

    public set WeightCost(value: number) {
        this.values.weightCost = value;
    }

    public get IsComputedByWeight(): boolean {
        return this.values.isComputedByWeight;
    }

    public set IsComputedByWeight(value: boolean) {
        this.values.isComputedByWeight = value;
    }

    public get CalculatedPrice(): number {
        return this.IsComputedByWeight ? this.getPricePerWeight() : this.getPricePerPerson();
    }

    private getPricePerPerson(): number {
        return this.PersonQuantity * (this.DayQuantity * HOURS_PER_DAY) * this.HourlyRate;
    }

    private getPricePerWeight(): number {
        return (this.quoteTotalWeight || 1) * this.WeightCost;
    }
}