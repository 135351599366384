import { Component, OnInit, Inject } from "@angular/core";
import { StepMatterBase } from "../../base/step-matter-base";
import { CONTAINER_DATA, StepFormService } from "app/presentationnal/organisms/wizard-modal/service/step-form.service";
import { CatalogQueriesService } from "app/facade/queries/catalog/async/catalog_queries.service";
import { CustomElementService } from "app/facade/quote-element-definition/elements/customs/custom.service";
import { FormBuilder } from "@angular/forms";
import { QuotesQueriesService } from "app/facade/queries/quote/async/quotes_queries.service";

@Component({
  selector: "app-plaque-step-one",
  templateUrl: "./plaque-step-one.component.html",
  styleUrls: ["./plaque-step-one.component.css"]
})
export class PlaqueStepOneComponent extends StepMatterBase implements OnInit {

  constructor(@Inject(CONTAINER_DATA) data: any,
    protected _stepFormSrv: StepFormService,
    protected _catalogQueriesSrv: CatalogQueriesService,
    protected _customElementSrv: CustomElementService,
    protected _quoteQueriesSrv: QuotesQueriesService,
    protected _fb: FormBuilder) {
      super(data, _stepFormSrv, _catalogQueriesSrv, _customElementSrv, _quoteQueriesSrv, _fb);
  }

  public chooseElement(choice: any, init: boolean = false) {
    super.chooseElement(choice, init);
    if (!init) {
      this.stepMatterForm.get("height").patchValue(choice.InnerHeight);
      this.stepMatterForm.get("height").markAsTouched();
    }
  }

}
