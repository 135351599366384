import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
import { ENV } from "app/environment.constant";
import { EnvironmentMode } from "@lib/environments.enum";
import { AppModule } from "app/app.module";

if (ENV === EnvironmentMode.Production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.log(err));
