import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { IActionTableHeader } from "app/facade/interfaces/tables.interface";

@Component({
  selector: "app-table-head",
  templateUrl: "./table-head.component.html",
  styleUrls: ["./table-head.component.css"]
})
export class TableHeadComponent implements OnInit {

  @Input() tableHeadClass: string = "table-head";
  @Input() arrayNameTable: string[];
  @Input() actionsList: IActionTableHeader[] = [];
  @Input() noSortHeaders: string[] = [];
  @Output() sendAction: EventEmitter<IActionTableHeader> = new EventEmitter<IActionTableHeader>();
  @Output() sendSortInfo: EventEmitter<any> = new EventEmitter<any>();

  public stateSort: boolean = false;
  constructor() { }

  ngOnInit() {
  }

  public checkActionsList(columnName: string) {
    const columnAction: IActionTableHeader  = this.actionsList.find( list => list.name === columnName);
    if ( columnAction ) {
      this.sendAction.emit(columnAction);
    } else {
      this.stateSort = !this.stateSort;
      this.sendSortInfo.emit({name: columnName, state: this.stateSort});
    }
  }

  public checkIsClickableHeader(columnName: string) {
    return this.actionsList.some( list => list.name === columnName);
  }

  public checkisNoSortHeader(columnName: string) {
    return this.noSortHeaders.some(column => column === columnName);
  }
}
