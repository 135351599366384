import { Component, OnInit, Input, EventEmitter, Output, LOCALE_ID, Inject } from "@angular/core";
import { TableDatasDefinition, TableInputsDefinition } from "app/facade/interfaces/table-datas-definition";
import { TABLE_ITEM_ACTION_TYPE } from "../table-item/enum/table-item-action-type.enum";
import { formatNumber } from "@angular/common";
import { PurchaseListStatus } from "app/facade/enums/purchase-list-status.enum";
import { IStatusConfig } from "app/facade/interfaces/status-config.interface";
import { PermissionService } from "app/facade/services/permission.service";
import { AuthService } from "@lib/auth/auth.service";
import { USER_PERMISSIONS } from "app/facade/constants/user.const";
import { AdmissionQueriesService } from "app/facade/queries/admission/admission.queries";
import { Router } from "@angular/router";
import { ScaleBlockConfig } from "app/facade/period-config";
import { ScanPdfQueriesService } from "app/facade/queries/scan-pdf/scan-pdf.queries.service";

@Component({
  selector: "app-list-table-item",
  templateUrl: "./list-table-item.component.html",
  styleUrls: ["./list-table-item.component.css"]
})
export class ListTableItemComponent   implements OnInit{
  @Input() contentItem: any[] = [];
  //check category
  @Input() categorie: string;
  @Input() public datasDefinition: TableDatasDefinition[] = [];
  @Input() public displayActions: boolean = true;
  @Input() public inputDefinition: TableInputsDefinition;
  @Input() public tableRowClass: string = "table-row";
  //check quantity is zero listing purchase order
  @Input() public disabled: boolean;
  @Input() public hidden: string;
  @Output() public buttonClicked: EventEmitter<{ itemId: number | string, actionType: number, value?: any }> = new EventEmitter();

  public tableItemActionType = TABLE_ITEM_ACTION_TYPE;
  public PURCHASE_LIST_STATUS_ENUM = PurchaseListStatus;

  //----------Permission params-----
  isEdit = false;
  isDelete = false;
  public userGroup : any;
  //--------------------------------

  //-------admission Log params--------
  public admission: any;
  private _remainingQuantity: number;
  public isRemainingQuantity : boolean;
  public quantityAdmission = 0;
  //check can add admission (isZero = false)
  public isZero: boolean;
  //-----------------------------------

  //-------purchaseOrder params--------
  public purchaseOrder: any;
  //check purchaseOrder scanPdf exist
  public isScanpdf: boolean;

  constructor( @Inject(LOCALE_ID) private _locale: string,
  private _permissionService: PermissionService,
  private _admissionQueriesSrv: AdmissionQueriesService,
  private _scanPdfQueriesSrv: ScanPdfQueriesService,
  private _authSrv: AuthService,
  private _router: Router,
  ) { }

  ngOnInit(): void {
    //To load admission and check if remainning quantity value = 0
    if(this._router.url == "/elements"){
      this.admission = this.contentItem;
      this.loadAdmission(+this.admission.id,this.admission.quantity)
    }

    //to load PurchaseOrder scanPdf
    if(this._router.url == "/purchase-order"){
      this.purchaseOrder = this.contentItem;
      this.getScanPdf(+this.purchaseOrder.id)
    }

    //chech permission
    this.userGroup =  this._authSrv.isUserGroup();
    //get and update Permission
    this._permissionService.getAndUpdatePermission(this.userGroup);
    this.isEdit = this._permissionService.hasPermission(this.userGroup,this.categorie,"write") ? true : false;
    this.isDelete = this._permissionService.hasPermission(this.userGroup,this.categorie,"delete") ? true : false;
  }

  public buttonClick(itemId: number, actionType: number): void {
    this.buttonClicked.emit({
      actionType: actionType,
      itemId: itemId
    });
  }

  public getFormatedValue(key): string | number {
    const value: any = this.contentItem[key],
          definition: any = this.datasDefinition.find(el => el.key === key);
    if (definition && definition.options ) {
      const selectedOption: any = definition.options.find(opt => opt.value === value);
      return selectedOption ? selectedOption.label : "?";
    } else {
      if (value === null || isNaN(value) || value === "") {
        return value;
      } {
        return formatNumber(value, this._locale);
      }
    }
  }

  public getValue(key): string | number {
    return this.contentItem[key];
  }

  public getStatusClass(statusConfigs: IStatusConfig[], key: string): string {
    const statusValue: string | number = this.getValue(key);
    const statusConfig: IStatusConfig = statusConfigs.find( config => config.value === statusValue);
    return statusConfig ? statusConfig.color : "";
  }

  public getStatusLabel(statusConfigs: IStatusConfig[], key: string): string {
    const statusValue: string | number = this.getValue(key);
    const statusConfig: IStatusConfig = statusConfigs.find( config => config.value === statusValue);
    return statusConfig ? statusConfig.label : key;
  }

  /**
  * @description get admission in db
  * @author Marie Claudia
  * @private
  * @memberof ListTableItemComponent
  */
   private loadAdmission(idElement: number,quantity : number){
    this._admissionQueriesSrv.getAdmission(idElement).subscribe(result => {
      const resultData: any = result.data;
      if (resultData && resultData.getAdmission) {
        let data = resultData.getAdmission;
        this.quantityAdmission =0;
        for (let index = 0; index < data.length; index++) {
          const element = data[index];
          this.quantityAdmission += element.quantity;
        }
        this._remainingQuantity = quantity - this.quantityAdmission;
        if(this._remainingQuantity == 0){
          this.isZero = true;
        }
      }
    }, error => {
      console.log("error",error);

    });
  }

  /**
  * @description get scan pdf list
  * @author Marie Claudia
  * @private
  * @memberof ListTableItemComponent
  */
  public getScanPdf(purchaseOrderId: number){
  this._scanPdfQueriesSrv.getScanPdf(purchaseOrderId).subscribe(result => {
      const resultData = <any>result.data;
      if(resultData && resultData.getScanPdf){
        if(resultData.getScanPdf.length > 0){
          this.isScanpdf = true;
          this.hidden = "hidden";
        }
      }
    }, error => {
      console.log("ERROR LOADING LISTING SCANPDF", {error});
    });
  }

}
