import gql from "graphql-tag";

export const LIST_QUOTES_PROJECTS = gql`
query quoteProjects($search: String) {
    quoteProjects(search: $search){
        _id
        name
    }
}`;

export const LIST_QUOTES_PROJECTS_WITH_QUOTE = gql`
query quoteProjects($search:String,$sort:SortQuoteProject,$pagination:Pagination){
    quoteProjects(search:$search,sort:$sort,pagination:$pagination){
        _id
        name
        reference
        customer
        displayQuotes {
            _id
            name
            reference
            isEn1090
            number
        }
    }
}`;

export const DELETE_QUOTES_PROJECT = gql`mutation($_id:String!){
    deleteQuoteProject(_id:$_id)
}`;

export const ADD_QUOTES_PROJECT = gql`mutation($project:InputQuoteProject!){
    createQuoteProject(project:$project){
        name
        reference
        customer
        _id
    }
}`;

export const GET_QUOTES_PROJECT = gql`query GetQuotesProject($_id: String!){
    quoteProject(_id:$_id){
        _id
        name
        customer
        reference
    }
}`;

export const UPDATE_QUOTES_PROJECT = gql`mutation($_id:String!,$project:UpdateQuoteProject!){
    updateQuoteProject(_id:$_id,project:$project){
        _id
        name
        reference
        customer
    }
}`;