import { Poutrelle, PoutrelleProperty } from "./poutrelle";
import { MM_TO_M, calculateNewValueWithCuttinAngle } from "app/facade/quote-element-definition/base";

export interface CornerValues {
    H: number;
    B: number;
    R: number;
    A: number;
    A_L: number;
    Kg_m: number;
    T_f1: number;
    T_f2: number;
    R2: number;
}

export class EqualCorner extends Poutrelle {

    public constructor(
        name: string,
        reference: string,
        values: CornerValues,
        properties: PoutrelleProperty) {
        super(name, reference, values, properties);
    }

    public get meterForWelding(): number {
        return (this.values.H * 2
            + (this.values.H - this.values.T_f1) * 2
            + this.values.T_f1 * 2) * MM_TO_M;
    }

    public meterForWeldingWithCut(angle: number): number {
      const B_i = calculateNewValueWithCuttinAngle((this.values.H - this.values.T_f1), angle);
      return (this.values.H * 2
        + B_i * 2
        + this.values.T_f1 * 2) * MM_TO_M;
    }

    public get thickness(): number {
        return this.values.T_f1;
    }

    public get InnerHeight(): number {
        return Math.ceil(this.values.H - this.values.T_f1);
    }

    public get InnerWidth(): number {
      return Math.ceil(this.values.B - this.values.T_f1);
    }
}