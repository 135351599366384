import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { USER_PERMISSIONS } from "../constants/user.const";
import { PERMISSION_TYPES } from "../enums/permissiontypes.enum";
import { IPermission } from "../interfaces/permission.interface";
import { PermissionQueriesService } from "../queries/permission/permission.queries.service";

@Injectable({
  providedIn: "root"
})
export class PermissionService {

  private _dataTable: IPermission[] = [];

  constructor(private _permissionqueriesSrv: PermissionQueriesService) { }

  /**
  * @description Determine if the user has the permission to do something
  * @author Raphaël Michaux
  * @param {string} userGroup
  * @param {string} category
  * @param {string|null} type
  * @returns {boolean}
  * @memberof UserService
  */
  public hasPermission(userGroup: string, category: string, type?: string|null): boolean{
    // //Get session dataPermission
    const data = JSON.parse(localStorage.getItem("dataPermission"));
    // Foreach dataPermission to get one permission by category
    if (data) {
      for (let index = 0; index < data.length; index++) {
        const element = data[index];
        if (element.category === category) {
          switch (type) {
            case PERMISSION_TYPES.READ : return element.read; // if boolean we directly return the value
            case PERMISSION_TYPES.WRITE : return element.write;
            case PERMISSION_TYPES.DELETE : return element.delete;
            case PERMISSION_TYPES.SEE_PRICES : return element.seePrices;
            default: return false;
          }
        }
      }
    }
  }
  public getAndUpdatePermission(userGroup: string, reload?: boolean): any {
    // get permission by group and update local storage
    this._permissionqueriesSrv.getPermissionyUserGroup(userGroup).subscribe( result => {
      const resultData: {getPermission: IPermission[]} = <any>result.data;
      if (resultData.getPermission) {
        // init permission
        localStorage.removeItem("dataPermission");
        // async permission
        localStorage.setItem("dataPermission", JSON.stringify(resultData.getPermission));
        if (reload) {
          window.location.reload();
        }
      }
    }, error => {
      console.log("ERROR LOADING HAS PERMISSION", {error});
      return false;
    });
  }
}
