import { Component, OnInit, OnDestroy, Inject } from "@angular/core";
import { ModalContentForm } from "../../model/modal-content-form";
import { ModalService, DATA } from "../../services/modal.service";
import { SnackService } from "app/presentationnal/organisms/snack-bar/services/snack.service";
import { FormBuilder, Validators } from "@angular/forms";
import { IModalData } from "../../interfaces/modal-data.interface";
import { IBarsetItem } from "app/facade/interfaces/barset.interface";

interface ICustomAmalgamFormatModalData extends IModalData {
  data: {
    barsetItem: IBarsetItem;
  };
}

@Component({
  selector: "app-modal-set-custom-amalgam-format",
  templateUrl: "./modal-set-custom-amalgam-format.component.html",
  styleUrls: ["./modal-set-custom-amalgam-format.component.css"]
})
export class ModalSetCustomAmalgamFormatComponent  extends ModalContentForm implements OnInit, OnDestroy  {

  constructor(protected _modalService: ModalService,
              protected _snackBar: SnackService,
              protected _fb: FormBuilder,
              @Inject(DATA) private _data: ICustomAmalgamFormatModalData) {
                super(_modalService, _fb, _snackBar);
  }

  ngOnInit() {
    this.initForm();

  }

  private initForm() {
    this._formGroup = this._fb.group({
      format: [this._data ? this._data.data.barsetItem.manualSizeValue : null, Validators.min(this._data.data.barsetItem.sizeUsed)],
    });
  }

  protected save(): void {
    const objData = {
      confirm: true,
      data: this._formGroup.get("format").value
    };
    this.afterClosed.next(objData);
  }

}
