import { Injectable } from "@angular/core";
import { MULTI_TABLE_GROUP_CLASS, MULTI_TABLE_GROUP_CONFIG, NEW_MULTI_TABLE_GROUP_CLASS } from "../configs/price-request.config";
import { AmalgamService } from "./amalgam.service";
import { IPriceRequestElement, IPriceRequestAdditionnalCost, IPriceRequestElementOption } from "../interfaces/price-request.interface";
import { ISupplierOfferElement, ISupplierOfferElementUpdateData, IVariant, ISupplierOffer, ISupplierOfferAdditionnalCost, IVariantOption, ISupplierOfferElementOption } from "../interfaces/supplier-offer";
import * as moment from "moment";
import { Subject } from "rxjs";
import { FormBuilder } from "@angular/forms";
import { deepClone } from "@lib/misc/clone";
import { MultiSupplierOfferAdditionnalCostType, UniqueSupplierOfferAdditionnalCostType, EnumAdditionnalCostUnit } from "../enums/supplier-offer-additionnal-cost-type.enum";
import { ILinkedMultiTableGroupData, IMultiTableGroup, IMultiTableGroupData, IMultiTableGroupLargeFormDatas, IMultiTableGroupShortFormDatas, IMultiTableGroupPlateFormDatas } from "../interfaces/multi-table-group.interface";
import { EnumSupplyTableLinkedSize } from "../enums/supply-table-linked-size.enum";
import { ElementUnitConfig } from "../configs/price-request-unit.config";
import { PriceRequestElementOptionUnit, PriceRequestElementOptionType } from "../enums/price-request-element-option-datas.enum";
import { AMALGAMS_IDS } from "../configs/amalgams-ids.config";
import { ElementUnitCategory, EnumElementUnitSelectValue, EnumQuantityUnit, EnumQuantityUnitSelectValue } from "../enums/element-unit-category.enum";

@Injectable({
  providedIn: "root"
})
export class SupplyTableService {
  private _updatePriceRequestElementRemark: Subject<any> = new Subject<any>();

  constructor(
    private _amalgamService: AmalgamService,
    protected _fb: FormBuilder) { }


  /* METHODS FOR ELEMENT */
  /**
   * @description Format element & linked datas (actually supplierOffer) to match with multi table component
   * @author Lainez Eddy
   * @param {IPriceRequestElement[]} data
   * @param {ILinkedMultiTableGroupData[]} linkedDatas
   * @param {boolean} isAllLinkedDatas > Defined if we need to check only one element
   * @returns
   * @memberof MultiTableGroupService
   */
  public formatMultiTableData(data: IPriceRequestElement[], additionnalCostsData: IPriceRequestAdditionnalCost[], linkedDatas: ILinkedMultiTableGroupData[], isAllLinkedDatas: boolean = true, columnToPrint: number = 2 ): IMultiTableGroup {
    const multiTable: IMultiTableGroup = {
      elements: [],
      linkedData: linkedDatas,
      supplyTableLinkedSize: (columnToPrint === 2 ? EnumSupplyTableLinkedSize.two :
        (columnToPrint === 3 ? EnumSupplyTableLinkedSize.three : EnumSupplyTableLinkedSize.four)
      )
    };
    let multiTableElementIndex: number,
        usedConfig: { classToCheck: string, tableDataToUse: string },
        isLargeTableElement: boolean,
        categoryId: number;

    /* Sort Data */
    const sortedData = data;
    // const sortedData = data.sort( (prev, curr) => {
    //   const prevName: string = prev.amalgamGroup ? prev.amalgamGroup.reference : prev.supplyListElement.denomination,
    //         currName: string = curr.amalgamGroup ? curr.amalgamGroup.reference : curr.supplyListElement.denomination;
    //   return prevName > currName ? 1 : -1;
    // });
    sortedData.forEach(element => {
      categoryId = element.parentSupplyCategory ? element.parentSupplyCategory.id : null;
      usedConfig = this.chooseTableConfig(element);
      isLargeTableElement = element.amalgamGroup !== null;

      multiTableElementIndex = multiTable.elements.findIndex( tableData => tableData.categoryId === categoryId && tableData.tableClass === usedConfig.classToCheck);
      this.setMultiTableDatas(multiTable.elements, element, multiTableElementIndex, usedConfig.tableDataToUse, isLargeTableElement, (isAllLinkedDatas ? null : linkedDatas[0].id), isAllLinkedDatas, columnToPrint);
      linkedDatas.forEach( link => {
        this.setMultiTableDatas(link.elements, element, multiTableElementIndex, "linked", isLargeTableElement, link.id, isAllLinkedDatas, columnToPrint);
      });
    });

    // Set Additionnal cost table
    this.formatMultiTableAdditionnalCostData(additionnalCostsData, linkedDatas, multiTable, columnToPrint);
    // Set elements keys
    let elementKey: number = 1,
        addionalOptionKey: number = 1;
    multiTable.elements.forEach(element => {
      element.datas.forEach( dataElement => {
        if ( element.newTableClass !== NEW_MULTI_TABLE_GROUP_CLASS.ADDITIONNAL_COST) {
          if (!dataElement.isAddedLine) {
            dataElement.keyLigne = `#${elementKey++}`;
          }
        } else {
          dataElement.keyLigne = `#${addionalOptionKey++}`;
        }
      });
    });

    multiTable.linkedData.forEach( linked => {
      // Add fct to select all element for a supplier offer
      if (linked.elements[0]) {
        linked.elements[0].headerAction = {
          columnIndex: 3,
          action: this.updateSelectLinkedDataValue,
          selected: false
        };
      }
      // Add listener on selectedQuantity
      linked.elements.forEach( element => {
        element.datas.forEach( dataElement => {
          if (dataElement.selectedQuantity) {
            dataElement.selectedQuantity.formGroup.get("selectedQuantity").valueChanges.subscribe( result => {
              if (+result < 0) {
                dataElement.selectedQuantity.formGroup.get("selectedQuantity")
                  .patchValue((+result * -1), {emitEvent: false, onlySelf: true});
              }
              this.calculateTotalElementPrice(linked);
            });
          }
        });
      });
      if (columnToPrint !== 4) {
        linked.totalSelectedElementPrice = null;
      }
    });
    return multiTable;
  }

  /**
   * @description set multi table data & check which class to use, which data config to use & set the order of tables
   * @author Lainez Eddy
   * @private
   * @param {IMultiTableGroupData[]} multiTable It's the existing data we need to fill
   * @param {IPriceRequestElement} element It's the principal element datas
   * @param {number} index Allow to know if we can get an existing multiTable or if we need to create a new
   * @param {string} tableDataToUse The type of setter we need to use (it's a config)
   * @param {boolean} isLargeTableElement Define if we need to push or unshift new table
   * @param {any} linkedDataSet Data for linked table
   * @memberof MultiTableGroupService
   */
  private setMultiTableDatas(multiTable: IMultiTableGroupData[], element: IPriceRequestElement,  index: number, tableDataToUse: string, isLargeTableElement: boolean, linkedId: number, isAllLinkedDatas: boolean, columnToPrint?: number) {
    const createTableDatas = index === -1;
    let elementTemp: IMultiTableGroupData;
    if (createTableDatas) {
      elementTemp = deepClone(MULTI_TABLE_GROUP_CONFIG[tableDataToUse]);
      elementTemp.categoryId = element.parentSupplyCategory ? element.parentSupplyCategory.id : null;
    } else {
      elementTemp = multiTable[index];
    }
    const elementUnitCategory: any = Object.keys(ElementUnitConfig.categories).find( elementCat => ElementUnitConfig.categories[elementCat].includes(+elementTemp.categoryId));
    elementTemp.unit = EnumElementUnitSelectValue[element.unit] ? EnumElementUnitSelectValue[element.unit] : ( !!elementUnitCategory ? ElementUnitConfig.units[elementUnitCategory] : ElementUnitConfig.units.default);
    switch (tableDataToUse) {
      case "large":
        elementTemp.datas.push(this.setLargeTableData(element));
        break;
      case "plate":
        elementTemp.datas.push(this.setPlateTableData(element));
        break;
      case "short":
        elementTemp.datas.push(this.setShortTableData(element));
        break;
      case "linked":
        if ( createTableDatas ) {
          if (isAllLinkedDatas) {
            elementTemp.config.find( config => config.key === "isSelected").clickFct = this.selectLinkedData;
            // elementTemp.config.find( config => config.key === "selectedQuantity").focusOutFct = this.setValueQuantity;
          }
          this.adaptLinkedData(elementTemp, columnToPrint);
        }
        // ADD Linked Element Data for supplier
        let tempDataToAdd: any[] = [{
          ...this.setLinkedTableDatas(element, linkedId, isAllLinkedDatas, elementTemp)
        }];
        // ADD Linked Element Options Datas for supplier
        if (element.options) {
          element.options.forEach( option => {
            tempDataToAdd.push(this.setLinkedTableDatasOptions(option, linkedId, isAllLinkedDatas));
          });
        }
        // ADD Linked Variant Element & Variant Element Option Datas for supplier
        tempDataToAdd = tempDataToAdd.concat(this.checkVariantLineResponseToAdd(element.supplierOfferElements, linkedId, isAllLinkedDatas, elementTemp.unit));
        tempDataToAdd[0].isFirstGroupData = true;
        elementTemp.datas = elementTemp.datas.concat(tempDataToAdd);
        break;
      default:
        break;
    }

    if (tableDataToUse !== "linked") {
      if ( createTableDatas && isAllLinkedDatas && columnToPrint !== 4 ) {
        elementTemp.config.find( config => config.key === "remark").clickFct = this.updateElementRemark;
      }
      elementTemp.datas = elementTemp.datas.concat(this.checkVariantLineToAdd(element.supplierOfferElements, tableDataToUse, linkedId));
    }

    if (createTableDatas) {
      if (isLargeTableElement) {
        // Put TUBES in the second position of the multi table
        if (+elementTemp.categoryId === +AMALGAMS_IDS.TUBES_ID) {
          const findBeamTableIndex: number = multiTable.findIndex( table => +table.categoryId === +AMALGAMS_IDS.BEAMS_ID);
          if (findBeamTableIndex === -1) {
            multiTable.unshift({...elementTemp});
          } else {
            multiTable.splice((findBeamTableIndex + 1), 0, {...elementTemp});
          }
        } else {
          multiTable.unshift({...elementTemp});
        }
      } else {
        multiTable.push({...elementTemp});
      }
    }
  }

  /**
   * @description Returns the configuration to use depending on the PriceRequestElement type
   * @author Quentin Wolfs
   * @private
   * @param {IPriceRequestElement} element
   * @returns {{ classToCheck: string, tableDataToUse: string }}
   * @memberof MultiTableGroupService
   */
  private chooseTableConfig(element: IPriceRequestElement): { classToCheck: string, tableDataToUse: string } {
    if (!!element.amalgamGroup) {
      return {
        classToCheck: MULTI_TABLE_GROUP_CLASS.LARGE,
        tableDataToUse: "large"
      };
    } else {
      if (ElementUnitConfig.categories[ElementUnitCategory.PLATES].includes(+element.supplyListElement.supplyCategoryId)) { // If element is a plate
        return {
          classToCheck: MULTI_TABLE_GROUP_CLASS.PLATE,
          tableDataToUse: "plate"
        };
      } else {
        return {
          classToCheck: MULTI_TABLE_GROUP_CLASS.SHORT,
          tableDataToUse: "short"
        };
      }
    }
  }

  /**
   * @description Adapt header size for the selected interface
   * @author Lainez Eddy
   * @private
   * @param {IMultiTableGroupData} element
   * @param {number} columnToPrint
   * @memberof SupplyTableService
   */
  private adaptLinkedData(element: IMultiTableGroupData, columnToPrint: number) {
    switch ( columnToPrint ) {
      case 2:
        element.headers.splice(2, 2);
        element.config.splice(2, 2);
        break;
      case 3: // Not used anymore, corresponds to the "selected" icon used before
        element.headers.splice(2, 1);
        element.config.splice(2, 1);
        break;
      default:
        break;
    }
  }

  /**
   * Add variant element for multitable
   * @param supplierOfferElements
   * @param type
   */
  private checkVariantLineToAdd(supplierOfferElements: ISupplierOfferElement[], type: string, linkedId: number): any[] {
    const variantToMerge: any[] = [];
    const supplierOfferElementsFiltred: ISupplierOfferElement[] = linkedId ?
      supplierOfferElements.filter( suppOffer => suppOffer.variantId !== null && suppOffer.supplierOfferId === linkedId) :
      supplierOfferElements.filter( suppOffer => suppOffer.variantId !== null);
    let variant: IVariant,
        data: any;
    supplierOfferElementsFiltred.forEach(suppOffer => {
      variant = suppOffer.variant;
      data = {
        quantity: variant.quantity,
        remark: variant.remark,
        weight: variant.weight,
        stockQuantity: 0,
        purchaseQuantity: variant.purchaseOrderQuantity ? variant.purchaseOrderQuantity : 0,
        isAddedLine: true,
        hasPrice: true,
        partiallyPurchase: (variant.purchaseOrderQuantity !== 0 && variant.purchaseOrderQuantity < variant.quantity),
        totallyPurchase: variant.purchaseOrderQuantity >= variant.quantity
      };
      if (type === "large") {
        data = {
          ...data,
          reference: variant.reference,
          matterRef: variant.matterRef,
          option: this._amalgamService.getOptionLabel(variant),
          format: variant.format,
          isCut: variant.isCut,
          isEn1090: variant.isEn1090,
        };
      } else if (type === "plate") {
        data = {
          ...data,
          matterRef: variant.matterRef,
          quantityUnit: variant.quantityUnit ? EnumQuantityUnitSelectValue[variant.quantityUnit] : null,
          thickness: variant.thickness,
          length: variant.length,
          width: variant.width
        };
      } else {
        data.denomination = variant.denomination;
        data.quantityUnit = variant.quantityUnit;
      }
      data.elementAdditionnalData = variant.options ? variant.options.map( option => {
        return {
          id: option.id,
          quantity: option.quantity,
          denomination: option.denomination,
          type: option.type,
          labelType: PriceRequestElementOptionType[option.type],
          unit: option.unit
        };
      }) : [];

      variantToMerge.push(data);
    });
    return variantToMerge;
  }

  private checkVariantLineResponseToAdd(supplierOfferElements: ISupplierOfferElement[], linkedId: number, isAllLinkedDatas: boolean, unit: string): any[] {
    const variantResponseToMerge: any[] = [];
    const supplierOfferElementsFiltred: ISupplierOfferElement[] = isAllLinkedDatas ?
    supplierOfferElements.filter( suppOffer => suppOffer.variantId !== null) :
    supplierOfferElements.filter( suppOffer => suppOffer.variantId !== null && suppOffer.supplierOfferId === linkedId);
    const bestPriceIds: number[] = [],
        bestTimeIds: number[] = [];
    let dataToReturn: any;
    supplierOfferElementsFiltred.forEach( suppOffer => {
      dataToReturn = {
        id: null,
        price: null,
        deliveryDate: null,
        selectedQuantity: null,
        isSelected: false
      };
      // Define data if it's in relation with the supplier
      if (suppOffer.supplierOfferId === linkedId) {
        // if (isAllLinkedDatas) {
        //   bestPriceIds = [suppOffer.id];
        //   bestTimeIds = [suppOffer.id];
        // }
        const remainingQuantity: number = suppOffer.variant.quantity - suppOffer.variant.purchaseOrderQuantity;
        dataToReturn = this.setLinkedDatas(
          suppOffer,
          bestPriceIds,
          bestTimeIds,
          (remainingQuantity >= 0 ? remainingQuantity : 0));
      }
      const quantity: number = suppOffer.variant.quantity ? suppOffer.variant.quantity : 1;
      variantResponseToMerge.push({
        ...dataToReturn,
        unit,
        computedPrice: dataToReturn.computedPrice ? dataToReturn.computedPrice : 0,
        unitPrice: dataToReturn.computedPrice ? dataToReturn.computedPrice / quantity : 0
      });

      suppOffer.variant.options.forEach( variantOption => {
        if (suppOffer.supplierOfferId === linkedId) {
          variantResponseToMerge.push({
            ...this.setLinkedTableVariantOptionData(variantOption),
            // bestPriceClass: isAllLinkedDatas ? "best" : "",
            unit: PriceRequestElementOptionUnit[variantOption.unit]
          });
        } else {
          variantResponseToMerge.push(this.setLinkedTableVariantOptionData());
        }
      });
    });
    return variantResponseToMerge;
  }

  private setLinkedTableVariantOptionData(variantOption?: IVariantOption) {
    const dataToReturn: any = {
      id: variantOption ? variantOption.id : null,
      price: variantOption ? variantOption.price : null,
      isFirstGroupData: false
    };
    return dataToReturn;
  }

  private updateElementRemark = (data: any) => {
    this._updatePriceRequestElementRemark.next(data);
  }

  private calculateTotalElementPrice(linkedData: ILinkedMultiTableGroupData ) {
    let allSelectedElementPrice: number[];
    let elementValue: number;
    allSelectedElementPrice = linkedData.elements.map( element => {
      return element.datas.map( dataElement => {
        elementValue = 0;
        if (dataElement.isSelected) {
          if (dataElement.isAdditionnalCost) {
            elementValue = dataElement.price;
          } else if (dataElement.selectedQuantity) {
            elementValue = dataElement.selectedQuantity.formGroup.get("selectedQuantity").value * dataElement.unitPrice;
          }
        }
        return elementValue;
      });
    }).flat();
    let selectedElementsPrice: number = 0;
    if ( allSelectedElementPrice.length !== 0 ) {
      selectedElementsPrice += allSelectedElementPrice.reduce( (prev, curr) => prev + curr);
    }
    linkedData.totalSelectedElementPrice = +selectedElementsPrice.toFixed(3);
  }

  private updateSelectLinkedDataValue = (groupData: IMultiTableGroupData, linkedData: ILinkedMultiTableGroupData) => {
    groupData.headerAction.selected = !groupData.headerAction.selected;
    linkedData.elements.forEach( element => {
      element.datas.forEach( data => {
        if (data.isSelected !== undefined && data.selectedQuantity) {
          data.isSelected = groupData.headerAction.selected;
          this.updateSelectedQuantity(data);
        }
      });
    });
    this.calculateTotalElementPrice(linkedData);
  }

  private selectLinkedData = (data: any, linkedData: ILinkedMultiTableGroupData) => {
    if (data.id) {
      data.isSelected = !data.isSelected;
      this.updateSelectedQuantity(data);
      this.calculateTotalElementPrice(linkedData);
    }
  }

  private updateSelectedQuantity(data: any) {
    // TODO: Delete oldSelectedQuantity > Useless
    const actualSelectedQuantity: number = +data.selectedQuantity.formGroup.get("selectedQuantity").value;
    if (actualSelectedQuantity >= 0 && data.isSelected) {
      data.selectedQuantity.formGroup.get("selectedQuantity").patchValue(data.remainingQuantity, {emitEvent: false, onlySelf: true});
    }
    if (!data.isSelected) {
      data.selectedQuantity.formGroup.get("selectedQuantity").patchValue(null, {emitEvent: false, onlySelf: true});
    }
  }

  private setLinkedTableDatasOptions(option: IPriceRequestElementOption | any, linkedId: number, isAllLinkedDatas: boolean) {
    const linkedDataOptionData: ISupplierOfferElementOption = option.supplierOfferElementOptions.find( opt => {
      return opt.supplierOfferElement.supplierOfferId === linkedId;
    });
    const dataToReturn: any = {
      id: linkedDataOptionData ? linkedDataOptionData.id : null,
      price: linkedDataOptionData ? linkedDataOptionData.price : null,
      isFirstGroupData: false,
      unit: PriceRequestElementOptionUnit[option.unit],
      bestPriceClass: isAllLinkedDatas ? this.checkOptionBestPrice(linkedId, option.bestPrice, option.supplierOfferElementOptions) : "",
    };
    return dataToReturn;
  }

  private checkOptionBestPrice(linkedId: number, bestPrice: number, supplierOfferElementOptions: ISupplierOfferElementOption[]): string {
    let bestPriceClass: string = "";
    const filteredBestPriceElementOption: ISupplierOfferElementOption[] = supplierOfferElementOptions.filter( suppOption => suppOption.price === bestPrice && suppOption.price !== null);
    const hasBestPrice: boolean = filteredBestPriceElementOption.some( suppOption => suppOption.supplierOfferElement.supplierOfferId === linkedId);
    if (filteredBestPriceElementOption.length > 0 && hasBestPrice) {
      bestPriceClass = filteredBestPriceElementOption.length === 1 ? "best" : "multi";
    }
    return bestPriceClass;
  }

  private setLinkedTableDatas(element: IPriceRequestElement, linkedId: number, isAllLinkedDatas: boolean,elementTemp? : any): any {
    let dataToReturn: any = {
      id: element.id,
      price: null,
      deliveryDate: null,
      selectedQuantity: null,
      isSelected: false,
      isFirstGroupData: false,
      isElement: true,
    };
    const elementUnitCategory: any = Object.keys(ElementUnitConfig.categories).find(elementCat => ElementUnitConfig.categories[elementCat].includes(+elementTemp.categoryId));
    // use on supplier detail (only one linked multi table)
    if (!isAllLinkedDatas && element.supplierOfferElements[0]) {
      const supplierOfferElement: ISupplierOfferElement = element.supplierOfferElements.find(supplierOffer => supplierOffer.variantId === null && supplierOffer.supplierOfferId === linkedId);
      if (supplierOfferElement) {
        dataToReturn = this.setLinkedDatas(supplierOfferElement);
        dataToReturn.unit = EnumElementUnitSelectValue[supplierOfferElement.unit] ? EnumElementUnitSelectValue[supplierOfferElement.unit] : (!!elementUnitCategory ? ElementUnitConfig.units[elementUnitCategory] : ElementUnitConfig.units.default);
      }
    // use on resume (multi linked multi table)
    } else if (element.supplierOfferElements) {
      const supplierOfferElement: ISupplierOfferElement = element.supplierOfferElements.find(suppOffer => suppOffer.supplierOfferId === linkedId && suppOffer.variantId === null);
      const remainingQuantity: number = (element.quantity - element.stockQuantity - element.purchaseOrderQuantity);
      if (supplierOfferElement) {
        // get supplierOfferIdsBestsPrice
        const supplierBestPrice: number[] = element.bestPrice !== null ? element.supplierOfferElements.filter( suppOffer => suppOffer.price === element.bestPrice && !suppOffer.variantId).map( suppOffer => suppOffer.id) : [];
        // get supplierOfferIdsBestsDeliveryDate
        const supplierBestDeliveryDate: number[] = element.bestTime !== null ? element.supplierOfferElements.filter( suppOffer => suppOffer.deliveryDate === element.bestTime && !suppOffer.variantId).map( suppOffer => suppOffer.id) : [];
        // get all quantity already selected to get the remaining quantity
        dataToReturn = this.setLinkedDatas(
          supplierOfferElement,
          supplierBestPrice,
          supplierBestDeliveryDate,
          (remainingQuantity >= 0 ? remainingQuantity : 0)
        );

        dataToReturn.unit = EnumElementUnitSelectValue[supplierOfferElement.unit] ? EnumElementUnitSelectValue[supplierOfferElement.unit] : ( !!elementUnitCategory ? ElementUnitConfig.units[elementUnitCategory] : ElementUnitConfig.units.default);
          // selectedQuantities.reduce( (prev, curr) => prev - curr));
      } else {
        dataToReturn = {
          ...dataToReturn,
          oldSelectedQuantity: 0,
          selectedQuantity: {
            disable: false,
            formGroup: this._fb.group({
              selectedQuantity: null
            })
          },
          isSelected: false,
          remainingQuantity: (remainingQuantity >= 0 ? remainingQuantity : 0),
        };
      }
    }
    const quantity: number = element.quantity ? element.quantity : 1;
    return {
      ...dataToReturn,
      computedPrice: dataToReturn.computedPrice ? dataToReturn.computedPrice : 0,
      unitPrice: dataToReturn.computedPrice ? dataToReturn.computedPrice / quantity : 0
    };
  }

  private setLinkedDatas(supplierOfferElement: ISupplierOfferElement, bestPriceIds: number[] = [] , bestTimeIds: number[] = [], remainingQuantity: number = 0): any {
    return {
      id: supplierOfferElement.id,
      price: supplierOfferElement.price,
      deliveryDate: supplierOfferElement.deliveryDate ? moment.unix(supplierOfferElement.deliveryDate).format("DD/MM/YYYY") : null,
      oldSelectedQuantity: supplierOfferElement.selectedQuantity,
      selectedQuantity: {
        disable: supplierOfferElement.id === undefined,
        formGroup: this._fb.group({
          selectedQuantity: null
        })
      },
      isSelected: false,
      remainingQuantity,
      bestPriceClass: bestPriceIds.length === 0 ? "" : this.checkLinkedDataClass(supplierOfferElement.id, bestPriceIds),
      bestDeliveryTimeClass: "" ,
      // bestDeliveryTimeClass: bestTimeIds.length !== 0 && supplierOfferElement.deliveryDate ? this.checkLinkedDataClass(supplierOfferElement.id, bestTimeIds) : "" ,
      computedPrice: supplierOfferElement.computedPrice
    };
  }

  private checkLinkedDataClass(supplierOfferId: number, supplierOffersIds: number[]): string {
    let returnClass: string = "";
    if ( supplierOffersIds.indexOf(supplierOfferId) !== -1) {
      returnClass = supplierOffersIds.length === 1 ? "best" : "multi";
    }
    return returnClass;
  }

  private setLargeTableData(element: IPriceRequestElement): IMultiTableGroupLargeFormDatas {
    return {
      keyLigne: `#`,
      id: element.id,
      quantity: element.quantity,
      stockQuantity: element.stockQuantity,
      purchaseQuantity: element.purchaseOrderQuantity ? element.purchaseOrderQuantity : 0,
      hasPrice: element.hasPrice,
      partiallyPurchase: (element.purchaseOrderQuantity !== 0 && element.purchaseOrderQuantity < element.quantity),
      totallyPurchase: element.purchaseOrderQuantity >= element.quantity,
      totallyInStock: element.stockQuantity >= element.quantity,
      reference: element.amalgamGroup.reference,
      matterRef: element.amalgamGroup.matterRef,
      option: this._amalgamService.getOptionLabel(element.amalgamGroup),
      format: element.amalgamGroup.format,
      isCut: element.amalgamGroup.isCut,
      isEn1090: element.amalgamGroup.isEn1090,
      remark: element.remark,
      weight: element.weight,
      // TODO: GET OPTIONS FROM POSSIBLE ELEMENT BY SUPPLIER
      elementAdditionnalData: element.options ? element.options.map( option => {
        return {
          id: option.id,
          quantity: option.quantity,
          denomination: option.denomination,
          type: option.type,
          labelType: PriceRequestElementOptionType[option.type],
          unit: option.unit
        };
      }) : []
    };
  }

  private setPlateTableData(element: IPriceRequestElement): IMultiTableGroupPlateFormDatas {
    return {
      keyLigne: `#`,
      id: element.id,
      quantity: element.quantity,
      purchaseQuantity: element.purchaseOrderQuantity ? element.purchaseOrderQuantity : 0,
      hasPrice: element.hasPrice,
      partiallyPurchase: (element.purchaseOrderQuantity !== 0 && element.purchaseOrderQuantity < element.quantity),
      totallyPurchase: element.purchaseOrderQuantity >= element.quantity,
      totallyInStock: element.stockQuantity >= element.quantity,
      matterRef: element.supplyListElement.matterRef,
      quantityUnit: EnumQuantityUnitSelectValue[element.supplyListElement.quantityUnit],
      thickness: element.supplyListElement.thickness,
      length: element.supplyListElement.length,
      width: element.supplyListElement.width,
      remark: element.remark,
      weight: element.weight,
      // TODO: GET OPTIONS FROM POSSIBLE ELEMENT BY SUPPLIER
      elementAdditionnalData: element.options ? element.options.map( option => {
        return {
          id: option.id,
          quantity: option.quantity,
          denomination: option.denomination,
          type: option.type,
          labelType: PriceRequestElementOptionType[option.type],
          unit: option.unit
        };
      }) : []
    };
  }

  private setShortTableData(element: IPriceRequestElement): IMultiTableGroupShortFormDatas {
    return {
      keyLigne: `#`,
      id: element.id,
      quantity: element.quantity,
      denomination: element.supplyListElement.denomination,
      remark: element.remark,
      weight: element.weight,
      purchaseQuantity: element.purchaseOrderQuantity ? element.purchaseOrderQuantity : 0,
      hasPrice: element.hasPrice,
      partiallyPurchase: (element.purchaseOrderQuantity !== 0 && element.purchaseOrderQuantity < element.quantity),
      totallyPurchase: element.purchaseOrderQuantity >= element.quantity,
      totallyInStock: element.stockQuantity >= element.quantity,
      quantityUnit: element.supplyListElement.quantityUnit,
      // TODO: GET OPTIONS FROM POSSIBLE ELEMENT BY SUPPLIER
      elementAdditionnalData: element.options ? element.options.map( option => {
        return {
          id: option.id,
          quantity: option.quantity,
          denomination: option.denomination,
          type: option.type,
          labelType: PriceRequestElementOptionType[option.type],
          unit: option.unit
        };
      }) : []
    };
  }

  private selectAdditionnalCostLinkedData = (data: any, linkedData: ILinkedMultiTableGroupData) => {
    if (data.id) {
      data.isSelected = !data.isSelected;
      this.calculateTotalElementPrice(linkedData);
    }
  }

  public formatMultiTableAdditionnalCostData(additionnalCosts: IPriceRequestAdditionnalCost[], linkedDatas: ILinkedMultiTableGroupData[], multiTable: IMultiTableGroup, columnToPrint: number) {
    const sortedAdditionnalCost: IPriceRequestAdditionnalCost[] = additionnalCosts.sort( (a, b) => {
      return a.denomination > b.denomination ? 1 : -1;
    });
    let configAdditionnalCost;
    const additionnalCostDatas: IMultiTableGroup = {
      elements: [deepClone(MULTI_TABLE_GROUP_CONFIG.additionnalCostElement)],
      linkedData: linkedDatas.map( link => {
        configAdditionnalCost = deepClone(MULTI_TABLE_GROUP_CONFIG.additionnalCost);
        configAdditionnalCost.config.find( config => config.key === "isSelected").clickFct = this.selectAdditionnalCostLinkedData;
        this.adaptLinkedData(configAdditionnalCost, columnToPrint);
        return {
          ...link,
          elements: [configAdditionnalCost]
        };
      })
    };
    let additionnalCost: IPriceRequestAdditionnalCost;
    let supplierResponse: ISupplierOfferAdditionnalCost;
    let addedLink: any;
    let sOAddCostIds: number[];
    Object.keys(UniqueSupplierOfferAdditionnalCostType).forEach( type => {
      additionnalCost = sortedAdditionnalCost.find( addCost => addCost.type === type);
      if (additionnalCost) {
        additionnalCostDatas.elements[0].datas.push({
          keyLigne: `#`,
          id: additionnalCost.id,
          quantity: additionnalCost.quantity,
          denomination: UniqueSupplierOfferAdditionnalCostType[type],
          type,
          unit: additionnalCost.unit,
          isAdditionnalCost: true,
          canBeUpdate: false,
        });
        sOAddCostIds = additionnalCost.bestPrice ?
          additionnalCost.supplierOfferAdditionnalCosts.filter(supResponse => additionnalCost.bestPrice === supResponse.price).map(supResponse => supResponse.id) :
          [];
        additionnalCostDatas.linkedData.forEach( link => {
          addedLink = {
            id: additionnalCost.id,
            isAdditionnalCostId: true,
            price: null,
            quantity: null,
            isSelected: false,
            isFirstGroupData: true,
            isAdditionnalCost: true
          };
          supplierResponse = additionnalCost.supplierOfferAdditionnalCosts.find( supResponse => supResponse.supplierOfferId === link.id);
          if (supplierResponse) {
            addedLink = {
              id: supplierResponse.id,
              price: supplierResponse.price,
              unit: EnumAdditionnalCostUnit[additionnalCost.unit],
              quantity: additionnalCost.quantity,
              isSelected: true,
              isFirstGroupData: true,
              isAdditionnalCost: true,
              bestPriceClass: this.checkLinkedDataClass(supplierResponse.id, sOAddCostIds)
            };
          }
          link.elements[0].datas.push(addedLink);
        });
      }
    });

    // check other additionnal cost
    sortedAdditionnalCost.filter(addCost => addCost.type === Object.keys(MultiSupplierOfferAdditionnalCostType)[0]).forEach( addCost => {
      additionnalCostDatas.elements[0].datas.push({
        keyLigne: `#`,
        id: addCost.id,
        quantity: addCost.quantity,
        denomination: addCost.denomination,
        type: addCost.type,
        unit: addCost.unit,
        isAdditionnalCost: true,
        canBeUpdate: true,
      });
      sOAddCostIds = addCost.bestPrice ?
        addCost.supplierOfferAdditionnalCosts.filter(supResponse => addCost.bestPrice === supResponse.price).map(supResponse => supResponse.id) :
        [];
      additionnalCostDatas.linkedData.forEach( link => {
        addedLink = {
          id: additionnalCost.id,
          isAdditionnalCostId: true,
          price: null,
          quantity: null,
          isSelected: false,
          isFirstGroupData: true,
          isAdditionnalCost: true
        };
        supplierResponse = addCost.supplierOfferAdditionnalCosts.find( supResponse => supResponse.supplierOfferId === link.id);
        if (supplierResponse) {
          addedLink = {
            id: supplierResponse.id,
            price: supplierResponse.price,
            unit: EnumAdditionnalCostUnit[addCost.unit],
            quantity: addCost.quantity,
            isSelected: true,
            isFirstGroupData: true,
            isAdditionnalCost: true,
            bestPriceClass: this.checkLinkedDataClass(supplierResponse.id, sOAddCostIds)
          };
        }
        link.elements[0].datas.push(addedLink);
      });
    });

    multiTable.elements = multiTable.elements.concat(additionnalCostDatas.elements);
    multiTable.linkedData.forEach( link => {
      link.elements = link.elements.concat(additionnalCostDatas.linkedData.find( addLink => addLink.id === link.id).elements);
    });
  }

  public subscribeElementUpdateRemark() {
    return this._updatePriceRequestElementRemark;
  }
}
