import { Component, OnInit, Inject, Injector, OnDestroy } from "@angular/core";
import { Portal, ComponentPortal, PortalInjector } from "@angular/cdk/portal";
import { CONTAINER_DATA, StepFormService, ActualData } from "./service/step-form.service";
import { ELEMENT_WIZARD_CONFIG, OPERATION_WIZARD_CONFIG, FINISH_WIZARD_CONFIG, OPTION_WIZARD_CONFIG, CUSTOM_CONFIGS } from "./wizard.config";
import { Subscription } from "rxjs";


interface Step {
  title: string;
  component: any;
}

@Component({
  selector: "app-wizard-modal",
  templateUrl: "./wizard-modal.component.html",
  styleUrls: ["./wizard-modal.component.css"]
})
export class WizardModalComponent implements OnInit, OnDestroy {
  public _portal: Portal<any>;
  private steps: Step[] = [];
  private _currentStep: number;
  public categories;
  public nameModal;
  public idData;
  public ticketData;
  public type;
  private _editedData: ActualData;
  private _navigateSubscription: Subscription;

  constructor(@Inject(CONTAINER_DATA) data: any,
    private _injector: Injector,
    private _stepFormSrv: StepFormService) {
    // this.categories = data.categories;
    this.nameModal = data.name;
    this.type = data.type;
    this.idData = data.categoryId;
    this.ticketData = data.ticket;
    this._editedData = data.actualData;
  }

  ngOnInit() {
    this._currentStep = 0;
    this.checkTypeModal();

    this.buildStep();
    this._navigateSubscription = this._stepFormSrv.navigate$.subscribe(next => {
      if (next) {
        this.next();
      } else {
        this.previous();
      }
    });

    if (this._editedData) {
      this._stepFormSrv.change(this._editedData);
    }
  }

  ngOnDestroy(): void {
    this._navigateSubscription.unsubscribe();
  }

  private buildStep(): void {
    const { component, title } = this.steps[this._currentStep];
    this._portal = new ComponentPortal(component, null, this.createInjector({
      id: this.idData,
      ticket: this.ticketData,
      useClass: this.nameModal
    }));
  }

  private next() {
    this._currentStep++;
    if (this._currentStep < this.steps.length) {
      this.buildStep();
    }
  }

  private previous() {
    this._currentStep--;
    if (this._currentStep >= 0 && this._currentStep < this.steps.length) {
      this.buildStep();
    }
  }

  private createInjector(dataToPass): PortalInjector {
    const injectorTokens = new WeakMap();
    injectorTokens.set(CONTAINER_DATA, dataToPass);
    return new PortalInjector(this._injector, injectorTokens);
  }

  public navigateNav(event) {
    this._currentStep = event;
    if (this._currentStep >= 0) {
      const actualData: ActualData = this._stepFormSrv.get();
      if (this.type === "Custom" && event === 0 && actualData.index !== undefined && actualData.childrenIndex !== undefined && !actualData.isEdited) {
        this._stepFormSrv.deleteElement(actualData.index, actualData.childrenIndex);
      }
      if (this.type === "Element" && event === 1 && actualData.index !== undefined && !actualData.isEdited) {
        this._stepFormSrv.deleteElement(actualData.index);
      }
      this.buildStep();
    }
  }

  public closeWizard() {
    const actualData: ActualData = this._stepFormSrv.get();
    if (actualData.index !== undefined && !this._editedData) {
      if (actualData.childrenIndex !== undefined) {
        this._stepFormSrv.deleteElement(actualData.index, actualData.childrenIndex);
      } else {
        this._stepFormSrv.deleteElement(actualData.index);
      }
    }
    this._stepFormSrv.closeModal$.next(true);
  }

  public checkTypeModal() {
    switch (this.type) {
      case "Element":
      this.steps = ELEMENT_WIZARD_CONFIG;
        break;
      case "Custom":
        const customConfig = CUSTOM_CONFIGS.find( config => config.key == this.nameModal);
        if (customConfig) {
          this.steps = customConfig.config;
          this.steps[0].title = this.nameModal;
        }
        break;
      case "Operation":
        this.steps =  OPERATION_WIZARD_CONFIG[(this.idData - 1)];
        break;
      case "Finition":
      this.steps =  FINISH_WIZARD_CONFIG[(this.idData - 1)];
        break;
      case "Option":
      this.steps =  OPTION_WIZARD_CONFIG[(this.idData - 1)];
        break;

      default:
        break;
    }
  }

}
