import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AuthService } from "@lib/auth/auth.service";
import { LoginPageComponent } from "./container/pages/login/login-page/login-page.component";
import { CatalogPagesComponent } from "./container/pages/catalog/catalog-pages/catalog-pages.component";
import { ListingQuoteComponent } from "./container/pages/quotes/listing-quote/listing-quote.component";
import { CreateQuoteComponent } from "./container/pages/quotes/create-quote/create-quote.component";
import { QuoteDetailsComponent } from "./container/pages/quotes/quote-details/quote-details.component";
import { HomepageComponent } from "./container/pages/wizards/homepage/homepage.component";
import { ListingSupplierComponent } from "./container/pages/suppliers/listing-supplier/listing-supplier.component";
import { SupplierDetailsComponent } from "./container/pages/suppliers/supplier-details/supplier-details.component";
import { ListingProjectComponent } from "./container/pages/projects/listing-project/listing-project.component";
import { ProjectDetailsComponent } from "./container/pages/projects/project-details/project-details.component";
import { ListingPriceRequestComponent } from "./container/pages/priceRequests/listing-price-request/listing-price-request.component";
import { PriceRequestDetailsComponent } from "./container/pages/priceRequests/price-request-details/price-request-details.component";
import { ListingPurchaseOrderComponent } from "./container/pages/purchaseOrder/listing-purchase-order/listing-purchase-order.component";
import { PurchaseOrderDetailsComponent } from "./container/pages/purchaseOrder/purchase-order-details/purchase-order-details.component";
import { EditedFormService } from "@lib/edited-form/edited-form.service";
import { ListingUsersComponent } from "./container/pages/users/listing-users/listing-users.component";
import { EditUserComponent } from "./container/pages/users/edit-user/edit-user.component";
import { AddUserComponent } from "./container/pages/users/add-user/add-user.component";
import { TabpermissionManagementComponent } from "./container/pages/permissionManagement/tabpermission-management/tabpermission-management.component";
import { ListingElementComponent } from "./container/pages/elementBdc/listing-element/listing-element.component";
import { PermissionGuard } from "@lib/auth/permission.guard";

const routes: Routes = [
  { path: "", redirectTo: "quote", pathMatch: "full" },
  { path: "login", component: LoginPageComponent },
  {
    path: "catalog",
    canActivate: [AuthService, PermissionGuard],
    data: { category: 'catalog' },
    component: CatalogPagesComponent,
  },
  {
    path: "quote",
    canActivate: [AuthService, PermissionGuard],
    children: [
      { path: "", component: ListingQuoteComponent },
      { path: "add", component: CreateQuoteComponent },
      { path: "edit/:id", component: CreateQuoteComponent },
      { path: "add/:projectId", component: CreateQuoteComponent },
      { path: ":id/details", component: QuoteDetailsComponent },
      { path: ":id/wizard", component: HomepageComponent }
    ],
    data: { category: "quotations" }
  },
  {
    path: "supplier",
    canActivate: [AuthService, PermissionGuard],
    children: [
      { path: "", component: ListingSupplierComponent },
      { path: "edit/:id", component: SupplierDetailsComponent },
    ],
    data: { category: 'suppliers' },
  },
  {
    path: "project",
    canActivate: [AuthService, PermissionGuard],
    children: [
      { path: "", component: ListingProjectComponent },
      { path: "edit/:id", component: ProjectDetailsComponent },
    ],
    data: { category: "projects" }
  },
  {
    path: "price-request",
    canActivate: [AuthService, PermissionGuard],
    children: [
      { path: "", component: ListingPriceRequestComponent },
      { path: "edit/:id", component: PriceRequestDetailsComponent, canDeactivate: [EditedFormService] },
    ],
    data: { category: "price-requests" }
  },
  {
    path: "purchase-order",
    canActivate: [AuthService, PermissionGuard],
    children: [
      { path: "", component: ListingPurchaseOrderComponent },
      { path: "edit/:id", component: PurchaseOrderDetailsComponent },
    ],
    data: { category: "purchase-orders" }
  },
  {
    path: "users",
    canActivate: [AuthService, PermissionGuard],
    children: [
      { path: "", component: ListingUsersComponent },
      { path: "edit/:id", component: EditUserComponent },
      { path: "add", component: AddUserComponent }
    ],
    data: { category: 'users' },
  },
  {
    path: "permission",
    canActivate: [AuthService],
    component: TabpermissionManagementComponent
  },
  {
    path: "elements",
    canActivate: [AuthService, PermissionGuard],
    component: ListingElementComponent,
    data: { category: "elements" }
  }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
