import { QuoteElementBase, QuoteElementType } from "../base";
import { ITicketElement } from "app/presentationnal/organisms/wizard-modal/service/step-form.service";
import { Element } from "../elements/element.base";
import { CustomElement } from "../elements/customs/custom.base";

export interface FinishProperty {
    cost: number;
    area?: number;
    weight?: number;
    areaCost?: number;
    weightCost?: number;
    fixedPrice: boolean;
}
export abstract class Finish<T extends FinishProperty> extends QuoteElementBase {

    public constructor(
        name: string,
        protected ticket: ITicketElement[],
        protected properties: T) {
        super(name);
        this.CustomName = name;
    }

    public get Type(): QuoteElementType {
        return "FINISH";
    }

    public get TotalArea(): number {
        // Need sum of all areas of every elements and their children
        const elements = this.ticket.filter(el => el.element instanceof Element);
        return elements.length == 0 ? 0 : elements
            .map(elem => {
                const tmp = this.getPropertySum(elem, "TotalArea");
                return tmp;
            })
            .reduce((total, current) => total + current);
    }

    public get TotalWeight(): number {
        // Need sum of all weight of every elements and their children
        const elements = this.ticket.filter(el => el.element instanceof Element);
        return elements.length == 0 ? 0 : elements
            .map(elem => this.getPropertySum(elem, "TotalWeight"))
            .reduce((total, current) => total + current);
    }

    private getPropertySum(elem: ITicketElement, propertyName: string): number {
        const children = elem.children.filter(child => child instanceof CustomElement);
        const tmp1 = elem.element[propertyName];
        const tmp2 =             (children.length == 0 ? 0 :
            children.map(child => child[propertyName] * child.Quantity)
                    .reduce((total, current) => total + current)
        );
        return elem.element.Quantity * (
            elem.element[propertyName] +
            (children.length == 0 ? 0 :
                children.map(child => child[propertyName] * child.Quantity)
                        .reduce((total, current) => total + current)
            )
        );
    }

    public get Weight(): number {
        return this.properties.weight != null ? this.properties.weight : this.TotalWeight;
    }

    public set Weight(value: number) {
        this.properties.weight = value;
    }

    public get Area(): number {
        return this.properties.area != null ? this.properties.area : this.TotalArea;
    }

    public set Area(value: number) {
        this.properties.area = value;
    }

    public get Cost(): number {
        return this.properties.cost;
    }

    public set Cost(value: number) {
        this.properties.cost = value;
    }

    public set Ticket(value: ITicketElement[]) {
        this.ticket = value;
    }

    public get FixedPrice(): boolean {
      return this.properties.fixedPrice;
    }

    public set FixedPrice(value: boolean) {
        this.properties.fixedPrice = value;
    }

    public get AreaCost(): number {
      return this.properties.areaCost;
    }

    public set AreaCost(value: number) {
        this.properties.areaCost = value;
    }

    public get WeightCost(): number {
      return this.properties.weightCost;
    }

    public set WeightCost(value: number ) {
        this.properties.weightCost = value;
    }


    public abstract getPricePerComputing(): number;
}