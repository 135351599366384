import { Component, OnInit, Inject } from "@angular/core";
import { ModalContentForm } from "../../model/modal-content-form";
import { ModalService, DATA } from "../../services/modal.service";
import { SnackService } from "app/presentationnal/organisms/snack-bar/services/snack.service";
import { FormBuilder, Validators } from "@angular/forms";
import { ISelectOption } from "app/presentationnal/atoms/inputs/select-input/selectOptions";
import { checkMailValid } from "app/presentationnal/atoms/inputs/custom-validator";
import { IModalData } from "../../interfaces/modal-data.interface";
import { ISupplierContact } from "app/facade/interfaces/supplier";
import { SuppliersQueriesService } from "app/facade/queries/supplier/suppliers-queries.service";

@Component({
  selector: "app-modal-add-supplier-contact",
  templateUrl: "./modal-add-supplier-contact.component.html",
  styleUrls: ["./modal-add-supplier-contact.component.css"]
})
export class ModalAddSupplierContactComponent extends ModalContentForm implements OnInit {
  private _contactData: any;
  public languageSelection: ISelectOption[] = [
    { value: "FR", label: "FR"},
    { value: "NL", label: "NL"}
  ];

  constructor(
    protected _modalService: ModalService,
    protected _snackBar: SnackService,
    protected _fb: FormBuilder,
    private _suppliersQueriesSrv: SuppliersQueriesService,
    @Inject(DATA) private _data: IModalData) {
      super(_modalService, _fb, _snackBar);
      this._contactData = this._data && this._data.data ? this._data.data : null;
    }

  ngOnInit() {
    this.initForm();
  }

  private initForm() {
    this._formGroup = this._fb.group({
      lastname: [this._contactData ? this._contactData.lastname : null, Validators.compose([
        Validators.required, Validators.maxLength(30)
      ])],
      firstname: [this._contactData ? this._contactData.firstname : null, Validators.maxLength(30)],
      phone: [this._contactData ? this._contactData.phone : null, Validators.maxLength(20)],
      mail: [this._contactData ? this._contactData.mail : null, Validators.compose([
        checkMailValid, Validators.maxLength(100)
      ])],
      language: [this._contactData ? this._contactData.language : null, Validators.required],
      function: [this._contactData ? this._contactData.function : null, Validators.maxLength(100)],
      isFavorite: [this._contactData ? this._contactData.isFavorite : false]
    });
  }

  public confirmModal(): void {
    if ( this._formGroup.valid ) {
      if (!!this._data.data.id) {
        this.updateSupplierContact(this._data.data.id);
      } else {
        this.saveNewSupplierContact();
      }
    } else {
        this.markFormGroupTouched(this._formGroup);
        this.displayErrorMessage();
    }
  }

  protected save(data: ISupplierContact): void {
    const objData = {
      confirm: true,
      data
    };
    this.afterClosed.next(objData);
  }

  /**
   * @description Send request to save new Supplier Contact. Closes the modal if successfull, displays error if not
   * @author Quentin Wolfs
   * @private
   * @memberof ModalAddSupplierContactComponent
   */
  private saveNewSupplierContact(): void {
    const snackBarTitle: string = "Ajout d'un contact";
    this._suppliersQueriesSrv.addSupplierContact(this._formGroup.value, this._data.data.supplierId).subscribe(result => {
      const resultData: any = result.data;
      if (resultData && resultData.createSupplierContact) {
        this._snackBar.open(snackBarTitle, "Le contact a été créé", "success", 5000);

        // Save was successfull, closing the modal with saved data
        this.save(resultData.createSupplierContact);
        this._modalService.closeModal();
      }
    }, error => {
      this._snackBar.openSnackBarError(snackBarTitle, "Le contact n'a pas été créé", error);
    });
  }

  /**
   * @description Send request to update existing Supplier Contact. Closes the modal if successfull, displays error if not
   * @author Quentin Wolfs
   * @private
   * @param {number} contactId
   * @memberof ModalAddSupplierContactComponent
   */
  private updateSupplierContact(contactId: number): void {
    const snackBarTitle: string = "Mise à jour d'un contact";
    this._suppliersQueriesSrv.updateSupplierContact(contactId, this._formGroup.value, this._data.data.supplierId).subscribe(result => {
      const resultData: any = result.data;
      if (resultData && resultData.updateSupplierContact) {
        this._snackBar.open(snackBarTitle, "Le contact a été mis à jour", "success", 5000);

        // Update was successfull, closing the modal with updated data
        this.save(resultData.updateSupplierContact);
        this._modalService.closeModal();
      }
    }, error => {
      this._snackBar.openSnackBarError(snackBarTitle, "Le contact n'a pas été mis à jour", error);
    });
  }
}
