import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { IpurchaseOrderAdmission } from '../../../../../facade/interfaces/purchaseOrderAdmission.interface';
import { AdmissionQueriesService } from '../../../../../facade/queries/admission/admission.queries';
import { SnackService } from '../../../snack-bar/services/snack.service';
import { MODAL_CLOSE_ACTION } from '../../enums/modal-close-action.enum';
import { IModalData } from '../../interfaces/modal-data.interface';
import { ModalContentForm } from '../../model/modal-content-form';
import { DATA, ModalService } from '../../services/modal.service';

@Component({
  selector: 'app-modal-add-admission',
  templateUrl: './modal-add-admission.component.html',
  styleUrls: ['./modal-add-admission.component.css'],
  providers: [DatePipe]
})
export class ModalAddAdmissionComponent extends ModalContentForm implements OnInit {

  public quantityAdmission = 0;
  public isZero: boolean;
  public currentD = new Date();
  private date;
  private _admissionData;
  public user : any;
  private _remainingQuantity: number;
  public isRemainingQuantity : boolean;

  constructor(
    protected _modalService: ModalService,
    protected _snackBar: SnackService,
    protected _fb: FormBuilder,
    private _admissionQueriesSrv: AdmissionQueriesService,
    private datePipe: DatePipe,
    @Inject(DATA) private _data: IModalData
  ) {
      super(_modalService, _fb, _snackBar);
      this.date = this.datePipe.transform((this.currentD), 'dd/MM/yyyy')
   }

  ngOnInit() {
    //get user
    this.user =  JSON.parse(localStorage.getItem('userData'));
    this.loadAdmission(+this._data.params.id);
  }

  /**
  * @description init form
  * @author Marie Claudia
  * @private
  * @memberof ModalAddAdmissionComponent
  */
  private initForm() {
    this._formGroup = this._fb.group({
      quantity: [this._remainingQuantity ? this._remainingQuantity : +this._data.params.quantity ,Validators.required],
      date: [this.date ,Validators.required],
      localisation: [null],
      admitedBy: [this.user ? this.user.firstname + " " + this.user.lastname : null ,Validators.required],
      idElement: +this._data.params.id
    });
  }

  /**
  * @description protected save data in modal
  * @author Marie Claudia
  * @protected
  * @memberof ModalAddAdmissionComponent
  */
  protected save(data?: IpurchaseOrderAdmission): void {
    const objData = {
      confirm: true,
      data
    };
    this.afterClosed.next(objData);
  }

  /**
  * @description save admission in db
  * @author Marie Claudia
  * @private
  * @memberof ModalAddAdmissionComponent
  */
  private saveAdmission(): void {
    let quantity= this._formGroup.get("quantity").value;
    let quantityInt  = +quantity;
    this._admissionData = {
      quantity: quantityInt,
      date: this._formGroup.get("date").value,
      location: this._formGroup.get("localisation").value,
      admitedBy: this._formGroup.get("admitedBy").value,
      idElement: this._formGroup.get("idElement").value
    }
    if(typeof(this._formGroup.get("date").value) === "string"){
       const date = Math.floor(Date.parse(this._formGroup.get("date").value) / 1000);
      this._admissionData.date = date;
    }

    if(!this.isRemainingQuantity){
      try {
          this._admissionQueriesSrv.addAdmission(this._admissionData).subscribe(result => {
            const resultData: any = result.data;
            if (resultData && resultData.addAdmission) {
              this._snackBar.open(this._data.title, "L'admission de cet élément a été fait", "success", 5000);

              // Save was successfull, closing the modal with saved data
              this.save(resultData.addAdmission);
              this._modalService.closeModal();
              window.location.reload()
            } else {
              this._snackBar.openSnackBarError(this._data.title, "L'admission n'a pas été fait");
            }
          }, error => {
            this._snackBar.openSnackBarError(this._data.title, "L'admission n'a pas été fait", error);
          });
        }
        catch (error) {
          this._snackBar.openSnackBarError(this._data.title,  "La quantité ajouter est supérieur à la quantité disponible", error);
      }
    }else{
      this._snackBar.openSnackBarError(this._data.title, "La quantité ajouter est supérieur à la quantité disponible");
    }
  }


  /**
  * @description save admission in db
  * @author Marie Claudia
  * @private
  * @memberof ModalAddAdmissionComponent
  */
  private loadAdmission(idElement: number){
      this._admissionQueriesSrv.getAdmission(idElement).subscribe(result => {
        const resultData: any = result.data;
        if (resultData && resultData.getAdmission) {
          let data = resultData.getAdmission;
          this.quantityAdmission =0;
          for (let index = 0; index < data.length; index++) {
            const element = data[index];
            this.quantityAdmission += element.quantity;
          }
          this._remainingQuantity = +this._data.params.quantity - this.quantityAdmission;
          if(+this._data.params.quantity <= this.quantityAdmission){
            this.isZero = true;
          }
          this.initForm();
        }
      }, error => {
        this._snackBar.openSnackBarError("Admission", "ERROR GET ADMISSION", error);
      });
    this.initForm();
  }

  /**
  * @description confirm modal
  * @author Marie Claudia
  * @public
  * @memberof ModalAddAdmissionComponent
  */
  public confirmModal(): void {
    if ( this._formGroup.valid ) {
      switch (this._data.closeAction) {
        case MODAL_CLOSE_ACTION.SAVE:
          this.saveAdmission();
          break;
        default:
          console.error("Modal called outside of scope");
      }
    } else {
        this.markFormGroupTouched(this._formGroup);
        this.displayErrorMessage();
    }
  }

  public quantityControl(){
    let quantity= this._formGroup.get("quantity").value;
    let quantityInt  = +quantity;
    this.isRemainingQuantity = (this._remainingQuantity < quantityInt) ? true : false;
  }
}
