import { Component, OnInit, Inject } from "@angular/core";
import { StepFormService, CONTAINER_DATA, ActualData } from "app/presentationnal/organisms/wizard-modal/service/step-form.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { OperationService } from "app/facade/quote-element-definition/operations/operation.service";
import { DrillingFormulaDisplayer, DrillingBase } from "app/facade/quote-element-definition/formula.display";
import { Subscription } from "rxjs";

@Component({
  selector: "app-drilling-step-one",
  templateUrl: "./drilling-step-one.component.html",
  styleUrls: ["./drilling-step-one.component.css"]
})
export class DrillingStepOneComponent implements OnInit {

  public dataTicket;
  public imgPath;
  public useClass;
  public elementChoose;
  public indexElement: number;
  public drillingStepOneForm: FormGroup;
  public calculs: string[];

  private _actualData: ActualData;
  private _dbData: any;
  private _formulaDisplayer: DrillingFormulaDisplayer;
  private _formulaSub: Subscription;

  constructor(@Inject(CONTAINER_DATA) data: any,
    private _stepFormSrv: StepFormService,
    private _operationSrv: OperationService,
    protected _fb: FormBuilder) {
    this.useClass = data.useClass;
    this._actualData = this._stepFormSrv.get();
    this.indexElement = this._actualData.isEdited ? this._actualData.index : this._stepFormSrv.getLastElementSelected();
    this.dataTicket = data.ticket[this.indexElement];
    this._dbData = {};
  }

  ngOnInit() {
    this.presetElement();
    this.initForm();
    this.getMyPicture("drilling");
    this.calculs = [];
    this._formulaDisplayer = new DrillingFormulaDisplayer();
    this._formulaSub = this._formulaDisplayer.$drillingPrice.subscribe(calculs => this.calculs = calculs);
    this.showCalcul();
  }

  private initForm() {
    const custom: any = this._actualData.custom;
    this.drillingStepOneForm = this._fb.group({
      customName: [custom ? custom.CustomName : this.useClass, Validators.required],
      isCalculated: [custom ? custom.IsCalculated : true, Validators.required],
      isRound: custom ? custom.IsRound : true,
      holePrice: custom ? custom.HolePrice : null,
      diameter: custom ? custom.Diameter : null,
      width: custom ? custom.Width : null,
      height: custom ? custom.Height : null,
      quantity: [custom ? custom.HoleQuantity : null, Validators.required],
      unitaryAdditionalCost: custom ? custom.UnitaryAdditionalCost : null,
      globalAdditionalCost: custom ? custom.GlobalAdditionalCost : null,
      remark: custom ? custom.Remarks : null,
    });
  }

  public chooseElement(choice: any, init: boolean) {
    this.elementChoose = choice;
    this._operationSrv.getDrillingData(choice.drillThickness).subscribe(data => {
      this._dbData.prc = data.data["getActionByThickness"]["natureValues"].Prc;
      this._dbData.priceByM = data.data["getActionByThickness"]["natureValues"].PriceByM;
      this.drillingStepOneForm.get("isCalculated").patchValue(this.drillingStepOneForm.get("isCalculated").value);
    });
    if (!init && this._actualData.isEdited) {
      this._stepFormSrv.deleteElement(this._actualData.index, this._actualData.childrenIndex);
      this._actualData.index = this.indexElement;
      this._actualData.custom.element = choice;
      this._actualData.childrenIndex = this._stepFormSrv.addCustom(this.indexElement, this._actualData.custom);
    }
  }

  public next() {
    const { isCalculated, holePrice, isRound, width, height, diameter } = this.drillingStepOneForm.value;
    if ((!isCalculated && holePrice) || (isCalculated && isRound && diameter) || (isCalculated && !isRound && width && height)) {
      if (this.drillingStepOneForm && this.drillingStepOneForm.valid) {
        if (this._actualData.isEdited) {
          this._actualData.custom.HoleQuantity = this.drillingStepOneForm.get("quantity").value;
          this._actualData.custom.CustomName = this.drillingStepOneForm.get("customName").value;
          this._actualData.custom.UnitaryAdditionalCost = this.drillingStepOneForm.get("unitaryAdditionalCost").value;
          this._actualData.custom.GlobalAdditionalCost = this.drillingStepOneForm.get("globalAdditionalCost").value;
          this._actualData.custom.Remarks = this.drillingStepOneForm.get("remark").value;
          this._actualData.custom.IsCalculated = <boolean>this.drillingStepOneForm.get("isCalculated").value;
          this._actualData.custom.IsRound = <boolean>this.drillingStepOneForm.get("isRound").value;
          this._actualData.custom.HolePrice = <number>this.drillingStepOneForm.get("holePrice").value;
          this._actualData.custom.Width = <number>this.drillingStepOneForm.get("width").value;
          this._actualData.custom.Height = <number>this.drillingStepOneForm.get("height").value;
          this._actualData.custom.Diameter = <number>this.drillingStepOneForm.get("diameter").value;
          this._stepFormSrv.updateElement(this._actualData.custom, null, this._actualData.index, this._actualData.childrenIndex);
        } else {
          // const properties = deepClone(this.elementChoose);
          const definedValues: any = {
            isCalculated: <boolean>this.drillingStepOneForm.get("isCalculated").value,
            holeQuantity: <number>this.drillingStepOneForm.get("quantity").value,
            isRound: <boolean>this.drillingStepOneForm.get("isRound").value,
            holePrice: <number>this.drillingStepOneForm.get("holePrice").value,
            width: <number>this.drillingStepOneForm.get("width").value,
            height: <number>this.drillingStepOneForm.get("height").value,
            diameter: <number>this.drillingStepOneForm.get("diameter").value
          };
          this._operationSrv.create("Drilling", { element: this.elementChoose, properties: definedValues }).subscribe(drilling => {
            drilling.CustomName = this.drillingStepOneForm.get("customName").value;
            drilling.UnitaryAdditionalCost = this.drillingStepOneForm.get("unitaryAdditionalCost").value;
            drilling.GlobalAdditionalCost = this.drillingStepOneForm.get("globalAdditionalCost").value;
            drilling.Remarks = this.drillingStepOneForm.get("remark").value;
            this._stepFormSrv.addCustom(this.indexElement, drilling);
          });
        }
        this._stepFormSrv.closeModal$.next(true);
      } else {
        this._stepFormSrv.markFormGroupTouched(this.drillingStepOneForm);
      }
    } else {
      this._stepFormSrv.markFormGroupTouched(this.drillingStepOneForm);
    }
  }

  /**
   * @description Cancel the creation/Edit of the drilling operation and close the wizard
   * @author Nicolas Van Der Haeghen
   * @memberof DrillingStepOneComponent
   */
  public cancel() {
    this._stepFormSrv.closeModal$.next(true);
  }

  public resetCalculated() {
    if (this.drillingStepOneForm.get("isCalculated").value) {
      this.drillingStepOneForm.get("holePrice").patchValue(null);
    } else {
      this.drillingStepOneForm.get("diameter").patchValue(null);
      this.drillingStepOneForm.get("height").patchValue(null);
      this.drillingStepOneForm.get("width").patchValue(null);
    }
  }

  public resetRound() {
    if (this.drillingStepOneForm.get("isRound").value) {
      this.drillingStepOneForm.get("height").patchValue(null);
      this.drillingStepOneForm.get("width").patchValue(null);
    } else {
      this.drillingStepOneForm.get("diameter").patchValue(null);
    }
  }

  public presetElement() {
    this.chooseElement(this.dataTicket.element, true);
  }

  public getMyPicture(folder: string) {
    this.imgPath = "../../../../../../../../assets/img/wizard/" + folder.toLowerCase() + "/step_one.jpg";
  }

  private showCalcul() {
    this.drillingStepOneForm.valueChanges.subscribe(values => {
      const base: DrillingBase = {
        isCalculated: values.isCalculated,
        holeQuantity: values.quantity,
        prc: this._dbData.prc,
        priceByM: this._dbData.priceByM
      };
      if (values.isCalculated) {
        base.isRound = values.isRound;
        if (values.isRound) {
          base.diameter = values.diameter;
        } else {
          base.height = values.height;
          base.width = values.width;
        }
      } else {
        base.holePrice = values.holePrice;
      }

      if (Object.keys(base).every(k => base[k] != null)) {
        this._formulaDisplayer.base = base;
      }
    });

    const init: DrillingBase = {
      isCalculated: this.drillingStepOneForm.value.isCalculated,
      holeQuantity: this.drillingStepOneForm.value.quantity,
      prc: this._dbData.prc ? this._dbData.prc : 0,
      priceByM: this._dbData.priceByM ? this._dbData.priceByM : 0,
    };
    if (init.isCalculated) {
      init.isRound = this.drillingStepOneForm.value.isRound;
      if (init.isRound) {
        init.diameter = this.drillingStepOneForm.value.diameter;
      } else {
        init.height = this.drillingStepOneForm.value.height;
        init.width = this.drillingStepOneForm.value.width;
      }
    } else {
      init.holePrice = this.drillingStepOneForm.value.holePrice;
    }

    if (Object.keys(init).every(k => init[k] != null)) {
      this._formulaDisplayer.base = init;
    }
  }
}
