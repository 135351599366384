import { Component, OnInit, OnDestroy } from "@angular/core";
import { FormGroup, FormBuilder, Validators, FormControl } from "@angular/forms";
import { SnackService } from "app/presentationnal/organisms/snack-bar/services/snack.service";
import { ActivatedRoute, Router } from "@angular/router";
import { Subscription } from "rxjs";
import { QuotesQueriesService } from "app/facade/queries/quote/async/quotes_queries.service";
import { QuotesProjectQueriesService } from "app/facade/queries/quote-project/async/quotes-project-queries.service";
import { ISelectOption } from "app/presentationnal/atoms/inputs/select-input/selectOptions";
import { AuthService } from "@lib/auth/auth.service";
import { ModalService } from "app/presentationnal/organisms/modal/services/modal.service";
import { ModalAddQuotesProjectComponent } from "app/presentationnal/organisms/modal/components/modal-add-quotes-project/modal-add-quotes-project.component";
import { MODAL_TYPE } from "app/presentationnal/organisms/modal/enums/modal-type.enum";
import { SearchCreateOption } from "app/presentationnal/atoms";
import { Quote } from "app/facade/interfaces";
@Component({
  selector: "app-create-quote",
  templateUrl: "./create-quote.component.html",
  styleUrls: ["./create-quote.component.css"]
})
export class CreateQuoteComponent implements OnInit, OnDestroy {
  public formGroup: FormGroup;
  public projectFormGroup: FormGroup;
  public projectIdData: string;
  public _objData: Quote;
  public optionsSelected: ISelectOption[] = [];
  public searchCreateOptions: SearchCreateOption = { // Config for the search input add button
    label: "Create this project",
    callback: null,
  };
  public autoSelect: boolean;

  private _projectSub: Subscription;
  private _addProjectSub: Subscription;

  constructor(protected _fb: FormBuilder,
              private snackBar: SnackService,
              public route: ActivatedRoute,
              public router: Router,
              private _modalService: ModalService,
              private _quotesQueriesSrv: QuotesQueriesService,
              private _quotesProjectQueriesSrv: QuotesProjectQueriesService,
              private authSrv: AuthService) {
  }

  ngOnInit() {
    this.checkUrl();
    this.searchCreateOptions.callback = this.addProject;
  }

    /**
   * @description Init the basics form group
   * @author Kevin Palade
   * @private
   * @memberof ModalEditProjectComponent
   */
  private initForm() {
    this.formGroup = this._fb.group({
      projectId : [this._objData && this._objData.projectId ? this._objData.projectId : null, Validators.required],
      name: [this._objData && this._objData.name ? this._objData.name : null, Validators.required],
      number: [this._objData && this._objData.number ? this._objData.number : this.getNumber(), Validators.required],
      reference: this._objData && this._objData.reference ? this._objData.reference : null,
      isEn1090: [this._objData  && this._objData.isEn1090 ? this._objData.isEn1090 : false, Validators.required],
    });

    this.projectFormGroup = this._fb.group({
      customer: null,
      reference: null
    });

    this.getProjects(this._objData && this._objData.project && this._objData.project.name ? this._objData.project.name : "", true);
    this.autoSelect = this._objData !== undefined;
    this.formGroup.get("projectId").valueChanges.subscribe(result => {
      if (result) {
        this._quotesProjectQueriesSrv.getQuoteProject(result).subscribe(resultProject => {
          const dataQuery: any = resultProject.data;
          this.projectFormGroup.get("customer").patchValue(dataQuery.quoteProject.customer);
          this.projectFormGroup.get("reference").patchValue(dataQuery.quoteProject.reference);
        });
      } else {
        this.projectFormGroup.get("customer").patchValue("");
        this.projectFormGroup.get("reference").patchValue("");
      }
    });
  }

  /**
   * @description When you receive one event from select autocomplete. The method for autocomplete is send.
   * @author Rousseau Corentin
   * @param {string} event
   * @memberof CreateQuoteComponent
   */
  public searchProjects(event: string) {
    this.getProjects(event);
  }

  /**
   * @description
   * @author Rousseau Corentin
   * @memberof CreateQuoteComponent
   */
  public save() {
    if (this._objData && this._objData._id) {
      this.updateQuote();
    } else {
      this.createQuote();
    }
  }

  /**
   * @description Processus to create Quote
   * @author Rousseau Corentin
   * @memberof CreateQuoteComponent
   */
  public createQuote() {
    if (this.formGroup && this.formGroup.valid) {
      const quote = {
        name : this.formGroup.get("name").value,
        reference : this.formGroup.get("reference").value,
        number : this.formGroup.get("number").value,
        isEn1090 : this.formGroup.get("isEn1090").value,
        projectId : this.formGroup.get("projectId").value
      };
      this._quotesQueriesSrv.addQuote(quote).subscribe(result => {
        const actualQuote = result.data["createQuote"];
        this.snackBar.open("Créer un devis", "Le devis a été créé", "success", 5000);
        this.router.navigate(["/quote/" + actualQuote._id + "/wizard"]);
      });
    } else {
      this.markFormGroupTouched(this.formGroup);
      this.snackBar.open("Créer un devis", "Formulaire incomplet", "warning", 5000);
    }
  }

  protected markFormGroupTouched(formGroup: FormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
        (<FormControl>control).updateValueAndValidity();
        control.markAsTouched();
        if (control.controls) { control.controls.forEach(c => this.markFormGroupTouched(c)); }
    });
}

  /**
   * @description Processus to update Quote
   * @author Rousseau Corentin
   * @memberof CreateQuoteComponent
   */
  public updateQuote() {
    if (this.formGroup && this.formGroup.valid) {
      const id = this._objData._id;
      const quote = {
        name : this.formGroup.get("name").value,
        reference : this.formGroup.get("reference").value,
        number : this.formGroup.get("number").value,
        isEn1090 : this.formGroup.get("isEn1090").value,
        projectId : this.formGroup.get("projectId").value,
      };
      this._quotesQueriesSrv.updateQuote(id, quote).subscribe(result => {
        const actualQuote = result.data["createQuote"];
        this.snackBar.open("Créer un devis", "Le devis a été modifié", "success", 5000);
        this.router.navigate(["/quote/" + id + "/wizard"]);
      });
    } else {
      this.snackBar.open("Créer un devis", "Formulaire incomplet", "warning", 5000);
    }
  }

  /**
   * @description list and format project for autocomplete Search
   * @author Rousseau Corentin
   * @param {*} typeFilter
   * @memberof CreateQuoteComponent
   */
  public getProjects(typeFilter: string, init: boolean = false) {
    this._projectSub = this._quotesProjectQueriesSrv.queryListQuotesProjects( typeFilter ).subscribe( result => {
      const projectOption: any = result.data;
      let projectFilter: any;
      if (init && typeFilter != "" && this.formGroup.get("projectId").value) {
        projectFilter = projectOption.quoteProjects.filter( project => project._id === this.formGroup.get("projectId").value);
      } else {
        projectFilter = projectOption.quoteProjects;
      }
      this.optionsSelected = projectFilter.map(element => {
        return {
          label : element.name,
          value : element._id,
        };
      });
    });
  }

  /**
   * @description Send result when we are in EDiTview
   * @author Rousseau Corentin
   * @param {*} id
   * @memberof CreateQuoteComponent
   */
  public getProject(id) {
    this._projectSub = this._quotesProjectQueriesSrv.getQuoteProject(id).subscribe(result => {
      const projectOption: any = result.data;
        const projectOptions: any = {
          label : projectOption.quoteProject.name,
          value : projectOption.quoteProject._id,
        };
        this.optionsSelected = [projectOptions];
    });
  }


  /**
   * @description Data for quote when the form is EDIT
   * @author Rousseau Corentin
   * @param {*} id
   * @memberof CreateQuoteComponent
   */
  public getQuote(id) {
    this._projectSub = this._quotesProjectQueriesSrv.getQuoteProject(id).subscribe(result => {
      const projectOption: any = result.data;
      this.formGroup.get("projectId").patchValue(projectOption.quoteProject._id);
        const projectOptions: any = {
          label : projectOption.quoteProject.name,
          value : projectOption.quoteProject._id,
        };
        this.optionsSelected = [projectOptions];
        return projectOption.quoteProject._id;
    });
  }

  /**
   * @description
   * @author Rousseau Corentin
   * @memberof CreateQuoteComponent
   */
  public checkUrl() {
    let hasNoParamMap: boolean = true;
    if (this.route.snapshot.paramMap.get("id")) {
      hasNoParamMap = false;
      this._quotesQueriesSrv.getQuote(this.route.snapshot.paramMap.get("id")).subscribe(result => {
        this._objData = result.data["quote"];
        if (this._objData.projectId) { this.getProject(this._objData.projectId); }
        this.initForm();
      });
    }

    if (this.route.snapshot.paramMap.get("projectId")) {
      hasNoParamMap = false;
      this._quotesProjectQueriesSrv.getQuoteProject(this.route.snapshot.paramMap.get("projectId")).subscribe(result => {
        this._objData = {
          projectId: result.data["quoteProject"]._id,
          project : result.data["quoteProject"]
        };
        this.initForm();
      });
    }

    if (hasNoParamMap) {
      this.initForm();
    }
  }

  /**
   * @description Open the add project modal when click on the searchInputAddButton
   * @private
   * @memberof CreateQuoteComponent
   */
  private addProject = (projectName: string) => {
    const project = { name: projectName };
    const modalRef = this._modalService.openModal(ModalAddQuotesProjectComponent, { title: "Ajouter un projet", type: MODAL_TYPE.NORMAL, data: {project: project}});
    if (modalRef) {
      modalRef.afterClosed.subscribe(res => {
        if (res.confirm && res.data) {
          const snackBarTitle: string = "Ajouter un projet";
          if (this._addProjectSub) { this._addProjectSub.unsubscribe(); }
          this._addProjectSub = this._quotesProjectQueriesSrv.addQuoteProject(res.data, {}).subscribe(result => {
            this.snackBar.open(snackBarTitle, "Le projet a été créé", "success", 5000);
            const projectRes = result.data["createQuoteProject"];
            this.formGroup.get("projectId").patchValue(projectRes._id);
            this.autoSelect = true;
            this.getProjects(projectRes && projectRes.name ? projectRes.name : "", true);
          }, error => {
            this.snackBar.openSnackBarError(snackBarTitle, "Le projet n'a pas été créé", error);
          });
        }
      });
    }
  }

  /**
   * @description
   * @author Rousseau Corentin
   * @memberof CreateQuoteComponent
   */
  public getNumber() {
    this._quotesQueriesSrv.getQuoteNumber().subscribe(result => {
      this.formGroup.get("number").patchValue(result.data["getQuoteNumber"]);
    });
  }

  /**
   * @description
   * @author Rousseau Corentin
   * @memberof CreateQuoteComponent
   */
  ngOnDestroy(): void {
    if (this._projectSub) { this._projectSub.unsubscribe(); }
    if (this._addProjectSub) { this._addProjectSub.unsubscribe(); }
  }
}
