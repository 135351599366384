import { Component, OnInit, OnDestroy, Inject } from "@angular/core";
import { ModalContentForm } from "../../model/modal-content-form";
import { ModalService, DATA } from "../../services/modal.service";
import { SnackService } from "app/presentationnal/organisms/snack-bar/services/snack.service";
import { FormBuilder } from "@angular/forms";

@Component({
  selector: "app-modal-edit-remarks",
  templateUrl: "./modal-edit-remarks.component.html",
  styleUrls: ["./modal-edit-remarks.component.css"]
})
export class ModalEditRemarksComponent  extends ModalContentForm implements OnInit, OnDestroy  {

  public data;

  constructor(protected _modalService: ModalService,
              protected _snackBar: SnackService,
              protected _fb: FormBuilder,
              @Inject(DATA) private _data) {
                super(_modalService, _fb, _snackBar);
                this.data = _data;
  }

  ngOnInit() {
    this.initForm();

  }

    /**
   * @description Initialize the formGroup
   * @author Kevin Palade
   * @private
   * @memberof ModalAddQuotesProjectComponent
   */
  private initForm() {
    this._formGroup = this._fb.group({
      remarks: this.data ? this.data.data : null,

    });
  }

  /**
   * @description Emit the data on save click
   * @author Kevin Palade
   * @protected
   * @memberof ModalAddQuotesProjectComponent
   */
  protected save(): void {
    const objData = {
      confirm: true,
      data: this._formGroup.value
    };
    this.afterClosed.next(objData);
  }

}
