import { Component, OnInit, OnDestroy } from "@angular/core";
import { TableDatasDefinition } from "app/facade/interfaces/table-datas-definition";
import { TABLE_ITEM_ACTION_TYPE } from "app/presentationnal/organisms/table/table-item/enum/table-item-action-type.enum";
import { ProjectsQueriesService } from "app/facade/queries/project/projects-queries.service";
import { ModalService } from "app/presentationnal/organisms/modal/services/modal.service";
import { Subscription } from "apollo-client/util/Observable";
import { SnackService } from "app/presentationnal/organisms/snack-bar/services/snack.service";
import { MODAL_TYPE } from "app/presentationnal/organisms/modal/enums/modal-type.enum";
import { ModalAddProjectComponent, ModalConfirmComponent } from "app/presentationnal/organisms/modal";
import { IProject } from "app/facade/interfaces/project";
import { Router } from "@angular/router";
import * as moment from "moment";
import { MODAL_CLOSE_ACTION } from "app/presentationnal/organisms/modal/enums/modal-close-action.enum";
import { ListTableService } from "app/facade/services/list-table.service";
import { IPagination } from "app/facade/interfaces/crud.interface";
import { PermissionService } from "app/facade/services/permission.service";
import { AuthService } from "@lib/auth/auth.service";

const DEFAULT_LIMIT: number = 25;

@Component({
  selector: "app-listing-project",
  templateUrl: "./listing-project.component.html",
  styleUrls: ["./listing-project.component.css"]
})
export class ListingProjectComponent implements OnInit, OnDestroy {
  private _listProjectSub: Subscription;
  public project: string = "projects";
  private _deleteProjectSub: Subscription;
  private _actionsType = TABLE_ITEM_ACTION_TYPE;
  private _filterProject: string = "";
  public projects: IProject[] = [];
  public tableHeaders: string[] = [
    "Référence projet",
    "Date de création",
    "EN1090",
    "Total listes appro.",
    "Listes non utilisées",
    "actions"];
  public dataDefinition: TableDatasDefinition[] = [
    { key: "reference", isTitle: true },
    { key: "createdAt", isTitle: false },
    { key: "isEn1090", isTitle: true, isCheckBox: true },
    { key: "totalSupplyListQty", isTitle: false },
    { key: "unusedSupplyListQty", isTitle: false }
  ];
  private _pagination: IPagination = { page: 1, limit: DEFAULT_LIMIT };
  public isEdit = false;
  public isDelete = false;
  public userGroup: any;

  constructor(
    private _projectsQueriesSrv: ProjectsQueriesService,
    private _modalService: ModalService,
    private _snackBar: SnackService,
    private _listTableSrv: ListTableService,
    private _permissionService: PermissionService,
    private _authSrv: AuthService,
    private router: Router) { }

  ngOnInit() {
    this.userGroup =  this._authSrv.isUserGroup();
    this._permissionService.getAndUpdatePermission(this.userGroup);

    let savedSearch = JSON.parse(localStorage.getItem("dataProjectRecherche"));
    savedSearch = savedSearch && savedSearch.recherche.length > 0 ? savedSearch.recherche : "";
    if (savedSearch.length > 0) {
      this._snackBar.open("Liste filtrée", "Cette liste est filtrée suivant votre recherche", "info", 5000);
      this._filterProject = savedSearch;
    }
    let savedPagination = JSON.parse(localStorage.getItem("dataProjectPagination"));
    savedPagination = savedPagination && savedPagination.pagination > 1 ? savedPagination.pagination : 1;
    if (savedPagination > 1) {
      this._pagination.limit = DEFAULT_LIMIT * savedPagination;
      this.getProjectList(true);
      this._pagination = { page: savedPagination, limit: DEFAULT_LIMIT };
    } else {
      this.getProjectList(true);
    }

    this.isEdit = this._permissionService.hasPermission(this.userGroup, "projects", "write");
    this.isDelete = this._permissionService.hasPermission(this.userGroup, "projects", "delete");
    console.log({isEdit: this.isEdit, isDelete: this.isDelete});
  }

  ngOnDestroy(): void {
    this._listProjectSub.unsubscribe();
    if (this._deleteProjectSub) { this._deleteProjectSub.unsubscribe(); }
  }

  private getProjectList(forceFirstPage: boolean = false) {
    if (this._listProjectSub) { this._listProjectSub.unsubscribe(); }
    this._listProjectSub = this._projectsQueriesSrv.getProjectListing(this._filterProject, this._pagination).subscribe( result => {
      const data: any = result.data;
      if ( data && data.projects ) {
        this.projects = !forceFirstPage && this._pagination.page !== 1 ?
          [ ...this.projects, ...this.mapProjects(data.projects) ] :
          this.mapProjects(data.projects);

      }
      if (data && data.pagination) {
        this._listTableSrv.setLoadMoreStatus(data.pagination.hasNext);
      }

    }, error => {
      console.log("LOADING PROJECT ERROR", {error});
    });

  }

  /**
   * @description Map projects for list display
   * @author Quentin Wolfs
   * @private
   * @param {any[]} data
   * @returns {IProject[]}
   * @memberof ListingProjectComponent
   */
  private mapProjects(data: any[]): IProject[] {
    return data.map(project => {
      return {
        ...project,
        createdAt: moment.unix(project.createdAt).format("DD/MM/YYYY")
      };
    });
  }

  public tableButtonClicked($event: { actionType: number, itemId: number}) {
    switch (this._actionsType[$event.actionType]) {
      case "DELETE":
        this.beforeDeleteProject($event.itemId);
        break;
      case "EDIT":
        this.router.navigate([`${this.router.url}/edit/${$event.itemId}`]);
        break;
      default:
        break;
    }
  }

  public filterProject($event: any) {
    localStorage.setItem('dataProjectRecherche',JSON.stringify({"recherche":$event}))
    localStorage.setItem('dataProjectPagination', JSON.stringify({"pagination":1}));
    this._filterProject = $event;
    this._pagination.page = 1;
    this.getProjectList();
  }

  public addProject() {
    const modalTitle: string = "Création d'un projet";
    const modalRef = this._modalService.openModal(ModalAddProjectComponent, {
      title: modalTitle,
      type: MODAL_TYPE.NORMAL,
      data: null,
      closeAction: MODAL_CLOSE_ACTION.SAVE
    });
    if (modalRef) {
      modalRef.afterClosed.subscribe(res => {
        if (res.confirm && res.data) {
          this.router.navigate([`${this.router.url}/edit/${res.data.id}`]);
        }
      });
    }
  }

  private beforeDeleteProject(projectId: number) {
    const item = this.projects.find(data => data.id == projectId);
    this._modalService.openModal(ModalConfirmComponent,
      {
        title: "Êtes-vous sûr?",
        type: MODAL_TYPE.CONFIRM,
        data: {
          message: `Voulez-vous supprimer le projet <b>${item.reference}</b>?`,
          actions: [
            "Supprimer le projet de manière définitive",
            "Supprimer les liste d'approvisionnement de ce projet"
          ],
        },
        params: { projectId },
        confirmCallback: this.deleteProject
      }
    );
  }

  public deleteProject = (params: { projectId: string|number }) => {
    const snackBarTitle: string = "Supprimer un projet";
    if (this._deleteProjectSub) { this._deleteProjectSub.unsubscribe(); }
    this._deleteProjectSub = this._projectsQueriesSrv.deleteProject(+params.projectId).subscribe(result => {
      if (result.data["deleteProject"]) {
        const index = this.projects.findIndex(project => project.id == params.projectId);
        this.projects = [...this.projects.slice(0, index), ...this.projects.slice(index + 1)];
        this._snackBar.open(snackBarTitle, "Le projet a été supprimé", "success", 5000);
      } else {
        this._snackBar.openSnackBarError(snackBarTitle, "Le projet n'a pas été supprimé");
      }
    }, error => {
      this._snackBar.openSnackBarError(snackBarTitle, "Le projet n'a pas été supprimé", error);
    });
    if(!this.isDelete){
      this._snackBar.openSnackBarError("Supprimer un projet", "Le projet n'est pas  supprimé car vous n'avez pas l'autorisation!");
    }
  }

  public loadMore(event: boolean) {
    this._pagination.page++;
    localStorage.setItem('dataProjectPagination', JSON.stringify({"pagination":this._pagination.page}));
    this.getProjectList();
  }
}
