/*
 * @Author: Kevin Palade
 * @Date: 2018-09-08 14:35:19
 * @Last Modified by:   Kevin Palade
 * @Last Modified time: 2018-09-08 14:35:19
 */
import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-max-text",
  templateUrl: "./max-text.component.html",
  styleUrls: ["./max-text.component.css"]
})
export class MaxTextComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
