import { OnInit, Inject, OnDestroy } from "@angular/core";
import { Validators, FormGroup, FormBuilder } from "@angular/forms";
import { StepFormService, ActualData, CONTAINER_DATA } from "../../../service/step-form.service";
import { SquareFoldingFormulaDisplayer } from "app/facade/quote-element-definition/formula.display";
import { Subscription } from "rxjs";

export abstract class StepFoldingBase implements OnInit, OnDestroy {

  public stepFoldingForm: FormGroup;
  private _actualData: ActualData;
  public imgPath;

  public calculs: string[] = [];
  private _formulaDisplayer: SquareFoldingFormulaDisplayer;
  private _formulaSub: Subscription;

  constructor(@Inject(CONTAINER_DATA) data: any,
              protected _stepFormSrv: StepFormService,
              protected _fb: FormBuilder) {
                this._actualData = this._stepFormSrv.get();
                this.imgPath = "../../../../../../../../assets/img/wizard/" + data.useClass.toLowerCase() + "/step_square.jpg";
              }

  ngOnInit() {
    this.initForm();
    this._formulaDisplayer = new SquareFoldingFormulaDisplayer();
    this._formulaSub = this._formulaDisplayer.$squareFoldingPrice.subscribe(calculs => this.calculs = calculs);
    this.showCalcul();
  }

  ngOnDestroy() {
    this._formulaSub.unsubscribe();
  }

  private initForm() {
    this.stepFoldingForm = this._fb.group({
      height: [this._actualData.custom.properties.height, Validators.required],
      base: [this._actualData.custom.properties.base, Validators.required],
      width: [this._actualData.custom.properties.width, Validators.required],
      foldingPrice: [this._actualData.custom.properties.foldingPrice, Validators.required],
      UnitaryFoldingAdditionalCost: this._actualData.custom.UnitaryFoldingAdditionalCost,
      GlobalFoldingAdditionalCost: this._actualData.custom.GlobalFoldingAdditionalCost,
      FoldingRemarks: this._actualData.custom.FoldingRemarks
    });
  }

  public stepChange() {
    Object.keys(this.stepFoldingForm.controls).forEach(key => {
      if (this.stepFoldingForm.get(key).touched && this.stepFoldingForm.get(key).valid) {
        this._actualData.custom[key] = this.stepFoldingForm.get(key).value;
      }
    });
    this._stepFormSrv.change({ custom: this._actualData.custom });
  }

  public next(fastSave: boolean = false) {
    if (this.stepFoldingForm && this.stepFoldingForm.valid) {
      this.stepChange();
      this._stepFormSrv.updateElement(this._actualData.custom, null, this._actualData.index, this._actualData.childrenIndex);
      if (fastSave) {
        this._stepFormSrv.closeModal$.next(true);
      } else {
        this._stepFormSrv.navigate$.next(true);
      }
    } else {
      this._stepFormSrv.markFormGroupTouched(this.stepFoldingForm);
    }
  }

  public previous() {
    this.stepChange();
    this._stepFormSrv.navigate$.next(false);
  }

  public cancel() {
    if (!this._actualData.isEdited) {
      this._stepFormSrv.deleteElement(this._actualData.index, this._actualData.childrenIndex);
    }
    this._stepFormSrv.closeModal$.next(true);
  }

  public showCalcul() {
    this.stepFoldingForm.valueChanges.subscribe(values => {
      const base = {
        height: values.height || null,
        base: values.base || null,
        length: values.width || null,
        thickness: this._actualData.custom.properties,
        price: values.foldingPrice || null
      };

      if (Object.keys(base).every(k => base[k] != null)) {
        this._formulaDisplayer.base = base;
      }
    });

    const init = {
      height: this.stepFoldingForm.value.height || null,
      base: this.stepFoldingForm.value.base || null,
      length: this.stepFoldingForm.value.width || null,
      thickness: this._actualData.custom.properties.thickness,
      price: this.stepFoldingForm.value.foldingPrice || null,
    };

    if (Object.keys(init).every(k => init[k] != null)) {
      this._formulaDisplayer.base = init;
    }
  }
}
