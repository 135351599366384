import gql from "graphql-tag";


// REFERENCE
export const PURCHASE_ORDER_ELEMENT_BDC = gql`
  query filterPurchaseOrderElement($search: String,$filter: FilterPurchaseOrderElement, $pagination: Pagination){
    filterPurchaseOrderElement(search: $search, filter: $filter, pagination: $pagination){
      id
      quantity
      denomination
      status
      isEn1090
      isBlack
      isBlasted
      isPrimaryBlasted
      format
      weight
      matterRef
      quantityUnit
      thickness
      length
      width
      remark
      deliveryDate
      realDeliveryDate
      price
      unit
      isPrinted
      purchaseOrderId
      supplierName
      purchaseOrder{
        id
        reference
        status
        sendingDate
        remark
        internalRemark
        projectId
        linkedProjects{
          id
          reference
          isEn1090
        }
        project{
          id
          reference
          isEn1090
        }
        priceRequest{
          remark
          internalRemark
        }
        createdAt
      }
      supplierOfferElementId
      supplierOfferElement{
        id
        price
        deliveryDate
        isSelected
        selectedQuantity
        parentSupplierOfferElementId
        supplierOfferId
        priceRequestElementId
        variantId
      }
      supplyCategoryId
      supplyCategory{
        id
        name
        parentSupplyCategoryId
        elementGroupId
      }
      options{
        id
        type
        denomination
        quantity
        price
        unit
        purchaseOrderElementId
      }
      createdAt
      qttOnqttTotal
      qttRecept
      projectRef
    }
  }
`;
