import { Component, OnInit, Inject, OnDestroy } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { StepFormService, CONTAINER_DATA, ActualData } from "app/presentationnal/organisms/wizard-modal/service/step-form.service";
import { Margin, MarginProperty } from "app/facade/quote-element-definition/options/margin";
import { OptionService } from "app/facade/quote-element-definition/options/options.service";
import { MarginFormulaDisplayer } from "app/facade/quote-element-definition/formula.display";
import { Subscription } from "rxjs";

@Component({
  selector: "app-margin-step-one",
  templateUrl: "./margin-step-one.component.html",
  styleUrls: ["./margin-step-one.component.css"]
})
export class MarginStepOneComponent implements OnInit, OnDestroy {
  public marginStepOneForm: FormGroup;
  private _actualData: ActualData;
  private _optionData: Margin;
  public newMargin: Margin;
  private _formulaDisplayer: MarginFormulaDisplayer;
  private _formulaSub: Subscription;
  public calculs: string[];

  constructor(@Inject(CONTAINER_DATA) data: any,
  private _fb: FormBuilder,
  private _stepFormSrv: StepFormService,
  private _optionSrv: OptionService) {
    this._actualData = this._stepFormSrv.get();
    this._optionData = this._actualData.element;
  }

  ngOnInit() {
    this.initForm();
    this.calculs = [];
    this._formulaDisplayer = new MarginFormulaDisplayer();
    this._formulaSub = this._formulaDisplayer.$optionPrice.subscribe(calculs => this.calculs = calculs);
    this.showCalcul();
  }

  public ngOnDestroy() {
    this._formulaSub.unsubscribe();
  }

  private initForm() {
    if (!this._optionData) {
      this._optionData = this._optionSrv.create("Margin", {percentage: null }, this._stepFormSrv.TicketData);
    }
    this.marginStepOneForm = this._fb.group({
      percentage: [this._optionData.Percentage , Validators.required]
    });
  }

  public cancel() {
    this._stepFormSrv.closeModal$.next(true);
  }

  public save() {
    if (this.marginStepOneForm && this.marginStepOneForm.valid) {
      if (this._actualData.isEdited) {
        this.newMargin = this._optionData;
        this.newMargin.Percentage = this.marginStepOneForm.get("percentage").value;

        this._stepFormSrv.updateElement(this.newMargin, null, this._actualData.index);
      } else {
        const marginData: MarginProperty = {
          percentage: this.marginStepOneForm.get("percentage").value,
        };
        this.newMargin = this._optionSrv.create("Margin", marginData, this._stepFormSrv.TicketData);
        this.newMargin.CustomName = "Marge";
        this._stepFormSrv.addElement(this.newMargin);
      }
      this._stepFormSrv.closeModal$.next(true);
    } else {
      this.marginStepOneForm.get("percentage").patchValue(this.marginStepOneForm.get("percentage").value);
    }
  }

  private showCalcul() {
    this.marginStepOneForm.valueChanges.subscribe(values => {
      const base = {
        ticketPrice: this._optionData.TotalTicketPrice,
        percentage: values.percentage,
        marge: this._optionData.Price
      };

      if (Object.keys(base).every(k => base[k] != null)) {
        this._formulaDisplayer.base = base;
      }
    });

    const init = {
      ticketPrice: this._optionData.TotalTicketPrice,
        percentage: this.marginStepOneForm.value.percentage,
        marge: this._optionData.Price
    };

    if (Object.keys(init).every(k => init[k] != null)) {
      this._formulaDisplayer.base = init;
    }
  }

}
