import { IWeldiableMeterable } from "../../assemblages/welding";
import { MM_TO_M, SQUARE_MM_TO_SQUARE_M } from "../../base";
import { ILaserCuttable } from "../../operations/laser-cutting";
import { AdditionalComputingCreate } from "./custom.service";
import { Platine, PlatineProperty } from "./platine";

// export interface GoussetProperty extends PlatineProperty {
// }

export class Gousset extends Platine<PlatineProperty> implements ILaserCuttable, IWeldiableMeterable {

    private _hypothenus: number;

    public constructor(
        name: string,
        createAdditionalComputing: AdditionalComputingCreate,
        properties: PlatineProperty
    ) {
        super(name, createAdditionalComputing, properties);
    }

    public get perimeter(): number {
        return (this.properties.width + this.properties.height) * 2;
    }

    public get perimeterWithMargin(): number {
        return (this.properties.width + this.properties.height + this.properties.margin * 4) * 2;
    }

    public get Area(): number {
        return this.properties.width * this.properties.height;
    }

    public get AreaWithMargin(): number {
        return ((this.properties.width + (this.properties.margin * 2)) * (this.properties.height + (this.properties.margin * 2))) / 2;
    }

    public get meterForWelding(): number {
        return (this.properties.thickness * 2 + this.properties.width * 2) * MM_TO_M;
    }

    public get TotalArea(): number {
        return (this.Area * 2
            + this.properties.width * this.properties.thickness
            + this.properties.height * this.properties.thickness * 2) * SQUARE_MM_TO_SQUARE_M;
    }
}