import {Component, Inject, OnDestroy, OnInit} from "@angular/core";
import {ModalContentForm} from "../../model/modal-content-form";
import {DATA, ModalService} from "../../services/modal.service";
import {SnackService} from "app/presentationnal/organisms/snack-bar/services/snack.service";
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {ISupplyList, ISupplyListElement, ISupplyListInput} from "app/facade/interfaces/project";
import {CategoriesQueriesService} from "app/facade/queries/category/categories-queries.service";
import {IAvailableMatters, IElements, ISupplyCategory, ISupplyFormData} from "app/facade/interfaces/supplier";
import {UploadFileService} from "@lib/upload-file/upload-file.service";
import {UPLOAD_URLS} from "@lib/upload-file/uploadUrls";
import {ITabs} from "app/facade/interfaces/tabs";
import {FIELDS_DEFAULT_VALUE, FIELDS_SPECIAL_KEY, FIELDS_TYPE_CONFIG, SUPPLY_LIST_TABLE_CONFIG} from "app/facade/enums/fields-default-value";
import {SupplyListSource} from "app/facade/enums/supply-list-source.enum";
import {ProjectsQueriesService} from "app/facade/queries/project/projects-queries.service";
import * as moment from "moment";
import {IBoxInfoConfig} from "app/facade/interfaces/box-info.interface";
import {ITableFormExecuteAction} from "app/facade/interfaces/tables.interface";
import {ElementUnitConfig} from "app/facade/configs/price-request-unit.config";
import {ElementUnitCategory, EnumQuantityUnit} from "app/facade/enums/element-unit-category.enum";
import {ISupplyListFieldsConfig} from "app/facade/interfaces/fields-config.interface";
import {CatalogQueriesService} from "app/facade/queries/catalog/async/catalog_queries.service";
import {ISelectOption} from "app/presentationnal/atoms/inputs/select-input/selectOptions";

interface IModalSupplyListData {
  supplyListData: ISupplyList;
  projectId: number;
}
@Component({
  selector: "app-modal-add-supply-list",
  templateUrl: "./modal-add-supply-list.component.html",
  styleUrls: ["./modal-add-supply-list.component.css"]
})
export class ModalAddSupplyListComponent extends ModalContentForm implements OnInit, OnDestroy {
  private _supplyListData: ISupplyList;
  private _supplyCategories: ISupplyCategory[] = [];
  private _textUndefined: string[] = [
    "Le système a rencontré des éléments ne faisant pas partis du catalogue de l'application.",
    "Ces éléments sont ",
    "Si vous souhaitez que l'application puisse reconnaître automatiquement ces éléments à l'avenir, il vous suffit de contacter la société ITDM."];
  private _textUnselectedWithElement: string = "Des catégories ou sous-catégories ont été désélectionnées alors qu'elles contenaient des éléments. En sauvegardant, les éléments des catégories suivantes seront effacés: ";

  public undefinedElements: ISupplyListElement[] = [];
  public undefinedElementAlert: IBoxInfoConfig = {
    type: "warning",
    title: "Attention",
    textContent: `${this._textUndefined[0]} ${this._textUndefined[2]}`
  };
  public categoriesUnselectedWithElement: Set<string> = new Set<string>();
  public categoriesUnselectedWithElementAlert: IBoxInfoConfig = {
    type: "danger",
    title: "Attention",
    textContent: this._textUnselectedWithElement
  };
  public formInputFileGroup: FormGroup;
  public disableFormInput: boolean = false;
  public categoryFormArray: FormArray;
  public subCategoryFormsArray: { parentId: number, form: FormArray}[] = [];
  public subCategoryIndexSelected: number = -1;
  public categoriesData: ISupplyFormData[] = [];
  public categoryFormSelected: ISupplyFormData;
  public tabsData: ITabs[] = [];
  public categoryHasElementGroup: number[] = [];
  public filterTable: string = "";
  public tableFormLoaded: boolean = false;
  private tableConfig: any = SUPPLY_LIST_TABLE_CONFIG;
  public fieldsConfig: ISupplyListFieldsConfig = FIELDS_TYPE_CONFIG;
  public showContent: boolean = false;
  public lastUpdatedCategoryId: number = null;

  constructor(
    protected _modalService: ModalService,
    protected _snackBar: SnackService,
    protected _fb: FormBuilder,
    @Inject(DATA) private _data: {data: IModalSupplyListData},
    private _categoriesQueriesSrv: CategoriesQueriesService,
    private _projectsQueriesSrv: ProjectsQueriesService,
    private _catalogQueriesSrv: CatalogQueriesService,
    private _uploadFileSrv: UploadFileService) {
      super(_modalService, _fb, _snackBar);
      this._supplyListData = this._data && this._data.data && this._data.data.supplyListData ? {...this._data.data.supplyListData} : null;
      if ( this._supplyListData) {
        this._supplyListData.deliveryDate = this._supplyListData.deliveryDate ?
          moment(this._supplyListData.deliveryDate, "DD/MM/YYYY").unix() : this._supplyListData.deliveryDate;
        this._supplyListData.createdAt = this._supplyListData.createdAt ?
          moment(this._supplyListData.createdAt, "DD/MM/YYYY HH:mm").unix() : this._supplyListData.createdAt;
      }

  }

  ngOnInit() {
    this.setHeadersActionsList();
    this.initForm();
    this.getInitData();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  /**
   * @description Define header action (click on "isBlack", "isBlasted", "IsPrimaryBlasted") for each category table
   * @author Lainez Eddy
   * @private
   * @memberof ModalAddSupplyListComponent
   */
  private setHeadersActionsList() {
    this.tableConfig.headerActionList = this.tableConfig.headerActionList.map( list => {
      if (list.id === "isBlack" || list.id === "isBlasted" || list.id === "isPrimaryBlasted") {
        return { ...list, actionToExecute: this.setOptions};
      }
      return list;
    });
  }

  /**
   * @description set option for all element in the category table
   * @private
   * @memberof ModalAddSupplyListComponent
   */
  private setOptions = (data: ITableFormExecuteAction) => {
    data.tableDatas.controls.forEach( dataControl => {
      dataControl.get("elementOptions").patchValue(data.event.id);
    });
    if (data.newData) {
      data.newData.get("elementOptions").patchValue(data.event.id);
    }
  }

  private loadSupplyListData() {
    this._projectsQueriesSrv.getSupplyList(this._supplyListData.id).subscribe( result => {
      const data: any = result.data;
      if (data && data.supplyList) {
        const {elements} = data.supplyList;
        this.checkAndGenerateData(elements);
      }
      this.tableFormLoaded = true;
      this.lastUpdatedCategoryId = null;
    }, error => {
      console.log("ERROR LOADING SUPPLY LIST DATA", {error});
      this.tableFormLoaded = true;
    });
  }

  private initForm() {
    this._formGroup = this._fb.group({
      description: [this._supplyListData ? this._supplyListData.description : null],
      deliveryDate: [this._supplyListData ? this._supplyListData.deliveryDate : null],
      model: [this._supplyListData ? this._supplyListData.model : null],
      source: [this._supplyListData ? this._supplyListData.source : SupplyListSource.MANUAL],
      createdAt: [this._supplyListData ? this._supplyListData.createdAt : null],
      isAlreadyInBarset: [this._supplyListData ? this._supplyListData.isAlreadyInBarset : false]
    });
    this.formInputFileGroup = this._fb.group({
      fileValue: [(this._supplyListData && this._supplyListData.source === SupplyListSource.TEKLA) ?
        (this._supplyListData.model) ? this._supplyListData.model : "Pas de nom de model définit"  : null],
    });
    this.disableFormInput = (this._supplyListData && this._supplyListData.source === SupplyListSource.TEKLA) ;
  }

  /**
   * @description Loads all required inital data, such as matters and all supplyCategories (parent and child) with their elements
   * @author Quentin Wolfs
   * @private
   * @memberof ModalAddSupplyListComponent
   */
  private getInitData(): void {
    const requiredData = [
      this._catalogQueriesSrv.getMatter(false).toPromise(),
      this._categoriesQueriesSrv.getSupplyCategories().toPromise()
    ];
    Promise.all(requiredData).then(result => {
      const matters: IAvailableMatters[] = (<any>result[0].data).availableMatters || [];
      const supplyCategories: ISupplyCategory[] = (<any>result[1].data).supplyCategories || [];
      this.setInitData(matters, supplyCategories);
    }, reject => {
      console.error("ERROR LOADING INIT DATA FOR ADD SUPPLY LIST", { reject });
    });
  }

  /**
   * @description Set field id for specials fields (reference, matterRef, etc...) and generate forms
   * @author Quentin Wolfs
   * @private
   * @param {IAvailableMatters[]} matters
   * @param {ISupplyCategory[]} supplyCategories
   * @memberof ModalAddSupplyListComponent
   */
  private setInitData(matters: IAvailableMatters[], supplyCategories: ISupplyCategory[]) {
    this._supplyCategories = supplyCategories.map(cat => {
      if (cat.fields.some(field => field.name === "reference")) {
        cat.fields.push({ name: "elementId", type: "Int", notDisplay: true});
        if (cat.parentSupplyCategoryId) {
          this.categoryHasElementGroup.push(+cat.id);
        }
      }
      if (cat.fields.some(field => field.name === "matterRef")) {
        cat.fields.push({ name: "matterId", type: "Int", notDisplay: true});
        // Only until Devis matches Administration matter variety for beams & tubes !
        if (cat.elementGroup && (ElementUnitConfig.categories[ElementUnitCategory.BEAMS].includes(+cat.id) || ElementUnitConfig.categories[ElementUnitCategory.TUBES].includes(+cat.id))) {
          cat.elementGroup.availableMatters = matters;
        }
      }
      return cat;
    });
    this.categoryFormArray = this.generateCategoryFormArray(this._supplyCategories.filter( cat => cat.parentSupplyCategoryId === null));
    this.generateSubCategoryForms();
    this.supplyCategoriesSubscribeChange();
    this.supplySubCategoriesSubscribeChange();
    if ( this._supplyListData) {
      this.loadSupplyListData();
    } else {
      this.tableFormLoaded = true;
    }
  }

  private checkPlateCategory(catId: number) {
    return ElementUnitConfig.categories[ElementUnitCategory.PLATES].includes(catId);
  }

  /**
   * @description Generate the formArray containing the ISupplyFormData for each parent categories
   * @author Lainez Eddy
   * @private
   * @param {ISupplyCategory[]} categories > Parent categories only
   * @returns {FormArray}
   * @memberof ModalAddSupplyListComponent
   */
  private generateCategoryFormArray(categories: ISupplyCategory[]): FormArray {
    const formArray = new FormArray([]);
    categories.forEach( category => {
      this.generateCategoryFormData(category);
      formArray.push( this.generateCategoryFormGroup(category) );
    });
    return formArray;
  }

  /**
   * @description Set the base category form data for all parent categories. Contains cat label, fields, header's label, class & action, datas in formArray, new formGroup data, actions for special's fields & options for some fields
   * @author Lainez Eddy
   * @private
   * @param {ISupplyCategory} category
   * @memberof ModalAddSupplyListComponent
   */
  private generateCategoryFormData(category: ISupplyCategory) {
    const data: ISupplyFormData = {
      id: +category.id,
      label: category.name,
      dataAttributs: category.fields
        .filter( field => this.fieldsConfig[field.name] ? !this.fieldsConfig[field.name].onlyBdc : category.fields),
      headerConfig: {
        // Take only necessary fields, sort them by priority and get the header
        headersName: category.fields
          .filter( field => !field.notDisplay && !this.fieldsConfig[field.name].onlyBdc)
          .sort((a, b) => this.fieldsConfig[a.name].priority > this.fieldsConfig[b.name].priority ? 1 : -1)
          .map( field => this.fieldsConfig[field.name].header),
        actionsList: this.tableConfig.headerActionList,
        headerClass: category.fields.some( field => field.name === "reference") ? "table-head-form-supply-list" : "table-head-form",
        rowClass: category.fields.some( field => field.name === "reference") ? "table-row-form-supply-list" : "table-row-form"
      },
      data:  new FormArray([]),
      newData: null,
      actionToDo: {
        reference: this.searchReference,
        matterRef: this.checkPlateCategory(+category.id) ? this.searchMatterForPlate : this.searchMatter
      },
      options: {
        reference: [],
        matterRef: []
      }
    };
    if (category.elementGroup && category.elementGroup.elements) {
      data.options.reference = category.elementGroup.elements.map((element, index) => {
        return { value: index, label: element.name };
      });
    }
    if (category.elementGroup && category.elementGroup.availableMatters) {
      data.options.matterRef = category.elementGroup.availableMatters.map( matter => {
        return {
          value: +matter.id,
          label: matter.en1090Name || matter.name
        };
      });
    }
    data.newData = this.createFormControl(data);
    data.headerConfig.headersName.push("actions");
    if (category.parentSupplyCategoryId === null) {
      this.categoriesData.push({
        ...data,
        subCategories: []
      });
    } else {
      const parentFormData: ISupplyFormData = this.categoriesData.find( catData => catData.id === +category.parentSupplyCategoryId);
      if (parentFormData) {
        parentFormData.subCategories.push({
          ...data,
          isDisplay: false
        });
      }
    }
  }


  /**
   * @description Generate formGroup for each parent category checkbox
   * @author Lainez Eddy
   * @private
   * @param {*} category
   * @returns {FormGroup}
   * @memberof ModalAddSupplyListComponent
   */
  private generateCategoryFormGroup( category: any ): FormGroup {
    const newFormGroup: FormGroup = this._fb.group({
      id: +category.id,
      name: category.name,
      selected: false
    });
    return newFormGroup;
  }

  /**
   * @description Generate FormArray for each child category
   * @author Lainez Eddy
   * @private
   * @memberof ModalAddSupplyListComponent
   */
  private generateSubCategoryForms() {
    this._supplyCategories.filter( cat => cat.parentSupplyCategoryId === null).forEach( parentCat => {
      this.subCategoryFormsArray.push({
        parentId: +parentCat.id,
        form: this.generateCategoryFormArray(this._supplyCategories.filter( cat => +cat.parentSupplyCategoryId === +parentCat.id))
      });
    });
  }


  /**
   * @description Listen value Changes on each parent category's checkbox
   * @author Lainez Eddy
   * @private
   * @memberof ModalAddSupplyListComponent
   */
  private supplyCategoriesSubscribeChange() {
    this.categoryFormArray.controls.forEach( catForm => {
      catForm.valueChanges.subscribe( result => {
        const categoryData: ISupplyFormData = this.categoriesData.find( catData => catData.id === +result.id);
        this.lastUpdatedCategoryId = result.selected ? result.id : null;
        if (result.selected) {
          this.tableFormLoaded = false;
          this.categoriesUnselectedWithElement.delete(categoryData.label);
          this.categoriesUnselectedWithElementAlert.textContent = `${this._textUnselectedWithElement} ${Array.from(this.categoriesUnselectedWithElement).join(", ")}`;
          this.tabsData = this.tabsData.map( tab => {
            return { ...tab, isActive: false };
          });
          this.tabsData.push({ value: result.id, label: result.name, isActive: true} );
          this.tabsData.sort((a, b) => a.value > b.value ? 1 : -1 );
        } else {
          const actualTab: ITabs = {...this.tabsData.find( tab => tab.value === result.id )};
          this.tabsData = this.tabsData.filter( tab => tab.value !== result.id);
          if (actualTab.isActive) {
            this.tableFormLoaded = false;
            this.tabsData = this.tabsData.map( (tab, index) => {
              return { ...tab, isActive: index === 0 };
            });
          }
          if (categoryData.data.value.length > 0 || categoryData.subCategories.some(subCat => subCat.data.value.length > 0)) {
            this.categoriesUnselectedWithElement.add(categoryData.label);
            this.categoriesUnselectedWithElementAlert.textContent = `${this._textUnselectedWithElement} ${Array.from(this.categoriesUnselectedWithElement).join(", ")}`;
          }
        }
        setTimeout(() => {
          this.getSubcategoryIndexToPrint();
        } , 200 );
      });
    });
  }

  /**
   * @description Listen value Changes on each children category's checkbox
   * @author Lainez Eddy
   * @private
   * @memberof ModalAddSupplyListComponent
   */
  private supplySubCategoriesSubscribeChange() {
    this.subCategoryFormsArray.forEach( subCatObject => {
      subCatObject.form.controls.forEach( catForm => {
        catForm.valueChanges.subscribe( result => {
          const selectedCat: ISupplyCategory = this._supplyCategories.find( cat => +cat.id === result.id),
                categoryData: ISupplyFormData = this.categoriesData.find( catData => catData.id === +selectedCat.parentSupplyCategoryId),
                subCategoryData: ISupplyFormData = categoryData.subCategories.find(subCat => subCat.id === result.id);
          subCategoryData.isDisplay = result.selected;
          this.lastUpdatedCategoryId = result.selected ? result.id : null;
          if ( !result.selected && subCategoryData.data.value.length > 0) {
            this.categoriesUnselectedWithElement.add(selectedCat.name);
            this.categoriesUnselectedWithElementAlert.textContent = `${this._textUnselectedWithElement} ${Array.from(this.categoriesUnselectedWithElement).join(", ")}`;
          } else {
            this.categoriesUnselectedWithElement.delete(selectedCat.name);
            this.categoriesUnselectedWithElementAlert.textContent = `${this._textUnselectedWithElement} ${Array.from(this.categoriesUnselectedWithElement).join(", ")}`;
          }
        });
      });
    });
  }

  /**
   * @description Get element's category and generate formDatas
   * @author Lainez Eddy
   * @private
   * @param {ISupplyListElement[]} elements
   * @memberof ModalAddSupplyListComponent
   */
  private checkAndGenerateData(elements: ISupplyListElement[]) {
    const categoriesSelected: Set<number> = new Set<number>();
    elements.forEach( element => {
      categoriesSelected.add(+element.supplyCategoryId);
    });
    const categoriesId: number[] = this.checkCategorieAfterImport(categoriesSelected);
    this.generateFormDataElement(elements, categoriesId);
  }

  /**
   * @description Load Tekla File and generate formGroups
   * @author Lainez Eddy
   * @param {File} teklaFile
   * @memberof ModalAddSupplyListComponent
   */
  public uploadTeklaFile(teklaFile: File) {
    this.tableFormLoaded = false;
    const formData: FormData = new FormData();
    formData.append("file", teklaFile);
    const snackBarTitle: string = "Upload fichier Tekla";
    this._uploadFileSrv.uploadFile(formData, UPLOAD_URLS.TEKLA).subscribe( result => {
      const resultData: { supplyList: ISupplyList} = <{ supplyList: ISupplyList}>result;
      if (resultData.supplyList) {
        this._formGroup.get("model").patchValue(resultData.supplyList.model);
        this._formGroup.get("source").patchValue(SupplyListSource.TEKLA);
        this.checkAndGenerateData(resultData.supplyList.elements);
      }
      this.formInputFileGroup.get("fileValue").patchValue(teklaFile.name);
      this.disableFormInput = true;
      this._snackBar.open(snackBarTitle, "Le fichier Tekla a bien été importé", "success", 5000);
      this.tableFormLoaded = true;
    }, error => {
      this._snackBar.openSnackBarError(snackBarTitle, "Erreur lors de l'upload du fichier", error);
      this.tableFormLoaded = true;
    });
  }

  /**
   * @description Check category's & subcategory's checkbox
   * @author Lainez Eddy
   * @private
   * @param {Set<number>} categoriesId
   * @returns
   * @memberof ModalAddSupplyListComponent
   */
  private checkCategorieAfterImport(categoriesId: Set<number>) {
    const parentCategoryToCheck: Set<number> = new Set<number>(),
          subCategoryToCheck: Set<number> = new Set<number>();
    const categoriesToSelect: ISupplyCategory[] = this._supplyCategories.filter( suppCat => Array.from(categoriesId).indexOf(+suppCat.id) !== -1);
    categoriesToSelect.forEach( cat => {
      if (cat.parentSupplyCategoryId === null) {
        parentCategoryToCheck.add(+cat.id);
      } else {
        parentCategoryToCheck.add(+cat.parentSupplyCategoryId);
        subCategoryToCheck.add(+cat.id);
      }
    });
    this.categoryFormArray.controls.forEach( catForm => {
      if (Array.from(parentCategoryToCheck).indexOf(catForm.get("id").value) !== -1) {
        if (!catForm.get("selected").value) {
          catForm.get("selected").patchValue(true);
        }
      }
    });
    Array.from(parentCategoryToCheck).forEach( parentCatId => {
      const subCat = this.subCategoryFormsArray.find( subCatObject => subCatObject.parentId === parentCatId );
      if (subCat) {
        subCat.form.controls.forEach( subCatForm => {
          if (Array.from(subCategoryToCheck).indexOf(subCatForm.get("id").value) !== -1) {
            subCategoryToCheck.delete(subCatForm.get("id").value);
            if (!subCatForm.get("selected").value) {
              subCatForm.get("selected").patchValue(true);
            }
          }
        });
      }
    });

    return Array.from(parentCategoryToCheck);
  }

  /**
   * @description Change selected tab
   * @author Lainez Eddy
   * @param {ITabs[]} value
   * @memberof ModalAddSupplyListComponent
   */
  public changeTab(value: ITabs[]) {
    this.tableFormLoaded = false;
    this.tabsData = value;
    this.filterTable = "";
    this.lastUpdatedCategoryId = null;
    setTimeout(() => {
      this.getSubcategoryIndexToPrint();
    } , 200 );
  }

  /**
   * @description Define which category must to be printed
   * @author Lainez Eddy
   * @private
   * @memberof ModalAddSupplyListComponent
   */
  private getSubcategoryIndexToPrint() {
    const selectedTab: ITabs = this.tabsData.find(tab => tab.isActive);
    if (selectedTab) {
      this.subCategoryIndexSelected = this.subCategoryFormsArray.findIndex( subCat => subCat.parentId === +selectedTab.value);
      this.categoryFormSelected = this.categoriesData.find( catData => catData.id === +selectedTab.value);
    }
    this.tableFormLoaded = true;
  }

  /**
   * @description Dispatch elements from Tekla file and generate formGroup in each category's formArray (excepted for elements from undefined category)
   * @author Lainez Eddy
   * @private
   * @param {ISupplyListElement[]} elements
   * @param {number[]} selectedCat
   * @memberof ModalAddSupplyListComponent
   */
  private generateFormDataElement(elements: ISupplyListElement[], selectedCat: number[]) {
    const undefinedElementList: Set<string> = new Set<string>();
    const neededFormArray: ISupplyFormData[] = this.categoriesData.filter( catForms => selectedCat.indexOf(catForms.id) !== -1);
    const dataToSet: {category: any, subCategory: any} = {
      category: {},
      subCategory: {}
    };
    elements.forEach(element => {
      if (element.supplyCategoryId !== null) {
        if (neededFormArray.some( form => form.id === +element.supplyCategoryId )) {
          if (!dataToSet.category[element.supplyCategoryId]) {
            dataToSet.category[element.supplyCategoryId] = [];
          }
          dataToSet.category[element.supplyCategoryId].push(element);
        } else {
          if (!dataToSet.subCategory[element.supplyCategoryId]) {
            dataToSet.subCategory[element.supplyCategoryId] = [];
          }
          dataToSet.subCategory[element.supplyCategoryId].push(element);
        }
      } else {
        // Stock undefined Elements to get them when we need to save the supply list
        const elem: any = {...element};
        elem.supplyCategoryId = null;
        delete elem.supplyListId;
        delete elem.__typename;
        this.undefinedElements.push(elem);
        undefinedElementList.add(elem.reference);
      }
    });
    Object.keys(dataToSet.category).forEach( categoryKey => {
      const categoryForm: ISupplyFormData = this.categoriesData.find( catForm => catForm.id === +categoryKey);
      this.generateFormDataByAttributs(categoryForm, dataToSet.category[categoryKey]);
    });

    Object.keys(dataToSet.subCategory).forEach( categoryKey => {
      const category: ISupplyCategory = this._supplyCategories.find( cat => +cat.id === +categoryKey ),
            categoryForm: ISupplyFormData = this.categoriesData.find( catForm => catForm.id === +category.parentSupplyCategoryId),
            subCategoryForm: ISupplyFormData = categoryForm.subCategories.find( subCatForm => subCatForm.id === +categoryKey);
      this.generateFormDataByAttributs(subCategoryForm, dataToSet.subCategory[categoryKey]);
    });

    if (undefinedElementList.size > 0) {
      this.undefinedElementAlert.textContent = `${this._textUndefined[0]} ${this._textUndefined[1]} ${Array.from(undefinedElementList).join(", ")}. ${this._textUndefined[2]}`;
    }

  }

  /**
   * @description Set saved datas in formGroup
   * @author Lainez Eddy
   * @private
   * @param {ISupplyFormData} categoryForm
   * @param {ISupplyListElement[]} elements
   * @memberof ModalAddSupplyListComponent
   */
  private generateFormDataByAttributs(categoryForm: ISupplyFormData, elements: ISupplyListElement[]) {
    elements.forEach( element => {
      categoryForm.data.push(this.createFormControl(categoryForm, element));
    });
  }


  /**
   * @description Create the form group with all fields linked to the category (with validators) and set the element's datas passes as parameter. If no value, set default value
   * @author Lainez Eddy
   * @private
   * @param {ISupplyFormData} categoryForm
   * @param {(ISupplyListElement | any)} [element={}]
   * @returns
   * @memberof ModalAddSupplyListComponent
   */
  private createFormControl(categoryForm: ISupplyFormData, element: ISupplyListElement | any = {}) {
    const categoryAttributs: any = categoryForm.dataAttributs;
    const data: FormGroup = this._fb.group({});
    let formControlAttr: FormControl;
    let value: any;
    let specialKey: string[];
    let attrName: string;
    if (element.id) {
      data.addControl("id", new FormControl(element.id));
    }
    categoryAttributs.forEach( attr => {
      value = FIELDS_DEFAULT_VALUE[attr.type] !== undefined ? FIELDS_DEFAULT_VALUE[attr.type] : null;
      specialKey = Object.keys(FIELDS_SPECIAL_KEY).filter( key => FIELDS_SPECIAL_KEY[key].indexOf(attr.name) !== -1);
      if (specialKey.length > 0) {
        attrName = specialKey[0];
        value = this.getSpecialKeyValue(attr.name, element[attr.name], data.get(attrName) === null);
      } else {
        attrName = attr.name;
        value = (element[attr.name] !== undefined) ? element[attr.name] : value;
      }

      if (attr.nullable || attr.name === "elementId" || attr.name === "matterId") {
        formControlAttr = new FormControl(value);
      } else {
        formControlAttr = new FormControl(value, Validators.required);
      }
      // Use to define the option
      if (data.get(attrName) && value) {
        data.get(attrName).patchValue(value);
      }
      data.addControl(attrName, formControlAttr);

      if (this.checkPlateCategory(+categoryForm.id) && !value) {
        this.setDefaultPlateFormControlData(data, attrName, categoryForm);
      }
    });
    return data;
  }

  /**
   * @description Set Default value for new plate data
   * @author Lainez Eddy
   * @private
   * @param {FormGroup} data
   * @param {string} attrName
   * @param {ISupplyFormData} categoryForm
   * @memberof ModalAddSupplyListComponent
   */
  private setDefaultPlateFormControlData(data: FormGroup, attrName: string, categoryForm: ISupplyFormData) {
    if (attrName === "quantityUnit") {
      data.get(attrName).patchValue(EnumQuantityUnit.TON);
    }
    if (attrName === "matterRef" && categoryForm.options.matterRef[0]) {
      data.get(attrName).patchValue(categoryForm.options.matterRef[0].label);
    }
    if (attrName === "matterId" && categoryForm.options.matterRef[0]) {
      data.get(attrName).patchValue(categoryForm.options.matterRef[0].value);
    }
  }

  /**
   * @description Use to define the default value of option group
   * @author Lainez Eddy
   * @private
   * @param {string} key
   * @param {*} value
   * @param {boolean} [isFirst=false]
   * @returns
   * @memberof ModalAddSupplyListComponent
   */
  private getSpecialKeyValue(key: string, value: any, isFirst: boolean = false) {
    let newValue;
    switch (key) {
      case "isBlack":
      case "isBlasted":
      case "isPrimaryBlasted":
        newValue = value || isFirst ? this.fieldsConfig[key].options[0].value : null;
        break;
      default:
        newValue = null;
        break;
    }
    return newValue;
  }

  /**
   * @description Push the "newData" FormGroup in the data FormArray of the category and create a new FormGroup for the "newData" attribute
   * @author Lainez Eddy
   * @param {{ data: FormGroup, categoryId: number}} eventData
   * @memberof ModalAddSupplyListComponent
   */
  public addCategoryData(eventData: { data: FormGroup, categoryId: number}) {
    this.lastUpdatedCategoryId = +eventData.categoryId;
    const category: ISupplyCategory = this._supplyCategories.find( cat => +cat.id === +eventData.categoryId );
    let parentCategoryForm: ISupplyFormData,
        categoryForm: ISupplyFormData;
    if (category.parentSupplyCategoryId) {
      parentCategoryForm = this.categoriesData.find( catForm => catForm.id === +category.parentSupplyCategoryId);
      categoryForm = parentCategoryForm.subCategories.find( subCatForm => subCatForm.id === +eventData.categoryId);
    } else {
      categoryForm = this.categoriesData.find( catForm => catForm.id === +category.id);
    }
    const newElementValue = this.setTrueElementData({...eventData.data.value});
    const oldElementOptions: any = newElementValue.isBlack === undefined ? {} : {
      isBlack: newElementValue.isBlack,
      isBlasted: newElementValue.isBlasted,
      isPrimaryBlasted: newElementValue.isPrimaryBlasted,
    };
    categoryForm.data.push(this.createFormControl(categoryForm, newElementValue));
    categoryForm.newData = null;
    setTimeout(() => {
      categoryForm.newData = this.createFormControl(categoryForm, oldElementOptions);
    }, 200);
    this._snackBar.open("Ajout d'un élémént", "L'élément a bien été ajouté", "success", 3000);
  }

  /**
   * @description Delete a row in a parent or child category's FormArray (attr "data")
   * @author Lainez Eddy
   * @param {{ rowId: string, categoryId: number}} eventData
   * @memberof ModalAddSupplyListComponent
   */
  public deleteCategoryData(eventData: { rowId: string, categoryId: number}) {
    const category: ISupplyCategory = this._supplyCategories.find( cat => +cat.id === +eventData.categoryId );
    let parentCategoryForm: ISupplyFormData,
        categoryForm: ISupplyFormData;
    if (category.parentSupplyCategoryId) {
      parentCategoryForm = this.categoriesData.find( catForm => catForm.id === +category.parentSupplyCategoryId);
      categoryForm = parentCategoryForm.subCategories.find( subCatForm => subCatForm.id === +eventData.categoryId);
    } else {
      categoryForm = this.categoriesData.find( catForm => catForm.id === +category.id);
    }
    categoryForm.data.removeAt(+eventData.rowId);
    this._snackBar.open("Suppression d'un élémént", "L'élément a bien été supprimé", "success", 3000);
  }

  /* START SUGGESTS METHODS */

  /**
   * @description Set elementId & matterId at null until the user select an element of the suggest
   * @private
   * @memberof ModalAddSupplyListComponent
   */
  private searchReference = (data: {isSearch: boolean, value: number, label: string, formGroup: FormGroup, matterId: number, addedData: any}) => {
    if (data.isSearch) {
      data.formGroup.get("elementId").patchValue(null);
    } else {
      const reference: string = data.formGroup.get("reference").value;
      if (reference) {
        const category: ISupplyCategory = this._supplyCategories.find( supplyCategory => +supplyCategory.id === data.addedData.index);
        if (category && category.elementGroup && category.elementGroup.elements) {
          const element: IElements = category.elementGroup.elements.find(elem => elem.name === reference);
          data.formGroup.get("elementId").patchValue(element ? +element.id : null);
        }
      }
    }
  }

  /**
   * @description Set elementId & matterId at null until the user select an element of the suggest
   * @private
   * @memberof ModalAddSupplyListComponent
   */
  private searchMatter = (data: {isSearch: boolean, value: number, label: string, formGroup: FormGroup, addedData: any, options: ISelectOption[]}) => {
    if (data.isSearch) {
      const matching = this.findMatchingMatter(data.formGroup.get("matterRef").value, data.options);
      data.formGroup.get("matterId").patchValue(!!matching ? matching.value : null);
    } else {
      const matterRef: string = data.formGroup.get("matterRef").value;
      if (matterRef) {
        data.formGroup.get("matterId").patchValue(data.value);
      }
    }
  }

  /**
   * @description Search for matching matter from available options
   * @author Quentin Wolfs
   * @private
   * @param {string} search
   * @param {ISelectOption[]} options
   * @returns {ISelectOption}
   * @memberof ModalAddSupplyListComponent
   */
  private findMatchingMatter(search: string, options: ISelectOption[]): ISelectOption {
    return options ? options.find(option => new RegExp(`^${option.label}.*$`).test(search)) : undefined;
  }

  /**
   * @description Set matterId at null until the user select an element of the suggest
   * @private
   * @memberof ModalAddSupplyListComponent
   */
  private searchMatterForPlate = (data: {isSearch: boolean, value: number, label: string, formGroup: FormGroup, addedData: any, options: ISelectOption[]}) => {
    if (data.isSearch) {
      const matching = this.findMatchingMatter(data.formGroup.get("matterRef").value, data.options);
      data.formGroup.get("matterId").patchValue(!!matching ? matching.value : null);
    } else {
      data.formGroup.get("matterId").patchValue(data.value);
    }
  }

  /* END SUGGESTS METHODS */

  /**
   * @description Check if the reference and the metterRef are define and if they have a match of a DB element to set the elementId and matterId value
   * @author Lainez Eddy
   * @private
   * @param {FormGroup} formGroup
   * @param {number} categoryId
   * @memberof ModalAddSupplyListComponent
   */
  private setElementAndMatterId(formGroup: FormGroup, categoryId: number) {
    const reference: string = formGroup.get("reference").value,
          matterRef: string = formGroup.get("matterRef").value;
    if (reference && matterRef) {
      const category: ISupplyCategory = this._supplyCategories.find( supplyCategory => +supplyCategory.id === categoryId);
      if (category && category.elementGroup && category.elementGroup.elements) {
        const element: IElements = category.elementGroup.elements.find( elem => (elem.matter.en1090Name === matterRef || elem.matter.name === matterRef) && elem.name === reference);
        formGroup.get("elementId").patchValue(element ? +element.id : null);
        formGroup.get("matterId").patchValue(element ? +element.matterId : null);
      }
    }
  }

  /**
   * @description Get all category & subCategory selected AND Return an array of ISupplyFormData (category with datas)
   * @author Lainez Eddy
   * @private
   * @returns {ISupplyFormData[]}
   * @memberof ModalAddSupplyListComponent
   */
  private getAllCategoriesDataSelected(): ISupplyFormData[] {
    const categoriesId: number[] = this.categoryFormArray.value.filter( cat => cat.selected).map(cat => cat.id),
          subCategoriesId: number[] = this.subCategoryFormsArray
            .filter( subCat => categoriesId.indexOf(subCat.parentId) !== -1).map( subCat => subCat.form )
            .map( subCatFormArray => subCatFormArray.value.filter( value => value.selected).map(cat => cat.id)).flat();
    const categoriesData: ISupplyFormData[] = this.categoriesData.filter( catData => categoriesId.indexOf(+catData.id) !== -1),
          subCategoriesData: ISupplyFormData[] = categoriesData.map( catData => catData.subCategories
            .filter( subCatData => subCategoriesId.indexOf(subCatData.id) !== -1)).flat();
    return categoriesData.concat(subCategoriesData);
  }

  /**
   * @description Return an array of element to save
   * @author Lainez Eddy
   * @private
   * @returns {ISupplyListElement[]}
   * @memberof ModalAddSupplyListComponent
   */
  private formatDataToSave(): ISupplyListElement[] {
    const allCategoryDatas: ISupplyFormData[] = this.getAllCategoriesDataSelected();
    const elements: ISupplyListElement[][] = [];
    let elementData: ISupplyListElement;
    allCategoryDatas.forEach( cat => {
      elements.push(cat.data.value.map( element => {
        elementData = this.setTrueElementData({...element});
        elementData.supplyCategoryId = cat.id;
        return elementData;
      }));
    });
    elements.push(this.undefinedElements);
    return elements.flat();
  }

  /**
   * @description Use to define the option selected
   * @author Lainez Eddy
   * @private
   * @param {ISupplyListElement} element
   * @returns
   * @memberof ModalAddSupplyListComponent
   */
  private setTrueElementData(element: ISupplyListElement) {
    if (element.elementOptions) {
      element.isBlack = element.elementOptions === "isBlack";
      element.isBlasted = element.elementOptions === "isBlasted";
      element.isPrimaryBlasted = element.elementOptions === "isPrimaryBlasted";
      delete element.elementOptions;
    }
    return element;
  }

  public filterSearch(event: string) {
    this.filterTable = event;
  }

  /**
   * @description Check if all categories's form are valid and save Or display error
   * @author Lainez Eddy
   * @memberof ModalAddSupplyListComponent
   */
  public confirmModal(): void {
    const allCategoryDatas: ISupplyFormData[] = this.getAllCategoriesDataSelected();
    if ( this._formGroup.valid && allCategoryDatas.every( cat => cat.data.valid)) {
        this.save();
    } else {
        this.markFormGroupTouched(this._formGroup);
        allCategoryDatas.filter( cat => !cat.data.valid).forEach( cat => {
          cat.data.controls.filter(formGroup => !formGroup.valid).forEach( (formGroup, index) => {
            const formGroupObject: FormGroup = <FormGroup>formGroup;
            Object.keys(formGroupObject.controls).forEach( formControl => {
              formGroup.get(formControl).updateValueAndValidity();
            });
          });
        });
        this.displayErrorMessage();
    }
  }

  public changeStatus() {
    this.showContent = !this.showContent;
  }

  protected save(): void {
    const data: ISupplyListInput = {
      ...this._formGroup.value,
      elements: this.formatDataToSave()
    };
    if (data.source === null) {
      data.source = SupplyListSource.MANUAL;
    }
    delete (<any>data).createdAt;
    if (!this._supplyListData) {
      this.createSupplyList(data);
    } else {
      this.updateSupplyList(data);
    }
  }

  /**
   * @description Create supply list and send new supplyList in the afterClose subject
   * @author Lainez Eddy
   * @private
   * @param {ISupplyListInput} data
   * @memberof ModalAddSupplyListComponent
   */
  private createSupplyList(data: ISupplyListInput) {
    const snackBarTitle: string = "Ajout d'une liste d'approvisionnement";
    this._projectsQueriesSrv.createSupplyList(data, this._data.data.projectId).subscribe(result => {
      const resultData: any = result.data;
      if (resultData && resultData.createSupplyList) {
        const supplyList: ISupplyList = resultData.createSupplyList;
        this.closeModalWithData(supplyList, false);
        this._snackBar.open(snackBarTitle, "La liste d'approvisionnement a été créée", "success", 5000);
      } else {
        this._snackBar.openSnackBarError(snackBarTitle, "La liste d'approvisionnement n'a pas été créée");
      }
    }, error => {
      this._snackBar.openSnackBarError(snackBarTitle, "La liste d'approvisionnement n'a pas été créée", error);
    });
  }

  /**
   * @description Update supply list and send the new supplyList's data in the afterClose subject
   * @author Lainez Eddy
   * @private
   * @param {ISupplyListInput} data
   * @memberof ModalAddSupplyListComponent
   */
  private updateSupplyList(data: ISupplyListInput) {
    const snackBarTitle: string = "Mise à jour d'une liste d'approvisionnement";
    this._projectsQueriesSrv.updateSupplyList(this._supplyListData.id, data).subscribe(result => {
      const resultData: any = result.data;
      if (resultData && resultData.updateSupplyList) {
        const supplyList: ISupplyList = resultData.updateSupplyList;
        this.closeModalWithData(supplyList);
        this._snackBar.open(snackBarTitle, "La liste d'approvisionnement a été mise à jour", "success", 5000);
      } else {
        this._snackBar.openSnackBarError(snackBarTitle, "La liste d'approvisionnement n'a pas été mise à jour");
      }
    }, error => {
      this._snackBar.openSnackBarError(snackBarTitle, "La liste d'approvisionnement n'a pas été mise à jour", error);
    });
  }

  /**
   * @description Send new data in project detail
   * @author Lainez Eddy
   * @private
   * @param {ISupplyList} supplyListData
   * @param {boolean} [isEdited=true]
   * @memberof ModalAddSupplyListComponent
   */
  private closeModalWithData(supplyListData: ISupplyList, isEdited: boolean = true) {
    const objData = {
      confirm: true,
      data: supplyListData,
      isEdited
    };
    this.afterClosed.next(objData);
    this._modalService.closeModal();
  }

}
