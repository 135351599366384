import { Matter } from "app/facade/interfaces";
import { Element } from "../../element.base";
import { IWeldiable } from "app/facade/quote-element-definition/assemblages/welding";
import { SQUARE_MM_TO_SQUARE_M, MM_TO_M } from "app/facade/quote-element-definition/base";

export interface ToleNatureValues {
    width: number;
    height: number;
    margin: number;
    Thickness: number;
}

export interface ToleProperty {
    length: number;
    matter: Matter;
    angle1?: number;
    angle2?: number;
}

export class Tole extends Element implements IWeldiable {

    public constructor(name: string,
                       reference: string,
                       protected values: ToleNatureValues,
                       protected properties: ToleProperty) {
        super(name, reference);
    }

    public get Matter(): Matter {
        return this.properties.matter;
    }

    public set Properties(properties: ToleProperty) {
        this.properties = { ...properties };
        this.elementUpdated();
    }

    public set Values(values: ToleNatureValues) {
        this.values = { ...values };
        this.elementUpdated();
    }

    public get CalculatedPrice(): number {
        return this.matterPrice;
    }

    public get Area(): number {
        return this.values.width * this.values.height;
    }

    public get AreaWithMargin(): number {
        return (this.values.width + (this.values.margin * 2)) * (this.values.height + (this.values.margin * 2));
    }

    public get TotalArea(): number {
        return this.Area + (4 * this.thickness);
    }

    public get TotalWeight(): number {
        const volume: number = (this.AreaWithMargin * SQUARE_MM_TO_SQUARE_M) * (this.thickness * MM_TO_M);
        const liter: number = volume * 1000;
        const { kgByLiter } = this.properties.matter;
        return liter / kgByLiter;
    }

    public get matterPrice(): number {
        const { pricePerKg } = this.properties.matter;
        return this.TotalWeight * pricePerKg;
    }

    public get thickness(): number {
        return this.values.Thickness;
    }

    public get Angle1(): number {
      return this.properties.angle1;
    }

    public set Angle1(value: number) {
      if (value === null) {
        delete this.properties.angle1;
      } else {
        this.properties.angle1 = value;
      }
    }

    public get Angle2(): number {
      return this.properties.angle2 || 90;
    }

    public set Angle2(value: number) {
      if (value === null) {
        delete this.properties.angle2;
      } else {
        this.properties.angle2 = value;
      }
    }
}