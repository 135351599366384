import { Component, OnInit, Inject } from "@angular/core";
import { FormBuilder, Validators, FormGroup } from "@angular/forms";
import { StepFormService, CONTAINER_DATA, ActualData } from "../../../service/step-form.service";
import { CatalogQueriesService } from "app/facade/queries/catalog/async/catalog_queries.service";
import { StandardElementService } from "app/facade/quote-element-definition/elements/standard/element.service";
import { deepClone } from "@lib/misc/clone";
import { Matter } from "app/facade/interfaces";
import { Subscription } from "rxjs";
import { StandardElementFormulaDisplayer } from "app/facade/quote-element-definition/formula.display";

@Component({
  selector: "app-element-dimension",
  templateUrl: "./element-dimension.component.html",
  styleUrls: ["./element-dimension.component.css"]
})
export class ElementDimensionComponent implements OnInit {
  public newElement;
  public elementForm: FormGroup;
  public card: any;
  private _matterSelected: Matter;
  public imgPath;
  public optionsSelected = [];
  private _actualData: ActualData;
  private _elementData: any;
  private _previousSelectedReference: string = "";

  private _formulaDisplayer: StandardElementFormulaDisplayer;
  private _formulaSub: Subscription;
  public calculs: string[];
  public autoSelect: boolean;

  constructor(@Inject(CONTAINER_DATA) data: any,
    private _stepFormSrv: StepFormService,
    private _catalogQueriesSrv: CatalogQueriesService,
    private _elementSrv: StandardElementService,
    protected _fb: FormBuilder) {
      this._actualData = this._stepFormSrv.get();
      this._elementData = this._actualData.element;
      this.card = this._actualData.elementGroup ? this._actualData.elementGroup : { id: 1 };
      this._matterSelected = this._actualData.matter;
      this.autoSelect = this._elementData !== undefined;
  }

  ngOnInit() {
    this.initForm();
    let searchReference: string = "";

    if (this._elementData && this._elementData.constructor.name === this._actualData.useClass) {
      searchReference = this._actualData.element.Reference;
    }
    this._previousSelectedReference = searchReference;
    this.getElement(searchReference, searchReference !== "");
    this.getMyPicture(this.card);
    this.calculs = [];
    this._formulaDisplayer = new StandardElementFormulaDisplayer();
    this._formulaSub = this._formulaDisplayer.$standardElementPrice.subscribe(calculs => this.calculs = calculs);
    this.showCalcul();
  }

  private initForm() {
    let matterValue: number = this._actualData.matter ? this._actualData.matter.pricePerKg : null;
    if (this._elementData && this._matterSelected.name === this._elementData.properties.matter.name) {
      matterValue = this._elementData.properties.matter.pricePerKg;
    }
    this.elementForm = this._fb.group({
      quantity: [this._elementData ? this._elementData.Quantity : null, Validators.compose([
        Validators.required,
        Validators.min(1)
      ])],
      denomination: this._elementData ? this._elementData.CustomName : null,
      reference: [null, Validators.required],
      lengthElement: [this._elementData ? this._elementData.properties.length : null, Validators.required],
      matterPrice: [matterValue, Validators.required],
      overallAdditionalCost: this._elementData ? this._elementData.GlobalAdditionalCost : null,
      additionalUnitCost: this._elementData ? this._elementData.UnitaryAdditionalCost : null,
      additionalInfo: this._elementData ? this._elementData.Remarks : null
    });

    setTimeout(() => {
      this.elementForm.get("reference").valueChanges.subscribe(result => {
        if (result) {
          const oldDenomination = this.elementForm.get("denomination").value ? this.elementForm.get("denomination").value : "";
          let newRef: string;
          if (this._previousSelectedReference && oldDenomination.search(this._previousSelectedReference) !== -1) {
            newRef = oldDenomination.replace(this._previousSelectedReference, result.name);
          } else {
            newRef = result.name;
          }
          this._previousSelectedReference = result.name;
          this.elementForm.get("denomination").patchValue(newRef);
        }
      });
    }, 1000);
  }

  public stepChange(addElement: boolean = true) {
    const reference = this.elementForm.get("reference").value;
    const updatedMatter = deepClone(this._actualData["matter"]);
    updatedMatter.pricePerKg = this.elementForm.get("matterPrice").value;
    let properties = {
      length: this.elementForm.get("lengthElement").value,
      matter: updatedMatter
    };
    let elementIndex: number;
    if (this._actualData.isEdited) {
      properties = { ...this._actualData.element.properties, ...properties };
      if (this._elementData.constructor.name === this._actualData.useClass && reference.name === this._elementData.Reference) {
        this.newElement = this.updateElementObject(this._elementData, reference, properties);
        this._stepFormSrv.updateElement(this.newElement, null, this._actualData.index);
      } else {
        this.newElement = this.createElementObject(reference, properties);
        this._stepFormSrv.replaceElement(this.newElement, this._actualData.index);
      }
      elementIndex = this._actualData.index;
    } else {
      this.newElement = this.createElementObject(reference, properties);
      if (addElement) {
        this._stepFormSrv.addElement(this.newElement);
        elementIndex = this._stepFormSrv.getLastElementSelected();
      }
    }
    this._stepFormSrv.change({
      element: this.newElement,
      index: elementIndex
    });
  }

  public next(goToSummary: boolean = true) {
    if (this.elementForm.valid) {
      this.stepChange();
      if (goToSummary) {
        this._stepFormSrv.navigate$.next(true);
      } else {
        this._stepFormSrv.closeModal$.next(true);
      }
    } else {
      this._stepFormSrv.markFormGroupTouched(this.elementForm);
    }
  }

  public previous() {
    if (this.elementForm.valid) {
      this.stepChange(false);
    }
    this._stepFormSrv.navigate$.next(false);
  }

  public cancel() {
    if (!this._actualData.isEdited && this._actualData.index !== undefined) {
      this._stepFormSrv.deleteElement(this._actualData.index);
    }
    this._stepFormSrv.closeModal$.next(true);
  }

  public searchElement(event) {
    this.getElement(event);
  }

  public getElement(search: string = "", init: boolean = false) {
    this._catalogQueriesSrv.searchElement(search, +this.card.id, +this._matterSelected.id).subscribe(result => {
      const elementOption: any = result.data;
      this.optionsSelected = elementOption.searchElement.map(element => {
        return {
          label: element.name,
          value: element,
        };
      });
      if (init) {
        this.optionsSelected = this.optionsSelected.filter(element => element.label === search);
      }
    });
  }

  public getElementGroupById(id: number) {
    if (id) {
      this._catalogQueriesSrv.getElementGroupById(id).subscribe(result => {
        this.card = result.data["getElementGroupById"];
      });
    }
  }

  public getMyPicture(folder: string) {
    this.imgPath = "../../../../../../../../assets/img/catalog/" + folder["icon"] + "_mini.jpg";
  }

  public showCalcul() {
    this.elementForm.valueChanges.subscribe(values => {
      const base = {
        kgByMeter: values.reference && values.reference.natureValues ? (values.reference.natureValues.Kg_m || 0) : null,
        length: values.lengthElement || null,
        priceByKg: values.matterPrice || null,
        kgByLiter: this._actualData && this._actualData.matter ? this._actualData.matter.kgByLiter : null,
        useClass: this._actualData ? this._actualData.useClass : null,
        section: values.reference && values.reference.natureValues ? (values.reference.natureValues.A || 0) : null
      };
      this._formulaDisplayer.base = base;
    });

    const init = {
      kgByMeter: this._elementData && this._elementData.values ? (this._elementData.values.Kg_m || null) : null,
      length: this.elementForm.value.lengthElement || null,
      priceByKg: this.elementForm.value.matterPrice || null,
      kgByLiter: this._actualData && this._actualData.matter ? this._actualData.matter.kgByLiter : null,
      useClass: this._actualData ? this._actualData.useClass : null,
      section: this.optionsSelected && this.optionsSelected.length == 1 ? (this.optionsSelected[0].value.natureValues.A || 0) : null
    };

    this._formulaDisplayer.base = init;
  }

  /**
   * createElement
   */
  public createElementObject(reference: any, properties: any) {
    const elementObj = this._elementSrv.create(this._actualData.useClass, reference.name, {
      values: reference.natureValues,
      properties: properties,
      reference: reference.name,
    });

    elementObj.CustomName = this.elementForm.get("denomination").value;
    elementObj.Quantity = this.elementForm.get("quantity").value;
    elementObj.Remarks = this.elementForm.get("additionalInfo").value;
    elementObj.GlobalAdditionalCost = this.elementForm.get("overallAdditionalCost").value;
    elementObj.UnitaryAdditionalCost = this.elementForm.get("additionalUnitCost").value;

    return elementObj;

  }

  public updateElementObject(elementUpdated: any, reference: any, properties: any) {
    const elementObjUpdated = elementUpdated;

    elementObjUpdated.Values = reference.natureValues;
    elementObjUpdated.Reference = reference.name;
    elementObjUpdated.Properties = properties;
    elementObjUpdated.CustomName = this.elementForm.get("denomination").value;
    elementObjUpdated.Quantity = this.elementForm.get("quantity").value;
    elementObjUpdated.Remarks = this.elementForm.get("additionalInfo").value;
    elementObjUpdated.GlobalAdditionalCost = this.elementForm.get("overallAdditionalCost").value;
    elementObjUpdated.UnitaryAdditionalCost = this.elementForm.get("additionalUnitCost").value;

    return elementObjUpdated;

  }
}
