import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { OrganismsModule } from "app/presentationnal/organisms/organisms.module";
import { QuoteDetailsComponent } from "./quote-details/quote-details.component";
import { ListingQuoteComponent } from "./listing-quote/listing-quote.component";
import { CreateQuoteComponent } from "./create-quote/create-quote.component";
import { QuotesQueriesService } from "app/facade/queries/quote/async/quotes_queries.service";
import { QuotesProjectQueriesService } from "app/facade/queries/quote-project/async/quotes-project-queries.service";

@NgModule({
  imports: [
    CommonModule,
    OrganismsModule
  ],
  declarations: [
    QuoteDetailsComponent,
    ListingQuoteComponent,
    CreateQuoteComponent
  ],
  providers: [
    QuotesQueriesService,
    QuotesProjectQueriesService
  ]
})
export class QuotesModule { }
