import { Injectable } from "@angular/core";
import { Apollo } from "apollo-angular";
import { IPurchaseOrderFromSupplierOfferInput, IPurchaseOrderSort, IPurchaseOrderFromProjectInput, IPurchaseOrderUpdate, IPurchaseOrderElementInput, IPurchaseOrderElementUpdate, IPurchaseOrderAdditionnalCostInput, IPurchaseOrderAdditionnalCostUpdate, IPurchaseOrderElementUpdateRemark, IPurchaseOrderElementUpdateFormat, IPurchaseOrderElementUpdateDenomination, IPurchaseOrderElementUpdateQuantity, IPurchaseOrderElementUpdateMatiere, IPurchaseOrderElementUpdateWeight, IPurchaseOrderElementUpdateWidth, IPurchaseOrderElementUpdateLength, IPurchaseOrderElementUpdateThickness, IPurchaseOrderElementUpdateQuantityUnit, IPurchaseOrderElementUpdateEn1090, IPurchaseOrderElementUpdateOption, IPurchaseOrderElementUpdatePrice, IPurchaseOrderElementUpdateDeliveryDate } from "app/facade/interfaces/purchase-order.interface";
import { CREATE_PURCHASE_ORDER_FROM_SUPPLIER_OFFER, PURCHASE_ORDERS, GET_PURCHASE_ORDER_REFERENCE, FREE_PURCHASE_ORDER_REFERENCE, CREATE_PURCHASE_ORDER_FROM_PROJECT, DELETE_PURCHASE_ORDER, PURCHASE_ORDER, UPDATE_PURCHASE_ORDER, SEND_PURCHASE_ORDER_MAIL, CREATE_PURCHASE_ORDER_ELEMENT, UPDATE_PURCHASE_ORDER_ELEMENT, DELETE_PURCHASE_ORDER_ELEMENT, PURCHASE_ORDERS_BY_PROJECT, CREATE_PURCHASE_ORDER_ADDITIONNAL_COST, UPDATE_PURCHASE_ORDER_ADDITIONNAL_COST, DELETE_PURCHASE_ORDER_ADDITIONNAL_COST, UPDATE_PURCHASE_ORDER_ELEMENT_REMARK, SINGLE_PURCHASE_ORDER, UPDATE_PURCHASE_ORDER_ELEMENT_FORMAT, UPDATE_PURCHASE_ORDER_ELEMENT_DENOMINATION, UPDATE_PURCHASE_ORDER_ELEMENT_QUANTITY, UPDATE_PURCHASE_ORDER_ELEMENT_MATIERE, UPDATE_PURCHASE_ORDER_ELEMENT_WEIGHT, UPDATE_PURCHASE_ORDER_ELEMENT_WIDTH, UPDATE_PURCHASE_ORDER_ELEMENT_LENGTH, UPDATE_PURCHASE_ORDER_ELEMENT_THICKNESS, UPDATE_PURCHASE_ORDER_ELEMENT_QUANTITY_UNIT, UPDATE_PURCHASE_ORDER_ELEMENT_EN1090, UPDATE_PURCHASE_ORDER_ELEMENT_OPTION, UPDATE_PURCHASE_ORDER_ELEMENT_PRICE, UPDATE_PURCHASE_ORDER_ELEMENT_DELIVERY_DATE } from "./purchase-order.queries";
import { GET_PRICE_REQUEST_ELEMENTS_AND_SUPPLIERS } from "../price-request/price-requests.queries";
import { IPagination } from "app/facade/interfaces/crud.interface";
import { AddScanPdfInput } from "app/facade/interfaces/scan-pdf.interface";

@Injectable({
  providedIn: "root"
})
export class PurchaseOrderQueriesService {

  constructor(private _apollo: Apollo) { }

  // Get Purchase Order List
  public getPurchaseOrders(search?: string, sort?: IPurchaseOrderSort, pagination?: IPagination) {
    return this._apollo.query({
      query: PURCHASE_ORDERS,
      variables: {
        search,
        sort,
        pagination
      },
      fetchPolicy: "no-cache"
    });
  }

  // Problem with sort many time... No reload of data because of cache
  public watchPurchaseOrders(search?: string, sort?: IPurchaseOrderSort) {
    return this._apollo.watchQuery({
      query: PURCHASE_ORDERS,
      variables: {search, sort},
      fetchPolicy: "network-only"
    });
  }

  public getPurchaseOrderByProject(projectId: number, pagination?: IPagination) {
    return this._apollo.query({
      query: PURCHASE_ORDERS_BY_PROJECT,
      variables: {projectId, pagination},
      fetchPolicy: "no-cache"
    });
  }

  // Get Purchase Order Detail
  public getPurchaseOrder(id: number) {
    return this._apollo.query({
      query: PURCHASE_ORDER,
      variables: {id},
      fetchPolicy: "no-cache"
    });
  }

  public watchPurchaseOrder(id: number) {
    return this._apollo.watchQuery({
      query: PURCHASE_ORDER,
      variables: {id},
      fetchPolicy: "network-only"
    });
  }

  public getSinglePurchaseOrder(id: number) {
    return this._apollo.query({
      query: SINGLE_PURCHASE_ORDER,
      variables: { id },
      fetchPolicy: "no-cache"
    });
  }

  // Create & Update Purchase Order
  public createPurchaseOrderFromSupplierOffer(data: IPurchaseOrderFromSupplierOfferInput[], priceRequestId: number) {
    return this._apollo.mutate({
      mutation: CREATE_PURCHASE_ORDER_FROM_SUPPLIER_OFFER,
      variables: {data},
      fetchPolicy: "no-cache",
      refetchQueries: [{
        query: GET_PRICE_REQUEST_ELEMENTS_AND_SUPPLIERS,
        variables: {id: priceRequestId}
      }]
    });
  }

  public createPurchaseOrderFromProject(data: IPurchaseOrderFromProjectInput, scanData: AddScanPdfInput[]) {
    return this._apollo.mutate({
      mutation: CREATE_PURCHASE_ORDER_FROM_PROJECT,
      variables: {data, scanData},
      fetchPolicy: "no-cache"
    });
  }

  public updatePurchaseOrder(id: number, data: IPurchaseOrderUpdate) {
    return this._apollo.mutate({
      mutation: UPDATE_PURCHASE_ORDER,
      variables: {id, data},
      fetchPolicy: "no-cache",
    });
  }

  // Delete Purchase Order
  public deletePurchaseOrder(id: number) {
    return this._apollo.mutate({
      mutation: DELETE_PURCHASE_ORDER,
      variables: {id},
      fetchPolicy: "no-cache",
      refetchQueries: [{
        query: SINGLE_PURCHASE_ORDER,
        variables: {id}
      }]
    });
  }

  // Add & Update & Delete Element
  public createPurchaseOrderElement(data: IPurchaseOrderElementInput, purchaseOrderId: number) {
    return this._apollo.mutate({
      mutation: CREATE_PURCHASE_ORDER_ELEMENT,
      variables: {data},
      fetchPolicy: "no-cache",
      refetchQueries: [{
        query: PURCHASE_ORDER,
        variables: {id: purchaseOrderId}
      }]
    });
  }

  public updatePurchaseOrderElement(id: number, data: IPurchaseOrderElementUpdate, purchaseOrderId: number) {
    return this._apollo.mutate({
      mutation: UPDATE_PURCHASE_ORDER_ELEMENT,
      variables: {id, data},
      fetchPolicy: "no-cache",
      refetchQueries: [{
        query: PURCHASE_ORDER,
        variables: {id: purchaseOrderId}
      }]
    });
  }

  public updatePurchaseOrderElementRemark(id: number, data: IPurchaseOrderElementUpdateRemark, purchaseOrderId) {
    return this._apollo.mutate({
      mutation: UPDATE_PURCHASE_ORDER_ELEMENT_REMARK,
      variables: {id, data},
      fetchPolicy: "no-cache",
      refetchQueries: [{
        query: PURCHASE_ORDER,
        variables: {id: purchaseOrderId}
      }]
    });
  }

  public updatePurchaseOrderElementFormat(id: number, data: IPurchaseOrderElementUpdateFormat, purchaseOrderId) {
    return this._apollo.mutate({
      mutation: UPDATE_PURCHASE_ORDER_ELEMENT_FORMAT,
      variables: {id, data},
      fetchPolicy: "no-cache",
      refetchQueries: [{
        query: PURCHASE_ORDER,
        variables: {id: purchaseOrderId}
      }]
    });
  }

  public updatePurchaseOrderElementDenomination(id: number, data: IPurchaseOrderElementUpdateDenomination, purchaseOrderId) {
    return this._apollo.mutate({
      mutation: UPDATE_PURCHASE_ORDER_ELEMENT_DENOMINATION,
      variables: {id, data},
      fetchPolicy: "no-cache",
      refetchQueries: [{
        query: PURCHASE_ORDER,
        variables: {id: purchaseOrderId}
      }]
    });
  }

  public updatePurchaseOrderElementMatiere(id: number, data: IPurchaseOrderElementUpdateMatiere, purchaseOrderId) {
    return this._apollo.mutate({
      mutation: UPDATE_PURCHASE_ORDER_ELEMENT_MATIERE,
      variables: {id, data},
      fetchPolicy: "no-cache",
      refetchQueries: [{
        query: PURCHASE_ORDER,
        variables: {id: purchaseOrderId}
      }]
    });
  }

  public updatePurchaseOrderElementWeight(id: number, data: IPurchaseOrderElementUpdateWeight, purchaseOrderId) {
    return this._apollo.mutate({
      mutation: UPDATE_PURCHASE_ORDER_ELEMENT_WEIGHT,
      variables: {id, data},
      fetchPolicy: "no-cache",
      refetchQueries: [{
        query: PURCHASE_ORDER,
        variables: {id: purchaseOrderId}
      }]
    });
  }

  public updatePurchaseOrderElementWidth(id: number, data: IPurchaseOrderElementUpdateWidth, purchaseOrderId) {
    return this._apollo.mutate({
      mutation: UPDATE_PURCHASE_ORDER_ELEMENT_WIDTH,
      variables: {id, data},
      fetchPolicy: "no-cache",
      refetchQueries: [{
        query: PURCHASE_ORDER,
        variables: {id: purchaseOrderId}
      }]
    });
  }

  public updatePurchaseOrderElementLength(id: number, data: IPurchaseOrderElementUpdateLength, purchaseOrderId) {
    return this._apollo.mutate({
      mutation: UPDATE_PURCHASE_ORDER_ELEMENT_LENGTH,
      variables: {id, data},
      fetchPolicy: "no-cache",
      refetchQueries: [{
        query: PURCHASE_ORDER,
        variables: {id: purchaseOrderId}
      }]
    });
  }

  public updatePurchaseOrderElementThickness(id: number, data: IPurchaseOrderElementUpdateThickness, purchaseOrderId) {
    return this._apollo.mutate({
      mutation: UPDATE_PURCHASE_ORDER_ELEMENT_THICKNESS,
      variables: {id, data},
      fetchPolicy: "no-cache",
      refetchQueries: [{
        query: PURCHASE_ORDER,
        variables: {id: purchaseOrderId}
      }]
    });
  }

  public updatePurchaseOrderElementPrice(id: number, data: IPurchaseOrderElementUpdatePrice, purchaseOrderId) {
    return this._apollo.mutate({
      mutation: UPDATE_PURCHASE_ORDER_ELEMENT_PRICE,
      variables: { id, data },
      fetchPolicy: "no-cache",
      refetchQueries: [{
        query: PURCHASE_ORDER,
        variables: { id: purchaseOrderId }
      }]
    });
  }

  public updatePurchaseOrderElementDeliveryDate(id: number, data: IPurchaseOrderElementUpdateDeliveryDate, purchaseOrderId) {
    return this._apollo.mutate({
      mutation: UPDATE_PURCHASE_ORDER_ELEMENT_DELIVERY_DATE,
      variables: { id, data },
      fetchPolicy: "no-cache",
      refetchQueries: [{
        query: PURCHASE_ORDER,
        variables: { id: purchaseOrderId }
      }]
    });
  }

  public updatePurchaseOrderElementEn1090(id: number, data: IPurchaseOrderElementUpdateEn1090, purchaseOrderId) {
    return this._apollo.mutate({
      mutation: UPDATE_PURCHASE_ORDER_ELEMENT_EN1090,
      variables: {id, data},
      fetchPolicy: "no-cache",
      refetchQueries: [{
        query: PURCHASE_ORDER,
        variables: {id: purchaseOrderId}
      }]
    });
  }

  public updatePurchaseOrderElementOption(id: number, data: IPurchaseOrderElementUpdateOption, purchaseOrderId) {
    return this._apollo.mutate({
      mutation: UPDATE_PURCHASE_ORDER_ELEMENT_OPTION,
      variables: {id, data},
      fetchPolicy: "no-cache",
      refetchQueries: [{
        query: PURCHASE_ORDER,
        variables: {id: purchaseOrderId}
      }]
    });
  }

  public updatePurchaseOrderElementQuantityUnit(id: number, data: IPurchaseOrderElementUpdateQuantityUnit, purchaseOrderId) {
    return this._apollo.mutate({
      mutation: UPDATE_PURCHASE_ORDER_ELEMENT_QUANTITY_UNIT,
      variables: {id, data},
      fetchPolicy: "no-cache",
      refetchQueries: [{
        query: PURCHASE_ORDER,
        variables: {id: purchaseOrderId}
      }]
    });
  }

  public updatePurchaseOrderElementQuantity(id: number, data: IPurchaseOrderElementUpdateQuantity, purchaseOrderId) {
    return this._apollo.mutate({
      mutation: UPDATE_PURCHASE_ORDER_ELEMENT_QUANTITY,
      variables: {id, data},
      fetchPolicy: "no-cache",
      refetchQueries: [{
        query: PURCHASE_ORDER,
        variables: {id: purchaseOrderId}
      }]
    });
  }

  public deletePurchaseOrderElement(id: number, purchaseOrderId: number) {
    return this._apollo.mutate({
      mutation: DELETE_PURCHASE_ORDER_ELEMENT,
      variables: {id},
      fetchPolicy: "no-cache",
      refetchQueries: [{
        query: PURCHASE_ORDER,
        variables: {id: purchaseOrderId}
      }]
    });
  }

  // Add & Update & Delete Additionnal Cost
  public createPurchaseOrderAdditionnalCost(data: IPurchaseOrderAdditionnalCostInput, purchaseOrderId: number) {
    return this._apollo.mutate({
      mutation: CREATE_PURCHASE_ORDER_ADDITIONNAL_COST,
      variables: {data},
      fetchPolicy: "no-cache",
      refetchQueries: [{
        query: PURCHASE_ORDER,
        variables: {id: purchaseOrderId}
      }]
    });
  }

  public updatePurchaseOrderAdditionnalCost(id: number, data: IPurchaseOrderAdditionnalCostUpdate, purchaseOrderId: number) {
    return this._apollo.mutate({
      mutation: UPDATE_PURCHASE_ORDER_ADDITIONNAL_COST,
      variables: {id, data},
      fetchPolicy: "no-cache",
      refetchQueries: [{
        query: PURCHASE_ORDER,
        variables: {id: purchaseOrderId}
      }]
    });
  }

  public deletePurchaseOrderAdditionnalCost(id: number, purchaseOrderId: number) {
    return this._apollo.mutate({
      mutation: DELETE_PURCHASE_ORDER_ADDITIONNAL_COST,
      variables: {id},
      fetchPolicy: "no-cache",
      refetchQueries: [{
        query: PURCHASE_ORDER,
        variables: {id: purchaseOrderId}
      }]
    });
  }

  // Référence
  public getPurchaseOrderReference() {
    return this._apollo.query({
      query: GET_PURCHASE_ORDER_REFERENCE,
      fetchPolicy: "no-cache"
    });
  }

  public freePurchaseOrderReference() {
    return this._apollo.mutate({
      mutation: FREE_PURCHASE_ORDER_REFERENCE,
      fetchPolicy: "no-cache"
    });
  }

  // Other
  public sendPurchaseOrder(id: number) {
    return this._apollo.mutate({
      mutation: SEND_PURCHASE_ORDER_MAIL,
      variables: {id},
      fetchPolicy: "no-cache",
      refetchQueries: [{
        query: PURCHASE_ORDER,
        variables: {id}
      }]
    });
  }
}
