/**
 * @description Provide a clone of the given object without any reference to his origin
 * @author Houtekiet Yves
 * @export
 * @template T
 * @param {T} obj
 * @param {*} [hash=new WeakMap()]
 * @returns {T}
 */
export function deepClone<T>(obj: T, hash = new WeakMap()): T {
    if (Object(obj) !== obj) { return obj; } // primitives
    if (hash.has(<any>obj)) { return hash.get(<any>obj); } // cyclic reference
    const result = obj instanceof Date ? new Date(obj)
                 : obj instanceof RegExp ? new RegExp(obj.source, obj.flags)
                 : obj.constructor ? new (<any>obj).constructor()
                 : Object.create(null);
    hash.set(<any>obj, result);
    if (obj instanceof Map) {
        Array.from(obj, ([key, val]) => result.set(key, deepClone(val, hash)) );
    }
    return Object.assign(result, ...Object.keys(obj).map (
        key => ({ [key]: deepClone(obj[key], hash) }) ));
}
