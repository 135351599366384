import { Component, OnInit, Inject, ViewChild, ElementRef, Renderer2 } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { CONTAINER_DATA, StepFormService, ActualData } from "app/presentationnal/organisms/wizard-modal/service/step-form.service";
import { OperationService } from "app/facade/quote-element-definition/operations/operation.service";
import { deepClone } from "@lib/misc/clone";
import { CuttingFormulaDisplayer } from "app/facade/quote-element-definition/formula.display";
import { Subscription } from "rxjs";

@Component({
  selector: "app-sawing-step-one",
  templateUrl: "./sawing-step-one.component.html",
  styleUrls: ["./sawing-step-one.component.css"]
})
export class SawingStepOneComponent implements OnInit {
  @ViewChild("popOverContent") public popOverContent: ElementRef;
  @ViewChild("popOver") public popOver: ElementRef;

  public dataTicket;
  public imgPath;
  public useClass;
  public popover = false;
  public elementChoose;
  public indexElement: number;
  public sawingStepOneForm: FormGroup;
  public calculs: string[];

  private _actualData: ActualData;
  private _formulaDisplayer: CuttingFormulaDisplayer;
  private _formulaSub: Subscription;
  private _dbData: any;

  constructor(@Inject(CONTAINER_DATA) data: any,
    private _stepFormSrv: StepFormService,
    private _operationSrv: OperationService,
    private renderer: Renderer2,
    protected _fb: FormBuilder) {

    this.useClass = data.useClass;
    this._actualData = this._stepFormSrv.get();
    this.indexElement = this._actualData.isEdited ? this._actualData.index : this._stepFormSrv.getLastElementSelected();
    this.dataTicket = data.ticket[this.indexElement];
    this._dbData = {};
  }

  ngOnInit() {
    this.initForm();
    this.getMyPicture(this.useClass);
    this.presetElement();
    this.calculs = [];
    this._formulaDisplayer = new CuttingFormulaDisplayer();
    this._formulaSub = this._formulaDisplayer.$cuttingPrice.subscribe(calculs => this.calculs = calculs);
    this.showCalcul();
  }

  private initForm() {
    this.sawingStepOneForm = this._fb.group({
      customName: [this._actualData.custom ? this._actualData.custom.CustomName : this.useClass, Validators.required],
      cuttingAngle: [this._actualData.custom ? this._actualData.custom.angle1 : 90, Validators.required],
      cuttingAngleTwo: this._actualData.custom && this._actualData.custom.angle2 ? this._actualData.custom.angle2 : null,
      priceBySquareCm: this._actualData.custom && this._actualData.custom.PriceBySquareCm ? this._actualData.custom.PriceBySquareCm : null,
      unitaryAdditionalCost: this._actualData.custom && this._actualData.custom.UnitaryAdditionalCost ? this._actualData.custom.UnitaryAdditionalCost : 0,
      globalAdditionalCost: this._actualData.custom && this._actualData.custom.GlobalAdditionalCost ? this._actualData.custom.GlobalAdditionalCost : 0,
      remark: this._actualData.custom && this._actualData.custom.Remarks ? this._actualData.custom.Remarks : null,
    });
    if (!this._actualData.custom) {
      this._operationSrv.getCuttingData().subscribe(data => {
        this._dbData.priceBySquareCm = data.data["searchAction"][0]["natureValues"].priceBySquareCm;
        this.sawingStepOneForm.get("priceBySquareCm").patchValue(this._dbData.priceBySquareCm);
      });
    }
  }

  public chooseElement(choice: any, init: boolean) {
    this.elementChoose = choice;
    if (!init && this._actualData.isEdited) {
      this._stepFormSrv.deleteElement(this._actualData.index, this._actualData.childrenIndex);
      this._actualData.index = this.indexElement;
      this._actualData.custom.element = choice;
      this._actualData.childrenIndex = this._stepFormSrv.addCustom(this.indexElement, this._actualData.custom);
    }
  }

  public next() {
    if (this.sawingStepOneForm && this.sawingStepOneForm.valid) {
      if (this._actualData.isEdited) {
        this._actualData.custom.angle1 = <number>this.sawingStepOneForm.get("cuttingAngle").value;
        this._actualData.custom.angle2 = <number>this.sawingStepOneForm.get("cuttingAngleTwo").value;
        this._actualData.custom.PriceBySquareCm = this.sawingStepOneForm.get("priceBySquareCm").value;
        this._actualData.custom.CustomName = this.sawingStepOneForm.get("customName").value;
        this._actualData.custom.GlobalAdditionalCost = this.sawingStepOneForm.get("globalAdditionalCost").value;
        this._actualData.custom.UnitaryAdditionalCost = this.sawingStepOneForm.get("unitaryAdditionalCost").value;
        this._actualData.custom.Remarks = this.sawingStepOneForm.get("remark").value;
        this._stepFormSrv.updateElement(this._actualData.custom, null, this._actualData.index, this._actualData.childrenIndex);
      } else {
        const properties = deepClone(this.elementChoose);
        const definedValue = {
          angle1: this.sawingStepOneForm.get("cuttingAngle").value,
          angle2: this.sawingStepOneForm.get("cuttingAngleTwo").value,
          priceBySquareCm: this.sawingStepOneForm.get("priceBySquareCm").value
        };
        this._operationSrv.create("Cutting", { element: this.elementChoose, properties: definedValue }).subscribe(saw => {
          saw.CustomName = this.sawingStepOneForm.get("customName").value;
          saw.GlobalAdditionalCost = this.sawingStepOneForm.get("globalAdditionalCost").value;
          saw.UnitaryAdditionalCost = this.sawingStepOneForm.get("unitaryAdditionalCost").value;
          saw.Remarks = this.sawingStepOneForm.get("remark").value;
          this._stepFormSrv.addCustom(this.indexElement, saw);
        });
      }
      this._stepFormSrv.closeModal$.next(true);
    } else {
      this._stepFormSrv.markFormGroupTouched(this.sawingStepOneForm);
    }
  }

  /**
   * @description Cancel the creation/Edit of the sawing operation and close the wizard
   * @author Nicolas Van Der Haeghen
   * @memberof SawingStepOneComponent
   */
  public cancel() {
    this._stepFormSrv.closeModal$.next(true);
  }

  public presetElement() {
    this.chooseElement(this.dataTicket.element, true);
  }

  public mouseOver(event) {
    const el = this.popOver.nativeElement;
    const content = this.popOverContent.nativeElement;
    // Define the position
    const posXa = el.getBoundingClientRect().right;
    const posXb = el.getBoundingClientRect().left;
    const posX = ((posXa - posXb) / 2) + posXb;
    const posY = el.getBoundingClientRect().bottom;
    // Set the position
    content.style.left = `${posX}px`;
    content.style.top = `${posY}px`;

    this.renderer.addClass(el, "popover_active");
  }

  public mouseOut(event) {
    this.renderer.removeClass(this.popOver.nativeElement, "popover_active");
  }

  public getMyPicture(folder: string) {
    this.imgPath = "../../../../../../../../assets/img/wizard/" + folder.toLowerCase() + "/step_one.jpg";
  }

  private showCalcul() {
    this.sawingStepOneForm.valueChanges.subscribe(values => {
      const base = {
        section: this.elementChoose ? this.elementChoose.section : null,
        angle1: values.cuttingAngle,
        angle2: values.cuttingAngleTwo,
        priceBySquareCm: values.priceBySquareCm
      };

      this._formulaDisplayer.base = base;
    });

    const init = {
      section: this.elementChoose ? this.elementChoose.section : null,
      angle1: this.sawingStepOneForm.value.cuttingAngle || null,
      angle2: this.sawingStepOneForm.value.cuttingAngleTwo || null,
      priceBySquareCm: this.sawingStepOneForm.value.priceBySquareCm || null
    };

    this._formulaDisplayer.base = init;
  }
}
