import { Component, OnInit, Inject, OnDestroy } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { StepFormService, CONTAINER_DATA, ActualData } from "app/presentationnal/organisms/wizard-modal/service/step-form.service";
import { OptionService } from "app/facade/quote-element-definition/options/options.service";
import { NacelleProperty, Nacelle } from "app/facade/quote-element-definition/options/nacelle";
import { OptionFormulaDisplayer } from "app/facade/quote-element-definition/formula.display";
import { Subscription } from "rxjs";

@Component({
  selector: "app-pod-step-one",
  templateUrl: "./pod-step-one.component.html",
  styleUrls: ["./pod-step-one.component.css"]
})
export class PodStepOneComponent implements OnInit, OnDestroy {
  private podStepOneForm: FormGroup;
  private _actualData: ActualData;
  private _optionData: Nacelle;
  public newPod: Nacelle;
  private _formulaDisplayer: OptionFormulaDisplayer;
  private _formulaSub: Subscription;
  public calculs: string[];

  constructor(@Inject(CONTAINER_DATA) data: any,
    private _fb: FormBuilder,
    private _stepFormSrv: StepFormService,
    private _optionSrv: OptionService) {
    this._actualData = this._stepFormSrv.get();
    this._optionData = this._actualData.element;
  }

  ngOnInit() {
    this.initform();
    this.calculs = [];
    this._formulaDisplayer = new OptionFormulaDisplayer();
    this._formulaSub = this._formulaDisplayer.$optionPrice.subscribe(calculs => this.calculs = calculs);
    this.showCalcul();
  }

  public ngOnDestroy() {
    this._formulaSub.unsubscribe();
  }

  public initform() {
    this.podStepOneForm = this._fb.group({
      personQuantity: [this._optionData ? this._optionData.PersonQuantity : 1, Validators.compose([Validators.required, Validators.min(1)])],
      dayQuantity: [this._optionData ? this._optionData.DayQuantity : 1, Validators.compose([Validators.required, Validators.min(0)])],
      hourlyRate: [this._optionData ? this._optionData.HourlyRate : 1, Validators.compose([Validators.required, Validators.min(0)])],
    });
  }

  public save() {
    if (this.podStepOneForm && this.podStepOneForm.valid) {
      if (this._actualData.isEdited) {
        this.newPod = this._optionData;
        this.newPod.HourlyRate = this.podStepOneForm.get("hourlyRate").value;
        this.newPod.PersonQuantity = this.podStepOneForm.get("personQuantity").value;
        this.newPod.DayQuantity = this.podStepOneForm.get("dayQuantity").value;

        this._stepFormSrv.updateElement(this.newPod, null, this._actualData.index);
      } else {
        const podData: NacelleProperty = {
          personQuantity: this.podStepOneForm.get("personQuantity").value,
          dayQuantity: this.podStepOneForm.get("dayQuantity").value,
          hourlyRate: this.podStepOneForm.get("hourlyRate").value,
        };
        this.newPod = this._optionSrv.create("Nacelle", podData);
        this.newPod.CustomName = "Nacelle";
        this._stepFormSrv.addElement(this.newPod);
      }
      this._stepFormSrv.closeModal$.next(true);
    }
  }

  public cancel() {
    this._stepFormSrv.closeModal$.next(true);
  }

  public showCalcul() {
    this.podStepOneForm.valueChanges.subscribe(values => {
      const base = {
        personQuantity: values.personQuantity || null,
        dayQuantity: values.dayQuantity || null,
        hourlyRate: values.hourlyRate || null
      };

      if (Object.keys(base).every(k => base[k])) {
        this._formulaDisplayer.base = base;
      }
    });

    const init = {
      personQuantity: this.podStepOneForm.value.personQuantity,
      dayQuantity: this.podStepOneForm.value.dayQuantity,
      hourlyRate: this.podStepOneForm.value.hourlyRate
    };

    if (Object.keys(init).every(k => init[k] !== undefined && init[k] !== null)) {
      this._formulaDisplayer.base = init;
    }
  }
}
