import { AbstractControl } from "@angular/forms";

export const CUSTOM_VALIDATOR = {
    phone: new RegExp( /^(00|\+)[1-9]{1}[0-9]{8,17}$/),
    hours: new RegExp(/^([01]?[0-9]|2[0-3]):[0-5][0-9]$/),
    email: new  RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/),
    decimal_number: new RegExp(/^-?\d+(?:[.]\d{2})?$/),
    number: new RegExp(/^\d+$/),
};

export const numberMatcher = (control: AbstractControl): {[key: string]: boolean} => {
    if (!control.value) { return null; }
    return control.value.toString().match(CUSTOM_VALIDATOR.number) ? null : { number : false};
};

export const checkMailValid = (control: AbstractControl): {[key: string]: boolean} => {
  if (!control.value) { return null; }
  return CUSTOM_VALIDATOR.email.test(control.value) ? null : { validEmail: false };
};