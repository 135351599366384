import { IWeldiable, IWeldiableMeterable } from "../../assemblages/welding";
import { ILaserCuttable } from "../../operations/laser-cutting";
import { AdditionalComputingCreate } from "./custom.service";
import { Platine, PlatineProperty } from "./platine";
import { SQUARE_MM_TO_SQUARE_M } from "../../base";

export interface EmbaseProperty extends PlatineProperty {
    elementForWelding: IWeldiableMeterable;
    usedAngle?: string;
}

export class Embase extends Platine<EmbaseProperty> implements ILaserCuttable, IWeldiable {

    public constructor(
        name: string,
        createAdditionalComputing: AdditionalComputingCreate,
        properties: EmbaseProperty
    ) {
        super(name, createAdditionalComputing, properties);
        // this.setAngle();
    }

    public get elementForWelding(): IWeldiableMeterable {
        return this.properties.elementForWelding;
    }

    public get hasWeldingLength(): boolean {
        return false;
    }

    public get TotalArea(): number {
        return (this.Area
            + (this.Area - (<any>this.elementForWelding).section)
            + this.properties.height * this.properties.thickness * 2
            + this.properties.width * this.properties.thickness * 2) * SQUARE_MM_TO_SQUARE_M;
    }

    // private setAngle() {
    //   const element: any = this.elementForWelding;
    //   if (element.Angle1 && !this.usedAngle) {
    //     this.usedAngle = "angle1";
    //   }
    // }

    public get usedAngle(): string {
      return this.properties.usedAngle;
    }

    public set usedAngle( value: string) {
      this.properties.usedAngle = value;
    }
}