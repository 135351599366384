import { Component, OnInit, Renderer2, ElementRef, Input, Output, EventEmitter } from "@angular/core";
import { ITabs } from "app/facade/interfaces/tabs";

@Component({
  selector: "app-tabs",
  templateUrl: "./tabs.component.html",
  styleUrls: ["./tabs.component.css"]
})
export class TabsComponent implements OnInit {
  @Input() public tabsData: ITabs[] = [];
  @Output() public onChangeTab: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private _renderer: Renderer2,
    private _elRef: ElementRef) {
    this._renderer.addClass(this._elRef.nativeElement, "main-header");
    this._renderer.addClass(this._elRef.nativeElement, "main-header_sub");
    this._renderer.addClass(this._elRef.nativeElement, "main-header_sub__small-margin");
  }

  ngOnInit() {
  }

  public selectTab(tabData) {
    this.tabsData = this.tabsData.map( tab => {
      return {...tab, isActive: tab.value === tabData.value};
    });
    this.onChangeTab.emit(this.tabsData);
  }

}
