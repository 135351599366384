import { Injectable } from "@angular/core";
import { CraneProperty } from "./crane";
import { NacelleProperty } from "./nacelle";
import { MontageProperty } from "./montage";
import { TransportProperty } from "./transport";
import { StudyProperty } from "./study";
import { MarginProperty } from "./margin";
import { OPTIONS_MAP } from "../use-class-map";
import { ITicketElement } from "app/presentationnal/organisms/wizard-modal/service/step-form.service";

type OptionAvailable =
    "Crane" |
    "Montage" |
    "Nacelle" |
    "Transport" |
    "Study" |
    "Margin";

type OptionBuild<T extends OptionAvailable> =
    T extends "Crane" ? CraneProperty :
    T extends "Montage" ? MontageProperty :
    T extends "Nacelle" ? NacelleProperty :
    T extends "Transport" ? TransportProperty :
    T extends "Study" ? StudyProperty :
    T extends "Margin" ? MarginProperty : never;

@Injectable()
export class OptionService {

    public constructor() {}

    public create<T extends OptionAvailable>(option: T, values: OptionBuild<T>, ticket?: ITicketElement[]) {
        const ctor: new (values: any, ticket: any) => any = OPTIONS_MAP[option];
        const newOption = new ctor(values, ticket);

        return newOption;
    }
}